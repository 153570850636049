import {uuidV4} from '../application/data-types'
import {formatDate} from '@angular/common'
import {Loan} from '@sparbanken-syd/kalpylator'
import {TermsTexts} from './terms-texts'

export class KalpLoan {

  public id: string = uuidV4()

  public interestPercent: number = TermsTexts.getThreeMonth().interestPercent

  public mortgagePercent = 0

  /**
   * MONTHLY mortgage in SEK.
   */
  public mortgageAmount = 0

  /**
   * The loan should be solved
   */
  public solve = false

  /**
   * Is this a new loan?
   */
  public new = false

  /**
   * Active is set if this loan is part of the current
   * rådgivning.
   */
  public active = false

  /**
   * If this is a blanco loan it should be handled differently?
   * Or is it just an indicator?
   */
  public isBlancoLoan = false

  /**
   * This can be set if this is part of a bunch of loans
   */
  public ratio = 1

  /**
   * An optional reference to a building that this loan belongs to?
   */
  public property: string | undefined

  /**
   * The date when this loan ends.
   */
  public expires = 0

  /**
   * Terms is the number of months this loan is running on, 3, 12, 24, 36, 60 etc.
   */
  private pTerms = 3

  /**
   * Returns the interest amount in SEK / month
   */
  get interestAmount(): number {
    return this.amount * this.interestPercent / 12
  }

  get cost(): number {
    return this.mortgageAmount + this.interestAmount
  }

  get changeDate(): string {
    return this.terms !== 3 && this.expires ? formatDate(this.expires, 'yyyy-MM-dd', 'fr', 'Europe/Stockholm') : ''
  }

  get terms(): number {
    return this.pTerms
  }

  set terms(terms: number) {
    this.pTerms = terms
    this.expires = new Date().getTime() + (60 * 60 * 24 * 30.41 * this.terms * 1000)
  }

  /**
   * Create a new loan
   * @param amount - The total amount of the loan
   * @param newLoan - Set to true if this should be "true"
   */
  constructor(
    public amount: number,
    newLoan = false
  ) {
    this.new = newLoan
  }

  public setFromInput(input: KalpLoan): void {
    Object.assign(this, input)
    this.setMortgageAmount(input.mortgageAmount)
  }

  public setMortgagePercent(percent: number): void {
    this.mortgagePercent = percent
    this.mortgageAmount = Math.ceil(this.amount * this.mortgagePercent / 12)
  }

  public setMortgageAmount(mortgage: number): void {
    this.mortgageAmount = mortgage
    this.mortgagePercent = mortgage * 12 / this.amount
  }

  /**
   * This is used to import loans saved in DataStore
   * @param input
   */
  public setLoanFromKalpInput(input: Loan): void {
    const blancoLoans = ['BLANCO', 'CREDIT', 'BORGEN']
    this.interestPercent = input.interestRate > 0 ? input.interestRate : 0
    this.mortgageAmount = input.mortgage > 0 ? input.mortgage : 0
    this.property = input.propertyId
    this.isBlancoLoan = blancoLoans.includes(input.loanType)
    this.solve = !input.keep
    this.expires = input.expires ?? 0
    this.pTerms = input.terms ?? 3
  }
}
