import {Injectable} from '@angular/core'
import {BehaviorSubject, ReplaySubject} from 'rxjs'
import {KalpLoan} from '../model/kalp-loan'
import {Questions} from './proposal.service'
import {Advice} from '../model/advice'

export interface ContactInfo {
  email?: string
  mobile?: string
}


@Injectable({
  providedIn: 'root'
})
export class StaticDataService {

  /**
   * We send empty advice to simplify testing a bit.
   */
  public advice$: BehaviorSubject<Advice[]> = new BehaviorSubject<Advice[]>([new Advice()])

  public plural$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  /**
   * This should be the active list of loans that are in the new
   * scenarios. Filtered on property etc.
   */
  public newLoans$: BehaviorSubject<KalpLoan[]> = new BehaviorSubject<KalpLoan[]>([])

  public questions$ = new BehaviorSubject<Questions>({q1: -1, q2: -1})

  public comment$ = new BehaviorSubject<string>('')

  public followAdvice$ = new BehaviorSubject<string>('')

  public contactInfo$ = new BehaviorSubject<[ContactInfo, ContactInfo]>([{}, {}])

  public reset$ = new ReplaySubject<any>(1)


  public reset(): void {
    this.comment$.next('')
    this.questions$.next({q1: -1, q2: -1})
    this.newLoans$.next([])
    this.plural$.next(false)
    this.advice$.next([])
    this.followAdvice$.next('')
    this.contactInfo$.next([{}, {}])
    this.reset$.next(undefined)
  }
}
