import {Injectable} from '@angular/core'
import {BehaviorSubject, ReplaySubject} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ControlService {

  public scenarioSelected$ = new BehaviorSubject<boolean>(false)

  public steps: { [key: number]: BehaviorSubject<boolean> } = {
    1: new BehaviorSubject<boolean>(false),
    2: new BehaviorSubject<boolean>(false),
    3: new BehaviorSubject<boolean>(false),
    5: new BehaviorSubject<boolean>(false),
    7: new BehaviorSubject<boolean>(false)
  }

  /**
   * This is set to true if needed.
   */
  public declines$ = new BehaviorSubject<boolean>(false)

  /**
   * To lock the customer from getting overwritten by another supervisor
   */
  public locked$ = new BehaviorSubject<boolean>(false)

  /**
   * We emit which step we should show.
   */
  public step$ = new ReplaySubject<number>(1)
  public canSave$ = new BehaviorSubject<boolean>(false)
  private declines = false

  /**
   * Set a value or toggle the value
   *
   * @param declines - Set it to true or false or do not send it to toggle
   */
  public decline(declines?: boolean | undefined): boolean {

    if (declines !== undefined) {
      this.declines = declines
    } else {
      this.declines = !this.declines
    }

    this.declines$.next(this.declines)
    if (this.declines) {
      setTimeout(() => this.step$.next(7), 1)
    } else {
      setTimeout(() => this.step$.next(0), 1)
    }
    // Object.values(this.steps).forEach((s: BehaviorSubject<boolean>) => s.next(this.declines))
    return this.declines
  }
}
