/**
 * This file only contains the values.
 *
 * It is huge and difficult to edit.
 *
 */
export const Skattesatser2022 = {
  '1060': 34.585,
  '1080': 34.49,
  '1081': 34.67,
  '1082': 35.195,
  '1083': 34.575,
  '1214': 32.815,
  '1230': 31.125,
  '1231': 31.955,
  '1233': 30.485,
  '1256': 33.04,
  '1257': 31.09,
  '1260': 32.97,
  '1261': 30.485,
  '1262': 31.49,
  '1263': 32.14,
  '1264': 32.515,
  '1265': 32.99,
  '1266': 33.085,
  '1267': 33.83,
  '1270': 32.605,
  '1272': 34.5,
  '1273': 34.22,
  '1275': 32.85,
  '1276': 32.765,
  '1277': 32.445,
  '1278': 32.335,
  '1280': 33.145,
  '1281': 33.205,
  '1282': 32.18,
  '1283': 32.15,
  '1284': 31.68,
  '1285': 32.45,
  '1286': 32.075,
  '1287': 32.255,
  '1290': 33.335,
  '1291': 32.6,
  '1292': 32.25,
  '1293': 33.255,
  '1315': 33.96,
  '1380': 33.12,
  '1381': 32.84,
  '1382': 33.41,
  '1383': 32.585,
  '1384': 33.66,
  '1401': 32.81,
  '1402': 32.225,
  '1407': 33.855,
  '1415': 33.985,
  '1419': 34.085,
  '1421': 35,
  '1427': 34.395,
  '1430': 35.75,
  '1435': 34.02,
  '1438': 35.76,
  '1439': 35.255,
  '1440': 34.175,
  '1441': 32.945,
  '1442': 33.955,
  '1443': 33.93,
  '1444': 34.305,
  '1445': 34.115,
  '1446': 33.715,
  '1447': 34.895,
  '1452': 33.975,
  '1460': 35.42,
  '1461': 35.09,
  '1462': 34.78,
  '1463': 33.955,
  '1465': 34.525,
  '1466': 34.365,
  '1470': 34.26,
  '1471': 34.49,
  '1472': 33.46,
  '1473': 34.095,
  '1480': 33.285,
  '1481': 32.65,
  '1482': 33.74,
  '1484': 34.845,
  '1485': 34.505,
  '1486': 34.26,
  '1487': 34.56,
  '1488': 34.71,
  '1489': 33.655,
  '1490': 33.755,
  '1491': 33.465,
  '1492': 34.835,
  '1493': 33.675,
  '1494': 33.79,
  '1495': 33.87,
  '1496': 34.045,
  '1497': 33.81,
  '1498': 34.5,
  '1499': 34.335,
  '1715': 34.365,
  '1730': 34.87,
  '1737': 34.655,
  '1760': 35.305,
  '1761': 35.47,
  '1763': 34.36,
  '1764': 35.085,
  '1765': 34.745,
  '1766': 34.25,
  '1780': 33.705,
  '1781': 34.495,
  '1782': 34.89,
  '1783': 34.56,
  '1784': 34.33,
  '1785': 34.1,
  '1814': 34.91,
  '1860': 34.68,
  '1861': 33.965,
  '1862': 35.27,
  '1863': 34.55,
  '1864': 33.475,
  '1880': 33.61,
  '1881': 33.92,
  '1882': 34.205,
  '1883': 34.27,
  '1884': 34.765,
  '1885': 34.78,
  '1904': 34.23,
  '1907': 34.04,
  '1960': 33.785,
  '1961': 33.46,
  '1962': 34.485,
  '1980': 31.98,
  '1981': 34.195,
  '1982': 33.855,
  '1983': 33.825,
  '1984': 34.205,
  '2021': 34.845,
  '2023': 35.065,
  '2026': 34.795,
  '2029': 34.39,
  '2031': 34.37,
  '2034': 34.9,
  '2039': 35.445,
  '2061': 34.96,
  '2062': 34.795,
  '2080': 34.465,
  '2081': 34.75,
  '2082': 34.86,
  '2083': 34.735,
  '2084': 34.445,
  '2085': 34.485,
  '2101': 35.135,
  '2104': 35.295,
  '2121': 34.235,
  '2132': 34.925,
  '2161': 34.685,
  '2180': 34.48,
  '2181': 33.945,
  '2182': 33.975,
  '2183': 34.14,
  '2184': 33.845,
  '2260': 35.665,
  '2262': 35.49,
  '2280': 35.625,
  '2281': 34.775,
  '2282': 35.49,
  '2283': 35.675,
  '2284': 34.985,
  '2303': 35.93,
  '2305': 36.1,
  '2309': 34.835,
  '2313': 35.93,
  '2321': 34.88,
  '2326': 35.23,
  '2361': 35.355,
  '2380': 34.565,
  '2401': 35.41,
  '2403': 35.265,
  '2404': 35.82,
  '2409': 35.345,
  '2417': 35.51,
  '2418': 35.635,
  '2421': 35.435,
  '2422': 35.885,
  '2425': 36.06,
  '2460': 35.715,
  '2462': 35.66,
  '2463': 35.86,
  '2480': 34.915,
  '2481': 35.31,
  '2482': 34.825,
  '2505': 35.025,
  '2506': 35.725,
  '2510': 35.2,
  '2513': 34.95,
  '2514': 34.64,
  '2518': 33.95,
  '2521': 35.625,
  '2523': 34.56,
  '2560': 34.555,
  '2580': 34.71,
  '2581': 34.31,
  '2582': 34.675,
  '2583': 34.565,
  '2584': 35.145,
  '0114': 32.175,
  '0115': 31.81,
  '0117': 29.605,
  '0120': 31.75,
  '0123': 31.67,
  '0125': 31.755,
  '0126': 32.165,
  '0127': 32.995,
  '0128': 32.18,
  '0136': 32.44,
  '0138': 32.245,
  '0139': 32.18,
  '0140': 32.845,
  '0160': 30.275,
  '0162': 31.05,
  '0163': 30.855,
  '0180': 30.23,
  '0181': 33.025,
  '0182': 30.755,
  '0183': 31.6,
  '0184': 29.8,
  '0186': 30.51,
  '0187': 32.06,
  '0188': 32.59,
  '0191': 32.805,
  '0192': 32.725,
  '0305': 33.62,
  '0319': 35.225,
  '0330': 33.395,
  '0331': 35.13,
  '0360': 33.97,
  '0380': 33.66,
  '0381': 33.87,
  '0382': 34.295,
  '0428': 34.28,
  '0461': 33.855,
  '0480': 33.305,
  '0481': 33.83,
  '0482': 34.095,
  '0483': 33.735,
  '0484': 33.56,
  '0486': 33.405,
  '0488': 33.175,
  '0509': 34.985,
  '0512': 35.125,
  '0513': 33.905,
  '0560': 34.205,
  '0561': 34.84,
  '0562': 34.485,
  '0563': 34.965,
  '0580': 32.64,
  '0581': 34.31,
  '0582': 33.765,
  '0583': 34.11,
  '0584': 35.25,
  '0586': 34.285,
  '0604': 34.82,
  '0617': 34.86,
  '0642': 34.925,
  '0643': 34.78,
  '0662': 34.6,
  '0665': 34.055,
  '0680': 34.425,
  '0682': 35.01,
  '0683': 34.01,
  '0684': 34.565,
  '0685': 34.71,
  '0686': 34.98,
  '0687': 34.56,
  '0760': 34.835,
  '0761': 34.735,
  '0763': 35.01,
  '0764': 34.39,
  '0765': 34.695,
  '0767': 34.29,
  '0780': 32.975,
  '0781': 33.93,
  '0821': 34.94,
  '0834': 34.175,
  '0840': 35.105,
  '0860': 34.7,
  '0861': 34.84,
  '0862': 34.68,
  '0880': 34.555,
  '0881': 35.035,
  '0882': 35.04,
  '0883': 34.03,
  '0884': 35.14,
  '0885': 34.47,
  '0980': 34.785
};
export const Skattesatser2023 = {
  '1060': 34.583,
  '1080': 34.443,
  '1081': 34.668,
  '1082': 35.193,
  '1083': 34.573,
  '1214': 32.813,
  '1230': 31.123,
  '1231': 31.953,
  '1233': 30.483,
  '1256': 33.038,
  '1257': 31.088,
  '1260': 33.053,
  '1261': 30.483,
  '1262': 31.488,
  '1263': 32.138,
  '1264': 32.513,
  '1265': 32.988,
  '1266': 33.083,
  '1267': 33.828,
  '1270': 32.603,
  '1272': 34.498,
  '1273': 34.218,
  '1275': 32.848,
  '1276': 32.763,
  '1277': 32.443,
  '1278': 32.333,
  '1280': 33.143,
  '1281': 33.203,
  '1282': 32.178,
  '1283': 32.151,
  '1284': 31.678,
  '1285': 32.448,
  '1286': 32.073,
  '1287': 32.303,
  '1290': 33.333,
  '1291': 32.598,
  '1292': 32.248,
  '1293': 33.253,
  '1315': 33.958,
  '1380': 33.12,
  '1381': 32.838,
  '1382': 33.408,
  '1383': 32.583,
  '1384': 33.658,
  '1401': 32.808,
  '1402': 32.223,
  '1407': 33.853,
  '1415': 33.983,
  '1419': 34.083,
  '1421': 34.898,
  '1427': 34.393,
  '1430': 35.773,
  '1435': 34.018,
  '1438': 35.761,
  '1439': 35.255,
  '1440': 34.173,
  '1441': 32.943,
  '1442': 33.956,
  '1443': 33.928,
  '1444': 34.306,
  '1445': 34.115,
  '1446': 33.715,
  '1447': 34.865,
  '1452': 33.973,
  '1460': 35.421,
  '1461': 35.088,
  '1462': 34.778,
  '1463': 33.953,
  '1465': 34.523,
  '1466': 34.364,
  '1470': 34.861,
  '1471': 34.49,
  '1472': 33.96,
  '1473': 34.096,
  '1480': 33.283,
  '1481': 32.648,
  '1482': 33.738,
  '1484': 34.843,
  '1485': 34.503,
  '1486': 34.258,
  '1487': 34.561,
  '1488': 34.71,
  '1489': 33.656,
  '1490': 33.755,
  '1491': 33.463,
  '1492': 34.835,
  '1493': 33.675,
  '1494': 33.79,
  '1495': 33.87,
  '1496': 34.046,
  '1497': 34.24,
  '1498': 34.501,
  '1499': 34.335,
  '1715': 34.365,
  '1730': 34.87,
  '1737': 34.653,
  '1760': 35.305,
  '1761': 35.27,
  '1762': 34.511,
  '1763': 34.361,
  '1764': 35.085,
  '1765': 34.743,
  '1766': 34.251,
  '1780': 33.703,
  '1781': 34.495,
  '1782': 34.891,
  '1783': 34.56,
  '1784': 34.331,
  '1785': 34.1,
  '1814': 34.908,
  '1860': 34.678,
  '1861': 33.963,
  '1862': 35.27,
  '1863': 34.548,
  '1864': 33.953,
  '1880': 33.608,
  '1881': 33.918,
  '1882': 34.203,
  '1883': 34.27,
  '1884': 34.763,
  '1885': 34.778,
  '1904': 34.253,
  '1907': 34.038,
  '1960': 33.783,
  '1961': 33.458,
  '1962': 34.493,
  '1980': 31.978,
  '1981': 34.193,
  '1982': 33.853,
  '1983': 33.823,
  '1984': 34.203,
  '2021': 34.843,
  '2023': 35.063,
  '2026': 34.793,
  '2029': 34.388,
  '2031': 34.368,
  '2034': 34.898,
  '2039': 35.443,
  '2061': 34.962,
  '2062': 34.793,
  '2080': 34.463,
  '2081': 34.748,
  '2082': 34.858,
  '2083': 34.733,
  '2084': 34.443,
  '2085': 34.483,
  '2101': 35.133,
  '2104': 35.293,
  '2121': 34.233,
  '2132': 34.923,
  '2161': 34.683,
  '2180': 34.478,
  '2181': 33.943,
  '2182': 33.973,
  '2183': 34.138,
  '2184': 33.844,
  '2260': 35.663,
  '2262': 35.438,
  '2280': 35.623,
  '2281': 34.773,
  '2282': 35.488,
  '2283': 35.673,
  '2284': 34.983,
  '2303': 35.928,
  '2305': 36.098,
  '2309': 34.834,
  '2313': 35.928,
  '2321': 34.878,
  '2326': 35.228,
  '2361': 35.353,
  '2380': 34.563,
  '2401': 35.408,
  '2403': 35.263,
  '2404': 35.818,
  '2409': 35.343,
  '2417': 35.508,
  '2418': 35.633,
  '2421': 35.433,
  '2422': 35.883,
  '2425': 36.058,
  '2460': 35.463,
  '2462': 35.658,
  '2463': 35.858,
  '2480': 34.913,
  '2481': 35.308,
  '2482': 34.823,
  '2505': 35.023,
  '2506': 35.723,
  '2510': 35.198,
  '2513': 34.948,
  '2514': 34.638,
  '2518': 33.948,
  '2521': 35.627,
  '2523': 34.558,
  '2560': 34.553,
  '2580': 34.708,
  '2581': 34.308,
  '2582': 34.673,
  '2583': 34.613,
  '2584': 35.143,
  '0114': 32.175,
  '0115': 31.808,
  '0117': 29.605,
  '0120': 31.751,
  '0123': 31.67,
  '0125': 31.755,
  '0126': 32.166,
  '0127': 32.993,
  '0128': 32.178,
  '0136': 32.441,
  '0138': 32.245,
  '0139': 32.178,
  '0140': 32.843,
  '0160': 30.276,
  '0162': 31.05,
  '0163': 30.855,
  '0180': 30.285,
  '0181': 33.023,
  '0182': 30.756,
  '0183': 31.948,
  '0184': 30.05,
  '0186': 30.511,
  '0187': 32.06,
  '0188': 32.588,
  '0191': 32.803,
  '0192': 32.723,
  '0305': 33.618,
  '0319': 35.223,
  '0330': 33.393,
  '0331': 35.128,
  '0360': 33.968,
  '0380': 33.658,
  '0381': 33.868,
  '0382': 34.293,
  '0428': 34.278,
  '0461': 33.853,
  '0480': 33.303,
  '0481': 33.828,
  '0482': 34.093,
  '0483': 33.733,
  '0484': 33.558,
  '0486': 33.403,
  '0488': 33.073,
  '0509': 34.983,
  '0512': 35.123,
  '0513': 33.903,
  '0560': 34.203,
  '0561': 34.838,
  '0562': 34.483,
  '0563': 34.963,
  '0580': 32.638,
  '0581': 34.308,
  '0582': 33.763,
  '0583': 34.108,
  '0584': 35.248,
  '0586': 34.283,
  '0604': 34.818,
  '0617': 34.958,
  '0642': 34.925,
  '0643': 34.781,
  '0662': 34.598,
  '0665': 34.053,
  '0680': 34.423,
  '0682': 35.008,
  '0683': 34.008,
  '0684': 34.563,
  '0685': 34.708,
  '0686': 34.978,
  '0687': 34.57,
  '0760': 34.733,
  '0761': 34.733,
  '0763': 35.008,
  '0764': 34.388,
  '0765': 34.693,
  '0767': 34.288,
  '0780': 32.973,
  '0781': 33.928,
  '0821': 34.938,
  '0834': 34.173,
  '0840': 35.103,
  '0860': 34.698,
  '0861': 34.838,
  '0862': 34.678,
  '0880': 34.553,
  '0881': 35.033,
  '0882': 35.038,
  '0883': 34.028,
  '0884': 35.138,
  '0885': 34.468,
  '0980': 34.783
};
export const Tabeller2022 = {
  29: {
    1: [0, 0, 0, 0, 0, 0],
    1701: [126, 0, 125, 1, 126, 27],
    1801: [137, 0, 133, 4, 137, 57],
    1901: [147, 0, 141, 6, 147, 87],
    2001: [158, 0, 150, 8, 158, 117],
    2101: [160, 0, 150, 10, 160, 147],
    2201: [171, 0, 158, 12, 177, 177],
    2301: [181, 0, 166, 14, 207, 207],
    2401: [192, 0, 175, 17, 237, 237],
    2501: [202, 0, 183, 19, 267, 267],
    2601: [213, 0, 191, 21, 297, 297],
    2701: [223, 0, 200, 23, 327, 327],
    2801: [225, 0, 200, 25, 357, 357],
    2901: [236, 0, 208, 27, 387, 387],
    3001: [246, 0, 216, 30, 417, 417],
    3101: [257, 0, 225, 32, 447, 447],
    3201: [267, 0, 233, 34, 477, 477],
    3301: [278, 0, 241, 36, 507, 507],
    3401: [280, 0, 241, 38, 537, 537],
    3501: [290, 0, 250, 40, 567, 567],
    3601: [301, 0, 258, 49, 597, 597],
    3701: [311, 0, 266, 70, 627, 627],
    3801: [322, 0, 275, 90, 657, 657],
    3901: [332, 0, 283, 111, 687, 687],
    4001: [334, 0, 283, 131, 709, 709],
    4101: [344, 0, 291, 151, 734, 734],
    4201: [354, 0, 300, 171, 759, 759],
    4301: [364, 0, 308, 191, 782, 782],
    4401: [374, 4, 317, 211, 807, 807],
    4501: [384, 27, 326, 231, 829, 829],
    4601: [386, 49, 328, 252, 854, 854],
    4701: [396, 72, 338, 272, 879, 879],
    4801: [406, 94, 348, 292, 902, 902],
    4901: [416, 117, 358, 312, 927, 927],
    5001: [426, 139, 368, 332, 949, 949],
    5101: [436, 162, 378, 352, 974, 974],
    5201: [447, 184, 388, 372, 1000, 1000],
    5301: [448, 204, 389, 392, 1021, 1021],
    5401: [458, 227, 399, 411, 1046, 1046],
    5501: [468, 250, 409, 430, 1068, 1068],
    5601: [478, 272, 419, 450, 1092, 1092],
    5701: [488, 294, 429, 470, 1116, 1116],
    5801: [498, 317, 439, 489, 1138, 1138],
    5901: [500, 339, 441, 509, 1163, 1163],
    6001: [510, 362, 451, 528, 1185, 1185],
    6101: [520, 384, 461, 547, 1209, 1209],
    6201: [533, 407, 471, 567, 1233, 1233],
    6301: [551, 429, 480, 586, 1255, 1255],
    6401: [569, 452, 490, 606, 1280, 1280],
    6501: [587, 472, 492, 625, 1302, 1302],
    6601: [605, 494, 502, 645, 1326, 1326],
    6701: [623, 517, 512, 664, 1350, 1350],
    6801: [642, 539, 522, 684, 1372, 1372],
    6901: [660, 562, 532, 703, 1397, 1397],
    7001: [678, 584, 542, 723, 1419, 1419],
    7101: [696, 607, 543, 742, 1443, 1443],
    7201: [714, 629, 553, 762, 1467, 1467],
    7301: [733, 652, 563, 781, 1489, 1489],
    7401: [751, 674, 573, 801, 1514, 1514],
    7501: [769, 697, 583, 820, 1536, 1536],
    7601: [787, 719, 593, 840, 1560, 1560],
    7701: [805, 739, 603, 859, 1584, 1584],
    7801: [823, 762, 604, 879, 1606, 1606],
    7901: [842, 777, 614, 898, 1631, 1631],
    8001: [860, 794, 623, 918, 1653, 1653],
    8101: [878, 812, 633, 937, 1677, 1677],
    8201: [896, 832, 643, 957, 1701, 1701],
    8301: [914, 849, 652, 976, 1723, 1723],
    8401: [933, 867, 654, 996, 1748, 1748],
    8501: [951, 884, 663, 1015, 1770, 1770],
    8601: [969, 904, 673, 1035, 1794, 1794],
    8701: [987, 922, 683, 1054, 1818, 1818],
    8801: [1005, 939, 692, 1074, 1840, 1840],
    8901: [1023, 957, 702, 1093, 1865, 1865],
    9001: [1042, 977, 703, 1113, 1887, 1887],
    9101: [1060, 994, 713, 1132, 1911, 1911],
    9201: [1078, 1012, 722, 1152, 1935, 1935],
    9301: [1096, 1031, 732, 1171, 1957, 1957],
    9401: [1114, 1048, 742, 1191, 1982, 1982],
    9501: [1132, 1065, 751, 1210, 2004, 2004],
    9601: [1151, 1082, 753, 1230, 2028, 2028],
    9701: [1169, 1102, 762, 1249, 2052, 2052],
    9801: [1187, 1119, 772, 1269, 2074, 2074],
    9901: [1205, 1136, 782, 1288, 2099, 2099],
    10001: [1223, 1153, 791, 1307, 2121, 2121],
    10101: [1242, 1173, 801, 1327, 2145, 2145],
    10201: [1260, 1190, 811, 1347, 2169, 2169],
    10301: [1278, 1207, 812, 1366, 2191, 2191],
    10401: [1296, 1224, 821, 1386, 2216, 2216],
    10501: [1314, 1243, 831, 1405, 2238, 2238],
    10601: [1333, 1260, 841, 1424, 2262, 2262],
    10701: [1351, 1277, 850, 1444, 2286, 2286],
    10801: [1369, 1294, 860, 1463, 2308, 2308],
    10901: [1387, 1314, 861, 1483, 2335, 2335],
    11001: [1406, 1331, 871, 1503, 2364, 2364],
    11101: [1424, 1348, 881, 1523, 2394, 2394],
    11201: [1443, 1368, 890, 1542, 2423, 2423],
    11301: [1461, 1385, 900, 1562, 2452, 2452],
    11401: [1480, 1402, 910, 1582, 2481, 2481],
    11501: [1498, 1419, 911, 1602, 2511, 2511],
    11601: [1516, 1438, 921, 1621, 2540, 2540],
    11701: [1535, 1455, 930, 1641, 2569, 2569],
    11801: [1553, 1472, 940, 1661, 2598, 2598],
    11901: [1572, 1489, 949, 1681, 2628, 2628],
    12001: [1590, 1509, 959, 1701, 2657, 2657],
    12101: [1609, 1526, 960, 1720, 2686, 2686],
    12201: [1627, 1543, 970, 1740, 2715, 2715],
    12301: [1646, 1560, 980, 1760, 2745, 2745],
    12401: [1664, 1580, 989, 1780, 2774, 2774],
    12501: [1683, 1597, 999, 1800, 2806, 2806],
    12601: [1701, 1614, 1009, 1819, 2837, 2837],
    12701: [1720, 1631, 1018, 1839, 2869, 2869],
    12801: [1739, 1650, 1020, 1859, 2901, 2901],
    12901: [1757, 1667, 1029, 1879, 2932, 2932],
    13001: [1781, 1689, 1039, 1903, 2967, 2967],
    13101: [1807, 1716, 1049, 1929, 2998, 2998],
    13201: [1832, 1740, 1060, 1955, 3030, 3030],
    13301: [1858, 1767, 1070, 1980, 3062, 3062],
    13401: [1884, 1792, 1072, 2006, 3093, 3093],
    13501: [1910, 1816, 1082, 2032, 3127, 3127],
    13601: [1936, 1843, 1092, 2058, 3159, 3159],
    13701: [1962, 1867, 1102, 2084, 3191, 3191],
    13801: [1987, 1894, 1113, 2109, 3222, 3222],
    13901: [2013, 1918, 1123, 2135, 3254, 3254],
    14001: [2038, 1943, 1124, 2160, 3287, 3287],
    14101: [2063, 1970, 1135, 2185, 3318, 3318],
    14201: [2088, 1994, 1145, 2210, 3349, 3349],
    14301: [2112, 2021, 1155, 2234, 3379, 3379],
    14401: [2137, 2045, 1165, 2259, 3410, 3410],
    14501: [2162, 2070, 1176, 2284, 3443, 3443],
    14601: [2186, 2096, 1178, 2309, 3473, 3473],
    14701: [2211, 2121, 1188, 2333, 3504, 3504],
    14801: [2236, 2148, 1198, 2358, 3534, 3534],
    14901: [2261, 2172, 1208, 2383, 3565, 3565],
    15001: [2285, 2196, 1218, 2408, 3598, 3598],
    15101: [2310, 2223, 1229, 2432, 3629, 3629],
    15201: [2335, 2247, 1239, 2457, 3659, 3659],
    15301: [2360, 2274, 1241, 2482, 3690, 3690],
    15401: [2384, 2299, 1251, 2506, 3720, 3720],
    15501: [2409, 2323, 1261, 2531, 3753, 3753],
    15601: [2434, 2350, 1271, 2556, 3784, 3784],
    15701: [2458, 2374, 1281, 2581, 3815, 3815],
    15801: [2483, 2401, 1292, 2605, 3845, 3845],
    15901: [2508, 2425, 1293, 2630, 3876, 3876],
    16001: [2533, 2450, 1304, 2655, 3909, 3909],
    16101: [2557, 2477, 1314, 2679, 3939, 3939],
    16201: [2582, 2501, 1324, 2704, 3970, 3970],
    16301: [2607, 2528, 1334, 2729, 4001, 4001],
    16401: [2632, 2552, 1344, 2754, 4031, 4031],
    16501: [2656, 2577, 1346, 2778, 4064, 4064],
    16601: [2681, 2603, 1357, 2803, 4095, 4095],
    16701: [2706, 2628, 1367, 2828, 4125, 4125],
    16801: [2730, 2655, 1377, 2853, 4156, 4156],
    16901: [2755, 2679, 1387, 2877, 4187, 4187],
    17001: [2780, 2703, 1397, 2902, 4220, 4220],
    17101: [2805, 2730, 1399, 2927, 4250, 4250],
    17201: [2829, 2754, 1409, 2952, 4281, 4281],
    17301: [2854, 2781, 1420, 2976, 4311, 4311],
    17401: [2879, 2806, 1430, 3001, 4342, 4342],
    17501: [2904, 2832, 1440, 3026, 4375, 4375],
    17601: [2928, 2857, 1450, 3050, 4406, 4406],
    17701: [2953, 2881, 1460, 3075, 4436, 4436],
    17801: [2978, 2908, 1462, 3100, 4467, 4467],
    17901: [3002, 2932, 1473, 3125, 4497, 4497],
    18001: [3027, 2959, 1483, 3149, 4530, 4530],
    18101: [3052, 2984, 1493, 3174, 4561, 4561],
    18201: [3077, 3008, 1503, 3199, 4592, 4592],
    18301: [3101, 3035, 1513, 3224, 4622, 4622],
    18401: [3126, 3059, 1515, 3248, 4653, 4653],
    18501: [3151, 3086, 1526, 3273, 4686, 4686],
    18601: [3176, 3110, 1536, 3298, 4716, 4716],
    18701: [3200, 3135, 1546, 3322, 4747, 4747],
    18801: [3225, 3162, 1556, 3347, 4777, 4777],
    18901: [3250, 3186, 1566, 3372, 4808, 4808],
    19001: [3274, 3213, 1568, 3397, 4841, 4841],
    19101: [3299, 3237, 1578, 3421, 4872, 4872],
    19201: [3324, 3261, 1588, 3446, 4902, 4902],
    19301: [3349, 3287, 1598, 3471, 4933, 4933],
    19401: [3373, 3311, 1607, 3495, 4963, 4963],
    19501: [3398, 3337, 1617, 3520, 4996, 4996],
    19601: [3423, 3360, 1618, 3545, 5027, 5027],
    19701: [3448, 3384, 1627, 3570, 5058, 5058],
    19801: [3472, 3410, 1636, 3594, 5088, 5088],
    19901: [3497, 3433, 1646, 3619, 5119, 5119],
    20001: [3546, 3483, 1664, 3669, 5182, 5182],
    20201: [3596, 3532, 1675, 3718, 5244, 5244],
    20401: [3645, 3582, 1693, 3768, 5307, 5307],
    20601: [3695, 3629, 1712, 3817, 5368, 5368],
    20801: [3744, 3678, 1722, 3866, 5430, 5430],
    21001: [3794, 3728, 1741, 3916, 5493, 5493],
    21201: [3843, 3777, 1760, 3965, 5554, 5554],
    21401: [3893, 3826, 1770, 4015, 5618, 5618],
    21601: [3942, 3873, 1789, 4064, 5679, 5679],
    21801: [3992, 3923, 1807, 4114, 5740, 5740],
    22001: [4041, 3972, 1818, 4164, 5804, 5804],
    22201: [4093, 4022, 1836, 4215, 5867, 5867],
    22401: [4144, 4074, 1855, 4266, 5932, 5932],
    22601: [4195, 4123, 1874, 4317, 5995, 5995],
    22801: [4246, 4172, 1884, 4368, 6058, 6058],
    23001: [4297, 4224, 1903, 4419, 6123, 6123],
    23201: [4348, 4274, 1922, 4470, 6186, 6186],
    23401: [4399, 4323, 1932, 4521, 6251, 6251],
    23601: [4450, 4375, 1951, 4572, 6314, 6314],
    23801: [4501, 4424, 1974, 4624, 6377, 6377],
    24001: [4553, 4474, 2014, 4675, 6442, 6442],
    24201: [4604, 4523, 2053, 4726, 6505, 6505],
    24401: [4655, 4575, 2095, 4777, 6571, 6571],
    24601: [4706, 4624, 2134, 4828, 6633, 6633],
    24801: [4757, 4674, 2174, 4879, 6696, 6696],
    25001: [4808, 4726, 2226, 4930, 6761, 6761],
    25201: [4859, 4775, 2275, 4981, 6824, 6824],
    25401: [4910, 4825, 2325, 5032, 6889, 6889],
    25601: [4961, 4876, 2376, 5084, 6952, 6952],
    25801: [5012, 4926, 2426, 5135, 7015, 7015],
    26001: [5064, 4975, 2475, 5186, 7080, 7080],
    26201: [5115, 5027, 2527, 5237, 7143, 7143],
    26401: [5166, 5076, 2576, 5288, 7208, 7208],
    26601: [5217, 5126, 2626, 5339, 7271, 7271],
    26801: [5268, 5175, 2675, 5390, 7334, 7334],
    27001: [5319, 5227, 2727, 5441, 7399, 7399],
    27201: [5370, 5277, 2777, 5492, 7462, 7462],
    27401: [5421, 5326, 2826, 5543, 7527, 7527],
    27601: [5472, 5378, 2878, 5595, 7590, 7590],
    27801: [5523, 5427, 2927, 5646, 7653, 7653],
    28001: [5575, 5477, 2977, 5697, 7718, 7718],
    28201: [5626, 5528, 3028, 5748, 7781, 7781],
    28401: [5677, 5578, 3078, 5799, 7846, 7846],
    28601: [5728, 5627, 3127, 5850, 7909, 7909],
    28801: [5779, 5679, 3179, 5901, 7972, 7972],
    29001: [5830, 5729, 3229, 5952, 8037, 8037],
    29201: [5881, 5778, 3278, 6003, 8100, 8100],
    29401: [5932, 5831, 3331, 6055, 8165, 8165],
    29601: [5983, 5882, 3382, 6106, 8228, 8228],
    29801: [6035, 5932, 3432, 6157, 8291, 8291],
    30001: [6086, 5983, 3483, 6208, 8356, 8356],
    30201: [6137, 6036, 3536, 6259, 8419, 8419],
    30401: [6188, 6087, 3587, 6310, 8484, 8484],
    30601: [6239, 6138, 3638, 6361, 8547, 8547],
    30801: [6290, 6191, 3691, 6412, 8610, 8610],
    31001: [6341, 6242, 3742, 6463, 8675, 8675],
    31201: [6392, 6293, 3793, 6514, 8738, 8738],
    31401: [6443, 6346, 3846, 6566, 8803, 8803],
    31601: [6494, 6394, 3894, 6617, 8864, 8864],
    31801: [6545, 6445, 3945, 6667, 8922, 8922],
    32001: [6596, 6496, 3996, 6718, 8980, 8980],
    32201: [6647, 6546, 4046, 6769, 9038, 9038],
    32401: [6700, 6599, 4099, 6820, 9096, 9096],
    32601: [6758, 6657, 4157, 6871, 9154, 9154],
    32801: [6816, 6715, 4215, 6922, 9212, 9212],
    33001: [6874, 6773, 4273, 6973, 9270, 9270],
    33201: [6932, 6831, 4331, 7024, 9328, 9328],
    33401: [6990, 6889, 4389, 7074, 9386, 9386],
    33601: [7048, 6947, 4447, 7125, 9444, 9444],
    33801: [7106, 7005, 4505, 7176, 9502, 9502],
    34001: [7164, 7063, 4563, 7227, 9560, 9560],
    34201: [7222, 7121, 4621, 7278, 9618, 9618],
    34401: [7280, 7179, 4679, 7329, 9676, 9676],
    34601: [7338, 7237, 4737, 7380, 9734, 9734],
    34801: [7396, 7296, 4796, 7431, 9792, 9792],
    35001: [7454, 7353, 4853, 7481, 9850, 9850],
    35201: [7512, 7411, 4911, 7532, 9908, 9908],
    35401: [7570, 7469, 4969, 7583, 9966, 9966],
    35601: [7628, 7527, 5027, 7634, 10024, 10024],
    35801: [7686, 7585, 5085, 7685, 10082, 10082],
    36001: [7744, 7643, 5143, 7736, 10140, 10140],
    36201: [7802, 7701, 5201, 7787, 10198, 10198],
    36401: [7860, 7759, 5259, 7838, 10256, 10256],
    36601: [7918, 7817, 5317, 7888, 10314, 10314],
    36801: [7976, 7875, 5375, 7939, 10372, 10372],
    37001: [8034, 7933, 5433, 7990, 10430, 10430],
    37201: [8092, 7991, 5491, 8041, 10488, 10488],
    37401: [8150, 8049, 5549, 8092, 10546, 10546],
    37601: [8208, 8107, 5607, 8143, 10604, 10604],
    37801: [8266, 8165, 5665, 8194, 10662, 10662],
    38001: [8324, 8223, 5723, 8244, 10720, 10720],
    38201: [8382, 8281, 5781, 8295, 10778, 10778],
    38401: [8440, 8339, 5839, 8346, 10836, 10836],
    38601: [8498, 8397, 5897, 8397, 10894, 10894],
    38801: [8556, 8455, 5955, 8448, 10952, 10952],
    39001: [8614, 8513, 6013, 8499, 11010, 11010],
    39201: [8672, 8571, 6071, 8550, 11068, 11068],
    39401: [8730, 8629, 6129, 8601, 11126, 11126],
    39601: [8788, 8687, 6187, 8651, 11184, 11184],
    39801: [8846, 8746, 6246, 8702, 11242, 11242],
    40001: [8904, 8803, 6303, 8753, 11300, 11300],
    40201: [8962, 8861, 6361, 8804, 11358, 11358],
    40401: [9020, 8919, 6419, 8855, 11416, 11416],
    40601: [9078, 8977, 6477, 8906, 11474, 11474],
    40801: [9136, 9035, 6535, 8957, 11532, 11532],
    41001: [9194, 9093, 6593, 9008, 11590, 11590],
    41201: [9252, 9151, 6651, 9058, 11648, 11648],
    41401: [9310, 9209, 6709, 9109, 11706, 11706],
    41601: [9368, 9267, 6767, 9160, 11764, 11764],
    41801: [9426, 9325, 6825, 9211, 11822, 11822],
    42001: [9484, 9383, 6883, 9262, 11880, 11880],
    42201: [9542, 9441, 6941, 9313, 11938, 11938],
    42401: [9600, 9499, 6999, 9364, 11996, 11996],
    42601: [9658, 9557, 7057, 9415, 12054, 12054],
    42801: [9716, 9615, 7115, 9465, 12112, 12112],
    43001: [9774, 9673, 7173, 9516, 12170, 12170],
    43201: [9832, 9731, 7231, 9567, 12228, 12228],
    43401: [9890, 9789, 7289, 9618, 12286, 12286],
    43601: [9948, 9847, 7347, 9669, 12344, 12344],
    43801: [10006, 9905, 7405, 9720, 12402, 12402],
    44001: [10064, 9963, 7463, 9771, 12460, 12460],
    44201: [10122, 10021, 7521, 9822, 12518, 12518],
    44401: [10180, 10079, 7579, 9872, 12576, 12576],
    44601: [10238, 10137, 7637, 9923, 12634, 12634],
    44801: [10296, 10196, 7696, 9974, 12692, 12692],
    45001: [10354, 10253, 7753, 10025, 12750, 12750],
    45201: [10412, 10311, 7811, 10076, 12808, 12808],
    45401: [10470, 10369, 7869, 10127, 12866, 12866],
    45601: [10528, 10427, 7927, 10178, 12924, 12924],
    45801: [10586, 10485, 7985, 10229, 12982, 12982],
    46001: [10644, 10543, 8043, 10279, 13040, 13040],
    46201: [10734, 10635, 8135, 10362, 13130, 13130],
    46401: [10832, 10730, 8230, 10453, 13228, 13228],
    46601: [10930, 10824, 8324, 10544, 13326, 13326],
    46801: [11028, 10918, 8418, 10635, 13424, 13424],
    47001: [11126, 11012, 8512, 10725, 13522, 13522],
    47201: [11224, 11104, 8604, 10816, 13620, 13620],
    47401: [11322, 11198, 8698, 10907, 13718, 13718],
    47601: [11420, 11293, 8793, 10998, 13816, 13816],
    47801: [11518, 11387, 8887, 11089, 13914, 13914],
    48001: [11616, 11481, 8981, 11180, 14012, 14012],
    48201: [11714, 11575, 9075, 11271, 14110, 14110],
    48401: [11812, 11670, 9170, 11362, 14208, 14208],
    48601: [11910, 11764, 9264, 11452, 14306, 14306],
    48801: [12008, 11856, 9356, 11543, 14404, 14404],
    49001: [12106, 11950, 9450, 11634, 14502, 14502],
    49201: [12204, 12044, 9544, 11725, 14600, 14600],
    49401: [12302, 12138, 9638, 11816, 14698, 14698],
    49601: [12400, 12233, 9733, 11907, 14796, 14796],
    49801: [12498, 12327, 9827, 11998, 14894, 14894],
    50001: [12596, 12421, 9927, 12089, 14992, 14992],
    50201: [12694, 12515, 10027, 12179, 15090, 15090],
    50401: [12792, 12607, 10125, 12270, 15188, 15188],
    50601: [12890, 12702, 10226, 12361, 15286, 15286],
    50801: [12988, 12796, 10326, 12452, 15384, 15384],
    51001: [13086, 12890, 10426, 12543, 15482, 15482],
    51201: [13184, 12984, 10526, 12634, 15580, 15580],
    51401: [13282, 13072, 10620, 12725, 15678, 15678],
    51601: [13380, 13170, 10724, 12816, 15776, 15776],
    51801: [13478, 13268, 10828, 12906, 15874, 15874],
    52001: [13576, 13366, 10932, 12997, 15972, 15972],
    52201: [13674, 13464, 11036, 13088, 16070, 16070],
    52401: [13772, 13562, 11140, 13179, 16168, 16168],
    52601: [13870, 13660, 11244, 13270, 16266, 16266],
    52801: [13968, 13758, 11348, 13361, 16364, 16364],
    53001: [14066, 13856, 11452, 13452, 16462, 16462],
    53201: [14164, 13954, 11556, 13543, 16560, 16560],
    53401: [14262, 14052, 11660, 13633, 16658, 16658],
    53601: [14360, 14150, 11764, 13724, 16756, 16756],
    53801: [14458, 14248, 11868, 13815, 16854, 16854],
    54001: [14556, 14346, 11972, 13906, 16952, 16952],
    54201: [14654, 14444, 12076, 13997, 17050, 17050],
    54401: [14755, 14546, 12184, 14088, 17148, 17148],
    54601: [14859, 14653, 12297, 14179, 17246, 17246],
    54801: [14963, 14755, 12405, 14269, 17344, 17344],
    55001: [15067, 14857, 12513, 14360, 17442, 17442],
    55201: [15171, 14963, 12625, 14451, 17540, 17540],
    55401: [15275, 15065, 12733, 14542, 17638, 17638],
    55601: [15379, 15167, 12841, 14633, 17736, 17736],
    55801: [15483, 15273, 12953, 14724, 17834, 17834],
    56001: [15587, 15375, 13061, 14815, 17932, 17932],
    56201: [15691, 15482, 13174, 14906, 18030, 18030],
    56401: [15795, 15584, 13282, 14997, 18128, 18128],
    56601: [15899, 15686, 13390, 15087, 18226, 18226],
    56801: [16003, 15792, 13502, 15178, 18324, 18324],
    57001: [16107, 15894, 13610, 15269, 18422, 18422],
    57201: [16211, 16000, 13722, 15360, 18520, 18520],
    57401: [16315, 16102, 13830, 15451, 18618, 18618],
    57601: [16419, 16204, 13938, 15542, 18716, 18716],
    57801: [16523, 16310, 14050, 15633, 18814, 18814],
    58001: [16627, 16413, 14159, 15723, 18912, 18912],
    58201: [16731, 16515, 14267, 15814, 19010, 19010],
    58401: [16835, 16621, 14379, 15905, 19108, 19108],
    58601: [16939, 16723, 14487, 15996, 19206, 19206],
    58801: [17043, 16829, 14599, 16087, 19304, 19304],
    59001: [17147, 16931, 14707, 16178, 19402, 19402],
    59201: [17251, 17033, 14815, 16269, 19500, 19500],
    59401: [17355, 17139, 14927, 16360, 19598, 19598],
    59601: [17459, 17241, 15035, 16450, 19696, 19696],
    59801: [17563, 17344, 15144, 16541, 19794, 19794],
    60001: [17667, 17450, 15256, 16632, 19892, 19892],
    60201: [17771, 17552, 15364, 16723, 19990, 19990],
    60401: [17875, 17658, 15476, 16814, 20088, 20088],
    60601: [17979, 17760, 15584, 16905, 20186, 20186],
    60801: [18083, 17862, 15692, 16996, 20284, 20284],
    61001: [18187, 17968, 15804, 17087, 20382, 20382],
    61201: [18291, 18070, 15912, 17177, 20480, 20480],
    61401: [18395, 18172, 16020, 17268, 20578, 20578],
    61601: [18499, 18279, 16133, 17359, 20676, 20676],
    61801: [18603, 18381, 16241, 17450, 20774, 20774],
    62001: [18707, 18487, 16353, 17541, 20872, 20872],
    62201: [18811, 18589, 16461, 17632, 20970, 20970],
    62401: [18915, 18691, 16569, 17723, 21068, 21068],
    62601: [19019, 18797, 16681, 17814, 21166, 21166],
    62801: [19123, 18899, 16789, 17904, 21264, 21264],
    63001: [19227, 19001, 16897, 17995, 21362, 21362],
    63201: [19331, 19108, 17010, 18086, 21460, 21460],
    63401: [19435, 19210, 17118, 18177, 21558, 21558],
    63601: [19539, 19316, 17230, 18268, 21656, 21656],
    63801: [19643, 19418, 17338, 18359, 21754, 21754],
    64001: [19747, 19520, 17446, 18450, 21852, 21852],
    64201: [19851, 19626, 17558, 18541, 21950, 21950],
    64401: [19955, 19728, 17666, 18631, 22048, 22048],
    64601: [20059, 19830, 17774, 18722, 22146, 22146],
    64801: [20163, 19936, 17886, 18813, 22244, 22244],
    65001: [20267, 20039, 17995, 18904, 22342, 22342],
    65201: [20371, 20145, 18107, 18995, 22440, 22440],
    65401: [20475, 20247, 18215, 19086, 22538, 22538],
    65601: [20579, 20349, 18323, 19177, 22636, 22636],
    65801: [20683, 20455, 18435, 19268, 22734, 22734],
    66001: [20787, 20557, 18543, 19358, 22832, 22832],
    66201: [20891, 20659, 18651, 19449, 22930, 22930],
    66401: [20995, 20765, 18763, 19540, 23028, 23028],
    66601: [21099, 20867, 18871, 19631, 23126, 23126],
    66801: [21203, 20974, 18984, 19722, 23224, 23224],
    67001: [21307, 21076, 19092, 19813, 23322, 23322],
    67201: [21411, 21178, 19200, 19904, 23420, 23420],
    67401: [21515, 21284, 19312, 19994, 23518, 23518],
    67601: [21619, 21386, 19420, 20085, 23616, 23616],
    67801: [21723, 21492, 19532, 20176, 23714, 23714],
    68001: [21827, 21594, 19640, 20267, 23812, 23812],
    68201: [21931, 21696, 19748, 20358, 23910, 23910],
    68401: [22035, 21803, 19861, 20449, 24008, 24008],
    68601: [22139, 21905, 19969, 20540, 24106, 24106],
    68801: [22243, 22007, 20077, 20631, 24204, 24204],
    69001: [22347, 22113, 20189, 20721, 24302, 24302],
    69201: [22451, 22215, 20297, 20812, 24400, 24400],
    69401: [22555, 22321, 20409, 20903, 24498, 24498],
    69601: [22659, 22423, 20517, 20994, 24596, 24596],
    69801: [22763, 22525, 20625, 21085, 24694, 24694],
    70001: [22867, 22631, 20737, 21176, 24792, 24792],
    70201: [22971, 22734, 20846, 21267, 24890, 24890],
    70401: [23075, 22836, 20954, 21358, 24988, 24988],
    70601: [23179, 22942, 21066, 21448, 25086, 25086],
    70801: [23283, 23044, 21174, 21539, 25184, 25184],
    71001: [23387, 23150, 21286, 21630, 25282, 25282],
    71201: [23491, 23252, 21394, 21721, 25380, 25380],
    71401: [23595, 23354, 21502, 21812, 25478, 25478],
    71601: [23699, 23460, 21614, 21903, 25576, 25576],
    71801: [23803, 23562, 21722, 21994, 25674, 25674],
    72001: [23907, 23665, 21831, 22085, 25772, 25772],
    72201: [24011, 23771, 21943, 22175, 25870, 25870],
    72401: [24115, 23873, 22051, 22266, 25968, 25968],
    72601: [24219, 23979, 22163, 22357, 26066, 26066],
    72801: [24323, 24081, 22271, 22448, 26164, 26164],
    73001: [24427, 24183, 22379, 22539, 26262, 26262],
    73201: [24531, 24289, 22491, 22630, 26360, 26360],
    73401: [24635, 24391, 22599, 22721, 26458, 26458],
    73601: [24739, 24493, 22707, 22812, 26556, 26556],
    73801: [24843, 24600, 22820, 22902, 26654, 26654],
    74001: [24947, 24702, 22928, 22993, 26752, 26752],
    74201: [25051, 24808, 23040, 23084, 26850, 26850],
    74401: [25155, 24910, 23148, 23175, 26948, 26948],
    74601: [25259, 25012, 23256, 23266, 27046, 27046],
    74801: [25363, 25118, 23368, 23357, 27144, 27144],
    75001: [25467, 25220, 23476, 23448, 27242, 27242],
    75201: [25571, 25322, 23584, 23539, 27340, 27340],
    75401: [25675, 25429, 23697, 23629, 27438, 27438],
    75601: [25779, 25531, 23805, 23720, 27536, 27536],
    75801: [25883, 25637, 23917, 23811, 27634, 27634],
    76001: [25987, 25739, 24025, 23902, 27732, 27732],
    76201: [26091, 25841, 24133, 23993, 27830, 27830],
    76401: [26195, 25947, 24245, 24084, 27928, 27928],
    76601: [26299, 26049, 24353, 24175, 28026, 28026],
    76801: [26403, 26155, 24465, 24266, 28124, 28124],
    77001: [26507, 26257, 24573, 24356, 28222, 28222],
    77201: [26611, 26360, 24682, 24447, 28320, 28320],
    77401: [26715, 26466, 24794, 24538, 28418, 28418],
    77601: [26819, 26568, 24902, 24629, 28516, 28516],
    77801: [26923, 26670, 25010, 24720, 28614, 28614],
    78001: [27027, 26776, 25122, 24811, 28712, 28712],
    78201: [27131, 26878, 25230, 24902, 28810, 28810],
    78401: [27235, 26984, 25342, 24993, 28908, 28908],
    78601: [27339, 27086, 25450, 25083, 29006, 29006],
    78801: [27443, 27188, 25558, 25174, 29104, 29104],
    79001: [27547, 27295, 25671, 25265, 29202, 29202],
    79201: [27651, 27397, 25779, 25356, 29300, 29300],
    79401: [27755, 27499, 25887, 25447, 29398, 29398],
    79601: [27859, 27605, 25999, 25538, 29496, 29496],
    79801: [27963, 27707, 26107, 25629, 29594, 29594],
    80001: [35, 35, 33, 32, 37, 37, '%'],
    82601: [36, 35, 33, 32, 37, 37, '%'],
    82801: [36, 35, 33, 33, 37, 37, '%'],
    83201: [36, 35, 34, 33, 37, 37, '%'],
    83401: [36, 35, 34, 33, 38, 38, '%'],
    84201: [36, 36, 34, 33, 38, 38, '%'],
    87201: [36, 36, 35, 33, 38, 38, '%'],
    87801: [37, 36, 35, 33, 38, 38, '%'],
    89601: [37, 37, 35, 33, 38, 38, '%'],
    89801: [37, 37, 35, 34, 38, 38, '%'],
    91401: [37, 37, 35, 34, 39, 39, '%'],
    91801: [37, 37, 36, 34, 39, 39, '%'],
    94001: [38, 37, 36, 34, 39, 39, '%'],
    96001: [38, 38, 36, 34, 39, 39, '%'],
    96801: [38, 38, 37, 34, 39, 39, '%'],
    98001: [38, 38, 37, 35, 39, 39, '%'],
    101001: [39, 38, 37, 35, 40, 40, '%'],
    102401: [39, 38, 38, 35, 40, 40, '%'],
    103201: [39, 39, 38, 35, 40, 40, '%'],
    107801: [39, 39, 38, 36, 40, 40, '%'],
    108601: [39, 39, 39, 36, 40, 40, '%'],
    109001: [40, 39, 39, 36, 40, 40, '%'],
    111601: [40, 40, 39, 36, 40, 40, '%'],
    113001: [40, 40, 39, 36, 41, 41, '%'],
    115801: [40, 40, 40, 36, 41, 41, '%'],
    118401: [41, 40, 40, 36, 41, 41, '%'],
    119801: [41, 40, 40, 37, 41, 41, '%'],
    121401: [41, 41, 40, 37, 41, 41, '%'],
    123801: [41, 41, 41, 37, 41, 41, '%'],
    128001: [41, 41, 41, 37, 42, 42, '%'],
    129801: [42, 41, 41, 37, 42, 42, '%'],
    133201: [42, 42, 42, 37, 42, 42, '%'],
    135001: [42, 42, 42, 38, 42, 42, '%'],
    147601: [43, 43, 43, 38, 43, 43, '%'],
    154401: [43, 43, 43, 39, 43, 43, '%'],
    174601: [44, 44, 44, 39, 44, 44, '%'],
    180401: [44, 44, 44, 40, 44, 44, '%'],
    213401: [45, 45, 45, 40, 45, 45, '%'],
    217001: [45, 45, 45, 41, 45, 45, '%'],
    272201: [45, 45, 45, 42, 45, 45, '%'],
    274401: [46, 46, 46, 42, 46, 46, '%'],
    365001: [46, 46, 46, 43, 46, 46, '%'],
    384201: [47, 47, 47, 43, 47, 47, '%'],
    553401: [47, 47, 47, 44, 47, 47, '%'],
    640201: [48, 48, 48, 44, 48, 48, '%'],
    1144601: [48, 48, 48, 45, 48, 48, '%'],
    1921001: [49, 49, 49, 45, 49, 49, '%']
  },
  30: {
    1: [0, 0, 0, 0, 0, 0],
    1701: [126, 0, 125, 1, 126, 28],
    1801: [137, 0, 133, 4, 137, 59],
    1901: [147, 0, 141, 6, 147, 90],
    2001: [158, 0, 150, 8, 158, 121],
    2101: [160, 0, 150, 10, 160, 152],
    2201: [171, 0, 158, 12, 183, 183],
    2301: [181, 0, 166, 14, 214, 214],
    2401: [192, 0, 175, 17, 245, 245],
    2501: [202, 0, 183, 19, 276, 276],
    2601: [213, 0, 191, 21, 307, 307],
    2701: [223, 0, 200, 23, 338, 338],
    2801: [225, 0, 200, 25, 369, 369],
    2901: [236, 0, 208, 27, 400, 400],
    3001: [246, 0, 216, 30, 431, 431],
    3101: [257, 0, 225, 32, 462, 462],
    3201: [267, 0, 233, 34, 493, 493],
    3301: [278, 0, 241, 36, 524, 524],
    3401: [280, 0, 241, 38, 555, 555],
    3501: [290, 0, 250, 40, 586, 586],
    3601: [301, 0, 258, 50, 617, 617],
    3701: [311, 0, 266, 71, 648, 648],
    3801: [322, 0, 275, 92, 679, 679],
    3901: [332, 0, 283, 113, 710, 710],
    4001: [334, 0, 283, 134, 733, 733],
    4101: [344, 0, 291, 155, 759, 759],
    4201: [354, 0, 300, 175, 785, 785],
    4301: [364, 0, 308, 196, 808, 808],
    4401: [374, 5, 317, 217, 834, 834],
    4501: [384, 28, 326, 238, 857, 857],
    4601: [386, 51, 328, 258, 883, 883],
    4701: [396, 74, 338, 279, 909, 909],
    4801: [406, 98, 348, 300, 932, 932],
    4901: [416, 121, 358, 321, 958, 958],
    5001: [426, 144, 368, 341, 981, 981],
    5101: [436, 167, 378, 362, 1007, 1007],
    5201: [447, 191, 388, 383, 1033, 1033],
    5301: [448, 211, 389, 403, 1056, 1056],
    5401: [458, 235, 399, 423, 1081, 1081],
    5501: [468, 258, 409, 443, 1103, 1103],
    5601: [478, 281, 419, 463, 1129, 1129],
    5701: [488, 304, 429, 484, 1154, 1154],
    5801: [498, 328, 439, 504, 1177, 1177],
    5901: [500, 351, 441, 524, 1202, 1202],
    6001: [511, 374, 451, 544, 1224, 1224],
    6101: [530, 397, 461, 564, 1250, 1250],
    6201: [549, 421, 471, 584, 1275, 1275],
    6301: [568, 444, 480, 605, 1298, 1298],
    6401: [586, 467, 490, 625, 1323, 1323],
    6501: [605, 488, 492, 645, 1345, 1345],
    6601: [624, 511, 502, 665, 1371, 1371],
    6701: [643, 534, 512, 685, 1396, 1396],
    6801: [661, 557, 522, 705, 1419, 1419],
    6901: [680, 581, 532, 725, 1444, 1444],
    7001: [699, 604, 542, 745, 1466, 1466],
    7101: [718, 627, 543, 766, 1492, 1492],
    7201: [737, 650, 553, 786, 1517, 1517],
    7301: [755, 674, 563, 806, 1540, 1540],
    7401: [774, 697, 573, 826, 1565, 1565],
    7501: [793, 720, 583, 846, 1587, 1587],
    7601: [812, 743, 593, 866, 1613, 1613],
    7701: [831, 764, 603, 887, 1638, 1638],
    7801: [849, 787, 604, 907, 1661, 1661],
    7901: [868, 803, 614, 927, 1686, 1686],
    8001: [887, 821, 623, 947, 1708, 1708],
    8101: [906, 839, 633, 967, 1734, 1734],
    8201: [925, 860, 643, 987, 1759, 1759],
    8301: [943, 878, 652, 1007, 1782, 1782],
    8401: [962, 896, 654, 1028, 1807, 1807],
    8501: [981, 914, 663, 1048, 1829, 1829],
    8601: [1000, 935, 673, 1068, 1855, 1855],
    8701: [1019, 953, 683, 1088, 1880, 1880],
    8801: [1037, 971, 692, 1108, 1903, 1903],
    8901: [1056, 989, 702, 1128, 1928, 1928],
    9001: [1075, 1010, 703, 1148, 1950, 1950],
    9101: [1094, 1028, 713, 1169, 1976, 1976],
    9201: [1113, 1045, 722, 1189, 2001, 2001],
    9301: [1131, 1066, 732, 1209, 2024, 2024],
    9401: [1150, 1083, 742, 1229, 2049, 2049],
    9501: [1169, 1101, 751, 1249, 2071, 2071],
    9601: [1188, 1119, 753, 1269, 2097, 2097],
    9701: [1207, 1139, 762, 1290, 2122, 2122],
    9801: [1225, 1156, 772, 1310, 2145, 2145],
    9901: [1244, 1174, 782, 1330, 2170, 2170],
    10001: [1263, 1192, 791, 1350, 2192, 2192],
    10101: [1282, 1212, 801, 1370, 2218, 2218],
    10201: [1301, 1229, 811, 1390, 2243, 2243],
    10301: [1319, 1247, 812, 1410, 2266, 2266],
    10401: [1338, 1265, 821, 1431, 2291, 2291],
    10501: [1357, 1285, 831, 1451, 2313, 2313],
    10601: [1376, 1303, 841, 1471, 2339, 2339],
    10701: [1395, 1320, 850, 1491, 2364, 2364],
    10801: [1413, 1338, 860, 1511, 2387, 2387],
    10901: [1432, 1358, 861, 1531, 2414, 2414],
    11001: [1451, 1376, 871, 1552, 2444, 2444],
    11101: [1470, 1393, 881, 1572, 2475, 2475],
    11201: [1489, 1413, 890, 1593, 2505, 2505],
    11301: [1508, 1431, 900, 1613, 2535, 2535],
    11401: [1528, 1449, 910, 1634, 2565, 2565],
    11501: [1547, 1466, 911, 1654, 2596, 2596],
    11601: [1566, 1487, 921, 1674, 2626, 2626],
    11701: [1585, 1504, 930, 1695, 2656, 2656],
    11801: [1604, 1522, 940, 1715, 2686, 2686],
    11901: [1623, 1540, 949, 1736, 2717, 2717],
    12001: [1642, 1560, 959, 1756, 2747, 2747],
    12101: [1661, 1577, 960, 1777, 2777, 2777],
    12201: [1680, 1595, 970, 1797, 2807, 2807],
    12301: [1699, 1613, 980, 1817, 2838, 2838],
    12401: [1718, 1633, 989, 1838, 2868, 2868],
    12501: [1738, 1650, 999, 1858, 2901, 2901],
    12601: [1757, 1668, 1009, 1879, 2934, 2934],
    12701: [1776, 1686, 1018, 1900, 2966, 2966],
    12801: [1795, 1706, 1020, 1920, 2999, 2999],
    12901: [1814, 1724, 1029, 1941, 3032, 3032],
    13001: [1839, 1746, 1039, 1965, 3067, 3067],
    13101: [1865, 1774, 1049, 1992, 3100, 3100],
    13201: [1892, 1799, 1060, 2019, 3133, 3133],
    13301: [1919, 1827, 1070, 2045, 3165, 3165],
    13401: [1946, 1852, 1072, 2072, 3198, 3198],
    13501: [1972, 1877, 1082, 2099, 3234, 3234],
    13601: [1999, 1905, 1092, 2126, 3266, 3266],
    13701: [2026, 1930, 1102, 2152, 3299, 3299],
    13801: [2052, 1958, 1113, 2179, 3332, 3332],
    13901: [2079, 1983, 1123, 2206, 3365, 3365],
    14001: [2105, 2008, 1124, 2231, 3399, 3399],
    14101: [2130, 2036, 1135, 2257, 3431, 3431],
    14201: [2156, 2061, 1145, 2283, 3462, 3462],
    14301: [2182, 2089, 1155, 2308, 3494, 3494],
    14401: [2207, 2114, 1165, 2334, 3526, 3526],
    14501: [2233, 2139, 1176, 2359, 3560, 3560],
    14601: [2258, 2167, 1178, 2385, 3591, 3591],
    14701: [2284, 2192, 1188, 2411, 3623, 3623],
    14801: [2310, 2220, 1198, 2436, 3655, 3655],
    14901: [2335, 2245, 1208, 2462, 3687, 3687],
    15001: [2361, 2271, 1218, 2487, 3721, 3721],
    15101: [2386, 2298, 1229, 2513, 3752, 3752],
    15201: [2412, 2323, 1239, 2539, 3784, 3784],
    15301: [2438, 2351, 1241, 2564, 3816, 3816],
    15401: [2463, 2376, 1251, 2590, 3847, 3847],
    15501: [2489, 2402, 1261, 2615, 3882, 3882],
    15601: [2515, 2429, 1271, 2641, 3913, 3913],
    15701: [2540, 2455, 1281, 2667, 3945, 3945],
    15801: [2566, 2482, 1292, 2692, 3977, 3977],
    15901: [2591, 2508, 1293, 2718, 4008, 4008],
    16001: [2617, 2533, 1304, 2743, 4042, 4042],
    16101: [2642, 2560, 1314, 2769, 4074, 4074],
    16201: [2668, 2586, 1324, 2795, 4106, 4106],
    16301: [2694, 2613, 1334, 2820, 4137, 4137],
    16401: [2719, 2639, 1344, 2846, 4169, 4169],
    16501: [2745, 2664, 1346, 2871, 4203, 4203],
    16601: [2770, 2692, 1357, 2897, 4235, 4235],
    16701: [2796, 2717, 1367, 2923, 4267, 4267],
    16801: [2822, 2744, 1377, 2948, 4298, 4298],
    16901: [2847, 2770, 1387, 2974, 4330, 4330],
    17001: [2873, 2795, 1397, 2999, 4364, 4364],
    17101: [2898, 2823, 1399, 3025, 4396, 4396],
    17201: [2924, 2848, 1409, 3051, 4428, 4428],
    17301: [2950, 2876, 1420, 3076, 4459, 4459],
    17401: [2975, 2901, 1430, 3102, 4491, 4491],
    17501: [3001, 2928, 1440, 3127, 4525, 4525],
    17601: [3026, 2954, 1450, 3153, 4557, 4557],
    17701: [3052, 2979, 1460, 3179, 4588, 4588],
    17801: [3078, 3007, 1462, 3204, 4620, 4620],
    17901: [3103, 3032, 1473, 3230, 4652, 4652],
    18001: [3129, 3060, 1483, 3255, 4686, 4686],
    18101: [3154, 3085, 1493, 3281, 4718, 4718],
    18201: [3180, 3110, 1503, 3307, 4749, 4749],
    18301: [3206, 3138, 1513, 3332, 4781, 4781],
    18401: [3231, 3163, 1515, 3358, 4813, 4813],
    18501: [3257, 3191, 1526, 3383, 4847, 4847],
    18601: [3282, 3216, 1536, 3409, 4878, 4878],
    18701: [3308, 3241, 1546, 3435, 4910, 4910],
    18801: [3334, 3269, 1556, 3460, 4942, 4942],
    18901: [3359, 3294, 1566, 3486, 4974, 4974],
    19001: [3385, 3322, 1568, 3511, 5008, 5008],
    19101: [3410, 3347, 1578, 3537, 5039, 5039],
    19201: [3436, 3372, 1588, 3563, 5071, 5071],
    19301: [3462, 3399, 1598, 3588, 5103, 5103],
    19401: [3487, 3423, 1607, 3614, 5134, 5134],
    19501: [3513, 3450, 1617, 3639, 5169, 5169],
    19601: [3538, 3474, 1618, 3665, 5200, 5200],
    19701: [3564, 3499, 1627, 3690, 5232, 5232],
    19801: [3590, 3526, 1636, 3716, 5264, 5264],
    19901: [3615, 3550, 1646, 3742, 5295, 5295],
    20001: [3666, 3601, 1664, 3793, 5361, 5361],
    20201: [3718, 3652, 1675, 3844, 5424, 5424],
    20401: [3769, 3704, 1693, 3895, 5490, 5490],
    20601: [3820, 3752, 1712, 3946, 5554, 5554],
    20801: [3871, 3804, 1722, 3998, 5617, 5617],
    21001: [3922, 3855, 1741, 4049, 5683, 5683],
    21201: [3974, 3906, 1760, 4100, 5746, 5746],
    21401: [4025, 3957, 1770, 4151, 5812, 5812],
    21601: [4076, 4006, 1789, 4202, 5875, 5875],
    21801: [4127, 4057, 1807, 4254, 5939, 5939],
    22001: [4179, 4108, 1818, 4305, 6005, 6005],
    22201: [4232, 4159, 1836, 4358, 6070, 6070],
    22401: [4285, 4213, 1855, 4411, 6138, 6138],
    22601: [4337, 4264, 1874, 4464, 6203, 6203],
    22801: [4390, 4315, 1915, 4517, 6268, 6268],
    23001: [4443, 4369, 1959, 4570, 6335, 6335],
    23201: [4496, 4420, 2000, 4622, 6400, 6400],
    23401: [4549, 4471, 2041, 4675, 6468, 6468],
    23601: [4602, 4525, 2085, 4728, 6533, 6533],
    23801: [4654, 4576, 2126, 4781, 6598, 6598],
    24001: [4707, 4627, 2167, 4834, 6665, 6665],
    24201: [4760, 4679, 2209, 4887, 6730, 6730],
    24401: [4813, 4732, 2252, 4940, 6798, 6798],
    24601: [4866, 4783, 2293, 4992, 6863, 6863],
    24801: [4919, 4835, 2335, 5045, 6928, 6928],
    25001: [4972, 4888, 2388, 5098, 6995, 6995],
    25201: [5024, 4939, 2439, 5151, 7060, 7060],
    25401: [5077, 4991, 2491, 5204, 7128, 7128],
    25601: [5130, 5044, 2544, 5257, 7193, 7193],
    25801: [5183, 5095, 2595, 5309, 7258, 7258],
    26001: [5236, 5147, 2647, 5362, 7325, 7325],
    26201: [5289, 5200, 2700, 5415, 7390, 7390],
    26401: [5342, 5251, 2751, 5468, 7458, 7458],
    26601: [5394, 5303, 2803, 5521, 7523, 7523],
    26801: [5447, 5354, 2854, 5574, 7588, 7588],
    27001: [5500, 5407, 2907, 5627, 7655, 7655],
    27201: [5553, 5459, 2959, 5679, 7720, 7720],
    27401: [5606, 5510, 3010, 5732, 7788, 7788],
    27601: [5659, 5563, 3063, 5785, 7853, 7853],
    27801: [5711, 5615, 3115, 5838, 7918, 7918],
    28001: [5764, 5666, 3166, 5891, 7985, 7985],
    28201: [5817, 5719, 3219, 5944, 8050, 8050],
    28401: [5870, 5771, 3271, 5997, 8118, 8118],
    28601: [5923, 5822, 3322, 6049, 8183, 8183],
    28801: [5976, 5875, 3375, 6102, 8248, 8248],
    29001: [6029, 5927, 3427, 6155, 8315, 8315],
    29201: [6081, 5978, 3478, 6208, 8380, 8380],
    29401: [6134, 6033, 3533, 6261, 8448, 8448],
    29601: [6187, 6085, 3585, 6314, 8513, 8513],
    29801: [6240, 6138, 3638, 6366, 8578, 8578],
    30001: [6293, 6190, 3690, 6419, 8645, 8645],
    30201: [6346, 6245, 3745, 6472, 8710, 8710],
    30401: [6399, 6298, 3798, 6525, 8778, 8778],
    30601: [6451, 6350, 3850, 6578, 8843, 8843],
    30801: [6504, 6405, 3905, 6631, 8908, 8908],
    31001: [6557, 6458, 3958, 6684, 8975, 8975],
    31201: [6610, 6510, 4010, 6736, 9040, 9040],
    31401: [6663, 6565, 4065, 6789, 9108, 9108],
    31601: [6715, 6615, 4115, 6842, 9170, 9170],
    31801: [6768, 6668, 4168, 6895, 9230, 9230],
    32001: [6821, 6720, 4220, 6947, 9290, 9290],
    32201: [6873, 6773, 4273, 7000, 9350, 9350],
    32401: [6928, 6828, 4328, 7052, 9410, 9410],
    32601: [6988, 6888, 4388, 7105, 9470, 9470],
    32801: [7048, 6948, 4448, 7158, 9530, 9530],
    33001: [7108, 7008, 4508, 7210, 9590, 9590],
    33201: [7168, 7068, 4568, 7263, 9650, 9650],
    33401: [7228, 7128, 4628, 7316, 9710, 9710],
    33601: [7288, 7188, 4688, 7368, 9770, 9770],
    33801: [7348, 7248, 4748, 7421, 9830, 9830],
    34001: [7408, 7308, 4808, 7473, 9890, 9890],
    34201: [7468, 7368, 4868, 7526, 9950, 9950],
    34401: [7528, 7428, 4928, 7579, 10010, 10010],
    34601: [7588, 7488, 4988, 7631, 10070, 10070],
    34801: [7648, 7548, 5048, 7684, 10130, 10130],
    35001: [7708, 7608, 5108, 7736, 10190, 10190],
    35201: [7768, 7668, 5168, 7789, 10250, 10250],
    35401: [7828, 7728, 5228, 7842, 10310, 10310],
    35601: [7888, 7788, 5288, 7894, 10370, 10370],
    35801: [7948, 7848, 5348, 7947, 10430, 10430],
    36001: [8008, 7908, 5408, 8000, 10490, 10490],
    36201: [8068, 7968, 5468, 8052, 10550, 10550],
    36401: [8128, 8028, 5528, 8105, 10610, 10610],
    36601: [8188, 8088, 5588, 8157, 10670, 10670],
    36801: [8248, 8148, 5648, 8210, 10730, 10730],
    37001: [8308, 8208, 5708, 8263, 10790, 10790],
    37201: [8368, 8268, 5768, 8315, 10850, 10850],
    37401: [8428, 8328, 5828, 8368, 10910, 10910],
    37601: [8488, 8388, 5888, 8421, 10970, 10970],
    37801: [8548, 8448, 5948, 8473, 11030, 11030],
    38001: [8608, 8508, 6008, 8526, 11090, 11090],
    38201: [8668, 8568, 6068, 8578, 11150, 11150],
    38401: [8728, 8628, 6128, 8631, 11210, 11210],
    38601: [8788, 8688, 6188, 8684, 11270, 11270],
    38801: [8848, 8748, 6248, 8736, 11330, 11330],
    39001: [8908, 8808, 6308, 8789, 11390, 11390],
    39201: [8968, 8868, 6368, 8841, 11450, 11450],
    39401: [9028, 8928, 6428, 8894, 11510, 11510],
    39601: [9088, 8988, 6488, 8947, 11570, 11570],
    39801: [9148, 9048, 6548, 8999, 11630, 11630],
    40001: [9208, 9108, 6608, 9052, 11690, 11690],
    40201: [9268, 9168, 6668, 9105, 11750, 11750],
    40401: [9328, 9228, 6728, 9157, 11810, 11810],
    40601: [9388, 9288, 6788, 9210, 11870, 11870],
    40801: [9448, 9348, 6848, 9262, 11930, 11930],
    41001: [9508, 9408, 6908, 9315, 11990, 11990],
    41201: [9568, 9468, 6968, 9368, 12050, 12050],
    41401: [9628, 9528, 7028, 9420, 12110, 12110],
    41601: [9688, 9588, 7088, 9473, 12170, 12170],
    41801: [9748, 9648, 7148, 9525, 12230, 12230],
    42001: [9808, 9708, 7208, 9578, 12290, 12290],
    42201: [9868, 9768, 7268, 9631, 12350, 12350],
    42401: [9928, 9828, 7328, 9683, 12410, 12410],
    42601: [9988, 9888, 7388, 9736, 12470, 12470],
    42801: [10048, 9948, 7448, 9789, 12530, 12530],
    43001: [10108, 10008, 7508, 9841, 12590, 12590],
    43201: [10168, 10068, 7568, 9894, 12650, 12650],
    43401: [10228, 10128, 7628, 9946, 12710, 12710],
    43601: [10288, 10188, 7688, 9999, 12770, 12770],
    43801: [10348, 10248, 7748, 10052, 12830, 12830],
    44001: [10408, 10308, 7808, 10104, 12890, 12890],
    44201: [10468, 10368, 7868, 10157, 12950, 12950],
    44401: [10528, 10428, 7928, 10209, 13010, 13010],
    44601: [10588, 10488, 7988, 10262, 13070, 13070],
    44801: [10648, 10548, 8048, 10315, 13130, 13130],
    45001: [10708, 10608, 8108, 10367, 13190, 13190],
    45201: [10768, 10668, 8168, 10420, 13250, 13250],
    45401: [10828, 10728, 8228, 10473, 13310, 13310],
    45601: [10888, 10788, 8288, 10525, 13370, 13370],
    45801: [10948, 10848, 8348, 10578, 13430, 13430],
    46001: [11008, 10908, 8408, 10630, 13490, 13490],
    46201: [11100, 11003, 8503, 10715, 13582, 13582],
    46401: [11200, 11100, 8600, 10807, 13682, 13682],
    46601: [11300, 11198, 8698, 10900, 13782, 13782],
    46801: [11400, 11295, 8795, 10993, 13882, 13882],
    47001: [11500, 11393, 8893, 11085, 13982, 13982],
    47201: [11600, 11488, 8988, 11178, 14082, 14082],
    47401: [11700, 11585, 9085, 11270, 14182, 14182],
    47601: [11800, 11683, 9183, 11363, 14282, 14282],
    47801: [11900, 11780, 9280, 11456, 14382, 14382],
    48001: [12000, 11878, 9378, 11548, 14482, 14482],
    48201: [12100, 11975, 9475, 11641, 14582, 14582],
    48401: [12200, 12073, 9573, 11733, 14682, 14682],
    48601: [12300, 12170, 9670, 11826, 14782, 14782],
    48801: [12400, 12265, 9765, 11919, 14882, 14882],
    49001: [12500, 12363, 9863, 12011, 14982, 14982],
    49201: [12600, 12460, 9960, 12104, 15082, 15082],
    49401: [12700, 12558, 10058, 12197, 15182, 15182],
    49601: [12800, 12655, 10155, 12289, 15282, 15282],
    49801: [12900, 12753, 10253, 12382, 15382, 15382],
    50001: [13000, 12850, 10356, 12474, 15482, 15482],
    50201: [13100, 12948, 10460, 12567, 15582, 15582],
    50401: [13200, 13043, 10561, 12660, 15682, 15682],
    50601: [13300, 13140, 10664, 12752, 15782, 15782],
    50801: [13400, 13238, 10768, 12845, 15882, 15882],
    51001: [13500, 13335, 10871, 12938, 15982, 15982],
    51201: [13600, 13433, 10975, 13030, 16082, 16082],
    51401: [13700, 13523, 11071, 13123, 16182, 16182],
    51601: [13800, 13623, 11177, 13215, 16282, 16282],
    51801: [13900, 13723, 11283, 13308, 16382, 16382],
    52001: [14000, 13823, 11389, 13401, 16482, 16482],
    52201: [14100, 13923, 11495, 13493, 16582, 16582],
    52401: [14200, 14023, 11601, 13586, 16682, 16682],
    52601: [14300, 14123, 11707, 13678, 16782, 16782],
    52801: [14400, 14223, 11813, 13771, 16882, 16882],
    53001: [14500, 14323, 11919, 13864, 16982, 16982],
    53201: [14600, 14423, 12025, 13956, 17082, 17082],
    53401: [14700, 14523, 12131, 14049, 17182, 17182],
    53601: [14800, 14623, 12237, 14142, 17282, 17282],
    53801: [14900, 14723, 12343, 14234, 17382, 17382],
    54001: [15000, 14823, 12449, 14327, 17482, 17482],
    54201: [15100, 14923, 12555, 14419, 17582, 17582],
    54401: [15203, 15028, 12666, 14512, 17682, 17682],
    54601: [15309, 15136, 12780, 14605, 17782, 17782],
    54801: [15415, 15240, 12890, 14697, 17882, 17882],
    55001: [15521, 15344, 13000, 14790, 17982, 17982],
    55201: [15627, 15453, 13115, 14882, 18082, 18082],
    55401: [15733, 15557, 13225, 14975, 18182, 18182],
    55601: [15839, 15661, 13335, 15068, 18282, 18282],
    55801: [15945, 15769, 13449, 15160, 18382, 18382],
    56001: [16051, 15873, 13559, 15253, 18482, 18482],
    56201: [16157, 15982, 13674, 15346, 18582, 18582],
    56401: [16263, 16086, 13784, 15438, 18682, 18682],
    56601: [16369, 16190, 13894, 15531, 18782, 18782],
    56801: [16475, 16298, 14008, 15623, 18882, 18882],
    57001: [16581, 16403, 14119, 15716, 18982, 18982],
    57201: [16687, 16511, 14233, 15809, 19082, 19082],
    57401: [16793, 16615, 14343, 15901, 19182, 19182],
    57601: [16899, 16719, 14453, 15994, 19282, 19282],
    57801: [17005, 16828, 14568, 16086, 19382, 19382],
    58001: [17111, 16932, 14678, 16179, 19482, 19482],
    58201: [17217, 17036, 14788, 16272, 19582, 19582],
    58401: [17323, 17144, 14902, 16364, 19682, 19682],
    58601: [17429, 17248, 15012, 16457, 19782, 19782],
    58801: [17535, 17357, 15127, 16550, 19882, 19882],
    59001: [17641, 17461, 15237, 16642, 19982, 19982],
    59201: [17747, 17565, 15347, 16735, 20082, 20082],
    59401: [17853, 17673, 15461, 16827, 20182, 20182],
    59601: [17959, 17778, 15572, 16920, 20282, 20282],
    59801: [18065, 17882, 15682, 17013, 20382, 20382],
    60001: [18171, 17990, 15796, 17105, 20482, 20482],
    60201: [18277, 18094, 15906, 17198, 20582, 20582],
    60401: [18383, 18203, 16021, 17291, 20682, 20682],
    60601: [18489, 18307, 16131, 17383, 20782, 20782],
    60801: [18595, 18411, 16241, 17476, 20882, 20882],
    61001: [18701, 18519, 16355, 17568, 20982, 20982],
    61201: [18807, 18623, 16465, 17661, 21082, 21082],
    61401: [18913, 18728, 16576, 17754, 21182, 21182],
    61601: [19019, 18836, 16690, 17846, 21282, 21282],
    61801: [19125, 18940, 16800, 17939, 21382, 21382],
    62001: [19231, 19048, 16914, 18031, 21482, 21482],
    62201: [19337, 19153, 17025, 18124, 21582, 21582],
    62401: [19443, 19257, 17135, 18217, 21682, 21682],
    62601: [19549, 19365, 17249, 18309, 21782, 21782],
    62801: [19655, 19469, 17359, 18402, 21882, 21882],
    63001: [19761, 19573, 17469, 18495, 21982, 21982],
    63201: [19867, 19682, 17584, 18587, 22082, 22082],
    63401: [19973, 19786, 17694, 18680, 22182, 22182],
    63601: [20079, 19894, 17808, 18772, 22282, 22282],
    63801: [20185, 19998, 17918, 18865, 22382, 22382],
    64001: [20291, 20103, 18029, 18958, 22482, 22482],
    64201: [20397, 20211, 18143, 19050, 22582, 22582],
    64401: [20503, 20315, 18253, 19143, 22682, 22682],
    64601: [20609, 20419, 18363, 19235, 22782, 22782],
    64801: [20715, 20528, 18478, 19328, 22882, 22882],
    65001: [20821, 20632, 18588, 19421, 22982, 22982],
    65201: [20927, 20740, 18702, 19513, 23082, 23082],
    65401: [21033, 20844, 18812, 19606, 23182, 23182],
    65601: [21139, 20948, 18922, 19699, 23282, 23282],
    65801: [21245, 21057, 19037, 19791, 23382, 23382],
    66001: [21351, 21161, 19147, 19884, 23482, 23482],
    66201: [21457, 21265, 19257, 19976, 23582, 23582],
    66401: [21563, 21373, 19371, 20069, 23682, 23682],
    66601: [21669, 21478, 19482, 20162, 23782, 23782],
    66801: [21775, 21586, 19596, 20254, 23882, 23882],
    67001: [21881, 21690, 19706, 20347, 23982, 23982],
    67201: [21987, 21794, 19816, 20439, 24082, 24082],
    67401: [22093, 21903, 19931, 20532, 24182, 24182],
    67601: [22199, 22007, 20041, 20625, 24282, 24282],
    67801: [22305, 22115, 20155, 20717, 24382, 24382],
    68001: [22411, 22219, 20265, 20810, 24482, 24482],
    68201: [22517, 22323, 20375, 20903, 24582, 24582],
    68401: [22623, 22432, 20490, 20995, 24682, 24682],
    68601: [22729, 22536, 20600, 21088, 24782, 24782],
    68801: [22835, 22640, 20710, 21180, 24882, 24882],
    69001: [22941, 22748, 20824, 21273, 24982, 24982],
    69201: [23047, 22853, 20935, 21366, 25082, 25082],
    69401: [23153, 22961, 21049, 21458, 25182, 25182],
    69601: [23259, 23065, 21159, 21551, 25282, 25282],
    69801: [23365, 23169, 21269, 21644, 25382, 25382],
    70001: [23471, 23278, 21384, 21736, 25482, 25482],
    70201: [23577, 23382, 21494, 21829, 25582, 25582],
    70401: [23683, 23486, 21604, 21921, 25682, 25682],
    70601: [23789, 23594, 21718, 22014, 25782, 25782],
    70801: [23895, 23698, 21828, 22107, 25882, 25882],
    71001: [24001, 23807, 21943, 22199, 25982, 25982],
    71201: [24107, 23911, 22053, 22292, 26082, 26082],
    71401: [24213, 24015, 22163, 22385, 26182, 26182],
    71601: [24319, 24123, 22277, 22477, 26282, 26282],
    71801: [24425, 24228, 22388, 22570, 26382, 26382],
    72001: [24531, 24332, 22498, 22662, 26482, 26482],
    72201: [24637, 24440, 22612, 22755, 26582, 26582],
    72401: [24743, 24544, 22722, 22848, 26682, 26682],
    72601: [24849, 24653, 22837, 22940, 26782, 26782],
    72801: [24955, 24757, 22947, 23033, 26882, 26882],
    73001: [25061, 24861, 23057, 23125, 26982, 26982],
    73201: [25167, 24969, 23171, 23218, 27082, 27082],
    73401: [25273, 25073, 23281, 23311, 27182, 27182],
    73601: [25379, 25178, 23392, 23403, 27282, 27282],
    73801: [25485, 25286, 23506, 23496, 27382, 27382],
    74001: [25591, 25390, 23616, 23588, 27482, 27482],
    74201: [25697, 25498, 23730, 23681, 27582, 27582],
    74401: [25803, 25603, 23841, 23774, 27682, 27682],
    74601: [25909, 25707, 23951, 23866, 27782, 27782],
    74801: [26015, 25815, 24065, 23959, 27882, 27882],
    75001: [26121, 25919, 24175, 24052, 27982, 27982],
    75201: [26227, 26023, 24285, 24144, 28082, 28082],
    75401: [26333, 26132, 24400, 24237, 28182, 28182],
    75601: [26439, 26236, 24510, 24329, 28282, 28282],
    75801: [26545, 26344, 24624, 24422, 28382, 28382],
    76001: [26651, 26448, 24734, 24515, 28482, 28482],
    76201: [26757, 26553, 24845, 24607, 28582, 28582],
    76401: [26863, 26661, 24959, 24700, 28682, 28682],
    76601: [26969, 26765, 25069, 24792, 28782, 28782],
    76801: [27075, 26873, 25183, 24885, 28882, 28882],
    77001: [27181, 26978, 25294, 24978, 28982, 28982],
    77201: [27287, 27082, 25404, 25070, 29082, 29082],
    77401: [27393, 27190, 25518, 25163, 29182, 29182],
    77601: [27499, 27294, 25628, 25256, 29282, 29282],
    77801: [27605, 27398, 25738, 25348, 29382, 29382],
    78001: [27711, 27507, 25853, 25441, 29482, 29482],
    78201: [27817, 27611, 25963, 25533, 29582, 29582],
    78401: [27923, 27719, 26077, 25626, 29682, 29682],
    78601: [28029, 27823, 26187, 25719, 29782, 29782],
    78801: [28135, 27928, 26298, 25811, 29882, 29882],
    79001: [28241, 28036, 26412, 25904, 29982, 29982],
    79201: [28347, 28140, 26522, 25997, 30082, 30082],
    79401: [28453, 28244, 26632, 26089, 30182, 30182],
    79601: [28559, 28353, 26747, 26182, 30282, 30282],
    79801: [28665, 28457, 26857, 26274, 30382, 30382],
    80001: [36, 36, 34, 33, 38, 38, '%'],
    83201: [37, 36, 34, 33, 38, 38, '%'],
    83401: [37, 36, 35, 33, 38, 38, '%'],
    83601: [37, 36, 35, 33, 39, 39, '%'],
    84001: [37, 36, 35, 34, 39, 39, '%'],
    84401: [37, 37, 35, 34, 39, 39, '%'],
    87401: [37, 37, 36, 34, 39, 39, '%'],
    88601: [38, 37, 36, 34, 39, 39, '%'],
    90001: [38, 38, 36, 34, 39, 39, '%'],
    91201: [38, 38, 36, 35, 39, 39, '%'],
    91401: [38, 38, 36, 35, 40, 40, '%'],
    92001: [38, 38, 37, 35, 40, 40, '%'],
    94601: [39, 38, 37, 35, 40, 40, '%'],
    96201: [39, 39, 37, 35, 40, 40, '%'],
    97001: [39, 39, 38, 35, 40, 40, '%'],
    99601: [39, 39, 38, 36, 40, 40, '%'],
    101201: [39, 39, 38, 36, 41, 41, '%'],
    101601: [40, 39, 38, 36, 41, 41, '%'],
    102601: [40, 39, 39, 36, 41, 41, '%'],
    103401: [40, 40, 39, 36, 41, 41, '%'],
    108801: [40, 40, 40, 36, 41, 41, '%'],
    109801: [41, 40, 40, 37, 41, 41, '%'],
    111801: [41, 41, 40, 37, 41, 41, '%'],
    113001: [41, 41, 40, 37, 42, 42, '%'],
    116001: [41, 41, 41, 37, 42, 42, '%'],
    119401: [42, 41, 41, 37, 42, 42, '%'],
    121601: [42, 42, 41, 37, 42, 42, '%'],
    122201: [42, 42, 41, 38, 42, 42, '%'],
    124001: [42, 42, 42, 38, 42, 42, '%'],
    128201: [42, 42, 42, 38, 43, 43, '%'],
    130801: [43, 42, 42, 38, 43, 43, '%'],
    133401: [43, 43, 43, 38, 43, 43, '%'],
    137801: [43, 43, 43, 39, 43, 43, '%'],
    147801: [44, 44, 44, 39, 44, 44, '%'],
    158201: [44, 44, 44, 40, 44, 44, '%'],
    174801: [45, 45, 45, 40, 45, 45, '%'],
    185401: [45, 45, 45, 41, 45, 45, '%'],
    213601: [46, 46, 46, 41, 46, 46, '%'],
    224001: [46, 46, 46, 42, 46, 46, '%'],
    274601: [47, 47, 47, 42, 47, 47, '%'],
    282801: [47, 47, 47, 43, 47, 47, '%'],
    383401: [47, 47, 47, 44, 47, 47, '%'],
    384601: [48, 48, 48, 44, 48, 48, '%'],
    595601: [48, 48, 48, 45, 48, 48, '%'],
    641001: [49, 49, 49, 45, 49, 49, '%'],
    1332201: [49, 49, 49, 46, 49, 49, '%'],
    1923401: [50, 50, 50, 46, 50, 50, '%']
  },
  31: {
    1: [0, 0, 0, 0, 0, 0],
    1701: [126, 0, 125, 1, 126, 29],
    1801: [137, 0, 133, 4, 137, 61],
    1901: [147, 0, 141, 6, 147, 93],
    2001: [158, 0, 150, 8, 158, 125],
    2101: [160, 0, 150, 10, 160, 157],
    2201: [171, 0, 158, 12, 189, 189],
    2301: [181, 0, 166, 14, 221, 221],
    2401: [192, 0, 175, 17, 253, 253],
    2501: [202, 0, 183, 19, 285, 285],
    2601: [213, 0, 191, 21, 317, 317],
    2701: [223, 0, 200, 23, 349, 349],
    2801: [225, 0, 200, 25, 381, 381],
    2901: [236, 0, 208, 27, 413, 413],
    3001: [246, 0, 216, 30, 445, 445],
    3101: [257, 0, 225, 32, 477, 477],
    3201: [267, 0, 233, 34, 509, 509],
    3301: [278, 0, 241, 36, 541, 541],
    3401: [280, 0, 241, 38, 573, 573],
    3501: [290, 0, 250, 40, 605, 605],
    3601: [301, 0, 258, 50, 637, 637],
    3701: [311, 0, 266, 72, 669, 669],
    3801: [322, 0, 275, 94, 701, 701],
    3901: [332, 0, 283, 115, 733, 733],
    4001: [334, 0, 283, 137, 757, 757],
    4101: [344, 0, 291, 158, 783, 783],
    4201: [354, 0, 300, 180, 810, 810],
    4301: [364, 0, 308, 201, 834, 834],
    4401: [374, 5, 317, 222, 861, 861],
    4501: [384, 29, 326, 244, 885, 885],
    4601: [386, 53, 328, 265, 911, 911],
    4701: [396, 77, 338, 287, 938, 938],
    4801: [406, 101, 348, 308, 962, 962],
    4901: [416, 125, 358, 329, 989, 989],
    5001: [426, 149, 368, 351, 1013, 1013],
    5101: [436, 173, 378, 372, 1039, 1039],
    5201: [447, 197, 388, 394, 1066, 1066],
    5301: [448, 218, 389, 415, 1090, 1090],
    5401: [458, 242, 399, 435, 1116, 1116],
    5501: [468, 266, 409, 456, 1139, 1139],
    5601: [478, 290, 419, 477, 1165, 1165],
    5701: [488, 314, 429, 498, 1191, 1191],
    5801: [498, 338, 439, 519, 1215, 1215],
    5901: [507, 362, 441, 539, 1241, 1241],
    6001: [526, 386, 451, 560, 1264, 1264],
    6101: [545, 410, 461, 581, 1290, 1290],
    6201: [565, 434, 471, 602, 1316, 1316],
    6301: [584, 458, 480, 623, 1340, 1340],
    6401: [604, 482, 490, 643, 1366, 1366],
    6501: [623, 503, 492, 664, 1389, 1389],
    6601: [642, 527, 502, 685, 1415, 1415],
    6701: [662, 551, 512, 706, 1441, 1441],
    6801: [681, 575, 522, 727, 1465, 1465],
    6901: [701, 600, 532, 747, 1491, 1491],
    7001: [720, 623, 542, 768, 1514, 1514],
    7101: [740, 647, 543, 789, 1540, 1540],
    7201: [759, 671, 553, 810, 1566, 1566],
    7301: [778, 695, 563, 831, 1590, 1590],
    7401: [798, 719, 573, 851, 1616, 1616],
    7501: [817, 743, 583, 872, 1639, 1639],
    7601: [837, 767, 593, 893, 1665, 1665],
    7701: [856, 789, 603, 914, 1691, 1691],
    7801: [875, 813, 604, 935, 1715, 1715],
    7901: [895, 829, 614, 956, 1741, 1741],
    8001: [914, 847, 623, 976, 1764, 1764],
    8101: [934, 866, 633, 997, 1790, 1790],
    8201: [953, 887, 643, 1018, 1816, 1816],
    8301: [972, 906, 652, 1039, 1840, 1840],
    8401: [992, 925, 654, 1060, 1866, 1866],
    8501: [1011, 943, 663, 1080, 1889, 1889],
    8601: [1031, 965, 673, 1101, 1915, 1915],
    8701: [1050, 983, 683, 1122, 1941, 1941],
    8801: [1069, 1002, 692, 1143, 1965, 1965],
    8901: [1089, 1021, 702, 1164, 1991, 1991],
    9001: [1108, 1042, 703, 1184, 2014, 2014],
    9101: [1128, 1061, 713, 1205, 2040, 2040],
    9201: [1147, 1079, 722, 1226, 2066, 2066],
    9301: [1167, 1100, 732, 1247, 2090, 2090],
    9401: [1186, 1118, 742, 1268, 2116, 2116],
    9501: [1205, 1136, 751, 1288, 2139, 2139],
    9601: [1225, 1155, 753, 1309, 2165, 2165],
    9701: [1244, 1176, 762, 1330, 2191, 2191],
    9801: [1264, 1194, 772, 1351, 2215, 2215],
    9901: [1283, 1212, 782, 1372, 2241, 2241],
    10001: [1302, 1230, 791, 1392, 2264, 2264],
    10101: [1322, 1251, 801, 1413, 2290, 2290],
    10201: [1341, 1269, 811, 1434, 2316, 2316],
    10301: [1361, 1288, 812, 1455, 2340, 2340],
    10401: [1380, 1306, 821, 1476, 2366, 2366],
    10501: [1399, 1327, 831, 1496, 2389, 2389],
    10601: [1419, 1345, 841, 1517, 2415, 2415],
    10701: [1438, 1363, 850, 1538, 2441, 2441],
    10801: [1458, 1381, 860, 1559, 2465, 2465],
    10901: [1477, 1402, 861, 1580, 2493, 2493],
    11001: [1497, 1420, 871, 1601, 2524, 2524],
    11101: [1517, 1439, 881, 1622, 2556, 2556],
    11201: [1536, 1459, 890, 1643, 2587, 2587],
    11301: [1556, 1478, 900, 1664, 2618, 2618],
    11401: [1576, 1496, 910, 1685, 2649, 2649],
    11501: [1595, 1514, 911, 1706, 2681, 2681],
    11601: [1615, 1535, 921, 1727, 2712, 2712],
    11701: [1635, 1553, 930, 1749, 2743, 2743],
    11801: [1654, 1571, 940, 1770, 2774, 2774],
    11901: [1674, 1590, 949, 1791, 2806, 2806],
    12001: [1694, 1610, 959, 1812, 2837, 2837],
    12101: [1713, 1629, 960, 1833, 2868, 2868],
    12201: [1733, 1647, 970, 1854, 2899, 2899],
    12301: [1753, 1665, 980, 1875, 2931, 2931],
    12401: [1772, 1686, 989, 1896, 2962, 2962],
    12501: [1792, 1704, 999, 1917, 2996, 2996],
    12601: [1812, 1722, 1009, 1939, 3030, 3030],
    12701: [1832, 1741, 1018, 1960, 3064, 3064],
    12801: [1852, 1761, 1020, 1981, 3097, 3097],
    12901: [1872, 1780, 1029, 2002, 3131, 3131],
    13001: [1897, 1803, 1039, 2028, 3168, 3168],
    13101: [1924, 1832, 1049, 2055, 3202, 3202],
    13201: [1952, 1858, 1060, 2083, 3235, 3235],
    13301: [1979, 1886, 1070, 2110, 3269, 3269],
    13401: [2007, 1913, 1072, 2138, 3303, 3303],
    13501: [2035, 1939, 1082, 2166, 3340, 3340],
    13601: [2062, 1967, 1092, 2193, 3373, 3373],
    13701: [2090, 1993, 1102, 2221, 3407, 3407],
    13801: [2117, 2022, 1113, 2248, 3441, 3441],
    13901: [2145, 2048, 1123, 2276, 3475, 3475],
    14001: [2172, 2074, 1124, 2302, 3510, 3510],
    14101: [2198, 2103, 1135, 2329, 3543, 3543],
    14201: [2224, 2129, 1145, 2355, 3576, 3576],
    14301: [2251, 2157, 1155, 2382, 3609, 3609],
    14401: [2277, 2183, 1165, 2408, 3642, 3642],
    14501: [2304, 2209, 1176, 2435, 3677, 3677],
    14601: [2330, 2238, 1178, 2461, 3710, 3710],
    14701: [2357, 2264, 1188, 2488, 3742, 3742],
    14801: [2383, 2293, 1198, 2514, 3775, 3775],
    14901: [2410, 2319, 1208, 2541, 3808, 3808],
    15001: [2436, 2345, 1218, 2567, 3843, 3843],
    15101: [2463, 2373, 1229, 2594, 3876, 3876],
    15201: [2489, 2399, 1239, 2620, 3909, 3909],
    15301: [2516, 2428, 1241, 2647, 3942, 3942],
    15401: [2542, 2454, 1251, 2673, 3974, 3974],
    15501: [2569, 2480, 1261, 2700, 4010, 4010],
    15601: [2595, 2509, 1271, 2726, 4042, 4042],
    15701: [2622, 2535, 1281, 2752, 4075, 4075],
    15801: [2648, 2564, 1292, 2779, 4108, 4108],
    15901: [2674, 2590, 1293, 2805, 4141, 4141],
    16001: [2701, 2616, 1304, 2832, 4176, 4176],
    16101: [2727, 2644, 1314, 2858, 4209, 4209],
    16201: [2754, 2670, 1324, 2885, 4242, 4242],
    16301: [2780, 2699, 1334, 2911, 4274, 4274],
    16401: [2807, 2725, 1344, 2938, 4307, 4307],
    16501: [2833, 2751, 1346, 2964, 4342, 4342],
    16601: [2860, 2780, 1357, 2991, 4375, 4375],
    16701: [2886, 2806, 1367, 3017, 4408, 4408],
    16801: [2913, 2834, 1377, 3044, 4441, 4441],
    16901: [2939, 2860, 1387, 3070, 4473, 4473],
    17001: [2966, 2886, 1397, 3097, 4509, 4509],
    17101: [2992, 2915, 1399, 3123, 4541, 4541],
    17201: [3019, 2941, 1409, 3150, 4574, 4574],
    17301: [3045, 2970, 1420, 3176, 4607, 4607],
    17401: [3072, 2996, 1430, 3202, 4640, 4640],
    17501: [3098, 3024, 1440, 3229, 4675, 4675],
    17601: [3125, 3051, 1450, 3255, 4708, 4708],
    17701: [3151, 3077, 1460, 3282, 4741, 4741],
    17801: [3177, 3105, 1462, 3308, 4773, 4773],
    17901: [3204, 3131, 1473, 3335, 4806, 4806],
    18001: [3230, 3160, 1483, 3361, 4841, 4841],
    18101: [3257, 3186, 1493, 3388, 4874, 4874],
    18201: [3283, 3212, 1503, 3414, 4907, 4907],
    18301: [3310, 3241, 1513, 3441, 4940, 4940],
    18401: [3336, 3267, 1515, 3467, 4973, 4973],
    18501: [3363, 3295, 1526, 3494, 5008, 5008],
    18601: [3389, 3321, 1536, 3520, 5041, 5041],
    18701: [3416, 3347, 1546, 3547, 5073, 5073],
    18801: [3442, 3376, 1556, 3573, 5106, 5106],
    18901: [3469, 3402, 1566, 3600, 5139, 5139],
    19001: [3495, 3431, 1568, 3626, 5174, 5174],
    19101: [3522, 3457, 1578, 3652, 5207, 5207],
    19201: [3548, 3483, 1588, 3679, 5240, 5240],
    19301: [3575, 3510, 1598, 3705, 5273, 5273],
    19401: [3601, 3536, 1607, 3732, 5305, 5305],
    19501: [3627, 3563, 1617, 3758, 5341, 5341],
    19601: [3654, 3589, 1618, 3785, 5373, 5373],
    19701: [3680, 3614, 1627, 3811, 5406, 5406],
    19801: [3707, 3642, 1636, 3838, 5439, 5439],
    19901: [3733, 3667, 1646, 3864, 5472, 5472],
    20001: [3786, 3720, 1664, 3917, 5540, 5540],
    20201: [3839, 3773, 1675, 3970, 5605, 5605],
    20401: [3892, 3826, 1693, 4023, 5673, 5673],
    20601: [3945, 3876, 1712, 4076, 5739, 5739],
    20801: [3998, 3929, 1722, 4129, 5804, 5804],
    21001: [4051, 3982, 1741, 4182, 5872, 5872],
    21201: [4104, 4035, 1760, 4235, 5938, 5938],
    21401: [4157, 4088, 1770, 4288, 6006, 6006],
    21601: [4210, 4138, 1798, 4341, 6072, 6072],
    21801: [4263, 4191, 1841, 4394, 6137, 6137],
    22001: [4316, 4244, 1884, 4447, 6206, 6206],
    22201: [4371, 4297, 1927, 4502, 6273, 6273],
    22401: [4425, 4352, 1972, 4556, 6343, 6343],
    22601: [4480, 4405, 2015, 4611, 6410, 6410],
    22801: [4534, 4458, 2058, 4665, 6477, 6477],
    23001: [4589, 4514, 2104, 4720, 6547, 6547],
    23201: [4644, 4567, 2147, 4775, 6614, 6614],
    23401: [4698, 4620, 2190, 4829, 6684, 6684],
    23601: [4753, 4675, 2235, 4884, 6751, 6751],
    23801: [4807, 4728, 2278, 4938, 6818, 6818],
    24001: [4862, 4781, 2321, 4993, 6888, 6888],
    24201: [4917, 4834, 2364, 5048, 6955, 6955],
    24401: [4971, 4889, 2409, 5102, 7025, 7025],
    24601: [5026, 4942, 2452, 5157, 7092, 7092],
    24801: [5080, 4995, 2495, 5211, 7159, 7159],
    25001: [5135, 5051, 2551, 5266, 7229, 7229],
    25201: [5190, 5104, 2604, 5321, 7296, 7296],
    25401: [5244, 5157, 2657, 5375, 7366, 7366],
    25601: [5299, 5212, 2712, 5430, 7433, 7433],
    25801: [5353, 5265, 2765, 5484, 7500, 7500],
    26001: [5408, 5318, 2818, 5539, 7570, 7570],
    26201: [5463, 5373, 2873, 5594, 7637, 7637],
    26401: [5517, 5426, 2926, 5648, 7707, 7707],
    26601: [5572, 5479, 2979, 5703, 7774, 7774],
    26801: [5626, 5532, 3032, 5757, 7841, 7841],
    27001: [5681, 5588, 3088, 5812, 7911, 7911],
    27201: [5736, 5641, 3141, 5866, 7978, 7978],
    27401: [5790, 5694, 3194, 5921, 8048, 8048],
    27601: [5845, 5749, 3249, 5976, 8115, 8115],
    27801: [5899, 5802, 3302, 6030, 8182, 8182],
    28001: [5954, 5855, 3355, 6085, 8252, 8252],
    28201: [6009, 5910, 3410, 6139, 8319, 8319],
    28401: [6063, 5963, 3463, 6194, 8389, 8389],
    28601: [6118, 6016, 3516, 6249, 8456, 8456],
    28801: [6172, 6072, 3572, 6303, 8523, 8523],
    29001: [6227, 6125, 3625, 6358, 8593, 8593],
    29201: [6281, 6178, 3678, 6412, 8660, 8660],
    29401: [6336, 6234, 3734, 6467, 8730, 8730],
    29601: [6391, 6288, 3788, 6522, 8797, 8797],
    29801: [6445, 6343, 3843, 6576, 8864, 8864],
    30001: [6500, 6397, 3897, 6631, 8934, 8934],
    30201: [6554, 6454, 3954, 6685, 9001, 9001],
    30401: [6609, 6508, 4008, 6740, 9071, 9071],
    30601: [6664, 6562, 4062, 6795, 9138, 9138],
    30801: [6718, 6619, 4119, 6849, 9205, 9205],
    31001: [6773, 6673, 4173, 6904, 9275, 9275],
    31201: [6827, 6728, 4228, 6958, 9342, 9342],
    31401: [6882, 6784, 4284, 7013, 9412, 9412],
    31601: [6937, 6836, 4336, 7067, 9476, 9476],
    31801: [6991, 6890, 4390, 7122, 9538, 9538],
    32001: [7045, 6945, 4445, 7176, 9600, 9600],
    32201: [7100, 6999, 4499, 7231, 9662, 9662],
    32401: [7156, 7056, 4556, 7285, 9724, 9724],
    32601: [7218, 7118, 4618, 7339, 9786, 9786],
    32801: [7280, 7180, 4680, 7394, 9848, 9848],
    33001: [7342, 7242, 4742, 7448, 9910, 9910],
    33201: [7404, 7304, 4804, 7502, 9972, 9972],
    33401: [7466, 7366, 4866, 7557, 10034, 10034],
    33601: [7528, 7428, 4928, 7611, 10096, 10096],
    33801: [7590, 7490, 4990, 7665, 10158, 10158],
    34001: [7652, 7552, 5052, 7720, 10220, 10220],
    34201: [7714, 7614, 5114, 7774, 10282, 10282],
    34401: [7776, 7676, 5176, 7829, 10344, 10344],
    34601: [7838, 7738, 5238, 7883, 10406, 10406],
    34801: [7900, 7800, 5300, 7937, 10468, 10468],
    35001: [7962, 7862, 5362, 7992, 10530, 10530],
    35201: [8024, 7924, 5424, 8046, 10592, 10592],
    35401: [8086, 7986, 5486, 8100, 10654, 10654],
    35601: [8148, 8048, 5548, 8155, 10716, 10716],
    35801: [8210, 8110, 5610, 8209, 10778, 10778],
    36001: [8272, 8172, 5672, 8263, 10840, 10840],
    36201: [8334, 8234, 5734, 8318, 10902, 10902],
    36401: [8396, 8296, 5796, 8372, 10964, 10964],
    36601: [8458, 8358, 5858, 8426, 11026, 11026],
    36801: [8520, 8420, 5920, 8481, 11088, 11088],
    37001: [8582, 8482, 5982, 8535, 11150, 11150],
    37201: [8644, 8544, 6044, 8590, 11212, 11212],
    37401: [8706, 8606, 6106, 8644, 11274, 11274],
    37601: [8768, 8668, 6168, 8698, 11336, 11336],
    37801: [8830, 8730, 6230, 8753, 11398, 11398],
    38001: [8892, 8792, 6292, 8807, 11460, 11460],
    38201: [8954, 8854, 6354, 8861, 11522, 11522],
    38401: [9016, 8916, 6416, 8916, 11584, 11584],
    38601: [9078, 8978, 6478, 8970, 11646, 11646],
    38801: [9140, 9040, 6540, 9024, 11708, 11708],
    39001: [9202, 9102, 6602, 9079, 11770, 11770],
    39201: [9264, 9164, 6664, 9133, 11832, 11832],
    39401: [9326, 9226, 6726, 9188, 11894, 11894],
    39601: [9388, 9288, 6788, 9242, 11956, 11956],
    39801: [9450, 9350, 6850, 9296, 12018, 12018],
    40001: [9512, 9412, 6912, 9351, 12080, 12080],
    40201: [9574, 9474, 6974, 9405, 12142, 12142],
    40401: [9636, 9536, 7036, 9459, 12204, 12204],
    40601: [9698, 9598, 7098, 9514, 12266, 12266],
    40801: [9760, 9660, 7160, 9568, 12328, 12328],
    41001: [9822, 9722, 7222, 9622, 12390, 12390],
    41201: [9884, 9784, 7284, 9677, 12452, 12452],
    41401: [9946, 9846, 7346, 9731, 12514, 12514],
    41601: [10008, 9908, 7408, 9785, 12576, 12576],
    41801: [10070, 9970, 7470, 9840, 12638, 12638],
    42001: [10132, 10032, 7532, 9894, 12700, 12700],
    42201: [10194, 10094, 7594, 9949, 12762, 12762],
    42401: [10256, 10156, 7656, 10003, 12824, 12824],
    42601: [10318, 10218, 7718, 10057, 12886, 12886],
    42801: [10380, 10280, 7780, 10112, 12948, 12948],
    43001: [10442, 10342, 7842, 10166, 13010, 13010],
    43201: [10504, 10404, 7904, 10220, 13072, 13072],
    43401: [10566, 10466, 7966, 10275, 13134, 13134],
    43601: [10628, 10528, 8028, 10329, 13196, 13196],
    43801: [10690, 10590, 8090, 10383, 13258, 13258],
    44001: [10752, 10652, 8152, 10438, 13320, 13320],
    44201: [10814, 10714, 8214, 10492, 13382, 13382],
    44401: [10876, 10776, 8276, 10547, 13444, 13444],
    44601: [10938, 10838, 8338, 10601, 13506, 13506],
    44801: [11000, 10900, 8400, 10655, 13568, 13568],
    45001: [11062, 10962, 8462, 10710, 13630, 13630],
    45201: [11124, 11024, 8524, 10764, 13692, 13692],
    45401: [11186, 11086, 8586, 10818, 13754, 13754],
    45601: [11248, 11148, 8648, 10873, 13816, 13816],
    45801: [11310, 11210, 8710, 10927, 13878, 13878],
    46001: [11372, 11272, 8772, 10981, 13940, 13940],
    46201: [11466, 11370, 8870, 11067, 14034, 14034],
    46401: [11568, 11471, 8971, 11162, 14136, 14136],
    46601: [11670, 11571, 9071, 11256, 14238, 14238],
    46801: [11772, 11672, 9172, 11351, 14340, 14340],
    47001: [11874, 11773, 9273, 11445, 14442, 14442],
    47201: [11976, 11871, 9371, 11539, 14544, 14544],
    47401: [12078, 11972, 9472, 11634, 14646, 14646],
    47601: [12180, 12072, 9572, 11728, 14748, 14748],
    47801: [12282, 12173, 9673, 11822, 14850, 14850],
    48001: [12384, 12274, 9774, 11917, 14952, 14952],
    48201: [12486, 12375, 9875, 12011, 15054, 15054],
    48401: [12588, 12475, 9975, 12105, 15156, 15156],
    48601: [12690, 12576, 10076, 12200, 15258, 15258],
    48801: [12792, 12674, 10174, 12294, 15360, 15360],
    49001: [12894, 12775, 10275, 12389, 15462, 15462],
    49201: [12996, 12876, 10376, 12483, 15564, 15564],
    49401: [13098, 12977, 10477, 12577, 15666, 15666],
    49601: [13200, 13077, 10577, 12672, 15768, 15768],
    49801: [13302, 13178, 10678, 12766, 15870, 15870],
    50001: [13404, 13279, 10785, 12860, 15972, 15972],
    50201: [13506, 13380, 10892, 12955, 16074, 16074],
    50401: [13608, 13478, 10996, 13049, 16176, 16176],
    50601: [13710, 13579, 11103, 13143, 16278, 16278],
    50801: [13812, 13679, 11209, 13238, 16380, 16380],
    51001: [13914, 13780, 11316, 13332, 16482, 16482],
    51201: [14016, 13881, 11423, 13426, 16584, 16584],
    51401: [14118, 13974, 11522, 13521, 16686, 16686],
    51601: [14220, 14076, 11630, 13615, 16788, 16788],
    51801: [14322, 14178, 11738, 13710, 16890, 16890],
    52001: [14424, 14280, 11846, 13804, 16992, 16992],
    52201: [14526, 14382, 11954, 13898, 17094, 17094],
    52401: [14628, 14484, 12062, 13993, 17196, 17196],
    52601: [14730, 14586, 12170, 14087, 17298, 17298],
    52801: [14832, 14688, 12278, 14181, 17400, 17400],
    53001: [14934, 14790, 12386, 14276, 17502, 17502],
    53201: [15036, 14892, 12494, 14370, 17604, 17604],
    53401: [15138, 14994, 12602, 14464, 17706, 17706],
    53601: [15240, 15096, 12710, 14559, 17808, 17808],
    53801: [15342, 15198, 12818, 14653, 17910, 17910],
    54001: [15444, 15300, 12926, 14748, 18012, 18012],
    54201: [15546, 15402, 13034, 14842, 18114, 18114],
    54401: [15651, 15509, 13147, 14936, 18216, 18216],
    54601: [15759, 15619, 13263, 15031, 18318, 18318],
    54801: [15867, 15725, 13375, 15125, 18420, 18420],
    55001: [15975, 15832, 13488, 15219, 18522, 18522],
    55201: [16083, 15942, 13604, 15314, 18624, 18624],
    55401: [16191, 16048, 13716, 15408, 18726, 18726],
    55601: [16299, 16155, 13829, 15502, 18828, 18828],
    55801: [16407, 16265, 13945, 15597, 18930, 18930],
    56001: [16515, 16371, 14057, 15691, 19032, 19032],
    56201: [16623, 16482, 14174, 15786, 19134, 19134],
    56401: [16731, 16588, 14286, 15880, 19236, 19236],
    56601: [16839, 16694, 14398, 15974, 19338, 19338],
    56801: [16947, 16805, 14515, 16069, 19440, 19440],
    57001: [17055, 16911, 14627, 16163, 19542, 19542],
    57201: [17163, 17022, 14744, 16257, 19644, 19644],
    57401: [17271, 17128, 14856, 16352, 19746, 19746],
    57601: [17379, 17234, 14968, 16446, 19848, 19848],
    57801: [17487, 17345, 15085, 16540, 19950, 19950],
    58001: [17595, 17451, 15197, 16635, 20052, 20052],
    58201: [17703, 17557, 15309, 16729, 20154, 20154],
    58401: [17811, 17668, 15426, 16823, 20256, 20256],
    58601: [17919, 17774, 15538, 16918, 20358, 20358],
    58801: [18027, 17884, 15654, 17012, 20460, 20460],
    59001: [18135, 17991, 15767, 17107, 20562, 20562],
    59201: [18243, 18097, 15879, 17201, 20664, 20664],
    59401: [18351, 18207, 15995, 17295, 20766, 20766],
    59601: [18459, 18314, 16108, 17390, 20868, 20868],
    59801: [18567, 18420, 16220, 17484, 20970, 20970],
    60001: [18675, 18530, 16336, 17578, 21072, 21072],
    60201: [18783, 18637, 16449, 17673, 21174, 21174],
    60401: [18891, 18747, 16565, 17767, 21276, 21276],
    60601: [18999, 18853, 16677, 17861, 21378, 21378],
    60801: [19107, 18960, 16790, 17956, 21480, 21480],
    61001: [19215, 19070, 16906, 18050, 21582, 21582],
    61201: [19323, 19176, 17018, 18145, 21684, 21684],
    61401: [19431, 19283, 17131, 18239, 21786, 21786],
    61601: [19539, 19393, 17247, 18333, 21888, 21888],
    61801: [19647, 19499, 17359, 18428, 21990, 21990],
    62001: [19755, 19610, 17476, 18522, 22092, 22092],
    62201: [19863, 19716, 17588, 18616, 22194, 22194],
    62401: [19971, 19822, 17700, 18711, 22296, 22296],
    62601: [20079, 19933, 17817, 18805, 22398, 22398],
    62801: [20187, 20039, 17929, 18899, 22500, 22500],
    63001: [20295, 20145, 18041, 18994, 22602, 22602],
    63201: [20403, 20256, 18158, 19088, 22704, 22704],
    63401: [20511, 20362, 18270, 19183, 22806, 22806],
    63601: [20619, 20473, 18387, 19277, 22908, 22908],
    63801: [20727, 20579, 18499, 19371, 23010, 23010],
    64001: [20835, 20685, 18611, 19466, 23112, 23112],
    64201: [20943, 20796, 18728, 19560, 23214, 23214],
    64401: [21051, 20902, 18840, 19654, 23316, 23316],
    64601: [21159, 21008, 18952, 19749, 23418, 23418],
    64801: [21267, 21119, 19069, 19843, 23520, 23520],
    65001: [21375, 21225, 19181, 19937, 23622, 23622],
    65201: [21483, 21335, 19297, 20032, 23724, 23724],
    65401: [21591, 21442, 19410, 20126, 23826, 23826],
    65601: [21699, 21548, 19522, 20220, 23928, 23928],
    65801: [21807, 21658, 19638, 20315, 24030, 24030],
    66001: [21915, 21765, 19751, 20409, 24132, 24132],
    66201: [22023, 21871, 19863, 20504, 24234, 24234],
    66401: [22131, 21981, 19979, 20598, 24336, 24336],
    66601: [22239, 22088, 20092, 20692, 24438, 24438],
    66801: [22347, 22198, 20208, 20787, 24540, 24540],
    67001: [22455, 22304, 20320, 20881, 24642, 24642],
    67201: [22563, 22411, 20433, 20975, 24744, 24744],
    67401: [22671, 22521, 20549, 21070, 24846, 24846],
    67601: [22779, 22627, 20661, 21164, 24948, 24948],
    67801: [22887, 22738, 20778, 21258, 25050, 25050],
    68001: [22995, 22844, 20890, 21353, 25152, 25152],
    68201: [23103, 22950, 21002, 21447, 25254, 25254],
    68401: [23211, 23061, 21119, 21542, 25356, 25356],
    68601: [23319, 23167, 21231, 21636, 25458, 25458],
    68801: [23427, 23273, 21343, 21730, 25560, 25560],
    69001: [23535, 23384, 21460, 21825, 25662, 25662],
    69201: [23643, 23490, 21572, 21919, 25764, 25764],
    69401: [23751, 23601, 21689, 22013, 25866, 25866],
    69601: [23859, 23707, 21801, 22108, 25968, 25968],
    69801: [23967, 23813, 21913, 22202, 26070, 26070],
    70001: [24075, 23924, 22030, 22296, 26172, 26172],
    70201: [24183, 24030, 22142, 22391, 26274, 26274],
    70401: [24291, 24136, 22254, 22485, 26376, 26376],
    70601: [24399, 24247, 22371, 22580, 26478, 26478],
    70801: [24507, 24353, 22483, 22674, 26580, 26580],
    71001: [24615, 24463, 22599, 22768, 26682, 26682],
    71201: [24723, 24570, 22712, 22863, 26784, 26784],
    71401: [24831, 24676, 22824, 22957, 26886, 26886],
    71601: [24939, 24786, 22940, 23051, 26988, 26988],
    71801: [25047, 24893, 23053, 23146, 27090, 27090],
    72001: [25155, 24999, 23165, 23240, 27192, 27192],
    72201: [25263, 25109, 23281, 23334, 27294, 27294],
    72401: [25371, 25216, 23394, 23429, 27396, 27396],
    72601: [25479, 25326, 23510, 23523, 27498, 27498],
    72801: [25587, 25432, 23622, 23617, 27600, 27600],
    73001: [25695, 25539, 23735, 23712, 27702, 27702],
    73201: [25803, 25649, 23851, 23806, 27804, 27804],
    73401: [25911, 25755, 23963, 23901, 27906, 27906],
    73601: [26019, 25862, 24076, 23995, 28008, 28008],
    73801: [26127, 25972, 24192, 24089, 28110, 28110],
    74001: [26235, 26078, 24304, 24184, 28212, 28212],
    74201: [26343, 26189, 24421, 24278, 28314, 28314],
    74401: [26451, 26295, 24533, 24372, 28416, 28416],
    74601: [26559, 26401, 24645, 24467, 28518, 28518],
    74801: [26667, 26512, 24762, 24561, 28620, 28620],
    75001: [26775, 26618, 24874, 24655, 28722, 28722],
    75201: [26883, 26724, 24986, 24750, 28824, 28824],
    75401: [26991, 26835, 25103, 24844, 28926, 28926],
    75601: [27099, 26941, 25215, 24939, 29028, 29028],
    75801: [27207, 27052, 25332, 25033, 29130, 29130],
    76001: [27315, 27158, 25444, 25127, 29232, 29232],
    76201: [27423, 27264, 25556, 25222, 29334, 29334],
    76401: [27531, 27375, 25673, 25316, 29436, 29436],
    76601: [27639, 27481, 25785, 25410, 29538, 29538],
    76801: [27747, 27591, 25901, 25505, 29640, 29640],
    77001: [27855, 27698, 26014, 25599, 29742, 29742],
    77201: [27963, 27804, 26126, 25693, 29844, 29844],
    77401: [28071, 27914, 26242, 25788, 29946, 29946],
    77601: [28179, 28021, 26355, 25882, 30048, 30048],
    77801: [28287, 28127, 26467, 25976, 30150, 30150],
    78001: [28395, 28237, 26583, 26071, 30252, 30252],
    78201: [28503, 28344, 26696, 26165, 30354, 30354],
    78401: [28611, 28454, 26812, 26260, 30456, 30456],
    78601: [28719, 28560, 26924, 26354, 30558, 30558],
    78801: [28827, 28667, 27037, 26448, 30660, 30660],
    79001: [28935, 28777, 27153, 26543, 30762, 30762],
    79201: [29043, 28883, 27265, 26637, 30864, 30864],
    79401: [29151, 28990, 27378, 26731, 30966, 30966],
    79601: [29259, 29100, 27494, 26826, 31068, 31068],
    79801: [29367, 29206, 27606, 26920, 31170, 31170],
    80001: [37, 37, 35, 34, 39, 39, '%'],
    83601: [37, 37, 36, 34, 40, 40, '%'],
    83801: [38, 37, 36, 34, 40, 40, '%'],
    84801: [38, 38, 36, 34, 40, 40, '%'],
    85201: [38, 38, 36, 35, 40, 40, '%'],
    87601: [38, 38, 37, 35, 40, 40, '%'],
    89201: [39, 38, 37, 35, 40, 40, '%'],
    90201: [39, 39, 37, 35, 40, 40, '%'],
    91601: [39, 39, 37, 35, 41, 41, '%'],
    92201: [39, 39, 38, 35, 41, 41, '%'],
    92601: [39, 39, 38, 36, 41, 41, '%'],
    95201: [40, 39, 38, 36, 41, 41, '%'],
    96401: [40, 40, 38, 36, 41, 41, '%'],
    97201: [40, 40, 39, 36, 41, 41, '%'],
    101201: [40, 40, 39, 37, 42, 42, '%'],
    102401: [41, 40, 39, 37, 42, 42, '%'],
    102801: [41, 40, 40, 37, 42, 42, '%'],
    103601: [41, 41, 40, 37, 42, 42, '%'],
    109001: [41, 41, 41, 37, 42, 42, '%'],
    110601: [42, 41, 41, 37, 42, 42, '%'],
    111801: [42, 41, 41, 38, 42, 42, '%'],
    112001: [42, 42, 41, 38, 42, 42, '%'],
    113201: [42, 42, 41, 38, 43, 43, '%'],
    116201: [42, 42, 42, 38, 43, 43, '%'],
    120201: [43, 42, 42, 38, 43, 43, '%'],
    121801: [43, 43, 42, 38, 43, 43, '%'],
    124201: [43, 43, 43, 38, 43, 43, '%'],
    124601: [43, 43, 43, 39, 43, 43, '%'],
    128201: [43, 43, 43, 39, 44, 44, '%'],
    131601: [44, 43, 43, 39, 44, 44, '%'],
    133601: [44, 44, 44, 39, 44, 44, '%'],
    140801: [44, 44, 44, 40, 44, 44, '%'],
    148001: [45, 45, 45, 40, 45, 45, '%'],
    162001: [45, 45, 45, 41, 45, 45, '%'],
    175001: [46, 46, 46, 41, 46, 46, '%'],
    190401: [46, 46, 46, 42, 46, 46, '%'],
    213801: [47, 47, 47, 42, 47, 47, '%'],
    231201: [47, 47, 47, 43, 47, 47, '%'],
    275001: [48, 48, 48, 43, 48, 48, '%'],
    294001: [48, 48, 48, 44, 48, 48, '%'],
    385001: [49, 49, 49, 44, 49, 49, '%'],
    403801: [49, 49, 49, 45, 49, 49, '%'],
    641801: [50, 50, 50, 45, 50, 50, '%'],
    644001: [50, 50, 50, 46, 50, 50, '%'],
    1590601: [50, 50, 50, 47, 50, 50, '%'],
    1925801: [51, 51, 51, 47, 51, 51, '%']
  },
  32: {
    1: [0, 0, 0, 0, 0, 0],
    1701: [126, 0, 125, 1, 126, 30],
    1801: [137, 0, 133, 4, 137, 63],
    1901: [147, 0, 141, 6, 147, 96],
    2001: [158, 0, 150, 8, 158, 129],
    2101: [160, 0, 150, 10, 162, 162],
    2201: [171, 0, 158, 12, 195, 195],
    2301: [181, 0, 166, 14, 228, 228],
    2401: [192, 0, 175, 17, 261, 261],
    2501: [202, 0, 183, 19, 294, 294],
    2601: [213, 0, 191, 21, 327, 327],
    2701: [223, 0, 200, 23, 360, 360],
    2801: [225, 0, 200, 25, 393, 393],
    2901: [236, 0, 208, 27, 426, 426],
    3001: [246, 0, 216, 30, 459, 459],
    3101: [257, 0, 225, 32, 492, 492],
    3201: [267, 0, 233, 34, 525, 525],
    3301: [278, 0, 241, 36, 558, 558],
    3401: [280, 0, 241, 38, 591, 591],
    3501: [290, 0, 250, 40, 624, 624],
    3601: [301, 0, 258, 50, 657, 657],
    3701: [311, 0, 266, 73, 690, 690],
    3801: [322, 0, 275, 95, 723, 723],
    3901: [332, 0, 283, 118, 756, 756],
    4001: [334, 0, 283, 140, 780, 780],
    4101: [344, 0, 291, 162, 808, 808],
    4201: [354, 0, 300, 184, 835, 835],
    4301: [364, 0, 308, 206, 860, 860],
    4401: [374, 5, 317, 228, 888, 888],
    4501: [384, 30, 326, 250, 912, 912],
    4601: [386, 54, 328, 272, 940, 940],
    4701: [396, 79, 338, 294, 967, 967],
    4801: [406, 104, 348, 316, 992, 992],
    4901: [416, 129, 358, 338, 1020, 1020],
    5001: [426, 153, 368, 360, 1044, 1044],
    5101: [436, 178, 378, 382, 1072, 1072],
    5201: [447, 203, 388, 405, 1100, 1100],
    5301: [448, 225, 389, 426, 1124, 1124],
    5401: [458, 250, 399, 447, 1151, 1151],
    5501: [468, 275, 409, 469, 1175, 1175],
    5601: [478, 299, 419, 490, 1202, 1202],
    5701: [488, 324, 429, 512, 1228, 1228],
    5801: [501, 349, 439, 533, 1253, 1253],
    5901: [521, 373, 441, 555, 1280, 1280],
    6001: [541, 398, 451, 576, 1304, 1304],
    6101: [561, 423, 461, 598, 1331, 1331],
    6201: [581, 448, 471, 619, 1357, 1357],
    6301: [601, 472, 480, 641, 1382, 1382],
    6401: [621, 497, 490, 662, 1409, 1409],
    6501: [641, 519, 492, 683, 1433, 1433],
    6601: [661, 544, 502, 705, 1460, 1460],
    6701: [681, 569, 512, 727, 1486, 1486],
    6801: [701, 593, 522, 748, 1511, 1511],
    6901: [721, 618, 532, 769, 1538, 1538],
    7001: [741, 643, 542, 791, 1562, 1562],
    7101: [761, 668, 543, 812, 1589, 1589],
    7201: [781, 692, 553, 834, 1615, 1615],
    7301: [801, 717, 563, 855, 1640, 1640],
    7401: [821, 742, 573, 877, 1667, 1667],
    7501: [841, 767, 583, 898, 1691, 1691],
    7601: [861, 791, 593, 920, 1718, 1718],
    7701: [881, 813, 603, 941, 1744, 1744],
    7801: [901, 838, 604, 963, 1769, 1769],
    7901: [921, 855, 614, 984, 1796, 1796],
    8001: [941, 874, 623, 1005, 1820, 1820],
    8101: [961, 893, 633, 1027, 1847, 1847],
    8201: [981, 915, 643, 1049, 1873, 1873],
    8301: [1001, 934, 652, 1070, 1898, 1898],
    8401: [1021, 954, 654, 1091, 1925, 1925],
    8501: [1041, 973, 663, 1113, 1949, 1949],
    8601: [1062, 995, 673, 1134, 1976, 1976],
    8701: [1082, 1014, 683, 1156, 2002, 2002],
    8801: [1102, 1033, 692, 1177, 2027, 2027],
    8901: [1122, 1053, 702, 1199, 2054, 2054],
    9001: [1142, 1075, 703, 1220, 2078, 2078],
    9101: [1162, 1094, 713, 1242, 2105, 2105],
    9201: [1182, 1113, 722, 1263, 2131, 2131],
    9301: [1202, 1134, 732, 1285, 2156, 2156],
    9401: [1222, 1153, 742, 1306, 2183, 2183],
    9501: [1242, 1172, 751, 1327, 2207, 2207],
    9601: [1262, 1191, 753, 1349, 2234, 2234],
    9701: [1282, 1212, 762, 1371, 2260, 2260],
    9801: [1302, 1231, 772, 1392, 2285, 2285],
    9901: [1322, 1250, 782, 1413, 2312, 2312],
    10001: [1342, 1269, 791, 1435, 2336, 2336],
    10101: [1362, 1290, 801, 1456, 2363, 2363],
    10201: [1382, 1309, 811, 1478, 2389, 2389],
    10301: [1402, 1328, 812, 1499, 2414, 2414],
    10401: [1422, 1347, 821, 1521, 2441, 2441],
    10501: [1442, 1368, 831, 1542, 2465, 2465],
    10601: [1462, 1387, 841, 1564, 2492, 2492],
    10701: [1482, 1406, 850, 1585, 2518, 2518],
    10801: [1502, 1425, 860, 1607, 2543, 2543],
    10901: [1522, 1446, 861, 1628, 2572, 2572],
    11001: [1542, 1465, 871, 1650, 2604, 2604],
    11101: [1563, 1484, 881, 1672, 2637, 2637],
    11201: [1583, 1505, 890, 1693, 2669, 2669],
    11301: [1603, 1524, 900, 1715, 2701, 2701],
    11401: [1624, 1543, 910, 1737, 2733, 2733],
    11501: [1644, 1562, 911, 1759, 2766, 2766],
    11601: [1664, 1583, 921, 1780, 2798, 2798],
    11701: [1685, 1602, 930, 1802, 2830, 2830],
    11801: [1705, 1621, 940, 1824, 2862, 2862],
    11901: [1725, 1640, 949, 1846, 2895, 2895],
    12001: [1745, 1661, 959, 1867, 2927, 2927],
    12101: [1766, 1680, 960, 1889, 2959, 2959],
    12201: [1786, 1699, 970, 1911, 2991, 2991],
    12301: [1806, 1718, 980, 1933, 3024, 3024],
    12401: [1827, 1739, 989, 1954, 3056, 3056],
    12501: [1847, 1758, 999, 1976, 3091, 3091],
    12601: [1868, 1777, 1009, 1998, 3126, 3126],
    12701: [1888, 1796, 1018, 2020, 3161, 3161],
    12801: [1908, 1817, 1020, 2042, 3196, 3196],
    12901: [1929, 1836, 1029, 2064, 3231, 3231],
    13001: [1955, 1860, 1039, 2090, 3268, 3268],
    13101: [1983, 1890, 1049, 2119, 3303, 3303],
    13201: [2012, 1916, 1060, 2147, 3338, 3338],
    13301: [2040, 1946, 1070, 2175, 3373, 3373],
    13401: [2068, 1973, 1072, 2204, 3408, 3408],
    13501: [2097, 2000, 1082, 2232, 3446, 3446],
    13601: [2125, 2029, 1092, 2261, 3481, 3481],
    13701: [2154, 2056, 1102, 2289, 3516, 3516],
    13801: [2182, 2086, 1113, 2318, 3550, 3550],
    13901: [2211, 2113, 1123, 2346, 3586, 3586],
    14001: [2238, 2140, 1124, 2374, 3622, 3622],
    14101: [2266, 2169, 1135, 2401, 3656, 3656],
    14201: [2293, 2196, 1145, 2428, 3690, 3690],
    14301: [2320, 2226, 1155, 2456, 3724, 3724],
    14401: [2348, 2252, 1165, 2483, 3757, 3757],
    14501: [2375, 2279, 1176, 2510, 3794, 3794],
    14601: [2402, 2309, 1178, 2538, 3828, 3828],
    14701: [2430, 2336, 1188, 2565, 3862, 3862],
    14801: [2457, 2365, 1198, 2592, 3895, 3895],
    14901: [2484, 2392, 1208, 2620, 3929, 3929],
    15001: [2512, 2419, 1218, 2647, 3966, 3966],
    15101: [2539, 2449, 1229, 2674, 4000, 4000],
    15201: [2566, 2475, 1239, 2702, 4034, 4034],
    15301: [2594, 2505, 1241, 2729, 4067, 4067],
    15401: [2621, 2532, 1251, 2756, 4101, 4101],
    15501: [2648, 2559, 1261, 2784, 4138, 4138],
    15601: [2676, 2588, 1271, 2811, 4172, 4172],
    15701: [2703, 2615, 1281, 2838, 4205, 4205],
    15801: [2730, 2645, 1292, 2866, 4239, 4239],
    15901: [2758, 2672, 1293, 2893, 4273, 4273],
    16001: [2785, 2699, 1304, 2920, 4310, 4310],
    16101: [2812, 2728, 1314, 2948, 4343, 4343],
    16201: [2840, 2755, 1324, 2975, 4377, 4377],
    16301: [2867, 2785, 1334, 3002, 4411, 4411],
    16401: [2894, 2811, 1344, 3030, 4445, 4445],
    16501: [2922, 2838, 1346, 3057, 4481, 4481],
    16601: [2949, 2868, 1357, 3084, 4515, 4515],
    16701: [2976, 2895, 1367, 3112, 4549, 4549],
    16801: [3004, 2924, 1377, 3139, 4583, 4583],
    16901: [3031, 2951, 1387, 3167, 4617, 4617],
    17001: [3059, 2978, 1397, 3194, 4653, 4653],
    17101: [3086, 3008, 1399, 3221, 4687, 4687],
    17201: [3113, 3034, 1409, 3249, 4721, 4721],
    17301: [3141, 3064, 1420, 3276, 4755, 4755],
    17401: [3168, 3091, 1430, 3303, 4789, 4789],
    17501: [3195, 3120, 1440, 3331, 4825, 4825],
    17601: [3223, 3147, 1450, 3358, 4859, 4859],
    17701: [3250, 3174, 1460, 3385, 4893, 4893],
    17801: [3277, 3204, 1462, 3413, 4927, 4927],
    17901: [3305, 3231, 1473, 3440, 4961, 4961],
    18001: [3332, 3260, 1483, 3467, 4997, 4997],
    18101: [3359, 3287, 1493, 3495, 5031, 5031],
    18201: [3387, 3314, 1503, 3522, 5065, 5065],
    18301: [3414, 3344, 1513, 3549, 5099, 5099],
    18401: [3441, 3370, 1515, 3577, 5132, 5132],
    18501: [3469, 3400, 1526, 3604, 5169, 5169],
    18601: [3496, 3427, 1536, 3631, 5203, 5203],
    18701: [3523, 3454, 1546, 3659, 5237, 5237],
    18801: [3551, 3483, 1556, 3686, 5270, 5270],
    18901: [3578, 3510, 1566, 3713, 5304, 5304],
    19001: [3605, 3540, 1568, 3741, 5341, 5341],
    19101: [3633, 3567, 1578, 3768, 5375, 5375],
    19201: [3660, 3593, 1588, 3796, 5409, 5409],
    19301: [3687, 3622, 1598, 3823, 5442, 5442],
    19401: [3715, 3648, 1607, 3850, 5476, 5476],
    19501: [3742, 3677, 1617, 3877, 5513, 5513],
    19601: [3769, 3703, 1618, 3905, 5547, 5547],
    19701: [3797, 3729, 1627, 3932, 5580, 5580],
    19801: [3824, 3757, 1636, 3959, 5614, 5614],
    19901: [3852, 3784, 1646, 3987, 5648, 5648],
    20001: [3906, 3838, 1664, 4041, 5718, 5718],
    20201: [3961, 3893, 1675, 4096, 5786, 5786],
    20401: [4016, 3948, 1693, 4151, 5856, 5856],
    20601: [4070, 4000, 1712, 4206, 5924, 5924],
    20801: [4125, 4054, 1754, 4260, 5992, 5992],
    21001: [4180, 4109, 1799, 4315, 6062, 6062],
    21201: [4234, 4164, 1844, 4370, 6130, 6130],
    21401: [4289, 4218, 1888, 4424, 6200, 6200],
    21601: [4344, 4270, 1930, 4479, 6268, 6268],
    21801: [4398, 4325, 1975, 4534, 6336, 6336],
    22001: [4454, 4380, 2020, 4589, 6406, 6406],
    22201: [4510, 4435, 2065, 4645, 6476, 6476],
    22401: [4566, 4492, 2112, 4702, 6548, 6548],
    22601: [4623, 4547, 2157, 4758, 6617, 6617],
    22801: [4679, 4601, 2201, 4814, 6686, 6686],
    23001: [4735, 4659, 2249, 4871, 6758, 6758],
    23201: [4792, 4713, 2293, 4927, 6828, 6828],
    23401: [4848, 4768, 2338, 4983, 6900, 6900],
    23601: [4904, 4825, 2385, 5040, 6969, 6969],
    23801: [4961, 4880, 2430, 5096, 7038, 7038],
    24001: [5017, 4935, 2475, 5152, 7110, 7110],
    24201: [5073, 4989, 2519, 5209, 7180, 7180],
    24401: [5130, 5047, 2567, 5265, 7252, 7252],
    24601: [5186, 5101, 2611, 5321, 7321, 7321],
    24801: [5242, 5156, 2656, 5377, 7390, 7390],
    25001: [5299, 5213, 2713, 5434, 7462, 7462],
    25201: [5355, 5268, 2768, 5490, 7532, 7532],
    25401: [5411, 5323, 2823, 5547, 7604, 7604],
    25601: [5468, 5380, 2880, 5603, 7673, 7673],
    25801: [5524, 5435, 2935, 5659, 7742, 7742],
    26001: [5580, 5489, 2989, 5716, 7814, 7814],
    26201: [5637, 5547, 3047, 5772, 7884, 7884],
    26401: [5693, 5601, 3101, 5828, 7956, 7956],
    26601: [5749, 5656, 3156, 5885, 8025, 8025],
    26801: [5806, 5711, 3211, 5941, 8094, 8094],
    27001: [5862, 5768, 3268, 5997, 8166, 8166],
    27201: [5918, 5823, 3323, 6054, 8236, 8236],
    27401: [5975, 5877, 3377, 6110, 8308, 8308],
    27601: [6031, 5935, 3435, 6166, 8377, 8377],
    27801: [6087, 5989, 3489, 6223, 8446, 8446],
    28001: [6144, 6044, 3544, 6279, 8518, 8518],
    28201: [6200, 6101, 3601, 6335, 8588, 8588],
    28401: [6256, 6156, 3656, 6392, 8660, 8660],
    28601: [6313, 6211, 3711, 6448, 8729, 8729],
    28801: [6369, 6268, 3768, 6504, 8798, 8798],
    29001: [6425, 6323, 3823, 6561, 8870, 8870],
    29201: [6482, 6377, 3877, 6617, 8940, 8940],
    29401: [6538, 6436, 3936, 6673, 9012, 9012],
    29601: [6594, 6492, 3992, 6730, 9081, 9081],
    29801: [6651, 6548, 4048, 6786, 9150, 9150],
    30001: [6707, 6604, 4104, 6842, 9222, 9222],
    30201: [6763, 6662, 4162, 6899, 9292, 9292],
    30401: [6820, 6718, 4218, 6955, 9364, 9364],
    30601: [6876, 6774, 4274, 7011, 9433, 9433],
    30801: [6932, 6833, 4333, 7068, 9502, 9502],
    31001: [6989, 6889, 4389, 7124, 9574, 9574],
    31201: [7045, 6945, 4445, 7180, 9644, 9644],
    31401: [7101, 7004, 4504, 7237, 9716, 9716],
    31601: [7158, 7057, 4557, 7293, 9782, 9782],
    31801: [7214, 7113, 4613, 7349, 9846, 9846],
    32001: [7270, 7169, 4669, 7405, 9910, 9910],
    32201: [7326, 7225, 4725, 7461, 9974, 9974],
    32401: [7384, 7284, 4784, 7517, 10038, 10038],
    32601: [7448, 7348, 4848, 7573, 10102, 10102],
    32801: [7512, 7412, 4912, 7630, 10166, 10166],
    33001: [7576, 7476, 4976, 7686, 10230, 10230],
    33201: [7640, 7540, 5040, 7742, 10294, 10294],
    33401: [7704, 7604, 5104, 7798, 10358, 10358],
    33601: [7768, 7668, 5168, 7854, 10422, 10422],
    33801: [7832, 7732, 5232, 7910, 10486, 10486],
    34001: [7896, 7796, 5296, 7966, 10550, 10550],
    34201: [7960, 7860, 5360, 8022, 10614, 10614],
    34401: [8024, 7924, 5424, 8078, 10678, 10678],
    34601: [8088, 7988, 5488, 8134, 10742, 10742],
    34801: [8152, 8052, 5552, 8191, 10806, 10806],
    35001: [8216, 8116, 5616, 8247, 10870, 10870],
    35201: [8280, 8180, 5680, 8303, 10934, 10934],
    35401: [8344, 8244, 5744, 8359, 10998, 10998],
    35601: [8408, 8308, 5808, 8415, 11062, 11062],
    35801: [8472, 8372, 5872, 8471, 11126, 11126],
    36001: [8536, 8436, 5936, 8527, 11190, 11190],
    36201: [8600, 8500, 6000, 8583, 11254, 11254],
    36401: [8665, 8564, 6064, 8639, 11318, 11318],
    36601: [8728, 8628, 6128, 8696, 11382, 11382],
    36801: [8792, 8692, 6192, 8752, 11446, 11446],
    37001: [8856, 8756, 6256, 8808, 11510, 11510],
    37201: [8920, 8820, 6320, 8864, 11574, 11574],
    37401: [8984, 8884, 6384, 8920, 11638, 11638],
    37601: [9048, 8948, 6448, 8976, 11702, 11702],
    37801: [9112, 9012, 6512, 9032, 11766, 11766],
    38001: [9176, 9076, 6576, 9088, 11830, 11830],
    38201: [9240, 9140, 6640, 9144, 11894, 11894],
    38401: [9304, 9204, 6704, 9200, 11958, 11958],
    38601: [9368, 9268, 6768, 9257, 12022, 12022],
    38801: [9432, 9332, 6832, 9313, 12086, 12086],
    39001: [9496, 9396, 6896, 9369, 12150, 12150],
    39201: [9560, 9460, 6960, 9425, 12214, 12214],
    39401: [9624, 9524, 7024, 9481, 12278, 12278],
    39601: [9688, 9588, 7088, 9537, 12342, 12342],
    39801: [9752, 9652, 7152, 9593, 12406, 12406],
    40001: [9816, 9716, 7216, 9649, 12470, 12470],
    40201: [9880, 9780, 7280, 9705, 12534, 12534],
    40401: [9944, 9844, 7344, 9762, 12598, 12598],
    40601: [10008, 9908, 7408, 9818, 12662, 12662],
    40801: [10072, 9972, 7472, 9874, 12726, 12726],
    41001: [10136, 10036, 7536, 9930, 12790, 12790],
    41201: [10200, 10100, 7600, 9986, 12854, 12854],
    41401: [10265, 10164, 7664, 10042, 12918, 12918],
    41601: [10328, 10228, 7728, 10098, 12982, 12982],
    41801: [10392, 10292, 7792, 10154, 13046, 13046],
    42001: [10456, 10356, 7856, 10210, 13110, 13110],
    42201: [10520, 10420, 7920, 10266, 13174, 13174],
    42401: [10584, 10484, 7984, 10323, 13238, 13238],
    42601: [10648, 10548, 8048, 10379, 13302, 13302],
    42801: [10712, 10612, 8112, 10435, 13366, 13366],
    43001: [10776, 10676, 8176, 10491, 13430, 13430],
    43201: [10840, 10740, 8240, 10547, 13494, 13494],
    43401: [10904, 10804, 8304, 10603, 13558, 13558],
    43601: [10968, 10868, 8368, 10659, 13622, 13622],
    43801: [11032, 10932, 8432, 10715, 13686, 13686],
    44001: [11096, 10996, 8496, 10771, 13750, 13750],
    44201: [11160, 11060, 8560, 10828, 13814, 13814],
    44401: [11224, 11124, 8624, 10884, 13878, 13878],
    44601: [11288, 11188, 8688, 10940, 13942, 13942],
    44801: [11352, 11252, 8752, 10996, 14006, 14006],
    45001: [11416, 11316, 8816, 11052, 14070, 14070],
    45201: [11480, 11380, 8880, 11108, 14134, 14134],
    45401: [11544, 11444, 8944, 11164, 14198, 14198],
    45601: [11608, 11508, 9008, 11220, 14262, 14262],
    45801: [11672, 11572, 9072, 11276, 14326, 14326],
    46001: [11736, 11636, 9136, 11332, 14390, 14390],
    46201: [11832, 11737, 9237, 11420, 14486, 14486],
    46401: [11936, 11841, 9341, 11516, 14590, 14590],
    46601: [12040, 11945, 9445, 11612, 14694, 14694],
    46801: [12144, 12049, 9549, 11709, 14798, 14798],
    47001: [12248, 12153, 9653, 11805, 14902, 14902],
    47201: [12352, 12254, 9754, 11901, 15006, 15006],
    47401: [12456, 12358, 9858, 11997, 15110, 15110],
    47601: [12560, 12462, 9962, 12093, 15214, 15214],
    47801: [12664, 12566, 10066, 12189, 15318, 15318],
    48001: [12768, 12670, 10170, 12285, 15422, 15422],
    48201: [12872, 12774, 10274, 12381, 15526, 15526],
    48401: [12976, 12878, 10378, 12477, 15630, 15630],
    48601: [13080, 12982, 10482, 12573, 15734, 15734],
    48801: [13184, 13084, 10584, 12670, 15838, 15838],
    49001: [13288, 13188, 10688, 12766, 15942, 15942],
    49201: [13392, 13292, 10792, 12862, 16046, 16046],
    49401: [13496, 13396, 10896, 12958, 16150, 16150],
    49601: [13600, 13500, 11000, 13054, 16254, 16254],
    49801: [13704, 13604, 11104, 13150, 16358, 16358],
    50001: [13808, 13708, 11214, 13246, 16462, 16462],
    50201: [13912, 13812, 11324, 13342, 16566, 16566],
    50401: [14016, 13913, 11431, 13438, 16670, 16670],
    50601: [14120, 14017, 11541, 13535, 16774, 16774],
    50801: [14224, 14121, 11651, 13631, 16878, 16878],
    51001: [14328, 14225, 11761, 13727, 16982, 16982],
    51201: [14432, 14329, 11871, 13823, 17086, 17086],
    51401: [14536, 14425, 11973, 13919, 17190, 17190],
    51601: [14640, 14529, 12083, 14015, 17294, 17294],
    51801: [14744, 14633, 12193, 14111, 17398, 17398],
    52001: [14848, 14737, 12303, 14207, 17502, 17502],
    52201: [14952, 14841, 12413, 14303, 17606, 17606],
    52401: [15056, 14945, 12523, 14399, 17710, 17710],
    52601: [15160, 15049, 12633, 14496, 17814, 17814],
    52801: [15264, 15153, 12743, 14592, 17918, 17918],
    53001: [15368, 15257, 12853, 14688, 18022, 18022],
    53201: [15472, 15361, 12963, 14784, 18126, 18126],
    53401: [15576, 15465, 13073, 14880, 18230, 18230],
    53601: [15680, 15569, 13183, 14976, 18334, 18334],
    53801: [15784, 15673, 13293, 15072, 18438, 18438],
    54001: [15888, 15777, 13403, 15168, 18542, 18542],
    54201: [15992, 15881, 13513, 15264, 18646, 18646],
    54401: [16099, 15990, 13628, 15361, 18750, 18750],
    54601: [16209, 16102, 13746, 15457, 18854, 18854],
    54801: [16319, 16211, 13861, 15553, 18958, 18958],
    55001: [16429, 16319, 13975, 15649, 19062, 19062],
    55201: [16539, 16432, 14094, 15745, 19166, 19166],
    55401: [16649, 16540, 14208, 15841, 19270, 19270],
    55601: [16759, 16648, 14322, 15937, 19374, 19374],
    55801: [16869, 16761, 14441, 16033, 19478, 19478],
    56001: [16979, 16869, 14555, 16129, 19582, 19582],
    56201: [17089, 16982, 14674, 16225, 19686, 19686],
    56401: [17199, 17090, 14788, 16322, 19790, 19790],
    56601: [17309, 17199, 14903, 16418, 19894, 19894],
    56801: [17419, 17311, 15021, 16514, 19998, 19998],
    57001: [17529, 17420, 15136, 16610, 20102, 20102],
    57201: [17639, 17532, 15254, 16706, 20206, 20206],
    57401: [17749, 17641, 15369, 16802, 20310, 20310],
    57601: [17859, 17749, 15483, 16898, 20414, 20414],
    57801: [17969, 17862, 15602, 16994, 20518, 20518],
    58001: [18079, 17970, 15716, 17090, 20622, 20622],
    58201: [18189, 18078, 15830, 17187, 20726, 20726],
    58401: [18299, 18191, 15949, 17283, 20830, 20830],
    58601: [18409, 18299, 16063, 17379, 20934, 20934],
    58801: [18519, 18412, 16182, 17475, 21038, 21038],
    59001: [18629, 18520, 16296, 17571, 21142, 21142],
    59201: [18739, 18629, 16411, 17667, 21246, 21246],
    59401: [18849, 18741, 16529, 17763, 21350, 21350],
    59601: [18959, 18850, 16644, 17859, 21454, 21454],
    59801: [19069, 18958, 16758, 17955, 21558, 21558],
    60001: [19179, 19071, 16877, 18051, 21662, 21662],
    60201: [19289, 19179, 16991, 18148, 21766, 21766],
    60401: [19399, 19292, 17110, 18244, 21870, 21870],
    60601: [19509, 19400, 17224, 18340, 21974, 21974],
    60801: [19619, 19508, 17338, 18436, 22078, 22078],
    61001: [19729, 19621, 17457, 18532, 22182, 22182],
    61201: [19839, 19729, 17571, 18628, 22286, 22286],
    61401: [19949, 19838, 17686, 18724, 22390, 22390],
    61601: [20059, 19950, 17804, 18820, 22494, 22494],
    61801: [20169, 20059, 17919, 18916, 22598, 22598],
    62001: [20279, 20171, 18037, 19013, 22702, 22702],
    62201: [20389, 20280, 18152, 19109, 22806, 22806],
    62401: [20499, 20388, 18266, 19205, 22910, 22910],
    62601: [20609, 20501, 18385, 19301, 23014, 23014],
    62801: [20719, 20609, 18499, 19397, 23118, 23118],
    63001: [20829, 20717, 18613, 19493, 23222, 23222],
    63201: [20939, 20830, 18732, 19589, 23326, 23326],
    63401: [21049, 20938, 18846, 19685, 23430, 23430],
    63601: [21159, 21051, 18965, 19781, 23534, 23534],
    63801: [21269, 21159, 19079, 19877, 23638, 23638],
    64001: [21379, 21268, 19194, 19974, 23742, 23742],
    64201: [21489, 21380, 19312, 20070, 23846, 23846],
    64401: [21599, 21489, 19427, 20166, 23950, 23950],
    64601: [21709, 21597, 19541, 20262, 24054, 24054],
    64801: [21819, 21710, 19660, 20358, 24158, 24158],
    65001: [21929, 21818, 19774, 20454, 24262, 24262],
    65201: [22039, 21931, 19893, 20550, 24366, 24366],
    65401: [22149, 22039, 20007, 20646, 24470, 24470],
    65601: [22259, 22147, 20121, 20742, 24574, 24574],
    65801: [22369, 22260, 20240, 20839, 24678, 24678],
    66001: [22479, 22368, 20354, 20935, 24782, 24782],
    66201: [22589, 22477, 20469, 21031, 24886, 24886],
    66401: [22699, 22589, 20587, 21127, 24990, 24990],
    66601: [22809, 22698, 20702, 21223, 25094, 25094],
    66801: [22919, 22810, 20820, 21319, 25198, 25198],
    67001: [23029, 22919, 20935, 21415, 25302, 25302],
    67201: [23139, 23027, 21049, 21511, 25406, 25406],
    67401: [23249, 23140, 21168, 21607, 25510, 25510],
    67601: [23359, 23248, 21282, 21703, 25614, 25614],
    67801: [23469, 23361, 21401, 21800, 25718, 25718],
    68001: [23579, 23469, 21515, 21896, 25822, 25822],
    68201: [23689, 23577, 21629, 21992, 25926, 25926],
    68401: [23799, 23690, 21748, 22088, 26030, 26030],
    68601: [23909, 23798, 21862, 22184, 26134, 26134],
    68801: [24019, 23907, 21977, 22280, 26238, 26238],
    69001: [24129, 24019, 22095, 22376, 26342, 26342],
    69201: [24239, 24128, 22210, 22472, 26446, 26446],
    69401: [24349, 24240, 22328, 22568, 26550, 26550],
    69601: [24459, 24349, 22443, 22664, 26654, 26654],
    69801: [24569, 24457, 22557, 22761, 26758, 26758],
    70001: [24679, 24570, 22676, 22857, 26862, 26862],
    70201: [24789, 24678, 22790, 22953, 26966, 26966],
    70401: [24899, 24786, 22904, 23049, 27070, 27070],
    70601: [25009, 24899, 23023, 23145, 27174, 27174],
    70801: [25119, 25007, 23137, 23241, 27278, 27278],
    71001: [25229, 25120, 23256, 23337, 27382, 27382],
    71201: [25339, 25228, 23370, 23433, 27486, 27486],
    71401: [25449, 25337, 23485, 23530, 27590, 27590],
    71601: [25559, 25449, 23603, 23626, 27694, 27694],
    71801: [25669, 25558, 23718, 23722, 27798, 27798],
    72001: [25779, 25666, 23832, 23818, 27902, 27902],
    72201: [25889, 25779, 23951, 23914, 28006, 28006],
    72401: [25999, 25887, 24065, 24010, 28110, 28110],
    72601: [26109, 26000, 24184, 24106, 28214, 28214],
    72801: [26219, 26108, 24298, 24202, 28318, 28318],
    73001: [26329, 26216, 24412, 24298, 28422, 28422],
    73201: [26439, 26329, 24531, 24394, 28526, 28526],
    73401: [26549, 26437, 24645, 24490, 28630, 28630],
    73601: [26659, 26546, 24760, 24587, 28734, 28734],
    73801: [26769, 26658, 24878, 24683, 28838, 28838],
    74001: [26879, 26767, 24993, 24779, 28942, 28942],
    74201: [26989, 26879, 25111, 24875, 29046, 29046],
    74401: [27099, 26988, 25226, 24971, 29150, 29150],
    74601: [27209, 27096, 25340, 25067, 29254, 29254],
    74801: [27319, 27209, 25459, 25163, 29358, 29358],
    75001: [27429, 27317, 25573, 25259, 29462, 29462],
    75201: [27539, 27425, 25687, 25355, 29566, 29566],
    75401: [27649, 27538, 25806, 25452, 29670, 29670],
    75601: [27759, 27646, 25920, 25548, 29774, 29774],
    75801: [27869, 27759, 26039, 25644, 29878, 29878],
    76001: [27979, 27867, 26153, 25740, 29982, 29982],
    76201: [28089, 27976, 26268, 25836, 30086, 30086],
    76401: [28199, 28088, 26386, 25932, 30190, 30190],
    76601: [28309, 28197, 26501, 26028, 30294, 30294],
    76801: [28419, 28309, 26619, 26124, 30398, 30398],
    77001: [28529, 28418, 26734, 26220, 30502, 30502],
    77201: [28639, 28526, 26848, 26316, 30606, 30606],
    77401: [28749, 28639, 26967, 26413, 30710, 30710],
    77601: [28859, 28747, 27081, 26509, 30814, 30814],
    77801: [28969, 28855, 27195, 26605, 30918, 30918],
    78001: [29079, 28968, 27314, 26701, 31022, 31022],
    78201: [29189, 29076, 27428, 26797, 31126, 31126],
    78401: [29299, 29189, 27547, 26893, 31230, 31230],
    78601: [29409, 29297, 27661, 26989, 31334, 31334],
    78801: [29519, 29406, 27776, 27085, 31438, 31438],
    79001: [29629, 29518, 27894, 27181, 31542, 31542],
    79201: [29739, 29627, 28009, 27278, 31646, 31646],
    79401: [29849, 29735, 28123, 27374, 31750, 31750],
    79601: [29959, 29848, 28242, 27470, 31854, 31854],
    79801: [30069, 29956, 28356, 27566, 31958, 31958],
    80001: [38, 37, 35, 34, 40, 40, '%'],
    80201: [38, 38, 36, 35, 40, 40, '%'],
    83801: [38, 38, 37, 35, 41, 41, '%'],
    84401: [39, 38, 37, 35, 41, 41, '%'],
    85001: [39, 39, 37, 35, 41, 41, '%'],
    86601: [39, 39, 37, 36, 41, 41, '%'],
    88001: [39, 39, 38, 36, 41, 41, '%'],
    89801: [40, 39, 38, 36, 41, 41, '%'],
    90601: [40, 40, 38, 36, 41, 41, '%'],
    91801: [40, 40, 38, 36, 42, 42, '%'],
    92401: [40, 40, 39, 36, 42, 42, '%'],
    94001: [40, 40, 39, 37, 42, 42, '%'],
    96001: [41, 40, 39, 37, 42, 42, '%'],
    96801: [41, 41, 39, 37, 42, 42, '%'],
    97401: [41, 41, 40, 37, 42, 42, '%'],
    101401: [41, 41, 40, 37, 43, 43, '%'],
    103001: [42, 41, 41, 38, 43, 43, '%'],
    104001: [42, 42, 41, 38, 43, 43, '%'],
    109201: [42, 42, 42, 38, 43, 43, '%'],
    111401: [43, 42, 42, 38, 43, 43, '%'],
    112201: [43, 43, 42, 38, 43, 43, '%'],
    113401: [43, 43, 42, 38, 44, 44, '%'],
    113801: [43, 43, 42, 39, 44, 44, '%'],
    116401: [43, 43, 43, 39, 44, 44, '%'],
    121001: [44, 43, 43, 39, 44, 44, '%'],
    122001: [44, 44, 43, 39, 44, 44, '%'],
    124401: [44, 44, 44, 39, 44, 44, '%'],
    127001: [44, 44, 44, 40, 44, 44, '%'],
    128401: [44, 44, 44, 40, 45, 45, '%'],
    132601: [45, 44, 44, 40, 45, 45, '%'],
    133801: [45, 45, 45, 40, 45, 45, '%'],
    144001: [45, 45, 45, 41, 45, 45, '%'],
    148201: [46, 46, 46, 41, 46, 46, '%'],
    165801: [46, 46, 46, 42, 46, 46, '%'],
    175201: [47, 47, 47, 42, 47, 47, '%'],
    195801: [47, 47, 47, 43, 47, 47, '%'],
    214201: [48, 48, 48, 43, 48, 48, '%'],
    238801: [48, 48, 48, 44, 48, 48, '%'],
    275401: [49, 49, 49, 44, 49, 49, '%'],
    306001: [49, 49, 49, 45, 49, 49, '%'],
    385601: [50, 50, 50, 45, 50, 50, '%'],
    426001: [50, 50, 50, 46, 50, 50, '%'],
    642601: [51, 51, 51, 46, 51, 51, '%'],
    700401: [51, 51, 51, 47, 51, 51, '%'],
    1928201: [52, 52, 52, 47, 52, 52, '%'],
    1968401: [52, 52, 52, 48, 52, 52, '%']
  },
  33: {
    1: [0, 0, 0, 0, 0, 0],
    1701: [126, 0, 125, 1, 126, 31],
    1801: [137, 0, 133, 4, 137, 65],
    1901: [147, 0, 141, 6, 147, 99],
    2001: [158, 0, 150, 8, 158, 133],
    2101: [160, 0, 150, 10, 167, 167],
    2201: [171, 0, 158, 12, 201, 201],
    2301: [181, 0, 166, 14, 235, 235],
    2401: [192, 0, 175, 17, 269, 269],
    2501: [202, 0, 183, 19, 303, 303],
    2601: [213, 0, 191, 21, 337, 337],
    2701: [223, 0, 200, 23, 371, 371],
    2801: [225, 0, 200, 25, 405, 405],
    2901: [236, 0, 208, 27, 439, 439],
    3001: [246, 0, 216, 30, 473, 473],
    3101: [257, 0, 225, 32, 507, 507],
    3201: [267, 0, 233, 34, 541, 541],
    3301: [278, 0, 241, 36, 575, 575],
    3401: [280, 0, 241, 38, 609, 609],
    3501: [290, 0, 250, 40, 643, 643],
    3601: [301, 0, 258, 50, 677, 677],
    3701: [311, 0, 266, 74, 711, 711],
    3801: [322, 0, 275, 97, 745, 745],
    3901: [332, 0, 283, 120, 779, 779],
    4001: [334, 0, 283, 142, 804, 804],
    4101: [344, 0, 291, 165, 832, 832],
    4201: [354, 0, 300, 188, 861, 861],
    4301: [364, 0, 308, 211, 886, 886],
    4401: [374, 5, 317, 233, 915, 915],
    4501: [384, 31, 326, 256, 940, 940],
    4601: [386, 56, 328, 279, 968, 968],
    4701: [396, 82, 338, 302, 997, 997],
    4801: [406, 107, 348, 324, 1022, 1022],
    4901: [416, 133, 358, 347, 1051, 1051],
    5001: [426, 158, 368, 370, 1076, 1076],
    5101: [436, 184, 378, 392, 1104, 1104],
    5201: [447, 209, 388, 415, 1133, 1133],
    5301: [448, 232, 389, 437, 1158, 1158],
    5401: [458, 257, 399, 460, 1185, 1185],
    5501: [468, 283, 409, 482, 1210, 1210],
    5601: [478, 308, 419, 504, 1238, 1238],
    5701: [494, 334, 429, 526, 1266, 1266],
    5801: [515, 359, 439, 548, 1291, 1291],
    5901: [535, 385, 441, 570, 1318, 1318],
    6001: [556, 410, 451, 592, 1343, 1343],
    6101: [577, 436, 461, 614, 1371, 1371],
    6201: [597, 461, 471, 637, 1399, 1399],
    6301: [618, 487, 480, 659, 1424, 1424],
    6401: [638, 512, 490, 681, 1451, 1451],
    6501: [659, 535, 492, 703, 1476, 1476],
    6601: [680, 560, 502, 725, 1504, 1504],
    6701: [700, 586, 512, 747, 1532, 1532],
    6801: [721, 611, 522, 769, 1557, 1557],
    6901: [742, 637, 532, 791, 1584, 1584],
    7001: [762, 662, 542, 813, 1609, 1609],
    7101: [783, 688, 543, 836, 1637, 1637],
    7201: [804, 713, 553, 858, 1665, 1665],
    7301: [824, 739, 563, 880, 1690, 1690],
    7401: [845, 764, 573, 902, 1717, 1717],
    7501: [865, 790, 583, 924, 1742, 1742],
    7601: [886, 815, 593, 946, 1770, 1770],
    7701: [907, 838, 603, 968, 1798, 1798],
    7801: [927, 864, 604, 991, 1823, 1823],
    7901: [948, 881, 614, 1013, 1850, 1850],
    8001: [968, 900, 623, 1035, 1875, 1875],
    8101: [989, 920, 633, 1057, 1903, 1903],
    8201: [1010, 943, 643, 1079, 1931, 1931],
    8301: [1030, 963, 652, 1101, 1956, 1956],
    8401: [1051, 983, 654, 1123, 1983, 1983],
    8501: [1072, 1002, 663, 1145, 2008, 2008],
    8601: [1092, 1025, 673, 1168, 2036, 2036],
    8701: [1113, 1045, 683, 1190, 2064, 2064],
    8801: [1134, 1065, 692, 1212, 2089, 2089],
    8901: [1154, 1085, 702, 1234, 2116, 2116],
    9001: [1175, 1107, 703, 1256, 2141, 2141],
    9101: [1196, 1127, 713, 1278, 2169, 2169],
    9201: [1216, 1147, 722, 1300, 2197, 2197],
    9301: [1237, 1169, 732, 1322, 2222, 2222],
    9401: [1257, 1188, 742, 1345, 2249, 2249],
    9501: [1278, 1208, 751, 1367, 2274, 2274],
    9601: [1299, 1227, 753, 1389, 2302, 2302],
    9701: [1319, 1249, 762, 1411, 2330, 2330],
    9801: [1340, 1269, 772, 1433, 2355, 2355],
    9901: [1361, 1288, 782, 1455, 2382, 2382],
    10001: [1381, 1307, 791, 1477, 2407, 2407],
    10101: [1402, 1330, 801, 1499, 2435, 2435],
    10201: [1423, 1349, 811, 1522, 2463, 2463],
    10301: [1443, 1368, 812, 1544, 2488, 2488],
    10401: [1464, 1388, 821, 1566, 2515, 2515],
    10501: [1484, 1410, 831, 1588, 2540, 2540],
    10601: [1505, 1429, 841, 1610, 2568, 2568],
    10701: [1526, 1449, 850, 1632, 2596, 2596],
    10801: [1546, 1468, 860, 1654, 2621, 2621],
    10901: [1567, 1490, 861, 1677, 2651, 2651],
    11001: [1588, 1510, 871, 1699, 2684, 2684],
    11101: [1609, 1529, 881, 1721, 2718, 2718],
    11201: [1630, 1551, 890, 1744, 2751, 2751],
    11301: [1651, 1571, 900, 1766, 2784, 2784],
    11401: [1672, 1590, 910, 1789, 2817, 2817],
    11501: [1693, 1609, 911, 1811, 2851, 2851],
    11601: [1713, 1632, 921, 1833, 2884, 2884],
    11701: [1734, 1651, 930, 1856, 2917, 2917],
    11801: [1755, 1670, 940, 1878, 2950, 2950],
    11901: [1776, 1690, 949, 1901, 2984, 2984],
    12001: [1797, 1712, 959, 1923, 3017, 3017],
    12101: [1818, 1731, 960, 1946, 3050, 3050],
    12201: [1839, 1751, 970, 1968, 3083, 3083],
    12301: [1860, 1770, 980, 1990, 3117, 3117],
    12401: [1881, 1792, 989, 2013, 3150, 3150],
    12501: [1902, 1812, 999, 2035, 3186, 3186],
    12601: [1923, 1831, 1009, 2058, 3222, 3222],
    12701: [1944, 1850, 1018, 2080, 3258, 3258],
    12801: [1965, 1873, 1020, 2103, 3294, 3294],
    12901: [1986, 1892, 1029, 2125, 3330, 3330],
    13001: [2013, 1917, 1039, 2152, 3369, 3369],
    13101: [2042, 1947, 1049, 2182, 3405, 3405],
    13201: [2071, 1975, 1060, 2211, 3441, 3441],
    13301: [2101, 2006, 1070, 2240, 3477, 3477],
    13401: [2130, 2033, 1072, 2270, 3513, 3513],
    13501: [2159, 2061, 1082, 2299, 3552, 3552],
    13601: [2189, 2092, 1092, 2328, 3588, 3588],
    13701: [2218, 2119, 1102, 2358, 3624, 3624],
    13801: [2247, 2150, 1113, 2387, 3660, 3660],
    13901: [2277, 2177, 1123, 2416, 3696, 3696],
    14001: [2305, 2205, 1124, 2445, 3734, 3734],
    14101: [2333, 2236, 1135, 2473, 3769, 3769],
    14201: [2361, 2263, 1145, 2501, 3804, 3804],
    14301: [2390, 2294, 1155, 2529, 3838, 3838],
    14401: [2418, 2322, 1165, 2557, 3873, 3873],
    14501: [2446, 2349, 1176, 2586, 3911, 3911],
    14601: [2474, 2380, 1178, 2614, 3946, 3946],
    14701: [2502, 2407, 1188, 2642, 3981, 3981],
    14801: [2531, 2438, 1198, 2670, 4016, 4016],
    14901: [2559, 2466, 1208, 2699, 4051, 4051],
    15001: [2587, 2493, 1218, 2727, 4088, 4088],
    15101: [2615, 2524, 1229, 2755, 4123, 4123],
    15201: [2644, 2551, 1239, 2783, 4158, 4158],
    15301: [2672, 2582, 1241, 2811, 4193, 4193],
    15401: [2700, 2610, 1251, 2840, 4228, 4228],
    15501: [2728, 2637, 1261, 2868, 4266, 4266],
    15601: [2756, 2668, 1271, 2896, 4301, 4301],
    15701: [2785, 2696, 1281, 2924, 4336, 4336],
    15801: [2813, 2726, 1292, 2952, 4371, 4371],
    15901: [2841, 2754, 1293, 2981, 4406, 4406],
    16001: [2869, 2781, 1304, 3009, 4443, 4443],
    16101: [2897, 2812, 1314, 3037, 4478, 4478],
    16201: [2926, 2840, 1324, 3065, 4513, 4513],
    16301: [2954, 2870, 1334, 3094, 4548, 4548],
    16401: [2982, 2898, 1344, 3122, 4583, 4583],
    16501: [3010, 2926, 1346, 3150, 4621, 4621],
    16601: [3039, 2956, 1357, 3178, 4655, 4655],
    16701: [3067, 2984, 1367, 3206, 4690, 4690],
    16801: [3095, 3014, 1377, 3235, 4725, 4725],
    16901: [3123, 3042, 1387, 3263, 4760, 4760],
    17001: [3151, 3070, 1397, 3291, 4798, 4798],
    17101: [3180, 3100, 1399, 3319, 4833, 4833],
    17201: [3208, 3128, 1409, 3348, 4868, 4868],
    17301: [3236, 3158, 1420, 3376, 4903, 4903],
    17401: [3264, 3186, 1430, 3404, 4938, 4938],
    17501: [3293, 3216, 1440, 3432, 4975, 4975],
    17601: [3321, 3244, 1450, 3460, 5010, 5010],
    17701: [3349, 3272, 1460, 3489, 5045, 5045],
    17801: [3377, 3302, 1462, 3517, 5080, 5080],
    17901: [3405, 3330, 1473, 3545, 5115, 5115],
    18001: [3434, 3361, 1483, 3573, 5153, 5153],
    18101: [3462, 3388, 1493, 3601, 5188, 5188],
    18201: [3490, 3416, 1503, 3630, 5223, 5223],
    18301: [3518, 3446, 1513, 3658, 5257, 5257],
    18401: [3546, 3474, 1515, 3686, 5292, 5292],
    18501: [3575, 3505, 1526, 3714, 5330, 5330],
    18601: [3603, 3532, 1536, 3743, 5365, 5365],
    18701: [3631, 3560, 1546, 3771, 5400, 5400],
    18801: [3659, 3591, 1556, 3799, 5435, 5435],
    18901: [3688, 3618, 1566, 3827, 5470, 5470],
    19001: [3716, 3649, 1568, 3855, 5507, 5507],
    19101: [3744, 3676, 1578, 3884, 5542, 5542],
    19201: [3772, 3704, 1588, 3912, 5577, 5577],
    19301: [3800, 3734, 1598, 3940, 5612, 5612],
    19401: [3829, 3761, 1607, 3968, 5647, 5647],
    19501: [3857, 3790, 1617, 3997, 5685, 5685],
    19601: [3885, 3817, 1618, 4025, 5720, 5720],
    19701: [3913, 3844, 1627, 4053, 5755, 5755],
    19801: [3941, 3873, 1636, 4081, 5790, 5790],
    19901: [3970, 3900, 1650, 4109, 5825, 5825],
    20001: [4026, 3957, 1697, 4166, 5897, 5897],
    20201: [4083, 4013, 1743, 4222, 5967, 5967],
    20401: [4139, 4070, 1790, 4279, 6040, 6040],
    20601: [4195, 4123, 1833, 4335, 6109, 6109],
    20801: [4252, 4180, 1880, 4392, 6179, 6179],
    21001: [4308, 4236, 1926, 4448, 6252, 6252],
    21201: [4365, 4293, 1973, 4504, 6322, 6322],
    21401: [4421, 4349, 2019, 4561, 6394, 6394],
    21601: [4478, 4403, 2063, 4617, 6464, 6464],
    21801: [4534, 4459, 2109, 4674, 6534, 6534],
    22001: [4591, 4516, 2156, 4731, 6607, 6607],
    22201: [4649, 4572, 2202, 4789, 6679, 6679],
    22401: [4707, 4631, 2251, 4847, 6753, 6753],
    22601: [4765, 4688, 2298, 4905, 6824, 6824],
    22801: [4823, 4744, 2344, 4963, 6896, 6896],
    23001: [4881, 4803, 2393, 5021, 6970, 6970],
    23201: [4939, 4860, 2440, 5079, 7042, 7042],
    23401: [4997, 4916, 2486, 5137, 7116, 7116],
    23601: [5056, 4975, 2535, 5195, 7187, 7187],
    23801: [5114, 5032, 2582, 5253, 7259, 7259],
    24001: [5172, 5088, 2628, 5311, 7333, 7333],
    24201: [5230, 5145, 2675, 5369, 7405, 7405],
    24401: [5288, 5204, 2724, 5428, 7479, 7479],
    24601: [5346, 5260, 2770, 5486, 7550, 7550],
    24801: [5404, 5317, 2817, 5544, 7622, 7622],
    25001: [5462, 5376, 2876, 5602, 7696, 7696],
    25201: [5520, 5432, 2932, 5660, 7768, 7768],
    25401: [5578, 5489, 2989, 5718, 7842, 7842],
    25601: [5636, 5548, 3048, 5776, 7913, 7913],
    25801: [5694, 5604, 3104, 5834, 7985, 7985],
    26001: [5752, 5661, 3161, 5892, 8059, 8059],
    26201: [5811, 5720, 3220, 5950, 8131, 8131],
    26401: [5869, 5776, 3276, 6008, 8205, 8205],
    26601: [5927, 5833, 3333, 6066, 8276, 8276],
    26801: [5985, 5889, 3389, 6124, 8348, 8348],
    27001: [6043, 5948, 3448, 6183, 8422, 8422],
    27201: [6101, 6005, 3505, 6241, 8494, 8494],
    27401: [6159, 6061, 3561, 6299, 8568, 8568],
    27601: [6217, 6120, 3620, 6357, 8639, 8639],
    27801: [6275, 6177, 3677, 6415, 8711, 8711],
    28001: [6333, 6233, 3733, 6473, 8785, 8785],
    28201: [6391, 6292, 3792, 6531, 8857, 8857],
    28401: [6449, 6349, 3849, 6589, 8931, 8931],
    28601: [6508, 6405, 3905, 6647, 9002, 9002],
    28801: [6566, 6464, 3964, 6705, 9074, 9074],
    29001: [6624, 6521, 4021, 6763, 9148, 9148],
    29201: [6682, 6577, 4077, 6821, 9220, 9220],
    29401: [6740, 6637, 4137, 6880, 9294, 9294],
    29601: [6798, 6695, 4195, 6938, 9365, 9365],
    29801: [6856, 6753, 4253, 6996, 9437, 9437],
    30001: [6914, 6811, 4311, 7054, 9511, 9511],
    30201: [6972, 6871, 4371, 7112, 9583, 9583],
    30401: [7030, 6929, 4429, 7170, 9657, 9657],
    30601: [7088, 6987, 4487, 7228, 9728, 9728],
    30801: [7146, 7047, 4547, 7286, 9800, 9800],
    31001: [7205, 7105, 4605, 7344, 9874, 9874],
    31201: [7263, 7163, 4663, 7402, 9946, 9946],
    31401: [7321, 7223, 4723, 7460, 10020, 10020],
    31601: [7379, 7278, 4778, 7518, 10089, 10089],
    31801: [7436, 7336, 4836, 7576, 10155, 10155],
    32001: [7494, 7394, 4894, 7634, 10221, 10221],
    32201: [7552, 7451, 4951, 7692, 10287, 10287],
    32401: [7613, 7512, 5012, 7750, 10353, 10353],
    32601: [7679, 7578, 5078, 7808, 10419, 10419],
    32801: [7745, 7644, 5144, 7865, 10485, 10485],
    33001: [7811, 7710, 5210, 7923, 10551, 10551],
    33201: [7877, 7776, 5276, 7981, 10617, 10617],
    33401: [7943, 7842, 5342, 8039, 10683, 10683],
    33601: [8009, 7908, 5408, 8097, 10749, 10749],
    33801: [8075, 7974, 5474, 8155, 10815, 10815],
    34001: [8141, 8040, 5540, 8213, 10881, 10881],
    34201: [8207, 8106, 5606, 8270, 10947, 10947],
    34401: [8273, 8172, 5672, 8328, 11013, 11013],
    34601: [8339, 8238, 5738, 8386, 11079, 11079],
    34801: [8405, 8304, 5804, 8444, 11145, 11145],
    35001: [8471, 8370, 5870, 8502, 11211, 11211],
    35201: [8537, 8436, 5936, 8560, 11277, 11277],
    35401: [8603, 8502, 6002, 8617, 11343, 11343],
    35601: [8669, 8568, 6068, 8675, 11409, 11409],
    35801: [8735, 8634, 6134, 8733, 11475, 11475],
    36001: [8801, 8700, 6200, 8791, 11541, 11541],
    36201: [8867, 8766, 6266, 8849, 11607, 11607],
    36401: [8933, 8832, 6332, 8907, 11673, 11673],
    36601: [8999, 8898, 6398, 8965, 11739, 11739],
    36801: [9065, 8964, 6464, 9022, 11805, 11805],
    37001: [9131, 9030, 6530, 9080, 11871, 11871],
    37201: [9197, 9096, 6596, 9138, 11937, 11937],
    37401: [9263, 9162, 6662, 9196, 12003, 12003],
    37601: [9329, 9228, 6728, 9254, 12069, 12069],
    37801: [9395, 9294, 6794, 9312, 12135, 12135],
    38001: [9461, 9360, 6860, 9369, 12201, 12201],
    38201: [9527, 9426, 6926, 9427, 12267, 12267],
    38401: [9593, 9492, 6992, 9485, 12333, 12333],
    38601: [9659, 9558, 7058, 9543, 12399, 12399],
    38801: [9725, 9624, 7124, 9601, 12465, 12465],
    39001: [9791, 9690, 7190, 9659, 12531, 12531],
    39201: [9857, 9756, 7256, 9717, 12597, 12597],
    39401: [9923, 9822, 7322, 9774, 12663, 12663],
    39601: [9989, 9888, 7388, 9832, 12729, 12729],
    39801: [10055, 9954, 7454, 9890, 12795, 12795],
    40001: [10121, 10020, 7520, 9948, 12861, 12861],
    40201: [10187, 10086, 7586, 10006, 12927, 12927],
    40401: [10253, 10152, 7652, 10064, 12993, 12993],
    40601: [10319, 10218, 7718, 10122, 13059, 13059],
    40801: [10385, 10284, 7784, 10179, 13125, 13125],
    41001: [10451, 10350, 7850, 10237, 13191, 13191],
    41201: [10517, 10416, 7916, 10295, 13257, 13257],
    41401: [10583, 10482, 7982, 10353, 13323, 13323],
    41601: [10649, 10548, 8048, 10411, 13389, 13389],
    41801: [10715, 10614, 8114, 10469, 13455, 13455],
    42001: [10781, 10680, 8180, 10526, 13521, 13521],
    42201: [10847, 10746, 8246, 10584, 13587, 13587],
    42401: [10913, 10812, 8312, 10642, 13653, 13653],
    42601: [10979, 10878, 8378, 10700, 13719, 13719],
    42801: [11045, 10944, 8444, 10758, 13785, 13785],
    43001: [11111, 11010, 8510, 10816, 13851, 13851],
    43201: [11177, 11076, 8576, 10874, 13917, 13917],
    43401: [11243, 11142, 8642, 10931, 13983, 13983],
    43601: [11309, 11208, 8708, 10989, 14049, 14049],
    43801: [11375, 11274, 8774, 11047, 14115, 14115],
    44001: [11441, 11340, 8840, 11105, 14181, 14181],
    44201: [11507, 11406, 8906, 11163, 14247, 14247],
    44401: [11573, 11472, 8972, 11221, 14313, 14313],
    44601: [11639, 11538, 9038, 11279, 14379, 14379],
    44801: [11705, 11604, 9104, 11336, 14445, 14445],
    45001: [11771, 11670, 9170, 11394, 14511, 14511],
    45201: [11837, 11736, 9236, 11452, 14577, 14577],
    45401: [11903, 11802, 9302, 11510, 14643, 14643],
    45601: [11969, 11868, 9368, 11568, 14709, 14709],
    45801: [12035, 11934, 9434, 11626, 14775, 14775],
    46001: [12101, 12000, 9500, 11683, 14841, 14841],
    46201: [12198, 12104, 9604, 11773, 14938, 14938],
    46401: [12304, 12212, 9712, 11871, 15044, 15044],
    46601: [12410, 12319, 9819, 11969, 15150, 15150],
    46801: [12516, 12426, 9926, 12067, 15256, 15256],
    47001: [12622, 12533, 10033, 12164, 15362, 15362],
    47201: [12728, 12638, 10138, 12262, 15468, 15468],
    47401: [12834, 12745, 10245, 12360, 15574, 15574],
    47601: [12940, 12852, 10352, 12458, 15680, 15680],
    47801: [13046, 12960, 10460, 12556, 15786, 15786],
    48001: [13152, 13067, 10567, 12654, 15892, 15892],
    48201: [13258, 13174, 10674, 12751, 15998, 15998],
    48401: [13364, 13281, 10781, 12849, 16104, 16104],
    48601: [13470, 13389, 10889, 12947, 16210, 16210],
    48801: [13576, 13493, 10993, 13045, 16316, 16316],
    49001: [13682, 13600, 11100, 13143, 16422, 16422],
    49201: [13788, 13708, 11208, 13241, 16528, 16528],
    49401: [13894, 13815, 11315, 13339, 16634, 16634],
    49601: [14000, 13922, 11422, 13436, 16740, 16740],
    49801: [14106, 14029, 11529, 13534, 16846, 16846],
    50001: [14212, 14137, 11643, 13632, 16952, 16952],
    50201: [14318, 14244, 11756, 13730, 17058, 17058],
    50401: [14424, 14348, 11866, 13828, 17164, 17164],
    50601: [14530, 14456, 11980, 13926, 17270, 17270],
    50801: [14636, 14563, 12093, 14024, 17376, 17376],
    51001: [14742, 14670, 12206, 14121, 17482, 17482],
    51201: [14848, 14777, 12319, 14219, 17588, 17588],
    51401: [14954, 14876, 12424, 14317, 17694, 17694],
    51601: [15060, 14982, 12536, 14415, 17800, 17800],
    51801: [15166, 15088, 12648, 14513, 17906, 17906],
    52001: [15272, 15194, 12760, 14611, 18012, 18012],
    52201: [15378, 15300, 12872, 14708, 18118, 18118],
    52401: [15484, 15406, 12984, 14806, 18224, 18224],
    52601: [15590, 15512, 13096, 14904, 18330, 18330],
    52801: [15696, 15618, 13208, 15002, 18436, 18436],
    53001: [15802, 15724, 13320, 15100, 18542, 18542],
    53201: [15908, 15830, 13432, 15198, 18648, 18648],
    53401: [16014, 15936, 13544, 15296, 18754, 18754],
    53601: [16120, 16042, 13656, 15393, 18860, 18860],
    53801: [16226, 16148, 13768, 15491, 18966, 18966],
    54001: [16332, 16254, 13880, 15589, 19072, 19072],
    54201: [16438, 16360, 13992, 15687, 19178, 19178],
    54401: [16547, 16471, 14109, 15785, 19284, 19284],
    54601: [16659, 16586, 14230, 15883, 19390, 19390],
    54801: [16771, 16696, 14346, 15980, 19496, 19496],
    55001: [16883, 16806, 14462, 16078, 19602, 19602],
    55201: [16995, 16921, 14583, 16176, 19708, 19708],
    55401: [17107, 17032, 14700, 16274, 19814, 19814],
    55601: [17219, 17142, 14816, 16372, 19920, 19920],
    55801: [17331, 17257, 14937, 16470, 20026, 20026],
    56001: [17443, 17367, 15053, 16568, 20132, 20132],
    56201: [17555, 17482, 15174, 16665, 20238, 20238],
    56401: [17667, 17593, 15291, 16763, 20344, 20344],
    56601: [17779, 17703, 15407, 16861, 20450, 20450],
    56801: [17891, 17818, 15528, 16959, 20556, 20556],
    57001: [18003, 17928, 15644, 17057, 20662, 20662],
    57201: [18115, 18043, 15765, 17155, 20768, 20768],
    57401: [18227, 18154, 15882, 17253, 20874, 20874],
    57601: [18339, 18264, 15998, 17350, 20980, 20980],
    57801: [18451, 18379, 16119, 17448, 21086, 21086],
    58001: [18563, 18489, 16235, 17546, 21192, 21192],
    58201: [18675, 18600, 16352, 17644, 21298, 21298],
    58401: [18787, 18714, 16472, 17742, 21404, 21404],
    58601: [18899, 18825, 16589, 17840, 21510, 21510],
    58801: [19011, 18940, 16710, 17937, 21616, 21616],
    59001: [19123, 19050, 16826, 18035, 21722, 21722],
    59201: [19235, 19161, 16943, 18133, 21828, 21828],
    59401: [19347, 19275, 17063, 18231, 21934, 21934],
    59601: [19459, 19386, 17180, 18329, 22040, 22040],
    59801: [19571, 19496, 17296, 18427, 22146, 22146],
    60001: [19683, 19611, 17417, 18525, 22252, 22252],
    60201: [19795, 19721, 17533, 18622, 22358, 22358],
    60401: [19907, 19836, 17654, 18720, 22464, 22464],
    60601: [20019, 19947, 17771, 18818, 22570, 22570],
    60801: [20131, 20057, 17887, 18916, 22676, 22676],
    61001: [20243, 20172, 18008, 19014, 22782, 22782],
    61201: [20355, 20282, 18124, 19112, 22888, 22888],
    61401: [20467, 20393, 18241, 19210, 22994, 22994],
    61601: [20579, 20508, 18362, 19307, 23100, 23100],
    61801: [20691, 20618, 18478, 19405, 23206, 23206],
    62001: [20803, 20733, 18599, 19503, 23312, 23312],
    62201: [20915, 20843, 18715, 19601, 23418, 23418],
    62401: [21027, 20954, 18832, 19699, 23524, 23524],
    62601: [21139, 21069, 18953, 19797, 23630, 23630],
    62801: [21251, 21179, 19069, 19894, 23736, 23736],
    63001: [21363, 21289, 19185, 19992, 23842, 23842],
    63201: [21475, 21404, 19306, 20090, 23948, 23948],
    63401: [21587, 21515, 19423, 20188, 24054, 24054],
    63601: [21699, 21629, 19543, 20286, 24160, 24160],
    63801: [21811, 21740, 19660, 20384, 24266, 24266],
    64001: [21923, 21850, 19776, 20482, 24372, 24372],
    64201: [22035, 21965, 19897, 20579, 24478, 24478],
    64401: [22147, 22076, 20014, 20677, 24584, 24584],
    64601: [22259, 22186, 20130, 20775, 24690, 24690],
    64801: [22371, 22301, 20251, 20873, 24796, 24796],
    65001: [22483, 22411, 20367, 20971, 24902, 24902],
    65201: [22595, 22526, 20488, 21069, 25008, 25008],
    65401: [22707, 22636, 20604, 21166, 25114, 25114],
    65601: [22819, 22747, 20721, 21264, 25220, 25220],
    65801: [22931, 22862, 20842, 21362, 25326, 25326],
    66001: [23043, 22972, 20958, 21460, 25432, 25432],
    66201: [23155, 23083, 21075, 21558, 25538, 25538],
    66401: [23267, 23197, 21195, 21656, 25644, 25644],
    66601: [23379, 23308, 21312, 21754, 25750, 25750],
    66801: [23491, 23423, 21433, 21851, 25856, 25856],
    67001: [23603, 23533, 21549, 21949, 25962, 25962],
    67201: [23715, 23643, 21665, 22047, 26068, 26068],
    67401: [23827, 23758, 21786, 22145, 26174, 26174],
    67601: [23939, 23869, 21903, 22243, 26280, 26280],
    67801: [24051, 23984, 22024, 22341, 26386, 26386],
    68001: [24163, 24094, 22140, 22439, 26492, 26492],
    68201: [24275, 24204, 22256, 22536, 26598, 26598],
    68401: [24387, 24319, 22377, 22634, 26704, 26704],
    68601: [24499, 24430, 22494, 22732, 26810, 26810],
    68801: [24611, 24540, 22610, 22830, 26916, 26916],
    69001: [24723, 24655, 22731, 22928, 27022, 27022],
    69201: [24835, 24765, 22847, 23026, 27128, 27128],
    69401: [24947, 24880, 22968, 23123, 27234, 27234],
    69601: [25059, 24991, 23085, 23221, 27340, 27340],
    69801: [25171, 25101, 23201, 23319, 27446, 27446],
    70001: [25283, 25216, 23322, 23417, 27552, 27552],
    70201: [25395, 25326, 23438, 23515, 27658, 27658],
    70401: [25507, 25437, 23555, 23613, 27764, 27764],
    70601: [25619, 25551, 23675, 23711, 27870, 27870],
    70801: [25731, 25662, 23792, 23808, 27976, 27976],
    71001: [25843, 25777, 23913, 23906, 28082, 28082],
    71201: [25955, 25887, 24029, 24004, 28188, 28188],
    71401: [26067, 25998, 24146, 24102, 28294, 28294],
    71601: [26179, 26112, 24266, 24200, 28400, 28400],
    71801: [26291, 26223, 24383, 24298, 28506, 28506],
    72001: [26403, 26333, 24499, 24395, 28612, 28612],
    72201: [26515, 26448, 24620, 24493, 28718, 28718],
    72401: [26627, 26558, 24736, 24591, 28824, 28824],
    72601: [26739, 26673, 24857, 24689, 28930, 28930],
    72801: [26851, 26784, 24974, 24787, 29036, 29036],
    73001: [26963, 26894, 25090, 24885, 29142, 29142],
    73201: [27075, 27009, 25211, 24983, 29248, 29248],
    73401: [27187, 27119, 25327, 25080, 29354, 29354],
    73601: [27299, 27230, 25444, 25178, 29460, 29460],
    73801: [27411, 27345, 25565, 25276, 29566, 29566],
    74001: [27523, 27455, 25681, 25374, 29672, 29672],
    74201: [27635, 27570, 25802, 25472, 29778, 29778],
    74401: [27747, 27680, 25918, 25570, 29884, 29884],
    74601: [27859, 27791, 26035, 25668, 29990, 29990],
    74801: [27971, 27906, 26156, 25765, 30096, 30096],
    75001: [28083, 28016, 26272, 25863, 30202, 30202],
    75201: [28195, 28126, 26388, 25961, 30308, 30308],
    75401: [28307, 28241, 26509, 26059, 30414, 30414],
    75601: [28419, 28352, 26626, 26157, 30520, 30520],
    75801: [28531, 28466, 26746, 26255, 30626, 30626],
    76001: [28643, 28577, 26863, 26352, 30732, 30732],
    76201: [28755, 28687, 26979, 26450, 30838, 30838],
    76401: [28867, 28802, 27100, 26548, 30944, 30944],
    76601: [28979, 28913, 27217, 26646, 31050, 31050],
    76801: [29091, 29027, 27337, 26744, 31156, 31156],
    77001: [29203, 29138, 27454, 26842, 31262, 31262],
    77201: [29315, 29248, 27570, 26940, 31368, 31368],
    77401: [29427, 29363, 27691, 27037, 31474, 31474],
    77601: [29539, 29473, 27807, 27135, 31580, 31580],
    77801: [29651, 29584, 27924, 27233, 31686, 31686],
    78001: [29763, 29699, 28045, 27331, 31792, 31792],
    78201: [29875, 29809, 28161, 27429, 31898, 31898],
    78401: [29987, 29924, 28282, 27527, 32004, 32004],
    78601: [30099, 30034, 28398, 27625, 32110, 32110],
    78801: [30211, 30145, 28515, 27722, 32216, 32216],
    79001: [30323, 30260, 28636, 27820, 32322, 32322],
    79201: [30435, 30370, 28752, 27918, 32428, 32428],
    79401: [30547, 30480, 28868, 28016, 32534, 32534],
    79601: [30659, 30595, 28989, 28114, 32640, 32640],
    79801: [30771, 30706, 29106, 28212, 32746, 32746],
    80001: [38, 38, 36, 35, 41, 41, '%'],
    80201: [39, 38, 36, 35, 41, 41, '%'],
    80401: [39, 39, 37, 35, 41, 41, '%'],
    81201: [39, 39, 37, 36, 41, 41, '%'],
    83801: [39, 39, 37, 36, 42, 42, '%'],
    84001: [39, 39, 38, 36, 42, 42, '%'],
    85001: [40, 39, 38, 36, 42, 42, '%'],
    85401: [40, 40, 38, 36, 42, 42, '%'],
    87801: [40, 40, 38, 37, 42, 42, '%'],
    88201: [40, 40, 39, 37, 42, 42, '%'],
    90401: [41, 40, 39, 37, 42, 42, '%'],
    90801: [41, 41, 39, 37, 42, 42, '%'],
    91801: [41, 41, 39, 37, 43, 43, '%'],
    92601: [41, 41, 40, 37, 43, 43, '%'],
    95601: [41, 41, 40, 38, 43, 43, '%'],
    96601: [42, 41, 40, 38, 43, 43, '%'],
    97001: [42, 42, 40, 38, 43, 43, '%'],
    97601: [42, 42, 41, 38, 43, 43, '%'],
    101601: [42, 42, 41, 38, 44, 44, '%'],
    103201: [42, 42, 42, 38, 44, 44, '%'],
    103801: [43, 42, 42, 38, 44, 44, '%'],
    104201: [43, 43, 42, 38, 44, 44, '%'],
    104801: [43, 43, 42, 39, 44, 44, '%'],
    109401: [43, 43, 43, 39, 44, 44, '%'],
    112201: [44, 43, 43, 39, 44, 44, '%'],
    112601: [44, 44, 43, 39, 44, 44, '%'],
    113401: [44, 44, 43, 39, 45, 45, '%'],
    115801: [44, 44, 43, 40, 45, 45, '%'],
    116601: [44, 44, 44, 40, 45, 45, '%'],
    121801: [45, 44, 44, 40, 45, 45, '%'],
    122401: [45, 45, 44, 40, 45, 45, '%'],
    124601: [45, 45, 45, 40, 45, 45, '%'],
    128601: [45, 45, 45, 40, 46, 46, '%'],
    129601: [45, 45, 45, 41, 46, 46, '%'],
    133601: [46, 45, 45, 41, 46, 46, '%'],
    134001: [46, 46, 46, 41, 46, 46, '%'],
    147001: [46, 46, 46, 42, 46, 46, '%'],
    148401: [47, 47, 47, 42, 47, 47, '%'],
    170001: [47, 47, 47, 43, 47, 47, '%'],
    175401: [48, 48, 48, 43, 48, 48, '%'],
    201401: [48, 48, 48, 44, 48, 48, '%'],
    214401: [49, 49, 49, 44, 49, 49, '%'],
    246801: [49, 49, 49, 45, 49, 49, '%'],
    275801: [50, 50, 50, 45, 50, 50, '%'],
    319001: [50, 50, 50, 46, 50, 50, '%'],
    386001: [51, 51, 51, 46, 51, 51, '%'],
    450601: [51, 51, 51, 47, 51, 51, '%'],
    643401: [52, 52, 52, 47, 52, 52, '%'],
    767001: [52, 52, 52, 48, 52, 52, '%'],
    1930601: [53, 53, 53, 48, 53, 53, '%']
  },
  34: {
    1: [0, 0, 0, 0, 0, 0],
    1701: [126, 0, 125, 1, 126, 32],
    1801: [137, 0, 133, 4, 137, 67],
    1901: [147, 0, 141, 6, 147, 102],
    2001: [158, 0, 150, 8, 158, 137],
    2101: [160, 0, 150, 10, 172, 172],
    2201: [171, 0, 158, 12, 207, 207],
    2301: [181, 0, 166, 14, 242, 242],
    2401: [192, 0, 175, 17, 277, 277],
    2501: [202, 0, 183, 19, 312, 312],
    2601: [213, 0, 191, 21, 347, 347],
    2701: [223, 0, 200, 23, 382, 382],
    2801: [225, 0, 200, 25, 417, 417],
    2901: [236, 0, 208, 27, 452, 452],
    3001: [246, 0, 216, 30, 487, 487],
    3101: [257, 0, 225, 32, 522, 522],
    3201: [267, 0, 233, 34, 557, 557],
    3301: [278, 0, 241, 36, 592, 592],
    3401: [280, 0, 241, 38, 627, 627],
    3501: [290, 0, 250, 40, 662, 662],
    3601: [301, 0, 258, 51, 697, 697],
    3701: [311, 0, 266, 74, 732, 732],
    3801: [322, 0, 275, 98, 767, 767],
    3901: [332, 0, 283, 122, 802, 802],
    4001: [334, 0, 283, 145, 828, 828],
    4101: [344, 0, 291, 169, 857, 857],
    4201: [354, 0, 300, 192, 886, 886],
    4301: [364, 0, 308, 216, 912, 912],
    4401: [374, 5, 317, 239, 942, 942],
    4501: [384, 32, 326, 262, 968, 968],
    4601: [386, 58, 328, 286, 997, 997],
    4701: [396, 84, 338, 309, 1026, 1026],
    4801: [406, 110, 348, 332, 1052, 1052],
    4901: [416, 137, 358, 356, 1082, 1082],
    5001: [426, 163, 368, 379, 1108, 1108],
    5101: [436, 189, 378, 403, 1137, 1137],
    5201: [447, 215, 388, 426, 1166, 1166],
    5301: [448, 239, 389, 449, 1192, 1192],
    5401: [458, 265, 399, 472, 1220, 1220],
    5501: [468, 291, 409, 494, 1246, 1246],
    5601: [486, 317, 419, 517, 1275, 1275],
    5701: [507, 344, 429, 540, 1303, 1303],
    5801: [528, 370, 439, 563, 1329, 1329],
    5901: [550, 396, 441, 586, 1357, 1357],
    6001: [571, 422, 451, 608, 1383, 1383],
    6101: [592, 449, 461, 631, 1412, 1412],
    6201: [613, 475, 471, 654, 1440, 1440],
    6301: [635, 501, 480, 677, 1466, 1466],
    6401: [656, 527, 490, 700, 1494, 1494],
    6501: [677, 551, 492, 722, 1520, 1520],
    6601: [698, 577, 502, 745, 1549, 1549],
    6701: [720, 603, 512, 768, 1577, 1577],
    6801: [741, 629, 522, 791, 1603, 1603],
    6901: [762, 656, 532, 813, 1631, 1631],
    7001: [783, 682, 542, 836, 1657, 1657],
    7101: [805, 708, 543, 859, 1686, 1686],
    7201: [826, 734, 553, 882, 1714, 1714],
    7301: [847, 761, 563, 905, 1740, 1740],
    7401: [868, 787, 573, 927, 1768, 1768],
    7501: [889, 813, 583, 950, 1794, 1794],
    7601: [911, 839, 593, 973, 1823, 1823],
    7701: [932, 863, 603, 996, 1851, 1851],
    7801: [953, 889, 604, 1019, 1877, 1877],
    7901: [975, 907, 614, 1041, 1905, 1905],
    8001: [996, 927, 623, 1064, 1931, 1931],
    8101: [1017, 947, 633, 1087, 1960, 1960],
    8201: [1038, 971, 643, 1110, 1988, 1988],
    8301: [1059, 991, 652, 1132, 2014, 2014],
    8401: [1081, 1012, 654, 1155, 2042, 2042],
    8501: [1102, 1032, 663, 1178, 2068, 2068],
    8601: [1123, 1055, 673, 1201, 2097, 2097],
    8701: [1144, 1076, 683, 1224, 2125, 2125],
    8801: [1166, 1096, 692, 1246, 2151, 2151],
    8901: [1187, 1117, 702, 1269, 2179, 2179],
    9001: [1208, 1140, 703, 1292, 2205, 2205],
    9101: [1229, 1160, 713, 1315, 2234, 2234],
    9201: [1251, 1180, 722, 1338, 2262, 2262],
    9301: [1272, 1203, 732, 1360, 2288, 2288],
    9401: [1293, 1223, 742, 1383, 2316, 2316],
    9501: [1314, 1243, 751, 1406, 2342, 2342],
    9601: [1336, 1263, 753, 1429, 2371, 2371],
    9701: [1357, 1286, 762, 1451, 2399, 2399],
    9801: [1378, 1306, 772, 1474, 2425, 2425],
    9901: [1399, 1326, 782, 1497, 2453, 2453],
    10001: [1421, 1346, 791, 1520, 2479, 2479],
    10101: [1442, 1369, 801, 1543, 2508, 2508],
    10201: [1463, 1389, 811, 1565, 2536, 2536],
    10301: [1484, 1409, 812, 1588, 2562, 2562],
    10401: [1506, 1429, 821, 1611, 2590, 2590],
    10501: [1527, 1452, 831, 1634, 2616, 2616],
    10601: [1548, 1472, 841, 1657, 2645, 2645],
    10701: [1569, 1492, 850, 1679, 2673, 2673],
    10801: [1591, 1512, 860, 1702, 2699, 2699],
    10901: [1612, 1534, 861, 1725, 2730, 2730],
    11001: [1634, 1554, 871, 1748, 2764, 2764],
    11101: [1655, 1574, 881, 1771, 2799, 2799],
    11201: [1677, 1597, 890, 1794, 2833, 2833],
    11301: [1698, 1617, 900, 1817, 2867, 2867],
    11401: [1720, 1637, 910, 1840, 2901, 2901],
    11501: [1741, 1657, 911, 1863, 2936, 2936],
    11601: [1763, 1680, 921, 1886, 2970, 2970],
    11701: [1784, 1700, 930, 1910, 3004, 3004],
    11801: [1806, 1720, 940, 1933, 3038, 3038],
    11901: [1827, 1740, 949, 1956, 3073, 3073],
    12001: [1849, 1763, 959, 1979, 3107, 3107],
    12101: [1870, 1783, 960, 2002, 3141, 3141],
    12201: [1892, 1803, 970, 2025, 3175, 3175],
    12301: [1913, 1823, 980, 2048, 3210, 3210],
    12401: [1935, 1845, 989, 2071, 3244, 3244],
    12501: [1957, 1865, 999, 2094, 3281, 3281],
    12601: [1978, 1885, 1009, 2117, 3318, 3318],
    12701: [2000, 1905, 1018, 2141, 3355, 3355],
    12801: [2021, 1928, 1020, 2164, 3392, 3392],
    12901: [2043, 1948, 1029, 2187, 3430, 3430],
    13001: [2071, 1974, 1039, 2215, 3469, 3469],
    13101: [2101, 2005, 1049, 2245, 3507, 3507],
    13201: [2131, 2034, 1060, 2275, 3544, 3544],
    13301: [2161, 2065, 1070, 2305, 3581, 3581],
    13401: [2191, 2094, 1072, 2336, 3618, 3618],
    13501: [2222, 2122, 1082, 2366, 3658, 3658],
    13601: [2252, 2154, 1092, 2396, 3695, 3695],
    13701: [2282, 2182, 1102, 2426, 3732, 3732],
    13801: [2312, 2214, 1113, 2456, 3769, 3769],
    13901: [2343, 2242, 1123, 2487, 3806, 3806],
    14001: [2372, 2271, 1124, 2516, 3845, 3845],
    14101: [2401, 2302, 1135, 2545, 3881, 3881],
    14201: [2430, 2331, 1145, 2574, 3917, 3917],
    14301: [2459, 2362, 1155, 2603, 3953, 3953],
    14401: [2488, 2391, 1165, 2632, 3989, 3989],
    14501: [2517, 2419, 1176, 2661, 4028, 4028],
    14601: [2546, 2451, 1178, 2690, 4064, 4064],
    14701: [2575, 2479, 1188, 2719, 4100, 4100],
    14801: [2604, 2510, 1198, 2748, 4136, 4136],
    14901: [2633, 2539, 1208, 2778, 4172, 4172],
    15001: [2663, 2568, 1218, 2807, 4211, 4211],
    15101: [2692, 2599, 1229, 2836, 4247, 4247],
    15201: [2721, 2627, 1239, 2865, 4283, 4283],
    15301: [2750, 2659, 1241, 2894, 4319, 4319],
    15401: [2779, 2687, 1251, 2923, 4355, 4355],
    15501: [2808, 2716, 1261, 2952, 4394, 4394],
    15601: [2837, 2747, 1271, 2981, 4430, 4430],
    15701: [2866, 2776, 1281, 3010, 4466, 4466],
    15801: [2895, 2807, 1292, 3039, 4502, 4502],
    15901: [2924, 2836, 1293, 3068, 4538, 4538],
    16001: [2953, 2864, 1304, 3097, 4577, 4577],
    16101: [2982, 2896, 1314, 3127, 4613, 4613],
    16201: [3012, 2924, 1324, 3156, 4649, 4649],
    16301: [3041, 2956, 1334, 3185, 4685, 4685],
    16401: [3070, 2984, 1344, 3214, 4721, 4721],
    16501: [3099, 3013, 1346, 3243, 4760, 4760],
    16601: [3128, 3044, 1357, 3272, 4796, 4796],
    16701: [3157, 3073, 1367, 3301, 4832, 4832],
    16801: [3186, 3104, 1377, 3330, 4868, 4868],
    16901: [3215, 3133, 1387, 3359, 4904, 4904],
    17001: [3244, 3161, 1397, 3388, 4942, 4942],
    17101: [3273, 3193, 1399, 3417, 4978, 4978],
    17201: [3302, 3221, 1409, 3447, 5015, 5015],
    17301: [3332, 3253, 1420, 3476, 5051, 5051],
    17401: [3361, 3281, 1430, 3505, 5087, 5087],
    17501: [3390, 3312, 1440, 3534, 5125, 5125],
    17601: [3419, 3341, 1450, 3563, 5161, 5161],
    17701: [3448, 3370, 1460, 3592, 5197, 5197],
    17801: [3477, 3401, 1462, 3621, 5233, 5233],
    17901: [3506, 3430, 1473, 3650, 5269, 5269],
    18001: [3535, 3461, 1483, 3679, 5308, 5308],
    18101: [3564, 3489, 1493, 3708, 5344, 5344],
    18201: [3593, 3518, 1503, 3737, 5380, 5380],
    18301: [3622, 3549, 1513, 3766, 5416, 5416],
    18401: [3651, 3578, 1515, 3796, 5452, 5452],
    18501: [3681, 3609, 1526, 3825, 5491, 5491],
    18601: [3710, 3638, 1536, 3854, 5527, 5527],
    18701: [3739, 3666, 1546, 3883, 5563, 5563],
    18801: [3768, 3698, 1556, 3912, 5599, 5599],
    18901: [3797, 3726, 1566, 3941, 5635, 5635],
    19001: [3826, 3758, 1568, 3970, 5674, 5674],
    19101: [3855, 3786, 1578, 3999, 5710, 5710],
    19201: [3884, 3815, 1600, 4028, 5746, 5746],
    19301: [3913, 3845, 1625, 4057, 5782, 5782],
    19401: [3942, 3873, 1648, 4086, 5818, 5818],
    19501: [3972, 3903, 1673, 4116, 5857, 5857],
    19601: [4001, 3931, 1696, 4145, 5893, 5893],
    19701: [4030, 3959, 1719, 4174, 5929, 5929],
    19801: [4059, 3989, 1744, 4203, 5965, 5965],
    19901: [4088, 4017, 1767, 4232, 6001, 6001],
    20001: [4146, 4075, 1815, 4290, 6076, 6076],
    20201: [4204, 4133, 1863, 4348, 6148, 6148],
    20401: [4262, 4192, 1912, 4406, 6223, 6223],
    20601: [4321, 4247, 1957, 4465, 6295, 6295],
    20801: [4379, 4305, 2005, 4523, 6367, 6367],
    21001: [4437, 4363, 2053, 4581, 6441, 6441],
    21201: [4495, 4422, 2102, 4639, 6514, 6514],
    21401: [4553, 4480, 2150, 4697, 6588, 6588],
    21601: [4611, 4535, 2195, 4755, 6660, 6660],
    21801: [4670, 4593, 2243, 4814, 6732, 6732],
    22001: [4728, 4652, 2292, 4872, 6808, 6808],
    22201: [4788, 4710, 2340, 4932, 6881, 6881],
    22401: [4848, 4771, 2391, 4992, 6958, 6958],
    22601: [4908, 4829, 2439, 5052, 7032, 7032],
    22801: [4967, 4887, 2487, 5112, 7105, 7105],
    23001: [5027, 4948, 2538, 5171, 7182, 7182],
    23201: [5087, 5006, 2586, 5231, 7255, 7255],
    23401: [5147, 5064, 2634, 5291, 7332, 7332],
    23601: [5207, 5125, 2685, 5351, 7406, 7406],
    23801: [5267, 5184, 2734, 5411, 7479, 7479],
    24001: [5326, 5242, 2782, 5471, 7556, 7556],
    24201: [5386, 5300, 2830, 5530, 7629, 7629],
    24401: [5446, 5361, 2881, 5590, 7706, 7706],
    24601: [5506, 5419, 2929, 5650, 7780, 7780],
    24801: [5566, 5477, 2977, 5710, 7853, 7853],
    25001: [5626, 5538, 3038, 5770, 7930, 7930],
    25201: [5685, 5596, 3096, 5829, 8003, 8003],
    25401: [5745, 5655, 3155, 5889, 8080, 8080],
    25601: [5805, 5716, 3216, 5949, 8154, 8154],
    25801: [5865, 5774, 3274, 6009, 8227, 8227],
    26001: [5925, 5832, 3332, 6069, 8304, 8304],
    26201: [5985, 5893, 3393, 6129, 8377, 8377],
    26401: [6044, 5951, 3451, 6188, 8454, 8454],
    26601: [6104, 6009, 3509, 6248, 8528, 8528],
    26801: [6164, 6067, 3567, 6308, 8601, 8601],
    27001: [6224, 6128, 3628, 6368, 8678, 8678],
    27201: [6284, 6187, 3687, 6428, 8751, 8751],
    27401: [6344, 6245, 3745, 6488, 8828, 8828],
    27601: [6403, 6306, 3806, 6547, 8902, 8902],
    27801: [6463, 6364, 3864, 6607, 8975, 8975],
    28001: [6523, 6422, 3922, 6667, 9052, 9052],
    28201: [6583, 6483, 3983, 6727, 9125, 9125],
    28401: [6643, 6541, 4041, 6787, 9202, 9202],
    28601: [6702, 6599, 4099, 6846, 9276, 9276],
    28801: [6762, 6660, 4160, 6906, 9349, 9349],
    29001: [6822, 6719, 4219, 6966, 9426, 9426],
    29201: [6882, 6777, 4277, 7026, 9499, 9499],
    29401: [6942, 6839, 4339, 7086, 9576, 9576],
    29601: [7002, 6898, 4398, 7146, 9650, 9650],
    29801: [7061, 6958, 4458, 7205, 9723, 9723],
    30001: [7121, 7017, 4517, 7265, 9800, 9800],
    30201: [7181, 7080, 4580, 7325, 9873, 9873],
    30401: [7241, 7139, 4639, 7385, 9950, 9950],
    30601: [7301, 7199, 4699, 7445, 10024, 10024],
    30801: [7360, 7261, 4761, 7505, 10097, 10097],
    31001: [7420, 7321, 4821, 7564, 10174, 10174],
    31201: [7480, 7380, 4880, 7624, 10247, 10247],
    31401: [7540, 7442, 4942, 7684, 10324, 10324],
    31601: [7600, 7499, 4999, 7744, 10395, 10395],
    31801: [7659, 7559, 5059, 7803, 10463, 10463],
    32001: [7719, 7618, 5118, 7863, 10531, 10531],
    32201: [7778, 7678, 5178, 7923, 10599, 10599],
    32401: [7841, 7740, 5240, 7982, 10667, 10667],
    32601: [7909, 7808, 5308, 8042, 10735, 10735],
    32801: [7977, 7876, 5376, 8101, 10803, 10803],
    33001: [8045, 7944, 5444, 8161, 10871, 10871],
    33201: [8113, 8012, 5512, 8221, 10939, 10939],
    33401: [8181, 8080, 5580, 8280, 11007, 11007],
    33601: [8249, 8148, 5648, 8340, 11075, 11075],
    33801: [8317, 8216, 5716, 8399, 11143, 11143],
    34001: [8385, 8284, 5784, 8459, 11211, 11211],
    34201: [8453, 8352, 5852, 8518, 11279, 11279],
    34401: [8521, 8420, 5920, 8578, 11347, 11347],
    34601: [8589, 8488, 5988, 8638, 11415, 11415],
    34801: [8657, 8556, 6056, 8697, 11483, 11483],
    35001: [8725, 8624, 6124, 8757, 11551, 11551],
    35201: [8793, 8692, 6192, 8816, 11619, 11619],
    35401: [8861, 8760, 6260, 8876, 11687, 11687],
    35601: [8929, 8828, 6328, 8936, 11755, 11755],
    35801: [8997, 8896, 6396, 8995, 11823, 11823],
    36001: [9065, 8964, 6464, 9055, 11891, 11891],
    36201: [9133, 9032, 6532, 9114, 11959, 11959],
    36401: [9201, 9100, 6600, 9174, 12027, 12027],
    36601: [9269, 9168, 6668, 9234, 12095, 12095],
    36801: [9337, 9236, 6736, 9293, 12163, 12163],
    37001: [9405, 9304, 6804, 9353, 12231, 12231],
    37201: [9473, 9372, 6872, 9412, 12299, 12299],
    37401: [9541, 9440, 6940, 9472, 12367, 12367],
    37601: [9609, 9508, 7008, 9532, 12435, 12435],
    37801: [9677, 9576, 7076, 9591, 12503, 12503],
    38001: [9745, 9644, 7144, 9651, 12571, 12571],
    38201: [9813, 9712, 7212, 9710, 12639, 12639],
    38401: [9881, 9780, 7280, 9770, 12707, 12707],
    38601: [9949, 9848, 7348, 9830, 12775, 12775],
    38801: [10017, 9916, 7416, 9889, 12843, 12843],
    39001: [10085, 9984, 7484, 9949, 12911, 12911],
    39201: [10153, 10052, 7552, 10008, 12979, 12979],
    39401: [10221, 10120, 7620, 10068, 13047, 13047],
    39601: [10289, 10188, 7688, 10127, 13115, 13115],
    39801: [10357, 10256, 7756, 10187, 13183, 13183],
    40001: [10425, 10324, 7824, 10247, 13251, 13251],
    40201: [10493, 10392, 7892, 10306, 13319, 13319],
    40401: [10561, 10460, 7960, 10366, 13387, 13387],
    40601: [10629, 10528, 8028, 10425, 13455, 13455],
    40801: [10697, 10596, 8096, 10485, 13523, 13523],
    41001: [10765, 10664, 8164, 10545, 13591, 13591],
    41201: [10833, 10732, 8232, 10604, 13659, 13659],
    41401: [10901, 10800, 8300, 10664, 13727, 13727],
    41601: [10969, 10868, 8368, 10723, 13795, 13795],
    41801: [11037, 10936, 8436, 10783, 13863, 13863],
    42001: [11105, 11004, 8504, 10843, 13931, 13931],
    42201: [11173, 11072, 8572, 10902, 13999, 13999],
    42401: [11241, 11140, 8640, 10962, 14067, 14067],
    42601: [11309, 11208, 8708, 11021, 14135, 14135],
    42801: [11377, 11276, 8776, 11081, 14203, 14203],
    43001: [11445, 11344, 8844, 11141, 14271, 14271],
    43201: [11513, 11412, 8912, 11200, 14339, 14339],
    43401: [11581, 11480, 8980, 11260, 14407, 14407],
    43601: [11649, 11548, 9048, 11319, 14475, 14475],
    43801: [11717, 11616, 9116, 11379, 14543, 14543],
    44001: [11785, 11684, 9184, 11439, 14611, 14611],
    44201: [11853, 11752, 9252, 11498, 14679, 14679],
    44401: [11921, 11820, 9320, 11558, 14747, 14747],
    44601: [11989, 11888, 9388, 11617, 14815, 14815],
    44801: [12057, 11956, 9456, 11677, 14883, 14883],
    45001: [12125, 12024, 9524, 11737, 14951, 14951],
    45201: [12193, 12092, 9592, 11796, 15019, 15019],
    45401: [12261, 12160, 9660, 11856, 15087, 15087],
    45601: [12329, 12228, 9728, 11915, 15155, 15155],
    45801: [12397, 12296, 9796, 11975, 15223, 15223],
    46001: [12465, 12364, 9864, 12034, 15291, 15291],
    46201: [12564, 12472, 9972, 12126, 15390, 15390],
    46401: [12672, 12582, 10082, 12225, 15498, 15498],
    46601: [12780, 12693, 10193, 12325, 15606, 15606],
    46801: [12888, 12803, 10303, 12425, 15714, 15714],
    47001: [12996, 12914, 10414, 12524, 15822, 15822],
    47201: [13104, 13021, 10521, 12624, 15930, 15930],
    47401: [13212, 13132, 10632, 12723, 16038, 16038],
    47601: [13320, 13242, 10742, 12823, 16146, 16146],
    47801: [13428, 13353, 10853, 12922, 16254, 16254],
    48001: [13536, 13463, 10963, 13022, 16362, 16362],
    48201: [13644, 13574, 11074, 13122, 16470, 16470],
    48401: [13752, 13684, 11184, 13221, 16578, 16578],
    48601: [13860, 13795, 11295, 13321, 16686, 16686],
    48801: [13968, 13902, 11402, 13420, 16794, 16794],
    49001: [14076, 14013, 11513, 13520, 16902, 16902],
    49201: [14184, 14123, 11623, 13620, 17010, 17010],
    49401: [14292, 14234, 11734, 13719, 17118, 17118],
    49601: [14400, 14344, 11844, 13819, 17226, 17226],
    49801: [14508, 14455, 11955, 13918, 17334, 17334],
    50001: [14616, 14565, 12071, 14018, 17442, 17442],
    50201: [14724, 14676, 12188, 14118, 17550, 17550],
    50401: [14832, 14784, 12302, 14217, 17658, 17658],
    50601: [14940, 14894, 12418, 14317, 17766, 17766],
    50801: [15048, 15005, 12535, 14416, 17874, 17874],
    51001: [15156, 15115, 12651, 14516, 17982, 17982],
    51201: [15264, 15226, 12768, 14616, 18090, 18090],
    51401: [15372, 15327, 12875, 14715, 18198, 18198],
    51601: [15480, 15435, 12989, 14815, 18306, 18306],
    51801: [15588, 15543, 13103, 14914, 18414, 18414],
    52001: [15696, 15651, 13217, 15014, 18522, 18522],
    52201: [15804, 15759, 13331, 15114, 18630, 18630],
    52401: [15912, 15867, 13445, 15213, 18738, 18738],
    52601: [16020, 15975, 13559, 15313, 18846, 18846],
    52801: [16128, 16083, 13673, 15412, 18954, 18954],
    53001: [16236, 16191, 13787, 15512, 19062, 19062],
    53201: [16344, 16299, 13901, 15611, 19170, 19170],
    53401: [16452, 16407, 14015, 15711, 19278, 19278],
    53601: [16560, 16515, 14129, 15811, 19386, 19386],
    53801: [16668, 16623, 14243, 15910, 19494, 19494],
    54001: [16776, 16731, 14357, 16010, 19602, 19602],
    54201: [16884, 16839, 14471, 16109, 19710, 19710],
    54401: [16995, 16952, 14590, 16209, 19818, 19818],
    54601: [17109, 17069, 14713, 16309, 19926, 19926],
    54801: [17223, 17181, 14831, 16408, 20034, 20034],
    55001: [17337, 17294, 14950, 16508, 20142, 20142],
    55201: [17451, 17411, 15073, 16607, 20250, 20250],
    55401: [17565, 17523, 15191, 16707, 20358, 20358],
    55601: [17679, 17636, 15310, 16807, 20466, 20466],
    55801: [17793, 17753, 15433, 16906, 20574, 20574],
    56001: [17907, 17865, 15551, 17006, 20682, 20682],
    56201: [18021, 17982, 15674, 17105, 20790, 20790],
    56401: [18136, 18095, 15793, 17205, 20898, 20898],
    56601: [18249, 18207, 15911, 17305, 21006, 21006],
    56801: [18363, 18324, 16034, 17404, 21114, 21114],
    57001: [18477, 18437, 16153, 17504, 21222, 21222],
    57201: [18591, 18554, 16276, 17603, 21330, 21330],
    57401: [18705, 18666, 16394, 17703, 21438, 21438],
    57601: [18819, 18779, 16513, 17803, 21546, 21546],
    57801: [18933, 18896, 16636, 17902, 21654, 21654],
    58001: [19047, 19008, 16754, 18002, 21762, 21762],
    58201: [19161, 19121, 16873, 18101, 21870, 21870],
    58401: [19275, 19238, 16996, 18201, 21978, 21978],
    58601: [19389, 19350, 17114, 18300, 22086, 22086],
    58801: [19503, 19467, 17237, 18400, 22194, 22194],
    59001: [19617, 19580, 17356, 18500, 22302, 22302],
    59201: [19731, 19692, 17474, 18599, 22410, 22410],
    59401: [19845, 19809, 17597, 18699, 22518, 22518],
    59601: [19959, 19922, 17716, 18798, 22626, 22626],
    59801: [20073, 20034, 17834, 18898, 22734, 22734],
    60001: [20187, 20151, 17957, 18998, 22842, 22842],
    60201: [20301, 20264, 18076, 19097, 22950, 22950],
    60401: [20415, 20381, 18199, 19197, 23058, 23058],
    60601: [20529, 20493, 18317, 19296, 23166, 23166],
    60801: [20643, 20606, 18436, 19396, 23274, 23274],
    61001: [20757, 20723, 18559, 19496, 23382, 23382],
    61201: [20871, 20835, 18677, 19595, 23490, 23490],
    61401: [20986, 20948, 18796, 19695, 23598, 23598],
    61601: [21099, 21065, 18919, 19794, 23706, 23706],
    61801: [21213, 21177, 19037, 19894, 23814, 23814],
    62001: [21327, 21294, 19160, 19994, 23922, 23922],
    62201: [21441, 21407, 19279, 20093, 24030, 24030],
    62401: [21555, 21519, 19397, 20193, 24138, 24138],
    62601: [21669, 21636, 19520, 20292, 24246, 24246],
    62801: [21783, 21749, 19639, 20392, 24354, 24354],
    63001: [21897, 21861, 19757, 20492, 24462, 24462],
    63201: [22011, 21978, 19880, 20591, 24570, 24570],
    63401: [22125, 22091, 19999, 20691, 24678, 24678],
    63601: [22239, 22208, 20122, 20790, 24786, 24786],
    63801: [22353, 22320, 20240, 20890, 24894, 24894],
    64001: [22467, 22433, 20359, 20990, 25002, 25002],
    64201: [22581, 22550, 20482, 21089, 25110, 25110],
    64401: [22695, 22662, 20600, 21189, 25218, 25218],
    64601: [22809, 22775, 20719, 21288, 25326, 25326],
    64801: [22923, 22892, 20842, 21388, 25434, 25434],
    65001: [23037, 23004, 20960, 21487, 25542, 25542],
    65201: [23151, 23121, 21083, 21587, 25650, 25650],
    65401: [23265, 23234, 21202, 21687, 25758, 25758],
    65601: [23379, 23346, 21320, 21786, 25866, 25866],
    65801: [23493, 23463, 21443, 21886, 25974, 25974],
    66001: [23607, 23576, 21562, 21985, 26082, 26082],
    66201: [23721, 23688, 21680, 22085, 26190, 26190],
    66401: [23836, 23805, 21803, 22185, 26298, 26298],
    66601: [23949, 23918, 21922, 22284, 26406, 26406],
    66801: [24063, 24035, 22045, 22384, 26514, 26514],
    67001: [24177, 24147, 22163, 22483, 26622, 26622],
    67201: [24291, 24260, 22282, 22583, 26730, 26730],
    67401: [24405, 24377, 22405, 22683, 26838, 26838],
    67601: [24519, 24489, 22523, 22782, 26946, 26946],
    67801: [24633, 24606, 22646, 22882, 27054, 27054],
    68001: [24747, 24719, 22765, 22981, 27162, 27162],
    68201: [24861, 24831, 22883, 23081, 27270, 27270],
    68401: [24975, 24948, 23006, 23181, 27378, 27378],
    68601: [25089, 25061, 23125, 23280, 27486, 27486],
    68801: [25203, 25173, 23243, 23380, 27594, 27594],
    69001: [25317, 25290, 23366, 23479, 27702, 27702],
    69201: [25431, 25403, 23485, 23579, 27810, 27810],
    69401: [25545, 25520, 23608, 23679, 27918, 27918],
    69601: [25659, 25632, 23726, 23778, 28026, 28026],
    69801: [25773, 25745, 23845, 23878, 28134, 28134],
    70001: [25887, 25862, 23968, 23977, 28242, 28242],
    70201: [26001, 25974, 24086, 24077, 28350, 28350],
    70401: [26115, 26087, 24205, 24176, 28458, 28458],
    70601: [26229, 26204, 24328, 24276, 28566, 28566],
    70801: [26343, 26316, 24446, 24376, 28674, 28674],
    71001: [26457, 26433, 24569, 24475, 28782, 28782],
    71201: [26571, 26546, 24688, 24575, 28890, 28890],
    71401: [26686, 26658, 24806, 24675, 28998, 28998],
    71601: [26799, 26775, 24929, 24774, 29106, 29106],
    71801: [26913, 26888, 25048, 24874, 29214, 29214],
    72001: [27027, 27000, 25166, 24973, 29322, 29322],
    72201: [27141, 27117, 25289, 25073, 29430, 29430],
    72401: [27255, 27230, 25408, 25172, 29538, 29538],
    72601: [27369, 27347, 25531, 25272, 29646, 29646],
    72801: [27483, 27459, 25649, 25372, 29754, 29754],
    73001: [27597, 27572, 25768, 25471, 29862, 29862],
    73201: [27711, 27689, 25891, 25571, 29970, 29970],
    73401: [27825, 27801, 26009, 25670, 30078, 30078],
    73601: [27939, 27914, 26128, 25770, 30186, 30186],
    73801: [28053, 28031, 26251, 25870, 30294, 30294],
    74001: [28167, 28143, 26369, 25969, 30402, 30402],
    74201: [28281, 28260, 26492, 26069, 30510, 30510],
    74401: [28395, 28373, 26611, 26168, 30618, 30618],
    74601: [28509, 28485, 26729, 26268, 30726, 30726],
    74801: [28623, 28602, 26852, 26368, 30834, 30834],
    75001: [28737, 28715, 26971, 26467, 30942, 30942],
    75201: [28851, 28827, 27089, 26567, 31050, 31050],
    75401: [28965, 28944, 27212, 26666, 31158, 31158],
    75601: [29079, 29057, 27331, 26766, 31266, 31266],
    75801: [29193, 29174, 27454, 26865, 31374, 31374],
    76001: [29307, 29286, 27572, 26965, 31482, 31482],
    76201: [29421, 29399, 27691, 27065, 31590, 31590],
    76401: [29536, 29516, 27814, 27164, 31698, 31698],
    76601: [29649, 29628, 27932, 27264, 31806, 31806],
    76801: [29763, 29745, 28055, 27363, 31914, 31914],
    77001: [29877, 29858, 28174, 27463, 32022, 32022],
    77201: [29991, 29970, 28292, 27563, 32130, 32130],
    77401: [30105, 30087, 28415, 27662, 32238, 32238],
    77601: [30219, 30200, 28534, 27762, 32346, 32346],
    77801: [30333, 30312, 28652, 27861, 32454, 32454],
    78001: [30447, 30429, 28775, 27961, 32562, 32562],
    78201: [30561, 30542, 28894, 28061, 32670, 32670],
    78401: [30675, 30659, 29017, 28160, 32778, 32778],
    78601: [30789, 30771, 29135, 28260, 32886, 32886],
    78801: [30903, 30884, 29254, 28359, 32994, 32994],
    79001: [31017, 31001, 29377, 28459, 33102, 33102],
    79201: [31131, 31113, 29495, 28559, 33210, 33210],
    79401: [31245, 31226, 29614, 28658, 33318, 33318],
    79601: [31359, 31343, 29737, 28758, 33426, 33426],
    79801: [31473, 31455, 29855, 28857, 33534, 33534],
    80001: [39, 39, 37, 36, 42, 42, '%'],
    80601: [40, 39, 38, 36, 42, 42, '%'],
    80801: [40, 40, 38, 36, 42, 42, '%'],
    82401: [40, 40, 38, 37, 42, 42, '%'],
    84001: [40, 40, 38, 37, 43, 43, '%'],
    84201: [40, 40, 39, 37, 43, 43, '%'],
    85601: [41, 41, 39, 37, 43, 43, '%'],
    88401: [41, 41, 40, 37, 43, 43, '%'],
    89201: [41, 41, 40, 38, 43, 43, '%'],
    91001: [42, 42, 40, 38, 43, 43, '%'],
    92001: [42, 42, 40, 38, 44, 44, '%'],
    92801: [42, 42, 41, 38, 44, 44, '%'],
    97001: [42, 42, 41, 39, 44, 44, '%'],
    97401: [43, 43, 41, 39, 44, 44, '%'],
    97801: [43, 43, 42, 39, 44, 44, '%'],
    101601: [43, 43, 42, 39, 45, 45, '%'],
    103401: [43, 43, 43, 39, 45, 45, '%'],
    104401: [43, 44, 43, 39, 45, 45, '%'],
    104601: [44, 44, 43, 39, 45, 45, '%'],
    106601: [44, 44, 43, 40, 45, 45, '%'],
    109601: [44, 44, 44, 40, 45, 45, '%'],
    112801: [44, 45, 44, 40, 45, 45, '%'],
    113001: [45, 45, 44, 40, 45, 45, '%'],
    113601: [45, 45, 44, 40, 46, 46, '%'],
    116801: [45, 45, 45, 40, 46, 46, '%'],
    118001: [45, 45, 45, 41, 46, 46, '%'],
    122601: [45, 46, 45, 41, 46, 46, '%'],
    122801: [46, 46, 45, 41, 46, 46, '%'],
    124801: [46, 46, 46, 41, 46, 46, '%'],
    128801: [46, 46, 46, 41, 47, 47, '%'],
    132201: [46, 46, 46, 42, 47, 47, '%'],
    134201: [46, 47, 47, 42, 47, 47, '%'],
    134401: [47, 47, 47, 42, 47, 47, '%'],
    148601: [48, 48, 48, 42, 48, 48, '%'],
    150401: [48, 48, 48, 43, 48, 48, '%'],
    174201: [48, 48, 48, 44, 48, 48, '%'],
    175601: [49, 49, 49, 44, 49, 49, '%'],
    207201: [49, 49, 49, 45, 49, 49, '%'],
    214601: [50, 50, 50, 45, 50, 50, '%'],
    255401: [50, 50, 50, 46, 50, 50, '%'],
    276001: [51, 51, 51, 46, 51, 51, '%'],
    333001: [51, 51, 51, 47, 51, 51, '%'],
    386601: [52, 52, 52, 47, 52, 52, '%'],
    478001: [52, 52, 52, 48, 52, 52, '%'],
    644201: [53, 53, 53, 48, 53, 53, '%'],
    846801: [53, 53, 53, 49, 53, 53, '%'],
    1933001: [54, 54, 54, 49, 54, 54, '%']
  },
  35: {
    1: [0, 0, 0, 0, 0, 0],
    1701: [126, 0, 125, 1, 126, 32],
    1801: [137, 0, 133, 4, 137, 68],
    1901: [147, 0, 141, 6, 147, 104],
    2001: [158, 0, 150, 8, 158, 140],
    2101: [160, 0, 150, 10, 176, 176],
    2201: [171, 0, 158, 12, 212, 212],
    2301: [181, 0, 166, 14, 248, 248],
    2401: [192, 0, 175, 17, 284, 284],
    2501: [202, 0, 183, 19, 320, 320],
    2601: [213, 0, 191, 21, 356, 356],
    2701: [223, 0, 200, 23, 392, 392],
    2801: [225, 0, 200, 25, 428, 428],
    2901: [236, 0, 208, 27, 464, 464],
    3001: [246, 0, 216, 30, 500, 500],
    3101: [257, 0, 225, 32, 536, 536],
    3201: [267, 0, 233, 34, 572, 572],
    3301: [278, 0, 241, 36, 608, 608],
    3401: [280, 0, 241, 38, 644, 644],
    3501: [290, 0, 250, 40, 680, 680],
    3601: [301, 0, 258, 51, 716, 716],
    3701: [311, 0, 266, 75, 752, 752],
    3801: [322, 0, 275, 100, 788, 788],
    3901: [332, 0, 283, 124, 825, 825],
    4001: [334, 0, 283, 148, 851, 851],
    4101: [344, 0, 291, 172, 881, 881],
    4201: [354, 0, 300, 196, 911, 911],
    4301: [364, 0, 308, 220, 938, 938],
    4401: [374, 5, 317, 244, 968, 968],
    4501: [384, 32, 326, 268, 995, 995],
    4601: [386, 59, 328, 293, 1025, 1025],
    4701: [396, 86, 338, 317, 1055, 1055],
    4801: [406, 113, 348, 341, 1082, 1082],
    4901: [416, 140, 358, 365, 1112, 1112],
    5001: [426, 167, 368, 389, 1139, 1139],
    5101: [436, 194, 378, 413, 1169, 1169],
    5201: [447, 221, 388, 437, 1200, 1200],
    5301: [448, 245, 389, 460, 1226, 1226],
    5401: [458, 272, 399, 484, 1255, 1255],
    5501: [476, 300, 409, 507, 1282, 1282],
    5601: [498, 326, 419, 531, 1311, 1311],
    5701: [520, 353, 429, 554, 1340, 1340],
    5801: [542, 380, 439, 578, 1367, 1367],
    5901: [564, 407, 441, 601, 1396, 1396],
    6001: [586, 434, 451, 624, 1423, 1423],
    6101: [608, 461, 461, 648, 1452, 1452],
    6201: [629, 488, 471, 671, 1481, 1481],
    6301: [651, 515, 480, 695, 1508, 1508],
    6401: [673, 542, 490, 718, 1537, 1537],
    6501: [695, 566, 492, 742, 1564, 1564],
    6601: [717, 593, 502, 765, 1593, 1593],
    6701: [739, 620, 512, 789, 1622, 1622],
    6801: [761, 647, 522, 812, 1649, 1649],
    6901: [782, 675, 532, 835, 1678, 1678],
    7001: [804, 701, 542, 859, 1705, 1705],
    7101: [826, 728, 543, 882, 1734, 1734],
    7201: [848, 755, 553, 906, 1763, 1763],
    7301: [870, 782, 563, 929, 1790, 1790],
    7401: [892, 809, 573, 953, 1819, 1819],
    7501: [914, 836, 583, 976, 1846, 1846],
    7601: [936, 863, 593, 1000, 1875, 1875],
    7701: [957, 887, 603, 1023, 1904, 1904],
    7801: [979, 914, 604, 1046, 1931, 1931],
    7901: [1001, 932, 614, 1070, 1960, 1960],
    8001: [1023, 953, 623, 1093, 1987, 1987],
    8101: [1045, 975, 633, 1117, 2016, 2016],
    8201: [1067, 998, 643, 1140, 2045, 2045],
    8301: [1089, 1019, 652, 1164, 2072, 2072],
    8401: [1110, 1040, 654, 1187, 2101, 2101],
    8501: [1132, 1061, 663, 1210, 2128, 2128],
    8601: [1154, 1085, 673, 1234, 2157, 2157],
    8701: [1176, 1106, 683, 1257, 2186, 2186],
    8801: [1198, 1127, 692, 1281, 2213, 2213],
    8901: [1220, 1148, 702, 1304, 2242, 2242],
    9001: [1241, 1172, 703, 1328, 2269, 2269],
    9101: [1263, 1193, 713, 1351, 2298, 2298],
    9201: [1285, 1214, 722, 1375, 2327, 2327],
    9301: [1307, 1238, 732, 1398, 2354, 2354],
    9401: [1329, 1258, 742, 1422, 2383, 2383],
    9501: [1351, 1279, 751, 1445, 2410, 2410],
    9601: [1373, 1299, 753, 1468, 2439, 2439],
    9701: [1395, 1323, 762, 1492, 2468, 2468],
    9801: [1416, 1343, 772, 1515, 2495, 2495],
    9901: [1438, 1364, 782, 1539, 2524, 2524],
    10001: [1460, 1385, 791, 1562, 2551, 2551],
    10101: [1482, 1408, 801, 1586, 2580, 2580],
    10201: [1504, 1429, 811, 1609, 2609, 2609],
    10301: [1526, 1449, 812, 1633, 2636, 2636],
    10401: [1548, 1470, 821, 1656, 2665, 2665],
    10501: [1569, 1493, 831, 1679, 2692, 2692],
    10601: [1591, 1514, 841, 1703, 2721, 2721],
    10701: [1613, 1534, 850, 1726, 2750, 2750],
    10801: [1635, 1555, 860, 1750, 2777, 2777],
    10901: [1657, 1578, 861, 1773, 2809, 2809],
    11001: [1679, 1599, 871, 1797, 2844, 2844],
    11101: [1701, 1620, 881, 1821, 2880, 2880],
    11201: [1723, 1643, 890, 1845, 2915, 2915],
    11301: [1745, 1664, 900, 1868, 2950, 2950],
    11401: [1768, 1684, 910, 1892, 2985, 2985],
    11501: [1790, 1705, 911, 1916, 3021, 3021],
    11601: [1812, 1728, 921, 1939, 3056, 3056],
    11701: [1834, 1749, 930, 1963, 3091, 3091],
    11801: [1856, 1769, 940, 1987, 3126, 3126],
    11901: [1878, 1790, 949, 2011, 3162, 3162],
    12001: [1900, 1813, 959, 2034, 3197, 3197],
    12101: [1923, 1834, 960, 2058, 3232, 3232],
    12201: [1945, 1855, 970, 2082, 3267, 3267],
    12301: [1967, 1875, 980, 2106, 3303, 3303],
    12401: [1989, 1899, 989, 2129, 3338, 3338],
    12501: [2011, 1919, 999, 2153, 3376, 3376],
    12601: [2034, 1940, 1009, 2177, 3414, 3414],
    12701: [2056, 1960, 1018, 2201, 3453, 3453],
    12801: [2078, 1984, 1020, 2225, 3491, 3491],
    12901: [2100, 2004, 1029, 2249, 3529, 3529],
    13001: [2129, 2031, 1039, 2277, 3570, 3570],
    13101: [2160, 2063, 1049, 2308, 3608, 3608],
    13201: [2191, 2092, 1060, 2339, 3646, 3646],
    13301: [2222, 2125, 1070, 2370, 3685, 3685],
    13401: [2253, 2154, 1072, 2401, 3723, 3723],
    13501: [2284, 2184, 1082, 2433, 3764, 3764],
    13601: [2315, 2216, 1092, 2464, 3802, 3802],
    13701: [2346, 2245, 1102, 2495, 3840, 3840],
    13801: [2377, 2278, 1113, 2526, 3878, 3878],
    13901: [2408, 2307, 1123, 2557, 3917, 3917],
    14001: [2438, 2336, 1124, 2587, 3957, 3957],
    14101: [2468, 2369, 1135, 2617, 3994, 3994],
    14201: [2498, 2398, 1145, 2647, 4031, 4031],
    14301: [2528, 2430, 1155, 2677, 4068, 4068],
    14401: [2558, 2460, 1165, 2707, 4105, 4105],
    14501: [2588, 2489, 1176, 2737, 4145, 4145],
    14601: [2618, 2521, 1178, 2767, 4182, 4182],
    14701: [2648, 2551, 1188, 2797, 4219, 4219],
    14801: [2678, 2583, 1198, 2826, 4256, 4256],
    14901: [2708, 2612, 1208, 2856, 4294, 4294],
    15001: [2738, 2642, 1218, 2886, 4334, 4334],
    15101: [2768, 2674, 1229, 2916, 4371, 4371],
    15201: [2798, 2703, 1239, 2946, 4408, 4408],
    15301: [2828, 2736, 1241, 2976, 4445, 4445],
    15401: [2858, 2765, 1251, 3006, 4482, 4482],
    15501: [2888, 2795, 1261, 3036, 4522, 4522],
    15601: [2918, 2827, 1271, 3066, 4559, 4559],
    15701: [2948, 2856, 1281, 3096, 4596, 4596],
    15801: [2978, 2889, 1292, 3126, 4633, 4633],
    15901: [3008, 2918, 1293, 3156, 4670, 4670],
    16001: [3038, 2947, 1304, 3186, 4710, 4710],
    16101: [3067, 2980, 1314, 3216, 4747, 4747],
    16201: [3098, 3009, 1324, 3246, 4785, 4785],
    16301: [3127, 3041, 1334, 3276, 4822, 4822],
    16401: [3157, 3071, 1344, 3306, 4859, 4859],
    16501: [3187, 3100, 1346, 3336, 4899, 4899],
    16601: [3217, 3132, 1357, 3366, 4936, 4936],
    16701: [3247, 3162, 1367, 3396, 4973, 4973],
    16801: [3277, 3194, 1377, 3426, 5010, 5010],
    16901: [3307, 3223, 1387, 3456, 5047, 5047],
    17001: [3337, 3253, 1397, 3486, 5087, 5087],
    17101: [3367, 3285, 1399, 3516, 5124, 5124],
    17201: [3397, 3314, 1409, 3546, 5161, 5161],
    17301: [3427, 3347, 1420, 3575, 5198, 5198],
    17401: [3457, 3376, 1430, 3605, 5235, 5235],
    17501: [3487, 3408, 1440, 3635, 5275, 5275],
    17601: [3517, 3438, 1450, 3665, 5313, 5313],
    17701: [3547, 3467, 1460, 3695, 5350, 5350],
    17801: [3577, 3500, 1462, 3725, 5387, 5387],
    17901: [3607, 3529, 1473, 3755, 5424, 5424],
    18001: [3637, 3561, 1483, 3785, 5464, 5464],
    18101: [3667, 3591, 1493, 3815, 5501, 5501],
    18201: [3697, 3620, 1503, 3845, 5538, 5538],
    18301: [3727, 3652, 1513, 3875, 5575, 5575],
    18401: [3757, 3682, 1515, 3905, 5612, 5612],
    18501: [3787, 3714, 1534, 3935, 5652, 5652],
    18601: [3817, 3743, 1558, 3965, 5689, 5689],
    18701: [3846, 3773, 1583, 3995, 5726, 5726],
    18801: [3876, 3805, 1610, 4025, 5763, 5763],
    18901: [3906, 3834, 1634, 4055, 5801, 5801],
    19001: [3936, 3867, 1662, 4085, 5841, 5841],
    19101: [3966, 3896, 1686, 4115, 5878, 5878],
    19201: [3996, 3925, 1710, 4145, 5915, 5915],
    19301: [4026, 3957, 1737, 4175, 5952, 5952],
    19401: [4056, 3985, 1760, 4205, 5989, 5989],
    19501: [4086, 4017, 1787, 4235, 6029, 6029],
    19601: [4116, 4045, 1810, 4265, 6066, 6066],
    19701: [4146, 4074, 1834, 4295, 6103, 6103],
    19801: [4176, 4105, 1860, 4324, 6140, 6140],
    19901: [4206, 4134, 1884, 4354, 6177, 6177],
    20001: [4266, 4194, 1934, 4414, 6254, 6254],
    20201: [4326, 4254, 1984, 4474, 6329, 6329],
    20401: [4386, 4314, 2034, 4534, 6406, 6406],
    20601: [4446, 4371, 2081, 4594, 6480, 6480],
    20801: [4506, 4431, 2131, 4654, 6554, 6554],
    21001: [4565, 4491, 2181, 4714, 6631, 6631],
    21201: [4625, 4550, 2230, 4774, 6705, 6705],
    21401: [4685, 4610, 2280, 4834, 6782, 6782],
    21601: [4745, 4667, 2327, 4894, 6857, 6857],
    21801: [4805, 4727, 2377, 4954, 6931, 6931],
    22001: [4866, 4787, 2427, 5014, 7008, 7008],
    22201: [4927, 4847, 2477, 5076, 7084, 7084],
    22401: [4989, 4910, 2530, 5137, 7163, 7163],
    22601: [5050, 4970, 2580, 5199, 7239, 7239],
    22801: [5112, 5030, 2630, 5260, 7315, 7315],
    23001: [5173, 5093, 2683, 5322, 7393, 7393],
    23201: [5235, 5153, 2733, 5383, 7469, 7469],
    23401: [5297, 5213, 2783, 5445, 7548, 7548],
    23601: [5358, 5275, 2835, 5507, 7624, 7624],
    23801: [5420, 5335, 2885, 5568, 7700, 7700],
    24001: [5481, 5395, 2935, 5630, 7778, 7778],
    24201: [5543, 5455, 2985, 5691, 7854, 7854],
    24401: [5604, 5518, 3038, 5753, 7933, 7933],
    24601: [5666, 5578, 3088, 5814, 8009, 8009],
    24801: [5727, 5638, 3138, 5876, 8085, 8085],
    25001: [5789, 5701, 3201, 5938, 8163, 8163],
    25201: [5851, 5761, 3261, 5999, 8239, 8239],
    25401: [5912, 5821, 3321, 6061, 8318, 8318],
    25601: [5974, 5883, 3383, 6122, 8394, 8394],
    25801: [6035, 5943, 3443, 6184, 8470, 8470],
    26001: [6097, 6003, 3503, 6245, 8548, 8548],
    26201: [6158, 6066, 3566, 6307, 8624, 8624],
    26401: [6220, 6126, 3626, 6369, 8703, 8703],
    26601: [6282, 6186, 3686, 6430, 8779, 8779],
    26801: [6343, 6246, 3746, 6492, 8855, 8855],
    27001: [6405, 6309, 3809, 6553, 8933, 8933],
    27201: [6466, 6369, 3869, 6615, 9009, 9009],
    27401: [6528, 6429, 3929, 6676, 9088, 9088],
    27601: [6589, 6491, 3991, 6738, 9164, 9164],
    27801: [6651, 6551, 4051, 6799, 9240, 9240],
    28001: [6713, 6611, 4111, 6861, 9318, 9318],
    28201: [6774, 6674, 4174, 6923, 9394, 9394],
    28401: [6836, 6734, 4234, 6984, 9473, 9473],
    28601: [6897, 6794, 4294, 7046, 9549, 9549],
    28801: [6959, 6857, 4357, 7107, 9625, 9625],
    29001: [7020, 6917, 4417, 7169, 9703, 9703],
    29201: [7082, 6977, 4477, 7230, 9779, 9779],
    29401: [7144, 7040, 4540, 7292, 9858, 9858],
    29601: [7205, 7102, 4602, 7354, 9934, 9934],
    29801: [7267, 7163, 4663, 7415, 10010, 10010],
    30001: [7328, 7224, 4724, 7477, 10088, 10088],
    30201: [7390, 7288, 4788, 7538, 10164, 10164],
    30401: [7451, 7350, 4850, 7600, 10243, 10243],
    30601: [7513, 7411, 4911, 7661, 10319, 10319],
    30801: [7575, 7475, 4975, 7723, 10395, 10395],
    31001: [7636, 7536, 5036, 7785, 10473, 10473],
    31201: [7698, 7598, 5098, 7846, 10549, 10549],
    31401: [7759, 7662, 5162, 7908, 10628, 10628],
    31601: [7821, 7720, 5220, 7969, 10701, 10701],
    31801: [7882, 7781, 5281, 8031, 10771, 10771],
    32001: [7943, 7843, 5343, 8092, 10841, 10841],
    32201: [8005, 7904, 5404, 8153, 10911, 10911],
    32401: [8069, 7968, 5468, 8215, 10981, 10981],
    32601: [8139, 8038, 5538, 8276, 11051, 11051],
    32801: [8209, 8108, 5608, 8337, 11121, 11121],
    33001: [8279, 8178, 5678, 8399, 11191, 11191],
    33201: [8349, 8248, 5748, 8460, 11261, 11261],
    33401: [8419, 8318, 5818, 8521, 11331, 11331],
    33601: [8489, 8388, 5888, 8583, 11401, 11401],
    33801: [8559, 8458, 5958, 8644, 11471, 11471],
    34001: [8629, 8528, 6028, 8705, 11541, 11541],
    34201: [8699, 8598, 6098, 8767, 11611, 11611],
    34401: [8769, 8668, 6168, 8828, 11681, 11681],
    34601: [8839, 8738, 6238, 8889, 11751, 11751],
    34801: [8909, 8808, 6308, 8951, 11821, 11821],
    35001: [8979, 8878, 6378, 9012, 11891, 11891],
    35201: [9049, 8948, 6448, 9073, 11961, 11961],
    35401: [9119, 9018, 6518, 9135, 12031, 12031],
    35601: [9189, 9088, 6588, 9196, 12101, 12101],
    35801: [9259, 9158, 6658, 9257, 12171, 12171],
    36001: [9329, 9228, 6728, 9319, 12241, 12241],
    36201: [9399, 9298, 6798, 9380, 12311, 12311],
    36401: [9469, 9368, 6868, 9441, 12381, 12381],
    36601: [9539, 9438, 6938, 9503, 12451, 12451],
    36801: [9609, 9508, 7008, 9564, 12521, 12521],
    37001: [9679, 9578, 7078, 9625, 12591, 12591],
    37201: [9749, 9648, 7148, 9687, 12661, 12661],
    37401: [9819, 9718, 7218, 9748, 12731, 12731],
    37601: [9889, 9788, 7288, 9809, 12801, 12801],
    37801: [9959, 9858, 7358, 9871, 12871, 12871],
    38001: [10029, 9928, 7428, 9932, 12941, 12941],
    38201: [10099, 9998, 7498, 9993, 13011, 13011],
    38401: [10169, 10068, 7568, 10055, 13081, 13081],
    38601: [10239, 10138, 7638, 10116, 13151, 13151],
    38801: [10309, 10208, 7708, 10177, 13221, 13221],
    39001: [10379, 10278, 7778, 10239, 13291, 13291],
    39201: [10449, 10348, 7848, 10300, 13361, 13361],
    39401: [10519, 10418, 7918, 10361, 13431, 13431],
    39601: [10589, 10488, 7988, 10423, 13501, 13501],
    39801: [10659, 10558, 8058, 10484, 13571, 13571],
    40001: [10729, 10628, 8128, 10545, 13641, 13641],
    40201: [10799, 10698, 8198, 10607, 13711, 13711],
    40401: [10869, 10768, 8268, 10668, 13781, 13781],
    40601: [10939, 10838, 8338, 10729, 13851, 13851],
    40801: [11009, 10908, 8408, 10791, 13921, 13921],
    41001: [11079, 10978, 8478, 10852, 13991, 13991],
    41201: [11149, 11048, 8548, 10913, 14061, 14061],
    41401: [11219, 11118, 8618, 10975, 14131, 14131],
    41601: [11289, 11188, 8688, 11036, 14201, 14201],
    41801: [11359, 11258, 8758, 11097, 14271, 14271],
    42001: [11429, 11328, 8828, 11159, 14341, 14341],
    42201: [11499, 11398, 8898, 11220, 14411, 14411],
    42401: [11569, 11468, 8968, 11281, 14481, 14481],
    42601: [11639, 11538, 9038, 11343, 14551, 14551],
    42801: [11709, 11608, 9108, 11404, 14621, 14621],
    43001: [11779, 11678, 9178, 11465, 14691, 14691],
    43201: [11849, 11748, 9248, 11527, 14761, 14761],
    43401: [11919, 11818, 9318, 11588, 14831, 14831],
    43601: [11989, 11888, 9388, 11649, 14901, 14901],
    43801: [12059, 11958, 9458, 11711, 14971, 14971],
    44001: [12129, 12028, 9528, 11772, 15041, 15041],
    44201: [12199, 12098, 9598, 11833, 15111, 15111],
    44401: [12269, 12168, 9668, 11895, 15181, 15181],
    44601: [12339, 12238, 9738, 11956, 15251, 15251],
    44801: [12409, 12308, 9808, 12017, 15321, 15321],
    45001: [12479, 12378, 9878, 12079, 15391, 15391],
    45201: [12549, 12448, 9948, 12140, 15461, 15461],
    45401: [12619, 12518, 10018, 12201, 15531, 15531],
    45601: [12689, 12588, 10088, 12263, 15601, 15601],
    45801: [12759, 12658, 10158, 12324, 15671, 15671],
    46001: [12829, 12728, 10228, 12385, 15741, 15741],
    46201: [12930, 12839, 10339, 12478, 15843, 15843],
    46401: [13041, 12953, 10453, 12580, 15953, 15953],
    46601: [13150, 13066, 10566, 12681, 16063, 16063],
    46801: [13260, 13180, 10680, 12782, 16173, 16173],
    47001: [13370, 13294, 10794, 12884, 16283, 16283],
    47201: [13480, 13405, 10905, 12985, 16393, 16393],
    47401: [13590, 13518, 11018, 13087, 16503, 16503],
    47601: [13700, 13632, 11132, 13188, 16613, 16613],
    47801: [13810, 13746, 11246, 13289, 16723, 16723],
    48001: [13920, 13860, 11360, 13391, 16833, 16833],
    48201: [14030, 13973, 11473, 13492, 16943, 16943],
    48401: [14140, 14087, 11587, 13593, 17053, 17053],
    48601: [14250, 14201, 11701, 13695, 17163, 17163],
    48801: [14360, 14312, 11812, 13796, 17273, 17273],
    49001: [14470, 14425, 11925, 13897, 17383, 17383],
    49201: [14580, 14539, 12039, 13999, 17493, 17493],
    49401: [14690, 14653, 12153, 14100, 17603, 17603],
    49601: [14800, 14767, 12267, 14201, 17713, 17713],
    49801: [14910, 14880, 12380, 14303, 17823, 17823],
    50001: [15020, 14994, 12500, 14404, 17933, 17933],
    50201: [15130, 15108, 12620, 14505, 18043, 18043],
    50401: [15240, 15219, 12737, 14607, 18153, 18153],
    50601: [15350, 15333, 12857, 14708, 18263, 18263],
    50801: [15460, 15446, 12976, 14809, 18373, 18373],
    51001: [15570, 15560, 13096, 14911, 18483, 18483],
    51201: [15680, 15674, 13216, 15012, 18593, 18593],
    51401: [15791, 15778, 13326, 15113, 18703, 18703],
    51601: [15900, 15888, 13442, 15215, 18813, 18813],
    51801: [16010, 15998, 13558, 15316, 18923, 18923],
    52001: [16120, 16108, 13674, 15417, 19033, 19033],
    52201: [16230, 16218, 13790, 15519, 19143, 19143],
    52401: [16340, 16328, 13906, 15620, 19253, 19253],
    52601: [16450, 16438, 14022, 15721, 19363, 19363],
    52801: [16560, 16548, 14138, 15823, 19473, 19473],
    53001: [16670, 16658, 14254, 15924, 19583, 19583],
    53201: [16780, 16768, 14370, 16025, 19693, 19693],
    53401: [16890, 16878, 14486, 16127, 19803, 19803],
    53601: [17000, 16988, 14602, 16228, 19913, 19913],
    53801: [17110, 17098, 14718, 16329, 20023, 20023],
    54001: [17220, 17208, 14834, 16431, 20133, 20133],
    54201: [17330, 17318, 14950, 16532, 20243, 20243],
    54401: [17444, 17433, 15071, 16633, 20353, 20353],
    54601: [17560, 17552, 15196, 16735, 20463, 20463],
    54801: [17676, 17667, 15317, 16836, 20573, 20573],
    55001: [17792, 17781, 15437, 16937, 20683, 20683],
    55201: [17908, 17901, 15563, 17039, 20793, 20793],
    55401: [18024, 18015, 15683, 17140, 20903, 20903],
    55601: [18140, 18130, 15804, 17241, 21013, 21013],
    55801: [18256, 18249, 15929, 17343, 21123, 21123],
    56001: [18372, 18363, 16049, 17444, 21233, 21233],
    56201: [18488, 18483, 16175, 17545, 21343, 21343],
    56401: [18604, 18597, 16295, 17647, 21453, 21453],
    56601: [18720, 18712, 16416, 17748, 21563, 21563],
    56801: [18836, 18831, 16541, 17849, 21673, 21673],
    57001: [18952, 18945, 16661, 17951, 21783, 21783],
    57201: [19068, 19065, 16787, 18052, 21893, 21893],
    57401: [19184, 19179, 16907, 18153, 22003, 22003],
    57601: [19300, 19294, 17028, 18255, 22113, 22113],
    57801: [19416, 19413, 17153, 18356, 22223, 22223],
    58001: [19532, 19528, 17274, 18457, 22333, 22333],
    58201: [19648, 19642, 17394, 18559, 22443, 22443],
    58401: [19764, 19761, 17519, 18660, 22553, 22553],
    58601: [19880, 19876, 17640, 18761, 22663, 22663],
    58801: [19996, 19995, 17765, 18863, 22773, 22773],
    59001: [20112, 20110, 17886, 18964, 22883, 22883],
    59201: [20228, 20224, 18006, 19065, 22993, 22993],
    59401: [20344, 20343, 18131, 19167, 23103, 23103],
    59601: [20460, 20458, 18252, 19268, 23213, 23213],
    59801: [20576, 20573, 18373, 19369, 23323, 23323],
    60001: [20692, 20692, 18498, 19471, 23433, 23433],
    60201: [20808, 20806, 18618, 19572, 23543, 23543],
    60401: [20924, 20925, 18743, 19673, 23653, 23653],
    60601: [21040, 21040, 18864, 19775, 23763, 23763],
    60801: [21156, 21155, 18985, 19876, 23873, 23873],
    61001: [21272, 21274, 19110, 19977, 23983, 23983],
    61201: [21388, 21388, 19230, 20079, 24093, 24093],
    61401: [21504, 21503, 19351, 20180, 24203, 24203],
    61601: [21620, 21622, 19476, 20281, 24313, 24313],
    61801: [21736, 21737, 19597, 20383, 24423, 24423],
    62001: [21852, 21856, 19722, 20484, 24533, 24533],
    62201: [21968, 21970, 19842, 20585, 24643, 24643],
    62401: [22084, 22085, 19963, 20687, 24753, 24753],
    62601: [22200, 22204, 20088, 20788, 24863, 24863],
    62801: [22316, 22319, 20209, 20889, 24973, 24973],
    63001: [22432, 22433, 20329, 20991, 25083, 25083],
    63201: [22548, 22553, 20455, 21092, 25193, 25193],
    63401: [22664, 22667, 20575, 21193, 25303, 25303],
    63601: [22780, 22786, 20700, 21295, 25413, 25413],
    63801: [22896, 22901, 20821, 21396, 25523, 25523],
    64001: [23012, 23015, 20941, 21497, 25633, 25633],
    64201: [23128, 23135, 21067, 21599, 25743, 25743],
    64401: [23244, 23249, 21187, 21700, 25853, 25853],
    64601: [23360, 23364, 21308, 21801, 25963, 25963],
    64801: [23476, 23483, 21433, 21903, 26073, 26073],
    65001: [23592, 23598, 21554, 22004, 26183, 26183],
    65201: [23708, 23717, 21679, 22105, 26293, 26293],
    65401: [23824, 23831, 21799, 22207, 26403, 26403],
    65601: [23940, 23946, 21920, 22308, 26513, 26513],
    65801: [24056, 24065, 22045, 22410, 26623, 26623],
    66001: [24172, 24180, 22166, 22511, 26733, 26733],
    66201: [24288, 24294, 22286, 22612, 26843, 26843],
    66401: [24404, 24413, 22411, 22714, 26953, 26953],
    66601: [24520, 24528, 22532, 22815, 27063, 27063],
    66801: [24636, 24647, 22657, 22916, 27173, 27173],
    67001: [24752, 24762, 22778, 23018, 27283, 27283],
    67201: [24868, 24876, 22898, 23119, 27393, 27393],
    67401: [24984, 24995, 23023, 23220, 27503, 27503],
    67601: [25100, 25110, 23144, 23322, 27613, 27613],
    67801: [25216, 25229, 23269, 23423, 27723, 27723],
    68001: [25332, 25344, 23390, 23524, 27833, 27833],
    68201: [25448, 25458, 23510, 23626, 27943, 27943],
    68401: [25564, 25578, 23636, 23727, 28053, 28053],
    68601: [25680, 25692, 23756, 23828, 28163, 28163],
    68801: [25796, 25807, 23877, 23930, 28273, 28273],
    69001: [25912, 25926, 24002, 24031, 28383, 28383],
    69201: [26028, 26040, 24122, 24132, 28493, 28493],
    69401: [26144, 26160, 24248, 24234, 28603, 28603],
    69601: [26260, 26274, 24368, 24335, 28713, 28713],
    69801: [26376, 26389, 24489, 24436, 28823, 28823],
    70001: [26492, 26508, 24614, 24538, 28933, 28933],
    70201: [26608, 26623, 24735, 24639, 29043, 29043],
    70401: [26724, 26737, 24855, 24740, 29153, 29153],
    70601: [26840, 26856, 24980, 24842, 29263, 29263],
    70801: [26956, 26971, 25101, 24943, 29373, 29373],
    71001: [27072, 27090, 25226, 25044, 29483, 29483],
    71201: [27188, 27205, 25347, 25146, 29593, 29593],
    71401: [27304, 27319, 25467, 25247, 29703, 29703],
    71601: [27420, 27438, 25592, 25348, 29813, 29813],
    71801: [27536, 27553, 25713, 25450, 29923, 29923],
    72001: [27652, 27668, 25834, 25551, 30033, 30033],
    72201: [27768, 27787, 25959, 25652, 30143, 30143],
    72401: [27884, 27901, 26079, 25754, 30253, 30253],
    72601: [28000, 28020, 26204, 25855, 30363, 30363],
    72801: [28116, 28135, 26325, 25956, 30473, 30473],
    73001: [28232, 28250, 26446, 26058, 30583, 30583],
    73201: [28348, 28369, 26571, 26159, 30693, 30693],
    73401: [28464, 28483, 26691, 26260, 30803, 30803],
    73601: [28580, 28598, 26812, 26362, 30913, 30913],
    73801: [28696, 28717, 26937, 26463, 31023, 31023],
    74001: [28812, 28832, 27058, 26564, 31133, 31133],
    74201: [28928, 28951, 27183, 26666, 31243, 31243],
    74401: [29044, 29065, 27303, 26767, 31353, 31353],
    74601: [29160, 29180, 27424, 26868, 31463, 31463],
    74801: [29276, 29299, 27549, 26970, 31573, 31573],
    75001: [29392, 29414, 27670, 27071, 31683, 31683],
    75201: [29508, 29528, 27790, 27172, 31793, 31793],
    75401: [29624, 29648, 27916, 27274, 31903, 31903],
    75601: [29740, 29762, 28036, 27375, 32013, 32013],
    75801: [29856, 29881, 28161, 27476, 32123, 32123],
    76001: [29972, 29996, 28282, 27578, 32233, 32233],
    76201: [30088, 30110, 28402, 27679, 32343, 32343],
    76401: [30204, 30230, 28528, 27780, 32453, 32453],
    76601: [30320, 30344, 28648, 27882, 32563, 32563],
    76801: [30436, 30463, 28773, 27983, 32673, 32673],
    77001: [30552, 30578, 28894, 28084, 32783, 32783],
    77201: [30668, 30693, 29015, 28186, 32893, 32893],
    77401: [30784, 30812, 29140, 28287, 33003, 33003],
    77601: [30900, 30926, 29260, 28388, 33113, 33113],
    77801: [31016, 31041, 29381, 28490, 33223, 33223],
    78001: [31132, 31160, 29506, 28591, 33333, 33333],
    78201: [31248, 31275, 29627, 28692, 33443, 33443],
    78401: [31364, 31394, 29752, 28794, 33553, 33553],
    78601: [31480, 31508, 29872, 28895, 33663, 33663],
    78801: [31596, 31623, 29993, 28996, 33773, 33773],
    79001: [31712, 31742, 30118, 29098, 33883, 33883],
    79201: [31828, 31857, 30239, 29199, 33993, 33993],
    79401: [31944, 31971, 30359, 29300, 34103, 34103],
    79601: [32060, 32090, 30484, 29402, 34213, 34213],
    79801: [32176, 32205, 30605, 29503, 34323, 34323],
    80001: [40, 40, 38, 37, 43, 43, '%'],
    80801: [40, 40, 39, 37, 43, 43, '%'],
    81001: [40, 41, 39, 37, 43, 43, '%'],
    81201: [41, 41, 39, 37, 43, 43, '%'],
    83601: [41, 41, 39, 38, 43, 43, '%'],
    84001: [41, 41, 39, 38, 44, 44, '%'],
    84401: [41, 41, 40, 38, 44, 44, '%'],
    85801: [41, 42, 40, 38, 44, 44, '%'],
    86201: [42, 42, 40, 38, 44, 44, '%'],
    88601: [42, 42, 41, 38, 44, 44, '%'],
    90601: [42, 42, 41, 39, 44, 44, '%'],
    91401: [42, 43, 41, 39, 44, 44, '%'],
    91601: [43, 43, 41, 39, 44, 44, '%'],
    92001: [43, 43, 41, 39, 45, 45, '%'],
    93001: [43, 43, 42, 39, 45, 45, '%'],
    97601: [43, 44, 42, 39, 45, 45, '%'],
    98001: [44, 44, 43, 39, 45, 45, '%'],
    98601: [44, 44, 43, 40, 45, 45, '%'],
    101801: [44, 44, 43, 40, 46, 46, '%'],
    103601: [44, 44, 44, 40, 46, 46, '%'],
    104801: [44, 45, 44, 40, 46, 46, '%'],
    105201: [45, 45, 44, 40, 46, 46, '%'],
    108401: [45, 45, 44, 41, 46, 46, '%'],
    109801: [45, 45, 45, 41, 46, 46, '%'],
    113001: [45, 46, 45, 41, 46, 46, '%'],
    113601: [46, 46, 45, 41, 46, 46, '%'],
    113801: [46, 46, 45, 41, 47, 47, '%'],
    117001: [46, 46, 46, 41, 47, 47, '%'],
    120201: [46, 46, 46, 42, 47, 47, '%'],
    122801: [46, 47, 46, 42, 47, 47, '%'],
    123601: [47, 47, 46, 42, 47, 47, '%'],
    124801: [47, 47, 47, 42, 47, 47, '%'],
    129001: [47, 47, 47, 42, 48, 48, '%'],
    134201: [47, 48, 48, 42, 48, 48, '%'],
    135001: [47, 48, 48, 43, 48, 48, '%'],
    135401: [48, 48, 48, 43, 48, 48, '%'],
    148801: [48, 49, 49, 43, 49, 49, '%'],
    149601: [49, 49, 49, 43, 49, 49, '%'],
    153801: [49, 49, 49, 44, 49, 49, '%'],
    175801: [50, 50, 50, 44, 50, 50, '%'],
    178801: [50, 50, 50, 45, 50, 50, '%'],
    213401: [50, 50, 50, 46, 50, 50, '%'],
    215001: [51, 51, 51, 46, 51, 51, '%'],
    264601: [51, 51, 51, 47, 51, 51, '%'],
    276401: [52, 52, 52, 47, 52, 52, '%'],
    348001: [52, 52, 52, 48, 52, 52, '%'],
    387001: [53, 53, 53, 48, 53, 53, '%'],
    508601: [53, 53, 53, 49, 53, 53, '%'],
    645001: [54, 54, 54, 49, 54, 54, '%'],
    944001: [54, 54, 54, 50, 54, 54, '%'],
    1935201: [55, 55, 55, 50, 55, 55, '%']
  },
  36: {
    1: [0, 0, 0, 0, 0, 0],
    1701: [126, 0, 125, 1, 126, 33],
    1801: [137, 0, 133, 4, 137, 70],
    1901: [147, 0, 141, 6, 147, 107],
    2001: [158, 0, 150, 8, 158, 144],
    2101: [160, 0, 150, 10, 181, 181],
    2201: [171, 0, 158, 12, 218, 218],
    2301: [181, 0, 166, 14, 255, 255],
    2401: [192, 0, 175, 17, 292, 292],
    2501: [202, 0, 183, 19, 329, 329],
    2601: [213, 0, 191, 21, 366, 366],
    2701: [223, 0, 200, 23, 403, 403],
    2801: [225, 0, 200, 25, 440, 440],
    2901: [236, 0, 208, 27, 477, 477],
    3001: [246, 0, 216, 30, 514, 514],
    3101: [257, 0, 225, 32, 551, 551],
    3201: [267, 0, 233, 34, 588, 588],
    3301: [278, 0, 241, 36, 625, 625],
    3401: [280, 0, 241, 38, 662, 662],
    3501: [290, 0, 250, 40, 699, 699],
    3601: [301, 0, 258, 51, 736, 736],
    3701: [311, 0, 266, 76, 773, 773],
    3801: [322, 0, 275, 101, 810, 810],
    3901: [332, 0, 283, 127, 847, 847],
    4001: [334, 0, 283, 151, 875, 875],
    4101: [344, 0, 291, 176, 906, 906],
    4201: [354, 0, 300, 201, 937, 937],
    4301: [364, 0, 308, 225, 965, 965],
    4401: [374, 6, 317, 250, 995, 995],
    4501: [384, 33, 326, 275, 1023, 1023],
    4601: [386, 61, 328, 299, 1054, 1054],
    4701: [396, 89, 338, 324, 1085, 1085],
    4801: [406, 117, 348, 349, 1113, 1113],
    4901: [416, 144, 358, 374, 1143, 1143],
    5001: [426, 172, 368, 398, 1171, 1171],
    5101: [436, 200, 378, 423, 1202, 1202],
    5201: [447, 228, 388, 448, 1233, 1233],
    5301: [448, 252, 389, 472, 1260, 1260],
    5401: [466, 280, 399, 496, 1290, 1290],
    5501: [488, 308, 409, 520, 1317, 1317],
    5601: [511, 336, 419, 544, 1348, 1348],
    5701: [533, 363, 429, 568, 1378, 1378],
    5801: [556, 391, 439, 592, 1405, 1405],
    5901: [578, 419, 441, 616, 1435, 1435],
    6001: [601, 447, 451, 640, 1462, 1462],
    6101: [623, 474, 461, 665, 1493, 1493],
    6201: [646, 502, 471, 689, 1523, 1523],
    6301: [668, 530, 480, 713, 1550, 1550],
    6401: [691, 558, 490, 737, 1580, 1580],
    6501: [713, 582, 492, 761, 1607, 1607],
    6601: [736, 610, 502, 785, 1638, 1638],
    6701: [758, 638, 512, 809, 1668, 1668],
    6801: [780, 665, 522, 833, 1695, 1695],
    6901: [803, 693, 532, 857, 1725, 1725],
    7001: [825, 721, 542, 881, 1752, 1752],
    7101: [848, 749, 543, 906, 1783, 1783],
    7201: [870, 776, 553, 930, 1813, 1813],
    7301: [893, 804, 563, 954, 1840, 1840],
    7401: [915, 832, 573, 978, 1870, 1870],
    7501: [938, 860, 583, 1002, 1897, 1897],
    7601: [960, 887, 593, 1026, 1928, 1928],
    7701: [983, 912, 603, 1050, 1958, 1958],
    7801: [1005, 940, 604, 1074, 1985, 1985],
    7901: [1028, 958, 614, 1099, 2015, 2015],
    8001: [1050, 980, 623, 1123, 2042, 2042],
    8101: [1073, 1002, 633, 1147, 2073, 2073],
    8201: [1095, 1026, 643, 1171, 2103, 2103],
    8301: [1118, 1048, 652, 1195, 2130, 2130],
    8401: [1140, 1069, 654, 1219, 2160, 2160],
    8501: [1162, 1091, 663, 1243, 2187, 2187],
    8601: [1185, 1116, 673, 1267, 2218, 2218],
    8701: [1207, 1137, 683, 1291, 2248, 2248],
    8801: [1230, 1159, 692, 1315, 2275, 2275],
    8901: [1252, 1180, 702, 1340, 2305, 2305],
    9001: [1275, 1205, 703, 1364, 2332, 2332],
    9101: [1297, 1227, 713, 1388, 2363, 2363],
    9201: [1320, 1248, 722, 1412, 2393, 2393],
    9301: [1342, 1272, 732, 1436, 2420, 2420],
    9401: [1365, 1293, 742, 1460, 2450, 2450],
    9501: [1387, 1314, 751, 1484, 2477, 2477],
    9601: [1410, 1336, 753, 1508, 2508, 2508],
    9701: [1432, 1360, 762, 1532, 2538, 2538],
    9801: [1455, 1381, 772, 1557, 2565, 2565],
    9901: [1477, 1402, 782, 1581, 2595, 2595],
    10001: [1499, 1423, 791, 1605, 2622, 2622],
    10101: [1522, 1447, 801, 1629, 2653, 2653],
    10201: [1544, 1468, 811, 1653, 2683, 2683],
    10301: [1567, 1490, 812, 1677, 2710, 2710],
    10401: [1589, 1511, 821, 1701, 2740, 2740],
    10501: [1612, 1535, 831, 1725, 2767, 2767],
    10601: [1634, 1556, 841, 1749, 2798, 2798],
    10701: [1657, 1577, 850, 1773, 2828, 2828],
    10801: [1679, 1598, 860, 1798, 2855, 2855],
    10901: [1702, 1623, 861, 1822, 2888, 2888],
    11001: [1725, 1644, 871, 1846, 2924, 2924],
    11101: [1747, 1665, 881, 1871, 2961, 2961],
    11201: [1770, 1689, 890, 1895, 2997, 2997],
    11301: [1793, 1710, 900, 1919, 3033, 3033],
    11401: [1816, 1731, 910, 1944, 3069, 3069],
    11501: [1838, 1752, 911, 1968, 3106, 3106],
    11601: [1861, 1777, 921, 1992, 3142, 3142],
    11701: [1884, 1798, 930, 2017, 3178, 3178],
    11801: [1907, 1819, 940, 2041, 3214, 3214],
    11901: [1929, 1840, 949, 2066, 3251, 3251],
    12001: [1952, 1864, 959, 2090, 3287, 3287],
    12101: [1975, 1885, 960, 2114, 3323, 3323],
    12201: [1998, 1906, 970, 2139, 3359, 3359],
    12301: [2020, 1928, 980, 2163, 3396, 3396],
    12401: [2043, 1952, 989, 2188, 3432, 3432],
    12501: [2066, 1973, 999, 2212, 3471, 3471],
    12601: [2089, 1994, 1009, 2237, 3511, 3511],
    12701: [2112, 2015, 1018, 2261, 3550, 3550],
    12801: [2135, 2039, 1020, 2286, 3589, 3589],
    12901: [2158, 2061, 1029, 2310, 3628, 3628],
    13001: [2187, 2088, 1039, 2340, 3671, 3671],
    13101: [2219, 2121, 1049, 2372, 3710, 3710],
    13201: [2251, 2151, 1060, 2403, 3749, 3749],
    13301: [2283, 2184, 1070, 2435, 3788, 3788],
    13401: [2314, 2215, 1072, 2467, 3828, 3828],
    13501: [2346, 2245, 1082, 2499, 3870, 3870],
    13601: [2378, 2278, 1092, 2531, 3909, 3909],
    13701: [2410, 2308, 1102, 2563, 3949, 3949],
    13801: [2442, 2341, 1113, 2595, 3988, 3988],
    13901: [2474, 2372, 1123, 2627, 4027, 4027],
    14001: [2505, 2402, 1124, 2658, 4068, 4068],
    14101: [2536, 2435, 1135, 2689, 4107, 4107],
    14201: [2567, 2465, 1145, 2720, 4145, 4145],
    14301: [2598, 2499, 1155, 2750, 4183, 4183],
    14401: [2628, 2529, 1165, 2781, 4221, 4221],
    14501: [2659, 2559, 1176, 2812, 4262, 4262],
    14601: [2690, 2592, 1178, 2843, 4300, 4300],
    14701: [2721, 2622, 1188, 2874, 4339, 4339],
    14801: [2752, 2656, 1198, 2905, 4377, 4377],
    14901: [2783, 2686, 1208, 2935, 4415, 4415],
    15001: [2813, 2716, 1218, 2966, 4456, 4456],
    15101: [2844, 2749, 1229, 2997, 4494, 4494],
    15201: [2875, 2779, 1239, 3028, 4533, 4533],
    15301: [2906, 2813, 1241, 3059, 4571, 4571],
    15401: [2937, 2843, 1251, 3090, 4609, 4609],
    15501: [2968, 2873, 1261, 3120, 4650, 4650],
    15601: [2998, 2906, 1271, 3151, 4688, 4688],
    15701: [3029, 2937, 1281, 3182, 4726, 4726],
    15801: [3060, 2970, 1292, 3213, 4765, 4765],
    15901: [3091, 3000, 1293, 3244, 4803, 4803],
    16001: [3122, 3030, 1304, 3275, 4844, 4844],
    16101: [3153, 3063, 1314, 3305, 4882, 4882],
    16201: [3183, 3094, 1324, 3336, 4920, 4920],
    16301: [3214, 3127, 1334, 3367, 4958, 4958],
    16401: [3245, 3157, 1344, 3398, 4997, 4997],
    16501: [3276, 3187, 1346, 3429, 5038, 5038],
    16601: [3307, 3221, 1357, 3460, 5076, 5076],
    16701: [3338, 3251, 1367, 3490, 5114, 5114],
    16801: [3368, 3284, 1377, 3521, 5152, 5152],
    16901: [3399, 3314, 1387, 3552, 5191, 5191],
    17001: [3430, 3344, 1397, 3583, 5232, 5232],
    17101: [3461, 3378, 1399, 3614, 5270, 5270],
    17201: [3492, 3408, 1409, 3645, 5308, 5308],
    17301: [3523, 3441, 1420, 3675, 5346, 5346],
    17401: [3553, 3471, 1430, 3706, 5384, 5384],
    17501: [3584, 3504, 1440, 3737, 5426, 5426],
    17601: [3615, 3535, 1450, 3768, 5464, 5464],
    17701: [3646, 3565, 1460, 3799, 5502, 5502],
    17801: [3677, 3598, 1462, 3829, 5540, 5540],
    17901: [3708, 3628, 1478, 3860, 5578, 5578],
    18001: [3738, 3662, 1507, 3891, 5619, 5619],
    18101: [3769, 3692, 1532, 3922, 5658, 5658],
    18201: [3800, 3722, 1557, 3953, 5696, 5696],
    18301: [3831, 3755, 1585, 3984, 5734, 5734],
    18401: [3862, 3785, 1610, 4014, 5772, 5772],
    18501: [3893, 3819, 1639, 4045, 5813, 5813],
    18601: [3923, 3849, 1664, 4076, 5851, 5851],
    18701: [3954, 3879, 1689, 4107, 5890, 5890],
    18801: [3985, 3912, 1717, 4138, 5928, 5928],
    18901: [4016, 3943, 1743, 4169, 5966, 5966],
    19001: [4047, 3976, 1771, 4200, 6007, 6007],
    19101: [4077, 4006, 1796, 4230, 6045, 6045],
    19201: [4108, 4036, 1821, 4261, 6084, 6084],
    19301: [4139, 4068, 1848, 4292, 6122, 6122],
    19401: [4170, 4098, 1873, 4323, 6160, 6160],
    19501: [4201, 4130, 1900, 4354, 6201, 6201],
    19601: [4232, 4159, 1924, 4385, 6239, 6239],
    19701: [4262, 4189, 1949, 4415, 6277, 6277],
    19801: [4293, 4221, 1976, 4446, 6316, 6316],
    19901: [4324, 4251, 2001, 4477, 6354, 6354],
    20001: [4386, 4312, 2052, 4539, 6433, 6433],
    20201: [4447, 4374, 2104, 4600, 6509, 6509],
    20401: [4509, 4436, 2156, 4662, 6589, 6589],
    20601: [4571, 4494, 2204, 4724, 6665, 6665],
    20801: [4632, 4556, 2256, 4785, 6742, 6742],
    21001: [4694, 4618, 2308, 4847, 6821, 6821],
    21201: [4756, 4679, 2359, 4909, 6897, 6897],
    21401: [4817, 4741, 2411, 4970, 6977, 6977],
    21601: [4879, 4800, 2460, 5032, 7053, 7053],
    21801: [4941, 4862, 2512, 5094, 7129, 7129],
    22001: [5003, 4923, 2563, 5156, 7209, 7209],
    22201: [5066, 4985, 2615, 5219, 7287, 7287],
    22401: [5130, 5050, 2670, 5282, 7368, 7368],
    22601: [5193, 5111, 2721, 5346, 7446, 7446],
    22801: [5256, 5173, 2773, 5409, 7524, 7524],
    23001: [5319, 5238, 2828, 5472, 7605, 7605],
    23201: [5383, 5299, 2879, 5536, 7683, 7683],
    23401: [5446, 5361, 2931, 5599, 7764, 7764],
    23601: [5509, 5426, 2986, 5662, 7842, 7842],
    23801: [5573, 5487, 3037, 5726, 7920, 7920],
    24001: [5636, 5549, 3089, 5789, 8001, 8001],
    24201: [5699, 5611, 3141, 5852, 8079, 8079],
    24401: [5763, 5675, 3195, 5916, 8160, 8160],
    24601: [5826, 5737, 3247, 5979, 8238, 8238],
    24801: [5889, 5799, 3299, 6042, 8316, 8316],
    25001: [5953, 5863, 3363, 6105, 8397, 8397],
    25201: [6016, 5925, 3425, 6169, 8475, 8475],
    25401: [6079, 5987, 3487, 6232, 8556, 8556],
    25601: [6143, 6051, 3551, 6295, 8634, 8634],
    25801: [6206, 6113, 3613, 6359, 8712, 8712],
    26001: [6269, 6175, 3675, 6422, 8793, 8793],
    26201: [6332, 6239, 3739, 6485, 8871, 8871],
    26401: [6396, 6301, 3801, 6549, 8952, 8952],
    26601: [6459, 6363, 3863, 6612, 9030, 9030],
    26801: [6522, 6424, 3924, 6675, 9108, 9108],
    27001: [6586, 6489, 3989, 6739, 9189, 9189],
    27201: [6649, 6551, 4051, 6802, 9267, 9267],
    27401: [6712, 6612, 4112, 6865, 9348, 9348],
    27601: [6776, 6677, 4177, 6928, 9426, 9426],
    27801: [6839, 6739, 4239, 6992, 9504, 9504],
    28001: [6902, 6800, 4300, 7055, 9585, 9585],
    28201: [6966, 6865, 4365, 7118, 9663, 9663],
    28401: [7029, 6927, 4427, 7182, 9744, 9744],
    28601: [7092, 6988, 4488, 7245, 9822, 9822],
    28801: [7155, 7053, 4553, 7308, 9900, 9900],
    29001: [7219, 7115, 4615, 7372, 9981, 9981],
    29201: [7282, 7176, 4676, 7435, 10059, 10059],
    29401: [7346, 7242, 4742, 7498, 10140, 10140],
    29601: [7409, 7305, 4805, 7562, 10218, 10218],
    29801: [7472, 7368, 4868, 7625, 10296, 10296],
    30001: [7535, 7431, 4931, 7688, 10377, 10377],
    30201: [7599, 7497, 4997, 7752, 10455, 10455],
    30401: [7662, 7560, 5060, 7815, 10536, 10536],
    30601: [7725, 7623, 5123, 7878, 10614, 10614],
    30801: [7789, 7689, 5189, 7941, 10692, 10692],
    31001: [7852, 7752, 5252, 8005, 10773, 10773],
    31201: [7915, 7815, 5315, 8068, 10851, 10851],
    31401: [7979, 7881, 5381, 8131, 10932, 10932],
    31601: [8042, 7941, 5441, 8195, 11007, 11007],
    31801: [8105, 8004, 5504, 8258, 11079, 11079],
    32001: [8168, 8067, 5567, 8321, 11151, 11151],
    32201: [8231, 8130, 5630, 8384, 11223, 11223],
    32401: [8297, 8196, 5696, 8447, 11295, 11295],
    32601: [8369, 8268, 5768, 8510, 11367, 11367],
    32801: [8441, 8340, 5840, 8573, 11439, 11439],
    33001: [8513, 8412, 5912, 8636, 11511, 11511],
    33201: [8585, 8484, 5984, 8699, 11583, 11583],
    33401: [8657, 8556, 6056, 8762, 11655, 11655],
    33601: [8729, 8628, 6128, 8825, 11727, 11727],
    33801: [8801, 8700, 6200, 8889, 11799, 11799],
    34001: [8873, 8772, 6272, 8952, 11871, 11871],
    34201: [8945, 8844, 6344, 9015, 11943, 11943],
    34401: [9017, 8916, 6416, 9078, 12015, 12015],
    34601: [9089, 8988, 6488, 9141, 12087, 12087],
    34801: [9161, 9060, 6560, 9204, 12159, 12159],
    35001: [9233, 9132, 6632, 9267, 12231, 12231],
    35201: [9305, 9204, 6704, 9330, 12303, 12303],
    35401: [9377, 9276, 6776, 9393, 12375, 12375],
    35601: [9449, 9348, 6848, 9456, 12447, 12447],
    35801: [9521, 9420, 6920, 9519, 12519, 12519],
    36001: [9593, 9492, 6992, 9582, 12591, 12591],
    36201: [9665, 9564, 7064, 9646, 12663, 12663],
    36401: [9737, 9636, 7136, 9709, 12735, 12735],
    36601: [9809, 9708, 7208, 9772, 12807, 12807],
    36801: [9881, 9780, 7280, 9835, 12879, 12879],
    37001: [9953, 9852, 7352, 9898, 12951, 12951],
    37201: [10025, 9924, 7424, 9961, 13023, 13023],
    37401: [10097, 9996, 7496, 10024, 13095, 13095],
    37601: [10169, 10068, 7568, 10087, 13167, 13167],
    37801: [10241, 10140, 7640, 10150, 13239, 13239],
    38001: [10313, 10212, 7712, 10213, 13311, 13311],
    38201: [10385, 10284, 7784, 10276, 13383, 13383],
    38401: [10457, 10356, 7856, 10339, 13455, 13455],
    38601: [10529, 10428, 7928, 10402, 13527, 13527],
    38801: [10601, 10500, 8000, 10466, 13599, 13599],
    39001: [10673, 10572, 8072, 10529, 13671, 13671],
    39201: [10745, 10644, 8144, 10592, 13743, 13743],
    39401: [10817, 10716, 8216, 10655, 13815, 13815],
    39601: [10889, 10788, 8288, 10718, 13887, 13887],
    39801: [10961, 10860, 8360, 10781, 13959, 13959],
    40001: [11033, 10932, 8432, 10844, 14031, 14031],
    40201: [11105, 11004, 8504, 10907, 14103, 14103],
    40401: [11177, 11076, 8576, 10970, 14175, 14175],
    40601: [11249, 11148, 8648, 11033, 14247, 14247],
    40801: [11321, 11220, 8720, 11096, 14319, 14319],
    41001: [11393, 11292, 8792, 11159, 14391, 14391],
    41201: [11465, 11364, 8864, 11223, 14463, 14463],
    41401: [11537, 11436, 8936, 11286, 14535, 14535],
    41601: [11609, 11508, 9008, 11349, 14607, 14607],
    41801: [11681, 11580, 9080, 11412, 14679, 14679],
    42001: [11753, 11652, 9152, 11475, 14751, 14751],
    42201: [11825, 11724, 9224, 11538, 14823, 14823],
    42401: [11897, 11796, 9296, 11601, 14895, 14895],
    42601: [11969, 11868, 9368, 11664, 14967, 14967],
    42801: [12041, 11940, 9440, 11727, 15039, 15039],
    43001: [12113, 12012, 9512, 11790, 15111, 15111],
    43201: [12185, 12084, 9584, 11853, 15183, 15183],
    43401: [12257, 12156, 9656, 11916, 15255, 15255],
    43601: [12329, 12228, 9728, 11980, 15327, 15327],
    43801: [12401, 12300, 9800, 12043, 15399, 15399],
    44001: [12473, 12372, 9872, 12106, 15471, 15471],
    44201: [12545, 12444, 9944, 12169, 15543, 15543],
    44401: [12617, 12516, 10016, 12232, 15615, 15615],
    44601: [12689, 12588, 10088, 12295, 15687, 15687],
    44801: [12761, 12660, 10160, 12358, 15759, 15759],
    45001: [12833, 12732, 10232, 12421, 15831, 15831],
    45201: [12905, 12804, 10304, 12484, 15903, 15903],
    45401: [12977, 12876, 10376, 12547, 15975, 15975],
    45601: [13049, 12948, 10448, 12610, 16047, 16047],
    45801: [13121, 13020, 10520, 12673, 16119, 16119],
    46001: [13193, 13092, 10592, 12736, 16191, 16191],
    46201: [13297, 13206, 10706, 12831, 16295, 16295],
    46401: [13409, 13323, 10823, 12934, 16407, 16407],
    46601: [13521, 13440, 10940, 13037, 16519, 16519],
    46801: [13633, 13557, 11057, 13140, 16631, 16631],
    47001: [13745, 13674, 11174, 13244, 16743, 16743],
    47201: [13857, 13788, 11288, 13347, 16855, 16855],
    47401: [13969, 13905, 11405, 13450, 16967, 16967],
    47601: [14081, 14022, 11522, 13553, 17079, 17079],
    47801: [14193, 14139, 11639, 13656, 17191, 17191],
    48001: [14305, 14256, 11756, 13759, 17303, 17303],
    48201: [14417, 14373, 11873, 13862, 17415, 17415],
    48401: [14529, 14490, 11990, 13965, 17527, 17527],
    48601: [14641, 14607, 12107, 14068, 17639, 17639],
    48801: [14753, 14721, 12221, 14171, 17751, 17751],
    49001: [14865, 14838, 12338, 14274, 17863, 17863],
    49201: [14977, 14955, 12455, 14377, 17975, 17975],
    49401: [15089, 15072, 12572, 14481, 18087, 18087],
    49601: [15201, 15189, 12689, 14584, 18199, 18199],
    49801: [15313, 15306, 12806, 14687, 18311, 18311],
    50001: [15425, 15423, 12929, 14790, 18423, 18423],
    50201: [15537, 15540, 13052, 14893, 18535, 18535],
    50401: [15649, 15654, 13172, 14996, 18647, 18647],
    50601: [15761, 15771, 13295, 15099, 18759, 18759],
    50801: [15873, 15888, 13418, 15202, 18871, 18871],
    51001: [15985, 16005, 13541, 15305, 18983, 18983],
    51201: [16097, 16122, 13664, 15408, 19095, 19095],
    51401: [16209, 16229, 13777, 15511, 19207, 19207],
    51601: [16321, 16341, 13895, 15614, 19319, 19319],
    51801: [16433, 16453, 14013, 15718, 19431, 19431],
    52001: [16545, 16565, 14131, 15821, 19543, 19543],
    52201: [16657, 16677, 14249, 15924, 19655, 19655],
    52401: [16769, 16789, 14367, 16027, 19767, 19767],
    52601: [16881, 16901, 14485, 16130, 19879, 19879],
    52801: [16993, 17013, 14603, 16233, 19991, 19991],
    53001: [17105, 17125, 14721, 16336, 20103, 20103],
    53201: [17217, 17237, 14839, 16439, 20215, 20215],
    53401: [17329, 17349, 14957, 16542, 20327, 20327],
    53601: [17441, 17461, 15075, 16645, 20439, 20439],
    53801: [17553, 17573, 15193, 16748, 20551, 20551],
    54001: [17665, 17685, 15311, 16851, 20663, 20663],
    54201: [17777, 17797, 15429, 16954, 20775, 20775],
    54401: [17892, 17914, 15552, 17058, 20887, 20887],
    54601: [18010, 18035, 15679, 17161, 20999, 20999],
    54801: [18128, 18152, 15802, 17264, 21111, 21111],
    55001: [18246, 18269, 15925, 17367, 21223, 21223],
    55201: [18364, 18390, 16052, 17470, 21335, 21335],
    55401: [18482, 18507, 16175, 17573, 21447, 21447],
    55601: [18600, 18623, 16297, 17676, 21559, 21559],
    55801: [18718, 18745, 16425, 17779, 21671, 21671],
    56001: [18836, 18861, 16547, 17882, 21783, 21783],
    56201: [18954, 18983, 16675, 17985, 21895, 21895],
    56401: [19072, 19099, 16797, 18088, 22007, 22007],
    56601: [19190, 19216, 16920, 18191, 22119, 22119],
    56801: [19308, 19337, 17047, 18295, 22231, 22231],
    57001: [19426, 19454, 17170, 18398, 22343, 22343],
    57201: [19544, 19575, 17297, 18501, 22455, 22455],
    57401: [19662, 19692, 17420, 18604, 22567, 22567],
    57601: [19780, 19809, 17543, 18707, 22679, 22679],
    57801: [19898, 19930, 17670, 18810, 22791, 22791],
    58001: [20016, 20047, 17793, 18913, 22903, 22903],
    58201: [20134, 20163, 17915, 19016, 23015, 23015],
    58401: [20252, 20285, 18043, 19119, 23127, 23127],
    58601: [20370, 20401, 18165, 19222, 23239, 23239],
    58801: [20488, 20523, 18293, 19325, 23351, 23351],
    59001: [20606, 20639, 18415, 19428, 23463, 23463],
    59201: [20724, 20756, 18538, 19532, 23575, 23575],
    59401: [20842, 20877, 18665, 19635, 23687, 23687],
    59601: [20960, 20994, 18788, 19738, 23799, 23799],
    59801: [21078, 21111, 18911, 19841, 23911, 23911],
    60001: [21196, 21232, 19038, 19944, 24023, 24023],
    60201: [21314, 21349, 19161, 20047, 24135, 24135],
    60401: [21432, 21470, 19288, 20150, 24247, 24247],
    60601: [21550, 21587, 19411, 20253, 24359, 24359],
    60801: [21668, 21703, 19533, 20356, 24471, 24471],
    61001: [21786, 21825, 19661, 20459, 24583, 24583],
    61201: [21904, 21941, 19783, 20562, 24695, 24695],
    61401: [22022, 22058, 19906, 20665, 24807, 24807],
    61601: [22140, 22179, 20033, 20768, 24919, 24919],
    61801: [22258, 22296, 20156, 20872, 25031, 25031],
    62001: [22376, 22417, 20283, 20975, 25143, 25143],
    62201: [22494, 22534, 20406, 21078, 25255, 25255],
    62401: [22612, 22651, 20529, 21181, 25367, 25367],
    62601: [22730, 22772, 20656, 21284, 25479, 25479],
    62801: [22848, 22889, 20779, 21387, 25591, 25591],
    63001: [22966, 23005, 20901, 21490, 25703, 25703],
    63201: [23084, 23127, 21029, 21593, 25815, 25815],
    63401: [23202, 23243, 21151, 21696, 25927, 25927],
    63601: [23320, 23365, 21279, 21799, 26039, 26039],
    63801: [23438, 23481, 21401, 21902, 26151, 26151],
    64001: [23556, 23598, 21524, 22005, 26263, 26263],
    64201: [23674, 23719, 21651, 22109, 26375, 26375],
    64401: [23792, 23836, 21774, 22212, 26487, 26487],
    64601: [23910, 23953, 21897, 22315, 26599, 26599],
    64801: [24028, 24074, 22024, 22418, 26711, 26711],
    65001: [24146, 24191, 22147, 22521, 26823, 26823],
    65201: [24264, 24312, 22274, 22624, 26935, 26935],
    65401: [24382, 24429, 22397, 22727, 27047, 27047],
    65601: [24500, 24545, 22519, 22830, 27159, 27159],
    65801: [24618, 24667, 22647, 22933, 27271, 27271],
    66001: [24736, 24783, 22769, 23036, 27383, 27383],
    66201: [24854, 24900, 22892, 23139, 27495, 27495],
    66401: [24972, 25021, 23019, 23242, 27607, 27607],
    66601: [25090, 25138, 23142, 23345, 27719, 27719],
    66801: [25208, 25259, 23269, 23449, 27831, 27831],
    67001: [25326, 25376, 23392, 23552, 27943, 27943],
    67201: [25444, 25493, 23515, 23655, 28055, 28055],
    67401: [25562, 25614, 23642, 23758, 28167, 28167],
    67601: [25680, 25731, 23765, 23861, 28279, 28279],
    67801: [25798, 25852, 23892, 23964, 28391, 28391],
    68001: [25916, 25969, 24015, 24067, 28503, 28503],
    68201: [26034, 26085, 24137, 24170, 28615, 28615],
    68401: [26152, 26207, 24265, 24273, 28727, 28727],
    68601: [26270, 26323, 24387, 24376, 28839, 28839],
    68801: [26388, 26440, 24510, 24479, 28951, 28951],
    69001: [26506, 26561, 24637, 24582, 29063, 29063],
    69201: [26624, 26678, 24760, 24686, 29175, 29175],
    69401: [26742, 26799, 24887, 24789, 29287, 29287],
    69601: [26860, 26916, 25010, 24892, 29399, 29399],
    69801: [26978, 27033, 25133, 24995, 29511, 29511],
    70001: [27096, 27154, 25260, 25098, 29623, 29623],
    70201: [27214, 27271, 25383, 25201, 29735, 29735],
    70401: [27332, 27387, 25505, 25304, 29847, 29847],
    70601: [27450, 27509, 25633, 25407, 29959, 29959],
    70801: [27568, 27625, 25755, 25510, 30071, 30071],
    71001: [27686, 27747, 25883, 25613, 30183, 30183],
    71201: [27804, 27863, 26005, 25716, 30295, 30295],
    71401: [27922, 27980, 26128, 25820, 30407, 30407],
    71601: [28040, 28101, 26255, 25923, 30519, 30519],
    71801: [28158, 28218, 26378, 26026, 30631, 30631],
    72001: [28276, 28335, 26501, 26129, 30743, 30743],
    72201: [28394, 28456, 26628, 26232, 30855, 30855],
    72401: [28512, 28573, 26751, 26335, 30967, 30967],
    72601: [28630, 28694, 26878, 26438, 31079, 31079],
    72801: [28748, 28811, 27001, 26541, 31191, 31191],
    73001: [28866, 28927, 27123, 26644, 31303, 31303],
    73201: [28984, 29049, 27251, 26747, 31415, 31415],
    73401: [29102, 29165, 27373, 26850, 31527, 31527],
    73601: [29220, 29282, 27496, 26953, 31639, 31639],
    73801: [29338, 29403, 27623, 27056, 31751, 31751],
    74001: [29456, 29520, 27746, 27159, 31863, 31863],
    74201: [29574, 29641, 27873, 27263, 31975, 31975],
    74401: [29692, 29758, 27996, 27366, 32087, 32087],
    74601: [29810, 29875, 28119, 27469, 32199, 32199],
    74801: [29928, 29996, 28246, 27572, 32311, 32311],
    75001: [30046, 30113, 28369, 27675, 32423, 32423],
    75201: [30164, 30229, 28491, 27778, 32535, 32535],
    75401: [30282, 30351, 28619, 27881, 32647, 32647],
    75601: [30400, 30467, 28741, 27984, 32759, 32759],
    75801: [30518, 30589, 28869, 28087, 32871, 32871],
    76001: [30636, 30705, 28991, 28190, 32983, 32983],
    76201: [30754, 30822, 29114, 28293, 33095, 33095],
    76401: [30872, 30943, 29241, 28397, 33207, 33207],
    76601: [30990, 31060, 29364, 28500, 33319, 33319],
    76801: [31108, 31181, 29491, 28603, 33431, 33431],
    77001: [31226, 31298, 29614, 28706, 33543, 33543],
    77201: [31344, 31415, 29737, 28809, 33655, 33655],
    77401: [31462, 31536, 29864, 28912, 33767, 33767],
    77601: [31580, 31653, 29987, 29015, 33879, 33879],
    77801: [31698, 31769, 30109, 29118, 33991, 33991],
    78001: [31816, 31891, 30237, 29221, 34103, 34103],
    78201: [31934, 32007, 30359, 29324, 34215, 34215],
    78401: [32052, 32129, 30487, 29427, 34327, 34327],
    78601: [32170, 32245, 30609, 29530, 34439, 34439],
    78801: [32288, 32362, 30732, 29633, 34551, 34551],
    79001: [32406, 32483, 30859, 29737, 34663, 34663],
    79201: [32524, 32600, 30982, 29840, 34775, 34775],
    79401: [32642, 32717, 31105, 29943, 34887, 34887],
    79601: [32760, 32838, 31232, 30046, 34999, 34999],
    79801: [32878, 32955, 31355, 30149, 35111, 35111],
    80001: [41, 41, 39, 38, 44, 44, '%'],
    81001: [41, 41, 40, 38, 44, 44, '%'],
    81201: [41, 42, 40, 38, 44, 44, '%'],
    81801: [42, 42, 40, 38, 44, 44, '%'],
    84201: [42, 42, 40, 38, 45, 45, '%'],
    84801: [42, 42, 41, 39, 45, 45, '%'],
    86201: [42, 43, 41, 39, 45, 45, '%'],
    86601: [43, 43, 41, 39, 45, 45, '%'],
    88801: [43, 43, 42, 39, 45, 45, '%'],
    91601: [43, 44, 42, 39, 45, 45, '%'],
    92001: [43, 44, 42, 40, 45, 45, '%'],
    92201: [44, 44, 42, 40, 46, 46, '%'],
    93401: [44, 44, 43, 40, 46, 46, '%'],
    97801: [44, 45, 43, 40, 46, 46, '%'],
    98401: [44, 45, 44, 40, 46, 46, '%'],
    98601: [45, 45, 44, 40, 46, 46, '%'],
    100201: [45, 45, 44, 41, 46, 46, '%'],
    101801: [45, 45, 44, 41, 47, 47, '%'],
    103801: [45, 45, 45, 41, 47, 47, '%'],
    105001: [45, 46, 45, 41, 47, 47, '%'],
    106001: [46, 46, 45, 41, 47, 47, '%'],
    110001: [46, 46, 46, 41, 47, 47, '%'],
    110201: [46, 46, 46, 42, 47, 47, '%'],
    113201: [46, 47, 46, 42, 47, 47, '%'],
    113801: [46, 47, 46, 42, 48, 48, '%'],
    114401: [47, 47, 46, 42, 48, 48, '%'],
    117201: [47, 47, 47, 42, 48, 48, '%'],
    122401: [47, 47, 47, 43, 48, 48, '%'],
    123001: [47, 48, 47, 43, 48, 48, '%'],
    124401: [48, 48, 47, 43, 48, 48, '%'],
    125001: [48, 48, 48, 43, 48, 48, '%'],
    129001: [48, 48, 48, 43, 49, 49, '%'],
    134401: [48, 49, 49, 43, 49, 49, '%'],
    136201: [49, 49, 49, 43, 49, 49, '%'],
    137801: [49, 49, 49, 44, 49, 49, '%'],
    149001: [49, 50, 50, 44, 50, 50, '%'],
    150601: [50, 50, 50, 44, 50, 50, '%'],
    157401: [50, 50, 50, 45, 50, 50, '%'],
    176001: [51, 51, 51, 45, 51, 51, '%'],
    183401: [51, 51, 51, 46, 51, 51, '%'],
    215201: [52, 52, 52, 46, 52, 52, '%'],
    219801: [52, 52, 52, 47, 52, 52, '%'],
    274201: [52, 52, 52, 48, 52, 52, '%'],
    276801: [53, 53, 53, 48, 53, 53, '%'],
    364401: [53, 53, 53, 49, 53, 53, '%'],
    387401: [54, 54, 54, 49, 54, 54, '%'],
    543001: [54, 54, 54, 50, 54, 54, '%'],
    645801: [55, 55, 55, 50, 55, 55, '%'],
    1065001: [55, 55, 55, 51, 55, 55, '%'],
    1937601: [56, 56, 56, 51, 56, 56, '%']
  },
  37: {
    1: [0, 0, 0, 0, 0, 0],
    1701: [126, 0, 125, 1, 126, 34],
    1801: [137, 0, 133, 4, 137, 72],
    1901: [147, 0, 141, 6, 147, 110],
    2001: [158, 0, 150, 8, 158, 148],
    2101: [160, 0, 150, 10, 186, 186],
    2201: [171, 0, 158, 12, 224, 224],
    2301: [181, 0, 166, 14, 262, 262],
    2401: [192, 0, 175, 17, 300, 300],
    2501: [202, 0, 183, 19, 338, 338],
    2601: [213, 0, 191, 21, 376, 376],
    2701: [223, 0, 200, 23, 414, 414],
    2801: [225, 0, 200, 25, 452, 452],
    2901: [236, 0, 208, 27, 490, 490],
    3001: [246, 0, 216, 30, 528, 528],
    3101: [257, 0, 225, 32, 566, 566],
    3201: [267, 0, 233, 34, 604, 604],
    3301: [278, 0, 241, 36, 642, 642],
    3401: [280, 0, 241, 38, 680, 680],
    3501: [290, 0, 250, 40, 718, 718],
    3601: [301, 0, 258, 51, 756, 756],
    3701: [311, 0, 266, 77, 794, 794],
    3801: [322, 0, 275, 103, 832, 832],
    3901: [332, 0, 283, 129, 870, 870],
    4001: [334, 0, 283, 154, 899, 899],
    4101: [344, 0, 291, 179, 930, 930],
    4201: [354, 0, 300, 205, 962, 962],
    4301: [364, 0, 308, 230, 991, 991],
    4401: [374, 6, 317, 256, 1022, 1022],
    4501: [384, 34, 326, 281, 1051, 1051],
    4601: [386, 63, 328, 306, 1082, 1082],
    4701: [396, 91, 338, 332, 1114, 1114],
    4801: [406, 120, 348, 357, 1143, 1143],
    4901: [416, 148, 358, 382, 1174, 1174],
    5001: [426, 177, 368, 408, 1203, 1203],
    5101: [436, 205, 378, 433, 1234, 1234],
    5201: [447, 234, 388, 459, 1266, 1266],
    5301: [454, 259, 389, 483, 1294, 1294],
    5401: [477, 288, 399, 508, 1325, 1325],
    5501: [500, 316, 409, 533, 1353, 1353],
    5601: [523, 345, 419, 558, 1384, 1384],
    5701: [546, 373, 429, 582, 1415, 1415],
    5801: [569, 402, 439, 607, 1443, 1443],
    5901: [593, 430, 441, 632, 1474, 1474],
    6001: [616, 459, 451, 657, 1502, 1502],
    6101: [639, 487, 461, 681, 1533, 1533],
    6201: [662, 516, 471, 706, 1564, 1564],
    6301: [685, 544, 480, 731, 1592, 1592],
    6401: [708, 573, 490, 756, 1623, 1623],
    6501: [731, 598, 492, 780, 1651, 1651],
    6601: [754, 626, 502, 805, 1682, 1682],
    6701: [777, 655, 512, 830, 1713, 1713],
    6801: [800, 683, 522, 855, 1741, 1741],
    6901: [823, 712, 532, 879, 1772, 1772],
    7001: [846, 740, 542, 904, 1800, 1800],
    7101: [870, 769, 543, 929, 1831, 1831],
    7201: [893, 797, 553, 954, 1862, 1862],
    7301: [916, 826, 563, 979, 1890, 1890],
    7401: [939, 854, 573, 1003, 1921, 1921],
    7501: [962, 883, 583, 1028, 1949, 1949],
    7601: [985, 911, 593, 1053, 1980, 1980],
    7701: [1008, 937, 603, 1078, 2011, 2011],
    7801: [1031, 965, 604, 1102, 2039, 2039],
    7901: [1054, 984, 614, 1127, 2070, 2070],
    8001: [1077, 1006, 623, 1152, 2098, 2098],
    8101: [1100, 1029, 633, 1177, 2129, 2129],
    8201: [1123, 1054, 643, 1201, 2160, 2160],
    8301: [1147, 1076, 652, 1226, 2188, 2188],
    8401: [1170, 1098, 654, 1251, 2219, 2219],
    8501: [1193, 1120, 663, 1276, 2247, 2247],
    8601: [1216, 1146, 673, 1300, 2278, 2278],
    8701: [1239, 1168, 683, 1325, 2309, 2309],
    8801: [1262, 1190, 692, 1350, 2337, 2337],
    8901: [1285, 1212, 702, 1375, 2368, 2368],
    9001: [1308, 1238, 703, 1399, 2396, 2396],
    9101: [1331, 1260, 713, 1424, 2427, 2427],
    9201: [1354, 1282, 722, 1449, 2458, 2458],
    9301: [1377, 1307, 732, 1474, 2486, 2486],
    9401: [1400, 1328, 742, 1499, 2517, 2517],
    9501: [1423, 1350, 751, 1523, 2545, 2545],
    9601: [1447, 1372, 753, 1548, 2576, 2576],
    9701: [1470, 1397, 762, 1573, 2607, 2607],
    9801: [1493, 1418, 772, 1598, 2635, 2635],
    9901: [1516, 1440, 782, 1622, 2666, 2666],
    10001: [1539, 1462, 791, 1647, 2694, 2694],
    10101: [1562, 1487, 801, 1672, 2725, 2725],
    10201: [1585, 1508, 811, 1697, 2756, 2756],
    10301: [1608, 1530, 812, 1721, 2784, 2784],
    10401: [1631, 1552, 821, 1746, 2815, 2815],
    10501: [1654, 1577, 831, 1771, 2843, 2843],
    10601: [1677, 1598, 841, 1796, 2874, 2874],
    10701: [1701, 1620, 850, 1821, 2905, 2905],
    10801: [1724, 1642, 860, 1845, 2933, 2933],
    10901: [1747, 1667, 861, 1870, 2967, 2967],
    11001: [1770, 1688, 871, 1895, 3004, 3004],
    11101: [1794, 1710, 881, 1920, 3042, 3042],
    11201: [1817, 1735, 890, 1945, 3079, 3079],
    11301: [1840, 1757, 900, 1970, 3116, 3116],
    11401: [1864, 1778, 910, 1995, 3153, 3153],
    11501: [1887, 1800, 911, 2020, 3191, 3191],
    11601: [1910, 1825, 921, 2045, 3228, 3228],
    11701: [1934, 1847, 930, 2071, 3265, 3265],
    11801: [1957, 1868, 940, 2096, 3302, 3302],
    11901: [1980, 1890, 949, 2121, 3340, 3340],
    12001: [2004, 1915, 959, 2146, 3377, 3377],
    12101: [2027, 1937, 960, 2171, 3414, 3414],
    12201: [2051, 1958, 970, 2196, 3451, 3451],
    12301: [2074, 1980, 980, 2221, 3489, 3489],
    12401: [2097, 2005, 989, 2246, 3526, 3526],
    12501: [2121, 2027, 999, 2271, 3566, 3566],
    12601: [2144, 2048, 1009, 2296, 3607, 3607],
    12701: [2168, 2070, 1018, 2321, 3647, 3647],
    12801: [2191, 2095, 1020, 2347, 3687, 3687],
    12901: [2215, 2117, 1029, 2372, 3728, 3728],
    13001: [2245, 2145, 1039, 2402, 3771, 3771],
    13101: [2278, 2179, 1049, 2435, 3812, 3812],
    13201: [2310, 2210, 1060, 2468, 3852, 3852],
    13301: [2343, 2244, 1070, 2500, 3892, 3892],
    13401: [2376, 2275, 1072, 2533, 3933, 3933],
    13501: [2409, 2306, 1082, 2566, 3976, 3976],
    13601: [2442, 2340, 1092, 2599, 4016, 4016],
    13701: [2474, 2371, 1102, 2632, 4057, 4057],
    13801: [2507, 2405, 1113, 2664, 4097, 4097],
    13901: [2540, 2436, 1123, 2697, 4138, 4138],
    14001: [2572, 2467, 1124, 2729, 4180, 4180],
    14101: [2603, 2502, 1135, 2761, 4219, 4219],
    14201: [2635, 2533, 1145, 2792, 4259, 4259],
    14301: [2667, 2567, 1155, 2824, 4298, 4298],
    14401: [2699, 2598, 1165, 2856, 4337, 4337],
    14501: [2730, 2629, 1176, 2888, 4379, 4379],
    14601: [2762, 2663, 1178, 2919, 4419, 4419],
    14701: [2794, 2694, 1188, 2951, 4458, 4458],
    14801: [2825, 2728, 1198, 2983, 4497, 4497],
    14901: [2857, 2759, 1208, 3014, 4536, 4536],
    15001: [2889, 2790, 1218, 3046, 4579, 4579],
    15101: [2921, 2824, 1229, 3078, 4618, 4618],
    15201: [2952, 2855, 1239, 3110, 4657, 4657],
    15301: [2984, 2890, 1241, 3141, 4697, 4697],
    15401: [3016, 2921, 1251, 3173, 4736, 4736],
    15501: [3047, 2952, 1261, 3205, 4778, 4778],
    15601: [3079, 2986, 1271, 3236, 4817, 4817],
    15701: [3111, 3017, 1281, 3268, 4857, 4857],
    15801: [3142, 3051, 1292, 3300, 4896, 4896],
    15901: [3174, 3082, 1293, 3331, 4935, 4935],
    16001: [3206, 3113, 1304, 3363, 4977, 4977],
    16101: [3238, 3147, 1314, 3395, 5017, 5017],
    16201: [3269, 3178, 1324, 3427, 5056, 5056],
    16301: [3301, 3212, 1334, 3458, 5095, 5095],
    16401: [3333, 3244, 1344, 3490, 5135, 5135],
    16501: [3364, 3275, 1346, 3522, 5177, 5177],
    16601: [3396, 3309, 1357, 3553, 5216, 5216],
    16701: [3428, 3340, 1367, 3585, 5255, 5255],
    16801: [3459, 3374, 1377, 3617, 5295, 5295],
    16901: [3491, 3405, 1387, 3648, 5334, 5334],
    17001: [3523, 3436, 1397, 3680, 5376, 5376],
    17101: [3555, 3470, 1399, 3712, 5415, 5415],
    17201: [3586, 3501, 1409, 3744, 5455, 5455],
    17301: [3618, 3535, 1420, 3775, 5494, 5494],
    17401: [3650, 3566, 1441, 3807, 5533, 5533],
    17501: [3681, 3600, 1470, 3839, 5576, 5576],
    17601: [3713, 3632, 1497, 3870, 5615, 5615],
    17701: [3745, 3663, 1523, 3902, 5654, 5654],
    17801: [3776, 3697, 1552, 3934, 5693, 5693],
    17901: [3808, 3728, 1578, 3965, 5733, 5733],
    18001: [3840, 3762, 1607, 3997, 5775, 5775],
    18101: [3872, 3793, 1633, 4029, 5814, 5814],
    18201: [3903, 3824, 1659, 4061, 5854, 5854],
    18301: [3935, 3858, 1688, 4092, 5893, 5893],
    18401: [3967, 3889, 1714, 4124, 5932, 5932],
    18501: [3998, 3923, 1743, 4156, 5974, 5974],
    18601: [4030, 3954, 1769, 4187, 6014, 6014],
    18701: [4062, 3985, 1795, 4219, 6053, 6053],
    18801: [4094, 4020, 1825, 4251, 6092, 6092],
    18901: [4125, 4051, 1851, 4283, 6131, 6131],
    19001: [4157, 4085, 1880, 4314, 6174, 6174],
    19101: [4189, 4116, 1906, 4346, 6213, 6213],
    19201: [4220, 4147, 1932, 4378, 6252, 6252],
    19301: [4252, 4180, 1960, 4409, 6292, 6292],
    19401: [4284, 4210, 1985, 4441, 6331, 6331],
    19501: [4316, 4243, 2013, 4473, 6373, 6373],
    19601: [4347, 4274, 2039, 4504, 6412, 6412],
    19701: [4379, 4304, 2064, 4536, 6452, 6452],
    19801: [4411, 4337, 2092, 4568, 6491, 6491],
    19901: [4442, 4367, 2117, 4600, 6530, 6530],
    20001: [4506, 4431, 2171, 4663, 6612, 6612],
    20201: [4569, 4494, 2224, 4726, 6690, 6690],
    20401: [4633, 4558, 2278, 4790, 6772, 6772],
    20601: [4696, 4618, 2328, 4853, 6850, 6850],
    20801: [4759, 4681, 2381, 4917, 6929, 6929],
    21001: [4823, 4745, 2435, 4980, 7010, 7010],
    21201: [4886, 4808, 2488, 5043, 7089, 7089],
    21401: [4950, 4872, 2542, 5107, 7171, 7171],
    21601: [5013, 4932, 2592, 5170, 7249, 7249],
    21801: [5076, 4996, 2646, 5234, 7328, 7328],
    22001: [5140, 5059, 2699, 5297, 7410, 7410],
    22201: [5205, 5122, 2752, 5363, 7490, 7490],
    22401: [5270, 5189, 2809, 5428, 7573, 7573],
    22601: [5335, 5252, 2862, 5493, 7653, 7653],
    22801: [5400, 5316, 2916, 5558, 7733, 7733],
    23001: [5466, 5382, 2972, 5623, 7817, 7817],
    23201: [5531, 5446, 3026, 5688, 7897, 7897],
    23401: [5596, 5509, 3079, 5753, 7980, 7980],
    23601: [5661, 5576, 3136, 5818, 8060, 8060],
    23801: [5726, 5639, 3189, 5883, 8140, 8140],
    24001: [5791, 5702, 3242, 5948, 8224, 8224],
    24201: [5856, 5766, 3296, 6013, 8304, 8304],
    24401: [5921, 5832, 3352, 6078, 8387, 8387],
    24601: [5986, 5896, 3406, 6143, 8467, 8467],
    24801: [6051, 5959, 3459, 6208, 8547, 8547],
    25001: [6116, 6026, 3526, 6273, 8631, 8631],
    25201: [6181, 6089, 3589, 6338, 8711, 8711],
    25401: [6246, 6153, 3653, 6403, 8794, 8794],
    25601: [6311, 6219, 3719, 6468, 8874, 8874],
    25801: [6376, 6282, 3782, 6534, 8954, 8954],
    26001: [6441, 6346, 3846, 6599, 9038, 9038],
    26201: [6506, 6412, 3912, 6664, 9118, 9118],
    26401: [6572, 6476, 3976, 6729, 9201, 9201],
    26601: [6637, 6539, 4039, 6794, 9281, 9281],
    26801: [6702, 6603, 4103, 6859, 9361, 9361],
    27001: [6767, 6669, 4169, 6924, 9445, 9445],
    27201: [6832, 6733, 4233, 6989, 9525, 9525],
    27401: [6897, 6796, 4296, 7054, 9608, 9608],
    27601: [6962, 6862, 4362, 7119, 9688, 9688],
    27801: [7027, 6926, 4426, 7184, 9768, 9768],
    28001: [7092, 6989, 4489, 7249, 9852, 9852],
    28201: [7157, 7056, 4556, 7314, 9932, 9932],
    28401: [7222, 7119, 4619, 7379, 10015, 10015],
    28601: [7287, 7183, 4683, 7444, 10095, 10095],
    28801: [7352, 7249, 4749, 7509, 10175, 10175],
    29001: [7417, 7313, 4813, 7574, 10259, 10259],
    29201: [7482, 7376, 4876, 7639, 10339, 10339],
    29401: [7547, 7444, 4944, 7705, 10422, 10422],
    29601: [7612, 7508, 5008, 7770, 10502, 10502],
    29801: [7677, 7573, 5073, 7835, 10582, 10582],
    30001: [7743, 7638, 5138, 7900, 10666, 10666],
    30201: [7808, 7706, 5206, 7965, 10746, 10746],
    30401: [7873, 7771, 5271, 8030, 10829, 10829],
    30601: [7938, 7835, 5335, 8095, 10909, 10909],
    30801: [8003, 7903, 5403, 8160, 10989, 10989],
    31001: [8068, 7968, 5468, 8225, 11073, 11073],
    31201: [8133, 8033, 5533, 8290, 11153, 11153],
    31401: [8198, 8100, 5600, 8355, 11236, 11236],
    31601: [8263, 8162, 5662, 8420, 11313, 11313],
    31801: [8328, 8227, 5727, 8485, 11387, 11387],
    32001: [8393, 8292, 5792, 8550, 11461, 11461],
    32201: [8457, 8356, 5856, 8615, 11535, 11535],
    32401: [8525, 8424, 5924, 8679, 11609, 11609],
    32601: [8599, 8498, 5998, 8744, 11683, 11683],
    32801: [8673, 8572, 6072, 8809, 11757, 11757],
    33001: [8747, 8646, 6146, 8874, 11831, 11831],
    33201: [8821, 8720, 6220, 8939, 11905, 11905],
    33401: [8895, 8794, 6294, 9004, 11979, 11979],
    33601: [8969, 8868, 6368, 9068, 12053, 12053],
    33801: [9043, 8942, 6442, 9133, 12127, 12127],
    34001: [9117, 9016, 6516, 9198, 12201, 12201],
    34201: [9191, 9090, 6590, 9263, 12275, 12275],
    34401: [9265, 9164, 6664, 9328, 12349, 12349],
    34601: [9339, 9238, 6738, 9392, 12423, 12423],
    34801: [9413, 9312, 6812, 9457, 12497, 12497],
    35001: [9487, 9386, 6886, 9522, 12571, 12571],
    35201: [9561, 9460, 6960, 9587, 12645, 12645],
    35401: [9635, 9534, 7034, 9652, 12719, 12719],
    35601: [9709, 9608, 7108, 9717, 12793, 12793],
    35801: [9783, 9682, 7182, 9781, 12867, 12867],
    36001: [9857, 9756, 7256, 9846, 12941, 12941],
    36201: [9931, 9830, 7330, 9911, 13015, 13015],
    36401: [10005, 9904, 7404, 9976, 13089, 13089],
    36601: [10079, 9978, 7478, 10041, 13163, 13163],
    36801: [10153, 10052, 7552, 10106, 13237, 13237],
    37001: [10227, 10126, 7626, 10170, 13311, 13311],
    37201: [10301, 10200, 7700, 10235, 13385, 13385],
    37401: [10375, 10274, 7774, 10300, 13459, 13459],
    37601: [10449, 10348, 7848, 10365, 13533, 13533],
    37801: [10523, 10422, 7922, 10430, 13607, 13607],
    38001: [10597, 10496, 7996, 10495, 13681, 13681],
    38201: [10671, 10570, 8070, 10559, 13755, 13755],
    38401: [10745, 10644, 8144, 10624, 13829, 13829],
    38601: [10819, 10718, 8218, 10689, 13903, 13903],
    38801: [10893, 10792, 8292, 10754, 13977, 13977],
    39001: [10967, 10866, 8366, 10819, 14051, 14051],
    39201: [11041, 10940, 8440, 10883, 14125, 14125],
    39401: [11115, 11014, 8514, 10948, 14199, 14199],
    39601: [11189, 11088, 8588, 11013, 14273, 14273],
    39801: [11263, 11162, 8662, 11078, 14347, 14347],
    40001: [11337, 11236, 8736, 11143, 14421, 14421],
    40201: [11411, 11310, 8810, 11208, 14495, 14495],
    40401: [11485, 11384, 8884, 11272, 14569, 14569],
    40601: [11559, 11458, 8958, 11337, 14643, 14643],
    40801: [11633, 11532, 9032, 11402, 14717, 14717],
    41001: [11707, 11606, 9106, 11467, 14791, 14791],
    41201: [11781, 11680, 9180, 11532, 14865, 14865],
    41401: [11855, 11754, 9254, 11597, 14939, 14939],
    41601: [11929, 11828, 9328, 11661, 15013, 15013],
    41801: [12003, 11902, 9402, 11726, 15087, 15087],
    42001: [12077, 11976, 9476, 11791, 15161, 15161],
    42201: [12151, 12050, 9550, 11856, 15235, 15235],
    42401: [12225, 12124, 9624, 11921, 15309, 15309],
    42601: [12299, 12198, 9698, 11985, 15383, 15383],
    42801: [12373, 12272, 9772, 12050, 15457, 15457],
    43001: [12447, 12346, 9846, 12115, 15531, 15531],
    43201: [12521, 12420, 9920, 12180, 15605, 15605],
    43401: [12595, 12494, 9994, 12245, 15679, 15679],
    43601: [12669, 12568, 10068, 12310, 15753, 15753],
    43801: [12743, 12642, 10142, 12374, 15827, 15827],
    44001: [12817, 12716, 10216, 12439, 15901, 15901],
    44201: [12891, 12790, 10290, 12504, 15975, 15975],
    44401: [12965, 12864, 10364, 12569, 16049, 16049],
    44601: [13039, 12938, 10438, 12634, 16123, 16123],
    44801: [13113, 13012, 10512, 12699, 16197, 16197],
    45001: [13187, 13086, 10586, 12763, 16271, 16271],
    45201: [13261, 13160, 10660, 12828, 16345, 16345],
    45401: [13335, 13234, 10734, 12893, 16419, 16419],
    45601: [13409, 13308, 10808, 12958, 16493, 16493],
    45801: [13483, 13382, 10882, 13023, 16567, 16567],
    46001: [13557, 13456, 10956, 13088, 16641, 16641],
    46201: [13663, 13573, 11073, 13184, 16747, 16747],
    46401: [13777, 13694, 11194, 13289, 16861, 16861],
    46601: [13891, 13814, 11314, 13394, 16975, 16975],
    46801: [14005, 13934, 11434, 13498, 17089, 17089],
    47001: [14119, 14054, 11554, 13603, 17203, 17203],
    47201: [14233, 14171, 11671, 13708, 17317, 17317],
    47401: [14347, 14292, 11792, 13813, 17431, 17431],
    47601: [14461, 14412, 11912, 13918, 17545, 17545],
    47801: [14575, 14532, 12032, 14023, 17659, 17659],
    48001: [14689, 14652, 12152, 14127, 17773, 17773],
    48201: [14803, 14773, 12273, 14232, 17887, 17887],
    48401: [14917, 14893, 12393, 14337, 18001, 18001],
    48601: [15031, 15013, 12513, 14442, 18115, 18115],
    48801: [15145, 15130, 12630, 14547, 18229, 18229],
    49001: [15259, 15251, 12751, 14652, 18343, 18343],
    49201: [15373, 15371, 12871, 14756, 18457, 18457],
    49401: [15487, 15491, 12991, 14861, 18571, 18571],
    49601: [15601, 15611, 13111, 14966, 18685, 18685],
    49801: [15715, 15732, 13232, 15071, 18799, 18799],
    50001: [15829, 15852, 13358, 15176, 18913, 18913],
    50201: [15943, 15972, 13484, 15280, 19027, 19027],
    50401: [16057, 16089, 13607, 15385, 19141, 19141],
    50601: [16171, 16210, 13734, 15490, 19255, 19255],
    50801: [16285, 16330, 13860, 15595, 19369, 19369],
    51001: [16399, 16450, 13986, 15700, 19483, 19483],
    51201: [16513, 16570, 14112, 15805, 19597, 19597],
    51401: [16627, 16680, 14228, 15910, 19711, 19711],
    51601: [16741, 16794, 14348, 16014, 19825, 19825],
    51801: [16855, 16908, 14468, 16119, 19939, 19939],
    52001: [16969, 17022, 14588, 16224, 20053, 20053],
    52201: [17083, 17136, 14708, 16329, 20167, 20167],
    52401: [17197, 17250, 14828, 16434, 20281, 20281],
    52601: [17311, 17364, 14948, 16538, 20395, 20395],
    52801: [17425, 17478, 15068, 16643, 20509, 20509],
    53001: [17539, 17592, 15188, 16748, 20623, 20623],
    53201: [17653, 17706, 15308, 16853, 20737, 20737],
    53401: [17767, 17820, 15428, 16958, 20851, 20851],
    53601: [17881, 17934, 15548, 17063, 20965, 20965],
    53801: [17995, 18048, 15668, 17167, 21079, 21079],
    54001: [18109, 18162, 15788, 17272, 21193, 21193],
    54201: [18223, 18276, 15908, 17377, 21307, 21307],
    54401: [18340, 18395, 16033, 17482, 21421, 21421],
    54601: [18460, 18519, 16163, 17587, 21535, 21535],
    54801: [18580, 18637, 16287, 17691, 21649, 21649],
    55001: [18700, 18756, 16412, 17796, 21763, 21763],
    55201: [18820, 18880, 16542, 17901, 21877, 21877],
    55401: [18940, 18998, 16666, 18006, 21991, 21991],
    55601: [19060, 19117, 16791, 18111, 22105, 22105],
    55801: [19180, 19241, 16921, 18216, 22219, 22219],
    56001: [19300, 19359, 17045, 18320, 22333, 22333],
    56201: [19420, 19483, 17175, 18425, 22447, 22447],
    56401: [19540, 19602, 17300, 18530, 22561, 22561],
    56601: [19660, 19720, 17424, 18635, 22675, 22675],
    56801: [19780, 19844, 17554, 18740, 22789, 22789],
    57001: [19900, 19963, 17679, 18845, 22903, 22903],
    57201: [20020, 20086, 17808, 18949, 23017, 23017],
    57401: [20140, 20205, 17933, 19054, 23131, 23131],
    57601: [20260, 20324, 18058, 19159, 23245, 23245],
    57801: [20380, 20447, 18187, 19264, 23359, 23359],
    58001: [20500, 20566, 18312, 19369, 23473, 23473],
    58201: [20620, 20685, 18437, 19473, 23587, 23587],
    58401: [20740, 20808, 18566, 19578, 23701, 23701],
    58601: [20860, 20927, 18691, 19683, 23815, 23815],
    58801: [20980, 21050, 18820, 19788, 23929, 23929],
    59001: [21100, 21169, 18945, 19893, 24043, 24043],
    59201: [21220, 21288, 19070, 19998, 24157, 24157],
    59401: [21340, 21411, 19199, 20102, 24271, 24271],
    59601: [21460, 21530, 19324, 20207, 24385, 24385],
    59801: [21580, 21649, 19449, 20312, 24499, 24499],
    60001: [21700, 21772, 19578, 20417, 24613, 24613],
    60201: [21820, 21891, 19703, 20522, 24727, 24727],
    60401: [21940, 22015, 19833, 20627, 24841, 24841],
    60601: [22060, 22133, 19957, 20731, 24955, 24955],
    60801: [22180, 22252, 20082, 20836, 25069, 25069],
    61001: [22300, 22376, 20212, 20941, 25183, 25183],
    61201: [22420, 22494, 20336, 21046, 25297, 25297],
    61401: [22540, 22613, 20461, 21151, 25411, 25411],
    61601: [22660, 22737, 20591, 21256, 25525, 25525],
    61801: [22780, 22855, 20715, 21360, 25639, 25639],
    62001: [22900, 22979, 20845, 21465, 25753, 25753],
    62201: [23020, 23098, 20970, 21570, 25867, 25867],
    62401: [23140, 23216, 21094, 21675, 25981, 25981],
    62601: [23260, 23340, 21224, 21780, 26095, 26095],
    62801: [23380, 23459, 21349, 21884, 26209, 26209],
    63001: [23500, 23577, 21473, 21989, 26323, 26323],
    63201: [23620, 23701, 21603, 22094, 26437, 26437],
    63401: [23740, 23820, 21728, 22199, 26551, 26551],
    63601: [23860, 23943, 21857, 22304, 26665, 26665],
    63801: [23980, 24062, 21982, 22409, 26779, 26779],
    64001: [24100, 24181, 22107, 22513, 26893, 26893],
    64201: [24220, 24304, 22236, 22618, 27007, 27007],
    64401: [24340, 24423, 22361, 22723, 27121, 27121],
    64601: [24460, 24542, 22486, 22828, 27235, 27235],
    64801: [24580, 24665, 22615, 22933, 27349, 27349],
    65001: [24700, 24784, 22740, 23038, 27463, 27463],
    65201: [24820, 24907, 22869, 23142, 27577, 27577],
    65401: [24940, 25026, 22994, 23247, 27691, 27691],
    65601: [25060, 25145, 23119, 23352, 27805, 27805],
    65801: [25180, 25268, 23248, 23457, 27919, 27919],
    66001: [25300, 25387, 23373, 23562, 28033, 28033],
    66201: [25420, 25506, 23498, 23666, 28147, 28147],
    66401: [25540, 25629, 23627, 23771, 28261, 28261],
    66601: [25660, 25748, 23752, 23876, 28375, 28375],
    66801: [25780, 25872, 23882, 23981, 28489, 28489],
    67001: [25900, 25990, 24006, 24086, 28603, 28603],
    67201: [26020, 26109, 24131, 24191, 28717, 28717],
    67401: [26140, 26233, 24261, 24295, 28831, 28831],
    67601: [26260, 26351, 24385, 24400, 28945, 28945],
    67801: [26380, 26475, 24515, 24505, 29059, 29059],
    68001: [26500, 26594, 24640, 24610, 29173, 29173],
    68201: [26620, 26712, 24764, 24715, 29287, 29287],
    68401: [26740, 26836, 24894, 24820, 29401, 29401],
    68601: [26860, 26955, 25019, 24924, 29515, 29515],
    68801: [26980, 27073, 25143, 25029, 29629, 29629],
    69001: [27100, 27197, 25273, 25134, 29743, 29743],
    69201: [27220, 27316, 25398, 25239, 29857, 29857],
    69401: [27340, 27439, 25527, 25344, 29971, 29971],
    69601: [27460, 27558, 25652, 25449, 30085, 30085],
    69801: [27580, 27677, 25777, 25553, 30199, 30199],
    70001: [27700, 27800, 25906, 25658, 30313, 30313],
    70201: [27820, 27919, 26031, 25763, 30427, 30427],
    70401: [27940, 28038, 26156, 25868, 30541, 30541],
    70601: [28060, 28161, 26285, 25973, 30655, 30655],
    70801: [28180, 28280, 26410, 26077, 30769, 30769],
    71001: [28300, 28403, 26539, 26182, 30883, 30883],
    71201: [28420, 28522, 26664, 26287, 30997, 30997],
    71401: [28540, 28641, 26789, 26392, 31111, 31111],
    71601: [28660, 28764, 26918, 26497, 31225, 31225],
    71801: [28780, 28883, 27043, 26602, 31339, 31339],
    72001: [28900, 29002, 27168, 26706, 31453, 31453],
    72201: [29020, 29125, 27297, 26811, 31567, 31567],
    72401: [29140, 29244, 27422, 26916, 31681, 31681],
    72601: [29260, 29368, 27552, 27021, 31795, 31795],
    72801: [29380, 29486, 27676, 27126, 31909, 31909],
    73001: [29500, 29605, 27801, 27231, 32023, 32023],
    73201: [29620, 29729, 27931, 27335, 32137, 32137],
    73401: [29740, 29847, 28055, 27440, 32251, 32251],
    73601: [29860, 29966, 28180, 27545, 32365, 32365],
    73801: [29980, 30090, 28310, 27650, 32479, 32479],
    74001: [30100, 30208, 28434, 27755, 32593, 32593],
    74201: [30220, 30332, 28564, 27859, 32707, 32707],
    74401: [30340, 30451, 28689, 27964, 32821, 32821],
    74601: [30460, 30569, 28813, 28069, 32935, 32935],
    74801: [30580, 30693, 28943, 28174, 33049, 33049],
    75001: [30700, 30812, 29068, 28279, 33163, 33163],
    75201: [30820, 30930, 29192, 28384, 33277, 33277],
    75401: [30940, 31054, 29322, 28488, 33391, 33391],
    75601: [31060, 31173, 29447, 28593, 33505, 33505],
    75801: [31180, 31296, 29576, 28698, 33619, 33619],
    76001: [31300, 31415, 29701, 28803, 33733, 33733],
    76201: [31420, 31534, 29826, 28908, 33847, 33847],
    76401: [31540, 31657, 29955, 29013, 33961, 33961],
    76601: [31660, 31776, 30080, 29117, 34075, 34075],
    76801: [31780, 31899, 30209, 29222, 34189, 34189],
    77001: [31900, 32018, 30334, 29327, 34303, 34303],
    77201: [32020, 32137, 30459, 29432, 34417, 34417],
    77401: [32140, 32260, 30588, 29537, 34531, 34531],
    77601: [32260, 32379, 30713, 29642, 34645, 34645],
    77801: [32380, 32498, 30838, 29746, 34759, 34759],
    78001: [32500, 32621, 30967, 29851, 34873, 34873],
    78201: [32620, 32740, 31092, 29956, 34987, 34987],
    78401: [32740, 32864, 31222, 30061, 35101, 35101],
    78601: [32860, 32982, 31346, 30166, 35215, 35215],
    78801: [32980, 33101, 31471, 30270, 35329, 35329],
    79001: [33100, 33225, 31601, 30375, 35443, 35443],
    79201: [33220, 33343, 31725, 30480, 35557, 35557],
    79401: [33340, 33462, 31850, 30585, 35671, 35671],
    79601: [33460, 33586, 31980, 30690, 35785, 35785],
    79801: [33580, 33704, 32104, 30795, 35899, 35899],
    80001: [42, 42, 40, 38, 45, 45, '%'],
    80201: [42, 42, 40, 39, 45, 45, '%'],
    81201: [42, 42, 41, 39, 45, 45, '%'],
    81601: [42, 43, 41, 39, 45, 45, '%'],
    82201: [43, 43, 41, 39, 45, 45, '%'],
    84201: [43, 43, 41, 39, 46, 46, '%'],
    85001: [43, 43, 42, 39, 46, 46, '%'],
    86201: [43, 43, 42, 40, 46, 46, '%'],
    86401: [43, 44, 42, 40, 46, 46, '%'],
    87201: [44, 44, 42, 40, 46, 46, '%'],
    89001: [44, 44, 43, 40, 46, 46, '%'],
    92001: [44, 45, 43, 40, 46, 46, '%'],
    92201: [44, 45, 43, 40, 47, 47, '%'],
    93001: [45, 45, 43, 40, 47, 47, '%'],
    93401: [45, 45, 43, 41, 47, 47, '%'],
    93601: [45, 45, 44, 41, 47, 47, '%'],
    98201: [45, 46, 44, 41, 47, 47, '%'],
    98601: [45, 46, 45, 41, 47, 47, '%'],
    99401: [46, 46, 45, 41, 47, 47, '%'],
    102001: [46, 46, 45, 42, 48, 48, '%'],
    104001: [46, 46, 46, 42, 48, 48, '%'],
    105201: [46, 47, 46, 42, 48, 48, '%'],
    106801: [47, 47, 46, 42, 48, 48, '%'],
    110201: [47, 47, 47, 42, 48, 48, '%'],
    112201: [47, 47, 47, 43, 48, 48, '%'],
    113601: [47, 48, 47, 43, 48, 48, '%'],
    114001: [47, 48, 47, 43, 49, 49, '%'],
    115201: [48, 48, 47, 43, 49, 49, '%'],
    117201: [48, 48, 48, 43, 49, 49, '%'],
    123201: [48, 49, 48, 43, 49, 49, '%'],
    124801: [48, 49, 48, 44, 49, 49, '%'],
    125201: [49, 49, 49, 44, 49, 49, '%'],
    129201: [49, 49, 49, 44, 50, 50, '%'],
    134601: [49, 50, 50, 44, 50, 50, '%'],
    137201: [50, 50, 50, 44, 50, 50, '%'],
    140601: [50, 50, 50, 45, 50, 50, '%'],
    149201: [50, 51, 51, 45, 51, 51, '%'],
    151601: [51, 51, 51, 45, 51, 51, '%'],
    161001: [51, 51, 51, 46, 51, 51, '%'],
    176201: [52, 52, 52, 46, 52, 52, '%'],
    188201: [52, 52, 52, 47, 52, 52, '%'],
    215401: [53, 53, 53, 47, 53, 53, '%'],
    226601: [53, 53, 53, 48, 53, 53, '%'],
    277001: [54, 54, 54, 48, 54, 54, '%'],
    284401: [54, 54, 54, 49, 54, 54, '%'],
    382201: [54, 54, 54, 50, 54, 54, '%'],
    388001: [55, 55, 55, 50, 55, 55, '%'],
    582201: [55, 55, 55, 51, 55, 55, '%'],
    646601: [56, 56, 56, 51, 56, 56, '%'],
    1220201: [56, 56, 56, 52, 56, 56, '%'],
    1940001: [57, 57, 57, 52, 57, 57, '%']
  },
  38: {
    1: [0, 0, 0, 0, 0, 0],
    1701: [126, 0, 125, 1, 126, 35],
    1801: [137, 0, 133, 4, 137, 74],
    1901: [147, 0, 141, 6, 147, 113],
    2001: [158, 0, 150, 8, 158, 152],
    2101: [160, 0, 150, 10, 191, 191],
    2201: [171, 0, 158, 12, 230, 230],
    2301: [181, 0, 166, 14, 269, 269],
    2401: [192, 0, 175, 17, 308, 308],
    2501: [202, 0, 183, 19, 347, 347],
    2601: [213, 0, 191, 21, 386, 386],
    2701: [223, 0, 200, 23, 425, 425],
    2801: [225, 0, 200, 25, 464, 464],
    2901: [236, 0, 208, 27, 503, 503],
    3001: [246, 0, 216, 30, 542, 542],
    3101: [257, 0, 225, 32, 581, 581],
    3201: [267, 0, 233, 34, 620, 620],
    3301: [278, 0, 241, 36, 659, 659],
    3401: [280, 0, 241, 38, 698, 698],
    3501: [290, 0, 250, 40, 737, 737],
    3601: [301, 0, 258, 52, 776, 776],
    3701: [311, 0, 266, 78, 815, 815],
    3801: [322, 0, 275, 104, 854, 854],
    3901: [332, 0, 283, 131, 893, 893],
    4001: [334, 0, 283, 157, 922, 922],
    4101: [344, 0, 291, 183, 955, 955],
    4201: [354, 0, 300, 209, 987, 987],
    4301: [364, 0, 308, 235, 1017, 1017],
    4401: [374, 6, 317, 261, 1049, 1049],
    4501: [384, 35, 326, 287, 1078, 1078],
    4601: [386, 64, 328, 313, 1111, 1111],
    4701: [396, 94, 338, 339, 1143, 1143],
    4801: [406, 123, 348, 365, 1173, 1173],
    4901: [416, 152, 358, 391, 1205, 1205],
    5001: [426, 181, 368, 417, 1234, 1234],
    5101: [436, 211, 378, 443, 1267, 1267],
    5201: [447, 240, 388, 469, 1300, 1300],
    5301: [465, 266, 389, 495, 1328, 1328],
    5401: [488, 295, 399, 520, 1360, 1360],
    5501: [512, 325, 409, 545, 1389, 1389],
    5601: [536, 354, 419, 571, 1421, 1421],
    5701: [559, 383, 429, 596, 1452, 1452],
    5801: [583, 412, 439, 622, 1481, 1481],
    5901: [607, 441, 441, 647, 1513, 1513],
    6001: [630, 471, 451, 673, 1542, 1542],
    6101: [654, 500, 461, 698, 1574, 1574],
    6201: [678, 529, 471, 724, 1605, 1605],
    6301: [702, 558, 480, 749, 1634, 1634],
    6401: [725, 588, 490, 774, 1666, 1666],
    6501: [749, 614, 492, 800, 1695, 1695],
    6601: [773, 643, 502, 825, 1727, 1727],
    6701: [796, 672, 512, 851, 1758, 1758],
    6801: [820, 701, 522, 876, 1787, 1787],
    6901: [844, 731, 532, 901, 1819, 1819],
    7001: [867, 760, 542, 927, 1848, 1848],
    7101: [891, 789, 543, 952, 1880, 1880],
    7201: [915, 818, 553, 978, 1911, 1911],
    7301: [939, 848, 563, 1003, 1940, 1940],
    7401: [962, 877, 573, 1029, 1972, 1972],
    7501: [986, 906, 583, 1054, 2001, 2001],
    7601: [1010, 935, 593, 1079, 2033, 2033],
    7701: [1033, 961, 603, 1105, 2064, 2064],
    7801: [1057, 991, 604, 1130, 2093, 2093],
    7901: [1081, 1010, 614, 1156, 2125, 2125],
    8001: [1104, 1033, 623, 1181, 2154, 2154],
    8101: [1128, 1056, 633, 1207, 2186, 2186],
    8201: [1152, 1082, 643, 1232, 2217, 2217],
    8301: [1176, 1104, 652, 1257, 2246, 2246],
    8401: [1199, 1127, 654, 1283, 2278, 2278],
    8501: [1223, 1150, 663, 1308, 2307, 2307],
    8601: [1247, 1176, 673, 1334, 2339, 2339],
    8701: [1270, 1199, 683, 1359, 2370, 2370],
    8801: [1294, 1221, 692, 1385, 2399, 2399],
    8901: [1318, 1244, 702, 1410, 2431, 2431],
    9001: [1341, 1270, 703, 1435, 2460, 2460],
    9101: [1365, 1293, 713, 1461, 2492, 2492],
    9201: [1389, 1315, 722, 1486, 2523, 2523],
    9301: [1413, 1341, 732, 1512, 2552, 2552],
    9401: [1436, 1363, 742, 1537, 2584, 2584],
    9501: [1460, 1386, 751, 1562, 2613, 2613],
    9601: [1484, 1408, 753, 1588, 2645, 2645],
    9701: [1507, 1433, 762, 1613, 2676, 2676],
    9801: [1531, 1456, 772, 1639, 2705, 2705],
    9901: [1555, 1478, 782, 1664, 2737, 2737],
    10001: [1578, 1500, 791, 1690, 2766, 2766],
    10101: [1602, 1526, 801, 1715, 2798, 2798],
    10201: [1626, 1548, 811, 1740, 2829, 2829],
    10301: [1649, 1570, 812, 1766, 2858, 2858],
    10401: [1673, 1593, 821, 1791, 2890, 2890],
    10501: [1697, 1618, 831, 1817, 2919, 2919],
    10601: [1721, 1641, 841, 1842, 2951, 2951],
    10701: [1744, 1663, 850, 1868, 2982, 2982],
    10801: [1768, 1685, 860, 1893, 3011, 3011],
    10901: [1792, 1711, 861, 1919, 3046, 3046],
    11001: [1816, 1733, 871, 1944, 3084, 3084],
    11101: [1840, 1755, 881, 1970, 3123, 3123],
    11201: [1864, 1781, 890, 1996, 3161, 3161],
    11301: [1888, 1803, 900, 2021, 3199, 3199],
    11401: [1912, 1825, 910, 2047, 3237, 3237],
    11501: [1936, 1848, 911, 2073, 3276, 3276],
    11601: [1960, 1873, 921, 2098, 3314, 3314],
    11701: [1984, 1896, 930, 2124, 3352, 3352],
    11801: [2008, 1918, 940, 2150, 3390, 3390],
    11901: [2032, 1940, 949, 2176, 3429, 3429],
    12001: [2056, 1966, 959, 2201, 3467, 3467],
    12101: [2080, 1988, 960, 2227, 3505, 3505],
    12201: [2103, 2010, 970, 2253, 3543, 3543],
    12301: [2127, 2033, 980, 2278, 3582, 3582],
    12401: [2151, 2058, 989, 2304, 3620, 3620],
    12501: [2176, 2080, 999, 2330, 3661, 3661],
    12601: [2200, 2103, 1009, 2356, 3703, 3703],
    12701: [2224, 2125, 1018, 2382, 3744, 3744],
    12801: [2248, 2151, 1020, 2407, 3786, 3786],
    12901: [2272, 2173, 1029, 2433, 3827, 3827],
    13001: [2303, 2202, 1039, 2464, 3872, 3872],
    13101: [2336, 2237, 1049, 2498, 3913, 3913],
    13201: [2370, 2268, 1060, 2532, 3955, 3955],
    13301: [2404, 2304, 1070, 2565, 3996, 3996],
    13401: [2437, 2336, 1072, 2599, 4038, 4038],
    13501: [2471, 2367, 1082, 2633, 4082, 4082],
    13601: [2505, 2402, 1092, 2666, 4124, 4124],
    13701: [2538, 2434, 1102, 2700, 4165, 4165],
    13801: [2572, 2469, 1113, 2734, 4206, 4206],
    13901: [2606, 2501, 1123, 2767, 4248, 4248],
    14001: [2639, 2533, 1124, 2800, 4292, 4292],
    14101: [2671, 2568, 1135, 2833, 4332, 4332],
    14201: [2704, 2600, 1145, 2865, 4372, 4372],
    14301: [2736, 2635, 1155, 2898, 4413, 4413],
    14401: [2769, 2667, 1165, 2930, 4453, 4453],
    14501: [2801, 2699, 1176, 2963, 4496, 4496],
    14601: [2834, 2734, 1178, 2996, 4537, 4537],
    14701: [2867, 2766, 1188, 3028, 4577, 4577],
    14801: [2899, 2801, 1198, 3061, 4617, 4617],
    14901: [2932, 2833, 1208, 3093, 4658, 4658],
    15001: [2964, 2865, 1218, 3126, 4701, 4701],
    15101: [2997, 2900, 1229, 3158, 4742, 4742],
    15201: [3029, 2931, 1239, 3191, 4782, 4782],
    15301: [3062, 2967, 1241, 3224, 4822, 4822],
    15401: [3095, 2998, 1251, 3256, 4863, 4863],
    15501: [3127, 3030, 1261, 3289, 4906, 4906],
    15601: [3160, 3065, 1271, 3321, 4947, 4947],
    15701: [3192, 3097, 1281, 3354, 4987, 4987],
    15801: [3225, 3132, 1292, 3386, 5027, 5027],
    15901: [3257, 3164, 1293, 3419, 5068, 5068],
    16001: [3290, 3196, 1304, 3452, 5111, 5111],
    16101: [3323, 3231, 1314, 3484, 5151, 5151],
    16201: [3355, 3263, 1324, 3517, 5192, 5192],
    16301: [3388, 3298, 1334, 3549, 5232, 5232],
    16401: [3420, 3330, 1344, 3582, 5272, 5272],
    16501: [3453, 3362, 1346, 3615, 5316, 5316],
    16601: [3485, 3397, 1357, 3647, 5356, 5356],
    16701: [3518, 3429, 1367, 3680, 5397, 5397],
    16801: [3551, 3464, 1377, 3712, 5437, 5437],
    16901: [3583, 3496, 1396, 3745, 5477, 5477],
    17001: [3616, 3528, 1423, 3777, 5521, 5521],
    17101: [3648, 3563, 1453, 3810, 5561, 5561],
    17201: [3681, 3594, 1479, 3843, 5602, 5602],
    17301: [3713, 3630, 1510, 3875, 5642, 5642],
    17401: [3746, 3661, 1536, 3908, 5682, 5682],
    17501: [3779, 3696, 1566, 3940, 5726, 5726],
    17601: [3811, 3728, 1593, 3973, 5766, 5766],
    17701: [3844, 3760, 1620, 4005, 5806, 5806],
    17801: [3876, 3795, 1650, 4038, 5847, 5847],
    17901: [3909, 3827, 1677, 4071, 5887, 5887],
    18001: [3942, 3862, 1707, 4103, 5931, 5931],
    18101: [3974, 3894, 1734, 4136, 5971, 5971],
    18201: [4007, 3926, 1761, 4168, 6011, 6011],
    18301: [4039, 3961, 1791, 4201, 6052, 6052],
    18401: [4072, 3993, 1818, 4233, 6092, 6092],
    18501: [4104, 4028, 1848, 4266, 6135, 6135],
    18601: [4137, 4060, 1875, 4299, 6176, 6176],
    18701: [4170, 4092, 1902, 4331, 6216, 6216],
    18801: [4202, 4127, 1932, 4364, 6256, 6256],
    18901: [4235, 4159, 1959, 4396, 6297, 6297],
    19001: [4267, 4194, 1989, 4429, 6340, 6340],
    19101: [4300, 4226, 2016, 4461, 6381, 6381],
    19201: [4333, 4257, 2042, 4494, 6421, 6421],
    19301: [4365, 4292, 2072, 4527, 6461, 6461],
    19401: [4398, 4323, 2098, 4559, 6502, 6502],
    19501: [4430, 4357, 2127, 4592, 6545, 6545],
    19601: [4463, 4388, 2153, 4624, 6586, 6586],
    19701: [4495, 4419, 2179, 4657, 6626, 6626],
    19801: [4528, 4453, 2208, 4689, 6666, 6666],
    19901: [4560, 4484, 2234, 4722, 6707, 6707],
    20001: [4626, 4549, 2289, 4787, 6790, 6790],
    20201: [4691, 4614, 2344, 4852, 6871, 6871],
    20401: [4756, 4680, 2400, 4918, 6955, 6955],
    20601: [4821, 4742, 2452, 4983, 7036, 7036],
    20801: [4886, 4807, 2507, 5048, 7116, 7116],
    21001: [4951, 4872, 2562, 5113, 7200, 7200],
    21201: [5017, 4937, 2617, 5178, 7281, 7281],
    21401: [5082, 5002, 2672, 5243, 7365, 7365],
    21601: [5147, 5064, 2724, 5308, 7445, 7445],
    21801: [5212, 5130, 2780, 5374, 7526, 7526],
    22001: [5278, 5195, 2835, 5439, 7610, 7610],
    22201: [5344, 5260, 2890, 5506, 7693, 7693],
    22401: [5411, 5328, 2948, 5573, 7778, 7778],
    22601: [5478, 5394, 3004, 5640, 7861, 7861],
    22801: [5545, 5459, 3059, 5706, 7943, 7943],
    23001: [5612, 5527, 3117, 5773, 8028, 8028],
    23201: [5678, 5592, 3172, 5840, 8111, 8111],
    23401: [5745, 5657, 3227, 5907, 8196, 8196],
    23601: [5812, 5726, 3286, 5974, 8279, 8279],
    23801: [5879, 5791, 3341, 6040, 8361, 8361],
    24001: [5946, 5856, 3396, 6107, 8446, 8446],
    24201: [6012, 5921, 3451, 6174, 8529, 8529],
    24401: [6079, 5990, 3510, 6241, 8614, 8614],
    24601: [6146, 6055, 3565, 6308, 8697, 8697],
    24801: [6213, 6120, 3620, 6374, 8779, 8779],
    25001: [6280, 6188, 3688, 6441, 8864, 8864],
    25201: [6346, 6253, 3753, 6508, 8947, 8947],
    25401: [6413, 6319, 3819, 6575, 9032, 9032],
    25601: [6480, 6387, 3887, 6642, 9115, 9115],
    25801: [6547, 6452, 3952, 6708, 9197, 9197],
    26001: [6614, 6517, 4017, 6775, 9282, 9282],
    26201: [6680, 6586, 4086, 6842, 9365, 9365],
    26401: [6747, 6651, 4151, 6909, 9450, 9450],
    26601: [6814, 6716, 4216, 6976, 9533, 9533],
    26801: [6881, 6781, 4281, 7042, 9615, 9615],
    27001: [6948, 6849, 4349, 7109, 9700, 9700],
    27201: [7014, 6915, 4415, 7176, 9783, 9783],
    27401: [7081, 6980, 4480, 7243, 9868, 9868],
    27601: [7148, 7048, 4548, 7310, 9951, 9951],
    27801: [7215, 7113, 4613, 7376, 10033, 10033],
    28001: [7282, 7178, 4678, 7443, 10118, 10118],
    28201: [7348, 7247, 4747, 7510, 10201, 10201],
    28401: [7415, 7312, 4812, 7577, 10286, 10286],
    28601: [7482, 7377, 4877, 7644, 10369, 10369],
    28801: [7549, 7445, 4945, 7710, 10451, 10451],
    29001: [7616, 7511, 5011, 7777, 10536, 10536],
    29201: [7682, 7576, 5076, 7844, 10619, 10619],
    29401: [7749, 7645, 5145, 7911, 10704, 10704],
    29601: [7816, 7712, 5212, 7978, 10787, 10787],
    29801: [7883, 7778, 5278, 8044, 10869, 10869],
    30001: [7950, 7845, 5345, 8111, 10954, 10954],
    30201: [8016, 7914, 5414, 8178, 11037, 11037],
    30401: [8083, 7981, 5481, 8245, 11122, 11122],
    30601: [8150, 8047, 5547, 8312, 11205, 11205],
    30801: [8217, 8117, 5617, 8378, 11287, 11287],
    31001: [8284, 8184, 5684, 8445, 11372, 11372],
    31201: [8350, 8250, 5750, 8512, 11455, 11455],
    31401: [8417, 8320, 5820, 8579, 11540, 11540],
    31601: [8484, 8383, 5883, 8646, 11619, 11619],
    31801: [8550, 8450, 5950, 8712, 11695, 11695],
    32001: [8617, 8516, 6016, 8779, 11771, 11771],
    32201: [8684, 8583, 6083, 8845, 11847, 11847],
    32401: [8753, 8652, 6152, 8912, 11923, 11923],
    32601: [8829, 8728, 6228, 8978, 11999, 11999],
    32801: [8905, 8804, 6304, 9045, 12075, 12075],
    33001: [8981, 8880, 6380, 9112, 12151, 12151],
    33201: [9057, 8956, 6456, 9178, 12227, 12227],
    33401: [9133, 9032, 6532, 9245, 12303, 12303],
    33601: [9209, 9108, 6608, 9311, 12379, 12379],
    33801: [9285, 9184, 6684, 9378, 12455, 12455],
    34001: [9361, 9260, 6760, 9444, 12531, 12531],
    34201: [9437, 9336, 6836, 9511, 12607, 12607],
    34401: [9513, 9412, 6912, 9578, 12683, 12683],
    34601: [9589, 9488, 6988, 9644, 12759, 12759],
    34801: [9665, 9564, 7064, 9711, 12835, 12835],
    35001: [9741, 9640, 7140, 9777, 12911, 12911],
    35201: [9817, 9716, 7216, 9844, 12987, 12987],
    35401: [9893, 9792, 7292, 9910, 13063, 13063],
    35601: [9969, 9868, 7368, 9977, 13139, 13139],
    35801: [10045, 9944, 7444, 10043, 13215, 13215],
    36001: [10121, 10020, 7520, 10110, 13291, 13291],
    36201: [10197, 10096, 7596, 10177, 13367, 13367],
    36401: [10273, 10172, 7672, 10243, 13443, 13443],
    36601: [10349, 10248, 7748, 10310, 13519, 13519],
    36801: [10425, 10324, 7824, 10376, 13595, 13595],
    37001: [10501, 10400, 7900, 10443, 13671, 13671],
    37201: [10577, 10476, 7976, 10509, 13747, 13747],
    37401: [10653, 10552, 8052, 10576, 13823, 13823],
    37601: [10729, 10628, 8128, 10643, 13899, 13899],
    37801: [10805, 10704, 8204, 10709, 13975, 13975],
    38001: [10881, 10780, 8280, 10776, 14051, 14051],
    38201: [10957, 10856, 8356, 10842, 14127, 14127],
    38401: [11033, 10932, 8432, 10909, 14203, 14203],
    38601: [11109, 11008, 8508, 10975, 14279, 14279],
    38801: [11185, 11084, 8584, 11042, 14355, 14355],
    39001: [11261, 11160, 8660, 11109, 14431, 14431],
    39201: [11337, 11236, 8736, 11175, 14507, 14507],
    39401: [11413, 11312, 8812, 11242, 14583, 14583],
    39601: [11489, 11388, 8888, 11308, 14659, 14659],
    39801: [11565, 11464, 8964, 11375, 14735, 14735],
    40001: [11641, 11540, 9040, 11441, 14811, 14811],
    40201: [11717, 11616, 9116, 11508, 14887, 14887],
    40401: [11793, 11692, 9192, 11575, 14963, 14963],
    40601: [11869, 11768, 9268, 11641, 15039, 15039],
    40801: [11945, 11844, 9344, 11708, 15115, 15115],
    41001: [12021, 11920, 9420, 11774, 15191, 15191],
    41201: [12097, 11996, 9496, 11841, 15267, 15267],
    41401: [12173, 12072, 9572, 11908, 15343, 15343],
    41601: [12249, 12148, 9648, 11974, 15419, 15419],
    41801: [12325, 12224, 9724, 12041, 15495, 15495],
    42001: [12401, 12300, 9800, 12107, 15571, 15571],
    42201: [12477, 12376, 9876, 12174, 15647, 15647],
    42401: [12553, 12452, 9952, 12240, 15723, 15723],
    42601: [12629, 12528, 10028, 12307, 15799, 15799],
    42801: [12705, 12604, 10104, 12373, 15875, 15875],
    43001: [12781, 12680, 10180, 12440, 15951, 15951],
    43201: [12857, 12756, 10256, 12507, 16027, 16027],
    43401: [12933, 12832, 10332, 12573, 16103, 16103],
    43601: [13009, 12908, 10408, 12640, 16179, 16179],
    43801: [13085, 12984, 10484, 12706, 16255, 16255],
    44001: [13161, 13060, 10560, 12773, 16331, 16331],
    44201: [13237, 13136, 10636, 12839, 16407, 16407],
    44401: [13313, 13212, 10712, 12906, 16483, 16483],
    44601: [13389, 13288, 10788, 12973, 16559, 16559],
    44801: [13465, 13364, 10864, 13039, 16635, 16635],
    45001: [13541, 13440, 10940, 13106, 16711, 16711],
    45201: [13617, 13516, 11016, 13172, 16787, 16787],
    45401: [13693, 13592, 11092, 13239, 16863, 16863],
    45601: [13769, 13668, 11168, 13305, 16939, 16939],
    45801: [13845, 13744, 11244, 13372, 17015, 17015],
    46001: [13921, 13820, 11320, 13439, 17091, 17091],
    46201: [14029, 13941, 11441, 13537, 17199, 17199],
    46401: [14145, 14064, 11564, 13643, 17315, 17315],
    46601: [14261, 14188, 11688, 13750, 17431, 17431],
    46801: [14377, 14311, 11811, 13856, 17547, 17547],
    47001: [14493, 14435, 11935, 13963, 17663, 17663],
    47201: [14609, 14555, 12055, 14070, 17779, 17779],
    47401: [14725, 14678, 12178, 14176, 17895, 17895],
    47601: [14841, 14802, 12302, 14283, 18011, 18011],
    47801: [14957, 14925, 12425, 14389, 18127, 18127],
    48001: [15073, 15049, 12549, 14496, 18243, 18243],
    48201: [15189, 15172, 12672, 14602, 18359, 18359],
    48401: [15305, 15296, 12796, 14709, 18475, 18475],
    48601: [15421, 15419, 12919, 14816, 18591, 18591],
    48801: [15537, 15540, 13040, 14922, 18707, 18707],
    49001: [15653, 15663, 13163, 15029, 18823, 18823],
    49201: [15769, 15787, 13287, 15135, 18939, 18939],
    49401: [15885, 15910, 13410, 15242, 19055, 19055],
    49601: [16001, 16034, 13534, 15348, 19171, 19171],
    49801: [16117, 16157, 13657, 15455, 19287, 19287],
    50001: [16233, 16281, 13787, 15562, 19403, 19403],
    50201: [16349, 16404, 13916, 15668, 19519, 19519],
    50401: [16465, 16525, 14043, 15775, 19635, 19635],
    50601: [16581, 16648, 14172, 15881, 19751, 19751],
    50801: [16697, 16772, 14302, 15988, 19867, 19867],
    51001: [16813, 16895, 14431, 16094, 19983, 19983],
    51201: [16929, 17019, 14561, 16201, 20099, 20099],
    51401: [17045, 17131, 14679, 16308, 20215, 20215],
    51601: [17161, 17247, 14801, 16414, 20331, 20331],
    51801: [17277, 17363, 14923, 16521, 20447, 20447],
    52001: [17393, 17479, 15045, 16627, 20563, 20563],
    52201: [17509, 17595, 15167, 16734, 20679, 20679],
    52401: [17625, 17711, 15289, 16840, 20795, 20795],
    52601: [17741, 17827, 15411, 16947, 20911, 20911],
    52801: [17857, 17943, 15533, 17054, 21027, 21027],
    53001: [17973, 18059, 15655, 17160, 21143, 21143],
    53201: [18089, 18175, 15777, 17267, 21259, 21259],
    53401: [18205, 18291, 15899, 17373, 21375, 21375],
    53601: [18321, 18407, 16021, 17480, 21491, 21491],
    53801: [18437, 18523, 16143, 17586, 21607, 21607],
    54001: [18553, 18639, 16265, 17693, 21723, 21723],
    54201: [18669, 18755, 16387, 17800, 21839, 21839],
    54401: [18788, 18876, 16514, 17906, 21955, 21955],
    54601: [18910, 19002, 16646, 18013, 22071, 22071],
    54801: [19032, 19123, 16773, 18119, 22187, 22187],
    55001: [19154, 19244, 16900, 18226, 22303, 22303],
    55201: [19276, 19369, 17031, 18332, 22419, 22419],
    55401: [19398, 19490, 17158, 18439, 22535, 22535],
    55601: [19520, 19611, 17285, 18545, 22651, 22651],
    55801: [19642, 19737, 17417, 18652, 22767, 22767],
    56001: [19764, 19857, 17543, 18759, 22883, 22883],
    56201: [19886, 19983, 17675, 18865, 22999, 22999],
    56401: [20008, 20104, 17802, 18972, 23115, 23115],
    56601: [20130, 20225, 17929, 19078, 23231, 23231],
    56801: [20252, 20350, 18060, 19185, 23347, 23347],
    57001: [20374, 20471, 18187, 19291, 23463, 23463],
    57201: [20496, 20597, 18319, 19398, 23579, 23579],
    57401: [20618, 20718, 18446, 19505, 23695, 23695],
    57601: [20740, 20839, 18573, 19611, 23811, 23811],
    57801: [20862, 20964, 18704, 19718, 23927, 23927],
    58001: [20984, 21085, 18831, 19824, 24043, 24043],
    58201: [21106, 21206, 18958, 19931, 24159, 24159],
    58401: [21228, 21332, 19090, 20037, 24275, 24275],
    58601: [21350, 21452, 19216, 20144, 24391, 24391],
    58801: [21472, 21578, 19348, 20251, 24507, 24507],
    59001: [21594, 21699, 19475, 20357, 24623, 24623],
    59201: [21716, 21820, 19602, 20464, 24739, 24739],
    59401: [21838, 21945, 19733, 20570, 24855, 24855],
    59601: [21960, 22066, 19860, 20677, 24971, 24971],
    59801: [22082, 22187, 19987, 20783, 25087, 25087],
    60001: [22204, 22313, 20119, 20890, 25203, 25203],
    60201: [22326, 22434, 20246, 20997, 25319, 25319],
    60401: [22448, 22559, 20377, 21103, 25435, 25435],
    60601: [22570, 22680, 20504, 21210, 25551, 25551],
    60801: [22692, 22801, 20631, 21316, 25667, 25667],
    61001: [22814, 22927, 20763, 21423, 25783, 25783],
    61201: [22936, 23047, 20889, 21529, 25899, 25899],
    61401: [23058, 23168, 21016, 21636, 26015, 26015],
    61601: [23180, 23294, 21148, 21743, 26131, 26131],
    61801: [23302, 23415, 21275, 21849, 26247, 26247],
    62001: [23424, 23540, 21406, 21956, 26363, 26363],
    62201: [23546, 23661, 21533, 22062, 26479, 26479],
    62401: [23668, 23782, 21660, 22169, 26595, 26595],
    62601: [23790, 23908, 21792, 22275, 26711, 26711],
    62801: [23912, 24029, 21919, 22382, 26827, 26827],
    63001: [24034, 24149, 22045, 22489, 26943, 26943],
    63201: [24156, 24275, 22177, 22595, 27059, 27059],
    63401: [24278, 24396, 22304, 22702, 27175, 27175],
    63601: [24400, 24522, 22436, 22808, 27291, 27291],
    63801: [24522, 24642, 22562, 22915, 27407, 27407],
    64001: [24644, 24763, 22689, 23021, 27523, 27523],
    64201: [24766, 24889, 22821, 23128, 27639, 27639],
    64401: [24888, 25010, 22948, 23235, 27755, 27755],
    64601: [25010, 25131, 23075, 23341, 27871, 27871],
    64801: [25132, 25256, 23206, 23448, 27987, 27987],
    65001: [25254, 25377, 23333, 23554, 28103, 28103],
    65201: [25376, 25503, 23465, 23661, 28219, 28219],
    65401: [25498, 25624, 23592, 23767, 28335, 28335],
    65601: [25620, 25744, 23718, 23874, 28451, 28451],
    65801: [25742, 25870, 23850, 23981, 28567, 28567],
    66001: [25864, 25991, 23977, 24087, 28683, 28683],
    66201: [25986, 26112, 24104, 24194, 28799, 28799],
    66401: [26108, 26237, 24235, 24300, 28915, 28915],
    66601: [26230, 26358, 24362, 24407, 29031, 29031],
    66801: [26352, 26484, 24494, 24513, 29147, 29147],
    67001: [26474, 26605, 24621, 24620, 29263, 29263],
    67201: [26596, 26726, 24748, 24726, 29379, 29379],
    67401: [26718, 26851, 24879, 24833, 29495, 29495],
    67601: [26840, 26972, 25006, 24940, 29611, 29611],
    67801: [26962, 27098, 25138, 25046, 29727, 29727],
    68001: [27084, 27219, 25265, 25153, 29843, 29843],
    68201: [27206, 27339, 25391, 25259, 29959, 29959],
    68401: [27328, 27465, 25523, 25366, 30075, 30075],
    68601: [27450, 27586, 25650, 25472, 30191, 30191],
    68801: [27572, 27707, 25777, 25579, 30307, 30307],
    69001: [27694, 27832, 25908, 25686, 30423, 30423],
    69201: [27816, 27953, 26035, 25792, 30539, 30539],
    69401: [27938, 28079, 26167, 25899, 30655, 30655],
    69601: [28060, 28200, 26294, 26005, 30771, 30771],
    69801: [28182, 28321, 26421, 26112, 30887, 30887],
    70001: [28304, 28446, 26552, 26218, 31003, 31003],
    70201: [28426, 28567, 26679, 26325, 31119, 31119],
    70401: [28548, 28688, 26806, 26432, 31235, 31235],
    70601: [28670, 28814, 26938, 26538, 31351, 31351],
    70801: [28792, 28934, 27064, 26645, 31467, 31467],
    71001: [28914, 29060, 27196, 26751, 31583, 31583],
    71201: [29036, 29181, 27323, 26858, 31699, 31699],
    71401: [29158, 29302, 27450, 26965, 31815, 31815],
    71601: [29280, 29427, 27581, 27071, 31931, 31931],
    71801: [29402, 29548, 27708, 27178, 32047, 32047],
    72001: [29524, 29669, 27835, 27284, 32163, 32163],
    72201: [29646, 29795, 27967, 27391, 32279, 32279],
    72401: [29768, 29916, 28094, 27497, 32395, 32395],
    72601: [29890, 30041, 28225, 27604, 32511, 32511],
    72801: [30012, 30162, 28352, 27710, 32627, 32627],
    73001: [30134, 30283, 28479, 27817, 32743, 32743],
    73201: [30256, 30409, 28611, 27924, 32859, 32859],
    73401: [30378, 30529, 28737, 28030, 32975, 32975],
    73601: [30500, 30650, 28864, 28137, 33091, 33091],
    73801: [30622, 30776, 28996, 28243, 33207, 33207],
    74001: [30744, 30897, 29123, 28350, 33323, 33323],
    74201: [30866, 31022, 29254, 28456, 33439, 33439],
    74401: [30988, 31143, 29381, 28563, 33555, 33555],
    74601: [31110, 31264, 29508, 28670, 33671, 33671],
    74801: [31232, 31390, 29640, 28776, 33787, 33787],
    75001: [31354, 31511, 29767, 28883, 33903, 33903],
    75201: [31476, 31631, 29893, 28989, 34019, 34019],
    75401: [31598, 31757, 30025, 29096, 34135, 34135],
    75601: [31720, 31878, 30152, 29202, 34251, 34251],
    75801: [31842, 32004, 30284, 29309, 34367, 34367],
    76001: [31964, 32124, 30410, 29416, 34483, 34483],
    76201: [32086, 32245, 30537, 29522, 34599, 34599],
    76401: [32208, 32371, 30669, 29629, 34715, 34715],
    76601: [32330, 32492, 30796, 29735, 34831, 34831],
    76801: [32452, 32617, 30927, 29842, 34947, 34947],
    77001: [32574, 32738, 31054, 29948, 35063, 35063],
    77201: [32696, 32859, 31181, 30055, 35179, 35179],
    77401: [32818, 32985, 31313, 30162, 35295, 35295],
    77601: [32940, 33106, 31440, 30268, 35411, 35411],
    77801: [33062, 33226, 31566, 30375, 35527, 35527],
    78001: [33184, 33352, 31698, 30481, 35643, 35643],
    78201: [33306, 33473, 31825, 30588, 35759, 35759],
    78401: [33428, 33599, 31957, 30694, 35875, 35875],
    78601: [33550, 33719, 32083, 30801, 35991, 35991],
    78801: [33672, 33840, 32210, 30907, 36107, 36107],
    79001: [33794, 33966, 32342, 31014, 36223, 36223],
    79201: [33916, 34087, 32469, 31121, 36339, 36339],
    79401: [34038, 34208, 32596, 31227, 36455, 36455],
    79601: [34160, 34333, 32727, 31334, 36571, 36571],
    79801: [34282, 34454, 32854, 31440, 36687, 36687],
    80001: [43, 43, 41, 39, 46, 46, '%'],
    81001: [43, 43, 41, 40, 46, 46, '%'],
    81401: [43, 43, 42, 40, 46, 46, '%'],
    81801: [43, 44, 42, 40, 46, 46, '%'],
    82801: [44, 44, 42, 40, 46, 46, '%'],
    84401: [44, 44, 42, 40, 47, 47, '%'],
    85201: [44, 44, 43, 40, 47, 47, '%'],
    86801: [44, 45, 43, 40, 47, 47, '%'],
    87401: [44, 45, 43, 41, 47, 47, '%'],
    87801: [45, 45, 43, 41, 47, 47, '%'],
    89201: [45, 45, 44, 41, 47, 47, '%'],
    92201: [45, 46, 44, 41, 47, 47, '%'],
    92401: [45, 46, 44, 41, 48, 48, '%'],
    93601: [46, 46, 44, 41, 48, 48, '%'],
    93801: [46, 46, 45, 41, 48, 48, '%'],
    94801: [46, 46, 45, 42, 48, 48, '%'],
    98401: [46, 47, 45, 42, 48, 48, '%'],
    98801: [46, 47, 46, 42, 48, 48, '%'],
    100001: [47, 47, 46, 42, 48, 48, '%'],
    102201: [47, 47, 46, 42, 49, 49, '%'],
    103601: [47, 47, 46, 43, 49, 49, '%'],
    104201: [47, 47, 47, 43, 49, 49, '%'],
    105601: [47, 48, 47, 43, 49, 49, '%'],
    107401: [48, 48, 47, 43, 49, 49, '%'],
    110401: [48, 48, 48, 43, 49, 49, '%'],
    113801: [48, 49, 48, 43, 49, 49, '%'],
    114201: [48, 49, 48, 44, 50, 50, '%'],
    116001: [49, 49, 48, 44, 50, 50, '%'],
    117401: [49, 49, 49, 44, 50, 50, '%'],
    123401: [49, 50, 49, 44, 50, 50, '%'],
    125401: [49, 50, 50, 44, 50, 50, '%'],
    126201: [50, 50, 50, 44, 50, 50, '%'],
    127201: [50, 50, 50, 45, 50, 50, '%'],
    129401: [50, 50, 50, 45, 51, 51, '%'],
    134801: [50, 51, 51, 45, 51, 51, '%'],
    138201: [51, 51, 51, 45, 51, 51, '%'],
    143601: [51, 51, 51, 46, 51, 51, '%'],
    149401: [51, 52, 52, 46, 52, 52, '%'],
    152801: [52, 52, 52, 46, 52, 52, '%'],
    164801: [52, 52, 52, 47, 52, 52, '%'],
    176401: [53, 53, 53, 47, 53, 53, '%'],
    193201: [53, 53, 53, 48, 53, 53, '%'],
    215801: [54, 54, 54, 48, 54, 54, '%'],
    233801: [54, 54, 54, 49, 54, 54, '%'],
    277401: [55, 55, 55, 49, 55, 55, '%'],
    295401: [55, 55, 55, 50, 55, 55, '%'],
    388401: [56, 56, 56, 50, 56, 56, '%'],
    401601: [56, 56, 56, 51, 56, 56, '%'],
    626801: [56, 56, 56, 52, 56, 56, '%'],
    647401: [57, 57, 57, 52, 57, 57, '%'],
    1426001: [57, 57, 57, 53, 57, 57, '%'],
    1942401: [58, 58, 58, 53, 58, 58, '%']
  },
  39: {
    1: [0, 0, 0, 0, 0, 0],
    1701: [126, 0, 125, 1, 126, 36],
    1801: [137, 0, 133, 4, 137, 76],
    1901: [147, 0, 141, 6, 147, 116],
    2001: [158, 0, 150, 8, 158, 156],
    2101: [160, 0, 150, 10, 196, 196],
    2201: [171, 0, 158, 12, 236, 236],
    2301: [181, 0, 166, 14, 276, 276],
    2401: [192, 0, 175, 17, 316, 316],
    2501: [202, 0, 183, 19, 356, 356],
    2601: [213, 0, 191, 21, 396, 396],
    2701: [223, 0, 200, 23, 436, 436],
    2801: [225, 0, 200, 25, 476, 476],
    2901: [236, 0, 208, 27, 516, 516],
    3001: [246, 0, 216, 30, 556, 556],
    3101: [257, 0, 225, 32, 596, 596],
    3201: [267, 0, 233, 34, 636, 636],
    3301: [278, 0, 241, 36, 676, 676],
    3401: [280, 0, 241, 38, 716, 716],
    3501: [290, 0, 250, 40, 756, 756],
    3601: [301, 0, 258, 52, 796, 796],
    3701: [311, 0, 266, 79, 836, 836],
    3801: [322, 0, 275, 106, 876, 876],
    3901: [332, 0, 283, 133, 916, 916],
    4001: [334, 0, 283, 160, 946, 946],
    4101: [344, 0, 291, 186, 979, 979],
    4201: [354, 0, 300, 213, 1013, 1013],
    4301: [364, 0, 308, 240, 1043, 1043],
    4401: [374, 6, 317, 267, 1076, 1076],
    4501: [384, 36, 326, 293, 1106, 1106],
    4601: [386, 66, 328, 320, 1139, 1139],
    4701: [396, 96, 338, 347, 1173, 1173],
    4801: [406, 126, 348, 373, 1203, 1203],
    4901: [416, 156, 358, 400, 1236, 1236],
    5001: [426, 186, 368, 427, 1266, 1266],
    5101: [436, 216, 378, 453, 1299, 1299],
    5201: [451, 246, 388, 480, 1333, 1333],
    5301: [475, 273, 389, 506, 1362, 1362],
    5401: [500, 303, 399, 532, 1395, 1395],
    5501: [524, 333, 409, 558, 1424, 1424],
    5601: [548, 363, 419, 584, 1457, 1457],
    5701: [573, 393, 429, 611, 1490, 1490],
    5801: [597, 423, 439, 637, 1519, 1519],
    5901: [621, 453, 441, 663, 1552, 1552],
    6001: [645, 483, 451, 689, 1581, 1581],
    6101: [670, 513, 461, 715, 1614, 1614],
    6201: [694, 543, 471, 741, 1647, 1647],
    6301: [718, 573, 480, 767, 1676, 1676],
    6401: [743, 603, 490, 793, 1709, 1709],
    6501: [767, 629, 492, 819, 1738, 1738],
    6601: [791, 659, 502, 845, 1771, 1771],
    6701: [816, 689, 512, 871, 1804, 1804],
    6801: [840, 719, 522, 897, 1833, 1833],
    6901: [864, 750, 532, 924, 1866, 1866],
    7001: [888, 779, 542, 949, 1895, 1895],
    7101: [913, 809, 543, 976, 1928, 1928],
    7201: [937, 839, 553, 1002, 1961, 1961],
    7301: [962, 869, 563, 1028, 1990, 1990],
    7401: [986, 899, 573, 1054, 2023, 2023],
    7501: [1010, 929, 583, 1080, 2052, 2052],
    7601: [1034, 959, 593, 1106, 2085, 2085],
    7701: [1059, 986, 603, 1132, 2118, 2118],
    7801: [1083, 1016, 604, 1158, 2147, 2147],
    7901: [1107, 1036, 614, 1184, 2180, 2180],
    8001: [1132, 1059, 623, 1210, 2209, 2209],
    8101: [1156, 1083, 633, 1236, 2242, 2242],
    8201: [1180, 1109, 643, 1263, 2275, 2275],
    8301: [1205, 1133, 652, 1289, 2304, 2304],
    8401: [1229, 1156, 654, 1315, 2337, 2337],
    8501: [1253, 1179, 663, 1341, 2366, 2366],
    8601: [1278, 1206, 673, 1367, 2399, 2399],
    8701: [1302, 1229, 683, 1393, 2432, 2432],
    8801: [1326, 1253, 692, 1419, 2461, 2461],
    8901: [1350, 1276, 702, 1445, 2494, 2494],
    9001: [1375, 1303, 703, 1471, 2523, 2523],
    9101: [1399, 1326, 713, 1497, 2556, 2556],
    9201: [1423, 1349, 722, 1523, 2589, 2589],
    9301: [1448, 1375, 732, 1549, 2618, 2618],
    9401: [1472, 1398, 742, 1576, 2651, 2651],
    9501: [1496, 1421, 751, 1602, 2680, 2680],
    9601: [1521, 1444, 753, 1628, 2713, 2713],
    9701: [1545, 1470, 762, 1654, 2746, 2746],
    9801: [1569, 1493, 772, 1680, 2775, 2775],
    9901: [1594, 1516, 782, 1706, 2808, 2808],
    10001: [1618, 1539, 791, 1732, 2837, 2837],
    10101: [1642, 1565, 801, 1758, 2870, 2870],
    10201: [1666, 1588, 811, 1784, 2903, 2903],
    10301: [1691, 1611, 812, 1810, 2932, 2932],
    10401: [1715, 1634, 821, 1836, 2965, 2965],
    10501: [1739, 1660, 831, 1862, 2994, 2994],
    10601: [1764, 1683, 841, 1889, 3027, 3027],
    10701: [1788, 1706, 850, 1915, 3060, 3060],
    10801: [1812, 1729, 860, 1941, 3089, 3089],
    10901: [1837, 1755, 861, 1967, 3125, 3125],
    11001: [1861, 1778, 871, 1993, 3164, 3164],
    11101: [1886, 1801, 881, 2020, 3204, 3204],
    11201: [1910, 1827, 890, 2046, 3243, 3243],
    11301: [1935, 1850, 900, 2072, 3282, 3282],
    11401: [1960, 1873, 910, 2099, 3321, 3321],
    11501: [1984, 1895, 911, 2125, 3361, 3361],
    11601: [2009, 1922, 921, 2151, 3400, 3400],
    11701: [2033, 1944, 930, 2178, 3439, 3439],
    11801: [2058, 1967, 940, 2204, 3478, 3478],
    11901: [2083, 1990, 949, 2231, 3518, 3518],
    12001: [2107, 2016, 959, 2257, 3557, 3557],
    12101: [2132, 2039, 960, 2283, 3596, 3596],
    12201: [2156, 2062, 970, 2310, 3635, 3635],
    12301: [2181, 2085, 980, 2336, 3675, 3675],
    12401: [2206, 2111, 989, 2362, 3714, 3714],
    12501: [2230, 2134, 999, 2389, 3757, 3757],
    12601: [2255, 2157, 1009, 2415, 3799, 3799],
    12701: [2280, 2180, 1018, 2442, 3842, 3842],
    12801: [2304, 2206, 1020, 2468, 3884, 3884],
    12901: [2329, 2229, 1029, 2495, 3927, 3927],
    13001: [2361, 2258, 1039, 2527, 3972, 3972],
    13101: [2395, 2294, 1049, 2561, 4015, 4015],
    13201: [2430, 2327, 1060, 2596, 4057, 4057],
    13301: [2464, 2363, 1070, 2630, 4100, 4100],
    13401: [2499, 2396, 1072, 2665, 4142, 4142],
    13501: [2534, 2429, 1082, 2700, 4188, 4188],
    13601: [2568, 2465, 1092, 2734, 4231, 4231],
    13701: [2603, 2497, 1102, 2769, 4273, 4273],
    13801: [2637, 2533, 1113, 2803, 4316, 4316],
    13901: [2672, 2566, 1123, 2838, 4358, 4358],
    14001: [2705, 2599, 1124, 2871, 4403, 4403],
    14101: [2739, 2635, 1135, 2905, 4445, 4445],
    14201: [2772, 2667, 1145, 2938, 4486, 4486],
    14301: [2806, 2703, 1155, 2972, 4527, 4527],
    14401: [2839, 2736, 1165, 3005, 4569, 4569],
    14501: [2872, 2769, 1176, 3039, 4614, 4614],
    14601: [2906, 2805, 1178, 3072, 4655, 4655],
    14701: [2939, 2837, 1188, 3105, 4696, 4696],
    14801: [2973, 2873, 1198, 3139, 4738, 4738],
    14901: [3006, 2906, 1208, 3172, 4779, 4779],
    15001: [3040, 2939, 1218, 3206, 4824, 4824],
    15101: [3073, 2975, 1229, 3239, 4865, 4865],
    15201: [3107, 3007, 1239, 3273, 4907, 4907],
    15301: [3140, 3043, 1241, 3306, 4948, 4948],
    15401: [3173, 3076, 1251, 3339, 4990, 4990],
    15501: [3207, 3109, 1261, 3373, 5034, 5034],
    15601: [3240, 3145, 1271, 3406, 5076, 5076],
    15701: [3274, 3178, 1281, 3440, 5117, 5117],
    15801: [3307, 3214, 1292, 3473, 5159, 5159],
    15901: [3341, 3246, 1293, 3507, 5200, 5200],
    16001: [3374, 3279, 1304, 3540, 5245, 5245],
    16101: [3408, 3315, 1314, 3574, 5286, 5286],
    16201: [3441, 3348, 1324, 3607, 5328, 5328],
    16301: [3475, 3384, 1334, 3641, 5369, 5369],
    16401: [3508, 3416, 1344, 3674, 5410, 5410],
    16501: [3541, 3449, 1369, 3707, 5455, 5455],
    16601: [3575, 3485, 1400, 3741, 5496, 5496],
    16701: [3608, 3518, 1428, 3774, 5538, 5538],
    16801: [3642, 3554, 1459, 3808, 5579, 5579],
    16901: [3675, 3586, 1486, 3841, 5621, 5621],
    17001: [3709, 3619, 1514, 3875, 5665, 5665],
    17101: [3742, 3655, 1545, 3908, 5707, 5707],
    17201: [3776, 3688, 1573, 3942, 5748, 5748],
    17301: [3809, 3724, 1604, 3975, 5790, 5790],
    17401: [3842, 3757, 1632, 4008, 5831, 5831],
    17501: [3876, 3792, 1662, 4042, 5876, 5876],
    17601: [3909, 3825, 1690, 4075, 5917, 5917],
    17701: [3943, 3858, 1718, 4109, 5959, 5959],
    17801: [3976, 3894, 1749, 4142, 6000, 6000],
    17901: [4010, 3927, 1777, 4176, 6042, 6042],
    18001: [4043, 3963, 1808, 4209, 6086, 6086],
    18101: [4077, 3995, 1835, 4243, 6128, 6128],
    18201: [4110, 4028, 1863, 4276, 6169, 6169],
    18301: [4143, 4064, 1894, 4309, 6210, 6210],
    18401: [4177, 4097, 1922, 4343, 6252, 6252],
    18501: [4210, 4133, 1953, 4376, 6297, 6297],
    18601: [4244, 4165, 1980, 4410, 6338, 6338],
    18701: [4277, 4198, 2008, 4443, 6379, 6379],
    18801: [4311, 4234, 2039, 4477, 6421, 6421],
    18901: [4344, 4267, 2067, 4510, 6462, 6462],
    19001: [4378, 4303, 2098, 4544, 6507, 6507],
    19101: [4411, 4335, 2125, 4577, 6548, 6548],
    19201: [4445, 4368, 2153, 4611, 6590, 6590],
    19301: [4478, 4403, 2183, 4644, 6631, 6631],
    19401: [4511, 4435, 2210, 4677, 6673, 6673],
    19501: [4545, 4470, 2240, 4711, 6717, 6717],
    19601: [4578, 4502, 2267, 4744, 6759, 6759],
    19701: [4612, 4534, 2294, 4778, 6800, 6800],
    19801: [4645, 4569, 2324, 4811, 6842, 6842],
    19901: [4679, 4601, 2351, 4845, 6883, 6883],
    20001: [4745, 4668, 2408, 4911, 6969, 6969],
    20201: [4812, 4735, 2465, 4978, 7052, 7052],
    20401: [4879, 4802, 2522, 5045, 7138, 7138],
    20601: [4946, 4865, 2575, 5112, 7221, 7221],
    20801: [5013, 4932, 2632, 5179, 7304, 7304],
    21001: [5080, 4999, 2689, 5246, 7390, 7390],
    21201: [5147, 5066, 2746, 5313, 7473, 7473],
    21401: [5214, 5133, 2803, 5380, 7559, 7559],
    21601: [5281, 5197, 2857, 5447, 7642, 7642],
    21801: [5348, 5264, 2914, 5514, 7725, 7725],
    22001: [5415, 5331, 2971, 5581, 7811, 7811],
    22201: [5483, 5398, 3028, 5649, 7896, 7896],
    22401: [5552, 5468, 3088, 5718, 7983, 7983],
    22601: [5621, 5535, 3145, 5787, 8068, 8068],
    22801: [5689, 5602, 3202, 5855, 8152, 8152],
    23001: [5758, 5672, 3262, 5924, 8240, 8240],
    23201: [5826, 5739, 3319, 5992, 8325, 8325],
    23401: [5895, 5806, 3376, 6061, 8412, 8412],
    23601: [5963, 5876, 3436, 6129, 8497, 8497],
    23801: [6032, 5943, 3493, 6198, 8581, 8581],
    24001: [6100, 6010, 3550, 6266, 8669, 8669],
    24201: [6169, 6077, 3607, 6335, 8754, 8754],
    24401: [6238, 6147, 3667, 6404, 8841, 8841],
    24601: [6306, 6214, 3724, 6472, 8926, 8926],
    24801: [6375, 6281, 3781, 6541, 9010, 9010],
    25001: [6443, 6351, 3851, 6609, 9098, 9098],
    25201: [6512, 6418, 3918, 6678, 9183, 9183],
    25401: [6580, 6485, 3985, 6746, 9270, 9270],
    25601: [6649, 6555, 4055, 6815, 9355, 9355],
    25801: [6717, 6622, 4122, 6883, 9439, 9439],
    26001: [6786, 6689, 4189, 6952, 9527, 9527],
    26201: [6854, 6759, 4259, 7020, 9612, 9612],
    26401: [6923, 6826, 4326, 7089, 9699, 9699],
    26601: [6991, 6893, 4393, 7157, 9784, 9784],
    26801: [7060, 6960, 4460, 7226, 9868, 9868],
    27001: [7129, 7030, 4530, 7295, 9956, 9956],
    27201: [7197, 7097, 4597, 7363, 10041, 10041],
    27401: [7266, 7164, 4664, 7432, 10128, 10128],
    27601: [7334, 7234, 4734, 7500, 10213, 10213],
    27801: [7403, 7301, 4801, 7569, 10297, 10297],
    28001: [7471, 7368, 4868, 7637, 10385, 10385],
    28201: [7540, 7438, 4938, 7706, 10470, 10470],
    28401: [7608, 7505, 5005, 7774, 10557, 10557],
    28601: [7677, 7572, 5072, 7843, 10642, 10642],
    28801: [7745, 7642, 5142, 7911, 10726, 10726],
    29001: [7814, 7709, 5209, 7980, 10814, 10814],
    29201: [7883, 7776, 5276, 8049, 10899, 10899],
    29401: [7951, 7847, 5347, 8117, 10986, 10986],
    29601: [8020, 7915, 5415, 8186, 11071, 11071],
    29801: [8088, 7983, 5483, 8254, 11155, 11155],
    30001: [8157, 8052, 5552, 8323, 11243, 11243],
    30201: [8225, 8123, 5623, 8391, 11328, 11328],
    30401: [8294, 8191, 5691, 8460, 11415, 11415],
    30601: [8362, 8260, 5760, 8528, 11500, 11500],
    30801: [8431, 8331, 5831, 8597, 11584, 11584],
    31001: [8499, 8399, 5899, 8665, 11672, 11672],
    31201: [8568, 8468, 5968, 8734, 11757, 11757],
    31401: [8637, 8539, 6039, 8803, 11844, 11844],
    31601: [8705, 8604, 6104, 8871, 11926, 11926],
    31801: [8773, 8672, 6172, 8939, 12004, 12004],
    32001: [8842, 8741, 6241, 9008, 12082, 12082],
    32201: [8910, 8809, 6309, 9076, 12160, 12160],
    32401: [8981, 8880, 6380, 9144, 12238, 12238],
    32601: [9059, 8958, 6458, 9213, 12316, 12316],
    32801: [9137, 9036, 6536, 9281, 12394, 12394],
    33001: [9215, 9114, 6614, 9349, 12472, 12472],
    33201: [9293, 9192, 6692, 9417, 12550, 12550],
    33401: [9371, 9270, 6770, 9486, 12628, 12628],
    33601: [9449, 9348, 6848, 9554, 12706, 12706],
    33801: [9527, 9426, 6926, 9622, 12784, 12784],
    34001: [9605, 9504, 7004, 9691, 12862, 12862],
    34201: [9683, 9582, 7082, 9759, 12940, 12940],
    34401: [9761, 9660, 7160, 9827, 13018, 13018],
    34601: [9839, 9738, 7238, 9896, 13096, 13096],
    34801: [9917, 9816, 7316, 9964, 13174, 13174],
    35001: [9995, 9894, 7394, 10032, 13252, 13252],
    35201: [10073, 9972, 7472, 10101, 13330, 13330],
    35401: [10151, 10050, 7550, 10169, 13408, 13408],
    35601: [10229, 10128, 7628, 10237, 13486, 13486],
    35801: [10307, 10206, 7706, 10306, 13564, 13564],
    36001: [10385, 10284, 7784, 10374, 13642, 13642],
    36201: [10463, 10362, 7862, 10442, 13720, 13720],
    36401: [10541, 10440, 7940, 10511, 13798, 13798],
    36601: [10619, 10518, 8018, 10579, 13876, 13876],
    36801: [10697, 10596, 8096, 10647, 13954, 13954],
    37001: [10775, 10674, 8174, 10715, 14032, 14032],
    37201: [10853, 10752, 8252, 10784, 14110, 14110],
    37401: [10931, 10830, 8330, 10852, 14188, 14188],
    37601: [11009, 10908, 8408, 10920, 14266, 14266],
    37801: [11087, 10986, 8486, 10989, 14344, 14344],
    38001: [11165, 11064, 8564, 11057, 14422, 14422],
    38201: [11243, 11142, 8642, 11125, 14500, 14500],
    38401: [11321, 11220, 8720, 11194, 14578, 14578],
    38601: [11399, 11298, 8798, 11262, 14656, 14656],
    38801: [11477, 11376, 8876, 11330, 14734, 14734],
    39001: [11555, 11454, 8954, 11399, 14812, 14812],
    39201: [11633, 11532, 9032, 11467, 14890, 14890],
    39401: [11711, 11610, 9110, 11535, 14968, 14968],
    39601: [11789, 11688, 9188, 11604, 15046, 15046],
    39801: [11867, 11766, 9266, 11672, 15124, 15124],
    40001: [11945, 11844, 9344, 11740, 15202, 15202],
    40201: [12023, 11922, 9422, 11808, 15280, 15280],
    40401: [12101, 12000, 9500, 11877, 15358, 15358],
    40601: [12179, 12078, 9578, 11945, 15436, 15436],
    40801: [12257, 12156, 9656, 12013, 15514, 15514],
    41001: [12335, 12234, 9734, 12082, 15592, 15592],
    41201: [12413, 12312, 9812, 12150, 15670, 15670],
    41401: [12491, 12390, 9890, 12218, 15748, 15748],
    41601: [12569, 12468, 9968, 12287, 15826, 15826],
    41801: [12647, 12546, 10046, 12355, 15904, 15904],
    42001: [12725, 12624, 10124, 12423, 15982, 15982],
    42201: [12803, 12702, 10202, 12492, 16060, 16060],
    42401: [12881, 12780, 10280, 12560, 16138, 16138],
    42601: [12959, 12858, 10358, 12628, 16216, 16216],
    42801: [13037, 12936, 10436, 12697, 16294, 16294],
    43001: [13115, 13014, 10514, 12765, 16372, 16372],
    43201: [13193, 13092, 10592, 12833, 16450, 16450],
    43401: [13271, 13170, 10670, 12901, 16528, 16528],
    43601: [13349, 13248, 10748, 12970, 16606, 16606],
    43801: [13427, 13326, 10826, 13038, 16684, 16684],
    44001: [13505, 13404, 10904, 13106, 16762, 16762],
    44201: [13583, 13482, 10982, 13175, 16840, 16840],
    44401: [13661, 13560, 11060, 13243, 16918, 16918],
    44601: [13739, 13638, 11138, 13311, 16996, 16996],
    44801: [13817, 13716, 11216, 13380, 17074, 17074],
    45001: [13895, 13794, 11294, 13448, 17152, 17152],
    45201: [13973, 13872, 11372, 13516, 17230, 17230],
    45401: [14051, 13950, 11450, 13585, 17308, 17308],
    45601: [14129, 14028, 11528, 13653, 17386, 17386],
    45801: [14207, 14106, 11606, 13721, 17464, 17464],
    46001: [14285, 14184, 11684, 13790, 17542, 17542],
    46201: [14395, 14308, 11808, 13890, 17651, 17651],
    46401: [14513, 14435, 11935, 13998, 17769, 17769],
    46601: [14631, 14561, 12061, 14106, 17887, 17887],
    46801: [14749, 14688, 12188, 14214, 18005, 18005],
    47001: [14867, 14815, 12315, 14323, 18123, 18123],
    47201: [14985, 14938, 12438, 14431, 18241, 18241],
    47401: [15103, 15065, 12565, 14539, 18359, 18359],
    47601: [15221, 15192, 12692, 14648, 18477, 18477],
    47801: [15339, 15319, 12819, 14756, 18595, 18595],
    48001: [15457, 15445, 12945, 14864, 18713, 18713],
    48201: [15575, 15572, 13072, 14973, 18831, 18831],
    48401: [15693, 15699, 13199, 15081, 18949, 18949],
    48601: [15811, 15826, 13326, 15189, 19067, 19067],
    48801: [15929, 15949, 13449, 15298, 19185, 19185],
    49001: [16047, 16076, 13576, 15406, 19303, 19303],
    49201: [16165, 16203, 13703, 15514, 19421, 19421],
    49401: [16283, 16329, 13829, 15623, 19539, 19539],
    49601: [16401, 16456, 13956, 15731, 19657, 19657],
    49801: [16519, 16583, 14083, 15839, 19775, 19775],
    50001: [16637, 16710, 14216, 15947, 19893, 19893],
    50201: [16755, 16836, 14348, 16056, 20011, 20011],
    50401: [16873, 16960, 14478, 16164, 20129, 20129],
    50601: [16991, 17087, 14611, 16272, 20247, 20247],
    50801: [17109, 17213, 14743, 16381, 20365, 20365],
    51001: [17227, 17340, 14876, 16489, 20483, 20483],
    51201: [17345, 17467, 15009, 16597, 20601, 20601],
    51401: [17463, 17582, 15130, 16706, 20719, 20719],
    51601: [17581, 17700, 15254, 16814, 20837, 20837],
    51801: [17699, 17818, 15378, 16922, 20955, 20955],
    52001: [17817, 17936, 15502, 17031, 21073, 21073],
    52201: [17935, 18054, 15626, 17139, 21191, 21191],
    52401: [18053, 18172, 15750, 17247, 21309, 21309],
    52601: [18171, 18290, 15874, 17356, 21427, 21427],
    52801: [18289, 18408, 15998, 17464, 21545, 21545],
    53001: [18407, 18526, 16122, 17572, 21663, 21663],
    53201: [18525, 18644, 16246, 17680, 21781, 21781],
    53401: [18643, 18762, 16370, 17789, 21899, 21899],
    53601: [18761, 18880, 16494, 17897, 22017, 22017],
    53801: [18879, 18998, 16618, 18005, 22135, 22135],
    54001: [18997, 19116, 16742, 18114, 22253, 22253],
    54201: [19115, 19234, 16866, 18222, 22371, 22371],
    54401: [19236, 19357, 16995, 18330, 22489, 22489],
    54601: [19360, 19485, 17129, 18439, 22607, 22607],
    54801: [19484, 19608, 17258, 18547, 22725, 22725],
    55001: [19608, 19731, 17387, 18655, 22843, 22843],
    55201: [19732, 19859, 17521, 18764, 22961, 22961],
    55401: [19856, 19982, 17650, 18872, 23079, 23079],
    55601: [19980, 20105, 17779, 18980, 23197, 23197],
    55801: [20104, 20232, 17912, 19089, 23315, 23315],
    56001: [20228, 20355, 18041, 19197, 23433, 23433],
    56201: [20352, 20483, 18175, 19305, 23551, 23551],
    56401: [20476, 20606, 18304, 19414, 23669, 23669],
    56601: [20600, 20729, 18433, 19522, 23787, 23787],
    56801: [20724, 20857, 18567, 19630, 23905, 23905],
    57001: [20848, 20980, 18696, 19738, 24023, 24023],
    57201: [20972, 21108, 18830, 19847, 24141, 24141],
    57401: [21096, 21231, 18959, 19955, 24259, 24259],
    57601: [21220, 21353, 19087, 20063, 24377, 24377],
    57801: [21344, 21481, 19221, 20172, 24495, 24495],
    58001: [21468, 21604, 19350, 20280, 24613, 24613],
    58201: [21592, 21727, 19479, 20388, 24731, 24731],
    58401: [21716, 21855, 19613, 20497, 24849, 24849],
    58601: [21840, 21978, 19742, 20605, 24967, 24967],
    58801: [21964, 22106, 19876, 20713, 25085, 25085],
    59001: [22088, 22229, 20005, 20822, 25203, 25203],
    59201: [22212, 22352, 20134, 20930, 25321, 25321],
    59401: [22336, 22479, 20267, 21038, 25439, 25439],
    59601: [22460, 22602, 20396, 21146, 25557, 25557],
    59801: [22584, 22725, 20525, 21255, 25675, 25675],
    60001: [22708, 22853, 20659, 21363, 25793, 25793],
    60201: [22832, 22976, 20788, 21471, 25911, 25911],
    60401: [22956, 23104, 20922, 21580, 26029, 26029],
    60601: [23080, 23227, 21051, 21688, 26147, 26147],
    60801: [23204, 23350, 21180, 21796, 26265, 26265],
    61001: [23328, 23477, 21313, 21905, 26383, 26383],
    61201: [23452, 23600, 21442, 22013, 26501, 26501],
    61401: [23576, 23723, 21571, 22121, 26619, 26619],
    61601: [23700, 23851, 21705, 22230, 26737, 26737],
    61801: [23824, 23974, 21834, 22338, 26855, 26855],
    62001: [23948, 24102, 21968, 22446, 26973, 26973],
    62201: [24072, 24225, 22097, 22555, 27091, 27091],
    62401: [24196, 24348, 22226, 22663, 27209, 27209],
    62601: [24320, 24476, 22360, 22771, 27327, 27327],
    62801: [24444, 24598, 22488, 22879, 27445, 27445],
    63001: [24568, 24721, 22617, 22988, 27563, 27563],
    63201: [24692, 24849, 22751, 23096, 27681, 27681],
    63401: [24816, 24972, 22880, 23204, 27799, 27799],
    63601: [24940, 25100, 23014, 23313, 27917, 27917],
    63801: [25064, 25223, 23143, 23421, 28035, 28035],
    64001: [25188, 25346, 23272, 23529, 28153, 28153],
    64201: [25312, 25474, 23406, 23638, 28271, 28271],
    64401: [25436, 25597, 23535, 23746, 28389, 28389],
    64601: [25560, 25719, 23663, 23854, 28507, 28507],
    64801: [25684, 25847, 23797, 23963, 28625, 28625],
    65001: [25808, 25970, 23926, 24071, 28743, 28743],
    65201: [25932, 26098, 24060, 24179, 28861, 28861],
    65401: [26056, 26221, 24189, 24288, 28979, 28979],
    65601: [26180, 26344, 24318, 24396, 29097, 29097],
    65801: [26304, 26472, 24452, 24504, 29215, 29215],
    66001: [26428, 26595, 24581, 24612, 29333, 29333],
    66201: [26552, 26718, 24710, 24721, 29451, 29451],
    66401: [26676, 26845, 24843, 24829, 29569, 29569],
    66601: [26800, 26968, 24972, 24937, 29687, 29687],
    66801: [26924, 27096, 25106, 25046, 29805, 29805],
    67001: [27048, 27219, 25235, 25154, 29923, 29923],
    67201: [27172, 27342, 25364, 25262, 30041, 30041],
    67401: [27296, 27470, 25498, 25371, 30159, 30159],
    67601: [27420, 27593, 25627, 25479, 30277, 30277],
    67801: [27544, 27721, 25761, 25587, 30395, 30395],
    68001: [27668, 27843, 25889, 25696, 30513, 30513],
    68201: [27792, 27966, 26018, 25804, 30631, 30631],
    68401: [27916, 28094, 26152, 25912, 30749, 30749],
    68601: [28040, 28217, 26281, 26021, 30867, 30867],
    68801: [28164, 28340, 26410, 26129, 30985, 30985],
    69001: [28288, 28468, 26544, 26237, 31103, 31103],
    69201: [28412, 28591, 26673, 26345, 31221, 31221],
    69401: [28536, 28719, 26807, 26454, 31339, 31339],
    69601: [28660, 28842, 26936, 26562, 31457, 31457],
    69801: [28784, 28964, 27064, 26670, 31575, 31575],
    70001: [28908, 29092, 27198, 26779, 31693, 31693],
    70201: [29032, 29215, 27327, 26887, 31811, 31811],
    70401: [29156, 29338, 27456, 26995, 31929, 31929],
    70601: [29280, 29466, 27590, 27104, 32047, 32047],
    70801: [29404, 29589, 27719, 27212, 32165, 32165],
    71001: [29528, 29717, 27853, 27320, 32283, 32283],
    71201: [29652, 29840, 27982, 27429, 32401, 32401],
    71401: [29776, 29963, 28111, 27537, 32519, 32519],
    71601: [29900, 30090, 28244, 27645, 32637, 32637],
    71801: [30024, 30213, 28373, 27754, 32755, 32755],
    72001: [30148, 30336, 28502, 27862, 32873, 32873],
    72201: [30272, 30464, 28636, 27970, 32991, 32991],
    72401: [30396, 30587, 28765, 28079, 33109, 33109],
    72601: [30520, 30715, 28899, 28187, 33227, 33227],
    72801: [30644, 30838, 29028, 28295, 33345, 33345],
    73001: [30768, 30961, 29157, 28403, 33463, 33463],
    73201: [30892, 31088, 29290, 28512, 33581, 33581],
    73401: [31016, 31211, 29419, 28620, 33699, 33699],
    73601: [31140, 31334, 29548, 28728, 33817, 33817],
    73801: [31264, 31462, 29682, 28837, 33935, 33935],
    74001: [31388, 31585, 29811, 28945, 34053, 34053],
    74201: [31512, 31713, 29945, 29053, 34171, 34171],
    74401: [31636, 31836, 30074, 29162, 34289, 34289],
    74601: [31760, 31959, 30203, 29270, 34407, 34407],
    74801: [31884, 32087, 30337, 29378, 34525, 34525],
    75001: [32008, 32209, 30465, 29487, 34643, 34643],
    75201: [32132, 32332, 30594, 29595, 34761, 34761],
    75401: [32256, 32460, 30728, 29703, 34879, 34879],
    75601: [32380, 32583, 30857, 29812, 34997, 34997],
    75801: [32504, 32711, 30991, 29920, 35115, 35115],
    76001: [32628, 32834, 31120, 30028, 35233, 35233],
    76201: [32752, 32957, 31249, 30136, 35351, 35351],
    76401: [32876, 33085, 31383, 30245, 35469, 35469],
    76601: [33000, 33208, 31512, 30353, 35587, 35587],
    76801: [33124, 33335, 31645, 30461, 35705, 35705],
    77001: [33248, 33458, 31774, 30570, 35823, 35823],
    77201: [33372, 33581, 31903, 30678, 35941, 35941],
    77401: [33496, 33709, 32037, 30786, 36059, 36059],
    77601: [33620, 33832, 32166, 30895, 36177, 36177],
    77801: [33744, 33955, 32295, 31003, 36295, 36295],
    78001: [33868, 34083, 32429, 31111, 36413, 36413],
    78201: [33992, 34206, 32558, 31220, 36531, 36531],
    78401: [34116, 34333, 32691, 31328, 36649, 36649],
    78601: [34240, 34456, 32820, 31436, 36767, 36767],
    78801: [34364, 34579, 32949, 31545, 36885, 36885],
    79001: [34488, 34707, 33083, 31653, 37003, 37003],
    79201: [34612, 34830, 33212, 31761, 37121, 37121],
    79401: [34736, 34953, 33341, 31869, 37239, 37239],
    79601: [34860, 35081, 33475, 31978, 37357, 37357],
    79801: [34984, 35204, 33604, 32086, 37475, 37475],
    80001: [44, 44, 42, 40, 47, 47, '%'],
    81601: [44, 44, 43, 40, 47, 47, '%'],
    82201: [44, 45, 43, 41, 47, 47, '%'],
    83401: [45, 45, 43, 41, 47, 47, '%'],
    84401: [45, 45, 43, 41, 48, 48, '%'],
    85401: [45, 45, 44, 41, 48, 48, '%'],
    87001: [45, 46, 44, 41, 48, 48, '%'],
    88401: [46, 46, 44, 41, 48, 48, '%'],
    88601: [46, 46, 44, 42, 48, 48, '%'],
    89401: [46, 46, 45, 42, 48, 48, '%'],
    92401: [46, 47, 45, 42, 48, 48, '%'],
    92601: [46, 47, 45, 42, 49, 49, '%'],
    94001: [46, 47, 46, 42, 49, 49, '%'],
    94201: [47, 47, 46, 42, 49, 49, '%'],
    96401: [47, 47, 46, 43, 49, 49, '%'],
    98601: [47, 48, 46, 43, 49, 49, '%'],
    99001: [47, 48, 47, 43, 49, 49, '%'],
    100601: [48, 48, 47, 43, 49, 49, '%'],
    102201: [48, 48, 47, 43, 50, 50, '%'],
    104401: [48, 48, 48, 43, 50, 50, '%'],
    105401: [48, 48, 48, 44, 50, 50, '%'],
    105801: [48, 49, 48, 44, 50, 50, '%'],
    108201: [49, 49, 48, 44, 50, 50, '%'],
    110601: [49, 49, 49, 44, 50, 50, '%'],
    114001: [49, 50, 49, 44, 50, 50, '%'],
    114401: [49, 50, 49, 44, 51, 51, '%'],
    116201: [49, 50, 49, 45, 51, 51, '%'],
    116801: [50, 50, 49, 45, 51, 51, '%'],
    117601: [50, 50, 50, 45, 51, 51, '%'],
    123601: [50, 51, 50, 45, 51, 51, '%'],
    125601: [50, 51, 51, 45, 51, 51, '%'],
    127001: [51, 51, 51, 45, 51, 51, '%'],
    129601: [51, 51, 51, 45, 52, 52, '%'],
    129801: [51, 51, 51, 46, 52, 52, '%'],
    135001: [51, 52, 52, 46, 52, 52, '%'],
    139001: [52, 52, 52, 46, 52, 52, '%'],
    146601: [52, 52, 52, 47, 52, 52, '%'],
    149601: [52, 53, 53, 47, 53, 53, '%'],
    153801: [53, 53, 53, 47, 53, 53, '%'],
    168801: [53, 53, 53, 48, 53, 53, '%'],
    176801: [54, 54, 54, 48, 54, 54, '%'],
    198601: [54, 54, 54, 49, 54, 54, '%'],
    216001: [55, 55, 55, 49, 55, 55, '%'],
    241201: [55, 55, 55, 50, 55, 55, '%'],
    277801: [56, 56, 56, 50, 56, 56, '%'],
    307201: [56, 56, 56, 51, 56, 56, '%'],
    388801: [57, 57, 57, 51, 57, 57, '%'],
    423001: [57, 57, 57, 52, 57, 57, '%'],
    648201: [58, 58, 58, 52, 58, 58, '%'],
    678401: [58, 58, 58, 53, 58, 58, '%'],
    1711801: [58, 58, 58, 54, 58, 58, '%'],
    1944801: [59, 59, 59, 54, 59, 59, '%']
  },
  40: {
    1: [0, 0, 0, 0, 0, 0],
    1701: [126, 0, 125, 1, 126, 37],
    1801: [137, 0, 133, 4, 137, 78],
    1901: [147, 0, 141, 6, 147, 119],
    2001: [158, 0, 150, 8, 160, 160],
    2101: [160, 0, 150, 10, 201, 201],
    2201: [171, 0, 158, 12, 242, 242],
    2301: [181, 0, 166, 14, 283, 283],
    2401: [192, 0, 175, 17, 324, 324],
    2501: [202, 0, 183, 19, 365, 365],
    2601: [213, 0, 191, 21, 406, 406],
    2701: [223, 0, 200, 23, 447, 447],
    2801: [225, 0, 200, 25, 488, 488],
    2901: [236, 0, 208, 27, 529, 529],
    3001: [246, 0, 216, 30, 570, 570],
    3101: [257, 0, 225, 32, 611, 611],
    3201: [267, 0, 233, 34, 652, 652],
    3301: [278, 0, 241, 36, 693, 693],
    3401: [280, 0, 241, 38, 734, 734],
    3501: [290, 0, 250, 40, 775, 775],
    3601: [301, 0, 258, 52, 816, 816],
    3701: [311, 0, 266, 80, 857, 857],
    3801: [322, 0, 275, 108, 898, 898],
    3901: [332, 0, 283, 135, 939, 939],
    4001: [334, 0, 283, 163, 970, 970],
    4101: [344, 0, 291, 190, 1004, 1004],
    4201: [354, 0, 300, 217, 1038, 1038],
    4301: [364, 0, 308, 245, 1069, 1069],
    4401: [374, 6, 317, 272, 1103, 1103],
    4501: [384, 37, 326, 299, 1134, 1134],
    4601: [386, 68, 328, 327, 1168, 1168],
    4701: [396, 99, 338, 354, 1202, 1202],
    4801: [406, 129, 348, 381, 1233, 1233],
    4901: [416, 160, 358, 409, 1267, 1267],
    5001: [426, 191, 368, 436, 1298, 1298],
    5101: [436, 222, 378, 463, 1332, 1332],
    5201: [461, 252, 388, 491, 1366, 1366],
    5301: [486, 280, 389, 518, 1396, 1396],
    5401: [511, 310, 399, 544, 1430, 1430],
    5501: [536, 341, 409, 571, 1460, 1460],
    5601: [561, 372, 419, 598, 1494, 1494],
    5701: [586, 403, 429, 625, 1527, 1527],
    5801: [611, 433, 439, 651, 1557, 1557],
    5901: [636, 464, 441, 678, 1591, 1591],
    6001: [660, 495, 451, 705, 1621, 1621],
    6101: [685, 526, 461, 732, 1655, 1655],
    6201: [710, 556, 471, 758, 1688, 1688],
    6301: [735, 587, 480, 785, 1718, 1718],
    6401: [760, 618, 490, 812, 1752, 1752],
    6501: [785, 645, 492, 838, 1782, 1782],
    6601: [810, 676, 502, 865, 1816, 1816],
    6701: [835, 707, 512, 892, 1849, 1849],
    6801: [860, 737, 522, 919, 1879, 1879],
    6901: [885, 768, 532, 946, 1913, 1913],
    7001: [910, 799, 542, 972, 1943, 1943],
    7101: [935, 830, 543, 999, 1977, 1977],
    7201: [959, 860, 553, 1026, 2010, 2010],
    7301: [984, 891, 563, 1052, 2040, 2040],
    7401: [1009, 922, 573, 1079, 2074, 2074],
    7501: [1034, 953, 583, 1106, 2104, 2104],
    7601: [1059, 983, 593, 1133, 2138, 2138],
    7701: [1084, 1011, 603, 1159, 2171, 2171],
    7801: [1109, 1042, 604, 1186, 2201, 2201],
    7901: [1134, 1062, 614, 1213, 2235, 2235],
    8001: [1159, 1086, 623, 1240, 2265, 2265],
    8101: [1184, 1110, 633, 1266, 2299, 2299],
    8201: [1209, 1137, 643, 1293, 2332, 2332],
    8301: [1234, 1161, 652, 1320, 2362, 2362],
    8401: [1259, 1185, 654, 1347, 2396, 2396],
    8501: [1283, 1209, 663, 1373, 2426, 2426],
    8601: [1308, 1236, 673, 1400, 2460, 2460],
    8701: [1333, 1260, 683, 1427, 2493, 2493],
    8801: [1358, 1284, 692, 1454, 2523, 2523],
    8901: [1383, 1308, 702, 1480, 2557, 2557],
    9001: [1408, 1335, 703, 1507, 2587, 2587],
    9101: [1433, 1359, 713, 1534, 2621, 2621],
    9201: [1458, 1383, 722, 1561, 2654, 2654],
    9301: [1483, 1410, 732, 1587, 2684, 2684],
    9401: [1508, 1433, 742, 1614, 2718, 2718],
    9501: [1533, 1457, 751, 1641, 2748, 2748],
    9601: [1558, 1480, 753, 1668, 2782, 2782],
    9701: [1583, 1507, 762, 1694, 2815, 2815],
    9801: [1607, 1531, 772, 1721, 2845, 2845],
    9901: [1632, 1554, 782, 1748, 2879, 2879],
    10001: [1657, 1577, 791, 1774, 2909, 2909],
    10101: [1682, 1604, 801, 1801, 2943, 2943],
    10201: [1707, 1628, 811, 1828, 2976, 2976],
    10301: [1732, 1651, 812, 1855, 3006, 3006],
    10401: [1757, 1675, 821, 1882, 3040, 3040],
    10501: [1782, 1702, 831, 1908, 3070, 3070],
    10601: [1807, 1725, 841, 1935, 3104, 3104],
    10701: [1832, 1749, 850, 1962, 3137, 3137],
    10801: [1857, 1772, 860, 1988, 3167, 3167],
    10901: [1882, 1799, 861, 2015, 3204, 3204],
    11001: [1907, 1822, 871, 2042, 3244, 3244],
    11101: [1932, 1846, 881, 2069, 3285, 3285],
    11201: [1957, 1873, 890, 2096, 3325, 3325],
    11301: [1982, 1896, 900, 2123, 3365, 3365],
    11401: [2008, 1920, 910, 2150, 3405, 3405],
    11501: [2033, 1943, 911, 2177, 3446, 3446],
    11601: [2058, 1970, 921, 2204, 3486, 3486],
    11701: [2083, 1993, 930, 2232, 3526, 3526],
    11801: [2109, 2017, 940, 2259, 3566, 3566],
    11901: [2134, 2040, 949, 2286, 3607, 3607],
    12001: [2159, 2067, 959, 2313, 3647, 3647],
    12101: [2184, 2091, 960, 2340, 3687, 3687],
    12201: [2209, 2114, 970, 2367, 3727, 3727],
    12301: [2235, 2138, 980, 2394, 3768, 3768],
    12401: [2260, 2164, 989, 2421, 3808, 3808],
    12501: [2285, 2188, 999, 2448, 3852, 3852],
    12601: [2310, 2211, 1009, 2475, 3895, 3895],
    12701: [2336, 2235, 1018, 2502, 3939, 3939],
    12801: [2361, 2262, 1020, 2529, 3982, 3982],
    12901: [2386, 2285, 1029, 2556, 4026, 4026],
    13001: [2419, 2315, 1039, 2589, 4073, 4073],
    13101: [2454, 2352, 1049, 2625, 4117, 4117],
    13201: [2490, 2386, 1060, 2660, 4160, 4160],
    13301: [2525, 2423, 1070, 2695, 4204, 4204],
    13401: [2560, 2456, 1072, 2731, 4247, 4247],
    13501: [2596, 2490, 1082, 2766, 4294, 4294],
    13601: [2631, 2527, 1092, 2802, 4338, 4338],
    13701: [2667, 2560, 1102, 2837, 4382, 4382],
    13801: [2702, 2597, 1113, 2872, 4425, 4425],
    13901: [2738, 2631, 1123, 2908, 4469, 4469],
    14001: [2772, 2664, 1124, 2942, 4515, 4515],
    14101: [2806, 2701, 1135, 2977, 4557, 4557],
    14201: [2841, 2735, 1145, 3011, 4600, 4600],
    14301: [2875, 2772, 1155, 3045, 4642, 4642],
    14401: [2909, 2805, 1165, 3080, 4685, 4685],
    14501: [2944, 2839, 1176, 3114, 4731, 4731],
    14601: [2978, 2876, 1178, 3148, 4773, 4773],
    14701: [3012, 2909, 1188, 3183, 4816, 4816],
    14801: [3046, 2946, 1198, 3217, 4858, 4858],
    14901: [3081, 2980, 1208, 3251, 4901, 4901],
    15001: [3115, 3013, 1218, 3286, 4946, 4946],
    15101: [3149, 3050, 1229, 3320, 4989, 4989],
    15201: [3184, 3083, 1239, 3354, 5032, 5032],
    15301: [3218, 3120, 1241, 3388, 5074, 5074],
    15401: [3252, 3154, 1251, 3423, 5117, 5117],
    15501: [3287, 3187, 1261, 3457, 5162, 5162],
    15601: [3321, 3224, 1271, 3492, 5205, 5205],
    15701: [3355, 3258, 1281, 3526, 5247, 5247],
    15801: [3390, 3295, 1292, 3560, 5290, 5290],
    15901: [3424, 3328, 1293, 3594, 5332, 5332],
    16001: [3458, 3362, 1307, 3629, 5378, 5378],
    16101: [3493, 3399, 1339, 3663, 5421, 5421],
    16201: [3527, 3432, 1367, 3697, 5463, 5463],
    16301: [3561, 3469, 1399, 3732, 5506, 5506],
    16401: [3596, 3503, 1428, 3766, 5548, 5548],
    16501: [3630, 3536, 1456, 3800, 5594, 5594],
    16601: [3664, 3573, 1488, 3835, 5637, 5637],
    16701: [3699, 3607, 1517, 3869, 5679, 5679],
    16801: [3733, 3644, 1549, 3903, 5722, 5722],
    16901: [3767, 3677, 1577, 3938, 5764, 5764],
    17001: [3802, 3711, 1606, 3972, 5810, 5810],
    17101: [3836, 3748, 1638, 4006, 5852, 5852],
    17201: [3870, 3781, 1666, 4041, 5895, 5895],
    17301: [3904, 3818, 1698, 4075, 5938, 5938],
    17401: [3939, 3852, 1727, 4109, 5980, 5980],
    17501: [3973, 3888, 1758, 4144, 6026, 6026],
    17601: [4007, 3922, 1787, 4178, 6068, 6068],
    17701: [4042, 3956, 1816, 4212, 6111, 6111],
    17801: [4076, 3992, 1847, 4246, 6153, 6153],
    17901: [4110, 4026, 1876, 4281, 6196, 6196],
    18001: [4145, 4063, 1908, 4315, 6242, 6242],
    18101: [4179, 4096, 1936, 4349, 6284, 6284],
    18201: [4213, 4130, 1965, 4384, 6327, 6327],
    18301: [4248, 4167, 1997, 4418, 6369, 6369],
    18401: [4282, 4200, 2025, 4452, 6412, 6412],
    18501: [4316, 4237, 2057, 4487, 6458, 6458],
    18601: [4351, 4271, 2086, 4521, 6500, 6500],
    18701: [4385, 4304, 2114, 4555, 6543, 6543],
    18801: [4419, 4341, 2146, 4590, 6585, 6585],
    18901: [4454, 4375, 2175, 4624, 6628, 6628],
    19001: [4488, 4412, 2207, 4658, 6673, 6673],
    19101: [4522, 4445, 2235, 4693, 6716, 6716],
    19201: [4557, 4479, 2264, 4727, 6759, 6759],
    19301: [4591, 4515, 2295, 4761, 6801, 6801],
    19401: [4625, 4547, 2322, 4796, 6844, 6844],
    19501: [4660, 4583, 2353, 4830, 6889, 6889],
    19601: [4694, 4616, 2381, 4864, 6932, 6932],
    19701: [4728, 4649, 2409, 4899, 6974, 6974],
    19801: [4762, 4685, 2440, 4933, 7017, 7017],
    19901: [4797, 4718, 2468, 4967, 7059, 7059],
    20001: [4865, 4786, 2526, 5036, 7148, 7148],
    20201: [4934, 4855, 2585, 5104, 7233, 7233],
    20401: [5003, 4924, 2644, 5173, 7321, 7321],
    20601: [5071, 4989, 2699, 5242, 7406, 7406],
    20801: [5140, 5058, 2758, 5310, 7491, 7491],
    21001: [5209, 5126, 2816, 5379, 7579, 7579],
    21201: [5277, 5195, 2875, 5448, 7665, 7665],
    21401: [5346, 5264, 2934, 5516, 7753, 7753],
    21601: [5415, 5329, 2989, 5585, 7838, 7838],
    21801: [5483, 5398, 3048, 5654, 7923, 7923],
    22001: [5552, 5467, 3107, 5723, 8012, 8012],
    22201: [5623, 5535, 3165, 5793, 8098, 8098],
    22401: [5693, 5607, 3227, 5863, 8188, 8188],
    22601: [5763, 5676, 3286, 5934, 8275, 8275],
    22801: [5833, 5745, 3345, 6004, 8362, 8362],
    23001: [5904, 5817, 3407, 6074, 8452, 8452],
    23201: [5974, 5885, 3465, 6144, 8538, 8538],
    23401: [6044, 5954, 3524, 6215, 8628, 8628],
    23601: [6115, 6026, 3586, 6285, 8715, 8715],
    23801: [6185, 6095, 3645, 6355, 8802, 8802],
    24001: [6255, 6163, 3703, 6426, 8892, 8892],
    24201: [6325, 6232, 3762, 6496, 8978, 8978],
    24401: [6396, 6304, 3824, 6566, 9068, 9068],
    24601: [6466, 6373, 3883, 6636, 9155, 9155],
    24801: [6536, 6441, 3941, 6707, 9242, 9242],
    25001: [6607, 6513, 4013, 6777, 9332, 9332],
    25201: [6677, 6582, 4082, 6847, 9418, 9418],
    25401: [6747, 6651, 4151, 6918, 9508, 9508],
    25601: [6817, 6723, 4223, 6988, 9595, 9595],
    25801: [6888, 6791, 4291, 7058, 9682, 9682],
    26001: [6958, 6860, 4360, 7128, 9772, 9772],
    26201: [7028, 6932, 4432, 7199, 9858, 9858],
    26401: [7099, 7001, 4501, 7269, 9948, 9948],
    26601: [7169, 7069, 4569, 7339, 10035, 10035],
    26801: [7239, 7138, 4638, 7410, 10122, 10122],
    27001: [7310, 7210, 4710, 7480, 10212, 10212],
    27201: [7380, 7279, 4779, 7550, 10298, 10298],
    27401: [7450, 7347, 4847, 7620, 10388, 10388],
    27601: [7520, 7419, 4919, 7691, 10475, 10475],
    27801: [7591, 7488, 4988, 7761, 10562, 10562],
    28001: [7661, 7557, 5057, 7831, 10652, 10652],
    28201: [7731, 7629, 5129, 7902, 10738, 10738],
    28401: [7802, 7697, 5197, 7972, 10828, 10828],
    28601: [7872, 7766, 5266, 8042, 10915, 10915],
    28801: [7942, 7838, 5338, 8112, 11002, 11002],
    29001: [8012, 7907, 5407, 8183, 11092, 11092],
    29201: [8083, 7975, 5475, 8253, 11178, 11178],
    29401: [8153, 8048, 5548, 8323, 11268, 11268],
    29601: [8223, 8118, 5618, 8394, 11355, 11355],
    29801: [8293, 8188, 5688, 8464, 11442, 11442],
    30001: [8364, 8258, 5758, 8534, 11532, 11532],
    30201: [8434, 8332, 5832, 8604, 11618, 11618],
    30401: [8504, 8402, 5902, 8675, 11708, 11708],
    30601: [8575, 8472, 5972, 8745, 11795, 11795],
    30801: [8645, 8545, 6045, 8815, 11882, 11882],
    31001: [8715, 8615, 6115, 8886, 11972, 11972],
    31201: [8786, 8685, 6185, 8956, 12058, 12058],
    31401: [8856, 8758, 6258, 9026, 12148, 12148],
    31601: [8926, 8825, 6325, 9096, 12232, 12232],
    31801: [8996, 8895, 6395, 9166, 12312, 12312],
    32001: [9066, 8965, 6465, 9237, 12392, 12392],
    32201: [9136, 9035, 6535, 9307, 12472, 12472],
    32401: [9209, 9108, 6608, 9377, 12552, 12552],
    32601: [9289, 9188, 6688, 9447, 12632, 12632],
    32801: [9369, 9268, 6768, 9517, 12712, 12712],
    33001: [9449, 9348, 6848, 9587, 12792, 12792],
    33201: [9529, 9428, 6928, 9657, 12872, 12872],
    33401: [9609, 9508, 7008, 9727, 12952, 12952],
    33601: [9689, 9588, 7088, 9797, 13032, 13032],
    33801: [9769, 9668, 7168, 9867, 13112, 13112],
    34001: [9849, 9748, 7248, 9937, 13192, 13192],
    34201: [9929, 9828, 7328, 10007, 13272, 13272],
    34401: [10009, 9908, 7408, 10077, 13352, 13352],
    34601: [10089, 9988, 7488, 10147, 13432, 13432],
    34801: [10169, 10068, 7568, 10217, 13512, 13512],
    35001: [10249, 10148, 7648, 10287, 13592, 13592],
    35201: [10329, 10228, 7728, 10357, 13672, 13672],
    35401: [10409, 10308, 7808, 10428, 13752, 13752],
    35601: [10489, 10388, 7888, 10498, 13832, 13832],
    35801: [10569, 10468, 7968, 10568, 13912, 13912],
    36001: [10649, 10548, 8048, 10638, 13992, 13992],
    36201: [10729, 10628, 8128, 10708, 14072, 14072],
    36401: [10809, 10708, 8208, 10778, 14152, 14152],
    36601: [10889, 10788, 8288, 10848, 14232, 14232],
    36801: [10969, 10868, 8368, 10918, 14312, 14312],
    37001: [11049, 10948, 8448, 10988, 14392, 14392],
    37201: [11129, 11028, 8528, 11058, 14472, 14472],
    37401: [11209, 11108, 8608, 11128, 14552, 14552],
    37601: [11289, 11188, 8688, 11198, 14632, 14632],
    37801: [11369, 11268, 8768, 11268, 14712, 14712],
    38001: [11449, 11348, 8848, 11338, 14792, 14792],
    38201: [11529, 11428, 8928, 11408, 14872, 14872],
    38401: [11609, 11508, 9008, 11478, 14952, 14952],
    38601: [11689, 11588, 9088, 11548, 15032, 15032],
    38801: [11769, 11668, 9168, 11618, 15112, 15112],
    39001: [11849, 11748, 9248, 11689, 15192, 15192],
    39201: [11929, 11828, 9328, 11759, 15272, 15272],
    39401: [12009, 11908, 9408, 11829, 15352, 15352],
    39601: [12089, 11988, 9488, 11899, 15432, 15432],
    39801: [12169, 12068, 9568, 11969, 15512, 15512],
    40001: [12249, 12148, 9648, 12039, 15592, 15592],
    40201: [12329, 12228, 9728, 12109, 15672, 15672],
    40401: [12409, 12308, 9808, 12179, 15752, 15752],
    40601: [12489, 12388, 9888, 12249, 15832, 15832],
    40801: [12569, 12468, 9968, 12319, 15912, 15912],
    41001: [12649, 12548, 10048, 12389, 15992, 15992],
    41201: [12729, 12628, 10128, 12459, 16072, 16072],
    41401: [12809, 12708, 10208, 12529, 16152, 16152],
    41601: [12889, 12788, 10288, 12599, 16232, 16232],
    41801: [12969, 12868, 10368, 12669, 16312, 16312],
    42001: [13049, 12948, 10448, 12739, 16392, 16392],
    42201: [13129, 13028, 10528, 12809, 16472, 16472],
    42401: [13209, 13108, 10608, 12880, 16552, 16552],
    42601: [13289, 13188, 10688, 12950, 16632, 16632],
    42801: [13369, 13268, 10768, 13020, 16712, 16712],
    43001: [13449, 13348, 10848, 13090, 16792, 16792],
    43201: [13529, 13428, 10928, 13160, 16872, 16872],
    43401: [13609, 13508, 11008, 13230, 16952, 16952],
    43601: [13689, 13588, 11088, 13300, 17032, 17032],
    43801: [13769, 13668, 11168, 13370, 17112, 17112],
    44001: [13849, 13748, 11248, 13440, 17192, 17192],
    44201: [13929, 13828, 11328, 13510, 17272, 17272],
    44401: [14009, 13908, 11408, 13580, 17352, 17352],
    44601: [14089, 13988, 11488, 13650, 17432, 17432],
    44801: [14169, 14068, 11568, 13720, 17512, 17512],
    45001: [14249, 14148, 11648, 13790, 17592, 17592],
    45201: [14329, 14228, 11728, 13860, 17672, 17672],
    45401: [14409, 14308, 11808, 13930, 17752, 17752],
    45601: [14489, 14388, 11888, 14000, 17832, 17832],
    45801: [14569, 14468, 11968, 14070, 17912, 17912],
    46001: [14649, 14548, 12048, 14141, 17992, 17992],
    46201: [14761, 14675, 12175, 14242, 18103, 18103],
    46401: [14881, 14805, 12305, 14352, 18223, 18223],
    46601: [15001, 14935, 12435, 14462, 18343, 18343],
    46801: [15121, 15065, 12565, 14572, 18463, 18463],
    47001: [15241, 15195, 12695, 14682, 18583, 18583],
    47201: [15361, 15322, 12822, 14793, 18703, 18703],
    47401: [15481, 15452, 12952, 14903, 18823, 18823],
    47601: [15601, 15582, 13082, 15013, 18943, 18943],
    47801: [15721, 15712, 13212, 15123, 19063, 19063],
    48001: [15841, 15842, 13342, 15233, 19183, 19183],
    48201: [15961, 15972, 13472, 15343, 19303, 19303],
    48401: [16081, 16102, 13602, 15453, 19423, 19423],
    48601: [16201, 16232, 13732, 15563, 19543, 19543],
    48801: [16321, 16358, 13858, 15673, 19663, 19663],
    49001: [16441, 16488, 13988, 15783, 19783, 19783],
    49201: [16561, 16618, 14118, 15893, 19903, 19903],
    49401: [16681, 16748, 14248, 16003, 20023, 20023],
    49601: [16801, 16878, 14378, 16113, 20143, 20143],
    49801: [16921, 17008, 14508, 16223, 20263, 20263],
    50001: [17041, 17138, 14644, 16333, 20383, 20383],
    50201: [17161, 17268, 14780, 16443, 20503, 20503],
    50401: [17281, 17395, 14913, 16553, 20623, 20623],
    50601: [17401, 17525, 15049, 16664, 20743, 20743],
    50801: [17521, 17655, 15185, 16774, 20863, 20863],
    51001: [17641, 17785, 15321, 16884, 20983, 20983],
    51201: [17761, 17915, 15457, 16994, 21103, 21103],
    51401: [17881, 18033, 15581, 17104, 21223, 21223],
    51601: [18001, 18153, 15707, 17214, 21343, 21343],
    51801: [18121, 18273, 15833, 17324, 21463, 21463],
    52001: [18241, 18393, 15959, 17434, 21583, 21583],
    52201: [18361, 18513, 16085, 17544, 21703, 21703],
    52401: [18481, 18633, 16211, 17654, 21823, 21823],
    52601: [18601, 18753, 16337, 17764, 21943, 21943],
    52801: [18721, 18873, 16463, 17874, 22063, 22063],
    53001: [18841, 18993, 16589, 17984, 22183, 22183],
    53201: [18961, 19113, 16715, 18094, 22303, 22303],
    53401: [19081, 19233, 16841, 18204, 22423, 22423],
    53601: [19201, 19353, 16967, 18314, 22543, 22543],
    53801: [19321, 19473, 17093, 18424, 22663, 22663],
    54001: [19441, 19593, 17219, 18534, 22783, 22783],
    54201: [19561, 19713, 17345, 18645, 22903, 22903],
    54401: [19684, 19838, 17476, 18755, 23023, 23023],
    54601: [19810, 19968, 17612, 18865, 23143, 23143],
    54801: [19936, 20093, 17743, 18975, 23263, 23263],
    55001: [20062, 20218, 17874, 19085, 23383, 23383],
    55201: [20188, 20348, 18010, 19195, 23503, 23503],
    55401: [20314, 20473, 18141, 19305, 23623, 23623],
    55601: [20440, 20598, 18272, 19415, 23743, 23743],
    55801: [20566, 20728, 18408, 19525, 23863, 23863],
    56001: [20692, 20853, 18539, 19635, 23983, 23983],
    56201: [20818, 20983, 18675, 19745, 24103, 24103],
    56401: [20944, 21108, 18806, 19855, 24223, 24223],
    56601: [21070, 21233, 18937, 19965, 24343, 24343],
    56801: [21196, 21363, 19073, 20075, 24463, 24463],
    57001: [21322, 21488, 19204, 20185, 24583, 24583],
    57201: [21448, 21618, 19340, 20295, 24703, 24703],
    57401: [21574, 21743, 19471, 20405, 24823, 24823],
    57601: [21700, 21868, 19602, 20516, 24943, 24943],
    57801: [21826, 21998, 19738, 20626, 25063, 25063],
    58001: [21952, 22123, 19869, 20736, 25183, 25183],
    58201: [22078, 22248, 20000, 20846, 25303, 25303],
    58401: [22204, 22378, 20136, 20956, 25423, 25423],
    58601: [22330, 22503, 20267, 21066, 25543, 25543],
    58801: [22456, 22633, 20403, 21176, 25663, 25663],
    59001: [22582, 22758, 20534, 21286, 25783, 25783],
    59201: [22708, 22883, 20665, 21396, 25903, 25903],
    59401: [22834, 23013, 20801, 21506, 26023, 26023],
    59601: [22960, 23138, 20932, 21616, 26143, 26143],
    59801: [23086, 23263, 21063, 21726, 26263, 26263],
    60001: [23212, 23393, 21199, 21836, 26383, 26383],
    60201: [23338, 23518, 21330, 21946, 26503, 26503],
    60401: [23464, 23648, 21466, 22056, 26623, 26623],
    60601: [23590, 23773, 21597, 22166, 26743, 26743],
    60801: [23716, 23898, 21728, 22276, 26863, 26863],
    61001: [23842, 24028, 21864, 22386, 26983, 26983],
    61201: [23968, 24153, 21995, 22497, 27103, 27103],
    61401: [24094, 24278, 22126, 22607, 27223, 27223],
    61601: [24220, 24408, 22262, 22717, 27343, 27343],
    61801: [24346, 24533, 22393, 22827, 27463, 27463],
    62001: [24472, 24663, 22529, 22937, 27583, 27583],
    62201: [24598, 24788, 22660, 23047, 27703, 27703],
    62401: [24724, 24913, 22791, 23157, 27823, 27823],
    62601: [24850, 25043, 22927, 23267, 27943, 27943],
    62801: [24976, 25168, 23058, 23377, 28063, 28063],
    63001: [25102, 25293, 23189, 23487, 28183, 28183],
    63201: [25228, 25423, 23325, 23597, 28303, 28303],
    63401: [25354, 25548, 23456, 23707, 28423, 28423],
    63601: [25480, 25678, 23592, 23817, 28543, 28543],
    63801: [25606, 25803, 23723, 23927, 28663, 28663],
    64001: [25732, 25928, 23854, 24037, 28783, 28783],
    64201: [25858, 26058, 23990, 24147, 28903, 28903],
    64401: [25984, 26183, 24121, 24257, 29023, 29023],
    64601: [26110, 26308, 24252, 24367, 29143, 29143],
    64801: [26236, 26438, 24388, 24478, 29263, 29263],
    65001: [26362, 26563, 24519, 24588, 29383, 29383],
    65201: [26488, 26693, 24655, 24698, 29503, 29503],
    65401: [26614, 26818, 24786, 24808, 29623, 29623],
    65601: [26740, 26943, 24917, 24918, 29743, 29743],
    65801: [26866, 27073, 25053, 25028, 29863, 29863],
    66001: [26992, 27198, 25184, 25138, 29983, 29983],
    66201: [27118, 27323, 25315, 25248, 30103, 30103],
    66401: [27244, 27453, 25451, 25358, 30223, 30223],
    66601: [27370, 27578, 25582, 25468, 30343, 30343],
    66801: [27496, 27708, 25718, 25578, 30463, 30463],
    67001: [27622, 27833, 25849, 25688, 30583, 30583],
    67201: [27748, 27958, 25980, 25798, 30703, 30703],
    67401: [27874, 28088, 26116, 25908, 30823, 30823],
    67601: [28000, 28213, 26247, 26018, 30943, 30943],
    67801: [28126, 28343, 26383, 26128, 31063, 31063],
    68001: [28252, 28468, 26514, 26238, 31183, 31183],
    68201: [28378, 28593, 26645, 26349, 31303, 31303],
    68401: [28504, 28723, 26781, 26459, 31423, 31423],
    68601: [28630, 28848, 26912, 26569, 31543, 31543],
    68801: [28756, 28973, 27043, 26679, 31663, 31663],
    69001: [28882, 29103, 27179, 26789, 31783, 31783],
    69201: [29008, 29228, 27310, 26899, 31903, 31903],
    69401: [29134, 29358, 27446, 27009, 32023, 32023],
    69601: [29260, 29483, 27577, 27119, 32143, 32143],
    69801: [29386, 29608, 27708, 27229, 32263, 32263],
    70001: [29512, 29738, 27844, 27339, 32383, 32383],
    70201: [29638, 29863, 27975, 27449, 32503, 32503],
    70401: [29764, 29988, 28106, 27559, 32623, 32623],
    70601: [29890, 30118, 28242, 27669, 32743, 32743],
    70801: [30016, 30243, 28373, 27779, 32863, 32863],
    71001: [30142, 30373, 28509, 27889, 32983, 32983],
    71201: [30268, 30498, 28640, 27999, 33103, 33103],
    71401: [30394, 30623, 28771, 28110, 33223, 33223],
    71601: [30520, 30753, 28907, 28219, 33343, 33343],
    71801: [30646, 30878, 29038, 28330, 33463, 33463],
    72001: [30772, 31003, 29169, 28440, 33583, 33583],
    72201: [30898, 31133, 29305, 28550, 33703, 33703],
    72401: [31024, 31258, 29436, 28660, 33823, 33823],
    72601: [31150, 31388, 29572, 28770, 33943, 33943],
    72801: [31276, 31513, 29703, 28880, 34063, 34063],
    73001: [31402, 31638, 29834, 28990, 34183, 34183],
    73201: [31528, 31768, 29970, 29100, 34303, 34303],
    73401: [31654, 31893, 30101, 29210, 34423, 34423],
    73601: [31780, 32018, 30232, 29320, 34543, 34543],
    73801: [31906, 32148, 30368, 29430, 34663, 34663],
    74001: [32032, 32273, 30499, 29540, 34783, 34783],
    74201: [32158, 32403, 30635, 29650, 34903, 34903],
    74401: [32284, 32528, 30766, 29760, 35023, 35023],
    74601: [32410, 32653, 30897, 29870, 35143, 35143],
    74801: [32536, 32783, 31033, 29980, 35263, 35263],
    75001: [32662, 32908, 31164, 30090, 35383, 35383],
    75201: [32788, 33033, 31295, 30201, 35503, 35503],
    75401: [32914, 33163, 31431, 30311, 35623, 35623],
    75601: [33040, 33288, 31562, 30421, 35743, 35743],
    75801: [33166, 33418, 31698, 30531, 35863, 35863],
    76001: [33292, 33543, 31829, 30641, 35983, 35983],
    76201: [33418, 33668, 31960, 30751, 36103, 36103],
    76401: [33544, 33798, 32096, 30861, 36223, 36223],
    76601: [33670, 33923, 32227, 30971, 36343, 36343],
    76801: [33796, 34053, 32363, 31081, 36463, 36463],
    77001: [33922, 34178, 32494, 31191, 36583, 36583],
    77201: [34048, 34303, 32625, 31301, 36703, 36703],
    77401: [34174, 34433, 32761, 31411, 36823, 36823],
    77601: [34300, 34558, 32892, 31521, 36943, 36943],
    77801: [34426, 34683, 33023, 31631, 37063, 37063],
    78001: [34552, 34813, 33159, 31741, 37183, 37183],
    78201: [34678, 34938, 33290, 31851, 37303, 37303],
    78401: [34804, 35068, 33426, 31961, 37423, 37423],
    78601: [34930, 35193, 33557, 32071, 37543, 37543],
    78801: [35056, 35318, 33688, 32182, 37663, 37663],
    79001: [35182, 35448, 33824, 32292, 37783, 37783],
    79201: [35308, 35573, 33955, 32402, 37903, 37903],
    79401: [35434, 35698, 34086, 32512, 38023, 38023],
    79601: [35560, 35828, 34222, 32622, 38143, 38143],
    79801: [35686, 35953, 34353, 32732, 38263, 38263],
    80001: [45, 45, 43, 41, 48, 48, '%'],
    81801: [45, 45, 44, 41, 48, 48, '%'],
    82401: [45, 46, 44, 41, 48, 48, '%'],
    83401: [45, 46, 44, 42, 48, 48, '%'],
    84001: [46, 46, 44, 42, 48, 48, '%'],
    84601: [46, 46, 44, 42, 49, 49, '%'],
    85601: [46, 46, 45, 42, 49, 49, '%'],
    87201: [46, 47, 45, 42, 49, 49, '%'],
    89001: [47, 47, 45, 42, 49, 49, '%'],
    89601: [47, 47, 46, 42, 49, 49, '%'],
    90001: [47, 47, 46, 43, 49, 49, '%'],
    92601: [47, 47, 46, 43, 50, 50, '%'],
    92801: [47, 48, 46, 43, 50, 50, '%'],
    94201: [47, 48, 47, 43, 50, 50, '%'],
    94801: [48, 48, 47, 43, 50, 50, '%'],
    97801: [48, 48, 47, 44, 50, 50, '%'],
    99001: [48, 49, 47, 44, 50, 50, '%'],
    99201: [48, 49, 48, 44, 50, 50, '%'],
    101401: [49, 49, 48, 44, 50, 50, '%'],
    102401: [49, 49, 48, 44, 51, 51, '%'],
    104601: [49, 49, 49, 44, 51, 51, '%'],
    106001: [49, 50, 49, 44, 51, 51, '%'],
    107201: [49, 50, 49, 45, 51, 51, '%'],
    108801: [50, 50, 49, 45, 51, 51, '%'],
    110801: [50, 50, 50, 45, 51, 51, '%'],
    114201: [50, 51, 50, 45, 51, 51, '%'],
    114401: [50, 51, 50, 45, 52, 52, '%'],
    117601: [51, 51, 50, 45, 52, 52, '%'],
    117801: [51, 51, 51, 45, 52, 52, '%'],
    118401: [51, 51, 51, 46, 52, 52, '%'],
    123801: [51, 52, 51, 46, 52, 52, '%'],
    125601: [51, 52, 52, 46, 52, 52, '%'],
    127801: [52, 52, 52, 46, 52, 52, '%'],
    129801: [52, 52, 52, 46, 53, 53, '%'],
    132201: [52, 52, 52, 47, 53, 53, '%'],
    135201: [52, 53, 53, 47, 53, 53, '%'],
    140001: [53, 53, 53, 47, 53, 53, '%'],
    149601: [53, 54, 54, 47, 54, 54, '%'],
    149801: [53, 54, 54, 48, 54, 54, '%'],
    154801: [54, 54, 54, 48, 54, 54, '%'],
    172801: [54, 54, 54, 49, 54, 54, '%'],
    177001: [55, 55, 55, 49, 55, 55, '%'],
    204201: [55, 55, 55, 50, 55, 55, '%'],
    216201: [56, 56, 56, 50, 56, 56, '%'],
    249201: [56, 56, 56, 51, 56, 56, '%'],
    278001: [57, 57, 57, 51, 57, 57, '%'],
    319801: [57, 57, 57, 52, 57, 57, '%'],
    389401: [58, 58, 58, 52, 58, 58, '%'],
    446401: [58, 58, 58, 53, 58, 58, '%'],
    649001: [59, 59, 59, 53, 59, 59, '%'],
    738601: [59, 59, 59, 54, 59, 59, '%'],
    1947201: [60, 60, 60, 54, 60, 60, '%']
  }
};
export const Tabeller2023 = {
  29: {
    1: [0, 0, 0, 0, 0, 0, 0],
    1801: [134, 0, 133, 0, 134, 12, 134],
    1901: [144, 0, 141, 3, 144, 42, 144],
    2001: [155, 0, 150, 5, 155, 72, 155],
    2101: [157, 0, 150, 7, 157, 102, 157],
    2201: [167, 0, 158, 9, 167, 132, 167],
    2301: [178, 0, 166, 11, 178, 162, 178],
    2401: [188, 0, 175, 13, 192, 192, 188],
    2501: [199, 0, 183, 16, 222, 222, 199],
    2601: [209, 0, 191, 18, 252, 252, 209],
    2701: [220, 0, 200, 20, 282, 282, 220],
    2801: [222, 0, 200, 22, 312, 312, 222],
    2901: [232, 0, 208, 24, 342, 342, 232],
    3001: [243, 0, 216, 26, 372, 372, 243],
    3101: [253, 0, 225, 28, 402, 402, 253],
    3201: [264, 0, 233, 31, 432, 432, 264],
    3301: [274, 0, 241, 33, 462, 462, 274],
    3401: [277, 0, 241, 35, 492, 492, 277],
    3501: [287, 0, 250, 37, 522, 522, 287],
    3601: [298, 0, 258, 39, 552, 552, 298],
    3701: [308, 0, 266, 41, 582, 582, 308],
    3801: [319, 0, 275, 44, 612, 612, 319],
    3901: [329, 0, 283, 49, 642, 642, 329],
    4001: [331, 0, 283, 70, 672, 672, 331],
    4101: [342, 0, 291, 90, 702, 702, 342],
    4201: [352, 0, 300, 111, 732, 732, 352],
    4301: [363, 0, 308, 131, 759, 759, 363],
    4401: [373, 0, 316, 151, 782, 782, 373],
    4501: [383, 0, 325, 171, 807, 807, 383],
    4601: [384, 0, 325, 191, 832, 832, 384],
    4701: [394, 0, 333, 211, 854, 854, 394],
    4801: [405, 7, 342, 232, 879, 879, 405],
    4901: [414, 29, 352, 252, 902, 902, 414],
    5001: [425, 52, 362, 272, 927, 927, 425],
    5101: [435, 74, 372, 292, 952, 952, 435],
    5201: [445, 97, 382, 312, 974, 974, 445],
    5301: [447, 119, 383, 332, 1000, 1000, 447],
    5401: [456, 142, 393, 351, 1021, 1021, 456],
    5501: [467, 164, 403, 371, 1046, 1046, 467],
    5601: [477, 187, 413, 391, 1070, 1070, 477],
    5701: [487, 209, 423, 410, 1092, 1092, 487],
    5801: [497, 229, 433, 429, 1116, 1116, 497],
    5901: [498, 252, 434, 449, 1138, 1138, 498],
    6001: [509, 274, 444, 468, 1163, 1163, 509],
    6101: [519, 297, 454, 488, 1187, 1187, 519],
    6201: [529, 319, 464, 507, 1209, 1209, 529],
    6301: [539, 342, 474, 527, 1233, 1233, 539],
    6401: [549, 364, 484, 546, 1255, 1255, 549],
    6501: [551, 387, 486, 566, 1280, 1280, 551],
    6601: [561, 409, 496, 586, 1304, 1304, 561],
    6701: [571, 432, 506, 605, 1326, 1326, 571],
    6801: [586, 454, 516, 624, 1350, 1350, 581],
    6901: [604, 474, 525, 644, 1372, 1372, 591],
    7001: [623, 497, 535, 663, 1397, 1397, 601],
    7101: [641, 519, 537, 683, 1421, 1421, 603],
    7201: [659, 542, 547, 702, 1443, 1443, 613],
    7301: [677, 564, 557, 722, 1467, 1467, 623],
    7401: [695, 587, 567, 741, 1489, 1489, 633],
    7501: [713, 609, 577, 761, 1514, 1514, 643],
    7601: [732, 632, 587, 780, 1538, 1538, 653],
    7701: [750, 654, 597, 800, 1560, 1560, 663],
    7801: [768, 677, 598, 819, 1584, 1584, 665],
    7901: [786, 699, 608, 839, 1606, 1606, 675],
    8001: [804, 722, 618, 858, 1631, 1631, 685],
    8101: [823, 742, 628, 878, 1655, 1655, 695],
    8201: [841, 764, 638, 897, 1677, 1677, 705],
    8301: [859, 787, 648, 917, 1701, 1701, 715],
    8401: [877, 809, 649, 936, 1723, 1723, 717],
    8501: [895, 827, 659, 956, 1748, 1748, 727],
    8601: [914, 844, 669, 975, 1772, 1772, 737],
    8701: [932, 864, 678, 995, 1794, 1794, 747],
    8801: [950, 882, 688, 1014, 1818, 1818, 757],
    8901: [968, 899, 698, 1034, 1840, 1840, 767],
    9001: [986, 917, 699, 1053, 1865, 1865, 769],
    9101: [1004, 937, 709, 1073, 1889, 1889, 779],
    9201: [1023, 954, 718, 1092, 1911, 1911, 789],
    9301: [1041, 972, 728, 1112, 1935, 1935, 799],
    9401: [1059, 989, 737, 1131, 1957, 1957, 809],
    9501: [1077, 1009, 747, 1151, 1982, 1982, 819],
    9601: [1095, 1026, 748, 1170, 2006, 2006, 821],
    9701: [1114, 1043, 758, 1189, 2028, 2028, 831],
    9801: [1132, 1061, 768, 1209, 2052, 2052, 841],
    9901: [1150, 1080, 777, 1228, 2074, 2074, 851],
    10001: [1168, 1097, 787, 1248, 2099, 2099, 861],
    10101: [1186, 1114, 797, 1268, 2123, 2123, 871],
    10201: [1204, 1131, 806, 1287, 2145, 2145, 881],
    10301: [1223, 1151, 808, 1306, 2169, 2169, 883],
    10401: [1241, 1168, 817, 1326, 2191, 2191, 893],
    10501: [1259, 1185, 827, 1345, 2216, 2216, 903],
    10601: [1277, 1202, 836, 1365, 2240, 2240, 913],
    10701: [1295, 1221, 846, 1384, 2262, 2262, 923],
    10801: [1314, 1238, 856, 1404, 2286, 2286, 933],
    10901: [1332, 1255, 857, 1423, 2308, 2308, 935],
    11001: [1350, 1275, 867, 1443, 2333, 2333, 945],
    11101: [1368, 1292, 876, 1462, 2357, 2357, 955],
    11201: [1386, 1309, 886, 1482, 2379, 2379, 965],
    11301: [1404, 1326, 896, 1501, 2403, 2403, 975],
    11401: [1423, 1346, 905, 1521, 2425, 2425, 985],
    11501: [1441, 1363, 907, 1540, 2450, 2450, 987],
    11601: [1459, 1380, 916, 1560, 2474, 2474, 997],
    11701: [1477, 1397, 926, 1579, 2496, 2496, 1007],
    11801: [1495, 1416, 936, 1599, 2520, 2520, 1017],
    11901: [1514, 1433, 945, 1618, 2547, 2547, 1027],
    12001: [1532, 1450, 955, 1638, 2577, 2577, 1038],
    12101: [1551, 1467, 956, 1658, 2606, 2606, 1040],
    12201: [1569, 1487, 966, 1678, 2635, 2635, 1051],
    12301: [1588, 1504, 975, 1697, 2664, 2664, 1061],
    12401: [1606, 1521, 985, 1717, 2694, 2694, 1072],
    12501: [1625, 1538, 995, 1737, 2723, 2723, 1082],
    12601: [1643, 1558, 1004, 1757, 2752, 2752, 1093],
    12701: [1661, 1575, 1014, 1777, 2781, 2781, 1103],
    12801: [1680, 1592, 1015, 1796, 2811, 2811, 1105],
    12901: [1698, 1611, 1025, 1816, 2840, 2840, 1116],
    13001: [1717, 1628, 1035, 1836, 2869, 2869, 1126],
    13101: [1735, 1645, 1044, 1856, 2898, 2898, 1137],
    13201: [1754, 1662, 1054, 1875, 2928, 2928, 1147],
    13301: [1772, 1682, 1064, 1895, 2957, 2957, 1158],
    13401: [1791, 1699, 1065, 1915, 2986, 2986, 1160],
    13501: [1809, 1716, 1074, 1935, 3015, 3015, 1170],
    13601: [1828, 1733, 1084, 1955, 3047, 3047, 1181],
    13701: [1846, 1753, 1094, 1975, 3081, 3081, 1192],
    13801: [1865, 1770, 1103, 1995, 3113, 3113, 1203],
    13901: [1884, 1787, 1113, 2014, 3144, 3144, 1213],
    14001: [1902, 1804, 1114, 2034, 3176, 3176, 1216],
    14101: [1923, 1826, 1124, 2056, 3207, 3207, 1226],
    14201: [1948, 1850, 1134, 2080, 3240, 3240, 1235],
    14301: [1972, 1877, 1145, 2105, 3271, 3271, 1245],
    14401: [1997, 1901, 1155, 2130, 3302, 3302, 1255],
    14501: [2022, 1926, 1165, 2155, 3332, 3332, 1264],
    14601: [2046, 1953, 1167, 2179, 3363, 3363, 1265],
    14701: [2071, 1977, 1177, 2204, 3396, 3396, 1275],
    14801: [2096, 2004, 1187, 2229, 3426, 3426, 1285],
    14901: [2121, 2028, 1197, 2253, 3457, 3457, 1294],
    15001: [2146, 2052, 1208, 2278, 3488, 3488, 1304],
    15101: [2170, 2079, 1218, 2303, 3518, 3518, 1314],
    15201: [2195, 2104, 1228, 2328, 3551, 3551, 1323],
    15301: [2220, 2130, 1230, 2352, 3582, 3582, 1324],
    15401: [2244, 2155, 1240, 2377, 3612, 3612, 1334],
    15501: [2269, 2179, 1250, 2402, 3643, 3643, 1344],
    15601: [2294, 2206, 1261, 2427, 3673, 3673, 1353],
    15701: [2319, 2230, 1271, 2451, 3706, 3706, 1363],
    15801: [2343, 2257, 1281, 2476, 3737, 3737, 1374],
    15901: [2368, 2282, 1283, 2501, 3768, 3768, 1398],
    16001: [2393, 2306, 1293, 2525, 3798, 3798, 1421],
    16101: [2417, 2333, 1303, 2550, 3829, 3829, 1445],
    16201: [2442, 2357, 1313, 2575, 3862, 3862, 1471],
    16301: [2467, 2384, 1324, 2600, 3892, 3892, 1494],
    16401: [2492, 2408, 1334, 2624, 3923, 3923, 1518],
    16501: [2516, 2433, 1336, 2649, 3954, 3954, 1542],
    16601: [2541, 2460, 1346, 2674, 3984, 3984, 1565],
    16701: [2566, 2484, 1356, 2699, 4017, 4017, 1591],
    16801: [2591, 2511, 1366, 2723, 4048, 4048, 1615],
    16901: [2615, 2535, 1377, 2748, 4078, 4078, 1638],
    17001: [2640, 2559, 1387, 2773, 4109, 4109, 1662],
    17101: [2665, 2586, 1389, 2797, 4140, 4140, 1686],
    17201: [2689, 2611, 1399, 2822, 4173, 4173, 1712],
    17301: [2714, 2637, 1409, 2847, 4203, 4203, 1735],
    17401: [2739, 2662, 1419, 2872, 4234, 4234, 1759],
    17501: [2764, 2686, 1429, 2896, 4264, 4264, 1782],
    17601: [2788, 2713, 1440, 2921, 4295, 4295, 1806],
    17701: [2813, 2737, 1450, 2946, 4328, 4328, 1832],
    17801: [2838, 2764, 1452, 2971, 4359, 4359, 1856],
    17901: [2862, 2789, 1462, 2995, 4389, 4389, 1879],
    18001: [2887, 2813, 1472, 3020, 4420, 4420, 1903],
    18101: [2912, 2840, 1482, 3045, 4450, 4450, 1926],
    18201: [2937, 2864, 1493, 3069, 4483, 4483, 1952],
    18301: [2961, 2891, 1503, 3094, 4514, 4514, 1976],
    18401: [2986, 2915, 1505, 3119, 4545, 4545, 2000],
    18501: [3011, 2940, 1515, 3144, 4575, 4575, 2023],
    18601: [3036, 2967, 1525, 3168, 4606, 4606, 2047],
    18701: [3060, 2991, 1535, 3193, 4639, 4639, 2073],
    18801: [3085, 3018, 1546, 3218, 4669, 4669, 2096],
    18901: [3110, 3042, 1556, 3243, 4700, 4700, 2120],
    19001: [3135, 3069, 1558, 3267, 4731, 4731, 2144],
    19101: [3159, 3093, 1568, 3292, 4761, 4761, 2167],
    19201: [3184, 3118, 1578, 3317, 4794, 4794, 2193],
    19301: [3209, 3144, 1588, 3342, 4825, 4825, 2217],
    19401: [3233, 3169, 1598, 3366, 4855, 4855, 2240],
    19501: [3258, 3196, 1609, 3391, 4886, 4886, 2264],
    19601: [3283, 3219, 1610, 3416, 4916, 4916, 2287],
    19701: [3308, 3243, 1619, 3440, 4949, 4949, 2313],
    19801: [3332, 3269, 1628, 3465, 4980, 4980, 2337],
    19901: [3357, 3292, 1638, 3490, 5011, 5011, 2361],
    20001: [3407, 3342, 1656, 3539, 5072, 5072, 2408],
    20201: [3456, 3391, 1667, 3589, 5136, 5136, 2458],
    20401: [3505, 3440, 1685, 3638, 5197, 5197, 2505],
    20601: [3555, 3488, 1704, 3688, 5260, 5260, 2554],
    20801: [3604, 3537, 1714, 3737, 5321, 5321, 2601],
    21001: [3654, 3586, 1733, 3787, 5383, 5383, 2649],
    21201: [3703, 3636, 1752, 3836, 5446, 5446, 2698],
    21401: [3753, 3685, 1762, 3885, 5507, 5507, 2745],
    21601: [3802, 3732, 1781, 3935, 5571, 5571, 2795],
    21801: [3852, 3782, 1799, 3984, 5632, 5632, 2842],
    22001: [3901, 3831, 1810, 4034, 5693, 5693, 2889],
    22201: [3951, 3881, 1828, 4083, 5757, 5757, 2939],
    22401: [4001, 3930, 1847, 4134, 5819, 5819, 2987],
    22601: [4052, 3977, 1866, 4185, 5884, 5884, 3038],
    22801: [4103, 4027, 1876, 4236, 5947, 5947, 3087],
    23001: [4154, 4076, 1895, 4287, 6010, 6010, 3136],
    23201: [4205, 4125, 1913, 4338, 6075, 6075, 3187],
    23401: [4256, 4175, 1924, 4389, 6138, 6138, 3236],
    23601: [4308, 4224, 1943, 4440, 6203, 6203, 3287],
    23801: [4359, 4271, 1961, 4491, 6266, 6266, 3336],
    24001: [4410, 4321, 1971, 4542, 6329, 6329, 3385],
    24201: [4461, 4373, 1990, 4594, 6394, 6394, 3436],
    24401: [4512, 4422, 2009, 4645, 6457, 6457, 3485],
    24601: [4563, 4472, 2019, 4696, 6522, 6522, 3536],
    24801: [4614, 4521, 2038, 4747, 6585, 6585, 3585],
    25001: [4665, 4573, 2057, 4798, 6648, 6648, 3648],
    25201: [4716, 4622, 2067, 4849, 6713, 6713, 3713],
    25401: [4767, 4672, 2086, 4900, 6776, 6776, 3776],
    25601: [4819, 4724, 2105, 4951, 6841, 6841, 3841],
    25801: [4870, 4773, 2115, 5002, 6904, 6904, 3904],
    26001: [4921, 4822, 2134, 5054, 6967, 6967, 3967],
    26201: [4972, 4874, 2153, 5105, 7032, 7032, 4032],
    26401: [5023, 4924, 2163, 5156, 7095, 7095, 4095],
    26601: [5074, 4973, 2182, 5207, 7160, 7160, 4160],
    26801: [5125, 5025, 2200, 5258, 7223, 7223, 4223],
    27001: [5176, 5074, 2211, 5309, 7286, 7286, 4286],
    27201: [5227, 5124, 2229, 5360, 7351, 7351, 4351],
    27401: [5279, 5175, 2248, 5411, 7414, 7414, 4414],
    27601: [5330, 5225, 2267, 5462, 7479, 7479, 4479],
    27801: [5381, 5274, 2277, 5513, 7542, 7542, 4542],
    28001: [5432, 5324, 2324, 5565, 7605, 7605, 4605],
    28201: [5483, 5376, 2376, 5616, 7670, 7670, 4670],
    28401: [5534, 5425, 2425, 5667, 7733, 7733, 4733],
    28601: [5585, 5474, 2474, 5718, 7798, 7798, 4798],
    28801: [5636, 5526, 2526, 5769, 7861, 7861, 4861],
    29001: [5687, 5576, 2576, 5820, 7924, 7924, 4924],
    29201: [5738, 5625, 2625, 5871, 7989, 7989, 4989],
    29401: [5790, 5677, 2677, 5922, 8052, 8052, 5052],
    29601: [5841, 5726, 2726, 5973, 8117, 8117, 5117],
    29801: [5892, 5776, 2776, 6025, 8180, 8180, 5180],
    30001: [5943, 5829, 2829, 6076, 8243, 8243, 5243],
    30201: [5994, 5879, 2879, 6127, 8308, 8308, 5308],
    30401: [6045, 5930, 2930, 6178, 8371, 8371, 5371],
    30601: [6096, 5981, 2981, 6229, 8436, 8436, 5436],
    30801: [6147, 6034, 3034, 6280, 8499, 8499, 5499],
    31001: [6198, 6085, 3085, 6331, 8562, 8562, 5562],
    31201: [6250, 6136, 3136, 6382, 8627, 8627, 5627],
    31401: [6301, 6189, 3189, 6433, 8690, 8690, 5690],
    31601: [6352, 6240, 3240, 6485, 8755, 8755, 5755],
    31801: [6403, 6290, 3290, 6536, 8818, 8818, 5818],
    32001: [6454, 6343, 3343, 6587, 8881, 8881, 5881],
    32201: [6505, 6394, 3394, 6638, 8946, 8946, 5946],
    32401: [6556, 6445, 3445, 6689, 9009, 9009, 6009],
    32601: [6607, 6498, 3498, 6740, 9074, 9074, 6074],
    32801: [6658, 6549, 3549, 6791, 9137, 9137, 6137],
    33001: [6709, 6600, 3600, 6842, 9200, 9200, 6200],
    33201: [6761, 6653, 3653, 6893, 9265, 9265, 6265],
    33401: [6812, 6704, 3704, 6944, 9328, 9328, 6328],
    33601: [6863, 6754, 3754, 6996, 9393, 9393, 6393],
    33801: [6914, 6805, 3805, 7047, 9456, 9456, 6456],
    34001: [6965, 6858, 3858, 7098, 9519, 9519, 6519],
    34201: [7016, 6909, 3909, 7149, 9584, 9584, 6584],
    34401: [7067, 6960, 3960, 7200, 9645, 9645, 6645],
    34601: [7118, 7010, 4010, 7251, 9703, 9703, 6703],
    34801: [7169, 7059, 4059, 7302, 9761, 9761, 6761],
    35001: [7220, 7110, 4110, 7352, 9819, 9819, 6819],
    35201: [7272, 7163, 4163, 7403, 9877, 9877, 6877],
    35401: [7330, 7221, 4221, 7454, 9935, 9935, 6935],
    35601: [7388, 7279, 4279, 7505, 9993, 9993, 6993],
    35801: [7446, 7337, 4337, 7556, 10051, 10051, 7051],
    36001: [7504, 7395, 4395, 7607, 10109, 10109, 7109],
    36201: [7562, 7453, 4453, 7658, 10167, 10167, 7167],
    36401: [7620, 7511, 4511, 7709, 10225, 10225, 7225],
    36601: [7678, 7569, 4569, 7759, 10283, 10283, 7283],
    36801: [7736, 7627, 4627, 7810, 10341, 10341, 7341],
    37001: [7794, 7685, 4685, 7861, 10399, 10399, 7399],
    37201: [7852, 7743, 4743, 7912, 10457, 10457, 7457],
    37401: [7910, 7801, 4801, 7963, 10515, 10515, 7515],
    37601: [7968, 7859, 4859, 8014, 10573, 10573, 7573],
    37801: [8026, 7917, 4917, 8065, 10631, 10631, 7631],
    38001: [8084, 7975, 4975, 8116, 10689, 10689, 7689],
    38201: [8142, 8033, 5033, 8166, 10747, 10747, 7747],
    38401: [8200, 8091, 5091, 8217, 10805, 10805, 7805],
    38601: [8258, 8149, 5149, 8268, 10863, 10863, 7863],
    38801: [8316, 8207, 5207, 8319, 10921, 10921, 7921],
    39001: [8374, 8265, 5265, 8370, 10979, 10979, 7979],
    39201: [8432, 8323, 5323, 8421, 11037, 11037, 8037],
    39401: [8490, 8381, 5381, 8472, 11095, 11095, 8095],
    39601: [8548, 8439, 5439, 8523, 11153, 11153, 8153],
    39801: [8606, 8497, 5497, 8573, 11211, 11211, 8211],
    40001: [8664, 8555, 5555, 8624, 11269, 11269, 8269],
    40201: [8722, 8613, 5613, 8675, 11327, 11327, 8327],
    40401: [8780, 8671, 5671, 8726, 11385, 11385, 8385],
    40601: [8838, 8729, 5729, 8777, 11443, 11443, 8443],
    40801: [8896, 8787, 5787, 8828, 11501, 11501, 8501],
    41001: [8954, 8845, 5845, 8879, 11559, 11559, 8559],
    41201: [9012, 8903, 5903, 8930, 11617, 11617, 8617],
    41401: [9070, 8961, 5961, 8980, 11675, 11675, 8675],
    41601: [9128, 9019, 6019, 9031, 11733, 11733, 8733],
    41801: [9186, 9077, 6077, 9082, 11791, 11791, 8791],
    42001: [9244, 9135, 6135, 9133, 11849, 11849, 8849],
    42201: [9302, 9193, 6193, 9184, 11907, 11907, 8907],
    42401: [9360, 9251, 6251, 9235, 11965, 11965, 8965],
    42601: [9418, 9309, 6309, 9286, 12023, 12023, 9023],
    42801: [9476, 9367, 6367, 9337, 12081, 12081, 9081],
    43001: [9534, 9425, 6425, 9387, 12139, 12139, 9139],
    43201: [9592, 9483, 6483, 9438, 12197, 12197, 9197],
    43401: [9650, 9541, 6541, 9489, 12255, 12255, 9255],
    43601: [9708, 9599, 6599, 9540, 12313, 12313, 9313],
    43801: [9766, 9657, 6657, 9591, 12371, 12371, 9371],
    44001: [9824, 9715, 6715, 9642, 12429, 12429, 9429],
    44201: [9882, 9773, 6773, 9693, 12487, 12487, 9487],
    44401: [9940, 9831, 6831, 9743, 12545, 12545, 9545],
    44601: [9998, 9889, 6889, 9794, 12603, 12603, 9603],
    44801: [10056, 9947, 6947, 9845, 12661, 12661, 9661],
    45001: [10114, 10005, 7005, 9896, 12719, 12719, 9719],
    45201: [10172, 10063, 7063, 9947, 12777, 12777, 9777],
    45401: [10230, 10121, 7121, 9998, 12835, 12835, 9835],
    45601: [10288, 10179, 7179, 10049, 12893, 12893, 9893],
    45801: [10346, 10237, 7237, 10100, 12951, 12951, 9951],
    46001: [10404, 10295, 7295, 10150, 13009, 13009, 10009],
    46201: [10462, 10353, 7353, 10201, 13067, 13067, 10067],
    46401: [10520, 10411, 7411, 10252, 13125, 13125, 10125],
    46601: [10578, 10469, 7469, 10303, 13183, 13183, 10183],
    46801: [10636, 10527, 7527, 10354, 13241, 13241, 10241],
    47001: [10694, 10585, 7585, 10405, 13299, 13299, 10299],
    47201: [10752, 10643, 7643, 10456, 13357, 13357, 10357],
    47401: [10810, 10701, 7701, 10507, 13415, 13415, 10415],
    47601: [10868, 10759, 7759, 10557, 13473, 13473, 10473],
    47801: [10926, 10817, 7817, 10608, 13531, 13531, 10531],
    48001: [10984, 10875, 7875, 10659, 13589, 13589, 10589],
    48201: [11042, 10933, 7933, 10710, 13647, 13647, 10647],
    48401: [11100, 10991, 7991, 10761, 13705, 13705, 10705],
    48601: [11158, 11049, 8049, 10812, 13763, 13763, 10763],
    48801: [11216, 11107, 8107, 10863, 13821, 13821, 10821],
    49001: [11274, 11165, 8165, 10914, 13879, 13879, 10879],
    49201: [11332, 11223, 8223, 10964, 13937, 13937, 10937],
    49401: [11390, 11281, 8281, 11015, 13995, 13995, 10995],
    49601: [11448, 11339, 8339, 11066, 14053, 14053, 11053],
    49801: [11506, 11397, 8397, 11117, 14111, 14111, 11111],
    50001: [11564, 11455, 8461, 11168, 14169, 14169, 11175],
    50201: [11622, 11544, 8556, 11219, 14227, 14227, 11239],
    50401: [11680, 11636, 8654, 11270, 14285, 14285, 11303],
    50601: [11738, 11730, 8754, 11321, 14343, 14343, 11367],
    50801: [11796, 11825, 8855, 11371, 14401, 14401, 11431],
    51001: [11862, 11919, 8955, 11431, 14467, 14467, 11503],
    51201: [11960, 12013, 9055, 11522, 14565, 14565, 11607],
    51401: [12058, 12107, 9155, 11612, 14663, 14663, 11711],
    51601: [12156, 12201, 9255, 11703, 14761, 14761, 11815],
    51801: [12254, 12296, 9356, 11794, 14859, 14859, 11919],
    52001: [12352, 12388, 9454, 11885, 14957, 14957, 12023],
    52201: [12450, 12482, 9554, 11976, 15055, 15055, 12127],
    52401: [12548, 12576, 9654, 12067, 15153, 15153, 12231],
    52601: [12646, 12670, 9754, 12158, 15251, 15251, 12335],
    52801: [12744, 12765, 9855, 12248, 15349, 15349, 12439],
    53001: [12842, 12859, 9955, 12339, 15447, 15447, 12543],
    53201: [12940, 12953, 10055, 12430, 15545, 15545, 12647],
    53401: [13038, 13047, 10155, 12521, 15643, 15643, 12751],
    53601: [13136, 13139, 10253, 12612, 15741, 15741, 12855],
    53801: [13234, 13233, 10353, 12703, 15839, 15839, 12959],
    54001: [13332, 13328, 10454, 12794, 15937, 15937, 13063],
    54201: [13430, 13422, 10554, 12885, 16035, 16035, 13167],
    54401: [13528, 13516, 10654, 12975, 16133, 16133, 13271],
    54601: [13626, 13610, 10754, 13066, 16231, 16231, 13375],
    54801: [13724, 13705, 10855, 13157, 16329, 16329, 13479],
    55001: [13822, 13799, 10955, 13248, 16427, 16427, 13583],
    55201: [13920, 13893, 11055, 13339, 16525, 16525, 13687],
    55401: [14018, 13985, 11153, 13430, 16623, 16623, 13791],
    55601: [14116, 14079, 11253, 13521, 16721, 16721, 13895],
    55801: [14214, 14173, 11353, 13612, 16819, 16819, 13999],
    56001: [14312, 14234, 11420, 13702, 16917, 16917, 14103],
    56201: [14410, 14292, 11484, 13793, 17015, 17015, 14207],
    56401: [14508, 14350, 11548, 13884, 17113, 17113, 14311],
    56601: [14606, 14408, 11612, 13975, 17211, 17211, 14415],
    56801: [14704, 14479, 11689, 14066, 17309, 17309, 14519],
    57001: [14802, 14577, 11793, 14157, 17407, 17407, 14623],
    57201: [14900, 14675, 11897, 14248, 17505, 17505, 14727],
    57401: [14998, 14773, 12001, 14339, 17603, 17603, 14831],
    57601: [15096, 14871, 12105, 14429, 17701, 17701, 14935],
    57801: [15194, 14969, 12209, 14520, 17799, 17799, 15039],
    58001: [15292, 15067, 12313, 14611, 17897, 17897, 15143],
    58201: [15390, 15165, 12417, 14702, 17995, 17995, 15247],
    58401: [15488, 15263, 12521, 14793, 18093, 18093, 15351],
    58601: [15586, 15361, 12625, 14884, 18191, 18191, 15455],
    58801: [15684, 15459, 12729, 14975, 18289, 18289, 15559],
    59001: [15782, 15557, 12833, 15066, 18387, 18387, 15663],
    59201: [15885, 15659, 12941, 15156, 18485, 18485, 15767],
    59401: [15989, 15761, 13049, 15247, 18583, 18583, 15871],
    59601: [16093, 15868, 13162, 15338, 18681, 18681, 15975],
    59801: [16197, 15970, 13270, 15429, 18779, 18779, 16079],
    60001: [16301, 16072, 13378, 15520, 18877, 18877, 16183],
    60201: [16405, 16178, 13490, 15611, 18975, 18975, 16287],
    60401: [16509, 16280, 13598, 15702, 19073, 19073, 16391],
    60601: [16613, 16386, 13710, 15793, 19171, 19171, 16495],
    60801: [16717, 16488, 13818, 15883, 19269, 19269, 16599],
    61001: [16821, 16590, 13926, 15974, 19367, 19367, 16703],
    61201: [16925, 16696, 14038, 16065, 19465, 19465, 16807],
    61401: [17029, 16799, 14147, 16156, 19563, 19563, 16911],
    61601: [17133, 16901, 14255, 16247, 19661, 19661, 17015],
    61801: [17237, 17007, 14367, 16338, 19759, 19759, 17119],
    62001: [17341, 17109, 14475, 16429, 19857, 19857, 17223],
    62201: [17445, 17215, 14587, 16520, 19955, 19955, 17327],
    62401: [17549, 17317, 14695, 16610, 20053, 20053, 17431],
    62601: [17653, 17419, 14803, 16701, 20151, 20151, 17535],
    62801: [17757, 17525, 14915, 16792, 20249, 20249, 17639],
    63001: [17861, 17627, 15023, 16883, 20347, 20347, 17743],
    63201: [17965, 17730, 15132, 16974, 20445, 20445, 17847],
    63401: [18069, 17836, 15244, 17065, 20543, 20543, 17951],
    63601: [18173, 17938, 15352, 17156, 20641, 20641, 18055],
    63801: [18277, 18044, 15464, 17247, 20739, 20739, 18159],
    64001: [18381, 18146, 15572, 17337, 20837, 20837, 18263],
    64201: [18485, 18248, 15680, 17428, 20935, 20935, 18367],
    64401: [18589, 18354, 15792, 17519, 21033, 21033, 18471],
    64601: [18693, 18456, 15900, 17610, 21131, 21131, 18575],
    64801: [18797, 18558, 16008, 17701, 21229, 21229, 18679],
    65001: [18901, 18665, 16121, 17792, 21327, 21327, 18783],
    65201: [19005, 18767, 16229, 17883, 21425, 21425, 18887],
    65401: [19109, 18873, 16341, 17973, 21523, 21523, 18991],
    65601: [19213, 18975, 16449, 18064, 21621, 21621, 19095],
    65801: [19317, 19077, 16557, 18155, 21719, 21719, 19199],
    66001: [19421, 19183, 16669, 18246, 21817, 21817, 19303],
    66201: [19525, 19285, 16777, 18337, 21915, 21915, 19407],
    66401: [19629, 19387, 16885, 18428, 22013, 22013, 19511],
    66601: [19733, 19494, 16998, 18519, 22111, 22111, 19615],
    66801: [19837, 19596, 17106, 18610, 22209, 22209, 19719],
    67001: [19941, 19702, 17218, 18700, 22307, 22307, 19823],
    67201: [20045, 19804, 17326, 18791, 22405, 22405, 19927],
    67401: [20149, 19906, 17434, 18882, 22503, 22503, 20031],
    67601: [20253, 20012, 17546, 18973, 22601, 22601, 20135],
    67801: [20357, 20114, 17654, 19064, 22699, 22699, 20239],
    68001: [20461, 20220, 17766, 19155, 22797, 22797, 20343],
    68201: [20565, 20322, 17874, 19246, 22895, 22895, 20447],
    68401: [20669, 20425, 17983, 19337, 22993, 22993, 20551],
    68601: [20773, 20531, 18095, 19427, 23091, 23091, 20655],
    68801: [20877, 20633, 18203, 19518, 23189, 23189, 20759],
    69001: [20981, 20735, 18311, 19609, 23287, 23287, 20863],
    69201: [21085, 20841, 18423, 19700, 23385, 23385, 20967],
    69401: [21189, 20943, 18531, 19791, 23483, 23483, 21071],
    69601: [21293, 21049, 18643, 19882, 23581, 23581, 21175],
    69801: [21397, 21151, 18751, 19973, 23679, 23679, 21279],
    70001: [21501, 21253, 18859, 20064, 23777, 23777, 21383],
    70201: [21605, 21360, 18972, 20154, 23875, 23875, 21487],
    70401: [21709, 21462, 19080, 20245, 23973, 23973, 21591],
    70601: [21813, 21564, 19188, 20336, 24071, 24071, 21695],
    70801: [21917, 21670, 19300, 20427, 24169, 24169, 21799],
    71001: [22021, 21772, 19408, 20518, 24267, 24267, 21903],
    71201: [22125, 21878, 19520, 20609, 24365, 24365, 22007],
    71401: [22229, 21980, 19628, 20700, 24463, 24463, 22111],
    71601: [22333, 22082, 19736, 20791, 24561, 24561, 22215],
    71801: [22437, 22189, 19849, 20881, 24659, 24659, 22319],
    72001: [22541, 22291, 19957, 20972, 24757, 24757, 22423],
    72201: [22645, 22393, 20065, 21063, 24855, 24855, 22527],
    72401: [22749, 22499, 20177, 21154, 24953, 24953, 22631],
    72601: [22853, 22601, 20285, 21245, 25051, 25051, 22735],
    72801: [22957, 22707, 20397, 21336, 25149, 25149, 22839],
    73001: [23061, 22809, 20505, 21427, 25247, 25247, 22943],
    73201: [23165, 22911, 20613, 21518, 25345, 25345, 23047],
    73401: [23269, 23017, 20725, 21608, 25443, 25443, 23151],
    73601: [23373, 23120, 20834, 21699, 25541, 25541, 23255],
    73801: [23477, 23222, 20942, 21790, 25639, 25639, 23359],
    74001: [23581, 23328, 21054, 21881, 25737, 25737, 23463],
    74201: [23685, 23430, 21162, 21972, 25835, 25835, 23567],
    74401: [23789, 23536, 21274, 22063, 25933, 25933, 23671],
    74601: [23893, 23638, 21382, 22154, 26031, 26031, 23775],
    74801: [23997, 23740, 21490, 22245, 26129, 26129, 23879],
    75001: [24101, 23846, 21602, 22335, 26227, 26227, 23983],
    75201: [24205, 23948, 21710, 22426, 26325, 26325, 24087],
    75401: [24309, 24051, 21819, 22517, 26423, 26423, 24191],
    75601: [24413, 24157, 21931, 22608, 26521, 26521, 24295],
    75801: [24517, 24259, 22039, 22699, 26619, 26619, 24399],
    76001: [24621, 24365, 22151, 22790, 26717, 26717, 24503],
    76201: [24725, 24467, 22259, 22881, 26815, 26815, 24607],
    76401: [24829, 24569, 22367, 22971, 26913, 26913, 24711],
    76601: [24933, 24675, 22479, 23062, 27011, 27011, 24815],
    76801: [25037, 24777, 22587, 23153, 27109, 27109, 24919],
    77001: [25141, 24884, 22700, 23244, 27207, 27207, 25023],
    77201: [25245, 24986, 22808, 23335, 27305, 27305, 25127],
    77401: [25349, 25088, 22916, 23426, 27403, 27403, 25231],
    77601: [25453, 25194, 23028, 23517, 27501, 27501, 25335],
    77801: [25557, 25296, 23136, 23608, 27599, 27599, 25439],
    78001: [25661, 25398, 23244, 23698, 27697, 27697, 25543],
    78201: [25765, 25504, 23356, 23789, 27795, 27795, 25647],
    78401: [25869, 25606, 23464, 23880, 27893, 27893, 25751],
    78601: [25973, 25712, 23576, 23971, 27991, 27991, 25855],
    78801: [26077, 25815, 23685, 24062, 28089, 28089, 25959],
    79001: [26181, 25917, 23793, 24153, 28187, 28187, 26063],
    79201: [26285, 26023, 23905, 24244, 28285, 28285, 26167],
    79401: [26389, 26125, 24013, 24335, 28383, 28383, 26271],
    79601: [26493, 26227, 24121, 24425, 28481, 28481, 26375],
    79801: [26597, 26333, 24233, 24516, 28579, 28579, 26479],
    80001: [33, 33, 30, 31, 36, 36, 33, '%'],
    80601: [33, 33, 31, 31, 36, 36, 33, '%'],
    81001: [34, 33, 31, 31, 36, 36, 33, '%'],
    81601: [34, 33, 31, 31, 36, 36, 34, '%'],
    82401: [34, 34, 31, 31, 36, 36, 34, '%'],
    84001: [34, 34, 32, 31, 36, 36, 34, '%'],
    84801: [34, 34, 32, 32, 37, 37, 34, '%'],
    85601: [35, 34, 32, 32, 37, 37, 34, '%'],
    86401: [35, 34, 32, 32, 37, 37, 35, '%'],
    87201: [35, 35, 32, 32, 37, 37, 35, '%'],
    87801: [35, 35, 33, 32, 37, 37, 35, '%'],
    90801: [36, 35, 33, 32, 37, 37, 35, '%'],
    91401: [36, 35, 33, 33, 37, 37, 35, '%'],
    91601: [36, 35, 33, 33, 37, 37, 36, '%'],
    92001: [36, 35, 34, 33, 37, 37, 36, '%'],
    92201: [36, 35, 34, 33, 38, 38, 36, '%'],
    92601: [36, 36, 34, 33, 38, 38, 36, '%'],
    96401: [36, 36, 35, 33, 38, 38, 36, '%'],
    96601: [37, 36, 35, 33, 38, 38, 36, '%'],
    97401: [37, 36, 35, 33, 38, 38, 37, '%'],
    98601: [37, 37, 35, 33, 38, 38, 37, '%'],
    99001: [37, 37, 35, 34, 38, 38, 37, '%'],
    101001: [37, 37, 35, 34, 39, 39, 37, '%'],
    101401: [37, 37, 36, 34, 39, 39, 37, '%'],
    103401: [38, 37, 36, 34, 39, 39, 37, '%'],
    104201: [38, 37, 36, 34, 39, 39, 38, '%'],
    105601: [38, 38, 36, 34, 39, 39, 38, '%'],
    107001: [38, 38, 37, 34, 39, 39, 38, '%'],
    108001: [38, 38, 37, 35, 39, 39, 38, '%'],
    111001: [39, 38, 37, 35, 39, 39, 38, '%'],
    111601: [39, 38, 37, 35, 40, 40, 38, '%'],
    112001: [39, 38, 37, 35, 40, 40, 39, '%'],
    113201: [39, 38, 38, 35, 40, 40, 39, '%'],
    113401: [39, 39, 38, 35, 40, 40, 39, '%'],
    119001: [39, 39, 38, 36, 40, 40, 39, '%'],
    120001: [40, 39, 38, 36, 40, 40, 39, '%'],
    120201: [40, 39, 39, 36, 40, 40, 39, '%'],
    120801: [40, 39, 39, 36, 40, 40, 40, '%'],
    122601: [40, 40, 39, 36, 40, 40, 40, '%'],
    124801: [40, 40, 39, 36, 41, 41, 40, '%'],
    128001: [40, 40, 40, 36, 41, 41, 40, '%'],
    130401: [41, 40, 40, 36, 41, 41, 40, '%'],
    131401: [41, 40, 40, 36, 41, 41, 41, '%'],
    132401: [41, 40, 40, 37, 41, 41, 41, '%'],
    133601: [41, 41, 40, 37, 41, 41, 41, '%'],
    137001: [41, 41, 41, 37, 41, 41, 41, '%'],
    141601: [41, 41, 41, 37, 42, 42, 41, '%'],
    142801: [42, 41, 41, 37, 42, 42, 41, '%'],
    144001: [42, 41, 41, 37, 42, 42, 42, '%'],
    146401: [42, 42, 41, 37, 42, 42, 42, '%'],
    147201: [42, 42, 42, 37, 42, 42, 42, '%'],
    149001: [42, 42, 42, 38, 42, 42, 42, '%'],
    163201: [43, 43, 43, 38, 43, 43, 43, '%'],
    170401: [43, 43, 43, 39, 43, 43, 43, '%'],
    193001: [44, 44, 44, 39, 44, 44, 44, '%'],
    199201: [44, 44, 44, 40, 44, 44, 44, '%'],
    236001: [45, 45, 45, 40, 45, 45, 45, '%'],
    239601: [45, 45, 45, 41, 45, 45, 45, '%'],
    300401: [45, 45, 45, 42, 45, 45, 45, '%'],
    303401: [46, 46, 46, 42, 46, 46, 46, '%'],
    402801: [46, 46, 46, 43, 46, 46, 46, '%'],
    424801: [47, 47, 47, 43, 47, 47, 47, '%'],
    611001: [47, 47, 47, 44, 47, 47, 47, '%'],
    708001: [48, 48, 48, 44, 48, 48, 48, '%'],
    1263401: [48, 48, 48, 45, 48, 48, 48, '%']
  },
  30: {
    1: [0, 0, 0, 0, 0, 0, 0],
    1801: [134, 0, 133, 0, 134, 12, 134],
    1901: [144, 0, 141, 3, 144, 43, 144],
    2001: [155, 0, 150, 5, 155, 74, 155],
    2101: [157, 0, 150, 7, 157, 105, 157],
    2201: [167, 0, 158, 9, 167, 136, 167],
    2301: [178, 0, 166, 11, 178, 167, 178],
    2401: [188, 0, 175, 13, 198, 198, 188],
    2501: [199, 0, 183, 16, 229, 229, 199],
    2601: [209, 0, 191, 18, 260, 260, 209],
    2701: [220, 0, 200, 20, 291, 291, 220],
    2801: [222, 0, 200, 22, 322, 322, 222],
    2901: [232, 0, 208, 24, 353, 353, 232],
    3001: [243, 0, 216, 26, 384, 384, 243],
    3101: [253, 0, 225, 28, 415, 415, 253],
    3201: [264, 0, 233, 31, 446, 446, 264],
    3301: [274, 0, 241, 33, 477, 477, 274],
    3401: [277, 0, 241, 35, 508, 508, 277],
    3501: [287, 0, 250, 37, 539, 539, 287],
    3601: [298, 0, 258, 39, 570, 570, 298],
    3701: [308, 0, 266, 41, 601, 601, 308],
    3801: [319, 0, 275, 44, 632, 632, 319],
    3901: [329, 0, 283, 49, 663, 663, 329],
    4001: [331, 0, 283, 71, 694, 694, 331],
    4101: [342, 0, 291, 92, 725, 725, 342],
    4201: [352, 0, 300, 113, 756, 756, 352],
    4301: [363, 0, 308, 134, 785, 785, 363],
    4401: [373, 0, 316, 155, 808, 808, 373],
    4501: [383, 0, 325, 175, 834, 834, 383],
    4601: [384, 0, 325, 196, 860, 860, 384],
    4701: [394, 0, 333, 217, 883, 883, 394],
    4801: [405, 7, 342, 238, 909, 909, 405],
    4901: [414, 30, 352, 258, 932, 932, 414],
    5001: [425, 54, 362, 279, 958, 958, 425],
    5101: [435, 77, 372, 300, 984, 984, 435],
    5201: [445, 100, 382, 321, 1007, 1007, 445],
    5301: [447, 123, 383, 341, 1033, 1033, 447],
    5401: [456, 147, 393, 361, 1056, 1056, 456],
    5501: [467, 170, 403, 382, 1081, 1081, 467],
    5601: [477, 193, 413, 402, 1106, 1106, 477],
    5701: [487, 216, 423, 422, 1129, 1129, 487],
    5801: [497, 237, 433, 442, 1154, 1154, 497],
    5901: [498, 260, 434, 462, 1177, 1177, 498],
    6001: [509, 284, 444, 482, 1202, 1202, 509],
    6101: [519, 307, 454, 503, 1227, 1227, 519],
    6201: [529, 330, 464, 523, 1250, 1250, 529],
    6301: [539, 353, 474, 543, 1275, 1275, 539],
    6401: [549, 377, 484, 563, 1298, 1298, 549],
    6501: [551, 400, 486, 583, 1323, 1323, 551],
    6601: [567, 423, 496, 603, 1348, 1348, 561],
    6701: [585, 446, 506, 623, 1371, 1371, 571],
    6801: [604, 470, 516, 644, 1396, 1396, 581],
    6901: [623, 490, 525, 664, 1419, 1419, 591],
    7001: [642, 514, 535, 684, 1444, 1444, 601],
    7101: [661, 537, 537, 704, 1469, 1469, 603],
    7201: [679, 560, 547, 724, 1492, 1492, 613],
    7301: [698, 583, 557, 744, 1517, 1517, 623],
    7401: [717, 607, 567, 764, 1540, 1540, 633],
    7501: [736, 630, 577, 785, 1565, 1565, 643],
    7601: [755, 653, 587, 805, 1590, 1590, 653],
    7701: [773, 676, 597, 825, 1613, 1613, 663],
    7801: [792, 700, 598, 845, 1638, 1638, 665],
    7901: [811, 723, 608, 865, 1661, 1661, 675],
    8001: [830, 746, 618, 885, 1686, 1686, 685],
    8101: [849, 767, 628, 906, 1711, 1711, 695],
    8201: [867, 790, 638, 926, 1734, 1734, 705],
    8301: [886, 813, 648, 946, 1759, 1759, 715],
    8401: [905, 836, 649, 966, 1782, 1782, 717],
    8501: [924, 855, 659, 986, 1807, 1807, 727],
    8601: [942, 873, 669, 1006, 1832, 1832, 737],
    8701: [961, 893, 678, 1026, 1855, 1855, 747],
    8801: [980, 911, 688, 1047, 1880, 1880, 757],
    8901: [999, 929, 698, 1067, 1903, 1903, 767],
    9001: [1018, 948, 699, 1087, 1928, 1928, 769],
    9101: [1036, 968, 709, 1107, 1953, 1953, 779],
    9201: [1055, 986, 718, 1127, 1976, 1976, 789],
    9301: [1074, 1004, 728, 1147, 2001, 2001, 799],
    9401: [1093, 1022, 737, 1167, 2024, 2024, 809],
    9501: [1112, 1043, 747, 1188, 2049, 2049, 819],
    9601: [1130, 1061, 748, 1208, 2074, 2074, 821],
    9701: [1149, 1078, 758, 1228, 2097, 2097, 831],
    9801: [1168, 1096, 768, 1248, 2122, 2122, 841],
    9901: [1187, 1116, 777, 1268, 2145, 2145, 851],
    10001: [1206, 1134, 787, 1288, 2170, 2170, 861],
    10101: [1224, 1151, 797, 1309, 2195, 2195, 871],
    10201: [1243, 1169, 806, 1329, 2218, 2218, 881],
    10301: [1262, 1189, 808, 1349, 2243, 2243, 883],
    10401: [1281, 1207, 817, 1369, 2266, 2266, 893],
    10501: [1300, 1224, 827, 1389, 2291, 2291, 903],
    10601: [1318, 1242, 836, 1409, 2316, 2316, 913],
    10701: [1337, 1262, 846, 1429, 2339, 2339, 923],
    10801: [1356, 1280, 856, 1449, 2364, 2364, 933],
    10901: [1375, 1298, 857, 1470, 2387, 2387, 935],
    11001: [1393, 1318, 867, 1490, 2412, 2412, 945],
    11101: [1412, 1335, 876, 1510, 2437, 2437, 955],
    11201: [1431, 1353, 886, 1530, 2460, 2460, 965],
    11301: [1450, 1371, 896, 1550, 2485, 2485, 975],
    11401: [1469, 1391, 905, 1570, 2508, 2508, 985],
    11501: [1487, 1408, 907, 1591, 2533, 2533, 987],
    11601: [1506, 1426, 916, 1611, 2558, 2558, 997],
    11701: [1525, 1444, 926, 1631, 2581, 2581, 1007],
    11801: [1544, 1464, 936, 1651, 2606, 2606, 1017],
    11901: [1563, 1482, 945, 1671, 2634, 2634, 1027],
    12001: [1582, 1499, 955, 1692, 2664, 2664, 1038],
    12101: [1601, 1517, 956, 1712, 2694, 2694, 1040],
    12201: [1620, 1537, 966, 1733, 2724, 2724, 1051],
    12301: [1639, 1555, 975, 1753, 2755, 2755, 1061],
    12401: [1658, 1572, 985, 1773, 2785, 2785, 1072],
    12501: [1677, 1590, 995, 1794, 2815, 2815, 1082],
    12601: [1696, 1610, 1004, 1814, 2845, 2845, 1093],
    12701: [1715, 1628, 1014, 1835, 2876, 2876, 1103],
    12801: [1735, 1645, 1015, 1855, 2906, 2906, 1105],
    12901: [1754, 1666, 1025, 1876, 2936, 2936, 1116],
    13001: [1773, 1683, 1035, 1896, 2966, 2966, 1126],
    13101: [1792, 1701, 1044, 1916, 2997, 2997, 1137],
    13201: [1811, 1718, 1054, 1937, 3027, 3027, 1147],
    13301: [1830, 1739, 1064, 1957, 3057, 3057, 1158],
    13401: [1849, 1756, 1065, 1978, 3087, 3087, 1160],
    13501: [1868, 1774, 1074, 1998, 3118, 3118, 1170],
    13601: [1887, 1792, 1084, 2019, 3150, 3150, 1181],
    13701: [1907, 1812, 1094, 2039, 3186, 3186, 1192],
    13801: [1926, 1829, 1103, 2060, 3218, 3218, 1203],
    13901: [1945, 1847, 1113, 2080, 3251, 3251, 1213],
    14001: [1964, 1865, 1114, 2101, 3284, 3284, 1216],
    14101: [1986, 1887, 1124, 2123, 3316, 3316, 1226],
    14201: [2011, 1913, 1134, 2149, 3350, 3350, 1235],
    14301: [2037, 1940, 1145, 2174, 3382, 3382, 1245],
    14401: [2062, 1966, 1155, 2200, 3414, 3414, 1255],
    14501: [2088, 1991, 1165, 2225, 3445, 3445, 1264],
    14601: [2114, 2018, 1167, 2251, 3477, 3477, 1265],
    14701: [2139, 2044, 1177, 2277, 3511, 3511, 1275],
    14801: [2165, 2071, 1187, 2302, 3543, 3543, 1285],
    14901: [2190, 2097, 1197, 2328, 3575, 3575, 1294],
    15001: [2216, 2122, 1208, 2354, 3606, 3606, 1304],
    15101: [2242, 2150, 1218, 2379, 3638, 3638, 1324],
    15201: [2267, 2175, 1228, 2405, 3672, 3672, 1351],
    15301: [2293, 2202, 1230, 2430, 3704, 3704, 1376],
    15401: [2318, 2228, 1240, 2456, 3735, 3735, 1400],
    15501: [2344, 2253, 1250, 2481, 3767, 3767, 1425],
    15601: [2370, 2281, 1261, 2507, 3799, 3799, 1450],
    15701: [2395, 2306, 1271, 2533, 3833, 3833, 1477],
    15801: [2421, 2334, 1281, 2558, 3865, 3865, 1502],
    15901: [2446, 2359, 1283, 2584, 3896, 3896, 1526],
    16001: [2472, 2384, 1293, 2610, 3928, 3928, 1551],
    16101: [2498, 2412, 1303, 2635, 3960, 3960, 1576],
    16201: [2523, 2437, 1313, 2661, 3994, 3994, 1603],
    16301: [2549, 2465, 1324, 2686, 4026, 4026, 1628],
    16401: [2574, 2490, 1334, 2712, 4057, 4057, 1652],
    16501: [2600, 2515, 1336, 2737, 4089, 4089, 1677],
    16601: [2626, 2543, 1346, 2763, 4121, 4121, 1702],
    16701: [2651, 2568, 1356, 2789, 4155, 4155, 1729],
    16801: [2677, 2596, 1366, 2814, 4186, 4186, 1753],
    16901: [2702, 2621, 1377, 2840, 4218, 4218, 1778],
    17001: [2728, 2646, 1387, 2866, 4250, 4250, 1803],
    17101: [2754, 2674, 1389, 2891, 4281, 4281, 1827],
    17201: [2779, 2699, 1399, 2917, 4316, 4316, 1855],
    17301: [2805, 2727, 1409, 2942, 4347, 4347, 1879],
    17401: [2830, 2752, 1419, 2968, 4379, 4379, 1904],
    17501: [2856, 2777, 1429, 2993, 4411, 4411, 1929],
    17601: [2882, 2805, 1440, 3019, 4442, 4442, 1953],
    17701: [2907, 2830, 1450, 3045, 4476, 4476, 1980],
    17801: [2933, 2858, 1452, 3070, 4508, 4508, 2005],
    17901: [2958, 2883, 1462, 3096, 4540, 4540, 2030],
    18001: [2984, 2908, 1472, 3122, 4572, 4572, 2055],
    18101: [3010, 2936, 1482, 3147, 4603, 4603, 2079],
    18201: [3035, 2961, 1493, 3173, 4637, 4637, 2106],
    18301: [3061, 2989, 1503, 3198, 4669, 4669, 2131],
    18401: [3086, 3014, 1505, 3224, 4701, 4701, 2156],
    18501: [3112, 3039, 1515, 3249, 4732, 4732, 2180],
    18601: [3138, 3067, 1525, 3275, 4764, 4764, 2205],
    18701: [3163, 3092, 1535, 3301, 4798, 4798, 2232],
    18801: [3189, 3120, 1546, 3326, 4830, 4830, 2257],
    18901: [3214, 3145, 1556, 3352, 4862, 4862, 2282],
    19001: [3240, 3173, 1558, 3377, 4893, 4893, 2306],
    19101: [3266, 3198, 1568, 3403, 4925, 4925, 2331],
    19201: [3291, 3223, 1578, 3429, 4959, 4959, 2358],
    19301: [3317, 3251, 1588, 3454, 4991, 4991, 2383],
    19401: [3342, 3276, 1598, 3480, 5022, 5022, 2407],
    19501: [3368, 3304, 1609, 3505, 5054, 5054, 2432],
    19601: [3393, 3328, 1610, 3531, 5086, 5086, 2457],
    19701: [3419, 3353, 1619, 3557, 5120, 5120, 2484],
    19801: [3445, 3380, 1628, 3582, 5152, 5152, 2509],
    19901: [3470, 3404, 1638, 3608, 5183, 5183, 2533],
    20001: [3522, 3455, 1656, 3659, 5247, 5247, 2583],
    20201: [3573, 3506, 1667, 3710, 5313, 5313, 2635],
    20401: [3624, 3558, 1685, 3761, 5376, 5376, 2684],
    20601: [3675, 3606, 1704, 3813, 5442, 5442, 2736],
    20801: [3726, 3657, 1714, 3864, 5505, 5505, 2785],
    21001: [3778, 3709, 1733, 3915, 5568, 5568, 2834],
    21201: [3829, 3760, 1752, 3966, 5634, 5634, 2886],
    21401: [3880, 3811, 1762, 4017, 5698, 5698, 2936],
    21601: [3931, 3860, 1781, 4069, 5763, 5763, 2987],
    21801: [3982, 3911, 1799, 4120, 5827, 5827, 3037],
    22001: [4034, 3962, 1810, 4171, 5890, 5890, 3086],
    22201: [4085, 4013, 1828, 4222, 5956, 5956, 3138],
    22401: [4137, 4065, 1847, 4274, 6020, 6020, 3188],
    22601: [4190, 4113, 1866, 4327, 6088, 6088, 3242],
    22801: [4243, 4164, 1876, 4380, 6153, 6153, 3293],
    23001: [4295, 4216, 1895, 4433, 6218, 6218, 3344],
    23201: [4348, 4267, 1913, 4486, 6285, 6285, 3397],
    23401: [4401, 4318, 1924, 4539, 6350, 6350, 3448],
    23601: [4454, 4369, 1943, 4592, 6418, 6418, 3502],
    23801: [4507, 4418, 1961, 4644, 6483, 6483, 3553],
    24001: [4560, 4469, 1971, 4697, 6548, 6548, 3604],
    24201: [4613, 4523, 1990, 4750, 6615, 6615, 3657],
    24401: [4665, 4574, 2009, 4803, 6680, 6680, 3708],
    24601: [4718, 4625, 2019, 4856, 6748, 6748, 3762],
    24801: [4771, 4676, 2038, 4909, 6813, 6813, 3813],
    25001: [4824, 4730, 2057, 4961, 6878, 6878, 3878],
    25201: [4877, 4781, 2067, 5014, 6945, 6945, 3945],
    25401: [4930, 4832, 2086, 5067, 7010, 7010, 4010],
    25601: [4982, 4886, 2105, 5120, 7078, 7078, 4078],
    25801: [5035, 4937, 2115, 5173, 7143, 7143, 4143],
    26001: [5088, 4988, 2134, 5226, 7208, 7208, 4208],
    26201: [5141, 5042, 2153, 5279, 7275, 7275, 4275],
    26401: [5194, 5093, 2163, 5331, 7340, 7340, 4340],
    26601: [5247, 5144, 2182, 5384, 7408, 7408, 4408],
    26801: [5300, 5198, 2200, 5437, 7473, 7473, 4473],
    27001: [5352, 5249, 2249, 5490, 7538, 7538, 4538],
    27201: [5405, 5300, 2300, 5543, 7605, 7605, 4605],
    27401: [5458, 5354, 2354, 5596, 7670, 7670, 4670],
    27601: [5511, 5405, 2405, 5648, 7738, 7738, 4738],
    27801: [5564, 5456, 2456, 5701, 7803, 7803, 4803],
    28001: [5617, 5508, 2508, 5754, 7868, 7868, 4868],
    28201: [5669, 5561, 2561, 5807, 7935, 7935, 4935],
    28401: [5722, 5612, 2612, 5860, 8000, 8000, 5000],
    28601: [5775, 5664, 2664, 5913, 8068, 8068, 5068],
    28801: [5828, 5717, 2717, 5966, 8133, 8133, 5133],
    29001: [5881, 5768, 2768, 6018, 8198, 8198, 5198],
    29201: [5934, 5820, 2820, 6071, 8265, 8265, 5265],
    29401: [5987, 5873, 2873, 6124, 8330, 8330, 5330],
    29601: [6039, 5924, 2924, 6177, 8398, 8398, 5398],
    29801: [6092, 5976, 2976, 6230, 8463, 8463, 5463],
    30001: [6145, 6030, 3030, 6283, 8528, 8528, 5528],
    30201: [6198, 6083, 3083, 6336, 8595, 8595, 5595],
    30401: [6251, 6135, 3135, 6388, 8660, 8660, 5660],
    30601: [6304, 6188, 3188, 6441, 8728, 8728, 5728],
    30801: [6356, 6243, 3243, 6494, 8793, 8793, 5793],
    31001: [6409, 6295, 3295, 6547, 8858, 8858, 5858],
    31201: [6462, 6348, 3348, 6600, 8925, 8925, 5925],
    31401: [6515, 6403, 3403, 6653, 8990, 8990, 5990],
    31601: [6568, 6455, 3455, 6705, 9058, 9058, 6058],
    31801: [6621, 6508, 3508, 6758, 9123, 9123, 6123],
    32001: [6674, 6563, 3563, 6811, 9188, 9188, 6188],
    32201: [6726, 6615, 3615, 6864, 9255, 9255, 6255],
    32401: [6779, 6668, 3668, 6917, 9320, 9320, 6320],
    32601: [6832, 6723, 3723, 6970, 9388, 9388, 6388],
    32801: [6885, 6775, 3775, 7023, 9453, 9453, 6453],
    33001: [6938, 6828, 3828, 7075, 9518, 9518, 6518],
    33201: [6991, 6883, 3883, 7128, 9585, 9585, 6585],
    33401: [7044, 6935, 3935, 7181, 9650, 9650, 6650],
    33601: [7096, 6988, 3988, 7234, 9718, 9718, 6718],
    33801: [7149, 7040, 4040, 7287, 9783, 9783, 6783],
    34001: [7202, 7095, 4095, 7340, 9848, 9848, 6848],
    34201: [7255, 7148, 4148, 7392, 9915, 9915, 6915],
    34401: [7308, 7200, 4200, 7445, 9978, 9978, 6978],
    34601: [7360, 7253, 4253, 7498, 10038, 10038, 7038],
    34801: [7413, 7303, 4303, 7550, 10098, 10098, 7098],
    35001: [7466, 7355, 4355, 7603, 10158, 10158, 7158],
    35201: [7519, 7410, 4410, 7656, 10218, 10218, 7218],
    35401: [7579, 7470, 4470, 7708, 10278, 10278, 7278],
    35601: [7639, 7530, 4530, 7761, 10338, 10338, 7338],
    35801: [7699, 7590, 4590, 7814, 10398, 10398, 7398],
    36001: [7759, 7650, 4650, 7866, 10458, 10458, 7458],
    36201: [7819, 7710, 4710, 7919, 10518, 10518, 7518],
    36401: [7879, 7770, 4770, 7971, 10578, 10578, 7578],
    36601: [7939, 7830, 4830, 8024, 10638, 10638, 7638],
    36801: [7999, 7890, 4890, 8077, 10698, 10698, 7698],
    37001: [8059, 7950, 4950, 8129, 10758, 10758, 7758],
    37201: [8119, 8010, 5010, 8182, 10818, 10818, 7818],
    37401: [8179, 8070, 5070, 8234, 10878, 10878, 7878],
    37601: [8239, 8130, 5130, 8287, 10938, 10938, 7938],
    37801: [8299, 8190, 5190, 8340, 10998, 10998, 7998],
    38001: [8359, 8250, 5250, 8392, 11058, 11058, 8058],
    38201: [8419, 8310, 5310, 8445, 11118, 11118, 8118],
    38401: [8479, 8370, 5370, 8498, 11178, 11178, 8178],
    38601: [8539, 8430, 5430, 8550, 11238, 11238, 8238],
    38801: [8599, 8490, 5490, 8603, 11298, 11298, 8298],
    39001: [8659, 8550, 5550, 8655, 11358, 11358, 8358],
    39201: [8719, 8610, 5610, 8708, 11418, 11418, 8418],
    39401: [8779, 8670, 5670, 8761, 11478, 11478, 8478],
    39601: [8839, 8730, 5730, 8813, 11538, 11538, 8538],
    39801: [8899, 8790, 5790, 8866, 11598, 11598, 8598],
    40001: [8959, 8850, 5850, 8918, 11658, 11658, 8658],
    40201: [9019, 8910, 5910, 8971, 11718, 11718, 8718],
    40401: [9079, 8970, 5970, 9024, 11778, 11778, 8778],
    40601: [9139, 9030, 6030, 9076, 11838, 11838, 8838],
    40801: [9199, 9090, 6090, 9129, 11898, 11898, 8898],
    41001: [9259, 9150, 6150, 9182, 11958, 11958, 8958],
    41201: [9319, 9210, 6210, 9234, 12018, 12018, 9018],
    41401: [9379, 9270, 6270, 9287, 12078, 12078, 9078],
    41601: [9439, 9330, 6330, 9339, 12138, 12138, 9138],
    41801: [9499, 9390, 6390, 9392, 12198, 12198, 9198],
    42001: [9559, 9450, 6450, 9445, 12258, 12258, 9258],
    42201: [9619, 9510, 6510, 9497, 12318, 12318, 9318],
    42401: [9679, 9570, 6570, 9550, 12378, 12378, 9378],
    42601: [9739, 9630, 6630, 9602, 12438, 12438, 9438],
    42801: [9799, 9690, 6690, 9655, 12498, 12498, 9498],
    43001: [9859, 9750, 6750, 9708, 12558, 12558, 9558],
    43201: [9919, 9810, 6810, 9760, 12618, 12618, 9618],
    43401: [9979, 9870, 6870, 9813, 12678, 12678, 9678],
    43601: [10039, 9930, 6930, 9866, 12738, 12738, 9738],
    43801: [10099, 9990, 6990, 9918, 12798, 12798, 9798],
    44001: [10159, 10050, 7050, 9971, 12858, 12858, 9858],
    44201: [10219, 10110, 7110, 10023, 12918, 12918, 9918],
    44401: [10279, 10170, 7170, 10076, 12978, 12978, 9978],
    44601: [10339, 10230, 7230, 10129, 13038, 13038, 10038],
    44801: [10399, 10290, 7290, 10181, 13098, 13098, 10098],
    45001: [10459, 10350, 7350, 10234, 13158, 13158, 10158],
    45201: [10519, 10410, 7410, 10287, 13218, 13218, 10218],
    45401: [10579, 10470, 7470, 10339, 13278, 13278, 10278],
    45601: [10639, 10530, 7530, 10392, 13338, 13338, 10338],
    45801: [10699, 10590, 7590, 10444, 13398, 13398, 10398],
    46001: [10759, 10650, 7650, 10497, 13458, 13458, 10458],
    46201: [10819, 10710, 7710, 10550, 13518, 13518, 10518],
    46401: [10879, 10770, 7770, 10602, 13578, 13578, 10578],
    46601: [10939, 10830, 7830, 10655, 13638, 13638, 10638],
    46801: [10999, 10890, 7890, 10707, 13698, 13698, 10698],
    47001: [11059, 10950, 7950, 10760, 13758, 13758, 10758],
    47201: [11119, 11010, 8010, 10813, 13818, 13818, 10818],
    47401: [11179, 11070, 8070, 10865, 13878, 13878, 10878],
    47601: [11239, 11130, 8130, 10918, 13938, 13938, 10938],
    47801: [11299, 11190, 8190, 10971, 13998, 13998, 10998],
    48001: [11359, 11250, 8250, 11023, 14058, 14058, 11058],
    48201: [11419, 11310, 8310, 11076, 14118, 14118, 11118],
    48401: [11479, 11370, 8370, 11128, 14178, 14178, 11178],
    48601: [11539, 11430, 8430, 11181, 14238, 14238, 11238],
    48801: [11599, 11490, 8490, 11234, 14298, 14298, 11298],
    49001: [11659, 11550, 8550, 11286, 14358, 14358, 11358],
    49201: [11719, 11610, 8610, 11339, 14418, 14418, 11418],
    49401: [11779, 11670, 8670, 11391, 14478, 14478, 11478],
    49601: [11839, 11730, 8730, 11444, 14538, 14538, 11538],
    49801: [11899, 11790, 8790, 11497, 14598, 14598, 11598],
    50001: [11959, 11850, 8856, 11549, 14658, 14658, 11664],
    50201: [12019, 11943, 8955, 11602, 14718, 14718, 11730],
    50401: [12079, 12038, 9056, 11655, 14778, 14778, 11796],
    50601: [12139, 12135, 9159, 11707, 14838, 14838, 11862],
    50801: [12199, 12233, 9263, 11760, 14898, 14898, 11928],
    51001: [12268, 12330, 9366, 11821, 14966, 14966, 12002],
    51201: [12368, 12428, 9470, 11913, 15066, 15066, 12108],
    51401: [12468, 12525, 9573, 12006, 15166, 15166, 12214],
    51601: [12568, 12623, 9677, 12099, 15266, 15266, 12320],
    51801: [12668, 12720, 9780, 12191, 15366, 15366, 12426],
    52001: [12768, 12815, 9881, 12284, 15466, 15466, 12532],
    52201: [12868, 12913, 9985, 12376, 15566, 15566, 12638],
    52401: [12968, 13010, 10088, 12469, 15666, 15666, 12744],
    52601: [13068, 13108, 10192, 12562, 15766, 15766, 12850],
    52801: [13168, 13205, 10295, 12654, 15866, 15866, 12956],
    53001: [13268, 13303, 10399, 12747, 15966, 15966, 13062],
    53201: [13368, 13400, 10502, 12840, 16066, 16066, 13168],
    53401: [13468, 13498, 10606, 12932, 16166, 16166, 13274],
    53601: [13568, 13593, 10707, 13025, 16266, 16266, 13380],
    53801: [13668, 13690, 10810, 13117, 16366, 16366, 13486],
    54001: [13768, 13788, 10914, 13210, 16466, 16466, 13592],
    54201: [13868, 13885, 11017, 13303, 16566, 16566, 13698],
    54401: [13968, 13983, 11121, 13395, 16666, 16666, 13804],
    54601: [14068, 14080, 11224, 13488, 16766, 16766, 13910],
    54801: [14168, 14178, 11328, 13580, 16866, 16866, 14016],
    55001: [14268, 14275, 11431, 13673, 16966, 16966, 14122],
    55201: [14368, 14373, 11535, 13766, 17066, 17066, 14228],
    55401: [14468, 14468, 11636, 13858, 17166, 17166, 14334],
    55601: [14568, 14565, 11739, 13951, 17266, 17266, 14440],
    55801: [14668, 14663, 11843, 14044, 17366, 17366, 14546],
    56001: [14768, 14725, 11911, 14136, 17466, 17466, 14652],
    56201: [14868, 14785, 11977, 14229, 17566, 17566, 14758],
    56401: [14968, 14845, 12043, 14321, 17666, 17666, 14864],
    56601: [15068, 14905, 12109, 14414, 17766, 17766, 14970],
    56801: [15168, 14979, 12189, 14507, 17866, 17866, 15076],
    57001: [15268, 15079, 12295, 14599, 17966, 17966, 15182],
    57201: [15368, 15179, 12401, 14692, 18066, 18066, 15288],
    57401: [15468, 15279, 12507, 14784, 18166, 18166, 15394],
    57601: [15568, 15379, 12613, 14877, 18266, 18266, 15500],
    57801: [15668, 15479, 12719, 14970, 18366, 18366, 15606],
    58001: [15768, 15579, 12825, 15062, 18466, 18466, 15712],
    58201: [15868, 15679, 12931, 15155, 18566, 18566, 15818],
    58401: [15968, 15779, 13037, 15248, 18666, 18666, 15924],
    58601: [16068, 15879, 13143, 15340, 18766, 18766, 16030],
    58801: [16168, 15979, 13249, 15433, 18866, 18866, 16136],
    59001: [16268, 16079, 13355, 15525, 18966, 18966, 16242],
    59201: [16373, 16183, 13465, 15618, 19066, 19066, 16348],
    59401: [16479, 16287, 13575, 15711, 19166, 19166, 16454],
    59601: [16585, 16395, 13689, 15803, 19266, 19266, 16560],
    59801: [16691, 16499, 13799, 15896, 19366, 19366, 16666],
    60001: [16797, 16604, 13910, 15988, 19466, 19466, 16772],
    60201: [16903, 16712, 14024, 16081, 19566, 19566, 16878],
    60401: [17009, 16816, 14134, 16174, 19666, 19666, 16984],
    60601: [17115, 16924, 14248, 16266, 19766, 19766, 17090],
    60801: [17221, 17029, 14359, 16359, 19866, 19866, 17196],
    61001: [17327, 17133, 14469, 16452, 19966, 19966, 17302],
    61201: [17433, 17241, 14583, 16544, 20066, 20066, 17408],
    61401: [17539, 17345, 14693, 16637, 20166, 20166, 17514],
    61601: [17645, 17449, 14803, 16729, 20266, 20266, 17620],
    61801: [17751, 17558, 14918, 16822, 20366, 20366, 17726],
    62001: [17857, 17662, 15028, 16915, 20466, 20466, 17832],
    62201: [17963, 17770, 15142, 17007, 20566, 20566, 17938],
    62401: [18069, 17874, 15252, 17100, 20666, 20666, 18044],
    62601: [18175, 17979, 15363, 17193, 20766, 20766, 18150],
    62801: [18281, 18087, 15477, 17285, 20866, 20866, 18256],
    63001: [18387, 18191, 15587, 17378, 20966, 20966, 18362],
    63201: [18493, 18295, 15697, 17470, 21066, 21066, 18468],
    63401: [18599, 18404, 15812, 17563, 21166, 21166, 18574],
    63601: [18705, 18508, 15922, 17656, 21266, 21266, 18680],
    63801: [18811, 18616, 16036, 17748, 21366, 21366, 18786],
    64001: [18917, 18720, 16146, 17841, 21466, 21466, 18892],
    64201: [19023, 18824, 16256, 17933, 21566, 21566, 18998],
    64401: [19129, 18933, 16371, 18026, 21666, 21666, 19104],
    64601: [19235, 19037, 16481, 18119, 21766, 21766, 19210],
    64801: [19341, 19141, 16591, 18211, 21866, 21866, 19316],
    65001: [19447, 19249, 16705, 18304, 21966, 21966, 19422],
    65201: [19553, 19354, 16816, 18397, 22066, 22066, 19528],
    65401: [19659, 19462, 16930, 18489, 22166, 22166, 19634],
    65601: [19765, 19566, 17040, 18582, 22266, 22266, 19740],
    65801: [19871, 19670, 17150, 18674, 22366, 22366, 19846],
    66001: [19977, 19779, 17265, 18767, 22466, 22466, 19952],
    66201: [20083, 19883, 17375, 18860, 22566, 22566, 20058],
    66401: [20189, 19987, 17485, 18952, 22666, 22666, 20164],
    66601: [20295, 20095, 17599, 19045, 22766, 22766, 20270],
    66801: [20401, 20199, 17709, 19137, 22866, 22866, 20376],
    67001: [20507, 20308, 17824, 19230, 22966, 22966, 20482],
    67201: [20613, 20412, 17934, 19323, 23066, 23066, 20588],
    67401: [20719, 20516, 18044, 19415, 23166, 23166, 20694],
    67601: [20825, 20624, 18158, 19508, 23266, 23266, 20800],
    67801: [20931, 20729, 18269, 19601, 23366, 23366, 20906],
    68001: [21037, 20837, 18383, 19693, 23466, 23466, 21012],
    68201: [21143, 20941, 18493, 19786, 23566, 23566, 21118],
    68401: [21249, 21045, 18603, 19878, 23666, 23666, 21224],
    68601: [21355, 21154, 18718, 19971, 23766, 23766, 21330],
    68801: [21461, 21258, 18828, 20064, 23866, 23866, 21436],
    69001: [21567, 21362, 18938, 20156, 23966, 23966, 21542],
    69201: [21673, 21470, 19052, 20249, 24066, 24066, 21648],
    69401: [21779, 21574, 19162, 20341, 24166, 24166, 21754],
    69601: [21885, 21683, 19277, 20434, 24266, 24266, 21860],
    69801: [21991, 21787, 19387, 20527, 24366, 24366, 21966],
    70001: [22097, 21891, 19497, 20619, 24466, 24466, 22072],
    70201: [22203, 21999, 19611, 20712, 24566, 24566, 22178],
    70401: [22309, 22104, 19722, 20805, 24666, 24666, 22284],
    70601: [22415, 22208, 19832, 20897, 24766, 24766, 22390],
    70801: [22521, 22316, 19946, 20990, 24866, 24866, 22496],
    71001: [22627, 22420, 20056, 21082, 24966, 24966, 22602],
    71201: [22733, 22529, 20171, 21175, 25066, 25066, 22708],
    71401: [22839, 22633, 20281, 21268, 25166, 25166, 22814],
    71601: [22945, 22737, 20391, 21360, 25266, 25266, 22920],
    71801: [23051, 22845, 20505, 21453, 25366, 25366, 23026],
    72001: [23157, 22949, 20615, 21546, 25466, 25466, 23132],
    72201: [23263, 23054, 20726, 21638, 25566, 25566, 23238],
    72401: [23369, 23162, 20840, 21731, 25666, 25666, 23344],
    72601: [23475, 23266, 20950, 21823, 25766, 25766, 23450],
    72801: [23581, 23374, 21064, 21916, 25866, 25866, 23556],
    73001: [23687, 23479, 21175, 22009, 25966, 25966, 23662],
    73201: [23793, 23583, 21285, 22101, 26066, 26066, 23768],
    73401: [23899, 23691, 21399, 22194, 26166, 26166, 23874],
    73601: [24005, 23795, 21509, 22286, 26266, 26266, 23980],
    73801: [24111, 23899, 21619, 22379, 26366, 26366, 24086],
    74001: [24217, 24008, 21734, 22472, 26466, 26466, 24192],
    74201: [24323, 24112, 21844, 22564, 26566, 26566, 24298],
    74401: [24429, 24220, 21958, 22657, 26666, 26666, 24404],
    74601: [24535, 24324, 22068, 22750, 26766, 26766, 24510],
    74801: [24641, 24429, 22179, 22842, 26866, 26866, 24616],
    75001: [24747, 24537, 22293, 22935, 26966, 26966, 24722],
    75201: [24853, 24641, 22403, 23027, 27066, 27066, 24828],
    75401: [24959, 24745, 22513, 23120, 27166, 27166, 24934],
    75601: [25065, 24854, 22628, 23213, 27266, 27266, 25040],
    75801: [25171, 24958, 22738, 23305, 27366, 27366, 25146],
    76001: [25277, 25066, 22852, 23398, 27466, 27466, 25252],
    76201: [25383, 25170, 22962, 23490, 27566, 27566, 25358],
    76401: [25489, 25274, 23072, 23583, 27666, 27666, 25464],
    76601: [25595, 25383, 23187, 23676, 27766, 27766, 25570],
    76801: [25701, 25487, 23297, 23768, 27866, 27866, 25676],
    77001: [25807, 25595, 23411, 23861, 27966, 27966, 25782],
    77201: [25913, 25699, 23521, 23954, 28066, 28066, 25888],
    77401: [26019, 25804, 23632, 24046, 28166, 28166, 25994],
    77601: [26125, 25912, 23746, 24139, 28266, 28266, 26100],
    77801: [26231, 26016, 23856, 24231, 28366, 28366, 26206],
    78001: [26337, 26120, 23966, 24324, 28466, 28466, 26312],
    78201: [26443, 26229, 24081, 24417, 28566, 28566, 26418],
    78401: [26549, 26333, 24191, 24509, 28666, 28666, 26524],
    78601: [26655, 26441, 24305, 24602, 28766, 28766, 26630],
    78801: [26761, 26545, 24415, 24694, 28866, 28866, 26736],
    79001: [26867, 26649, 24525, 24787, 28966, 28966, 26842],
    79201: [26973, 26758, 24640, 24880, 29066, 29066, 26948],
    79401: [27079, 26862, 24750, 24972, 29166, 29166, 27054],
    79601: [27185, 26966, 24860, 25065, 29266, 29266, 27160],
    79801: [27291, 27074, 24974, 25158, 29366, 29366, 27266],
    80001: [34, 34, 31, 31, 37, 37, 34, '%'],
    80201: [34, 34, 31, 32, 37, 37, 34, '%'],
    80801: [34, 34, 32, 32, 37, 37, 34, '%'],
    81601: [35, 34, 32, 32, 37, 37, 34, '%'],
    81801: [35, 34, 32, 32, 37, 37, 35, '%'],
    82801: [35, 35, 32, 32, 37, 37, 35, '%'],
    84201: [35, 35, 33, 32, 37, 37, 35, '%'],
    85001: [35, 35, 33, 32, 38, 38, 35, '%'],
    86001: [35, 35, 33, 33, 38, 38, 35, '%'],
    86201: [36, 35, 33, 33, 38, 38, 35, '%'],
    86401: [36, 35, 33, 33, 38, 38, 36, '%'],
    87601: [36, 36, 33, 33, 38, 38, 36, '%'],
    88001: [36, 36, 34, 33, 38, 38, 36, '%'],
    91401: [37, 36, 34, 33, 38, 38, 36, '%'],
    91601: [37, 36, 34, 33, 38, 38, 37, '%'],
    92201: [37, 36, 35, 33, 38, 38, 37, '%'],
    92401: [37, 36, 35, 33, 39, 39, 37, '%'],
    92801: [37, 37, 35, 34, 39, 39, 37, '%'],
    96801: [37, 37, 36, 34, 39, 39, 37, '%'],
    97401: [38, 37, 36, 34, 39, 39, 37, '%'],
    97601: [38, 37, 36, 34, 39, 39, 38, '%'],
    99001: [38, 38, 36, 34, 39, 39, 38, '%'],
    100601: [38, 38, 36, 35, 39, 39, 38, '%'],
    101201: [38, 38, 36, 35, 40, 40, 38, '%'],
    101801: [38, 38, 37, 35, 40, 40, 38, '%'],
    104001: [39, 38, 37, 35, 40, 40, 38, '%'],
    104201: [39, 38, 37, 35, 40, 40, 39, '%'],
    105801: [39, 39, 37, 35, 40, 40, 39, '%'],
    107201: [39, 39, 38, 35, 40, 40, 39, '%'],
    109801: [39, 39, 38, 36, 40, 40, 39, '%'],
    111801: [40, 39, 38, 36, 41, 41, 39, '%'],
    112001: [40, 39, 38, 36, 41, 41, 40, '%'],
    113401: [40, 39, 39, 36, 41, 41, 40, '%'],
    113801: [40, 40, 39, 36, 41, 41, 40, '%'],
    120401: [40, 40, 40, 36, 41, 41, 40, '%'],
    120801: [41, 40, 40, 36, 41, 41, 40, '%'],
    121001: [41, 40, 40, 36, 41, 41, 41, '%'],
    121201: [41, 40, 40, 37, 41, 41, 41, '%'],
    123001: [41, 41, 40, 37, 41, 41, 41, '%'],
    125001: [41, 41, 40, 37, 42, 42, 41, '%'],
    128201: [41, 41, 41, 37, 42, 42, 41, '%'],
    131201: [42, 41, 41, 37, 42, 42, 41, '%'],
    131401: [42, 41, 41, 37, 42, 42, 42, '%'],
    133801: [42, 42, 41, 37, 42, 42, 42, '%'],
    134801: [42, 42, 41, 38, 42, 42, 42, '%'],
    137201: [42, 42, 42, 38, 42, 42, 42, '%'],
    141601: [42, 42, 42, 38, 43, 43, 42, '%'],
    143801: [43, 42, 42, 38, 43, 43, 42, '%'],
    144001: [43, 42, 42, 38, 43, 43, 43, '%'],
    146601: [43, 43, 42, 38, 43, 43, 43, '%'],
    147401: [43, 43, 43, 38, 43, 43, 43, '%'],
    152201: [43, 43, 43, 39, 43, 43, 43, '%'],
    163401: [44, 44, 44, 39, 44, 44, 44, '%'],
    174601: [44, 44, 44, 40, 44, 44, 44, '%'],
    193201: [45, 45, 45, 40, 45, 45, 45, '%'],
    204601: [45, 45, 45, 41, 45, 45, 45, '%'],
    236201: [46, 46, 46, 41, 46, 46, 46, '%'],
    247201: [46, 46, 46, 42, 46, 46, 46, '%'],
    303801: [47, 47, 47, 42, 47, 47, 47, '%'],
    312001: [47, 47, 47, 43, 47, 47, 47, '%'],
    423201: [47, 47, 47, 44, 47, 47, 47, '%'],
    425201: [48, 48, 48, 44, 48, 48, 48, '%'],
    657201: [48, 48, 48, 45, 48, 48, 48, '%'],
    708801: [49, 49, 49, 45, 49, 49, 49, '%'],
    1470401: [49, 49, 49, 46, 49, 49, 49, '%']
  },
  31: {
    1: [0, 0, 0, 0, 0, 0, 0],
    1801: [134, 0, 133, 0, 134, 13, 134],
    1901: [144, 0, 141, 3, 144, 45, 144],
    2001: [155, 0, 150, 5, 155, 77, 155],
    2101: [157, 0, 150, 7, 157, 109, 157],
    2201: [167, 0, 158, 9, 167, 141, 167],
    2301: [178, 0, 166, 11, 178, 173, 178],
    2401: [188, 0, 175, 13, 205, 205, 188],
    2501: [199, 0, 183, 16, 237, 237, 199],
    2601: [209, 0, 191, 18, 269, 269, 209],
    2701: [220, 0, 200, 20, 301, 301, 220],
    2801: [222, 0, 200, 22, 333, 333, 222],
    2901: [232, 0, 208, 24, 365, 365, 232],
    3001: [243, 0, 216, 26, 397, 397, 243],
    3101: [253, 0, 225, 28, 429, 429, 253],
    3201: [264, 0, 233, 31, 461, 461, 264],
    3301: [274, 0, 241, 33, 493, 493, 274],
    3401: [277, 0, 241, 35, 525, 525, 277],
    3501: [287, 0, 250, 37, 557, 557, 287],
    3601: [298, 0, 258, 39, 589, 589, 298],
    3701: [308, 0, 266, 41, 621, 621, 308],
    3801: [319, 0, 275, 44, 653, 653, 319],
    3901: [329, 0, 283, 49, 685, 685, 329],
    4001: [331, 0, 283, 71, 717, 717, 331],
    4101: [342, 0, 291, 93, 749, 749, 342],
    4201: [352, 0, 300, 115, 781, 781, 352],
    4301: [363, 0, 308, 137, 810, 810, 363],
    4401: [373, 0, 316, 158, 834, 834, 373],
    4501: [383, 0, 325, 179, 861, 861, 383],
    4601: [384, 0, 325, 201, 887, 887, 384],
    4701: [394, 0, 333, 222, 911, 911, 394],
    4801: [405, 7, 342, 244, 938, 938, 405],
    4901: [414, 31, 352, 265, 962, 962, 414],
    5001: [425, 55, 362, 286, 989, 989, 425],
    5101: [435, 79, 372, 308, 1015, 1015, 435],
    5201: [445, 103, 382, 329, 1039, 1039, 445],
    5301: [447, 127, 383, 351, 1066, 1066, 447],
    5401: [456, 151, 393, 372, 1090, 1090, 456],
    5501: [467, 175, 403, 392, 1116, 1116, 467],
    5601: [477, 200, 413, 413, 1142, 1142, 477],
    5701: [487, 223, 423, 434, 1165, 1165, 487],
    5801: [497, 245, 433, 455, 1191, 1191, 497],
    5901: [498, 269, 434, 476, 1215, 1215, 498],
    6001: [509, 293, 444, 496, 1241, 1241, 509],
    6101: [519, 317, 454, 517, 1267, 1267, 519],
    6201: [529, 341, 464, 538, 1290, 1290, 529],
    6301: [539, 365, 474, 559, 1316, 1316, 539],
    6401: [549, 389, 484, 580, 1340, 1340, 549],
    6501: [564, 413, 486, 600, 1366, 1366, 551],
    6601: [583, 437, 496, 621, 1392, 1392, 561],
    6701: [603, 461, 506, 642, 1415, 1415, 571],
    6801: [622, 485, 516, 663, 1441, 1441, 581],
    6901: [641, 506, 525, 684, 1465, 1465, 591],
    7001: [661, 530, 535, 704, 1491, 1491, 601],
    7101: [680, 554, 537, 725, 1517, 1517, 603],
    7201: [700, 578, 547, 746, 1540, 1540, 613],
    7301: [719, 602, 557, 767, 1566, 1566, 623],
    7401: [738, 626, 567, 788, 1590, 1590, 633],
    7501: [758, 650, 577, 808, 1616, 1616, 643],
    7601: [777, 674, 587, 829, 1642, 1642, 653],
    7701: [797, 698, 597, 850, 1665, 1665, 663],
    7801: [816, 722, 598, 871, 1691, 1691, 665],
    7901: [835, 746, 608, 892, 1715, 1715, 675],
    8001: [855, 770, 618, 913, 1741, 1741, 685],
    8101: [874, 791, 628, 933, 1767, 1767, 695],
    8201: [894, 815, 638, 954, 1790, 1790, 705],
    8301: [913, 839, 648, 975, 1816, 1816, 715],
    8401: [932, 863, 649, 996, 1840, 1840, 717],
    8501: [952, 882, 659, 1017, 1866, 1866, 727],
    8601: [971, 901, 669, 1037, 1892, 1892, 737],
    8701: [991, 922, 678, 1058, 1915, 1915, 747],
    8801: [1010, 941, 688, 1079, 1941, 1941, 757],
    8901: [1030, 959, 698, 1100, 1965, 1965, 767],
    9001: [1049, 978, 699, 1121, 1991, 1991, 769],
    9101: [1068, 1000, 709, 1141, 2017, 2017, 779],
    9201: [1088, 1018, 718, 1162, 2040, 2040, 789],
    9301: [1107, 1037, 728, 1183, 2066, 2066, 799],
    9401: [1127, 1055, 737, 1204, 2090, 2090, 809],
    9501: [1146, 1077, 747, 1225, 2116, 2116, 819],
    9601: [1165, 1095, 748, 1246, 2142, 2142, 821],
    9701: [1185, 1113, 758, 1266, 2165, 2165, 831],
    9801: [1204, 1131, 768, 1287, 2191, 2191, 841],
    9901: [1224, 1152, 777, 1308, 2215, 2215, 851],
    10001: [1243, 1170, 787, 1329, 2241, 2241, 861],
    10101: [1263, 1189, 797, 1350, 2267, 2267, 871],
    10201: [1282, 1207, 806, 1370, 2290, 2290, 881],
    10301: [1301, 1228, 808, 1391, 2316, 2316, 883],
    10401: [1321, 1246, 817, 1412, 2340, 2340, 893],
    10501: [1340, 1264, 827, 1433, 2366, 2366, 903],
    10601: [1360, 1282, 836, 1454, 2392, 2392, 913],
    10701: [1379, 1303, 846, 1474, 2415, 2415, 923],
    10801: [1398, 1321, 856, 1495, 2441, 2441, 933],
    10901: [1418, 1340, 857, 1516, 2465, 2465, 935],
    11001: [1437, 1360, 867, 1537, 2491, 2491, 945],
    11101: [1457, 1379, 876, 1558, 2517, 2517, 955],
    11201: [1476, 1397, 886, 1578, 2540, 2540, 965],
    11301: [1495, 1415, 896, 1599, 2566, 2566, 975],
    11401: [1515, 1436, 905, 1620, 2590, 2590, 985],
    11501: [1534, 1454, 907, 1641, 2616, 2616, 987],
    11601: [1554, 1472, 916, 1662, 2642, 2642, 997],
    11701: [1573, 1491, 926, 1682, 2665, 2665, 1007],
    11801: [1592, 1511, 936, 1703, 2691, 2691, 1017],
    11901: [1612, 1530, 945, 1724, 2720, 2720, 1027],
    12001: [1632, 1548, 955, 1745, 2751, 2751, 1038],
    12101: [1651, 1566, 956, 1766, 2782, 2782, 1040],
    12201: [1671, 1587, 966, 1787, 2814, 2814, 1051],
    12301: [1691, 1605, 975, 1809, 2845, 2845, 1061],
    12401: [1710, 1623, 985, 1830, 2876, 2876, 1072],
    12501: [1730, 1642, 995, 1851, 2907, 2907, 1082],
    12601: [1750, 1663, 1004, 1872, 2939, 2939, 1093],
    12701: [1769, 1681, 1014, 1893, 2970, 2970, 1103],
    12801: [1789, 1699, 1015, 1914, 3001, 3001, 1105],
    12901: [1809, 1720, 1025, 1935, 3032, 3032, 1116],
    13001: [1829, 1738, 1035, 1956, 3064, 3064, 1126],
    13101: [1848, 1756, 1044, 1977, 3095, 3095, 1137],
    13201: [1868, 1774, 1054, 1998, 3126, 3126, 1147],
    13301: [1888, 1795, 1064, 2019, 3157, 3157, 1158],
    13401: [1907, 1814, 1065, 2041, 3189, 3189, 1160],
    13501: [1927, 1832, 1074, 2062, 3220, 3220, 1170],
    13601: [1947, 1850, 1084, 2083, 3254, 3254, 1181],
    13701: [1967, 1871, 1094, 2104, 3290, 3290, 1192],
    13801: [1987, 1889, 1103, 2125, 3324, 3324, 1203],
    13901: [2006, 1907, 1113, 2147, 3358, 3358, 1213],
    14001: [2026, 1926, 1114, 2168, 3392, 3392, 1216],
    14101: [2048, 1949, 1124, 2191, 3425, 3425, 1226],
    14201: [2075, 1975, 1134, 2217, 3460, 3460, 1235],
    14301: [2101, 2004, 1145, 2244, 3493, 3493, 1245],
    14401: [2128, 2030, 1155, 2270, 3526, 3526, 1261],
    14501: [2154, 2056, 1165, 2296, 3559, 3559, 1287],
    14601: [2181, 2084, 1167, 2323, 3591, 3591, 1312],
    14701: [2207, 2110, 1177, 2349, 3627, 3627, 1341],
    14801: [2234, 2139, 1187, 2376, 3659, 3659, 1366],
    14901: [2260, 2165, 1197, 2402, 3692, 3692, 1392],
    15001: [2287, 2191, 1208, 2429, 3725, 3725, 1418],
    15101: [2313, 2220, 1218, 2455, 3758, 3758, 1444],
    15201: [2339, 2246, 1228, 2482, 3793, 3793, 1472],
    15301: [2366, 2274, 1230, 2508, 3826, 3826, 1498],
    15401: [2392, 2301, 1240, 2535, 3859, 3859, 1524],
    15501: [2419, 2327, 1250, 2561, 3891, 3891, 1549],
    15601: [2445, 2355, 1261, 2588, 3924, 3924, 1575],
    15701: [2472, 2381, 1271, 2614, 3959, 3959, 1603],
    15801: [2498, 2410, 1281, 2641, 3992, 3992, 1629],
    15901: [2525, 2436, 1283, 2667, 4025, 4025, 1655],
    16001: [2551, 2462, 1293, 2694, 4058, 4058, 1681],
    16101: [2578, 2491, 1303, 2720, 4091, 4091, 1707],
    16201: [2604, 2517, 1313, 2746, 4126, 4126, 1735],
    16301: [2631, 2545, 1324, 2773, 4159, 4159, 1761],
    16401: [2657, 2571, 1334, 2799, 4191, 4191, 1786],
    16501: [2684, 2597, 1336, 2826, 4224, 4224, 1812],
    16601: [2710, 2626, 1346, 2852, 4257, 4257, 1838],
    16701: [2737, 2652, 1356, 2879, 4292, 4292, 1866],
    16801: [2763, 2681, 1366, 2905, 4325, 4325, 1892],
    16901: [2789, 2707, 1377, 2932, 4358, 4358, 1918],
    17001: [2816, 2733, 1387, 2958, 4391, 4391, 1944],
    17101: [2842, 2761, 1389, 2985, 4423, 4423, 1969],
    17201: [2869, 2788, 1399, 3011, 4459, 4459, 1998],
    17301: [2895, 2816, 1409, 3038, 4491, 4491, 2023],
    17401: [2922, 2842, 1419, 3064, 4524, 4524, 2049],
    17501: [2948, 2868, 1429, 3091, 4557, 4557, 2075],
    17601: [2975, 2897, 1440, 3117, 4590, 4590, 2101],
    17701: [3001, 2923, 1450, 3144, 4625, 4625, 2129],
    17801: [3028, 2952, 1452, 3170, 4658, 4658, 2155],
    17901: [3054, 2978, 1462, 3196, 4690, 4690, 2180],
    18001: [3081, 3004, 1472, 3223, 4723, 4723, 2206],
    18101: [3107, 3032, 1482, 3249, 4756, 4756, 2232],
    18201: [3134, 3058, 1493, 3276, 4791, 4791, 2260],
    18301: [3160, 3087, 1503, 3302, 4824, 4824, 2286],
    18401: [3187, 3113, 1505, 3329, 4857, 4857, 2312],
    18501: [3213, 3139, 1515, 3355, 4890, 4890, 2338],
    18601: [3240, 3168, 1525, 3382, 4922, 4922, 2363],
    18701: [3266, 3194, 1535, 3408, 4958, 4958, 2392],
    18801: [3292, 3222, 1546, 3435, 4990, 4990, 2417],
    18901: [3319, 3248, 1556, 3461, 5023, 5023, 2443],
    19001: [3345, 3277, 1558, 3488, 5056, 5056, 2469],
    19101: [3372, 3303, 1568, 3514, 5089, 5089, 2495],
    19201: [3398, 3329, 1578, 3541, 5124, 5124, 2523],
    19301: [3425, 3358, 1588, 3567, 5157, 5157, 2549],
    19401: [3451, 3384, 1598, 3594, 5190, 5190, 2575],
    19501: [3478, 3412, 1609, 3620, 5222, 5222, 2600],
    19601: [3504, 3438, 1610, 3646, 5255, 5255, 2626],
    19701: [3531, 3463, 1619, 3673, 5290, 5290, 2654],
    19801: [3557, 3491, 1628, 3699, 5323, 5323, 2680],
    19901: [3584, 3516, 1638, 3726, 5356, 5356, 2706],
    20001: [3637, 3569, 1656, 3779, 5422, 5422, 2758],
    20201: [3690, 3622, 1667, 3832, 5490, 5490, 2812],
    20401: [3742, 3675, 1685, 3885, 5555, 5555, 2863],
    20601: [3795, 3725, 1704, 3938, 5623, 5623, 2917],
    20801: [3848, 3778, 1714, 3991, 5689, 5689, 2969],
    21001: [3901, 3831, 1733, 4044, 5754, 5754, 3020],
    21201: [3954, 3884, 1752, 4097, 5822, 5822, 3074],
    21401: [4007, 3937, 1762, 4149, 5888, 5888, 3126],
    21601: [4060, 3987, 1781, 4202, 5956, 5956, 3180],
    21801: [4113, 4040, 1799, 4255, 6021, 6021, 3231],
    22001: [4166, 4093, 1810, 4308, 6087, 6087, 3283],
    22201: [4219, 4146, 1828, 4361, 6155, 6155, 3337],
    22401: [4273, 4199, 1847, 4415, 6222, 6222, 3390],
    22601: [4327, 4249, 1866, 4470, 6291, 6291, 3445],
    22801: [4382, 4302, 1876, 4524, 6358, 6358, 3498],
    23001: [4437, 4355, 1895, 4579, 6426, 6426, 3552],
    23201: [4491, 4408, 1913, 4633, 6495, 6495, 3607],
    23401: [4546, 4461, 1924, 4688, 6563, 6563, 3661],
    23601: [4600, 4514, 1943, 4743, 6632, 6632, 3716],
    23801: [4655, 4564, 1961, 4797, 6700, 6700, 3770],
    24001: [4709, 4617, 1971, 4852, 6767, 6767, 3823],
    24201: [4764, 4673, 1990, 4906, 6836, 6836, 3878],
    24401: [4819, 4726, 2009, 4961, 6904, 6904, 3932],
    24601: [4873, 4779, 2019, 5016, 6973, 6973, 3987],
    24801: [4928, 4832, 2038, 5070, 7040, 7040, 4040],
    25001: [4982, 4887, 2057, 5125, 7108, 7108, 4108],
    25201: [5037, 4940, 2067, 5179, 7177, 7177, 4177],
    25401: [5092, 4993, 2086, 5234, 7245, 7245, 4245],
    25601: [5146, 5049, 2105, 5289, 7314, 7314, 4314],
    25801: [5201, 5101, 2115, 5343, 7381, 7381, 4381],
    26001: [5255, 5154, 2154, 5398, 7449, 7449, 4449],
    26201: [5310, 5210, 2210, 5452, 7518, 7518, 4518],
    26401: [5365, 5263, 2263, 5507, 7586, 7586, 4586],
    26601: [5419, 5316, 2316, 5562, 7655, 7655, 4655],
    26801: [5474, 5371, 2371, 5616, 7722, 7722, 4722],
    27001: [5528, 5424, 2424, 5671, 7790, 7790, 4790],
    27201: [5583, 5477, 2477, 5725, 7859, 7859, 4859],
    27401: [5638, 5532, 2532, 5780, 7927, 7927, 4927],
    27601: [5692, 5585, 2585, 5835, 7996, 7996, 4996],
    27801: [5747, 5638, 2638, 5889, 8063, 8063, 5063],
    28001: [5801, 5691, 2691, 5944, 8131, 8131, 5131],
    28201: [5856, 5747, 2747, 5998, 8200, 8200, 5200],
    28401: [5911, 5800, 2800, 6053, 8268, 8268, 5268],
    28601: [5965, 5853, 2853, 6108, 8337, 8337, 5337],
    28801: [6020, 5908, 2908, 6162, 8404, 8404, 5404],
    29001: [6074, 5961, 2961, 6217, 8472, 8472, 5472],
    29201: [6129, 6014, 3014, 6271, 8541, 8541, 5541],
    29401: [6184, 6069, 3069, 6326, 8609, 8609, 5609],
    29601: [6238, 6122, 3122, 6380, 8678, 8678, 5678],
    29801: [6293, 6175, 3175, 6435, 8745, 8745, 5745],
    30001: [6347, 6232, 3232, 6490, 8813, 8813, 5813],
    30201: [6402, 6286, 3286, 6544, 8882, 8882, 5882],
    30401: [6456, 6340, 3340, 6599, 8950, 8950, 5950],
    30601: [6511, 6395, 3395, 6653, 9019, 9019, 6019],
    30801: [6566, 6451, 3451, 6708, 9086, 9086, 6086],
    31001: [6620, 6506, 3506, 6763, 9154, 9154, 6154],
    31201: [6675, 6560, 3560, 6817, 9223, 9223, 6223],
    31401: [6729, 6617, 3617, 6872, 9291, 9291, 6291],
    31601: [6784, 6671, 3671, 6926, 9360, 9360, 6360],
    31801: [6839, 6725, 3725, 6981, 9427, 9427, 6427],
    32001: [6893, 6782, 3782, 7036, 9495, 9495, 6495],
    32201: [6948, 6836, 3836, 7090, 9564, 9564, 6564],
    32401: [7002, 6891, 3891, 7145, 9632, 9632, 6632],
    32601: [7057, 6947, 3947, 7199, 9701, 9701, 6701],
    32801: [7112, 7002, 4002, 7254, 9768, 9768, 6768],
    33001: [7166, 7056, 4056, 7308, 9836, 9836, 6836],
    33201: [7221, 7113, 4113, 7363, 9905, 9905, 6905],
    33401: [7275, 7167, 4167, 7418, 9973, 9973, 6973],
    33601: [7330, 7221, 4221, 7472, 10042, 10042, 7042],
    33801: [7385, 7276, 4276, 7527, 10109, 10109, 7109],
    34001: [7439, 7332, 4332, 7581, 10177, 10177, 7177],
    34201: [7494, 7387, 4387, 7636, 10246, 10246, 7246],
    34401: [7548, 7441, 4441, 7691, 10311, 10311, 7311],
    34601: [7603, 7495, 4495, 7745, 10373, 10373, 7373],
    34801: [7657, 7547, 4547, 7799, 10435, 10435, 7435],
    35001: [7711, 7601, 4601, 7854, 10497, 10497, 7497],
    35201: [7767, 7658, 4658, 7908, 10559, 10559, 7559],
    35401: [7829, 7720, 4720, 7962, 10621, 10621, 7621],
    35601: [7891, 7782, 4782, 8017, 10683, 10683, 7683],
    35801: [7953, 7844, 4844, 8071, 10745, 10745, 7745],
    36001: [8015, 7906, 4906, 8125, 10807, 10807, 7807],
    36201: [8077, 7968, 4968, 8180, 10869, 10869, 7869],
    36401: [8139, 8030, 5030, 8234, 10931, 10931, 7931],
    36601: [8201, 8092, 5092, 8289, 10993, 10993, 7993],
    36801: [8263, 8154, 5154, 8343, 11055, 11055, 8055],
    37001: [8325, 8216, 5216, 8397, 11117, 11117, 8117],
    37201: [8387, 8278, 5278, 8452, 11179, 11179, 8179],
    37401: [8449, 8340, 5340, 8506, 11241, 11241, 8241],
    37601: [8511, 8402, 5402, 8560, 11303, 11303, 8303],
    37801: [8573, 8464, 5464, 8615, 11365, 11365, 8365],
    38001: [8635, 8526, 5526, 8669, 11427, 11427, 8427],
    38201: [8697, 8588, 5588, 8723, 11489, 11489, 8489],
    38401: [8759, 8650, 5650, 8778, 11551, 11551, 8551],
    38601: [8821, 8712, 5712, 8832, 11613, 11613, 8613],
    38801: [8883, 8774, 5774, 8886, 11675, 11675, 8675],
    39001: [8945, 8836, 5836, 8941, 11737, 11737, 8737],
    39201: [9007, 8898, 5898, 8995, 11799, 11799, 8799],
    39401: [9069, 8960, 5960, 9050, 11861, 11861, 8861],
    39601: [9131, 9022, 6022, 9104, 11923, 11923, 8923],
    39801: [9193, 9084, 6084, 9158, 11985, 11985, 8985],
    40001: [9255, 9146, 6146, 9213, 12047, 12047, 9047],
    40201: [9317, 9208, 6208, 9267, 12109, 12109, 9109],
    40401: [9379, 9270, 6270, 9321, 12171, 12171, 9171],
    40601: [9441, 9332, 6332, 9376, 12233, 12233, 9233],
    40801: [9503, 9394, 6394, 9430, 12295, 12295, 9295],
    41001: [9565, 9456, 6456, 9484, 12357, 12357, 9357],
    41201: [9627, 9518, 6518, 9539, 12419, 12419, 9419],
    41401: [9689, 9580, 6580, 9593, 12481, 12481, 9481],
    41601: [9751, 9642, 6642, 9648, 12543, 12543, 9543],
    41801: [9813, 9704, 6704, 9702, 12605, 12605, 9605],
    42001: [9875, 9766, 6766, 9756, 12667, 12667, 9667],
    42201: [9937, 9828, 6828, 9811, 12729, 12729, 9729],
    42401: [9999, 9890, 6890, 9865, 12791, 12791, 9791],
    42601: [10061, 9952, 6952, 9919, 12853, 12853, 9853],
    42801: [10123, 10014, 7014, 9974, 12915, 12915, 9915],
    43001: [10185, 10076, 7076, 10028, 12977, 12977, 9977],
    43201: [10247, 10138, 7138, 10082, 13039, 13039, 10039],
    43401: [10309, 10200, 7200, 10137, 13101, 13101, 10101],
    43601: [10371, 10262, 7262, 10191, 13163, 13163, 10163],
    43801: [10433, 10324, 7324, 10246, 13225, 13225, 10225],
    44001: [10495, 10386, 7386, 10300, 13287, 13287, 10287],
    44201: [10557, 10448, 7448, 10354, 13349, 13349, 10349],
    44401: [10619, 10510, 7510, 10409, 13411, 13411, 10411],
    44601: [10681, 10572, 7572, 10463, 13473, 13473, 10473],
    44801: [10743, 10634, 7634, 10517, 13535, 13535, 10535],
    45001: [10805, 10696, 7696, 10572, 13597, 13597, 10597],
    45201: [10867, 10758, 7758, 10626, 13659, 13659, 10659],
    45401: [10929, 10820, 7820, 10680, 13721, 13721, 10721],
    45601: [10991, 10882, 7882, 10735, 13783, 13783, 10783],
    45801: [11053, 10944, 7944, 10789, 13845, 13845, 10845],
    46001: [11115, 11006, 8006, 10843, 13907, 13907, 10907],
    46201: [11177, 11068, 8068, 10898, 13969, 13969, 10969],
    46401: [11239, 11130, 8130, 10952, 14031, 14031, 11031],
    46601: [11301, 11192, 8192, 11007, 14093, 14093, 11093],
    46801: [11363, 11254, 8254, 11061, 14155, 14155, 11155],
    47001: [11425, 11316, 8316, 11115, 14217, 14217, 11217],
    47201: [11487, 11378, 8378, 11170, 14279, 14279, 11279],
    47401: [11549, 11440, 8440, 11224, 14341, 14341, 11341],
    47601: [11611, 11502, 8502, 11278, 14403, 14403, 11403],
    47801: [11673, 11564, 8564, 11333, 14465, 14465, 11465],
    48001: [11735, 11626, 8626, 11387, 14527, 14527, 11527],
    48201: [11797, 11688, 8688, 11441, 14589, 14589, 11589],
    48401: [11859, 11750, 8750, 11496, 14651, 14651, 11651],
    48601: [11921, 11812, 8812, 11550, 14713, 14713, 11713],
    48801: [11983, 11874, 8874, 11605, 14775, 14775, 11775],
    49001: [12045, 11936, 8936, 11659, 14837, 14837, 11837],
    49201: [12107, 11998, 8998, 11713, 14899, 14899, 11899],
    49401: [12169, 12060, 9060, 11768, 14961, 14961, 11961],
    49601: [12231, 12122, 9122, 11822, 15023, 15023, 12023],
    49801: [12293, 12184, 9184, 11876, 15085, 15085, 12085],
    50001: [12355, 12246, 9252, 11931, 15147, 15147, 12153],
    50201: [12417, 12341, 9353, 11985, 15209, 15209, 12221],
    50401: [12479, 12440, 9458, 12039, 15271, 15271, 12289],
    50601: [12541, 12540, 9564, 12094, 15333, 15333, 12357],
    50801: [12603, 12641, 9671, 12148, 15395, 15395, 12425],
    51001: [12673, 12742, 9778, 12211, 15465, 15465, 12501],
    51201: [12775, 12843, 9885, 12305, 15567, 15567, 12609],
    51401: [12877, 12943, 9991, 12400, 15669, 15669, 12717],
    51601: [12979, 13044, 10098, 12494, 15771, 15771, 12825],
    51801: [13081, 13145, 10205, 12588, 15873, 15873, 12933],
    52001: [13183, 13243, 10309, 12683, 15975, 15975, 13041],
    52201: [13285, 13344, 10416, 12777, 16077, 16077, 13149],
    52401: [13387, 13445, 10523, 12871, 16179, 16179, 13257],
    52601: [13489, 13545, 10629, 12966, 16281, 16281, 13365],
    52801: [13591, 13646, 10736, 13060, 16383, 16383, 13473],
    53001: [13693, 13747, 10843, 13154, 16485, 16485, 13581],
    53201: [13795, 13848, 10950, 13249, 16587, 16587, 13689],
    53401: [13897, 13948, 11056, 13343, 16689, 16689, 13797],
    53601: [13999, 14046, 11160, 13438, 16791, 16791, 13905],
    53801: [14101, 14147, 11267, 13532, 16893, 16893, 14013],
    54001: [14203, 14248, 11374, 13626, 16995, 16995, 14121],
    54201: [14305, 14349, 11481, 13721, 17097, 17097, 14229],
    54401: [14407, 14450, 11588, 13815, 17199, 17199, 14337],
    54601: [14509, 14550, 11694, 13909, 17301, 17301, 14445],
    54801: [14611, 14651, 11801, 14004, 17403, 17403, 14553],
    55001: [14713, 14752, 11908, 14098, 17505, 17505, 14661],
    55201: [14815, 14852, 12014, 14192, 17607, 17607, 14769],
    55401: [14917, 14951, 12119, 14287, 17709, 17709, 14877],
    55601: [15019, 15051, 12225, 14381, 17811, 17811, 14985],
    55801: [15121, 15152, 12332, 14475, 17913, 17913, 15093],
    56001: [15223, 15217, 12403, 14570, 18015, 18015, 15201],
    56201: [15325, 15279, 12471, 14664, 18117, 18117, 15309],
    56401: [15427, 15341, 12539, 14759, 18219, 18219, 15417],
    56601: [15529, 15403, 12607, 14853, 18321, 18321, 15525],
    56801: [15631, 15478, 12688, 14947, 18423, 18423, 15633],
    57001: [15733, 15580, 12796, 15042, 18525, 18525, 15741],
    57201: [15835, 15682, 12904, 15136, 18627, 18627, 15849],
    57401: [15937, 15784, 13012, 15230, 18729, 18729, 15957],
    57601: [16039, 15886, 13120, 15325, 18831, 18831, 16065],
    57801: [16141, 15988, 13228, 15419, 18933, 18933, 16173],
    58001: [16243, 16090, 13336, 15513, 19035, 19035, 16281],
    58201: [16345, 16192, 13444, 15608, 19137, 19137, 16389],
    58401: [16447, 16294, 13552, 15702, 19239, 19239, 16497],
    58601: [16549, 16396, 13660, 15797, 19341, 19341, 16605],
    58801: [16651, 16498, 13768, 15891, 19443, 19443, 16713],
    59001: [16753, 16600, 13876, 15985, 19545, 19545, 16821],
    59201: [16860, 16706, 13988, 16080, 19647, 19647, 16929],
    59401: [16968, 16813, 14101, 16174, 19749, 19749, 17037],
    59601: [17076, 16923, 14217, 16268, 19851, 19851, 17145],
    59801: [17184, 17029, 14329, 16363, 19953, 19953, 17253],
    60001: [17292, 17136, 14442, 16457, 20055, 20055, 17361],
    60201: [17400, 17246, 14558, 16551, 20157, 20157, 17469],
    60401: [17508, 17352, 14670, 16646, 20259, 20259, 17577],
    60601: [17616, 17463, 14787, 16740, 20361, 20361, 17685],
    60801: [17724, 17569, 14899, 16835, 20463, 20463, 17793],
    61001: [17832, 17675, 15011, 16929, 20565, 20565, 17901],
    61201: [17940, 17786, 15128, 17023, 20667, 20667, 18009],
    61401: [18048, 17892, 15240, 17118, 20769, 20769, 18117],
    61601: [18156, 17998, 15352, 17212, 20871, 20871, 18225],
    61801: [18264, 18109, 15469, 17306, 20973, 20973, 18333],
    62001: [18372, 18215, 15581, 17401, 21075, 21075, 18441],
    62201: [18480, 18326, 15698, 17495, 21177, 21177, 18549],
    62401: [18588, 18432, 15810, 17589, 21279, 21279, 18657],
    62601: [18696, 18538, 15922, 17684, 21381, 21381, 18765],
    62801: [18804, 18649, 16039, 17778, 21483, 21483, 18873],
    63001: [18912, 18755, 16151, 17872, 21585, 21585, 18981],
    63201: [19020, 18861, 16263, 17967, 21687, 21687, 19089],
    63401: [19128, 18972, 16380, 18061, 21789, 21789, 19197],
    63601: [19236, 19078, 16492, 18156, 21891, 21891, 19305],
    63801: [19344, 19188, 16608, 18250, 21993, 21993, 19413],
    64001: [19452, 19295, 16721, 18344, 22095, 22095, 19521],
    64201: [19560, 19401, 16833, 18439, 22197, 22197, 19629],
    64401: [19668, 19511, 16949, 18533, 22299, 22299, 19737],
    64601: [19776, 19618, 17062, 18627, 22401, 22401, 19845],
    64801: [19884, 19724, 17174, 18722, 22503, 22503, 19953],
    65001: [19992, 19834, 17290, 18816, 22605, 22605, 20061],
    65201: [20100, 19941, 17403, 18910, 22707, 22707, 20169],
    65401: [20208, 20051, 17519, 19005, 22809, 22809, 20277],
    65601: [20316, 20157, 17631, 19099, 22911, 22911, 20385],
    65801: [20424, 20264, 17744, 19194, 23013, 23013, 20493],
    66001: [20532, 20374, 17860, 19288, 23115, 23115, 20601],
    66201: [20640, 20480, 17972, 19382, 23217, 23217, 20709],
    66401: [20748, 20587, 18085, 19477, 23319, 23319, 20817],
    66601: [20856, 20697, 18201, 19571, 23421, 23421, 20925],
    66801: [20964, 20803, 18313, 19665, 23523, 23523, 21033],
    67001: [21072, 20914, 18430, 19760, 23625, 23625, 21141],
    67201: [21180, 21020, 18542, 19854, 23727, 23727, 21249],
    67401: [21288, 21126, 18654, 19948, 23829, 23829, 21357],
    67601: [21396, 21237, 18771, 20043, 23931, 23931, 21465],
    67801: [21504, 21343, 18883, 20137, 24033, 24033, 21573],
    68001: [21612, 21454, 19000, 20232, 24135, 24135, 21681],
    68201: [21720, 21560, 19112, 20326, 24237, 24237, 21789],
    68401: [21828, 21666, 19224, 20420, 24339, 24339, 21897],
    68601: [21936, 21777, 19341, 20515, 24441, 24441, 22005],
    68801: [22044, 21883, 19453, 20609, 24543, 24543, 22113],
    69001: [22152, 21989, 19565, 20703, 24645, 24645, 22221],
    69201: [22260, 22100, 19682, 20798, 24747, 24747, 22329],
    69401: [22368, 22206, 19794, 20892, 24849, 24849, 22437],
    69601: [22476, 22316, 19910, 20986, 24951, 24951, 22545],
    69801: [22584, 22423, 20023, 21081, 25053, 25053, 22653],
    70001: [22692, 22529, 20135, 21175, 25155, 25155, 22761],
    70201: [22800, 22639, 20251, 21269, 25257, 25257, 22869],
    70401: [22908, 22746, 20364, 21364, 25359, 25359, 22977],
    70601: [23016, 22852, 20476, 21458, 25461, 25461, 23085],
    70801: [23124, 22962, 20592, 21553, 25563, 25563, 23193],
    71001: [23232, 23069, 20705, 21647, 25665, 25665, 23301],
    71201: [23340, 23179, 20821, 21741, 25767, 25767, 23409],
    71401: [23448, 23285, 20933, 21836, 25869, 25869, 23517],
    71601: [23556, 23392, 21046, 21930, 25971, 25971, 23625],
    71801: [23664, 23502, 21162, 22024, 26073, 26073, 23733],
    72001: [23772, 23608, 21274, 22119, 26175, 26175, 23841],
    72201: [23880, 23715, 21387, 22213, 26277, 26277, 23949],
    72401: [23988, 23825, 21503, 22307, 26379, 26379, 24057],
    72601: [24096, 23931, 21615, 22402, 26481, 26481, 24165],
    72801: [24204, 24042, 21732, 22496, 26583, 26583, 24273],
    73001: [24312, 24148, 21844, 22591, 26685, 26685, 24381],
    73201: [24420, 24254, 21956, 22685, 26787, 26787, 24489],
    73401: [24528, 24365, 22073, 22779, 26889, 26889, 24597],
    73601: [24636, 24471, 22185, 22874, 26991, 26991, 24705],
    73801: [24744, 24577, 22297, 22968, 27093, 27093, 24813],
    74001: [24852, 24688, 22414, 23062, 27195, 27195, 24921],
    74201: [24960, 24794, 22526, 23157, 27297, 27297, 25029],
    74401: [25068, 24905, 22643, 23251, 27399, 27399, 25137],
    74601: [25176, 25011, 22755, 23345, 27501, 27501, 25245],
    74801: [25284, 25117, 22867, 23440, 27603, 27603, 25353],
    75001: [25392, 25228, 22984, 23534, 27705, 27705, 25461],
    75201: [25500, 25334, 23096, 23629, 27807, 27807, 25569],
    75401: [25608, 25440, 23208, 23723, 27909, 27909, 25677],
    75601: [25716, 25551, 23325, 23817, 28011, 28011, 25785],
    75801: [25824, 25657, 23437, 23912, 28113, 28113, 25893],
    76001: [25932, 25767, 23553, 24006, 28215, 28215, 26001],
    76201: [26040, 25874, 23666, 24100, 28317, 28317, 26109],
    76401: [26148, 25980, 23778, 24195, 28419, 28419, 26217],
    76601: [26256, 26090, 23894, 24289, 28521, 28521, 26325],
    76801: [26364, 26197, 24007, 24383, 28623, 28623, 26433],
    77001: [26472, 26307, 24123, 24478, 28725, 28725, 26541],
    77201: [26580, 26413, 24235, 24572, 28827, 28827, 26649],
    77401: [26688, 26520, 24348, 24666, 28929, 28929, 26757],
    77601: [26796, 26630, 24464, 24761, 29031, 29031, 26865],
    77801: [26904, 26736, 24576, 24855, 29133, 29133, 26973],
    78001: [27012, 26843, 24689, 24950, 29235, 29235, 27081],
    78201: [27120, 26953, 24805, 25044, 29337, 29337, 27189],
    78401: [27228, 27059, 24917, 25138, 29439, 29439, 27297],
    78601: [27336, 27170, 25034, 25233, 29541, 29541, 27405],
    78801: [27444, 27276, 25146, 25327, 29643, 29643, 27513],
    79001: [27552, 27382, 25258, 25421, 29745, 29745, 27621],
    79201: [27660, 27493, 25375, 25516, 29847, 29847, 27729],
    79401: [27768, 27599, 25487, 25610, 29949, 29949, 27837],
    79601: [27876, 27705, 25599, 25704, 30051, 30051, 27945],
    79801: [27984, 27816, 25716, 25799, 30153, 30153, 28053],
    80001: [35, 35, 32, 32, 38, 38, 35, '%'],
    81001: [35, 35, 33, 32, 38, 38, 35, '%'],
    81201: [35, 35, 33, 33, 38, 38, 35, '%'],
    81801: [35, 35, 33, 33, 38, 38, 36, '%'],
    82201: [36, 35, 33, 33, 38, 38, 36, '%'],
    83001: [36, 36, 33, 33, 38, 38, 36, '%'],
    84601: [36, 36, 34, 33, 38, 38, 36, '%'],
    85001: [36, 36, 34, 33, 39, 39, 36, '%'],
    86401: [36, 36, 34, 33, 39, 39, 37, '%'],
    86801: [37, 36, 34, 33, 39, 39, 37, '%'],
    87201: [37, 36, 34, 34, 39, 39, 37, '%'],
    87801: [37, 37, 34, 34, 39, 39, 37, '%'],
    88201: [37, 37, 35, 34, 39, 39, 37, '%'],
    91601: [37, 37, 35, 34, 39, 39, 38, '%'],
    92201: [38, 37, 35, 34, 39, 39, 38, '%'],
    92401: [38, 37, 36, 34, 40, 40, 38, '%'],
    93201: [38, 38, 36, 34, 40, 40, 38, '%'],
    94001: [38, 38, 36, 35, 40, 40, 38, '%'],
    97001: [38, 38, 37, 35, 40, 40, 38, '%'],
    97601: [38, 38, 37, 35, 40, 40, 39, '%'],
    98001: [39, 38, 37, 35, 40, 40, 39, '%'],
    99201: [39, 39, 37, 35, 40, 40, 39, '%'],
    101201: [39, 39, 37, 35, 41, 41, 39, '%'],
    102001: [39, 39, 38, 35, 41, 41, 39, '%'],
    102201: [39, 39, 38, 36, 41, 41, 39, '%'],
    104401: [39, 39, 38, 36, 41, 41, 40, '%'],
    104801: [40, 39, 38, 36, 41, 41, 40, '%'],
    106201: [40, 40, 38, 36, 41, 41, 40, '%'],
    107401: [40, 40, 39, 36, 41, 41, 40, '%'],
    111801: [40, 40, 39, 37, 41, 41, 40, '%'],
    112001: [40, 40, 39, 37, 42, 42, 41, '%'],
    112601: [41, 40, 39, 37, 42, 42, 41, '%'],
    113601: [41, 40, 40, 37, 42, 42, 41, '%'],
    114001: [41, 41, 40, 37, 42, 42, 41, '%'],
    120601: [41, 41, 41, 37, 42, 42, 41, '%'],
    121001: [41, 41, 41, 37, 42, 42, 42, '%'],
    121601: [42, 41, 41, 37, 42, 42, 42, '%'],
    123201: [42, 42, 41, 38, 42, 42, 42, '%'],
    125201: [42, 42, 41, 38, 43, 43, 42, '%'],
    128401: [42, 42, 42, 38, 43, 43, 42, '%'],
    131601: [42, 42, 42, 38, 43, 43, 43, '%'],
    132201: [43, 42, 42, 38, 43, 43, 43, '%'],
    134001: [43, 43, 42, 38, 43, 43, 43, '%'],
    137401: [43, 43, 43, 38, 43, 43, 43, '%'],
    137601: [43, 43, 43, 39, 43, 43, 43, '%'],
    141801: [43, 43, 43, 39, 44, 44, 43, '%'],
    144201: [43, 43, 43, 39, 44, 44, 44, '%'],
    144801: [44, 43, 43, 39, 44, 44, 44, '%'],
    146801: [44, 44, 43, 39, 44, 44, 44, '%'],
    147601: [44, 44, 44, 39, 44, 44, 44, '%'],
    155401: [44, 44, 44, 40, 44, 44, 44, '%'],
    163601: [45, 45, 45, 40, 45, 45, 45, '%'],
    178801: [45, 45, 45, 41, 45, 45, 45, '%'],
    193401: [46, 46, 46, 41, 46, 46, 46, '%'],
    210201: [46, 46, 46, 42, 46, 46, 46, '%'],
    236401: [47, 47, 47, 42, 47, 47, 47, '%'],
    255201: [47, 47, 47, 43, 47, 47, 47, '%'],
    304001: [48, 48, 48, 43, 48, 48, 48, '%'],
    324401: [48, 48, 48, 44, 48, 48, 48, '%'],
    425801: [49, 49, 49, 44, 49, 49, 49, '%'],
    445601: [49, 49, 49, 45, 49, 49, 49, '%'],
    709601: [50, 50, 50, 45, 50, 50, 50, '%'],
    710801: [50, 50, 50, 46, 50, 50, 50, '%'],
    1755201: [50, 50, 50, 47, 50, 50, 50, '%']
  },
  32: {
    1: [0, 0, 0, 0, 0, 0, 0],
    1801: [134, 0, 133, 0, 134, 13, 134],
    1901: [144, 0, 141, 3, 144, 46, 144],
    2001: [155, 0, 150, 5, 155, 79, 155],
    2101: [157, 0, 150, 7, 157, 112, 157],
    2201: [167, 0, 158, 9, 167, 145, 167],
    2301: [178, 0, 166, 11, 178, 178, 178],
    2401: [188, 0, 175, 13, 211, 211, 188],
    2501: [199, 0, 183, 16, 244, 244, 199],
    2601: [209, 0, 191, 18, 277, 277, 209],
    2701: [220, 0, 200, 20, 310, 310, 220],
    2801: [222, 0, 200, 22, 343, 343, 222],
    2901: [232, 0, 208, 24, 376, 376, 232],
    3001: [243, 0, 216, 26, 409, 409, 243],
    3101: [253, 0, 225, 28, 442, 442, 253],
    3201: [264, 0, 233, 31, 475, 475, 264],
    3301: [274, 0, 241, 33, 508, 508, 274],
    3401: [277, 0, 241, 35, 541, 541, 277],
    3501: [287, 0, 250, 37, 574, 574, 287],
    3601: [298, 0, 258, 39, 607, 607, 298],
    3701: [308, 0, 266, 41, 640, 640, 308],
    3801: [319, 0, 275, 44, 673, 673, 319],
    3901: [329, 0, 283, 50, 706, 706, 329],
    4001: [331, 0, 283, 72, 739, 739, 331],
    4101: [342, 0, 291, 95, 772, 772, 342],
    4201: [352, 0, 300, 117, 805, 805, 352],
    4301: [363, 0, 308, 139, 835, 835, 363],
    4401: [373, 0, 316, 161, 860, 860, 373],
    4501: [383, 0, 325, 183, 888, 888, 383],
    4601: [384, 0, 325, 206, 915, 915, 384],
    4701: [394, 0, 333, 228, 940, 940, 394],
    4801: [405, 8, 342, 250, 967, 967, 405],
    4901: [414, 32, 352, 272, 992, 992, 414],
    5001: [425, 57, 362, 294, 1020, 1020, 425],
    5101: [435, 82, 372, 316, 1047, 1047, 435],
    5201: [445, 107, 382, 338, 1072, 1072, 445],
    5301: [447, 131, 383, 360, 1100, 1100, 447],
    5401: [456, 156, 393, 382, 1124, 1124, 456],
    5501: [467, 181, 403, 403, 1151, 1151, 467],
    5601: [477, 206, 413, 425, 1177, 1177, 477],
    5701: [487, 230, 423, 446, 1202, 1202, 487],
    5801: [497, 252, 433, 467, 1228, 1228, 497],
    5901: [498, 277, 434, 489, 1253, 1253, 498],
    6001: [509, 302, 444, 510, 1280, 1280, 509],
    6101: [519, 327, 454, 532, 1306, 1306, 519],
    6201: [529, 351, 464, 553, 1331, 1331, 529],
    6301: [540, 376, 474, 575, 1357, 1357, 539],
    6401: [560, 401, 484, 596, 1382, 1382, 549],
    6501: [580, 426, 486, 618, 1409, 1409, 551],
    6601: [600, 450, 496, 639, 1436, 1436, 561],
    6701: [620, 475, 506, 661, 1460, 1460, 571],
    6801: [640, 500, 516, 682, 1486, 1486, 581],
    6901: [660, 522, 525, 704, 1511, 1511, 591],
    7001: [680, 547, 535, 725, 1538, 1538, 601],
    7101: [700, 571, 537, 747, 1564, 1564, 603],
    7201: [720, 596, 547, 768, 1589, 1589, 613],
    7301: [740, 621, 557, 789, 1615, 1615, 623],
    7401: [760, 646, 567, 811, 1640, 1640, 633],
    7501: [780, 670, 577, 832, 1667, 1667, 643],
    7601: [800, 695, 587, 854, 1693, 1693, 653],
    7701: [820, 720, 597, 875, 1718, 1718, 663],
    7801: [840, 745, 598, 897, 1744, 1744, 665],
    7901: [860, 769, 608, 918, 1769, 1769, 675],
    8001: [880, 794, 618, 940, 1796, 1796, 685],
    8101: [900, 816, 628, 961, 1822, 1822, 695],
    8201: [920, 841, 638, 983, 1847, 1847, 705],
    8301: [940, 866, 648, 1004, 1873, 1873, 715],
    8401: [960, 890, 649, 1026, 1898, 1898, 717],
    8501: [980, 910, 659, 1047, 1925, 1925, 727],
    8601: [1000, 929, 669, 1069, 1951, 1951, 737],
    8701: [1020, 951, 678, 1090, 1976, 1976, 747],
    8801: [1040, 970, 688, 1111, 2002, 2002, 757],
    8901: [1060, 989, 698, 1133, 2027, 2027, 767],
    9001: [1080, 1009, 699, 1154, 2054, 2054, 769],
    9101: [1100, 1031, 709, 1176, 2080, 2080, 779],
    9201: [1120, 1050, 718, 1197, 2105, 2105, 789],
    9301: [1140, 1069, 728, 1219, 2131, 2131, 799],
    9401: [1160, 1088, 737, 1240, 2156, 2156, 809],
    9501: [1180, 1110, 747, 1262, 2183, 2183, 819],
    9601: [1201, 1129, 748, 1283, 2209, 2209, 821],
    9701: [1220, 1148, 758, 1305, 2234, 2234, 831],
    9801: [1240, 1167, 768, 1326, 2260, 2260, 841],
    9901: [1260, 1188, 777, 1348, 2285, 2285, 851],
    10001: [1281, 1207, 787, 1369, 2312, 2312, 861],
    10101: [1301, 1226, 797, 1391, 2338, 2338, 871],
    10201: [1321, 1245, 806, 1412, 2363, 2363, 881],
    10301: [1341, 1266, 808, 1433, 2389, 2389, 883],
    10401: [1361, 1285, 817, 1455, 2414, 2414, 893],
    10501: [1381, 1304, 827, 1476, 2441, 2441, 903],
    10601: [1401, 1323, 836, 1498, 2467, 2467, 913],
    10701: [1421, 1344, 846, 1519, 2492, 2492, 923],
    10801: [1441, 1363, 856, 1541, 2518, 2518, 933],
    10901: [1461, 1382, 857, 1562, 2543, 2543, 935],
    11001: [1481, 1403, 867, 1584, 2570, 2570, 945],
    11101: [1501, 1422, 876, 1605, 2596, 2596, 955],
    11201: [1521, 1441, 886, 1627, 2621, 2621, 965],
    11301: [1541, 1460, 896, 1648, 2647, 2647, 975],
    11401: [1561, 1481, 905, 1670, 2672, 2672, 985],
    11501: [1581, 1500, 907, 1691, 2699, 2699, 987],
    11601: [1601, 1519, 916, 1713, 2725, 2725, 997],
    11701: [1621, 1538, 926, 1734, 2750, 2750, 1007],
    11801: [1641, 1559, 936, 1755, 2776, 2776, 1017],
    11901: [1661, 1578, 945, 1777, 2806, 2806, 1027],
    12001: [1681, 1597, 955, 1799, 2838, 2838, 1038],
    12101: [1702, 1615, 956, 1821, 2871, 2871, 1040],
    12201: [1722, 1637, 966, 1842, 2903, 2903, 1051],
    12301: [1742, 1656, 975, 1864, 2935, 2935, 1061],
    12401: [1763, 1675, 985, 1886, 2967, 2967, 1072],
    12501: [1783, 1693, 995, 1908, 3000, 3000, 1082],
    12601: [1803, 1715, 1004, 1929, 3032, 3032, 1093],
    12701: [1824, 1734, 1014, 1951, 3064, 3064, 1103],
    12801: [1844, 1753, 1015, 1973, 3096, 3096, 1105],
    12901: [1864, 1774, 1025, 1995, 3129, 3129, 1116],
    13001: [1884, 1793, 1035, 2016, 3161, 3161, 1126],
    13101: [1905, 1812, 1044, 2038, 3193, 3193, 1137],
    13201: [1925, 1830, 1054, 2060, 3225, 3225, 1147],
    13301: [1945, 1852, 1064, 2082, 3258, 3258, 1158],
    13401: [1966, 1871, 1065, 2103, 3290, 3290, 1160],
    13501: [1986, 1890, 1074, 2125, 3322, 3322, 1170],
    13601: [2006, 1908, 1084, 2147, 3357, 3357, 1181],
    13701: [2027, 1930, 1094, 2169, 3395, 3395, 1192],
    13801: [2047, 1949, 1103, 2191, 3430, 3430, 1207],
    13901: [2068, 1968, 1113, 2213, 3464, 3464, 1234],
    14001: [2088, 1986, 1114, 2235, 3499, 3499, 1262],
    14101: [2111, 2011, 1124, 2258, 3534, 3534, 1290],
    14201: [2138, 2037, 1134, 2285, 3570, 3570, 1319],
    14301: [2166, 2067, 1145, 2313, 3604, 3604, 1346],
    14401: [2193, 2094, 1155, 2340, 3638, 3638, 1373],
    14501: [2220, 2121, 1165, 2367, 3672, 3672, 1400],
    14601: [2248, 2150, 1167, 2395, 3706, 3706, 1427],
    14701: [2275, 2177, 1177, 2422, 3742, 3742, 1456],
    14801: [2302, 2207, 1187, 2449, 3776, 3776, 1483],
    14901: [2330, 2234, 1197, 2477, 3810, 3810, 1510],
    15001: [2357, 2260, 1208, 2504, 3844, 3844, 1537],
    15101: [2384, 2290, 1218, 2531, 3878, 3878, 1564],
    15201: [2412, 2317, 1228, 2559, 3914, 3914, 1593],
    15301: [2439, 2346, 1230, 2586, 3948, 3948, 1620],
    15401: [2466, 2373, 1240, 2613, 3982, 3982, 1647],
    15501: [2494, 2400, 1250, 2641, 4016, 4016, 1674],
    15601: [2521, 2430, 1261, 2668, 4049, 4049, 1700],
    15701: [2548, 2457, 1271, 2696, 4086, 4086, 1730],
    15801: [2576, 2486, 1281, 2723, 4120, 4120, 1757],
    15901: [2603, 2513, 1283, 2750, 4154, 4154, 1784],
    16001: [2631, 2540, 1293, 2778, 4188, 4188, 1811],
    16101: [2658, 2570, 1303, 2805, 4221, 4221, 1837],
    16201: [2685, 2596, 1313, 2832, 4258, 4258, 1867],
    16301: [2713, 2626, 1324, 2860, 4292, 4292, 1894],
    16401: [2740, 2653, 1334, 2887, 4326, 4326, 1921],
    16501: [2767, 2680, 1336, 2914, 4359, 4359, 1947],
    16601: [2794, 2709, 1346, 2942, 4393, 4393, 1974],
    16701: [2822, 2736, 1356, 2969, 4430, 4430, 2004],
    16801: [2849, 2766, 1366, 2996, 4464, 4464, 2031],
    16901: [2877, 2793, 1377, 3024, 4497, 4497, 2057],
    17001: [2904, 2819, 1387, 3051, 4531, 4531, 2084],
    17101: [2931, 2849, 1389, 3078, 4565, 4565, 2111],
    17201: [2959, 2876, 1399, 3106, 4602, 4602, 2141],
    17301: [2986, 2905, 1409, 3133, 4635, 4635, 2167],
    17401: [3013, 2932, 1419, 3160, 4669, 4669, 2194],
    17501: [3041, 2959, 1429, 3188, 4703, 4703, 2221],
    17601: [3068, 2989, 1440, 3215, 4737, 4737, 2248],
    17701: [3095, 3016, 1450, 3242, 4773, 4773, 2277],
    17801: [3123, 3045, 1452, 3270, 4807, 4807, 2304],
    17901: [3150, 3072, 1462, 3297, 4841, 4841, 2331],
    18001: [3177, 3099, 1472, 3324, 4875, 4875, 2358],
    18101: [3205, 3129, 1482, 3352, 4909, 4909, 2385],
    18201: [3232, 3155, 1493, 3379, 4945, 4945, 2414],
    18301: [3259, 3185, 1503, 3406, 4979, 4979, 2441],
    18401: [3287, 3212, 1505, 3434, 5013, 5013, 2468],
    18501: [3314, 3239, 1515, 3461, 5047, 5047, 2495],
    18601: [3341, 3268, 1525, 3489, 5081, 5081, 2522],
    18701: [3369, 3295, 1535, 3516, 5117, 5117, 2551],
    18801: [3396, 3325, 1546, 3543, 5151, 5151, 2578],
    18901: [3423, 3352, 1556, 3570, 5185, 5185, 2605],
    19001: [3451, 3381, 1558, 3598, 5219, 5219, 2632],
    19101: [3478, 3408, 1568, 3625, 5253, 5253, 2659],
    19201: [3505, 3435, 1578, 3653, 5289, 5289, 2688],
    19301: [3533, 3464, 1588, 3680, 5323, 5323, 2715],
    19401: [3560, 3491, 1598, 3707, 5357, 5357, 2742],
    19501: [3587, 3521, 1609, 3735, 5391, 5391, 2769],
    19601: [3615, 3547, 1610, 3762, 5424, 5424, 2795],
    19701: [3642, 3573, 1619, 3789, 5461, 5461, 2825],
    19801: [3670, 3602, 1628, 3817, 5495, 5495, 2852],
    19901: [3697, 3628, 1638, 3844, 5529, 5529, 2879],
    20001: [3752, 3682, 1656, 3899, 5596, 5596, 2932],
    20201: [3806, 3737, 1667, 3953, 5667, 5667, 2989],
    20401: [3861, 3792, 1685, 4008, 5734, 5734, 3042],
    20601: [3916, 3844, 1704, 4063, 5805, 5805, 3099],
    20801: [3970, 3898, 1714, 4117, 5872, 5872, 3152],
    21001: [4025, 3953, 1733, 4172, 5940, 5940, 3206],
    21201: [4080, 4008, 1752, 4227, 6010, 6010, 3262],
    21401: [4134, 4063, 1762, 4281, 6078, 6078, 3316],
    21601: [4189, 4115, 1781, 4336, 6148, 6148, 3372],
    21801: [4244, 4169, 1799, 4391, 6216, 6216, 3426],
    22001: [4298, 4224, 1810, 4445, 6284, 6284, 3480],
    22201: [4353, 4279, 1828, 4500, 6354, 6354, 3536],
    22401: [4409, 4333, 1847, 4556, 6423, 6423, 3591],
    22601: [4465, 4385, 1866, 4612, 6495, 6495, 3649],
    22801: [4521, 4440, 1876, 4668, 6564, 6564, 3704],
    23001: [4578, 4495, 1895, 4725, 6633, 6633, 3759],
    23201: [4634, 4549, 1913, 4781, 6705, 6705, 3817],
    23401: [4690, 4604, 1924, 4837, 6775, 6775, 3873],
    23601: [4747, 4659, 1943, 4894, 6847, 6847, 3931],
    23801: [4803, 4711, 1961, 4950, 6916, 6916, 3986],
    24001: [4859, 4766, 1971, 5006, 6985, 6985, 4041],
    24201: [4916, 4823, 1990, 5063, 7057, 7057, 4099],
    24401: [4972, 4878, 2009, 5119, 7127, 7127, 4155],
    24601: [5028, 4932, 2019, 5176, 7199, 7199, 4213],
    24801: [5085, 4987, 2038, 5232, 7268, 7268, 4268],
    25001: [5141, 5044, 2057, 5288, 7337, 7337, 4337],
    25201: [5197, 5099, 2099, 5345, 7409, 7409, 4409],
    25401: [5254, 5154, 2154, 5401, 7479, 7479, 4479],
    25601: [5310, 5211, 2211, 5457, 7551, 7551, 4551],
    25801: [5366, 5266, 2266, 5514, 7620, 7620, 4620],
    26001: [5423, 5320, 2320, 5570, 7689, 7689, 4689],
    26201: [5479, 5378, 2378, 5626, 7761, 7761, 4761],
    26401: [5535, 5432, 2432, 5683, 7831, 7831, 4831],
    26601: [5592, 5487, 2487, 5739, 7903, 7903, 4903],
    26801: [5648, 5544, 2544, 5795, 7972, 7972, 4972],
    27001: [5704, 5599, 2599, 5852, 8041, 8041, 5041],
    27201: [5761, 5654, 2654, 5908, 8113, 8113, 5113],
    27401: [5817, 5711, 2711, 5964, 8183, 8183, 5183],
    27601: [5874, 5766, 2766, 6021, 8255, 8255, 5255],
    27801: [5930, 5820, 2820, 6077, 8324, 8324, 5324],
    28001: [5986, 5875, 2875, 6133, 8393, 8393, 5393],
    28201: [6043, 5932, 2932, 6190, 8465, 8465, 5465],
    28401: [6099, 5987, 2987, 6246, 8535, 8535, 5535],
    28601: [6155, 6042, 3042, 6302, 8607, 8607, 5607],
    28801: [6212, 6099, 3099, 6359, 8676, 8676, 5676],
    29001: [6268, 6154, 3154, 6415, 8745, 8745, 5745],
    29201: [6324, 6208, 3208, 6471, 8817, 8817, 5817],
    29401: [6380, 6266, 3266, 6528, 8887, 8887, 5887],
    29601: [6437, 6320, 3320, 6584, 8959, 8959, 5959],
    29801: [6493, 6375, 3375, 6640, 9028, 9028, 6028],
    30001: [6549, 6433, 3433, 6697, 9097, 9097, 6097],
    30201: [6606, 6489, 3489, 6753, 9169, 9169, 6169],
    30401: [6662, 6545, 3545, 6809, 9239, 9239, 6239],
    30601: [6719, 6601, 3601, 6866, 9311, 9311, 6311],
    30801: [6775, 6660, 3660, 6922, 9380, 9380, 6380],
    31001: [6831, 6716, 3716, 6978, 9450, 9450, 6450],
    31201: [6888, 6772, 3772, 7035, 9521, 9521, 6521],
    31401: [6944, 6831, 3831, 7091, 9591, 9591, 6591],
    31601: [7000, 6887, 3887, 7147, 9663, 9663, 6663],
    31801: [7057, 6943, 3943, 7204, 9732, 9732, 6732],
    32001: [7113, 7001, 4001, 7260, 9801, 9801, 6801],
    32201: [7169, 7057, 4057, 7316, 9873, 9873, 6873],
    32401: [7226, 7113, 4113, 7373, 9943, 9943, 6943],
    32601: [7282, 7172, 4172, 7429, 10015, 10015, 7015],
    32801: [7338, 7228, 4228, 7485, 10084, 10084, 7084],
    33001: [7395, 7284, 4284, 7542, 10153, 10153, 7153],
    33201: [7451, 7343, 4343, 7598, 10225, 10225, 7225],
    33401: [7507, 7399, 4399, 7654, 10295, 10295, 7295],
    33601: [7564, 7455, 4455, 7711, 10367, 10367, 7367],
    33801: [7620, 7511, 4511, 7767, 10436, 10436, 7436],
    34001: [7676, 7569, 4569, 7823, 10505, 10505, 7505],
    34201: [7733, 7625, 4625, 7880, 10577, 10577, 7577],
    34401: [7789, 7681, 4681, 7936, 10644, 10644, 7644],
    34601: [7845, 7737, 4737, 7992, 10708, 10708, 7708],
    34801: [7901, 7791, 4791, 8048, 10772, 10772, 7772],
    35001: [7957, 7847, 4847, 8104, 10836, 10836, 7836],
    35201: [8015, 7905, 4905, 8160, 10900, 10900, 7900],
    35401: [8079, 7969, 4969, 8216, 10964, 10964, 7964],
    35601: [8143, 8033, 5033, 8273, 11028, 11028, 8028],
    35801: [8207, 8097, 5097, 8329, 11092, 11092, 8092],
    36001: [8271, 8161, 5161, 8385, 11156, 11156, 8156],
    36201: [8335, 8225, 5225, 8441, 11220, 11220, 8220],
    36401: [8399, 8289, 5289, 8497, 11284, 11284, 8284],
    36601: [8463, 8353, 5353, 8553, 11348, 11348, 8348],
    36801: [8527, 8417, 5417, 8609, 11412, 11412, 8412],
    37001: [8591, 8481, 5481, 8665, 11476, 11476, 8476],
    37201: [8655, 8545, 5545, 8721, 11540, 11540, 8540],
    37401: [8719, 8609, 5609, 8777, 11604, 11604, 8604],
    37601: [8783, 8673, 5673, 8834, 11668, 11668, 8668],
    37801: [8847, 8737, 5737, 8890, 11732, 11732, 8732],
    38001: [8911, 8801, 5801, 8946, 11796, 11796, 8796],
    38201: [8975, 8865, 5865, 9002, 11860, 11860, 8860],
    38401: [9039, 8929, 5929, 9058, 11924, 11924, 8924],
    38601: [9103, 8993, 5993, 9114, 11988, 11988, 8988],
    38801: [9167, 9057, 6057, 9170, 12052, 12052, 9052],
    39001: [9231, 9121, 6121, 9226, 12116, 12116, 9116],
    39201: [9295, 9185, 6185, 9282, 12180, 12180, 9180],
    39401: [9359, 9250, 6250, 9339, 12244, 12244, 9244],
    39601: [9423, 9313, 6313, 9395, 12308, 12308, 9308],
    39801: [9487, 9377, 6377, 9451, 12372, 12372, 9372],
    40001: [9551, 9441, 6441, 9507, 12436, 12436, 9436],
    40201: [9615, 9505, 6505, 9563, 12500, 12500, 9500],
    40401: [9679, 9569, 6569, 9619, 12564, 12564, 9564],
    40601: [9743, 9633, 6633, 9675, 12628, 12628, 9628],
    40801: [9807, 9697, 6697, 9731, 12692, 12692, 9692],
    41001: [9871, 9761, 6761, 9787, 12756, 12756, 9756],
    41201: [9935, 9825, 6825, 9843, 12820, 12820, 9820],
    41401: [9999, 9889, 6889, 9900, 12884, 12884, 9884],
    41601: [10063, 9953, 6953, 9956, 12948, 12948, 9948],
    41801: [10127, 10017, 7017, 10012, 13012, 13012, 10012],
    42001: [10191, 10081, 7081, 10068, 13076, 13076, 10076],
    42201: [10255, 10145, 7145, 10124, 13140, 13140, 10140],
    42401: [10319, 10209, 7209, 10180, 13204, 13204, 10204],
    42601: [10383, 10273, 7273, 10236, 13268, 13268, 10268],
    42801: [10447, 10337, 7337, 10292, 13332, 13332, 10332],
    43001: [10511, 10401, 7401, 10348, 13396, 13396, 10396],
    43201: [10575, 10465, 7465, 10405, 13460, 13460, 10460],
    43401: [10639, 10529, 7529, 10461, 13524, 13524, 10524],
    43601: [10703, 10593, 7593, 10517, 13588, 13588, 10588],
    43801: [10767, 10657, 7657, 10573, 13652, 13652, 10652],
    44001: [10831, 10721, 7721, 10629, 13716, 13716, 10716],
    44201: [10895, 10785, 7785, 10685, 13780, 13780, 10780],
    44401: [10959, 10850, 7850, 10741, 13844, 13844, 10844],
    44601: [11023, 10913, 7913, 10797, 13908, 13908, 10908],
    44801: [11087, 10977, 7977, 10853, 13972, 13972, 10972],
    45001: [11151, 11041, 8041, 10909, 14036, 14036, 11036],
    45201: [11215, 11105, 8105, 10966, 14100, 14100, 11100],
    45401: [11279, 11169, 8169, 11022, 14164, 14164, 11164],
    45601: [11343, 11233, 8233, 11078, 14228, 14228, 11228],
    45801: [11407, 11297, 8297, 11134, 14292, 14292, 11292],
    46001: [11471, 11361, 8361, 11190, 14356, 14356, 11356],
    46201: [11535, 11425, 8425, 11246, 14420, 14420, 11420],
    46401: [11599, 11489, 8489, 11302, 14484, 14484, 11484],
    46601: [11663, 11553, 8553, 11358, 14548, 14548, 11548],
    46801: [11727, 11617, 8617, 11414, 14612, 14612, 11612],
    47001: [11791, 11681, 8681, 11471, 14676, 14676, 11676],
    47201: [11855, 11745, 8745, 11527, 14740, 14740, 11740],
    47401: [11919, 11809, 8809, 11583, 14804, 14804, 11804],
    47601: [11983, 11873, 8873, 11639, 14868, 14868, 11868],
    47801: [12047, 11937, 8937, 11695, 14932, 14932, 11932],
    48001: [12111, 12001, 9001, 11751, 14996, 14996, 11996],
    48201: [12175, 12065, 9065, 11807, 15060, 15060, 12060],
    48401: [12239, 12129, 9129, 11863, 15124, 15124, 12124],
    48601: [12303, 12193, 9193, 11919, 15188, 15188, 12188],
    48801: [12367, 12257, 9257, 11975, 15252, 15252, 12252],
    49001: [12431, 12321, 9321, 12032, 15316, 15316, 12316],
    49201: [12495, 12385, 9385, 12088, 15380, 15380, 12380],
    49401: [12559, 12450, 9450, 12144, 15444, 15444, 12444],
    49601: [12623, 12513, 9513, 12200, 15508, 15508, 12508],
    49801: [12687, 12577, 9577, 12256, 15572, 15572, 12572],
    50001: [12751, 12641, 9647, 12312, 15636, 15636, 12642],
    50201: [12815, 12740, 9752, 12368, 15700, 15700, 12712],
    50401: [12879, 12841, 9859, 12424, 15764, 15764, 12782],
    50601: [12943, 12945, 9969, 12480, 15828, 15828, 12852],
    50801: [13007, 13050, 10080, 12536, 15892, 15892, 12922],
    51001: [13079, 13153, 10189, 12601, 15964, 15964, 13000],
    51201: [13183, 13257, 10299, 12697, 16068, 16068, 13110],
    51401: [13287, 13361, 10409, 12793, 16172, 16172, 13220],
    51601: [13391, 13465, 10519, 12889, 16276, 16276, 13330],
    51801: [13495, 13569, 10629, 12985, 16380, 16380, 13440],
    52001: [13599, 13671, 10737, 13081, 16484, 16484, 13550],
    52201: [13703, 13775, 10847, 13178, 16588, 16588, 13660],
    52401: [13807, 13879, 10957, 13274, 16692, 16692, 13770],
    52601: [13911, 13983, 11067, 13370, 16796, 16796, 13880],
    52801: [14015, 14087, 11177, 13466, 16900, 16900, 13990],
    53001: [14119, 14191, 11287, 13562, 17004, 17004, 14100],
    53201: [14223, 14295, 11397, 13658, 17108, 17108, 14210],
    53401: [14327, 14399, 11507, 13754, 17212, 17212, 14320],
    53601: [14431, 14500, 11614, 13850, 17316, 17316, 14430],
    53801: [14535, 14604, 11724, 13946, 17420, 17420, 14540],
    54001: [14639, 14708, 11834, 14043, 17525, 17525, 14651],
    54201: [14743, 14812, 11944, 14139, 17628, 17628, 14760],
    54401: [14847, 14916, 12054, 14235, 17732, 17732, 14870],
    54601: [14951, 15020, 12164, 14331, 17836, 17836, 14980],
    54801: [15055, 15124, 12274, 14427, 17940, 17940, 15090],
    55001: [15159, 15228, 12384, 14523, 18044, 18044, 15200],
    55201: [15263, 15332, 12494, 14619, 18148, 18148, 15310],
    55401: [15367, 15433, 12601, 14715, 18252, 18252, 15420],
    55601: [15471, 15537, 12711, 14811, 18356, 18356, 15530],
    55801: [15575, 15641, 12821, 14907, 18460, 18460, 15640],
    56001: [15679, 15708, 12894, 15004, 18564, 18564, 15750],
    56201: [15783, 15772, 12964, 15100, 18668, 18668, 15860],
    56401: [15887, 15836, 13034, 15196, 18772, 18772, 15970],
    56601: [15991, 15900, 13104, 15292, 18876, 18876, 16080],
    56801: [16095, 15977, 13187, 15388, 18980, 18980, 16190],
    57001: [16199, 16081, 13297, 15484, 19084, 19084, 16300],
    57201: [16303, 16185, 13407, 15580, 19188, 19188, 16410],
    57401: [16407, 16289, 13517, 15676, 19292, 19292, 16520],
    57601: [16511, 16393, 13627, 15772, 19396, 19396, 16630],
    57801: [16615, 16497, 13737, 15869, 19500, 19500, 16740],
    58001: [16719, 16601, 13847, 15965, 19604, 19604, 16850],
    58201: [16823, 16705, 13957, 16061, 19708, 19708, 16960],
    58401: [16927, 16809, 14067, 16157, 19812, 19812, 17070],
    58601: [17031, 16913, 14177, 16253, 19916, 19916, 17180],
    58801: [17135, 17017, 14287, 16349, 20020, 20020, 17290],
    59001: [17239, 17121, 14397, 16445, 20125, 20125, 17401],
    59201: [17348, 17230, 14512, 16541, 20228, 20228, 17510],
    59401: [17458, 17338, 14626, 16637, 20332, 20332, 17620],
    59601: [17568, 17451, 14745, 16733, 20436, 20436, 17730],
    59801: [17678, 17559, 14859, 16830, 20540, 20540, 17840],
    60001: [17788, 17667, 14973, 16926, 20644, 20644, 17950],
    60201: [17898, 17780, 15092, 17022, 20748, 20748, 18060],
    60401: [18008, 17888, 15206, 17118, 20852, 20852, 18170],
    60601: [18118, 18001, 15325, 17214, 20956, 20956, 18280],
    60801: [18228, 18109, 15439, 17310, 21060, 21060, 18390],
    61001: [18338, 18218, 15554, 17406, 21164, 21164, 18500],
    61201: [18448, 18330, 15672, 17502, 21268, 21268, 18610],
    61401: [18558, 18439, 15787, 17598, 21372, 21372, 18720],
    61601: [18668, 18547, 15901, 17695, 21476, 21476, 18830],
    61801: [18778, 18660, 16020, 17791, 21580, 21580, 18940],
    62001: [18888, 18768, 16134, 17887, 21684, 21684, 19050],
    62201: [18998, 18881, 16253, 17983, 21788, 21788, 19160],
    62401: [19108, 18989, 16367, 18079, 21892, 21892, 19270],
    62601: [19218, 19097, 16481, 18175, 21996, 21996, 19380],
    62801: [19328, 19210, 16600, 18271, 22100, 22100, 19490],
    63001: [19438, 19318, 16714, 18367, 22204, 22204, 19600],
    63201: [19548, 19427, 16829, 18463, 22308, 22308, 19710],
    63401: [19658, 19539, 16947, 18559, 22412, 22412, 19820],
    63601: [19768, 19648, 17062, 18656, 22516, 22516, 19930],
    63801: [19878, 19760, 17180, 18752, 22620, 22620, 20040],
    64001: [19988, 19869, 17295, 18848, 22725, 22725, 20151],
    64201: [20098, 19977, 17409, 18944, 22828, 22828, 20260],
    64401: [20208, 20090, 17528, 19040, 22932, 22932, 20370],
    64601: [20318, 20198, 17642, 19136, 23036, 23036, 20480],
    64801: [20428, 20306, 17756, 19232, 23140, 23140, 20590],
    65001: [20538, 20419, 17875, 19328, 23244, 23244, 20700],
    65201: [20648, 20527, 17989, 19424, 23348, 23348, 20810],
    65401: [20758, 20640, 18108, 19520, 23452, 23452, 20920],
    65601: [20868, 20748, 18222, 19617, 23556, 23556, 21030],
    65801: [20978, 20857, 18337, 19713, 23660, 23660, 21140],
    66001: [21088, 20969, 18455, 19809, 23764, 23764, 21250],
    66201: [21198, 21078, 18570, 19905, 23868, 23868, 21360],
    66401: [21308, 21186, 18684, 20001, 23972, 23972, 21470],
    66601: [21418, 21299, 18803, 20097, 24076, 24076, 21580],
    66801: [21528, 21407, 18917, 20193, 24180, 24180, 21690],
    67001: [21638, 21520, 19036, 20289, 24284, 24284, 21800],
    67201: [21748, 21628, 19150, 20385, 24388, 24388, 21910],
    67401: [21858, 21736, 19264, 20482, 24492, 24492, 22020],
    67601: [21968, 21849, 19383, 20578, 24596, 24596, 22130],
    67801: [22078, 21957, 19497, 20674, 24700, 24700, 22240],
    68001: [22188, 22070, 19616, 20770, 24804, 24804, 22350],
    68201: [22298, 22178, 19730, 20866, 24908, 24908, 22460],
    68401: [22408, 22287, 19845, 20962, 25012, 25012, 22570],
    68601: [22518, 22400, 19964, 21058, 25116, 25116, 22680],
    68801: [22628, 22508, 20078, 21154, 25220, 25220, 22790],
    69001: [22738, 22616, 20192, 21250, 25325, 25325, 22901],
    69201: [22848, 22729, 20311, 21346, 25428, 25428, 23010],
    69401: [22958, 22837, 20425, 21443, 25532, 25532, 23120],
    69601: [23068, 22950, 20544, 21539, 25636, 25636, 23230],
    69801: [23178, 23058, 20658, 21635, 25740, 25740, 23340],
    70001: [23288, 23166, 20772, 21731, 25844, 25844, 23450],
    70201: [23398, 23279, 20891, 21827, 25948, 25948, 23560],
    70401: [23508, 23387, 21005, 21923, 26052, 26052, 23670],
    70601: [23618, 23496, 21120, 22019, 26156, 26156, 23780],
    70801: [23728, 23608, 21238, 22115, 26260, 26260, 23890],
    71001: [23838, 23717, 21353, 22211, 26364, 26364, 24000],
    71201: [23948, 23829, 21471, 22308, 26468, 26468, 24110],
    71401: [24058, 23938, 21586, 22404, 26572, 26572, 24220],
    71601: [24168, 24046, 21700, 22500, 26676, 26676, 24330],
    71801: [24278, 24159, 21819, 22596, 26780, 26780, 24440],
    72001: [24388, 24267, 21933, 22692, 26884, 26884, 24550],
    72201: [24498, 24375, 22047, 22788, 26988, 26988, 24660],
    72401: [24608, 24488, 22166, 22884, 27092, 27092, 24770],
    72601: [24718, 24596, 22280, 22980, 27196, 27196, 24880],
    72801: [24828, 24709, 22399, 23076, 27300, 27300, 24990],
    73001: [24938, 24817, 22513, 23172, 27404, 27404, 25100],
    73201: [25048, 24926, 22628, 23269, 27508, 27508, 25210],
    73401: [25158, 25038, 22746, 23365, 27612, 27612, 25320],
    73601: [25268, 25147, 22861, 23461, 27716, 27716, 25430],
    73801: [25378, 25255, 22975, 23557, 27820, 27820, 25540],
    74001: [25488, 25368, 23094, 23653, 27925, 27925, 25651],
    74201: [25598, 25476, 23208, 23749, 28028, 28028, 25760],
    74401: [25708, 25589, 23327, 23845, 28132, 28132, 25870],
    74601: [25818, 25697, 23441, 23941, 28236, 28236, 25980],
    74801: [25928, 25805, 23555, 24037, 28340, 28340, 26090],
    75001: [26038, 25918, 23674, 24134, 28444, 28444, 26200],
    75201: [26148, 26026, 23788, 24230, 28548, 28548, 26310],
    75401: [26258, 26135, 23903, 24326, 28652, 28652, 26420],
    75601: [26368, 26247, 24021, 24422, 28756, 28756, 26530],
    75801: [26478, 26356, 24136, 24518, 28860, 28860, 26640],
    76001: [26588, 26468, 24254, 24614, 28964, 28964, 26750],
    76201: [26698, 26577, 24369, 24710, 29068, 29068, 26860],
    76401: [26808, 26685, 24483, 24806, 29172, 29172, 26970],
    76601: [26918, 26798, 24602, 24902, 29276, 29276, 27080],
    76801: [27028, 26906, 24716, 24998, 29380, 29380, 27190],
    77001: [27138, 27019, 24835, 25095, 29484, 29484, 27300],
    77201: [27248, 27127, 24949, 25191, 29588, 29588, 27410],
    77401: [27358, 27235, 25063, 25287, 29692, 29692, 27520],
    77601: [27468, 27348, 25182, 25383, 29796, 29796, 27630],
    77801: [27578, 27456, 25296, 25479, 29900, 29900, 27740],
    78001: [27688, 27565, 25411, 25575, 30004, 30004, 27850],
    78201: [27798, 27677, 25529, 25671, 30108, 30108, 27960],
    78401: [27908, 27786, 25644, 25767, 30212, 30212, 28070],
    78601: [28018, 27898, 25762, 25863, 30316, 30316, 28180],
    78801: [28128, 28007, 25877, 25960, 30420, 30420, 28290],
    79001: [28238, 28115, 25991, 26056, 30525, 30525, 28401],
    79201: [28348, 28228, 26110, 26152, 30628, 30628, 28510],
    79401: [28458, 28336, 26224, 26248, 30732, 30732, 28620],
    79601: [28568, 28444, 26338, 26344, 30836, 30836, 28730],
    79801: [28678, 28557, 26457, 26440, 30940, 30940, 28840],
    80001: [36, 36, 33, 33, 39, 39, 36, '%'],
    81401: [36, 36, 34, 33, 39, 39, 36, '%'],
    81801: [36, 36, 34, 33, 39, 39, 37, '%'],
    82401: [36, 36, 34, 34, 39, 39, 37, '%'],
    82801: [37, 36, 34, 34, 39, 39, 37, '%'],
    83401: [37, 37, 34, 34, 39, 39, 37, '%'],
    84801: [37, 37, 35, 34, 39, 39, 37, '%'],
    85201: [37, 37, 35, 34, 40, 40, 37, '%'],
    86601: [37, 37, 35, 34, 40, 40, 38, '%'],
    87401: [38, 37, 35, 34, 40, 40, 38, '%'],
    88201: [38, 38, 35, 34, 40, 40, 38, '%'],
    88401: [38, 38, 35, 35, 40, 40, 38, '%'],
    88601: [38, 38, 36, 35, 40, 40, 38, '%'],
    91801: [38, 38, 36, 35, 40, 40, 39, '%'],
    92601: [38, 38, 37, 35, 41, 41, 39, '%'],
    92801: [39, 38, 37, 35, 41, 41, 39, '%'],
    93601: [39, 39, 37, 35, 41, 41, 39, '%'],
    95601: [39, 39, 37, 36, 41, 41, 39, '%'],
    97201: [39, 39, 38, 36, 41, 41, 39, '%'],
    97801: [39, 39, 38, 36, 41, 41, 40, '%'],
    98801: [40, 39, 38, 36, 41, 41, 40, '%'],
    99601: [40, 40, 38, 36, 41, 41, 40, '%'],
    101401: [40, 40, 38, 36, 42, 42, 40, '%'],
    102201: [40, 40, 39, 36, 42, 42, 40, '%'],
    103801: [40, 40, 39, 37, 42, 42, 40, '%'],
    104401: [40, 40, 39, 37, 42, 42, 41, '%'],
    105601: [41, 40, 39, 37, 42, 42, 41, '%'],
    106401: [41, 41, 39, 37, 42, 42, 41, '%'],
    107801: [41, 41, 40, 37, 42, 42, 41, '%'],
    112201: [41, 41, 40, 37, 43, 43, 42, '%'],
    113401: [42, 41, 40, 37, 43, 43, 42, '%'],
    113601: [42, 41, 40, 38, 43, 43, 42, '%'],
    113801: [42, 41, 41, 38, 43, 43, 42, '%'],
    114401: [42, 42, 41, 38, 43, 43, 42, '%'],
    120801: [42, 42, 42, 38, 43, 43, 42, '%'],
    121201: [42, 42, 42, 38, 43, 43, 43, '%'],
    122401: [43, 42, 42, 38, 43, 43, 43, '%'],
    123601: [43, 43, 42, 38, 43, 43, 43, '%'],
    125401: [43, 43, 42, 38, 44, 44, 43, '%'],
    125601: [43, 43, 42, 39, 44, 44, 43, '%'],
    128601: [43, 43, 43, 39, 44, 44, 43, '%'],
    131801: [43, 43, 43, 39, 44, 44, 44, '%'],
    133201: [44, 43, 43, 39, 44, 44, 44, '%'],
    134201: [44, 44, 43, 39, 44, 44, 44, '%'],
    137401: [44, 44, 44, 39, 44, 44, 44, '%'],
    140201: [44, 44, 44, 40, 44, 44, 44, '%'],
    142001: [44, 44, 44, 40, 45, 45, 44, '%'],
    144201: [44, 44, 44, 40, 45, 45, 45, '%'],
    145801: [45, 44, 44, 40, 45, 45, 45, '%'],
    147001: [45, 45, 44, 40, 45, 45, 45, '%'],
    147601: [45, 45, 45, 40, 45, 45, 45, '%'],
    158801: [45, 45, 45, 41, 45, 45, 45, '%'],
    163801: [46, 46, 46, 41, 46, 46, 46, '%'],
    183001: [46, 46, 46, 42, 46, 46, 46, '%'],
    193801: [47, 47, 47, 42, 47, 47, 47, '%'],
    216001: [47, 47, 47, 43, 47, 47, 47, '%'],
    236801: [48, 48, 48, 43, 48, 48, 48, '%'],
    263601: [48, 48, 48, 44, 48, 48, 48, '%'],
    304401: [49, 49, 49, 44, 49, 49, 49, '%'],
    337801: [49, 49, 49, 45, 49, 49, 49, '%'],
    426201: [50, 50, 50, 45, 50, 50, 50, '%'],
    470001: [50, 50, 50, 46, 50, 50, 50, '%'],
    710601: [51, 51, 51, 46, 51, 51, 51, '%'],
    773001: [51, 51, 51, 47, 51, 51, 51, '%']
  },
  33: {
    1: [0, 0, 0, 0, 0, 0, 0],
    1801: [134, 0, 133, 0, 134, 14, 134],
    1901: [144, 0, 141, 3, 144, 48, 144],
    2001: [155, 0, 150, 5, 155, 82, 155],
    2101: [157, 0, 150, 7, 157, 116, 157],
    2201: [167, 0, 158, 9, 167, 150, 167],
    2301: [178, 0, 166, 11, 184, 184, 178],
    2401: [188, 0, 175, 13, 218, 218, 188],
    2501: [199, 0, 183, 16, 252, 252, 199],
    2601: [209, 0, 191, 18, 286, 286, 209],
    2701: [220, 0, 200, 20, 320, 320, 220],
    2801: [222, 0, 200, 22, 354, 354, 222],
    2901: [232, 0, 208, 24, 388, 388, 232],
    3001: [243, 0, 216, 26, 422, 422, 243],
    3101: [253, 0, 225, 28, 456, 456, 253],
    3201: [264, 0, 233, 31, 490, 490, 264],
    3301: [274, 0, 241, 33, 524, 524, 274],
    3401: [277, 0, 241, 35, 558, 558, 277],
    3501: [287, 0, 250, 37, 592, 592, 287],
    3601: [298, 0, 258, 39, 626, 626, 298],
    3701: [308, 0, 266, 41, 660, 660, 308],
    3801: [319, 0, 275, 44, 694, 694, 319],
    3901: [329, 0, 283, 50, 728, 728, 329],
    4001: [331, 0, 283, 73, 762, 762, 331],
    4101: [342, 0, 291, 96, 796, 796, 342],
    4201: [352, 0, 300, 119, 830, 830, 352],
    4301: [363, 0, 308, 142, 861, 861, 363],
    4401: [373, 0, 316, 165, 886, 886, 373],
    4501: [383, 0, 325, 188, 915, 915, 383],
    4601: [384, 0, 325, 210, 943, 943, 384],
    4701: [394, 0, 333, 233, 968, 968, 394],
    4801: [405, 8, 342, 256, 997, 997, 405],
    4901: [414, 33, 352, 278, 1022, 1022, 414],
    5001: [425, 59, 362, 301, 1051, 1051, 425],
    5101: [435, 84, 372, 324, 1079, 1079, 435],
    5201: [445, 110, 382, 347, 1104, 1104, 445],
    5301: [447, 135, 383, 369, 1133, 1133, 447],
    5401: [456, 161, 393, 392, 1158, 1158, 456],
    5501: [467, 186, 403, 414, 1185, 1185, 467],
    5601: [477, 212, 413, 436, 1213, 1213, 477],
    5701: [487, 237, 423, 458, 1238, 1238, 487],
    5801: [497, 260, 433, 480, 1266, 1266, 497],
    5901: [498, 286, 434, 502, 1291, 1291, 498],
    6001: [509, 311, 444, 524, 1318, 1318, 509],
    6101: [519, 337, 454, 547, 1346, 1346, 519],
    6201: [534, 362, 464, 569, 1371, 1371, 529],
    6301: [555, 388, 474, 591, 1399, 1399, 539],
    6401: [575, 413, 484, 613, 1424, 1424, 549],
    6501: [596, 439, 486, 635, 1451, 1451, 551],
    6601: [617, 464, 496, 657, 1479, 1479, 561],
    6701: [637, 490, 506, 679, 1504, 1504, 571],
    6801: [658, 515, 516, 701, 1532, 1532, 581],
    6901: [678, 538, 525, 723, 1557, 1557, 591],
    7001: [699, 563, 535, 746, 1584, 1584, 601],
    7101: [720, 589, 537, 768, 1612, 1612, 603],
    7201: [740, 614, 547, 790, 1637, 1637, 613],
    7301: [761, 640, 557, 812, 1665, 1665, 623],
    7401: [782, 665, 567, 834, 1690, 1690, 633],
    7501: [802, 691, 577, 856, 1717, 1717, 643],
    7601: [823, 716, 587, 878, 1745, 1745, 653],
    7701: [843, 742, 597, 900, 1770, 1770, 663],
    7801: [864, 767, 598, 923, 1798, 1798, 665],
    7901: [885, 793, 608, 945, 1823, 1823, 675],
    8001: [905, 818, 618, 967, 1850, 1850, 685],
    8101: [926, 841, 628, 989, 1878, 1878, 695],
    8201: [947, 866, 638, 1011, 1903, 1903, 705],
    8301: [967, 892, 648, 1033, 1931, 1931, 715],
    8401: [988, 917, 649, 1055, 1956, 1956, 717],
    8501: [1008, 937, 659, 1077, 1983, 1983, 727],
    8601: [1029, 957, 669, 1100, 2011, 2011, 737],
    8701: [1050, 980, 678, 1122, 2036, 2036, 747],
    8801: [1070, 1000, 688, 1144, 2064, 2064, 757],
    8901: [1091, 1019, 698, 1166, 2089, 2089, 767],
    9001: [1112, 1039, 699, 1188, 2116, 2116, 769],
    9101: [1132, 1062, 709, 1210, 2144, 2144, 779],
    9201: [1153, 1082, 718, 1232, 2169, 2169, 789],
    9301: [1174, 1102, 728, 1254, 2197, 2197, 799],
    9401: [1194, 1121, 737, 1277, 2222, 2222, 809],
    9501: [1215, 1144, 747, 1299, 2249, 2249, 819],
    9601: [1236, 1163, 748, 1321, 2277, 2277, 821],
    9701: [1256, 1183, 758, 1343, 2302, 2302, 831],
    9801: [1277, 1202, 768, 1365, 2330, 2330, 841],
    9901: [1297, 1224, 777, 1387, 2355, 2355, 851],
    10001: [1318, 1244, 787, 1409, 2382, 2382, 861],
    10101: [1339, 1263, 797, 1432, 2410, 2410, 871],
    10201: [1359, 1282, 806, 1454, 2435, 2435, 881],
    10301: [1380, 1305, 808, 1476, 2463, 2463, 883],
    10401: [1401, 1324, 817, 1498, 2488, 2488, 893],
    10501: [1421, 1343, 827, 1520, 2515, 2515, 903],
    10601: [1442, 1363, 836, 1542, 2543, 2543, 913],
    10701: [1462, 1385, 846, 1564, 2568, 2568, 923],
    10801: [1483, 1404, 856, 1586, 2596, 2596, 933],
    10901: [1504, 1424, 857, 1608, 2621, 2621, 935],
    11001: [1524, 1446, 867, 1631, 2648, 2648, 945],
    11101: [1545, 1465, 876, 1653, 2676, 2676, 955],
    11201: [1566, 1485, 886, 1675, 2701, 2701, 965],
    11301: [1586, 1504, 896, 1697, 2729, 2729, 975],
    11401: [1607, 1526, 905, 1719, 2754, 2754, 985],
    11501: [1627, 1546, 907, 1741, 2781, 2781, 987],
    11601: [1648, 1565, 916, 1763, 2809, 2809, 997],
    11701: [1669, 1584, 926, 1786, 2834, 2834, 1007],
    11801: [1689, 1607, 936, 1808, 2862, 2862, 1017],
    11901: [1710, 1626, 945, 1830, 2892, 2892, 1027],
    12001: [1731, 1645, 955, 1852, 2926, 2926, 1038],
    12101: [1752, 1665, 956, 1875, 2959, 2959, 1040],
    12201: [1773, 1687, 966, 1897, 2992, 2992, 1051],
    12301: [1794, 1706, 975, 1920, 3025, 3025, 1061],
    12401: [1815, 1726, 985, 1942, 3059, 3059, 1072],
    12501: [1836, 1745, 995, 1964, 3092, 3092, 1082],
    12601: [1857, 1767, 1004, 1987, 3125, 3125, 1093],
    12701: [1878, 1787, 1014, 2009, 3158, 3158, 1103],
    12801: [1898, 1806, 1015, 2032, 3192, 3192, 1105],
    12901: [1919, 1828, 1025, 2054, 3225, 3225, 1116],
    13001: [1940, 1848, 1035, 2076, 3258, 3258, 1126],
    13101: [1961, 1867, 1044, 2099, 3291, 3291, 1137],
    13201: [1982, 1886, 1054, 2121, 3325, 3325, 1147],
    13301: [2003, 1909, 1064, 2144, 3358, 3358, 1170],
    13401: [2024, 1928, 1065, 2166, 3391, 3391, 1196],
    13501: [2045, 1947, 1074, 2188, 3424, 3424, 1222],
    13601: [2066, 1967, 1084, 2211, 3460, 3460, 1251],
    13701: [2087, 1989, 1094, 2234, 3499, 3499, 1283],
    13801: [2108, 2008, 1103, 2256, 3535, 3535, 1312],
    13901: [2129, 2028, 1113, 2279, 3571, 3571, 1341],
    14001: [2150, 2047, 1114, 2301, 3607, 3607, 1370],
    14101: [2174, 2072, 1124, 2325, 3643, 3643, 1399],
    14201: [2202, 2100, 1134, 2354, 3680, 3680, 1429],
    14301: [2230, 2130, 1145, 2382, 3715, 3715, 1457],
    14401: [2258, 2158, 1155, 2410, 3750, 3750, 1485],
    14501: [2286, 2186, 1165, 2438, 3785, 3785, 1513],
    14601: [2315, 2216, 1167, 2467, 3820, 3820, 1541],
    14701: [2343, 2244, 1177, 2495, 3858, 3858, 1572],
    14801: [2371, 2274, 1187, 2523, 3893, 3893, 1600],
    14901: [2399, 2302, 1197, 2551, 3928, 3928, 1628],
    15001: [2428, 2330, 1208, 2580, 3963, 3963, 1656],
    15101: [2456, 2360, 1218, 2608, 3997, 3997, 1683],
    15201: [2484, 2388, 1228, 2636, 4035, 4035, 1714],
    15301: [2512, 2418, 1230, 2664, 4070, 4070, 1742],
    15401: [2540, 2446, 1240, 2692, 4105, 4105, 1770],
    15501: [2569, 2474, 1250, 2720, 4140, 4140, 1798],
    15601: [2597, 2504, 1261, 2749, 4175, 4175, 1826],
    15701: [2625, 2532, 1271, 2777, 4212, 4212, 1856],
    15801: [2653, 2563, 1281, 2805, 4247, 4247, 1884],
    15901: [2681, 2590, 1283, 2833, 4282, 4282, 1912],
    16001: [2710, 2618, 1293, 2862, 4317, 4317, 1940],
    16101: [2738, 2648, 1303, 2890, 4352, 4352, 1968],
    16201: [2766, 2676, 1313, 2918, 4390, 4390, 1999],
    16301: [2794, 2707, 1324, 2946, 4425, 4425, 2027],
    16401: [2823, 2734, 1334, 2974, 4460, 4460, 2055],
    16501: [2851, 2762, 1336, 3003, 4495, 4495, 2083],
    16601: [2879, 2793, 1346, 3031, 4530, 4530, 2111],
    16701: [2907, 2820, 1356, 3059, 4567, 4567, 2141],
    16801: [2935, 2851, 1366, 3087, 4602, 4602, 2169],
    16901: [2964, 2878, 1377, 3115, 4637, 4637, 2197],
    17001: [2992, 2906, 1387, 3144, 4672, 4672, 2225],
    17101: [3020, 2937, 1389, 3172, 4707, 4707, 2253],
    17201: [3048, 2964, 1399, 3200, 4745, 4745, 2284],
    17301: [3077, 2995, 1409, 3228, 4780, 4780, 2312],
    17401: [3105, 3023, 1419, 3257, 4814, 4814, 2339],
    17501: [3133, 3050, 1429, 3285, 4849, 4849, 2367],
    17601: [3161, 3081, 1440, 3313, 4884, 4884, 2395],
    17701: [3189, 3108, 1450, 3341, 4922, 4922, 2426],
    17801: [3218, 3139, 1452, 3369, 4957, 4957, 2454],
    17901: [3246, 3167, 1462, 3398, 4992, 4992, 2482],
    18001: [3274, 3194, 1472, 3426, 5027, 5027, 2510],
    18101: [3302, 3225, 1482, 3454, 5062, 5062, 2538],
    18201: [3330, 3253, 1493, 3482, 5099, 5099, 2568],
    18301: [3359, 3283, 1503, 3511, 5134, 5134, 2596],
    18401: [3387, 3311, 1505, 3539, 5169, 5169, 2624],
    18501: [3415, 3338, 1515, 3567, 5204, 5204, 2652],
    18601: [3443, 3369, 1525, 3595, 5239, 5239, 2680],
    18701: [3472, 3397, 1535, 3623, 5277, 5277, 2711],
    18801: [3500, 3427, 1546, 3652, 5312, 5312, 2739],
    18901: [3528, 3455, 1556, 3680, 5347, 5347, 2767],
    19001: [3556, 3485, 1558, 3708, 5382, 5382, 2795],
    19101: [3584, 3513, 1568, 3736, 5416, 5416, 2822],
    19201: [3613, 3541, 1578, 3764, 5454, 5454, 2853],
    19301: [3641, 3571, 1588, 3793, 5489, 5489, 2881],
    19401: [3669, 3599, 1598, 3821, 5524, 5524, 2909],
    19501: [3697, 3629, 1609, 3849, 5559, 5559, 2937],
    19601: [3725, 3656, 1610, 3877, 5594, 5594, 2965],
    19701: [3754, 3683, 1619, 3906, 5631, 5631, 2995],
    19801: [3782, 3713, 1628, 3934, 5666, 5666, 3023],
    19901: [3810, 3739, 1638, 3962, 5701, 5701, 3051],
    20001: [3867, 3796, 1656, 4018, 5771, 5771, 3107],
    20201: [3923, 3852, 1667, 4075, 5844, 5844, 3166],
    20401: [3979, 3909, 1685, 4131, 5914, 5914, 3222],
    20601: [4036, 3963, 1704, 4188, 5986, 5986, 3280],
    20801: [4092, 4019, 1714, 4244, 6056, 6056, 3336],
    21001: [4149, 4075, 1733, 4301, 6126, 6126, 3392],
    21201: [4205, 4132, 1752, 4357, 6199, 6199, 3451],
    21401: [4262, 4188, 1762, 4413, 6268, 6268, 3506],
    21601: [4318, 4242, 1781, 4470, 6341, 6341, 3565],
    21801: [4374, 4299, 1799, 4526, 6411, 6411, 3621],
    22001: [4431, 4355, 1810, 4583, 6481, 6481, 3677],
    22201: [4487, 4411, 1828, 4639, 6553, 6553, 3735],
    22401: [4545, 4468, 1847, 4696, 6624, 6624, 3792],
    22601: [4603, 4522, 1866, 4755, 6698, 6698, 3852],
    22801: [4661, 4578, 1876, 4813, 6770, 6770, 3910],
    23001: [4719, 4634, 1895, 4871, 6841, 6841, 3967],
    23201: [4777, 4691, 1913, 4929, 6916, 6916, 4028],
    23401: [4835, 4747, 1924, 4987, 6987, 6987, 4085],
    23601: [4893, 4804, 1943, 5045, 7061, 7061, 4145],
    23801: [4951, 4857, 1961, 5103, 7133, 7133, 4203],
    24001: [5009, 4914, 1971, 5161, 7204, 7204, 4260],
    24201: [5067, 4973, 2015, 5219, 7279, 7279, 4321],
    24401: [5125, 5029, 2057, 5277, 7350, 7350, 4378],
    24601: [5184, 5086, 2100, 5335, 7424, 7424, 4438],
    24801: [5242, 5142, 2142, 5393, 7496, 7496, 4496],
    25001: [5300, 5201, 2201, 5452, 7567, 7567, 4567],
    25201: [5358, 5258, 2258, 5510, 7642, 7642, 4642],
    25401: [5416, 5314, 2314, 5568, 7713, 7713, 4713],
    25601: [5474, 5374, 2374, 5626, 7787, 7787, 4787],
    25801: [5532, 5430, 2430, 5684, 7859, 7859, 4859],
    26001: [5590, 5486, 2486, 5742, 7930, 7930, 4930],
    26201: [5648, 5545, 2545, 5800, 8005, 8005, 5005],
    26401: [5706, 5602, 2602, 5858, 8076, 8076, 5076],
    26601: [5764, 5658, 2658, 5916, 8150, 8150, 5150],
    26801: [5822, 5717, 2717, 5974, 8222, 8222, 5222],
    27001: [5880, 5774, 2774, 6032, 8293, 8293, 5293],
    27201: [5939, 5830, 2830, 6090, 8368, 8368, 5368],
    27401: [5997, 5889, 2889, 6149, 8439, 8439, 5439],
    27601: [6055, 5946, 2946, 6207, 8513, 8513, 5513],
    27801: [6113, 6002, 3002, 6265, 8585, 8585, 5585],
    28001: [6171, 6059, 3059, 6323, 8656, 8656, 5656],
    28201: [6229, 6118, 3118, 6381, 8731, 8731, 5731],
    28401: [6287, 6174, 3174, 6439, 8802, 8802, 5802],
    28601: [6345, 6231, 3231, 6497, 8876, 8876, 5876],
    28801: [6403, 6290, 3290, 6555, 8948, 8948, 5948],
    29001: [6461, 6346, 3346, 6613, 9019, 9019, 6019],
    29201: [6519, 6403, 3403, 6671, 9094, 9094, 6094],
    29401: [6577, 6462, 3462, 6729, 9165, 9165, 6165],
    29601: [6636, 6518, 3518, 6787, 9239, 9239, 6239],
    29801: [6694, 6575, 3575, 6845, 9311, 9311, 6311],
    30001: [6752, 6635, 3635, 6904, 9382, 9382, 6382],
    30201: [6810, 6693, 3693, 6962, 9457, 9457, 6457],
    30401: [6868, 6751, 3751, 7020, 9528, 9528, 6528],
    30601: [6926, 6808, 3808, 7078, 9602, 9602, 6602],
    30801: [6984, 6869, 3869, 7136, 9674, 9674, 6674],
    31001: [7042, 6927, 3927, 7194, 9745, 9745, 6745],
    31201: [7100, 6984, 3984, 7252, 9820, 9820, 6820],
    31401: [7158, 7045, 4045, 7310, 9891, 9891, 6891],
    31601: [7216, 7103, 4103, 7368, 9965, 9965, 6965],
    31801: [7274, 7160, 4160, 7426, 10037, 10037, 7037],
    32001: [7333, 7221, 4221, 7484, 10108, 10108, 7108],
    32201: [7391, 7279, 4279, 7542, 10183, 10183, 7183],
    32401: [7449, 7336, 4336, 7601, 10254, 10254, 7254],
    32601: [7507, 7397, 4397, 7659, 10328, 10328, 7328],
    32801: [7565, 7455, 4455, 7717, 10400, 10400, 7400],
    33001: [7623, 7512, 4512, 7775, 10471, 10471, 7471],
    33201: [7681, 7573, 4573, 7833, 10546, 10546, 7546],
    33401: [7739, 7631, 4631, 7891, 10617, 10617, 7617],
    33601: [7797, 7688, 4688, 7949, 10691, 10691, 7691],
    33801: [7855, 7746, 4746, 8007, 10763, 10763, 7763],
    34001: [7913, 7807, 4807, 8065, 10834, 10834, 7834],
    34201: [7971, 7864, 4864, 8123, 10909, 10909, 7909],
    34401: [8029, 7922, 4922, 8181, 10977, 10977, 7977],
    34601: [8087, 7980, 4980, 8239, 11043, 11043, 8043],
    34801: [8145, 8035, 5035, 8297, 11109, 11109, 8109],
    35001: [8203, 8093, 5093, 8355, 11175, 11175, 8175],
    35201: [8262, 8153, 5153, 8413, 11241, 11241, 8241],
    35401: [8328, 8219, 5219, 8470, 11307, 11307, 8307],
    35601: [8394, 8285, 5285, 8528, 11373, 11373, 8373],
    35801: [8460, 8351, 5351, 8586, 11439, 11439, 8439],
    36001: [8526, 8417, 5417, 8644, 11505, 11505, 8505],
    36201: [8592, 8483, 5483, 8702, 11571, 11571, 8571],
    36401: [8658, 8549, 5549, 8760, 11637, 11637, 8637],
    36601: [8724, 8615, 5615, 8818, 11703, 11703, 8703],
    36801: [8790, 8681, 5681, 8875, 11769, 11769, 8769],
    37001: [8856, 8747, 5747, 8933, 11835, 11835, 8835],
    37201: [8922, 8813, 5813, 8991, 11901, 11901, 8901],
    37401: [8988, 8879, 5879, 9049, 11967, 11967, 8967],
    37601: [9054, 8945, 5945, 9107, 12033, 12033, 9033],
    37801: [9120, 9011, 6011, 9165, 12099, 12099, 9099],
    38001: [9186, 9077, 6077, 9223, 12165, 12165, 9165],
    38201: [9252, 9143, 6143, 9280, 12231, 12231, 9231],
    38401: [9318, 9209, 6209, 9338, 12297, 12297, 9297],
    38601: [9384, 9275, 6275, 9396, 12363, 12363, 9363],
    38801: [9450, 9341, 6341, 9454, 12429, 12429, 9429],
    39001: [9516, 9407, 6407, 9512, 12495, 12495, 9495],
    39201: [9582, 9473, 6473, 9570, 12561, 12561, 9561],
    39401: [9648, 9539, 6539, 9627, 12627, 12627, 9627],
    39601: [9714, 9605, 6605, 9685, 12693, 12693, 9693],
    39801: [9780, 9671, 6671, 9743, 12759, 12759, 9759],
    40001: [9846, 9737, 6737, 9801, 12825, 12825, 9825],
    40201: [9912, 9803, 6803, 9859, 12891, 12891, 9891],
    40401: [9978, 9869, 6869, 9917, 12957, 12957, 9957],
    40601: [10044, 9935, 6935, 9975, 13023, 13023, 10023],
    40801: [10110, 10001, 7001, 10032, 13089, 13089, 10089],
    41001: [10176, 10067, 7067, 10090, 13155, 13155, 10155],
    41201: [10242, 10133, 7133, 10148, 13221, 13221, 10221],
    41401: [10308, 10199, 7199, 10206, 13287, 13287, 10287],
    41601: [10374, 10265, 7265, 10264, 13353, 13353, 10353],
    41801: [10440, 10331, 7331, 10322, 13419, 13419, 10419],
    42001: [10506, 10397, 7397, 10380, 13485, 13485, 10485],
    42201: [10572, 10463, 7463, 10437, 13551, 13551, 10551],
    42401: [10638, 10529, 7529, 10495, 13617, 13617, 10617],
    42601: [10704, 10595, 7595, 10553, 13683, 13683, 10683],
    42801: [10770, 10661, 7661, 10611, 13749, 13749, 10749],
    43001: [10836, 10727, 7727, 10669, 13815, 13815, 10815],
    43201: [10902, 10793, 7793, 10727, 13881, 13881, 10881],
    43401: [10968, 10859, 7859, 10784, 13947, 13947, 10947],
    43601: [11034, 10925, 7925, 10842, 14013, 14013, 11013],
    43801: [11100, 10991, 7991, 10900, 14079, 14079, 11079],
    44001: [11166, 11057, 8057, 10958, 14145, 14145, 11145],
    44201: [11232, 11123, 8123, 11016, 14211, 14211, 11211],
    44401: [11298, 11189, 8189, 11074, 14277, 14277, 11277],
    44601: [11364, 11255, 8255, 11132, 14343, 14343, 11343],
    44801: [11430, 11321, 8321, 11189, 14409, 14409, 11409],
    45001: [11496, 11387, 8387, 11247, 14475, 14475, 11475],
    45201: [11562, 11453, 8453, 11305, 14541, 14541, 11541],
    45401: [11628, 11519, 8519, 11363, 14607, 14607, 11607],
    45601: [11694, 11585, 8585, 11421, 14673, 14673, 11673],
    45801: [11760, 11651, 8651, 11479, 14739, 14739, 11739],
    46001: [11826, 11717, 8717, 11536, 14805, 14805, 11805],
    46201: [11892, 11783, 8783, 11594, 14871, 14871, 11871],
    46401: [11958, 11849, 8849, 11652, 14937, 14937, 11937],
    46601: [12024, 11915, 8915, 11710, 15003, 15003, 12003],
    46801: [12090, 11981, 8981, 11768, 15069, 15069, 12069],
    47001: [12156, 12047, 9047, 11826, 15135, 15135, 12135],
    47201: [12222, 12113, 9113, 11884, 15201, 15201, 12201],
    47401: [12288, 12179, 9179, 11941, 15267, 15267, 12267],
    47601: [12354, 12245, 9245, 11999, 15333, 15333, 12333],
    47801: [12420, 12311, 9311, 12057, 15399, 15399, 12399],
    48001: [12486, 12377, 9377, 12115, 15465, 15465, 12465],
    48201: [12552, 12443, 9443, 12173, 15531, 15531, 12531],
    48401: [12618, 12509, 9509, 12231, 15597, 15597, 12597],
    48601: [12684, 12575, 9575, 12289, 15663, 15663, 12663],
    48801: [12750, 12641, 9641, 12346, 15729, 15729, 12729],
    49001: [12816, 12707, 9707, 12404, 15795, 15795, 12795],
    49201: [12882, 12773, 9773, 12462, 15861, 15861, 12861],
    49401: [12948, 12839, 9839, 12520, 15927, 15927, 12927],
    49601: [13014, 12905, 9905, 12578, 15993, 15993, 12993],
    49801: [13080, 12971, 9971, 12636, 16059, 16059, 13059],
    50001: [13146, 13037, 10043, 12693, 16125, 16125, 13131],
    50201: [13212, 13139, 10151, 12751, 16191, 16191, 13203],
    50401: [13278, 13243, 10261, 12809, 16257, 16257, 13275],
    50601: [13344, 13351, 10375, 12867, 16323, 16323, 13347],
    50801: [13410, 13458, 10488, 12925, 16389, 16389, 13419],
    51001: [13485, 13565, 10601, 12991, 16464, 16464, 13500],
    51201: [13591, 13672, 10714, 13089, 16570, 16570, 13612],
    51401: [13697, 13780, 10828, 13187, 16676, 16676, 13724],
    51601: [13803, 13887, 10941, 13285, 16782, 16782, 13836],
    51801: [13909, 13994, 11054, 13382, 16888, 16888, 13948],
    52001: [14015, 14099, 11165, 13480, 16994, 16994, 14060],
    52201: [14121, 14206, 11278, 13578, 17100, 17100, 14172],
    52401: [14227, 14313, 11391, 13676, 17206, 17206, 14284],
    52601: [14333, 14420, 11504, 13774, 17312, 17312, 14396],
    52801: [14439, 14528, 11618, 13872, 17418, 17418, 14508],
    53001: [14545, 14635, 11731, 13970, 17524, 17524, 14620],
    53201: [14651, 14742, 11844, 14067, 17630, 17630, 14732],
    53401: [14757, 14849, 11957, 14165, 17736, 17736, 14844],
    53601: [14863, 14954, 12068, 14263, 17842, 17842, 14956],
    53801: [14969, 15061, 12181, 14361, 17948, 17948, 15068],
    54001: [15075, 15168, 12294, 14459, 18054, 18054, 15180],
    54201: [15181, 15276, 12408, 14557, 18160, 18160, 15292],
    54401: [15287, 15383, 12521, 14654, 18266, 18266, 15404],
    54601: [15393, 15490, 12634, 14752, 18372, 18372, 15516],
    54801: [15499, 15597, 12747, 14850, 18478, 18478, 15628],
    55001: [15605, 15705, 12861, 14948, 18584, 18584, 15740],
    55201: [15711, 15812, 12974, 15046, 18690, 18690, 15852],
    55401: [15817, 15916, 13084, 15144, 18796, 18796, 15964],
    55601: [15923, 16024, 13198, 15242, 18902, 18902, 16076],
    55801: [16029, 16131, 13311, 15339, 19008, 19008, 16188],
    56001: [16135, 16200, 13386, 15437, 19114, 19114, 16300],
    56201: [16241, 16266, 13458, 15535, 19220, 19220, 16412],
    56401: [16347, 16332, 13530, 15633, 19326, 19326, 16524],
    56601: [16453, 16398, 13602, 15731, 19432, 19432, 16636],
    56801: [16559, 16477, 13687, 15829, 19538, 19538, 16748],
    57001: [16665, 16583, 13799, 15927, 19644, 19644, 16860],
    57201: [16771, 16689, 13911, 16024, 19750, 19750, 16972],
    57401: [16877, 16795, 14023, 16122, 19856, 19856, 17084],
    57601: [16983, 16901, 14135, 16220, 19962, 19962, 17196],
    57801: [17089, 17007, 14247, 16318, 20068, 20068, 17308],
    58001: [17195, 17113, 14359, 16416, 20174, 20174, 17420],
    58201: [17301, 17219, 14471, 16514, 20280, 20280, 17532],
    58401: [17407, 17325, 14583, 16611, 20386, 20386, 17644],
    58601: [17513, 17431, 14695, 16709, 20492, 20492, 17756],
    58801: [17619, 17537, 14807, 16807, 20598, 20598, 17868],
    59001: [17725, 17643, 14919, 16905, 20704, 20704, 17980],
    59201: [17835, 17753, 15035, 17003, 20810, 20810, 18092],
    59401: [17947, 17864, 15152, 17101, 20916, 20916, 18204],
    59601: [18059, 17979, 15273, 17199, 21022, 21022, 18316],
    59801: [18171, 18089, 15389, 17296, 21128, 21128, 18428],
    60001: [18283, 18199, 15505, 17394, 21234, 21234, 18540],
    60201: [18395, 18314, 15626, 17492, 21340, 21340, 18652],
    60401: [18507, 18425, 15743, 17590, 21446, 21446, 18764],
    60601: [18619, 18539, 15863, 17688, 21552, 21552, 18876],
    60801: [18731, 18650, 15980, 17786, 21658, 21658, 18988],
    61001: [18843, 18760, 16096, 17883, 21764, 21764, 19100],
    61201: [18955, 18875, 16217, 17981, 21870, 21870, 19212],
    61401: [19067, 18986, 16334, 18079, 21976, 21976, 19324],
    61601: [19179, 19096, 16450, 18177, 22082, 22082, 19436],
    61801: [19291, 19211, 16571, 18275, 22188, 22188, 19548],
    62001: [19403, 19321, 16687, 18373, 22294, 22294, 19660],
    62201: [19515, 19436, 16808, 18471, 22400, 22400, 19772],
    62401: [19627, 19546, 16924, 18568, 22506, 22506, 19884],
    62601: [19739, 19657, 17041, 18666, 22612, 22612, 19996],
    62801: [19851, 19772, 17162, 18764, 22718, 22718, 20108],
    63001: [19963, 19882, 17278, 18862, 22824, 22824, 20220],
    63201: [20075, 19993, 17395, 18960, 22930, 22930, 20332],
    63401: [20187, 20107, 17515, 19058, 23036, 23036, 20444],
    63601: [20299, 20218, 17632, 19156, 23142, 23142, 20556],
    63801: [20411, 20333, 17753, 19253, 23248, 23248, 20668],
    64001: [20523, 20443, 17869, 19351, 23354, 23354, 20780],
    64201: [20635, 20553, 17985, 19449, 23460, 23460, 20892],
    64401: [20747, 20668, 18106, 19547, 23566, 23566, 21004],
    64601: [20859, 20779, 18223, 19645, 23672, 23672, 21116],
    64801: [20971, 20889, 18339, 19743, 23778, 23778, 21228],
    65001: [21083, 21004, 18460, 19840, 23884, 23884, 21340],
    65201: [21195, 21114, 18576, 19938, 23990, 23990, 21452],
    65401: [21307, 21229, 18697, 20036, 24096, 24096, 21564],
    65601: [21419, 21340, 18814, 20134, 24202, 24202, 21676],
    65801: [21531, 21450, 18930, 20232, 24308, 24308, 21788],
    66001: [21643, 21565, 19051, 20330, 24414, 24414, 21900],
    66201: [21755, 21675, 19167, 20428, 24520, 24520, 22012],
    66401: [21867, 21786, 19284, 20525, 24626, 24626, 22124],
    66601: [21979, 21901, 19405, 20623, 24732, 24732, 22236],
    66801: [22091, 22011, 19521, 20721, 24838, 24838, 22348],
    67001: [22203, 22126, 19642, 20819, 24944, 24944, 22460],
    67201: [22315, 22236, 19758, 20917, 25050, 25050, 22572],
    67401: [22427, 22347, 19875, 21015, 25156, 25156, 22684],
    67601: [22539, 22461, 19995, 21113, 25262, 25262, 22796],
    67801: [22651, 22572, 20112, 21210, 25368, 25368, 22908],
    68001: [22763, 22687, 20233, 21308, 25474, 25474, 23020],
    68201: [22875, 22797, 20349, 21406, 25580, 25580, 23132],
    68401: [22987, 22908, 20466, 21504, 25686, 25686, 23244],
    68601: [23099, 23022, 20586, 21602, 25792, 25792, 23356],
    68801: [23211, 23133, 20703, 21700, 25898, 25898, 23468],
    69001: [23323, 23243, 20819, 21798, 26004, 26004, 23580],
    69201: [23435, 23358, 20940, 21895, 26110, 26110, 23692],
    69401: [23547, 23468, 21056, 21993, 26216, 26216, 23804],
    69601: [23659, 23583, 21177, 22091, 26322, 26322, 23916],
    69801: [23771, 23694, 21294, 22189, 26428, 26428, 24028],
    70001: [23883, 23804, 21410, 22287, 26534, 26534, 24140],
    70201: [23995, 23919, 21531, 22385, 26640, 26640, 24252],
    70401: [24107, 24029, 21647, 22482, 26746, 26746, 24364],
    70601: [24219, 24140, 21764, 22580, 26852, 26852, 24476],
    70801: [24331, 24255, 21885, 22678, 26958, 26958, 24588],
    71001: [24443, 24365, 22001, 22776, 27064, 27064, 24700],
    71201: [24555, 24480, 22122, 22874, 27170, 27170, 24812],
    71401: [24667, 24590, 22238, 22972, 27276, 27276, 24924],
    71601: [24779, 24701, 22355, 23069, 27382, 27382, 25036],
    71801: [24891, 24816, 22476, 23167, 27488, 27488, 25148],
    72001: [25003, 24926, 22592, 23265, 27594, 27594, 25260],
    72201: [25115, 25036, 22708, 23363, 27700, 27700, 25372],
    72401: [25227, 25151, 22829, 23461, 27806, 27806, 25484],
    72601: [25339, 25262, 22946, 23559, 27912, 27912, 25596],
    72801: [25451, 25376, 23066, 23657, 28018, 28018, 25708],
    73001: [25563, 25487, 23183, 23754, 28124, 28124, 25820],
    73201: [25675, 25597, 23299, 23852, 28230, 28230, 25932],
    73401: [25787, 25712, 23420, 23950, 28336, 28336, 26044],
    73601: [25899, 25823, 23537, 24048, 28442, 28442, 26156],
    73801: [26011, 25933, 23653, 24146, 28548, 28548, 26268],
    74001: [26123, 26048, 23774, 24244, 28654, 28654, 26380],
    74201: [26235, 26158, 23890, 24342, 28760, 28760, 26492],
    74401: [26347, 26273, 24011, 24439, 28866, 28866, 26604],
    74601: [26459, 26383, 24127, 24537, 28972, 28972, 26716],
    74801: [26571, 26494, 24244, 24635, 29078, 29078, 26828],
    75001: [26683, 26609, 24365, 24733, 29184, 29184, 26940],
    75201: [26795, 26719, 24481, 24831, 29290, 29290, 27052],
    75401: [26907, 26830, 24598, 24929, 29396, 29396, 27164],
    75601: [27019, 26944, 24718, 25026, 29502, 29502, 27276],
    75801: [27131, 27055, 24835, 25124, 29608, 29608, 27388],
    76001: [27243, 27170, 24956, 25222, 29714, 29714, 27500],
    76201: [27355, 27280, 25072, 25320, 29820, 29820, 27612],
    76401: [27467, 27390, 25188, 25418, 29926, 29926, 27724],
    76601: [27579, 27505, 25309, 25516, 30032, 30032, 27836],
    76801: [27691, 27616, 25426, 25614, 30138, 30138, 27948],
    77001: [27803, 27731, 25547, 25711, 30244, 30244, 28060],
    77201: [27915, 27841, 25663, 25809, 30350, 30350, 28172],
    77401: [28027, 27951, 25779, 25907, 30456, 30456, 28284],
    77601: [28139, 28066, 25900, 26005, 30562, 30562, 28396],
    77801: [28251, 28177, 26017, 26103, 30668, 30668, 28508],
    78001: [28363, 28287, 26133, 26201, 30774, 30774, 28620],
    78201: [28475, 28402, 26254, 26298, 30880, 30880, 28732],
    78401: [28587, 28512, 26370, 26396, 30986, 30986, 28844],
    78601: [28699, 28627, 26491, 26494, 31092, 31092, 28956],
    78801: [28811, 28738, 26608, 26592, 31198, 31198, 29068],
    79001: [28923, 28848, 26724, 26690, 31304, 31304, 29180],
    79201: [29035, 28963, 26845, 26788, 31410, 31410, 29292],
    79401: [29147, 29073, 26961, 26886, 31516, 31516, 29404],
    79601: [29259, 29184, 27078, 26983, 31622, 31622, 29516],
    79801: [29371, 29298, 27198, 27081, 31728, 31728, 29628],
    80001: [37, 37, 34, 34, 40, 40, 37, '%'],
    81601: [37, 37, 35, 34, 40, 40, 37, '%'],
    82001: [37, 37, 35, 34, 40, 40, 38, '%'],
    83201: [38, 37, 35, 34, 40, 40, 38, '%'],
    83401: [38, 37, 35, 35, 40, 40, 38, '%'],
    83601: [38, 38, 35, 35, 40, 40, 38, '%'],
    85001: [38, 38, 36, 35, 40, 40, 38, '%'],
    85201: [38, 38, 36, 35, 41, 41, 38, '%'],
    86601: [38, 38, 36, 35, 41, 41, 39, '%'],
    88001: [39, 38, 36, 35, 41, 41, 39, '%'],
    88401: [39, 39, 36, 35, 41, 41, 39, '%'],
    88801: [39, 39, 37, 35, 41, 41, 39, '%'],
    89801: [39, 39, 37, 36, 41, 41, 39, '%'],
    91801: [39, 39, 37, 36, 41, 41, 40, '%'],
    92601: [39, 39, 37, 36, 42, 42, 40, '%'],
    93001: [39, 39, 38, 36, 42, 42, 40, '%'],
    93401: [40, 39, 38, 36, 42, 42, 40, '%'],
    93801: [40, 40, 38, 36, 42, 42, 40, '%'],
    97001: [40, 40, 38, 37, 42, 42, 40, '%'],
    97401: [40, 40, 39, 37, 42, 42, 40, '%'],
    97801: [40, 40, 39, 37, 42, 42, 41, '%'],
    99401: [41, 40, 39, 37, 42, 42, 41, '%'],
    99801: [41, 41, 39, 37, 42, 42, 41, '%'],
    101601: [41, 41, 39, 37, 43, 43, 41, '%'],
    102401: [41, 41, 40, 37, 43, 43, 41, '%'],
    104601: [41, 41, 40, 37, 43, 43, 42, '%'],
    105401: [41, 41, 40, 38, 43, 43, 42, '%'],
    106401: [42, 41, 40, 38, 43, 43, 42, '%'],
    106801: [42, 42, 40, 38, 43, 43, 42, '%'],
    108001: [42, 42, 41, 38, 43, 43, 42, '%'],
    112201: [42, 42, 41, 38, 44, 44, 43, '%'],
    114201: [43, 42, 42, 38, 44, 44, 43, '%'],
    114601: [43, 43, 42, 38, 44, 44, 43, '%'],
    115601: [43, 43, 42, 39, 44, 44, 43, '%'],
    121001: [43, 43, 43, 39, 44, 44, 43, '%'],
    121201: [43, 43, 43, 39, 44, 44, 44, '%'],
    123401: [44, 43, 43, 39, 44, 44, 44, '%'],
    123801: [44, 44, 43, 39, 44, 44, 44, '%'],
    125401: [44, 44, 43, 39, 45, 45, 44, '%'],
    127801: [44, 44, 43, 40, 45, 45, 44, '%'],
    128801: [44, 44, 44, 40, 45, 45, 44, '%'],
    131801: [44, 44, 44, 40, 45, 45, 45, '%'],
    134001: [45, 44, 44, 40, 45, 45, 45, '%'],
    134401: [45, 45, 44, 40, 45, 45, 45, '%'],
    137601: [45, 45, 45, 40, 45, 45, 45, '%'],
    142201: [45, 45, 45, 40, 46, 46, 45, '%'],
    143001: [45, 45, 45, 41, 46, 46, 45, '%'],
    144401: [45, 45, 45, 41, 46, 46, 46, '%'],
    146801: [46, 45, 45, 41, 46, 46, 46, '%'],
    147201: [46, 46, 45, 41, 46, 46, 46, '%'],
    147801: [46, 46, 46, 41, 46, 46, 46, '%'],
    162401: [46, 46, 46, 42, 46, 46, 46, '%'],
    164001: [47, 47, 47, 42, 47, 47, 47, '%'],
    187601: [47, 47, 47, 43, 47, 47, 47, '%'],
    194001: [48, 48, 48, 43, 48, 48, 48, '%'],
    222201: [48, 48, 48, 44, 48, 48, 48, '%'],
    237001: [49, 49, 49, 44, 49, 49, 49, '%'],
    272401: [49, 49, 49, 45, 49, 49, 49, '%'],
    304801: [50, 50, 50, 45, 50, 50, 50, '%'],
    352001: [50, 50, 50, 46, 50, 50, 50, '%'],
    426801: [51, 51, 51, 46, 51, 51, 51, '%'],
    497201: [51, 51, 51, 47, 51, 51, 51, '%'],
    711401: [52, 52, 52, 47, 52, 52, 52, '%'],
    846401: [52, 52, 52, 48, 52, 52, 52, '%']
  },
  34: {
    1: [0, 0, 0, 0, 0, 0, 0],
    1801: [134, 0, 133, 0, 134, 14, 134],
    1901: [144, 0, 141, 3, 144, 49, 144],
    2001: [155, 0, 150, 5, 155, 84, 155],
    2101: [157, 0, 150, 7, 157, 119, 157],
    2201: [167, 0, 158, 9, 167, 154, 167],
    2301: [178, 0, 166, 11, 189, 189, 178],
    2401: [188, 0, 175, 13, 224, 224, 188],
    2501: [199, 0, 183, 16, 259, 259, 199],
    2601: [209, 0, 191, 18, 294, 294, 209],
    2701: [220, 0, 200, 20, 329, 329, 220],
    2801: [222, 0, 200, 22, 364, 364, 222],
    2901: [232, 0, 208, 24, 399, 399, 232],
    3001: [243, 0, 216, 26, 434, 434, 243],
    3101: [253, 0, 225, 28, 469, 469, 253],
    3201: [264, 0, 233, 31, 504, 504, 264],
    3301: [274, 0, 241, 33, 539, 539, 274],
    3401: [277, 0, 241, 35, 574, 574, 277],
    3501: [287, 0, 250, 37, 609, 609, 287],
    3601: [298, 0, 258, 39, 644, 644, 298],
    3701: [308, 0, 266, 41, 679, 679, 308],
    3801: [319, 0, 275, 44, 714, 714, 319],
    3901: [329, 0, 283, 50, 749, 749, 329],
    4001: [331, 0, 283, 74, 784, 784, 331],
    4101: [342, 0, 291, 97, 819, 819, 342],
    4201: [352, 0, 300, 121, 854, 854, 352],
    4301: [363, 0, 308, 145, 886, 886, 363],
    4401: [373, 0, 316, 168, 912, 912, 373],
    4501: [383, 0, 325, 192, 942, 942, 383],
    4601: [384, 0, 325, 215, 971, 971, 384],
    4701: [394, 0, 333, 238, 997, 997, 394],
    4801: [405, 8, 342, 262, 1026, 1026, 405],
    4901: [414, 34, 352, 285, 1052, 1052, 414],
    5001: [425, 61, 362, 309, 1082, 1082, 425],
    5101: [435, 87, 372, 332, 1111, 1111, 435],
    5201: [445, 113, 382, 355, 1137, 1137, 445],
    5301: [447, 139, 383, 379, 1166, 1166, 447],
    5401: [456, 166, 393, 402, 1192, 1192, 456],
    5501: [467, 192, 403, 424, 1220, 1220, 467],
    5601: [477, 218, 413, 447, 1249, 1249, 477],
    5701: [487, 244, 423, 470, 1275, 1275, 487],
    5801: [497, 268, 433, 493, 1303, 1303, 497],
    5901: [498, 294, 434, 515, 1329, 1329, 498],
    6001: [509, 320, 444, 538, 1357, 1357, 509],
    6101: [527, 347, 454, 561, 1386, 1386, 519],
    6201: [548, 373, 464, 584, 1412, 1412, 529],
    6301: [569, 399, 474, 607, 1440, 1440, 539],
    6401: [591, 425, 484, 629, 1466, 1466, 549],
    6501: [612, 452, 486, 652, 1494, 1494, 551],
    6601: [633, 478, 496, 675, 1523, 1523, 561],
    6701: [654, 504, 506, 698, 1549, 1549, 571],
    6801: [676, 530, 516, 721, 1577, 1577, 581],
    6901: [697, 554, 525, 743, 1603, 1603, 591],
    7001: [718, 580, 535, 766, 1631, 1631, 601],
    7101: [739, 606, 537, 789, 1660, 1660, 603],
    7201: [761, 632, 547, 812, 1686, 1686, 613],
    7301: [782, 659, 557, 834, 1714, 1714, 623],
    7401: [803, 685, 567, 857, 1740, 1740, 633],
    7501: [824, 711, 577, 880, 1768, 1768, 643],
    7601: [846, 737, 587, 903, 1797, 1797, 653],
    7701: [867, 764, 597, 926, 1823, 1823, 663],
    7801: [888, 790, 598, 948, 1851, 1851, 665],
    7901: [909, 816, 608, 971, 1877, 1877, 675],
    8001: [931, 842, 618, 994, 1905, 1905, 685],
    8101: [952, 866, 628, 1017, 1934, 1934, 695],
    8201: [973, 892, 638, 1040, 1960, 1960, 705],
    8301: [994, 918, 648, 1062, 1988, 1988, 715],
    8401: [1016, 944, 649, 1085, 2014, 2014, 717],
    8501: [1037, 965, 659, 1108, 2042, 2042, 727],
    8601: [1058, 985, 669, 1131, 2071, 2071, 737],
    8701: [1079, 1009, 678, 1153, 2097, 2097, 747],
    8801: [1101, 1029, 688, 1176, 2125, 2125, 757],
    8901: [1122, 1049, 698, 1199, 2151, 2151, 767],
    9001: [1143, 1070, 699, 1222, 2179, 2179, 769],
    9101: [1164, 1093, 709, 1245, 2208, 2208, 779],
    9201: [1186, 1114, 718, 1267, 2234, 2234, 789],
    9301: [1207, 1134, 728, 1290, 2262, 2262, 799],
    9401: [1228, 1154, 737, 1313, 2288, 2288, 809],
    9501: [1249, 1178, 747, 1336, 2316, 2316, 819],
    9601: [1271, 1198, 748, 1359, 2345, 2345, 821],
    9701: [1292, 1218, 758, 1381, 2371, 2371, 831],
    9801: [1313, 1238, 768, 1404, 2399, 2399, 841],
    9901: [1334, 1260, 777, 1427, 2425, 2425, 851],
    10001: [1355, 1280, 787, 1450, 2453, 2453, 861],
    10101: [1377, 1300, 797, 1473, 2482, 2482, 871],
    10201: [1398, 1320, 806, 1495, 2508, 2508, 881],
    10301: [1419, 1343, 808, 1518, 2536, 2536, 883],
    10401: [1440, 1363, 817, 1541, 2562, 2562, 893],
    10501: [1462, 1383, 827, 1564, 2590, 2590, 903],
    10601: [1483, 1403, 836, 1587, 2619, 2619, 913],
    10701: [1504, 1426, 846, 1609, 2645, 2645, 923],
    10801: [1525, 1446, 856, 1632, 2673, 2673, 933],
    10901: [1547, 1466, 857, 1655, 2699, 2699, 935],
    11001: [1568, 1489, 867, 1678, 2727, 2727, 945],
    11101: [1589, 1509, 876, 1700, 2756, 2756, 955],
    11201: [1610, 1529, 886, 1723, 2782, 2782, 965],
    11301: [1632, 1549, 896, 1746, 2810, 2810, 975],
    11401: [1653, 1571, 905, 1769, 2836, 2836, 985],
    11501: [1674, 1591, 907, 1792, 2864, 2864, 987],
    11601: [1695, 1611, 916, 1814, 2893, 2893, 997],
    11701: [1717, 1631, 926, 1837, 2919, 2919, 1007],
    11801: [1738, 1654, 936, 1860, 2947, 2947, 1017],
    11901: [1759, 1674, 945, 1883, 2979, 2979, 1027],
    12001: [1781, 1694, 955, 1906, 3013, 3013, 1038],
    12101: [1802, 1714, 956, 1929, 3047, 3047, 1040],
    12201: [1824, 1737, 966, 1952, 3081, 3081, 1051],
    12301: [1845, 1757, 975, 1975, 3116, 3116, 1061],
    12401: [1867, 1777, 985, 1998, 3150, 3150, 1072],
    12501: [1888, 1797, 995, 2021, 3184, 3184, 1082],
    12601: [1910, 1820, 1004, 2044, 3218, 3218, 1093],
    12701: [1932, 1840, 1014, 2067, 3253, 3253, 1107],
    12801: [1953, 1860, 1015, 2090, 3287, 3287, 1134],
    12901: [1975, 1883, 1025, 2114, 3321, 3321, 1161],
    13001: [1996, 1903, 1035, 2137, 3355, 3355, 1188],
    13101: [2018, 1923, 1044, 2160, 3390, 3390, 1216],
    13201: [2039, 1942, 1054, 2183, 3424, 3424, 1243],
    13301: [2061, 1965, 1064, 2206, 3458, 3458, 1270],
    13401: [2082, 1985, 1065, 2229, 3492, 3492, 1297],
    13501: [2104, 2005, 1074, 2252, 3527, 3527, 1325],
    13601: [2125, 2025, 1084, 2275, 3564, 3564, 1355],
    13701: [2147, 2048, 1094, 2298, 3604, 3604, 1388],
    13801: [2169, 2068, 1103, 2322, 3641, 3641, 1418],
    13901: [2190, 2088, 1113, 2345, 3678, 3678, 1448],
    14001: [2212, 2108, 1114, 2368, 3715, 3715, 1478],
    14101: [2236, 2134, 1124, 2393, 3752, 3752, 1508],
    14201: [2265, 2162, 1134, 2422, 3790, 3790, 1539],
    14301: [2295, 2194, 1145, 2451, 3826, 3826, 1568],
    14401: [2324, 2222, 1155, 2480, 3862, 3862, 1597],
    14501: [2353, 2251, 1165, 2509, 3898, 3898, 1626],
    14601: [2382, 2282, 1167, 2538, 3934, 3934, 1655],
    14701: [2411, 2311, 1177, 2567, 3973, 3973, 1687],
    14801: [2440, 2342, 1187, 2597, 4009, 4009, 1716],
    14901: [2469, 2371, 1197, 2626, 4045, 4045, 1745],
    15001: [2498, 2399, 1208, 2655, 4081, 4081, 1774],
    15101: [2527, 2431, 1218, 2684, 4117, 4117, 1803],
    15201: [2556, 2459, 1228, 2713, 4156, 4156, 1835],
    15301: [2585, 2490, 1230, 2742, 4192, 4192, 1864],
    15401: [2614, 2519, 1240, 2771, 4228, 4228, 1893],
    15501: [2644, 2548, 1250, 2800, 4264, 4264, 1922],
    15601: [2673, 2579, 1261, 2829, 4300, 4300, 1951],
    15701: [2702, 2608, 1271, 2858, 4339, 4339, 1983],
    15801: [2731, 2639, 1281, 2887, 4375, 4375, 2012],
    15901: [2760, 2667, 1283, 2916, 4411, 4411, 2041],
    16001: [2789, 2696, 1293, 2946, 4447, 4447, 2070],
    16101: [2818, 2727, 1303, 2975, 4483, 4483, 2099],
    16201: [2847, 2756, 1313, 3004, 4522, 4522, 2131],
    16301: [2876, 2787, 1324, 3033, 4558, 4558, 2160],
    16401: [2905, 2816, 1334, 3062, 4594, 4594, 2189],
    16501: [2934, 2844, 1336, 3091, 4630, 4630, 2218],
    16601: [2963, 2876, 1346, 3120, 4666, 4666, 2247],
    16701: [2993, 2904, 1356, 3149, 4705, 4705, 2279],
    16801: [3022, 2936, 1366, 3178, 4741, 4741, 2308],
    16901: [3051, 2964, 1377, 3207, 4777, 4777, 2337],
    17001: [3080, 2993, 1387, 3237, 4813, 4813, 2366],
    17101: [3109, 3024, 1389, 3266, 4849, 4849, 2395],
    17201: [3138, 3053, 1399, 3295, 4888, 4888, 2427],
    17301: [3167, 3084, 1409, 3324, 4924, 4924, 2456],
    17401: [3196, 3113, 1419, 3353, 4960, 4960, 2485],
    17501: [3225, 3141, 1429, 3382, 4996, 4996, 2514],
    17601: [3254, 3173, 1440, 3411, 5032, 5032, 2543],
    17701: [3284, 3201, 1450, 3440, 5070, 5070, 2574],
    17801: [3313, 3233, 1452, 3469, 5106, 5106, 2603],
    17901: [3342, 3261, 1462, 3498, 5142, 5142, 2632],
    18001: [3371, 3290, 1472, 3527, 5179, 5179, 2662],
    18101: [3400, 3321, 1482, 3556, 5215, 5215, 2691],
    18201: [3429, 3350, 1493, 3586, 5253, 5253, 2722],
    18301: [3458, 3381, 1503, 3615, 5289, 5289, 2751],
    18401: [3487, 3410, 1505, 3644, 5325, 5325, 2780],
    18501: [3516, 3438, 1515, 3673, 5361, 5361, 2809],
    18601: [3545, 3469, 1525, 3702, 5397, 5397, 2838],
    18701: [3574, 3498, 1535, 3731, 5436, 5436, 2870],
    18801: [3603, 3529, 1546, 3760, 5472, 5472, 2899],
    18901: [3633, 3558, 1556, 3789, 5508, 5508, 2928],
    19001: [3662, 3589, 1558, 3818, 5544, 5544, 2957],
    19101: [3691, 3618, 1568, 3847, 5580, 5580, 2986],
    19201: [3720, 3646, 1578, 3876, 5619, 5619, 3018],
    19301: [3749, 3678, 1588, 3906, 5655, 5655, 3047],
    19401: [3778, 3706, 1598, 3935, 5691, 5691, 3076],
    19501: [3807, 3738, 1609, 3964, 5727, 5727, 3105],
    19601: [3836, 3765, 1610, 3993, 5763, 5763, 3134],
    19701: [3865, 3793, 1619, 4022, 5802, 5802, 3166],
    19801: [3894, 3824, 1628, 4051, 5838, 5838, 3195],
    19901: [3923, 3851, 1638, 4080, 5874, 5874, 3224],
    20001: [3982, 3909, 1656, 4138, 5946, 5946, 3282],
    20201: [4040, 3968, 1667, 4196, 6021, 6021, 3343],
    20401: [4098, 4026, 1685, 4255, 6093, 6093, 3401],
    20601: [4156, 4081, 1704, 4313, 6168, 6168, 3462],
    20801: [4214, 4139, 1714, 4371, 6240, 6240, 3520],
    21001: [4272, 4198, 1733, 4429, 6312, 6312, 3578],
    21201: [4331, 4256, 1752, 4487, 6387, 6387, 3639],
    21401: [4389, 4314, 1762, 4545, 6459, 6459, 3697],
    21601: [4447, 4369, 1781, 4604, 6533, 6533, 3757],
    21801: [4505, 4428, 1799, 4662, 6605, 6605, 3815],
    22001: [4563, 4486, 1810, 4720, 6678, 6678, 3874],
    22201: [4622, 4544, 1828, 4778, 6752, 6752, 3934],
    22401: [4681, 4602, 1847, 4837, 6825, 6825, 3993],
    22601: [4740, 4658, 1866, 4897, 6902, 6902, 4056],
    22801: [4800, 4716, 1876, 4957, 6975, 6975, 4115],
    23001: [4860, 4774, 1900, 5017, 7049, 7049, 4175],
    23201: [4920, 4832, 1944, 5077, 7126, 7126, 4238],
    23401: [4980, 4890, 1988, 5136, 7199, 7199, 4297],
    23601: [5040, 4949, 2033, 5196, 7276, 7276, 4360],
    23801: [5099, 5004, 2074, 5256, 7350, 7350, 4420],
    24001: [5159, 5062, 2118, 5316, 7423, 7423, 4479],
    24201: [5219, 5123, 2165, 5376, 7500, 7500, 4542],
    24401: [5279, 5181, 2209, 5435, 7573, 7573, 4601],
    24601: [5339, 5239, 2253, 5495, 7650, 7650, 4664],
    24801: [5399, 5298, 2298, 5555, 7723, 7723, 4723],
    25001: [5458, 5359, 2359, 5615, 7797, 7797, 4797],
    25201: [5518, 5417, 2417, 5675, 7874, 7874, 4874],
    25401: [5578, 5475, 2475, 5735, 7947, 7947, 4947],
    25601: [5638, 5536, 2536, 5794, 8024, 8024, 5024],
    25801: [5698, 5594, 2594, 5854, 8097, 8097, 5097],
    26001: [5757, 5652, 2652, 5914, 8171, 8171, 5171],
    26201: [5817, 5713, 2713, 5974, 8248, 8248, 5248],
    26401: [5877, 5771, 2771, 6034, 8321, 8321, 5321],
    26601: [5937, 5830, 2830, 6094, 8398, 8398, 5398],
    26801: [5997, 5891, 2891, 6153, 8471, 8471, 5471],
    27001: [6057, 5949, 2949, 6213, 8545, 8545, 5545],
    27201: [6116, 6007, 3007, 6273, 8622, 8622, 5622],
    27401: [6176, 6068, 3068, 6333, 8695, 8695, 5695],
    27601: [6236, 6126, 3126, 6393, 8772, 8772, 5772],
    27801: [6296, 6184, 3184, 6452, 8845, 8845, 5845],
    28001: [6356, 6243, 3243, 6512, 8919, 8919, 5919],
    28201: [6416, 6303, 3303, 6572, 8996, 8996, 5996],
    28401: [6475, 6362, 3362, 6632, 9069, 9069, 6069],
    28601: [6535, 6420, 3420, 6692, 9146, 9146, 6146],
    28801: [6595, 6481, 3481, 6752, 9219, 9219, 6219],
    29001: [6655, 6539, 3539, 6811, 9293, 9293, 6293],
    29201: [6715, 6597, 3597, 6871, 9370, 9370, 6370],
    29401: [6774, 6658, 3658, 6931, 9443, 9443, 6443],
    29601: [6834, 6716, 3716, 6991, 9520, 9520, 6520],
    29801: [6894, 6775, 3775, 7051, 9593, 9593, 6593],
    30001: [6954, 6837, 3837, 7111, 9667, 9667, 6667],
    30201: [7014, 6896, 3896, 7170, 9744, 9744, 6744],
    30401: [7074, 6956, 3956, 7230, 9817, 9817, 6817],
    30601: [7133, 7015, 4015, 7290, 9894, 9894, 6894],
    30801: [7193, 7077, 4077, 7350, 9967, 9967, 6967],
    31001: [7253, 7137, 4137, 7410, 10041, 10041, 7041],
    31201: [7313, 7196, 4196, 7470, 10118, 10118, 7118],
    31401: [7373, 7259, 4259, 7529, 10191, 10191, 7191],
    31601: [7433, 7318, 4318, 7589, 10268, 10268, 7268],
    31801: [7492, 7378, 4378, 7649, 10341, 10341, 7341],
    32001: [7552, 7440, 4440, 7709, 10415, 10415, 7415],
    32201: [7612, 7500, 4500, 7769, 10492, 10492, 7492],
    32401: [7672, 7559, 4559, 7828, 10565, 10565, 7565],
    32601: [7732, 7621, 4621, 7888, 10642, 10642, 7642],
    32801: [7792, 7681, 4681, 7948, 10715, 10715, 7715],
    33001: [7851, 7740, 4740, 8008, 10789, 10789, 7789],
    33201: [7911, 7803, 4803, 8068, 10866, 10866, 7866],
    33401: [7971, 7862, 4862, 8128, 10939, 10939, 7939],
    33601: [8031, 7922, 4922, 8187, 11016, 11016, 8016],
    33801: [8091, 7981, 4981, 8247, 11089, 11089, 8089],
    34001: [8150, 8044, 5044, 8307, 11163, 11163, 8163],
    34201: [8210, 8103, 5103, 8367, 11240, 11240, 8240],
    34401: [8270, 8163, 5163, 8427, 11310, 11310, 8310],
    34601: [8330, 8222, 5222, 8486, 11378, 11378, 8378],
    34801: [8389, 8279, 5279, 8546, 11446, 11446, 8446],
    35001: [8449, 8338, 5338, 8605, 11514, 11514, 8514],
    35201: [8510, 8401, 5401, 8665, 11582, 11582, 8582],
    35401: [8578, 8469, 5469, 8725, 11650, 11650, 8650],
    35601: [8646, 8537, 5537, 8784, 11718, 11718, 8718],
    35801: [8714, 8605, 5605, 8844, 11786, 11786, 8786],
    36001: [8782, 8673, 5673, 8903, 11854, 11854, 8854],
    36201: [8850, 8741, 5741, 8963, 11922, 11922, 8922],
    36401: [8918, 8809, 5809, 9023, 11990, 11990, 8990],
    36601: [8986, 8877, 5877, 9082, 12058, 12058, 9058],
    36801: [9054, 8945, 5945, 9142, 12126, 12126, 9126],
    37001: [9122, 9013, 6013, 9201, 12194, 12194, 9194],
    37201: [9190, 9081, 6081, 9261, 12262, 12262, 9262],
    37401: [9258, 9149, 6149, 9320, 12330, 12330, 9330],
    37601: [9326, 9217, 6217, 9380, 12398, 12398, 9398],
    37801: [9394, 9285, 6285, 9440, 12466, 12466, 9466],
    38001: [9462, 9353, 6353, 9499, 12534, 12534, 9534],
    38201: [9530, 9421, 6421, 9559, 12602, 12602, 9602],
    38401: [9598, 9489, 6489, 9618, 12670, 12670, 9670],
    38601: [9666, 9557, 6557, 9678, 12738, 12738, 9738],
    38801: [9734, 9625, 6625, 9738, 12806, 12806, 9806],
    39001: [9802, 9693, 6693, 9797, 12875, 12875, 9875],
    39201: [9870, 9761, 6761, 9857, 12942, 12942, 9942],
    39401: [9938, 9829, 6829, 9916, 13010, 13010, 10010],
    39601: [10006, 9897, 6897, 9976, 13078, 13078, 10078],
    39801: [10074, 9965, 6965, 10036, 13146, 13146, 10146],
    40001: [10142, 10033, 7033, 10095, 13214, 13214, 10214],
    40201: [10210, 10101, 7101, 10155, 13282, 13282, 10282],
    40401: [10278, 10169, 7169, 10214, 13350, 13350, 10350],
    40601: [10346, 10237, 7237, 10274, 13418, 13418, 10418],
    40801: [10414, 10305, 7305, 10334, 13486, 13486, 10486],
    41001: [10482, 10373, 7373, 10393, 13554, 13554, 10554],
    41201: [10550, 10441, 7441, 10453, 13622, 13622, 10622],
    41401: [10618, 10509, 7509, 10512, 13690, 13690, 10690],
    41601: [10686, 10577, 7577, 10572, 13758, 13758, 10758],
    41801: [10754, 10645, 7645, 10632, 13826, 13826, 10826],
    42001: [10822, 10713, 7713, 10691, 13894, 13894, 10894],
    42201: [10890, 10781, 7781, 10751, 13962, 13962, 10962],
    42401: [10958, 10849, 7849, 10810, 14030, 14030, 11030],
    42601: [11026, 10917, 7917, 10870, 14098, 14098, 11098],
    42801: [11094, 10985, 7985, 10929, 14166, 14166, 11166],
    43001: [11162, 11053, 8053, 10989, 14234, 14234, 11234],
    43201: [11230, 11121, 8121, 11049, 14302, 14302, 11302],
    43401: [11298, 11189, 8189, 11108, 14370, 14370, 11370],
    43601: [11366, 11257, 8257, 11168, 14438, 14438, 11438],
    43801: [11434, 11325, 8325, 11227, 14506, 14506, 11506],
    44001: [11502, 11393, 8393, 11287, 14575, 14575, 11575],
    44201: [11570, 11461, 8461, 11347, 14642, 14642, 11642],
    44401: [11638, 11529, 8529, 11406, 14710, 14710, 11710],
    44601: [11706, 11597, 8597, 11466, 14778, 14778, 11778],
    44801: [11774, 11665, 8665, 11525, 14846, 14846, 11846],
    45001: [11842, 11733, 8733, 11585, 14914, 14914, 11914],
    45201: [11910, 11801, 8801, 11645, 14982, 14982, 11982],
    45401: [11978, 11869, 8869, 11704, 15050, 15050, 12050],
    45601: [12046, 11937, 8937, 11764, 15118, 15118, 12118],
    45801: [12114, 12005, 9005, 11823, 15186, 15186, 12186],
    46001: [12182, 12073, 9073, 11883, 15254, 15254, 12254],
    46201: [12250, 12141, 9141, 11943, 15322, 15322, 12322],
    46401: [12318, 12209, 9209, 12002, 15390, 15390, 12390],
    46601: [12386, 12277, 9277, 12062, 15458, 15458, 12458],
    46801: [12454, 12345, 9345, 12121, 15526, 15526, 12526],
    47001: [12522, 12413, 9413, 12181, 15594, 15594, 12594],
    47201: [12590, 12481, 9481, 12241, 15662, 15662, 12662],
    47401: [12658, 12549, 9549, 12300, 15730, 15730, 12730],
    47601: [12726, 12617, 9617, 12360, 15798, 15798, 12798],
    47801: [12794, 12685, 9685, 12419, 15866, 15866, 12866],
    48001: [12862, 12753, 9753, 12479, 15934, 15934, 12934],
    48201: [12930, 12821, 9821, 12539, 16002, 16002, 13002],
    48401: [12998, 12889, 9889, 12598, 16070, 16070, 13070],
    48601: [13066, 12957, 9957, 12658, 16138, 16138, 13138],
    48801: [13134, 13025, 10025, 12717, 16206, 16206, 13206],
    49001: [13202, 13093, 10093, 12777, 16275, 16275, 13275],
    49201: [13270, 13161, 10161, 12836, 16342, 16342, 13342],
    49401: [13338, 13229, 10229, 12896, 16410, 16410, 13410],
    49601: [13406, 13297, 10297, 12956, 16478, 16478, 13478],
    49801: [13474, 13365, 10365, 13015, 16546, 16546, 13546],
    50001: [13542, 13433, 10439, 13075, 16614, 16614, 13620],
    50201: [13610, 13537, 10549, 13134, 16682, 16682, 13694],
    50401: [13678, 13645, 10663, 13194, 16750, 16750, 13768],
    50601: [13746, 13756, 10780, 13254, 16818, 16818, 13842],
    50801: [13814, 13866, 10896, 13313, 16886, 16886, 13916],
    51001: [13890, 13977, 11013, 13381, 16963, 16963, 13999],
    51201: [13998, 14087, 11129, 13481, 17071, 17071, 14113],
    51401: [14106, 14198, 11246, 13580, 17179, 17179, 14227],
    51601: [14214, 14308, 11362, 13680, 17287, 17287, 14341],
    51801: [14322, 14419, 11479, 13780, 17395, 17395, 14455],
    52001: [14430, 14526, 11592, 13879, 17503, 17503, 14569],
    52201: [14538, 14637, 11709, 13979, 17611, 17611, 14683],
    52401: [14646, 14747, 11825, 14078, 17719, 17719, 14797],
    52601: [14754, 14858, 11942, 14178, 17827, 17827, 14911],
    52801: [14862, 14968, 12058, 14277, 17935, 17935, 15025],
    53001: [14970, 15079, 12175, 14377, 18043, 18043, 15139],
    53201: [15078, 15189, 12291, 14477, 18151, 18151, 15253],
    53401: [15186, 15300, 12408, 14576, 18259, 18259, 15367],
    53601: [15294, 15407, 12521, 14676, 18367, 18367, 15481],
    53801: [15402, 15518, 12638, 14775, 18475, 18475, 15595],
    54001: [15510, 15628, 12754, 14875, 18583, 18583, 15709],
    54201: [15618, 15739, 12871, 14975, 18691, 18691, 15823],
    54401: [15726, 15850, 12988, 15074, 18799, 18799, 15937],
    54601: [15834, 15960, 13104, 15174, 18907, 18907, 16051],
    54801: [15942, 16070, 13220, 15273, 19015, 19015, 16165],
    55001: [16050, 16181, 13337, 15373, 19123, 19123, 16279],
    55201: [16158, 16291, 13453, 15473, 19231, 19231, 16393],
    55401: [16266, 16399, 13567, 15572, 19339, 19339, 16507],
    55601: [16374, 16510, 13684, 15672, 19447, 19447, 16621],
    55801: [16482, 16620, 13800, 15771, 19555, 19555, 16735],
    56001: [16590, 16691, 13877, 15871, 19663, 19663, 16849],
    56201: [16698, 16759, 13951, 15971, 19771, 19771, 16963],
    56401: [16806, 16827, 14025, 16070, 19879, 19879, 17077],
    56601: [16914, 16895, 14099, 16170, 19987, 19987, 17191],
    56801: [17022, 16976, 14186, 16269, 20095, 20095, 17305],
    57001: [17130, 17084, 14300, 16369, 20203, 20203, 17419],
    57201: [17238, 17192, 14414, 16469, 20311, 20311, 17533],
    57401: [17346, 17300, 14528, 16568, 20419, 20419, 17647],
    57601: [17454, 17408, 14642, 16668, 20527, 20527, 17761],
    57801: [17562, 17516, 14756, 16767, 20635, 20635, 17875],
    58001: [17670, 17624, 14870, 16867, 20743, 20743, 17989],
    58201: [17778, 17732, 14984, 16966, 20851, 20851, 18103],
    58401: [17886, 17840, 15098, 17066, 20959, 20959, 18217],
    58601: [17994, 17948, 15212, 17166, 21067, 21067, 18331],
    58801: [18102, 18056, 15326, 17265, 21175, 21175, 18445],
    59001: [18210, 18164, 15440, 17365, 21283, 21283, 18559],
    59201: [18323, 18277, 15559, 17464, 21391, 21391, 18673],
    59401: [18437, 18389, 15677, 17564, 21499, 21499, 18787],
    59601: [18551, 18506, 15800, 17664, 21607, 21607, 18901],
    59801: [18665, 18619, 15919, 17763, 21715, 21715, 19015],
    60001: [18779, 18731, 16037, 17863, 21823, 21823, 19129],
    60201: [18893, 18848, 16160, 17962, 21931, 21931, 19243],
    60401: [19007, 18961, 16279, 18062, 22039, 22039, 19357],
    60601: [19121, 19078, 16402, 18162, 22147, 22147, 19471],
    60801: [19235, 19190, 16520, 18261, 22255, 22255, 19585],
    61001: [19349, 19303, 16639, 18361, 22363, 22363, 19699],
    61201: [19463, 19420, 16762, 18460, 22471, 22471, 19813],
    61401: [19577, 19532, 16880, 18560, 22579, 22579, 19927],
    61601: [19691, 19645, 16999, 18660, 22687, 22687, 20041],
    61801: [19805, 19762, 17122, 18759, 22795, 22795, 20155],
    62001: [19919, 19874, 17240, 18859, 22903, 22903, 20269],
    62201: [20033, 19991, 17363, 18958, 23011, 23011, 20383],
    62401: [20147, 20104, 17482, 19058, 23119, 23119, 20497],
    62601: [20261, 20216, 17600, 19158, 23227, 23227, 20611],
    62801: [20375, 20333, 17723, 19257, 23335, 23335, 20725],
    63001: [20489, 20446, 17842, 19357, 23443, 23443, 20839],
    63201: [20603, 20558, 17960, 19456, 23551, 23551, 20953],
    63401: [20717, 20675, 18083, 19556, 23659, 23659, 21067],
    63601: [20831, 20788, 18202, 19655, 23767, 23767, 21181],
    63801: [20945, 20905, 18325, 19755, 23875, 23875, 21295],
    64001: [21059, 21017, 18443, 19855, 23983, 23983, 21409],
    64201: [21173, 21130, 18562, 19954, 24091, 24091, 21523],
    64401: [21287, 21247, 18685, 20054, 24199, 24199, 21637],
    64601: [21401, 21359, 18803, 20153, 24307, 24307, 21751],
    64801: [21515, 21472, 18922, 20253, 24415, 24415, 21865],
    65001: [21629, 21589, 19045, 20353, 24523, 24523, 21979],
    65201: [21743, 21701, 19163, 20452, 24631, 24631, 22093],
    65401: [21857, 21818, 19286, 20552, 24739, 24739, 22207],
    65601: [21971, 21931, 19405, 20651, 24847, 24847, 22321],
    65801: [22085, 22043, 19523, 20751, 24955, 24955, 22435],
    66001: [22199, 22160, 19646, 20851, 25063, 25063, 22549],
    66201: [22313, 22273, 19765, 20950, 25171, 25171, 22663],
    66401: [22427, 22385, 19883, 21050, 25279, 25279, 22777],
    66601: [22541, 22502, 20006, 21149, 25387, 25387, 22891],
    66801: [22655, 22615, 20125, 21249, 25495, 25495, 23005],
    67001: [22769, 22732, 20248, 21349, 25603, 25603, 23119],
    67201: [22883, 22844, 20366, 21448, 25711, 25711, 23233],
    67401: [22997, 22957, 20485, 21548, 25819, 25819, 23347],
    67601: [23111, 23074, 20608, 21647, 25927, 25927, 23461],
    67801: [23225, 23186, 20726, 21747, 26035, 26035, 23575],
    68001: [23339, 23303, 20849, 21847, 26143, 26143, 23689],
    68201: [23453, 23416, 20968, 21946, 26251, 26251, 23803],
    68401: [23567, 23528, 21086, 22046, 26359, 26359, 23917],
    68601: [23681, 23645, 21209, 22145, 26467, 26467, 24031],
    68801: [23795, 23758, 21328, 22245, 26575, 26575, 24145],
    69001: [23909, 23870, 21446, 22345, 26683, 26683, 24259],
    69201: [24023, 23987, 21569, 22444, 26791, 26791, 24373],
    69401: [24137, 24100, 21688, 22544, 26899, 26899, 24487],
    69601: [24251, 24217, 21811, 22643, 27007, 27007, 24601],
    69801: [24365, 24329, 21929, 22743, 27115, 27115, 24715],
    70001: [24479, 24442, 22048, 22842, 27223, 27223, 24829],
    70201: [24593, 24559, 22171, 22942, 27331, 27331, 24943],
    70401: [24707, 24671, 22289, 23042, 27439, 27439, 25057],
    70601: [24821, 24784, 22408, 23141, 27547, 27547, 25171],
    70801: [24935, 24901, 22531, 23241, 27655, 27655, 25285],
    71001: [25049, 25013, 22649, 23340, 27763, 27763, 25399],
    71201: [25163, 25130, 22772, 23440, 27871, 27871, 25513],
    71401: [25277, 25243, 22891, 23540, 27979, 27979, 25627],
    71601: [25391, 25355, 23009, 23639, 28087, 28087, 25741],
    71801: [25505, 25472, 23132, 23739, 28195, 28195, 25855],
    72001: [25619, 25585, 23251, 23838, 28303, 28303, 25969],
    72201: [25733, 25697, 23369, 23938, 28411, 28411, 26083],
    72401: [25847, 25814, 23492, 24038, 28519, 28519, 26197],
    72601: [25961, 25927, 23611, 24137, 28627, 28627, 26311],
    72801: [26075, 26044, 23734, 24237, 28735, 28735, 26425],
    73001: [26189, 26156, 23852, 24336, 28843, 28843, 26539],
    73201: [26303, 26269, 23971, 24436, 28951, 28951, 26653],
    73401: [26417, 26386, 24094, 24536, 29059, 29059, 26767],
    73601: [26531, 26498, 24212, 24635, 29167, 29167, 26881],
    73801: [26645, 26611, 24331, 24735, 29275, 29275, 26995],
    74001: [26759, 26728, 24454, 24834, 29383, 29383, 27109],
    74201: [26873, 26840, 24572, 24934, 29491, 29491, 27223],
    74401: [26987, 26957, 24695, 25034, 29599, 29599, 27337],
    74601: [27101, 27070, 24814, 25133, 29707, 29707, 27451],
    74801: [27215, 27182, 24932, 25233, 29815, 29815, 27565],
    75001: [27329, 27299, 25055, 25332, 29923, 29923, 27679],
    75201: [27443, 27412, 25174, 25432, 30031, 30031, 27793],
    75401: [27557, 27524, 25292, 25531, 30139, 30139, 27907],
    75601: [27671, 27641, 25415, 25631, 30247, 30247, 28021],
    75801: [27785, 27754, 25534, 25731, 30355, 30355, 28135],
    76001: [27899, 27871, 25657, 25830, 30463, 30463, 28249],
    76201: [28013, 27983, 25775, 25930, 30571, 30571, 28363],
    76401: [28127, 28096, 25894, 26029, 30679, 30679, 28477],
    76601: [28241, 28213, 26017, 26129, 30787, 30787, 28591],
    76801: [28355, 28325, 26135, 26229, 30895, 30895, 28705],
    77001: [28469, 28442, 26258, 26328, 31003, 31003, 28819],
    77201: [28583, 28555, 26377, 26428, 31111, 31111, 28933],
    77401: [28697, 28667, 26495, 26527, 31219, 31219, 29047],
    77601: [28811, 28784, 26618, 26627, 31327, 31327, 29161],
    77801: [28925, 28897, 26737, 26727, 31435, 31435, 29275],
    78001: [29039, 29009, 26855, 26826, 31543, 31543, 29389],
    78201: [29153, 29126, 26978, 26926, 31651, 31651, 29503],
    78401: [29267, 29239, 27097, 27025, 31759, 31759, 29617],
    78601: [29381, 29356, 27220, 27125, 31867, 31867, 29731],
    78801: [29495, 29468, 27338, 27225, 31975, 31975, 29845],
    79001: [29609, 29581, 27457, 27324, 32083, 32083, 29959],
    79201: [29723, 29698, 27580, 27424, 32191, 32191, 30073],
    79401: [29837, 29810, 27698, 27523, 32299, 32299, 30187],
    79601: [29951, 29923, 27817, 27623, 32407, 32407, 30301],
    79801: [30065, 30040, 27940, 27723, 32515, 32515, 30415],
    80001: [38, 38, 35, 35, 41, 41, 38, '%'],
    81801: [38, 38, 36, 35, 41, 41, 38, '%'],
    82001: [38, 38, 36, 35, 41, 41, 39, '%'],
    83801: [39, 38, 36, 35, 41, 41, 39, '%'],
    84001: [39, 39, 36, 35, 41, 41, 39, '%'],
    84601: [39, 39, 36, 36, 41, 41, 39, '%'],
    85201: [39, 39, 37, 36, 41, 41, 39, '%'],
    85401: [39, 39, 37, 36, 42, 42, 39, '%'],
    86601: [39, 39, 37, 36, 42, 42, 40, '%'],
    88601: [40, 39, 37, 36, 42, 42, 40, '%'],
    88801: [40, 40, 37, 36, 42, 42, 40, '%'],
    89001: [40, 40, 38, 36, 42, 42, 40, '%'],
    91001: [40, 40, 38, 37, 42, 42, 40, '%'],
    92001: [40, 40, 38, 37, 42, 42, 41, '%'],
    92801: [40, 40, 38, 37, 43, 43, 41, '%'],
    93201: [40, 40, 39, 37, 43, 43, 41, '%'],
    94001: [41, 40, 39, 37, 43, 43, 41, '%'],
    94201: [41, 41, 39, 37, 43, 43, 41, '%'],
    97601: [41, 41, 40, 37, 43, 43, 41, '%'],
    97801: [41, 41, 40, 37, 43, 43, 42, '%'],
    98401: [41, 41, 40, 38, 43, 43, 42, '%'],
    100201: [42, 42, 40, 38, 43, 43, 42, '%'],
    101601: [42, 42, 40, 38, 44, 44, 42, '%'],
    102601: [42, 42, 41, 38, 44, 44, 42, '%'],
    104601: [42, 42, 41, 38, 44, 44, 43, '%'],
    107001: [43, 43, 41, 38, 44, 44, 43, '%'],
    107201: [43, 43, 41, 39, 44, 44, 43, '%'],
    108201: [43, 43, 42, 39, 44, 44, 43, '%'],
    112401: [43, 43, 42, 39, 45, 45, 44, '%'],
    114401: [43, 43, 43, 39, 45, 45, 44, '%'],
    115001: [44, 44, 43, 39, 45, 45, 44, '%'],
    117601: [44, 44, 43, 40, 45, 45, 44, '%'],
    121201: [44, 44, 44, 40, 45, 45, 44, '%'],
    121401: [44, 44, 44, 40, 45, 45, 45, '%'],
    124001: [44, 45, 44, 40, 45, 45, 45, '%'],
    124201: [45, 45, 44, 40, 45, 45, 45, '%'],
    125601: [45, 45, 44, 40, 46, 46, 45, '%'],
    129001: [45, 45, 45, 40, 46, 46, 45, '%'],
    130201: [45, 45, 45, 41, 46, 46, 45, '%'],
    132001: [45, 45, 45, 41, 46, 46, 46, '%'],
    134801: [45, 46, 45, 41, 46, 46, 46, '%'],
    135001: [46, 46, 45, 41, 46, 46, 46, '%'],
    137801: [46, 46, 46, 41, 46, 46, 46, '%'],
    142401: [46, 46, 46, 41, 47, 47, 46, '%'],
    144601: [46, 46, 46, 41, 47, 47, 47, '%'],
    146001: [46, 46, 46, 42, 47, 47, 47, '%'],
    147401: [46, 47, 46, 42, 47, 47, 47, '%'],
    147801: [47, 47, 46, 42, 47, 47, 47, '%'],
    148001: [47, 47, 47, 42, 47, 47, 47, '%'],
    164201: [48, 48, 48, 42, 48, 48, 48, '%'],
    166001: [48, 48, 48, 43, 48, 48, 48, '%'],
    192201: [48, 48, 48, 44, 48, 48, 48, '%'],
    194201: [49, 49, 49, 44, 49, 49, 49, '%'],
    228601: [49, 49, 49, 45, 49, 49, 49, '%'],
    237401: [50, 50, 50, 45, 50, 50, 50, '%'],
    281801: [50, 50, 50, 46, 50, 50, 50, '%'],
    305201: [51, 51, 51, 46, 51, 51, 51, '%'],
    367401: [51, 51, 51, 47, 51, 51, 51, '%'],
    427201: [52, 52, 52, 47, 52, 52, 52, '%'],
    527401: [52, 52, 52, 48, 52, 52, 52, '%'],
    712201: [53, 53, 53, 48, 53, 53, 53, '%'],
    934201: [53, 53, 53, 49, 53, 53, 53, '%']
  },
  35: {
    1: [0, 0, 0, 0, 0, 0, 0],
    1801: [134, 0, 133, 0, 134, 14, 134],
    1901: [144, 0, 141, 3, 144, 50, 144],
    2001: [155, 0, 150, 5, 155, 86, 155],
    2101: [157, 0, 150, 7, 157, 122, 157],
    2201: [167, 0, 158, 9, 167, 158, 167],
    2301: [178, 0, 166, 11, 194, 194, 178],
    2401: [188, 0, 175, 13, 230, 230, 188],
    2501: [199, 0, 183, 16, 266, 266, 199],
    2601: [209, 0, 191, 18, 302, 302, 209],
    2701: [220, 0, 200, 20, 338, 338, 220],
    2801: [222, 0, 200, 22, 375, 375, 222],
    2901: [232, 0, 208, 24, 410, 410, 232],
    3001: [243, 0, 216, 26, 446, 446, 243],
    3101: [253, 0, 225, 28, 482, 482, 253],
    3201: [264, 0, 233, 31, 518, 518, 264],
    3301: [274, 0, 241, 33, 554, 554, 274],
    3401: [277, 0, 241, 35, 590, 590, 277],
    3501: [287, 0, 250, 37, 626, 626, 287],
    3601: [298, 0, 258, 39, 662, 662, 298],
    3701: [308, 0, 266, 41, 698, 698, 308],
    3801: [319, 0, 275, 44, 734, 734, 319],
    3901: [329, 0, 283, 50, 770, 770, 329],
    4001: [331, 0, 283, 74, 806, 806, 331],
    4101: [342, 0, 291, 99, 842, 842, 342],
    4201: [352, 0, 300, 123, 878, 878, 352],
    4301: [363, 0, 308, 148, 911, 911, 363],
    4401: [373, 0, 316, 172, 938, 938, 373],
    4501: [383, 0, 325, 196, 968, 968, 383],
    4601: [384, 0, 325, 220, 998, 998, 384],
    4701: [394, 0, 333, 244, 1025, 1025, 394],
    4801: [405, 8, 342, 268, 1055, 1055, 405],
    4901: [414, 35, 352, 292, 1082, 1082, 414],
    5001: [425, 62, 362, 316, 1112, 1112, 425],
    5101: [435, 89, 372, 340, 1142, 1142, 435],
    5201: [445, 116, 382, 364, 1169, 1169, 445],
    5301: [447, 143, 383, 388, 1200, 1200, 447],
    5401: [456, 170, 393, 412, 1226, 1226, 456],
    5501: [467, 197, 403, 435, 1255, 1255, 467],
    5601: [477, 225, 413, 459, 1285, 1285, 477],
    5701: [487, 251, 423, 482, 1311, 1311, 487],
    5801: [497, 275, 433, 505, 1340, 1340, 497],
    5901: [498, 302, 434, 529, 1367, 1367, 498],
    6001: [519, 329, 444, 552, 1396, 1396, 509],
    6101: [541, 356, 454, 576, 1426, 1426, 519],
    6201: [562, 383, 464, 599, 1452, 1452, 529],
    6301: [584, 410, 474, 623, 1481, 1481, 539],
    6401: [606, 437, 484, 646, 1508, 1508, 549],
    6501: [628, 464, 486, 669, 1537, 1537, 551],
    6601: [650, 491, 496, 693, 1567, 1567, 561],
    6701: [672, 518, 506, 716, 1593, 1593, 571],
    6801: [693, 545, 516, 740, 1622, 1622, 581],
    6901: [715, 569, 525, 763, 1649, 1649, 591],
    7001: [737, 596, 535, 787, 1678, 1678, 601],
    7101: [759, 623, 537, 810, 1708, 1708, 603],
    7201: [781, 650, 547, 834, 1734, 1734, 613],
    7301: [803, 677, 557, 857, 1763, 1763, 623],
    7401: [825, 704, 567, 880, 1790, 1790, 633],
    7501: [846, 731, 577, 904, 1819, 1819, 643],
    7601: [868, 758, 587, 927, 1849, 1849, 653],
    7701: [890, 785, 597, 951, 1875, 1875, 663],
    7801: [912, 812, 598, 974, 1904, 1904, 665],
    7901: [934, 839, 608, 998, 1931, 1931, 675],
    8001: [956, 866, 618, 1021, 1960, 1960, 685],
    8101: [978, 890, 628, 1045, 1990, 1990, 695],
    8201: [1000, 917, 638, 1068, 2016, 2016, 705],
    8301: [1021, 944, 648, 1091, 2045, 2045, 715],
    8401: [1043, 971, 649, 1115, 2072, 2072, 717],
    8501: [1065, 992, 659, 1138, 2101, 2101, 727],
    8601: [1087, 1013, 669, 1162, 2131, 2131, 737],
    8701: [1109, 1037, 678, 1185, 2157, 2157, 747],
    8801: [1131, 1058, 688, 1209, 2186, 2186, 757],
    8901: [1153, 1079, 698, 1232, 2213, 2213, 767],
    9001: [1174, 1100, 699, 1256, 2242, 2242, 769],
    9101: [1196, 1125, 709, 1279, 2272, 2272, 779],
    9201: [1218, 1145, 718, 1302, 2298, 2298, 789],
    9301: [1240, 1166, 728, 1326, 2327, 2327, 799],
    9401: [1262, 1187, 737, 1349, 2354, 2354, 809],
    9501: [1284, 1211, 747, 1373, 2383, 2383, 819],
    9601: [1306, 1232, 748, 1396, 2413, 2413, 821],
    9701: [1327, 1252, 758, 1420, 2439, 2439, 831],
    9801: [1349, 1273, 768, 1443, 2468, 2468, 841],
    9901: [1371, 1296, 777, 1467, 2495, 2495, 851],
    10001: [1393, 1317, 787, 1490, 2524, 2524, 861],
    10101: [1415, 1338, 797, 1514, 2554, 2554, 871],
    10201: [1437, 1358, 806, 1537, 2580, 2580, 881],
    10301: [1459, 1382, 808, 1560, 2609, 2609, 883],
    10401: [1480, 1402, 817, 1584, 2636, 2636, 893],
    10501: [1502, 1423, 827, 1607, 2665, 2665, 903],
    10601: [1524, 1443, 836, 1631, 2695, 2695, 913],
    10701: [1546, 1467, 846, 1654, 2721, 2721, 923],
    10801: [1568, 1487, 856, 1678, 2750, 2750, 933],
    10901: [1590, 1508, 857, 1701, 2777, 2777, 935],
    11001: [1612, 1531, 867, 1725, 2806, 2806, 945],
    11101: [1633, 1552, 876, 1748, 2836, 2836, 955],
    11201: [1655, 1573, 886, 1771, 2862, 2862, 965],
    11301: [1677, 1593, 896, 1795, 2891, 2891, 975],
    11401: [1699, 1617, 905, 1818, 2918, 2918, 985],
    11501: [1721, 1637, 907, 1842, 2947, 2947, 987],
    11601: [1743, 1658, 916, 1865, 2977, 2977, 997],
    11701: [1765, 1678, 926, 1889, 3003, 3003, 1007],
    11801: [1786, 1702, 936, 1912, 3032, 3032, 1017],
    11901: [1808, 1722, 945, 1936, 3065, 3065, 1027],
    12001: [1831, 1743, 955, 1959, 3100, 3100, 1038],
    12101: [1853, 1763, 956, 1983, 3135, 3135, 1040],
    12201: [1875, 1787, 966, 2007, 3171, 3171, 1060],
    12301: [1897, 1808, 975, 2031, 3206, 3206, 1088],
    12401: [1919, 1828, 985, 2054, 3241, 3241, 1116],
    12501: [1941, 1849, 995, 2078, 3276, 3276, 1144],
    12601: [1963, 1872, 1004, 2102, 3312, 3312, 1173],
    12701: [1986, 1893, 1014, 2126, 3347, 3347, 1201],
    12801: [2008, 1913, 1015, 2149, 3382, 3382, 1229],
    12901: [2030, 1937, 1025, 2173, 3417, 3417, 1257],
    13001: [2052, 1957, 1035, 2197, 3453, 3453, 1286],
    13101: [2074, 1978, 1044, 2220, 3488, 3488, 1314],
    13201: [2096, 1998, 1054, 2244, 3523, 3523, 1342],
    13301: [2118, 2022, 1064, 2268, 3558, 3558, 1370],
    13401: [2141, 2043, 1065, 2292, 3594, 3594, 1399],
    13501: [2163, 2063, 1074, 2315, 3629, 3629, 1427],
    13601: [2185, 2084, 1084, 2339, 3667, 3667, 1458],
    13701: [2207, 2107, 1094, 2363, 3708, 3708, 1492],
    13801: [2230, 2128, 1103, 2387, 3746, 3746, 1523],
    13901: [2252, 2148, 1113, 2411, 3784, 3784, 1554],
    14001: [2274, 2169, 1114, 2435, 3823, 3823, 1586],
    14101: [2299, 2195, 1124, 2460, 3860, 3860, 1616],
    14201: [2329, 2225, 1134, 2490, 3900, 3900, 1649],
    14301: [2359, 2257, 1145, 2520, 3937, 3937, 1679],
    14401: [2389, 2286, 1155, 2550, 3975, 3975, 1710],
    14501: [2419, 2316, 1165, 2580, 4012, 4012, 1740],
    14601: [2449, 2348, 1167, 2610, 4049, 4049, 1770],
    14701: [2479, 2377, 1177, 2640, 4089, 4089, 1803],
    14801: [2509, 2410, 1187, 2670, 4126, 4126, 1833],
    14901: [2539, 2439, 1197, 2700, 4163, 4163, 1863],
    15001: [2569, 2468, 1208, 2730, 4200, 4200, 1893],
    15101: [2599, 2501, 1218, 2760, 4237, 4237, 1923],
    15201: [2629, 2530, 1228, 2790, 4277, 4277, 1956],
    15301: [2659, 2562, 1230, 2820, 4314, 4314, 1986],
    15401: [2689, 2592, 1240, 2850, 4351, 4351, 2016],
    15501: [2718, 2621, 1250, 2880, 4388, 4388, 2046],
    15601: [2748, 2654, 1261, 2910, 4425, 4425, 2076],
    15701: [2778, 2683, 1271, 2940, 4465, 4465, 2109],
    15801: [2808, 2715, 1281, 2970, 4503, 4503, 2140],
    15901: [2838, 2745, 1283, 3000, 4540, 4540, 2170],
    16001: [2868, 2774, 1293, 3030, 4577, 4577, 2200],
    16101: [2898, 2806, 1303, 3060, 4614, 4614, 2230],
    16201: [2928, 2836, 1313, 3090, 4654, 4654, 2263],
    16301: [2958, 2868, 1324, 3120, 4691, 4691, 2293],
    16401: [2988, 2897, 1334, 3149, 4728, 4728, 2323],
    16501: [3018, 2927, 1336, 3179, 4765, 4765, 2353],
    16601: [3048, 2959, 1346, 3209, 4802, 4802, 2383],
    16701: [3078, 2988, 1356, 3239, 4842, 4842, 2416],
    16801: [3108, 3021, 1366, 3269, 4879, 4879, 2446],
    16901: [3138, 3050, 1377, 3299, 4916, 4916, 2476],
    17001: [3168, 3079, 1387, 3329, 4954, 4954, 2507],
    17101: [3198, 3112, 1389, 3359, 4991, 4991, 2537],
    17201: [3228, 3141, 1399, 3389, 5031, 5031, 2570],
    17301: [3258, 3173, 1409, 3419, 5068, 5068, 2600],
    17401: [3288, 3203, 1419, 3449, 5105, 5105, 2630],
    17501: [3318, 3232, 1429, 3479, 5142, 5142, 2660],
    17601: [3348, 3265, 1440, 3509, 5179, 5179, 2690],
    17701: [3378, 3294, 1450, 3539, 5219, 5219, 2723],
    17801: [3408, 3326, 1452, 3569, 5256, 5256, 2753],
    17901: [3437, 3356, 1462, 3599, 5293, 5293, 2783],
    18001: [3467, 3385, 1472, 3629, 5330, 5330, 2813],
    18101: [3497, 3417, 1482, 3659, 5367, 5367, 2843],
    18201: [3527, 3447, 1493, 3689, 5407, 5407, 2876],
    18301: [3557, 3479, 1503, 3719, 5444, 5444, 2906],
    18401: [3587, 3508, 1505, 3749, 5482, 5482, 2937],
    18501: [3617, 3538, 1515, 3779, 5519, 5519, 2967],
    18601: [3647, 3570, 1525, 3809, 5556, 5556, 2997],
    18701: [3677, 3599, 1535, 3839, 5596, 5596, 3030],
    18801: [3707, 3632, 1546, 3868, 5633, 5633, 3060],
    18901: [3737, 3661, 1556, 3898, 5670, 5670, 3090],
    19001: [3767, 3693, 1558, 3928, 5707, 5707, 3120],
    19101: [3797, 3723, 1568, 3958, 5744, 5744, 3150],
    19201: [3827, 3752, 1578, 3988, 5784, 5784, 3183],
    19301: [3857, 3784, 1588, 4018, 5821, 5821, 3213],
    19401: [3887, 3814, 1598, 4048, 5858, 5858, 3243],
    19501: [3917, 3846, 1609, 4078, 5895, 5895, 3273],
    19601: [3947, 3875, 1610, 4108, 5932, 5932, 3303],
    19701: [3977, 3903, 1619, 4138, 5972, 5972, 3336],
    19801: [4007, 3935, 1628, 4168, 6010, 6010, 3367],
    19901: [4037, 3963, 1638, 4198, 6047, 6047, 3397],
    20001: [4097, 4023, 1656, 4258, 6121, 6121, 3457],
    20201: [4157, 4083, 1667, 4318, 6198, 6198, 3520],
    20401: [4216, 4143, 1685, 4378, 6272, 6272, 3580],
    20601: [4276, 4200, 1704, 4438, 6349, 6349, 3643],
    20801: [4336, 4260, 1714, 4498, 6423, 6423, 3703],
    21001: [4396, 4320, 1733, 4558, 6498, 6498, 3764],
    21201: [4456, 4380, 1752, 4618, 6575, 6575, 3827],
    21401: [4516, 4440, 1762, 4677, 6649, 6649, 3887],
    21601: [4576, 4497, 1781, 4737, 6726, 6726, 3950],
    21801: [4636, 4557, 1799, 4797, 6800, 6800, 4010],
    22001: [4696, 4617, 1813, 4857, 6874, 6874, 4070],
    22201: [4756, 4677, 1859, 4917, 6951, 6951, 4133],
    22401: [4817, 4737, 1905, 4978, 7027, 7027, 4195],
    22601: [4878, 4794, 1948, 5040, 7105, 7105, 4259],
    22801: [4940, 4854, 1994, 5101, 7181, 7181, 4321],
    23001: [5001, 4914, 2040, 5163, 7257, 7257, 4383],
    23201: [5063, 4973, 2085, 5224, 7336, 7336, 4448],
    23401: [5124, 5033, 2131, 5286, 7412, 7412, 4510],
    23601: [5186, 5093, 2177, 5347, 7490, 7490, 4574],
    23801: [5248, 5150, 2220, 5409, 7566, 7566, 4636],
    24001: [5309, 5210, 2266, 5470, 7642, 7642, 4698],
    24201: [5371, 5273, 2315, 5532, 7721, 7721, 4763],
    24401: [5432, 5333, 2361, 5594, 7797, 7797, 4825],
    24601: [5494, 5393, 2407, 5655, 7875, 7875, 4889],
    24801: [5555, 5453, 2453, 5717, 7951, 7951, 4951],
    25001: [5617, 5516, 2516, 5778, 8027, 8027, 5027],
    25201: [5679, 5576, 2576, 5840, 8106, 8106, 5106],
    25401: [5740, 5636, 2636, 5901, 8182, 8182, 5182],
    25601: [5802, 5699, 2699, 5963, 8260, 8260, 5260],
    25801: [5863, 5758, 2758, 6025, 8336, 8336, 5336],
    26001: [5925, 5818, 2818, 6086, 8412, 8412, 5412],
    26201: [5986, 5881, 2881, 6148, 8491, 8491, 5491],
    26401: [6048, 5941, 2941, 6209, 8567, 8567, 5567],
    26601: [6110, 6001, 3001, 6271, 8645, 8645, 5645],
    26801: [6171, 6064, 3064, 6332, 8721, 8721, 5721],
    27001: [6233, 6124, 3124, 6394, 8797, 8797, 5797],
    27201: [6294, 6184, 3184, 6456, 8876, 8876, 5876],
    27401: [6356, 6246, 3246, 6517, 8952, 8952, 5952],
    27601: [6417, 6306, 3306, 6579, 9030, 9030, 6030],
    27801: [6479, 6366, 3366, 6640, 9106, 9106, 6106],
    28001: [6540, 6426, 3426, 6702, 9182, 9182, 6182],
    28201: [6602, 6489, 3489, 6763, 9261, 9261, 6261],
    28401: [6664, 6549, 3549, 6825, 9337, 9337, 6337],
    28601: [6725, 6609, 3609, 6887, 9415, 9415, 6415],
    28801: [6787, 6672, 3672, 6948, 9491, 9491, 6491],
    29001: [6848, 6732, 3732, 7010, 9567, 9567, 6567],
    29201: [6910, 6792, 3792, 7071, 9646, 9646, 6646],
    29401: [6971, 6854, 3854, 7133, 9722, 9722, 6722],
    29601: [7033, 6914, 3914, 7194, 9800, 9800, 6800],
    29801: [7095, 6974, 3974, 7256, 9876, 9876, 6876],
    30001: [7156, 7038, 4038, 7318, 9952, 9952, 6952],
    30201: [7218, 7099, 4099, 7379, 10031, 10031, 7031],
    30401: [7279, 7161, 4161, 7441, 10107, 10107, 7107],
    30601: [7341, 7222, 4222, 7502, 10185, 10185, 7185],
    30801: [7402, 7286, 4286, 7564, 10261, 10261, 7261],
    31001: [7464, 7347, 4347, 7625, 10337, 10337, 7337],
    31201: [7526, 7409, 4409, 7687, 10416, 10416, 7416],
    31401: [7587, 7473, 4473, 7749, 10492, 10492, 7492],
    31601: [7649, 7534, 4534, 7810, 10570, 10570, 7570],
    31801: [7710, 7595, 4595, 7872, 10646, 10646, 7646],
    32001: [7772, 7659, 4659, 7933, 10722, 10722, 7722],
    32201: [7833, 7721, 4721, 7995, 10801, 10801, 7801],
    32401: [7895, 7782, 4782, 8056, 10877, 10877, 7877],
    32601: [7957, 7846, 4846, 8118, 10955, 10955, 7955],
    32801: [8018, 7907, 4907, 8180, 11031, 11031, 8031],
    33001: [8080, 7969, 4969, 8241, 11107, 11107, 8107],
    33201: [8141, 8033, 5033, 8303, 11186, 11186, 8186],
    33401: [8203, 8094, 5094, 8364, 11262, 11262, 8262],
    33601: [8264, 8155, 5155, 8426, 11340, 11340, 8340],
    33801: [8326, 8217, 5217, 8487, 11416, 11416, 8416],
    34001: [8388, 8281, 5281, 8549, 11492, 11492, 8492],
    34201: [8449, 8342, 5342, 8611, 11571, 11571, 8571],
    34401: [8511, 8403, 5403, 8672, 11644, 11644, 8644],
    34601: [8572, 8464, 5464, 8733, 11714, 11714, 8714],
    34801: [8633, 8523, 5523, 8795, 11784, 11784, 8784],
    35001: [8695, 8584, 5584, 8856, 11854, 11854, 8854],
    35201: [8757, 8648, 5648, 8917, 11924, 11924, 8924],
    35401: [8827, 8718, 5718, 8979, 11994, 11994, 8994],
    35601: [8897, 8788, 5788, 9040, 12064, 12064, 9064],
    35801: [8967, 8858, 5858, 9101, 12134, 12134, 9134],
    36001: [9037, 8928, 5928, 9163, 12204, 12204, 9204],
    36201: [9107, 8998, 5998, 9224, 12274, 12274, 9274],
    36401: [9177, 9068, 6068, 9285, 12344, 12344, 9344],
    36601: [9247, 9138, 6138, 9347, 12414, 12414, 9414],
    36801: [9317, 9208, 6208, 9408, 12484, 12484, 9484],
    37001: [9387, 9278, 6278, 9469, 12554, 12554, 9554],
    37201: [9457, 9348, 6348, 9531, 12624, 12624, 9624],
    37401: [9527, 9418, 6418, 9592, 12694, 12694, 9694],
    37601: [9597, 9488, 6488, 9653, 12764, 12764, 9764],
    37801: [9667, 9558, 6558, 9715, 12834, 12834, 9834],
    38001: [9737, 9628, 6628, 9776, 12904, 12904, 9904],
    38201: [9807, 9698, 6698, 9837, 12974, 12974, 9974],
    38401: [9877, 9768, 6768, 9899, 13044, 13044, 10044],
    38601: [9947, 9838, 6838, 9960, 13114, 13114, 10114],
    38801: [10017, 9908, 6908, 10021, 13184, 13184, 10184],
    39001: [10087, 9978, 6978, 10083, 13254, 13254, 10254],
    39201: [10157, 10048, 7048, 10144, 13324, 13324, 10324],
    39401: [10227, 10118, 7118, 10205, 13394, 13394, 10394],
    39601: [10297, 10188, 7188, 10267, 13464, 13464, 10464],
    39801: [10367, 10258, 7258, 10328, 13534, 13534, 10534],
    40001: [10437, 10328, 7328, 10389, 13604, 13604, 10604],
    40201: [10507, 10398, 7398, 10451, 13674, 13674, 10674],
    40401: [10577, 10468, 7468, 10512, 13744, 13744, 10744],
    40601: [10647, 10538, 7538, 10573, 13814, 13814, 10814],
    40801: [10717, 10608, 7608, 10635, 13884, 13884, 10884],
    41001: [10787, 10678, 7678, 10696, 13954, 13954, 10954],
    41201: [10857, 10748, 7748, 10757, 14024, 14024, 11024],
    41401: [10927, 10818, 7818, 10819, 14094, 14094, 11094],
    41601: [10997, 10888, 7888, 10880, 14164, 14164, 11164],
    41801: [11067, 10958, 7958, 10941, 14234, 14234, 11234],
    42001: [11137, 11028, 8028, 11003, 14304, 14304, 11304],
    42201: [11207, 11098, 8098, 11064, 14374, 14374, 11374],
    42401: [11277, 11168, 8168, 11125, 14444, 14444, 11444],
    42601: [11347, 11238, 8238, 11187, 14514, 14514, 11514],
    42801: [11417, 11308, 8308, 11248, 14584, 14584, 11584],
    43001: [11487, 11378, 8378, 11309, 14654, 14654, 11654],
    43201: [11557, 11448, 8448, 11371, 14724, 14724, 11724],
    43401: [11627, 11518, 8518, 11432, 14794, 14794, 11794],
    43601: [11697, 11588, 8588, 11493, 14864, 14864, 11864],
    43801: [11767, 11658, 8658, 11555, 14934, 14934, 11934],
    44001: [11837, 11728, 8728, 11616, 15004, 15004, 12004],
    44201: [11907, 11798, 8798, 11677, 15074, 15074, 12074],
    44401: [11977, 11868, 8868, 11739, 15144, 15144, 12144],
    44601: [12047, 11938, 8938, 11800, 15214, 15214, 12214],
    44801: [12117, 12008, 9008, 11861, 15284, 15284, 12284],
    45001: [12187, 12078, 9078, 11923, 15354, 15354, 12354],
    45201: [12257, 12148, 9148, 11984, 15424, 15424, 12424],
    45401: [12327, 12218, 9218, 12045, 15494, 15494, 12494],
    45601: [12397, 12288, 9288, 12107, 15564, 15564, 12564],
    45801: [12467, 12358, 9358, 12168, 15634, 15634, 12634],
    46001: [12537, 12428, 9428, 12229, 15704, 15704, 12704],
    46201: [12607, 12498, 9498, 12291, 15774, 15774, 12774],
    46401: [12677, 12568, 9568, 12352, 15844, 15844, 12844],
    46601: [12747, 12638, 9638, 12413, 15914, 15914, 12914],
    46801: [12817, 12708, 9708, 12475, 15984, 15984, 12984],
    47001: [12887, 12778, 9778, 12536, 16054, 16054, 13054],
    47201: [12957, 12848, 9848, 12598, 16124, 16124, 13124],
    47401: [13027, 12918, 9918, 12659, 16194, 16194, 13194],
    47601: [13097, 12988, 9988, 12720, 16264, 16264, 13264],
    47801: [13167, 13058, 10058, 12782, 16334, 16334, 13334],
    48001: [13237, 13128, 10128, 12843, 16404, 16404, 13404],
    48201: [13307, 13198, 10198, 12904, 16474, 16474, 13474],
    48401: [13377, 13268, 10268, 12966, 16544, 16544, 13544],
    48601: [13447, 13338, 10338, 13027, 16614, 16614, 13614],
    48801: [13517, 13408, 10408, 13088, 16684, 16684, 13684],
    49001: [13587, 13478, 10478, 13150, 16754, 16754, 13754],
    49201: [13657, 13548, 10548, 13211, 16824, 16824, 13824],
    49401: [13727, 13618, 10618, 13272, 16894, 16894, 13894],
    49601: [13797, 13688, 10688, 13334, 16964, 16964, 13964],
    49801: [13867, 13758, 10758, 13395, 17034, 17034, 14034],
    50001: [13937, 13828, 10834, 13456, 17104, 17104, 14110],
    50201: [14007, 13936, 10948, 13518, 17174, 17174, 14186],
    50401: [14077, 14047, 11065, 13579, 17244, 17244, 14262],
    50601: [14147, 14161, 11185, 13640, 17314, 17314, 14338],
    50801: [14217, 14275, 11305, 13702, 17384, 17384, 14414],
    51001: [14296, 14388, 11424, 13771, 17462, 17462, 14498],
    51201: [14406, 14502, 11544, 13873, 17572, 17572, 14614],
    51401: [14516, 14616, 11664, 13974, 17682, 17682, 14730],
    51601: [14626, 14729, 11783, 14075, 17792, 17792, 14846],
    51801: [14736, 14843, 11903, 14177, 17902, 17902, 14962],
    52001: [14846, 14954, 12020, 14278, 18012, 18012, 15078],
    52201: [14956, 15068, 12140, 14379, 18122, 18122, 15194],
    52401: [15066, 15182, 12260, 14481, 18232, 18232, 15310],
    52601: [15176, 15295, 12379, 14582, 18342, 18342, 15426],
    52801: [15286, 15409, 12499, 14683, 18452, 18452, 15542],
    53001: [15396, 15523, 12619, 14785, 18562, 18562, 15658],
    53201: [15506, 15637, 12739, 14886, 18672, 18672, 15774],
    53401: [15616, 15750, 12858, 14987, 18782, 18782, 15890],
    53601: [15726, 15861, 12975, 15089, 18892, 18892, 16006],
    53801: [15836, 15975, 13095, 15190, 19002, 19002, 16122],
    54001: [15946, 16089, 13215, 15291, 19112, 19112, 16238],
    54201: [16056, 16202, 13334, 15393, 19222, 19222, 16354],
    54401: [16166, 16316, 13454, 15494, 19332, 19332, 16470],
    54601: [16276, 16430, 13574, 15595, 19442, 19442, 16586],
    54801: [16386, 16544, 13694, 15697, 19552, 19552, 16702],
    55001: [16496, 16657, 13813, 15798, 19662, 19662, 16818],
    55201: [16606, 16771, 13933, 15899, 19772, 19772, 16934],
    55401: [16716, 16882, 14050, 16001, 19882, 19882, 17050],
    55601: [16826, 16996, 14170, 16102, 19992, 19992, 17166],
    55801: [16936, 17109, 14289, 16203, 20102, 20102, 17282],
    56001: [17046, 17182, 14368, 16305, 20212, 20212, 17398],
    56201: [17156, 17252, 14444, 16406, 20322, 20322, 17514],
    56401: [17266, 17322, 14520, 16507, 20432, 20432, 17630],
    56601: [17376, 17392, 14596, 16609, 20542, 20542, 17746],
    56801: [17486, 17476, 14686, 16710, 20652, 20652, 17862],
    57001: [17596, 17586, 14802, 16811, 20762, 20762, 17978],
    57201: [17706, 17696, 14918, 16913, 20872, 20872, 18094],
    57401: [17816, 17806, 15034, 17014, 20982, 20982, 18210],
    57601: [17926, 17916, 15150, 17115, 21092, 21092, 18326],
    57801: [18036, 18026, 15266, 17217, 21202, 21202, 18442],
    58001: [18146, 18136, 15382, 17318, 21312, 21312, 18558],
    58201: [18256, 18246, 15498, 17419, 21422, 21422, 18674],
    58401: [18366, 18356, 15614, 17521, 21532, 21532, 18790],
    58601: [18476, 18466, 15730, 17622, 21642, 21642, 18906],
    58801: [18586, 18576, 15846, 17723, 21752, 21752, 19022],
    59001: [18696, 18686, 15962, 17825, 21862, 21862, 19138],
    59201: [18811, 18800, 16082, 17926, 21972, 21972, 19254],
    59401: [18927, 18915, 16203, 18027, 22082, 22082, 19370],
    59601: [19043, 19034, 16328, 18129, 22192, 22192, 19486],
    59801: [19159, 19149, 16449, 18230, 22302, 22302, 19602],
    60001: [19275, 19263, 16569, 18331, 22412, 22412, 19718],
    60201: [19391, 19382, 16694, 18433, 22522, 22522, 19834],
    60401: [19507, 19497, 16815, 18534, 22632, 22632, 19950],
    60601: [19623, 19616, 16940, 18635, 22742, 22742, 20066],
    60801: [19739, 19731, 17061, 18737, 22852, 22852, 20182],
    61001: [19855, 19845, 17181, 18838, 22962, 22962, 20298],
    61201: [19971, 19964, 17306, 18939, 23072, 23072, 20414],
    61401: [20087, 20079, 17427, 19041, 23182, 23182, 20530],
    61601: [20203, 20194, 17548, 19142, 23292, 23292, 20646],
    61801: [20319, 20313, 17673, 19243, 23402, 23402, 20762],
    62001: [20435, 20427, 17793, 19345, 23512, 23512, 20878],
    62201: [20551, 20547, 17919, 19446, 23622, 23622, 20994],
    62401: [20667, 20661, 18039, 19547, 23732, 23732, 21110],
    62601: [20783, 20776, 18160, 19649, 23842, 23842, 21226],
    62801: [20899, 20895, 18285, 19750, 23952, 23952, 21342],
    63001: [21015, 21009, 18405, 19851, 24062, 24062, 21458],
    63201: [21131, 21124, 18526, 19953, 24172, 24172, 21574],
    63401: [21247, 21243, 18651, 20054, 24282, 24282, 21690],
    63601: [21363, 21358, 18772, 20155, 24392, 24392, 21806],
    63801: [21479, 21477, 18897, 20257, 24502, 24502, 21922],
    64001: [21595, 21592, 19018, 20358, 24612, 24612, 22038],
    64201: [21711, 21706, 19138, 20459, 24722, 24722, 22154],
    64401: [21827, 21825, 19263, 20561, 24832, 24832, 22270],
    64601: [21943, 21940, 19384, 20662, 24942, 24942, 22386],
    64801: [22059, 22054, 19504, 20763, 25052, 25052, 22502],
    65001: [22175, 22174, 19630, 20865, 25162, 25162, 22618],
    65201: [22291, 22288, 19750, 20966, 25272, 25272, 22734],
    65401: [22407, 22407, 19875, 21068, 25382, 25382, 22850],
    65601: [22523, 22522, 19996, 21169, 25492, 25492, 22966],
    65801: [22639, 22637, 20117, 21270, 25602, 25602, 23082],
    66001: [22755, 22756, 20242, 21372, 25712, 25712, 23198],
    66201: [22871, 22870, 20362, 21473, 25822, 25822, 23314],
    66401: [22987, 22985, 20483, 21574, 25932, 25932, 23430],
    66601: [23103, 23104, 20608, 21676, 26042, 26042, 23546],
    66801: [23219, 23219, 20729, 21777, 26152, 26152, 23662],
    67001: [23335, 23338, 20854, 21878, 26262, 26262, 23778],
    67201: [23451, 23452, 20974, 21980, 26372, 26372, 23894],
    67401: [23567, 23567, 21095, 22081, 26482, 26482, 24010],
    67601: [23683, 23686, 21220, 22182, 26592, 26592, 24126],
    67801: [23799, 23801, 21341, 22284, 26702, 26702, 24242],
    68001: [23915, 23920, 21466, 22385, 26812, 26812, 24358],
    68201: [24031, 24034, 21586, 22486, 26922, 26922, 24474],
    68401: [24147, 24149, 21707, 22588, 27032, 27032, 24590],
    68601: [24263, 24268, 21832, 22689, 27142, 27142, 24706],
    68801: [24379, 24383, 21953, 22790, 27252, 27252, 24822],
    69001: [24495, 24497, 22073, 22892, 27362, 27362, 24938],
    69201: [24611, 24617, 22199, 22993, 27472, 27472, 25054],
    69401: [24727, 24731, 22319, 23094, 27582, 27582, 25170],
    69601: [24843, 24850, 22444, 23196, 27692, 27692, 25286],
    69801: [24959, 24965, 22565, 23297, 27802, 27802, 25402],
    70001: [25075, 25079, 22685, 23398, 27912, 27912, 25518],
    70201: [25191, 25199, 22811, 23500, 28022, 28022, 25634],
    70401: [25307, 25313, 22931, 23601, 28132, 28132, 25750],
    70601: [25423, 25428, 23052, 23702, 28242, 28242, 25866],
    70801: [25539, 25547, 23177, 23804, 28352, 28352, 25982],
    71001: [25655, 25662, 23298, 23905, 28462, 28462, 26098],
    71201: [25771, 25781, 23423, 24006, 28572, 28572, 26214],
    71401: [25887, 25895, 23543, 24108, 28682, 28682, 26330],
    71601: [26003, 26010, 23664, 24209, 28792, 28792, 26446],
    71801: [26119, 26129, 23789, 24310, 28902, 28902, 26562],
    72001: [26235, 26244, 23910, 24412, 29012, 29012, 26678],
    72201: [26351, 26358, 24030, 24513, 29122, 29122, 26794],
    72401: [26467, 26477, 24155, 24614, 29232, 29232, 26910],
    72601: [26583, 26592, 24276, 24716, 29342, 29342, 27026],
    72801: [26699, 26711, 24401, 24817, 29452, 29452, 27142],
    73001: [26815, 26826, 24522, 24918, 29562, 29562, 27258],
    73201: [26931, 26940, 24642, 25020, 29672, 29672, 27374],
    73401: [27047, 27059, 24767, 25121, 29782, 29782, 27490],
    73601: [27163, 27174, 24888, 25222, 29892, 29892, 27606],
    73801: [27279, 27289, 25009, 25324, 30002, 30002, 27722],
    74001: [27395, 27408, 25134, 25425, 30112, 30112, 27838],
    74201: [27511, 27522, 25254, 25526, 30222, 30222, 27954],
    74401: [27627, 27642, 25380, 25628, 30332, 30332, 28070],
    74601: [27743, 27756, 25500, 25729, 30442, 30442, 28186],
    74801: [27859, 27871, 25621, 25830, 30552, 30552, 28302],
    75001: [27975, 27990, 25746, 25932, 30662, 30662, 28418],
    75201: [28091, 28104, 25866, 26033, 30772, 30772, 28534],
    75401: [28207, 28219, 25987, 26134, 30882, 30882, 28650],
    75601: [28323, 28338, 26112, 26236, 30992, 30992, 28766],
    75801: [28439, 28453, 26233, 26337, 31102, 31102, 28882],
    76001: [28555, 28572, 26358, 26438, 31212, 31212, 28998],
    76201: [28671, 28687, 26479, 26540, 31322, 31322, 29114],
    76401: [28787, 28801, 26599, 26641, 31432, 31432, 29230],
    76601: [28903, 28920, 26724, 26742, 31542, 31542, 29346],
    76801: [29019, 29035, 26845, 26844, 31652, 31652, 29462],
    77001: [29135, 29154, 26970, 26945, 31762, 31762, 29578],
    77201: [29251, 29269, 27091, 27046, 31872, 31872, 29694],
    77401: [29367, 29383, 27211, 27148, 31982, 31982, 29810],
    77601: [29483, 29502, 27336, 27249, 32092, 32092, 29926],
    77801: [29599, 29617, 27457, 27350, 32202, 32202, 30042],
    78001: [29715, 29732, 27578, 27452, 32312, 32312, 30158],
    78201: [29831, 29851, 27703, 27553, 32422, 32422, 30274],
    78401: [29947, 29965, 27823, 27654, 32532, 32532, 30390],
    78601: [30063, 30084, 27948, 27756, 32642, 32642, 30506],
    78801: [30179, 30199, 28069, 27857, 32752, 32752, 30622],
    79001: [30295, 30314, 28190, 27959, 32862, 32862, 30738],
    79201: [30411, 30433, 28315, 28060, 32972, 32972, 30854],
    79401: [30527, 30547, 28435, 28161, 33082, 33082, 30970],
    79601: [30643, 30662, 28556, 28262, 33192, 33192, 31086],
    79801: [30759, 30781, 28681, 28364, 33302, 33302, 31202],
    80001: [38, 39, 36, 35, 42, 42, 39, '%'],
    80201: [39, 39, 36, 36, 42, 42, 39, '%'],
    82001: [39, 39, 37, 36, 42, 42, 40, '%'],
    84201: [39, 40, 37, 36, 42, 42, 40, '%'],
    84401: [40, 40, 37, 36, 42, 42, 40, '%'],
    85401: [40, 40, 38, 36, 43, 43, 40, '%'],
    85801: [40, 40, 38, 37, 43, 43, 40, '%'],
    86801: [40, 40, 38, 37, 43, 43, 41, '%'],
    89001: [40, 41, 38, 37, 43, 43, 41, '%'],
    89201: [41, 41, 39, 37, 43, 43, 41, '%'],
    92001: [41, 41, 39, 37, 43, 43, 42, '%'],
    92401: [41, 41, 39, 38, 43, 43, 42, '%'],
    93001: [41, 41, 39, 38, 44, 44, 42, '%'],
    93401: [41, 41, 40, 38, 44, 44, 42, '%'],
    94401: [41, 42, 40, 38, 44, 44, 42, '%'],
    94601: [42, 42, 40, 38, 44, 44, 42, '%'],
    98001: [42, 42, 41, 38, 44, 44, 43, '%'],
    100001: [42, 42, 41, 39, 44, 44, 43, '%'],
    100401: [42, 43, 41, 39, 44, 44, 43, '%'],
    100801: [43, 43, 41, 39, 44, 44, 43, '%'],
    101801: [43, 43, 41, 39, 45, 45, 43, '%'],
    102801: [43, 43, 42, 39, 45, 45, 43, '%'],
    104801: [43, 43, 42, 39, 45, 45, 44, '%'],
    107401: [43, 44, 42, 39, 45, 45, 44, '%'],
    107801: [44, 44, 42, 39, 45, 45, 44, '%'],
    108401: [44, 44, 43, 39, 45, 45, 44, '%'],
    108801: [44, 44, 43, 40, 45, 45, 44, '%'],
    112401: [44, 44, 43, 40, 45, 45, 45, '%'],
    112601: [44, 44, 43, 40, 46, 46, 45, '%'],
    114601: [44, 44, 44, 40, 46, 46, 45, '%'],
    115201: [44, 45, 44, 40, 46, 46, 45, '%'],
    115801: [45, 45, 44, 40, 46, 46, 45, '%'],
    119601: [45, 45, 44, 41, 46, 46, 45, '%'],
    121401: [45, 45, 45, 41, 46, 46, 46, '%'],
    124201: [45, 46, 45, 41, 46, 46, 46, '%'],
    125001: [46, 46, 45, 41, 46, 46, 46, '%'],
    125801: [46, 46, 45, 41, 47, 47, 46, '%'],
    129201: [46, 46, 46, 41, 47, 47, 46, '%'],
    132001: [46, 46, 46, 41, 47, 47, 47, '%'],
    132601: [46, 46, 46, 42, 47, 47, 47, '%'],
    135001: [46, 47, 46, 42, 47, 47, 47, '%'],
    136001: [47, 47, 46, 42, 47, 47, 47, '%'],
    138001: [47, 47, 47, 42, 47, 47, 47, '%'],
    142601: [47, 47, 47, 42, 48, 48, 47, '%'],
    144601: [47, 47, 47, 42, 48, 48, 48, '%'],
    147601: [47, 48, 47, 42, 48, 48, 48, '%'],
    148201: [47, 48, 48, 42, 48, 48, 48, '%'],
    148801: [48, 48, 48, 43, 48, 48, 48, '%'],
    164401: [48, 49, 49, 43, 49, 49, 49, '%'],
    164601: [49, 49, 49, 43, 49, 49, 49, '%'],
    169601: [49, 49, 49, 44, 49, 49, 49, '%'],
    194401: [50, 50, 50, 44, 50, 50, 50, '%'],
    197201: [50, 50, 50, 45, 50, 50, 50, '%'],
    235401: [50, 50, 50, 46, 50, 50, 50, '%'],
    237601: [51, 51, 51, 46, 51, 51, 51, '%'],
    291801: [51, 51, 51, 47, 51, 51, 51, '%'],
    305601: [52, 52, 52, 47, 52, 52, 52, '%'],
    384001: [52, 52, 52, 48, 52, 52, 52, '%'],
    427801: [53, 53, 53, 48, 53, 53, 53, '%'],
    561201: [53, 53, 53, 49, 53, 53, 53, '%'],
    713001: [54, 54, 54, 49, 54, 54, 54, '%'],
    1041401: [54, 54, 54, 50, 54, 54, 54, '%']
  },
  36: {
    1: [0, 0, 0, 0, 0, 0, 0],
    1801: [134, 0, 133, 0, 134, 15, 134],
    1901: [144, 0, 141, 3, 144, 52, 144],
    2001: [155, 0, 150, 5, 155, 89, 155],
    2101: [157, 0, 150, 7, 157, 126, 157],
    2201: [167, 0, 158, 9, 167, 163, 167],
    2301: [178, 0, 166, 11, 200, 200, 178],
    2401: [188, 0, 175, 13, 237, 237, 188],
    2501: [199, 0, 183, 16, 274, 274, 199],
    2601: [209, 0, 191, 18, 311, 311, 209],
    2701: [220, 0, 200, 20, 348, 348, 220],
    2801: [222, 0, 200, 22, 385, 385, 222],
    2901: [232, 0, 208, 24, 422, 422, 232],
    3001: [243, 0, 216, 26, 459, 459, 243],
    3101: [253, 0, 225, 28, 496, 496, 253],
    3201: [264, 0, 233, 31, 533, 533, 264],
    3301: [274, 0, 241, 33, 570, 570, 274],
    3401: [277, 0, 241, 35, 607, 607, 277],
    3501: [287, 0, 250, 37, 644, 644, 287],
    3601: [298, 0, 258, 39, 681, 681, 298],
    3701: [308, 0, 266, 41, 718, 718, 308],
    3801: [319, 0, 275, 44, 755, 755, 319],
    3901: [329, 0, 283, 50, 792, 792, 329],
    4001: [331, 0, 283, 75, 829, 829, 331],
    4101: [342, 0, 291, 100, 866, 866, 342],
    4201: [352, 0, 300, 125, 903, 903, 352],
    4301: [363, 0, 308, 150, 937, 937, 363],
    4401: [373, 0, 316, 175, 965, 965, 373],
    4501: [383, 0, 325, 200, 995, 995, 383],
    4601: [384, 0, 325, 225, 1026, 1026, 384],
    4701: [394, 0, 333, 249, 1054, 1054, 394],
    4801: [405, 9, 342, 274, 1085, 1085, 405],
    4901: [414, 36, 352, 299, 1113, 1113, 414],
    5001: [425, 64, 362, 323, 1143, 1143, 425],
    5101: [435, 92, 372, 348, 1174, 1174, 435],
    5201: [445, 120, 382, 373, 1202, 1202, 445],
    5301: [447, 147, 383, 398, 1233, 1233, 447],
    5401: [456, 175, 393, 422, 1260, 1260, 456],
    5501: [467, 203, 403, 446, 1290, 1290, 467],
    5601: [477, 231, 413, 470, 1320, 1320, 477],
    5701: [487, 258, 423, 494, 1348, 1348, 487],
    5801: [497, 283, 433, 518, 1378, 1378, 497],
    5901: [509, 311, 434, 542, 1405, 1405, 498],
    6001: [532, 339, 444, 566, 1435, 1435, 509],
    6101: [554, 366, 454, 590, 1465, 1465, 519],
    6201: [577, 394, 464, 614, 1493, 1493, 529],
    6301: [599, 422, 474, 639, 1523, 1523, 539],
    6401: [621, 450, 484, 663, 1550, 1550, 549],
    6501: [644, 477, 486, 687, 1580, 1580, 551],
    6601: [667, 505, 496, 711, 1611, 1611, 561],
    6701: [689, 533, 506, 735, 1638, 1638, 571],
    6801: [711, 561, 516, 759, 1668, 1668, 581],
    6901: [734, 585, 525, 783, 1695, 1695, 591],
    7001: [756, 613, 535, 807, 1725, 1725, 601],
    7101: [779, 641, 537, 831, 1755, 1755, 603],
    7201: [801, 669, 547, 855, 1783, 1783, 613],
    7301: [824, 696, 557, 880, 1813, 1813, 623],
    7401: [846, 724, 567, 904, 1840, 1840, 633],
    7501: [869, 752, 577, 928, 1870, 1870, 643],
    7601: [891, 780, 587, 952, 1900, 1900, 653],
    7701: [914, 807, 597, 976, 1928, 1928, 663],
    7801: [936, 835, 598, 1000, 1958, 1958, 665],
    7901: [959, 863, 608, 1024, 1985, 1985, 675],
    8001: [981, 891, 618, 1048, 2015, 2015, 685],
    8101: [1004, 915, 628, 1073, 2045, 2045, 695],
    8201: [1026, 943, 638, 1097, 2073, 2073, 705],
    8301: [1048, 971, 648, 1121, 2103, 2103, 715],
    8401: [1071, 998, 649, 1145, 2130, 2130, 717],
    8501: [1093, 1020, 659, 1169, 2160, 2160, 727],
    8601: [1116, 1042, 669, 1193, 2190, 2190, 737],
    8701: [1138, 1066, 678, 1217, 2218, 2218, 747],
    8801: [1161, 1088, 688, 1241, 2248, 2248, 757],
    8901: [1183, 1109, 698, 1265, 2275, 2275, 767],
    9001: [1206, 1131, 699, 1289, 2305, 2305, 769],
    9101: [1228, 1156, 709, 1314, 2335, 2335, 779],
    9201: [1251, 1177, 718, 1338, 2363, 2363, 789],
    9301: [1273, 1199, 728, 1362, 2393, 2393, 799],
    9401: [1296, 1220, 737, 1386, 2420, 2420, 809],
    9501: [1318, 1245, 747, 1410, 2450, 2450, 819],
    9601: [1341, 1266, 748, 1434, 2480, 2480, 821],
    9701: [1363, 1287, 758, 1458, 2508, 2508, 831],
    9801: [1385, 1308, 768, 1482, 2538, 2538, 841],
    9901: [1408, 1333, 777, 1506, 2565, 2565, 851],
    10001: [1430, 1354, 787, 1530, 2595, 2595, 861],
    10101: [1453, 1375, 797, 1555, 2625, 2625, 871],
    10201: [1475, 1396, 806, 1579, 2653, 2653, 881],
    10301: [1498, 1420, 808, 1603, 2683, 2683, 883],
    10401: [1520, 1441, 817, 1627, 2710, 2710, 893],
    10501: [1543, 1462, 827, 1651, 2740, 2740, 903],
    10601: [1565, 1484, 836, 1675, 2770, 2770, 913],
    10701: [1588, 1508, 846, 1699, 2798, 2798, 923],
    10801: [1610, 1529, 856, 1723, 2828, 2828, 933],
    10901: [1633, 1550, 857, 1747, 2855, 2855, 935],
    11001: [1655, 1574, 867, 1771, 2885, 2885, 945],
    11101: [1678, 1595, 876, 1796, 2915, 2915, 955],
    11201: [1700, 1616, 886, 1820, 2943, 2943, 965],
    11301: [1723, 1638, 896, 1844, 2973, 2973, 975],
    11401: [1745, 1662, 905, 1868, 3000, 3000, 985],
    11501: [1768, 1683, 907, 1892, 3030, 3030, 987],
    11601: [1790, 1704, 916, 1916, 3060, 3060, 997],
    11701: [1812, 1725, 926, 1940, 3088, 3088, 1012],
    11801: [1835, 1749, 936, 1964, 3118, 3118, 1035],
    11901: [1858, 1771, 945, 1989, 3151, 3151, 1061],
    12001: [1880, 1792, 955, 2013, 3187, 3187, 1090],
    12101: [1903, 1813, 956, 2037, 3224, 3224, 1120],
    12201: [1926, 1837, 966, 2062, 3260, 3260, 1149],
    12301: [1949, 1858, 975, 2086, 3296, 3296, 1178],
    12401: [1971, 1879, 985, 2111, 3332, 3332, 1207],
    12501: [1994, 1900, 995, 2135, 3369, 3369, 1237],
    12601: [2017, 1925, 1004, 2159, 3405, 3405, 1266],
    12701: [2040, 1946, 1014, 2184, 3441, 3441, 1295],
    12801: [2062, 1967, 1015, 2208, 3477, 3477, 1324],
    12901: [2085, 1991, 1025, 2232, 3514, 3514, 1354],
    13001: [2108, 2012, 1035, 2257, 3550, 3550, 1383],
    13101: [2131, 2033, 1044, 2281, 3586, 3586, 1412],
    13201: [2153, 2054, 1054, 2306, 3622, 3622, 1441],
    13301: [2176, 2079, 1064, 2330, 3659, 3659, 1471],
    13401: [2199, 2100, 1065, 2354, 3695, 3695, 1500],
    13501: [2222, 2121, 1074, 2379, 3731, 3731, 1529],
    13601: [2244, 2142, 1084, 2403, 3770, 3770, 1561],
    13701: [2268, 2166, 1094, 2428, 3813, 3813, 1597],
    13801: [2290, 2187, 1103, 2452, 3852, 3852, 1629],
    13901: [2313, 2209, 1113, 2477, 3891, 3891, 1661],
    14001: [2336, 2230, 1114, 2501, 3930, 3930, 1693],
    14101: [2362, 2257, 1124, 2528, 3969, 3969, 1725],
    14201: [2393, 2287, 1134, 2559, 4010, 4010, 1759],
    14301: [2423, 2320, 1145, 2590, 4049, 4049, 1791],
    14401: [2454, 2351, 1155, 2620, 4087, 4087, 1822],
    14501: [2485, 2381, 1165, 2651, 4125, 4125, 1853],
    14601: [2516, 2414, 1167, 2682, 4163, 4163, 1884],
    14701: [2547, 2444, 1177, 2713, 4204, 4204, 1918],
    14801: [2578, 2477, 1187, 2744, 4242, 4242, 1949],
    14901: [2608, 2508, 1197, 2774, 4281, 4281, 1981],
    15001: [2639, 2538, 1208, 2805, 4319, 4319, 2012],
    15101: [2670, 2571, 1218, 2836, 4357, 4357, 2043],
    15201: [2701, 2601, 1228, 2867, 4398, 4398, 2077],
    15301: [2732, 2634, 1230, 2898, 4436, 4436, 2108],
    15401: [2763, 2665, 1240, 2929, 4474, 4474, 2139],
    15501: [2793, 2695, 1250, 2959, 4513, 4513, 2171],
    15601: [2824, 2728, 1261, 2990, 4551, 4551, 2202],
    15701: [2855, 2758, 1271, 3021, 4592, 4592, 2236],
    15801: [2886, 2792, 1281, 3052, 4630, 4630, 2267],
    15901: [2917, 2822, 1283, 3083, 4668, 4668, 2298],
    16001: [2948, 2852, 1293, 3114, 4707, 4707, 2330],
    16101: [2978, 2885, 1303, 3144, 4745, 4745, 2361],
    16201: [3009, 2915, 1313, 3175, 4786, 4786, 2395],
    16301: [3040, 2949, 1324, 3206, 4824, 4824, 2426],
    16401: [3071, 2979, 1334, 3237, 4862, 4862, 2457],
    16501: [3102, 3009, 1336, 3268, 4900, 4900, 2488],
    16601: [3132, 3042, 1346, 3299, 4939, 4939, 2520],
    16701: [3163, 3073, 1356, 3330, 4980, 4980, 2554],
    16801: [3194, 3106, 1366, 3360, 5018, 5018, 2585],
    16901: [3225, 3136, 1377, 3391, 5056, 5056, 2616],
    17001: [3256, 3166, 1387, 3422, 5094, 5094, 2647],
    17101: [3287, 3199, 1389, 3453, 5132, 5132, 2678],
    17201: [3317, 3230, 1399, 3484, 5174, 5174, 2713],
    17301: [3348, 3263, 1409, 3515, 5212, 5212, 2744],
    17401: [3379, 3293, 1419, 3545, 5250, 5250, 2775],
    17501: [3410, 3323, 1429, 3576, 5288, 5288, 2806],
    17601: [3441, 3356, 1440, 3607, 5326, 5326, 2837],
    17701: [3472, 3387, 1450, 3638, 5367, 5367, 2871],
    17801: [3502, 3420, 1452, 3669, 5406, 5406, 2903],
    17901: [3533, 3450, 1462, 3699, 5444, 5444, 2934],
    18001: [3564, 3480, 1472, 3730, 5482, 5482, 2965],
    18101: [3595, 3514, 1482, 3761, 5520, 5520, 2996],
    18201: [3626, 3544, 1493, 3792, 5561, 5561, 3030],
    18301: [3657, 3577, 1503, 3823, 5600, 5600, 3062],
    18401: [3687, 3607, 1505, 3854, 5638, 5638, 3093],
    18501: [3718, 3637, 1515, 3884, 5676, 5676, 3124],
    18601: [3749, 3671, 1525, 3915, 5714, 5714, 3155],
    18701: [3780, 3701, 1535, 3946, 5755, 5755, 3189],
    18801: [3811, 3734, 1546, 3977, 5793, 5793, 3220],
    18901: [3842, 3764, 1556, 4008, 5832, 5832, 3252],
    19001: [3872, 3798, 1558, 4039, 5870, 5870, 3283],
    19101: [3903, 3828, 1568, 4069, 5908, 5908, 3314],
    19201: [3934, 3858, 1578, 4100, 5949, 5949, 3348],
    19301: [3965, 3891, 1588, 4131, 5987, 5987, 3379],
    19401: [3996, 3921, 1598, 4162, 6025, 6025, 3410],
    19501: [4027, 3955, 1609, 4193, 6064, 6064, 3442],
    19601: [4057, 3984, 1610, 4224, 6102, 6102, 3473],
    19701: [4088, 4013, 1619, 4254, 6143, 6143, 3507],
    19801: [4119, 4046, 1628, 4285, 6181, 6181, 3538],
    19901: [4150, 4075, 1638, 4316, 6219, 6219, 3569],
    20001: [4212, 4137, 1656, 4378, 6296, 6296, 3632],
    20201: [4273, 4198, 1667, 4440, 6375, 6375, 3697],
    20401: [4335, 4260, 1685, 4501, 6451, 6451, 3759],
    20601: [4397, 4319, 1704, 4563, 6531, 6531, 3825],
    20801: [4458, 4380, 1714, 4624, 6607, 6607, 3887],
    21001: [4520, 4442, 1733, 4686, 6683, 6683, 3949],
    21201: [4582, 4504, 1756, 4748, 6763, 6763, 4015],
    21401: [4643, 4566, 1804, 4809, 6839, 6839, 4077],
    21601: [4705, 4624, 1848, 4871, 6918, 6918, 4142],
    21801: [4767, 4686, 1896, 4933, 6995, 6995, 4205],
    22001: [4828, 4748, 1944, 4994, 7071, 7071, 4267],
    22201: [4890, 4809, 1991, 5056, 7151, 7151, 4333],
    22401: [4952, 4871, 2039, 5119, 7228, 7228, 4396],
    22601: [5016, 4930, 2084, 5182, 7309, 7309, 4463],
    22801: [5079, 4991, 2131, 5245, 7387, 7387, 4527],
    23001: [5142, 5053, 2179, 5309, 7465, 7465, 4591],
    23201: [5206, 5115, 2227, 5372, 7546, 7546, 4658],
    23401: [5269, 5177, 2275, 5435, 7624, 7624, 4722],
    23601: [5332, 5238, 2322, 5499, 7705, 7705, 4789],
    23801: [5396, 5297, 2367, 5562, 7783, 7783, 4853],
    24001: [5459, 5359, 2415, 5625, 7861, 7861, 4917],
    24201: [5522, 5423, 2465, 5688, 7942, 7942, 4984],
    24401: [5586, 5485, 2513, 5752, 8020, 8020, 5048],
    24601: [5649, 5547, 2561, 5815, 8101, 8101, 5115],
    24801: [5712, 5608, 2608, 5878, 8179, 8179, 5179],
    25001: [5776, 5673, 2673, 5942, 8257, 8257, 5257],
    25201: [5839, 5735, 2735, 6005, 8338, 8338, 5338],
    25401: [5902, 5796, 2796, 6068, 8416, 8416, 5416],
    25601: [5966, 5861, 2861, 6132, 8497, 8497, 5497],
    25801: [6029, 5923, 2923, 6195, 8575, 8575, 5575],
    26001: [6092, 5984, 2984, 6258, 8653, 8653, 5653],
    26201: [6155, 6049, 3049, 6322, 8734, 8734, 5734],
    26401: [6219, 6111, 3111, 6385, 8812, 8812, 5812],
    26601: [6282, 6172, 3172, 6448, 8893, 8893, 5893],
    26801: [6345, 6237, 3237, 6512, 8971, 8971, 5971],
    27001: [6409, 6299, 3299, 6575, 9049, 9049, 6049],
    27201: [6472, 6360, 3360, 6638, 9130, 9130, 6130],
    27401: [6535, 6425, 3425, 6702, 9208, 9208, 6208],
    27601: [6599, 6487, 3487, 6765, 9289, 9289, 6289],
    27801: [6662, 6548, 3548, 6828, 9367, 9367, 6367],
    28001: [6725, 6610, 3610, 6891, 9445, 9445, 6445],
    28201: [6789, 6675, 3675, 6955, 9526, 9526, 6526],
    28401: [6852, 6736, 3736, 7018, 9604, 9604, 6604],
    28601: [6915, 6798, 3798, 7081, 9685, 9685, 6685],
    28801: [6979, 6863, 3863, 7145, 9763, 9763, 6763],
    29001: [7042, 6924, 3924, 7208, 9841, 9841, 6841],
    29201: [7105, 6986, 3986, 7271, 9922, 9922, 6922],
    29401: [7168, 7051, 4051, 7335, 10000, 10000, 7000],
    29601: [7232, 7112, 4112, 7398, 10081, 10081, 7081],
    29801: [7295, 7174, 4174, 7461, 10159, 10159, 7159],
    30001: [7358, 7240, 4240, 7524, 10237, 10237, 7237],
    30201: [7422, 7303, 4303, 7588, 10318, 10318, 7318],
    30401: [7485, 7366, 4366, 7651, 10396, 10396, 7396],
    30601: [7548, 7429, 4429, 7715, 10477, 10477, 7477],
    30801: [7612, 7495, 4495, 7778, 10555, 10555, 7555],
    31001: [7675, 7558, 4558, 7841, 10633, 10633, 7633],
    31201: [7738, 7621, 4621, 7904, 10714, 10714, 7714],
    31401: [7802, 7687, 4687, 7968, 10792, 10792, 7792],
    31601: [7865, 7750, 4750, 8031, 10873, 10873, 7873],
    31801: [7928, 7813, 4813, 8094, 10951, 10951, 7951],
    32001: [7991, 7879, 4879, 8158, 11029, 11029, 8029],
    32201: [8055, 7942, 4942, 8221, 11110, 11110, 8110],
    32401: [8118, 8005, 5005, 8284, 11188, 11188, 8188],
    32601: [8181, 8071, 5071, 8348, 11269, 11269, 8269],
    32801: [8245, 8134, 5134, 8411, 11347, 11347, 8347],
    33001: [8308, 8197, 5197, 8474, 11425, 11425, 8425],
    33201: [8371, 8263, 5263, 8538, 11506, 11506, 8506],
    33401: [8435, 8326, 5326, 8601, 11584, 11584, 8584],
    33601: [8498, 8389, 5389, 8664, 11665, 11665, 8665],
    33801: [8561, 8452, 5452, 8727, 11743, 11743, 8743],
    34001: [8625, 8518, 5518, 8791, 11821, 11821, 8821],
    34201: [8688, 8581, 5581, 8854, 11902, 11902, 8902],
    34401: [8751, 8644, 5644, 8917, 11977, 11977, 8977],
    34601: [8814, 8707, 5707, 8980, 12049, 12049, 9049],
    34801: [8877, 8767, 5767, 9043, 12121, 12121, 9121],
    35001: [8940, 8830, 5830, 9107, 12193, 12193, 9193],
    35201: [9005, 8896, 5896, 9170, 12265, 12265, 9265],
    35401: [9077, 8968, 5968, 9233, 12337, 12337, 9337],
    35601: [9149, 9040, 6040, 9296, 12409, 12409, 9409],
    35801: [9221, 9112, 6112, 9359, 12481, 12481, 9481],
    36001: [9293, 9184, 6184, 9422, 12553, 12553, 9553],
    36201: [9365, 9256, 6256, 9485, 12625, 12625, 9625],
    36401: [9437, 9328, 6328, 9548, 12697, 12697, 9697],
    36601: [9509, 9400, 6400, 9611, 12769, 12769, 9769],
    36801: [9581, 9472, 6472, 9674, 12841, 12841, 9841],
    37001: [9653, 9544, 6544, 9737, 12913, 12913, 9913],
    37201: [9725, 9616, 6616, 9800, 12985, 12985, 9985],
    37401: [9797, 9688, 6688, 9864, 13057, 13057, 10057],
    37601: [9869, 9760, 6760, 9927, 13129, 13129, 10129],
    37801: [9941, 9832, 6832, 9990, 13201, 13201, 10201],
    38001: [10013, 9904, 6904, 10053, 13273, 13273, 10273],
    38201: [10085, 9976, 6976, 10116, 13345, 13345, 10345],
    38401: [10157, 10048, 7048, 10179, 13417, 13417, 10417],
    38601: [10229, 10120, 7120, 10242, 13489, 13489, 10489],
    38801: [10301, 10192, 7192, 10305, 13561, 13561, 10561],
    39001: [10373, 10264, 7264, 10368, 13633, 13633, 10633],
    39201: [10445, 10336, 7336, 10431, 13705, 13705, 10705],
    39401: [10517, 10408, 7408, 10494, 13777, 13777, 10777],
    39601: [10589, 10480, 7480, 10557, 13849, 13849, 10849],
    39801: [10661, 10552, 7552, 10620, 13921, 13921, 10921],
    40001: [10733, 10624, 7624, 10684, 13993, 13993, 10993],
    40201: [10805, 10696, 7696, 10747, 14065, 14065, 11065],
    40401: [10877, 10768, 7768, 10810, 14137, 14137, 11137],
    40601: [10949, 10840, 7840, 10873, 14209, 14209, 11209],
    40801: [11021, 10912, 7912, 10936, 14281, 14281, 11281],
    41001: [11093, 10984, 7984, 10999, 14353, 14353, 11353],
    41201: [11165, 11056, 8056, 11062, 14425, 14425, 11425],
    41401: [11237, 11128, 8128, 11125, 14497, 14497, 11497],
    41601: [11309, 11200, 8200, 11188, 14569, 14569, 11569],
    41801: [11381, 11272, 8272, 11251, 14641, 14641, 11641],
    42001: [11453, 11344, 8344, 11314, 14713, 14713, 11713],
    42201: [11525, 11416, 8416, 11377, 14785, 14785, 11785],
    42401: [11597, 11488, 8488, 11441, 14857, 14857, 11857],
    42601: [11669, 11560, 8560, 11504, 14929, 14929, 11929],
    42801: [11741, 11632, 8632, 11567, 15001, 15001, 12001],
    43001: [11813, 11704, 8704, 11630, 15073, 15073, 12073],
    43201: [11885, 11776, 8776, 11693, 15145, 15145, 12145],
    43401: [11957, 11848, 8848, 11756, 15217, 15217, 12217],
    43601: [12029, 11920, 8920, 11819, 15289, 15289, 12289],
    43801: [12101, 11992, 8992, 11882, 15361, 15361, 12361],
    44001: [12173, 12064, 9064, 11945, 15433, 15433, 12433],
    44201: [12245, 12136, 9136, 12008, 15505, 15505, 12505],
    44401: [12317, 12208, 9208, 12071, 15577, 15577, 12577],
    44601: [12389, 12280, 9280, 12134, 15649, 15649, 12649],
    44801: [12461, 12352, 9352, 12198, 15721, 15721, 12721],
    45001: [12533, 12424, 9424, 12261, 15793, 15793, 12793],
    45201: [12605, 12496, 9496, 12324, 15865, 15865, 12865],
    45401: [12677, 12568, 9568, 12387, 15937, 15937, 12937],
    45601: [12749, 12640, 9640, 12450, 16009, 16009, 13009],
    45801: [12821, 12712, 9712, 12513, 16081, 16081, 13081],
    46001: [12893, 12784, 9784, 12576, 16153, 16153, 13153],
    46201: [12965, 12856, 9856, 12639, 16225, 16225, 13225],
    46401: [13037, 12928, 9928, 12702, 16297, 16297, 13297],
    46601: [13109, 13000, 10000, 12765, 16369, 16369, 13369],
    46801: [13181, 13072, 10072, 12828, 16441, 16441, 13441],
    47001: [13253, 13144, 10144, 12891, 16513, 16513, 13513],
    47201: [13325, 13216, 10216, 12954, 16585, 16585, 13585],
    47401: [13397, 13288, 10288, 13018, 16657, 16657, 13657],
    47601: [13469, 13360, 10360, 13081, 16729, 16729, 13729],
    47801: [13541, 13432, 10432, 13144, 16801, 16801, 13801],
    48001: [13613, 13504, 10504, 13207, 16873, 16873, 13873],
    48201: [13685, 13576, 10576, 13270, 16945, 16945, 13945],
    48401: [13757, 13648, 10648, 13333, 17017, 17017, 14017],
    48601: [13829, 13720, 10720, 13396, 17089, 17089, 14089],
    48801: [13901, 13792, 10792, 13459, 17161, 17161, 14161],
    49001: [13973, 13864, 10864, 13522, 17233, 17233, 14233],
    49201: [14045, 13936, 10936, 13585, 17305, 17305, 14305],
    49401: [14117, 14008, 11008, 13648, 17377, 17377, 14377],
    49601: [14189, 14080, 11080, 13711, 17449, 17449, 14449],
    49801: [14261, 14152, 11152, 13775, 17521, 17521, 14521],
    50001: [14333, 14224, 11230, 13838, 17593, 17593, 14599],
    50201: [14405, 14335, 11347, 13901, 17665, 17665, 14677],
    50401: [14477, 14449, 11467, 13964, 17737, 17737, 14755],
    50601: [14549, 14566, 11590, 14027, 17809, 17809, 14833],
    50801: [14621, 14683, 11713, 14090, 17881, 17881, 14911],
    51001: [14701, 14800, 11836, 14161, 17961, 17961, 14997],
    51201: [14813, 14917, 11959, 14264, 18073, 18073, 15115],
    51401: [14925, 15034, 12082, 14368, 18185, 18185, 15233],
    51601: [15037, 15151, 12205, 14471, 18297, 18297, 15351],
    51801: [15149, 15268, 12328, 14574, 18409, 18409, 15469],
    52001: [15261, 15382, 12448, 14677, 18521, 18521, 15587],
    52201: [15373, 15499, 12571, 14780, 18633, 18633, 15705],
    52401: [15485, 15616, 12694, 14883, 18745, 18745, 15823],
    52601: [15597, 15733, 12817, 14986, 18857, 18857, 15941],
    52801: [15709, 15850, 12940, 15089, 18969, 18969, 16059],
    53001: [15821, 15967, 13063, 15192, 19081, 19081, 16177],
    53201: [15933, 16084, 13186, 15295, 19193, 19193, 16295],
    53401: [16045, 16201, 13309, 15398, 19305, 19305, 16413],
    53601: [16157, 16315, 13429, 15501, 19417, 19417, 16531],
    53801: [16269, 16432, 13552, 15604, 19529, 19529, 16649],
    54001: [16381, 16549, 13675, 15708, 19641, 19641, 16767],
    54201: [16493, 16666, 13798, 15811, 19753, 19753, 16885],
    54401: [16605, 16783, 13921, 15914, 19865, 19865, 17003],
    54601: [16717, 16900, 14044, 16017, 19977, 19977, 17121],
    54801: [16829, 17017, 14167, 16120, 20089, 20089, 17239],
    55001: [16941, 17134, 14290, 16223, 20201, 20201, 17357],
    55201: [17053, 17251, 14413, 16326, 20313, 20313, 17475],
    55401: [17165, 17365, 14533, 16429, 20425, 20425, 17593],
    55601: [17277, 17482, 14656, 16532, 20537, 20537, 17711],
    55801: [17389, 17599, 14779, 16635, 20649, 20649, 17829],
    56001: [17501, 17674, 14860, 16738, 20761, 20761, 17947],
    56201: [17613, 17746, 14938, 16841, 20873, 20873, 18065],
    56401: [17725, 17818, 15016, 16945, 20985, 20985, 18183],
    56601: [17837, 17890, 15094, 17048, 21097, 21097, 18301],
    56801: [17949, 17975, 15185, 17151, 21209, 21209, 18419],
    57001: [18061, 18087, 15303, 17254, 21321, 21321, 18537],
    57201: [18173, 18199, 15421, 17357, 21433, 21433, 18655],
    57401: [18285, 18311, 15539, 17460, 21545, 21545, 18773],
    57601: [18397, 18423, 15657, 17563, 21657, 21657, 18891],
    57801: [18509, 18535, 15775, 17666, 21769, 21769, 19009],
    58001: [18621, 18647, 15893, 17769, 21881, 21881, 19127],
    58201: [18733, 18759, 16011, 17872, 21993, 21993, 19245],
    58401: [18845, 18871, 16129, 17975, 22105, 22105, 19363],
    58601: [18957, 18983, 16247, 18078, 22217, 22217, 19481],
    58801: [19069, 19095, 16365, 18182, 22329, 22329, 19599],
    59001: [19181, 19207, 16483, 18285, 22441, 22441, 19717],
    59201: [19298, 19324, 16606, 18388, 22553, 22553, 19835],
    59401: [19416, 19440, 16728, 18491, 22665, 22665, 19953],
    59601: [19534, 19562, 16856, 18594, 22777, 22777, 20071],
    59801: [19652, 19678, 16978, 18697, 22889, 22889, 20189],
    60001: [19770, 19795, 17101, 18800, 23001, 23001, 20307],
    60201: [19888, 19916, 17228, 18903, 23113, 23113, 20425],
    60401: [20006, 20033, 17351, 19006, 23225, 23225, 20543],
    60601: [20124, 20154, 17478, 19109, 23337, 23337, 20661],
    60801: [20242, 20271, 17601, 19212, 23449, 23449, 20779],
    61001: [20360, 20388, 17724, 19315, 23561, 23561, 20897],
    61201: [20478, 20509, 17851, 19418, 23673, 23673, 21015],
    61401: [20596, 20626, 17974, 19522, 23785, 23785, 21133],
    61601: [20714, 20742, 18096, 19625, 23897, 23897, 21251],
    61801: [20832, 20864, 18224, 19728, 24009, 24009, 21369],
    62001: [20950, 20980, 18346, 19831, 24121, 24121, 21487],
    62201: [21068, 21102, 18474, 19934, 24233, 24233, 21605],
    62401: [21186, 21218, 18596, 20037, 24345, 24345, 21723],
    62601: [21304, 21335, 18719, 20140, 24457, 24457, 21841],
    62801: [21422, 21456, 18846, 20243, 24569, 24569, 21959],
    63001: [21540, 21573, 18969, 20346, 24681, 24681, 22077],
    63201: [21658, 21690, 19092, 20449, 24793, 24793, 22195],
    63401: [21776, 21811, 19219, 20552, 24905, 24905, 22313],
    63601: [21894, 21928, 19342, 20655, 25017, 25017, 22431],
    63801: [22012, 22049, 19469, 20759, 25129, 25129, 22549],
    64001: [22130, 22166, 19592, 20862, 25241, 25241, 22667],
    64201: [22248, 22282, 19714, 20965, 25353, 25353, 22785],
    64401: [22366, 22404, 19842, 21068, 25465, 25465, 22903],
    64601: [22484, 22520, 19964, 21171, 25577, 25577, 23021],
    64801: [22602, 22637, 20087, 21274, 25689, 25689, 23139],
    65001: [22720, 22758, 20214, 21377, 25801, 25801, 23257],
    65201: [22838, 22875, 20337, 21480, 25913, 25913, 23375],
    65401: [22956, 22996, 20464, 21583, 26025, 26025, 23493],
    65601: [23074, 23113, 20587, 21686, 26137, 26137, 23611],
    65801: [23192, 23230, 20710, 21789, 26249, 26249, 23729],
    66001: [23310, 23351, 20837, 21892, 26361, 26361, 23847],
    66201: [23428, 23468, 20960, 21995, 26473, 26473, 23965],
    66401: [23546, 23584, 21082, 22099, 26585, 26585, 24083],
    66601: [23664, 23706, 21210, 22202, 26697, 26697, 24201],
    66801: [23782, 23822, 21332, 22305, 26809, 26809, 24319],
    67001: [23900, 23944, 21460, 22408, 26921, 26921, 24437],
    67201: [24018, 24060, 21582, 22511, 27033, 27033, 24555],
    67401: [24136, 24177, 21705, 22614, 27145, 27145, 24673],
    67601: [24254, 24298, 21832, 22717, 27257, 27257, 24791],
    67801: [24372, 24415, 21955, 22820, 27369, 27369, 24909],
    68001: [24490, 24536, 22082, 22923, 27481, 27481, 25027],
    68201: [24608, 24653, 22205, 23026, 27593, 27593, 25145],
    68401: [24726, 24770, 22328, 23129, 27705, 27705, 25263],
    68601: [24844, 24891, 22455, 23232, 27817, 27817, 25381],
    68801: [24962, 25008, 22578, 23336, 27929, 27929, 25499],
    69001: [25080, 25125, 22701, 23439, 28041, 28041, 25617],
    69201: [25198, 25246, 22828, 23542, 28153, 28153, 25735],
    69401: [25316, 25362, 22950, 23645, 28265, 28265, 25853],
    69601: [25434, 25484, 23078, 23748, 28377, 28377, 25971],
    69801: [25552, 25600, 23200, 23851, 28489, 28489, 26089],
    70001: [25670, 25717, 23323, 23954, 28601, 28601, 26207],
    70201: [25788, 25838, 23450, 24057, 28713, 28713, 26325],
    70401: [25906, 25955, 23573, 24160, 28825, 28825, 26443],
    70601: [26024, 26072, 23696, 24263, 28937, 28937, 26561],
    70801: [26142, 26193, 23823, 24366, 29049, 29049, 26679],
    71001: [26260, 26310, 23946, 24469, 29161, 29161, 26797],
    71201: [26378, 26431, 24073, 24573, 29273, 29273, 26915],
    71401: [26496, 26548, 24196, 24676, 29385, 29385, 27033],
    71601: [26614, 26664, 24318, 24779, 29497, 29497, 27151],
    71801: [26732, 26786, 24446, 24882, 29609, 29609, 27269],
    72001: [26850, 26902, 24568, 24985, 29721, 29721, 27387],
    72201: [26968, 27019, 24691, 25088, 29833, 29833, 27505],
    72401: [27086, 27140, 24818, 25191, 29945, 29945, 27623],
    72601: [27204, 27257, 24941, 25294, 30057, 30057, 27741],
    72801: [27322, 27378, 25068, 25397, 30169, 30169, 27859],
    73001: [27440, 27495, 25191, 25500, 30281, 30281, 27977],
    73201: [27558, 27612, 25314, 25603, 30393, 30393, 28095],
    73401: [27676, 27733, 25441, 25706, 30505, 30505, 28213],
    73601: [27794, 27850, 25564, 25809, 30617, 30617, 28331],
    73801: [27912, 27966, 25686, 25913, 30729, 30729, 28449],
    74001: [28030, 28088, 25814, 26016, 30841, 30841, 28567],
    74201: [28148, 28204, 25936, 26119, 30953, 30953, 28685],
    74401: [28266, 28326, 26064, 26222, 31065, 31065, 28803],
    74601: [28384, 28442, 26186, 26325, 31177, 31177, 28921],
    74801: [28502, 28559, 26309, 26428, 31289, 31289, 29039],
    75001: [28620, 28680, 26436, 26531, 31401, 31401, 29157],
    75201: [28738, 28797, 26559, 26634, 31513, 31513, 29275],
    75401: [28856, 28914, 26682, 26737, 31625, 31625, 29393],
    75601: [28974, 29035, 26809, 26840, 31737, 31737, 29511],
    75801: [29092, 29152, 26932, 26943, 31849, 31849, 29629],
    76001: [29210, 29273, 27059, 27046, 31961, 31961, 29747],
    76201: [29328, 29390, 27182, 27150, 32073, 32073, 29865],
    76401: [29446, 29506, 27304, 27253, 32185, 32185, 29983],
    76601: [29564, 29628, 27432, 27356, 32297, 32297, 30101],
    76801: [29682, 29744, 27554, 27459, 32409, 32409, 30219],
    77001: [29800, 29866, 27682, 27562, 32521, 32521, 30337],
    77201: [29918, 29982, 27804, 27665, 32633, 32633, 30455],
    77401: [30036, 30099, 27927, 27768, 32745, 32745, 30573],
    77601: [30154, 30220, 28054, 27871, 32857, 32857, 30691],
    77801: [30272, 30337, 28177, 27974, 32969, 32969, 30809],
    78001: [30390, 30454, 28300, 28077, 33081, 33081, 30927],
    78201: [30508, 30575, 28427, 28180, 33193, 33193, 31045],
    78401: [30626, 30692, 28550, 28283, 33305, 33305, 31163],
    78601: [30744, 30813, 28677, 28387, 33417, 33417, 31281],
    78801: [30862, 30930, 28800, 28490, 33529, 33529, 31399],
    79001: [30980, 31046, 28922, 28593, 33641, 33641, 31517],
    79201: [31098, 31168, 29050, 28696, 33753, 33753, 31635],
    79401: [31216, 31284, 29172, 28799, 33865, 33865, 31753],
    79601: [31334, 31401, 29295, 28902, 33977, 33977, 31871],
    79801: [31452, 31522, 29422, 29005, 34089, 34089, 31989],
    80001: [39, 39, 37, 36, 43, 43, 40, '%'],
    80201: [39, 40, 37, 36, 43, 43, 40, '%'],
    80601: [40, 40, 37, 36, 43, 43, 40, '%'],
    81201: [40, 40, 37, 37, 43, 43, 40, '%'],
    82201: [40, 40, 38, 37, 43, 43, 41, '%'],
    84601: [40, 41, 38, 37, 43, 43, 41, '%'],
    85001: [41, 41, 38, 37, 43, 43, 41, '%'],
    85601: [41, 41, 38, 37, 44, 44, 41, '%'],
    85801: [41, 41, 39, 37, 44, 44, 41, '%'],
    86801: [41, 41, 39, 37, 44, 44, 42, '%'],
    87001: [41, 41, 39, 38, 44, 44, 42, '%'],
    89401: [41, 42, 40, 38, 44, 44, 42, '%'],
    89801: [42, 42, 40, 38, 44, 44, 42, '%'],
    92001: [42, 42, 40, 38, 44, 44, 43, '%'],
    93001: [42, 42, 40, 38, 45, 45, 43, '%'],
    93601: [42, 42, 41, 39, 45, 45, 43, '%'],
    94801: [42, 43, 41, 39, 45, 45, 43, '%'],
    95401: [43, 43, 41, 39, 45, 45, 43, '%'],
    98001: [43, 43, 41, 39, 45, 45, 44, '%'],
    98201: [43, 43, 42, 39, 45, 45, 44, '%'],
    100801: [43, 44, 42, 39, 45, 45, 44, '%'],
    101401: [44, 44, 42, 40, 45, 45, 44, '%'],
    102001: [44, 44, 42, 40, 46, 46, 44, '%'],
    103201: [44, 44, 43, 40, 46, 46, 44, '%'],
    104801: [44, 44, 43, 40, 46, 46, 45, '%'],
    107601: [44, 45, 43, 40, 46, 46, 45, '%'],
    108601: [45, 45, 44, 40, 46, 46, 45, '%'],
    110601: [45, 45, 44, 41, 46, 46, 45, '%'],
    112601: [45, 45, 44, 41, 47, 47, 46, '%'],
    114801: [45, 45, 45, 41, 47, 47, 46, '%'],
    115401: [45, 46, 45, 41, 47, 47, 46, '%'],
    116601: [46, 46, 45, 41, 47, 47, 46, '%'],
    121601: [46, 46, 46, 42, 47, 47, 47, '%'],
    124601: [46, 47, 46, 42, 47, 47, 47, '%'],
    125801: [47, 47, 46, 42, 47, 47, 47, '%'],
    126001: [47, 47, 46, 42, 48, 48, 47, '%'],
    129401: [47, 47, 47, 42, 48, 48, 47, '%'],
    132201: [47, 47, 47, 42, 48, 48, 48, '%'],
    135201: [47, 48, 47, 43, 48, 48, 48, '%'],
    136801: [48, 48, 47, 43, 48, 48, 48, '%'],
    138201: [48, 48, 48, 43, 48, 48, 48, '%'],
    142801: [48, 48, 48, 43, 49, 49, 48, '%'],
    144801: [48, 48, 48, 43, 49, 49, 49, '%'],
    147801: [48, 49, 48, 43, 49, 49, 49, '%'],
    148201: [48, 49, 49, 43, 49, 49, 49, '%'],
    149801: [49, 49, 49, 43, 49, 49, 49, '%'],
    152001: [49, 49, 49, 44, 49, 49, 49, '%'],
    164601: [49, 50, 50, 44, 50, 50, 50, '%'],
    165601: [50, 50, 50, 44, 50, 50, 50, '%'],
    173601: [50, 50, 50, 45, 50, 50, 50, '%'],
    194601: [51, 51, 51, 45, 51, 51, 51, '%'],
    202401: [51, 51, 51, 46, 51, 51, 51, '%'],
    238001: [52, 52, 52, 46, 52, 52, 52, '%'],
    242401: [52, 52, 52, 47, 52, 52, 52, '%'],
    302601: [52, 52, 52, 48, 52, 52, 52, '%'],
    306001: [53, 53, 53, 48, 53, 53, 53, '%'],
    402001: [53, 53, 53, 49, 53, 53, 53, '%'],
    428401: [54, 54, 54, 49, 54, 54, 54, '%'],
    599201: [54, 54, 54, 50, 54, 54, 54, '%'],
    714001: [55, 55, 55, 50, 55, 55, 55, '%'],
    1175001: [55, 55, 55, 51, 55, 55, 55, '%']
  },
  37: {
    1: [0, 0, 0, 0, 0, 0, 0],
    1801: [134, 0, 133, 0, 134, 15, 134],
    1901: [144, 0, 141, 3, 144, 53, 144],
    2001: [155, 0, 150, 5, 155, 91, 155],
    2101: [157, 0, 150, 7, 157, 129, 157],
    2201: [167, 0, 158, 9, 167, 167, 167],
    2301: [178, 0, 166, 11, 205, 205, 178],
    2401: [188, 0, 175, 13, 243, 243, 188],
    2501: [199, 0, 183, 16, 281, 281, 199],
    2601: [209, 0, 191, 18, 319, 319, 209],
    2701: [220, 0, 200, 20, 357, 357, 220],
    2801: [222, 0, 200, 22, 395, 395, 222],
    2901: [232, 0, 208, 24, 433, 433, 232],
    3001: [243, 0, 216, 26, 471, 471, 243],
    3101: [253, 0, 225, 28, 509, 509, 253],
    3201: [264, 0, 233, 31, 547, 547, 264],
    3301: [274, 0, 241, 33, 585, 585, 274],
    3401: [277, 0, 241, 35, 623, 623, 277],
    3501: [287, 0, 250, 37, 661, 661, 287],
    3601: [298, 0, 258, 39, 699, 699, 298],
    3701: [308, 0, 266, 41, 737, 737, 308],
    3801: [319, 0, 275, 44, 775, 775, 319],
    3901: [329, 0, 283, 50, 813, 813, 329],
    4001: [331, 0, 283, 76, 851, 851, 331],
    4101: [342, 0, 291, 102, 889, 889, 342],
    4201: [352, 0, 300, 128, 927, 927, 352],
    4301: [363, 0, 308, 153, 962, 962, 363],
    4401: [373, 0, 316, 178, 991, 991, 373],
    4501: [383, 0, 325, 204, 1022, 1022, 383],
    4601: [384, 0, 325, 229, 1054, 1054, 384],
    4701: [394, 0, 333, 255, 1082, 1082, 394],
    4801: [405, 9, 342, 280, 1114, 1114, 405],
    4901: [414, 37, 352, 305, 1143, 1143, 414],
    5001: [425, 66, 362, 331, 1174, 1174, 425],
    5101: [435, 94, 372, 356, 1206, 1206, 435],
    5201: [445, 123, 382, 381, 1234, 1234, 445],
    5301: [447, 151, 383, 407, 1266, 1266, 447],
    5401: [456, 180, 393, 432, 1294, 1294, 456],
    5501: [467, 208, 403, 456, 1325, 1325, 467],
    5601: [477, 237, 413, 481, 1356, 1356, 477],
    5701: [487, 265, 423, 506, 1384, 1384, 487],
    5801: [498, 291, 433, 531, 1415, 1415, 497],
    5901: [521, 319, 434, 555, 1443, 1443, 498],
    6001: [545, 348, 444, 580, 1474, 1474, 509],
    6101: [568, 376, 454, 605, 1505, 1505, 519],
    6201: [591, 405, 464, 630, 1533, 1533, 529],
    6301: [614, 433, 474, 654, 1564, 1564, 539],
    6401: [637, 462, 484, 679, 1592, 1592, 549],
    6501: [660, 490, 486, 704, 1623, 1623, 551],
    6601: [683, 519, 496, 729, 1654, 1654, 561],
    6701: [706, 547, 506, 754, 1682, 1682, 571],
    6801: [729, 576, 516, 778, 1713, 1713, 581],
    6901: [752, 601, 525, 803, 1741, 1741, 591],
    7001: [775, 630, 535, 828, 1772, 1772, 601],
    7101: [799, 658, 537, 853, 1803, 1803, 603],
    7201: [822, 687, 547, 877, 1831, 1831, 613],
    7301: [845, 715, 557, 902, 1862, 1862, 623],
    7401: [868, 744, 567, 927, 1890, 1890, 633],
    7501: [891, 772, 577, 952, 1921, 1921, 643],
    7601: [914, 801, 587, 977, 1952, 1952, 653],
    7701: [937, 829, 597, 1001, 1980, 1980, 663],
    7801: [960, 858, 598, 1026, 2011, 2011, 665],
    7901: [983, 886, 608, 1051, 2039, 2039, 675],
    8001: [1006, 915, 618, 1075, 2070, 2070, 685],
    8101: [1029, 940, 628, 1100, 2101, 2101, 695],
    8201: [1052, 968, 638, 1125, 2129, 2129, 705],
    8301: [1076, 997, 648, 1150, 2160, 2160, 715],
    8401: [1099, 1025, 649, 1175, 2188, 2188, 717],
    8501: [1122, 1048, 659, 1199, 2219, 2219, 727],
    8601: [1145, 1070, 669, 1224, 2250, 2250, 737],
    8701: [1168, 1095, 678, 1249, 2278, 2278, 747],
    8801: [1191, 1117, 688, 1274, 2309, 2309, 757],
    8901: [1214, 1139, 698, 1298, 2337, 2337, 767],
    9001: [1237, 1162, 699, 1323, 2368, 2368, 769],
    9101: [1260, 1187, 709, 1348, 2399, 2399, 779],
    9201: [1283, 1209, 718, 1373, 2427, 2427, 789],
    9301: [1306, 1231, 728, 1397, 2458, 2458, 799],
    9401: [1329, 1253, 737, 1422, 2486, 2486, 809],
    9501: [1353, 1279, 747, 1447, 2517, 2517, 819],
    9601: [1376, 1300, 748, 1472, 2548, 2548, 821],
    9701: [1399, 1322, 758, 1496, 2576, 2576, 831],
    9801: [1422, 1344, 768, 1521, 2607, 2607, 841],
    9901: [1445, 1369, 777, 1546, 2635, 2635, 851],
    10001: [1468, 1390, 787, 1571, 2666, 2666, 861],
    10101: [1491, 1412, 797, 1596, 2697, 2697, 871],
    10201: [1514, 1434, 806, 1620, 2725, 2725, 881],
    10301: [1537, 1459, 808, 1645, 2756, 2756, 883],
    10401: [1560, 1480, 817, 1670, 2784, 2784, 893],
    10501: [1583, 1502, 827, 1695, 2815, 2815, 903],
    10601: [1607, 1524, 836, 1719, 2846, 2846, 913],
    10701: [1630, 1549, 846, 1744, 2874, 2874, 923],
    10801: [1653, 1570, 856, 1769, 2905, 2905, 933],
    10901: [1676, 1592, 857, 1794, 2933, 2933, 935],
    11001: [1699, 1617, 867, 1818, 2964, 2964, 945],
    11101: [1722, 1639, 876, 1843, 2995, 2995, 961],
    11201: [1745, 1660, 886, 1868, 3023, 3023, 982],
    11301: [1768, 1682, 896, 1893, 3054, 3054, 1006],
    11401: [1791, 1707, 905, 1917, 3082, 3082, 1027],
    11501: [1814, 1729, 907, 1942, 3113, 3113, 1051],
    11601: [1837, 1750, 916, 1967, 3144, 3144, 1075],
    11701: [1860, 1772, 926, 1992, 3172, 3172, 1096],
    11801: [1883, 1797, 936, 2017, 3203, 3203, 1120],
    11901: [1907, 1819, 945, 2041, 3237, 3237, 1147],
    12001: [1930, 1840, 955, 2067, 3275, 3275, 1178],
    12101: [1953, 1862, 956, 2092, 3312, 3312, 1208],
    12201: [1977, 1887, 966, 2117, 3349, 3349, 1238],
    12301: [2000, 1909, 975, 2142, 3386, 3386, 1268],
    12401: [2024, 1930, 985, 2167, 3424, 3424, 1299],
    12501: [2047, 1952, 995, 2192, 3461, 3461, 1329],
    12601: [2070, 1977, 1004, 2217, 3498, 3498, 1359],
    12701: [2094, 1999, 1014, 2242, 3535, 3535, 1389],
    12801: [2117, 2020, 1015, 2267, 3573, 3573, 1420],
    12901: [2140, 2045, 1025, 2292, 3610, 3610, 1450],
    13001: [2164, 2067, 1035, 2317, 3647, 3647, 1480],
    13101: [2187, 2089, 1044, 2342, 3684, 3684, 1510],
    13201: [2210, 2110, 1054, 2367, 3722, 3722, 1541],
    13301: [2234, 2135, 1064, 2392, 3759, 3759, 1571],
    13401: [2257, 2157, 1065, 2417, 3796, 3796, 1601],
    13501: [2281, 2179, 1074, 2442, 3833, 3833, 1631],
    13601: [2304, 2201, 1084, 2467, 3874, 3874, 1665],
    13701: [2328, 2225, 1094, 2493, 3917, 3917, 1701],
    13801: [2351, 2247, 1103, 2518, 3957, 3957, 1734],
    13901: [2375, 2269, 1113, 2543, 3998, 3998, 1768],
    14001: [2398, 2291, 1114, 2568, 4038, 4038, 1801],
    14101: [2424, 2318, 1124, 2595, 4078, 4078, 1834],
    14201: [2456, 2350, 1134, 2627, 4120, 4120, 1869],
    14301: [2488, 2384, 1145, 2659, 4160, 4160, 1902],
    14401: [2520, 2415, 1155, 2690, 4199, 4199, 1934],
    14501: [2551, 2446, 1165, 2722, 4238, 4238, 1966],
    14601: [2583, 2480, 1167, 2754, 4277, 4277, 1998],
    14701: [2615, 2511, 1177, 2786, 4320, 4320, 2034],
    14801: [2646, 2545, 1187, 2817, 4359, 4359, 2066],
    14901: [2678, 2576, 1197, 2849, 4398, 4398, 2098],
    15001: [2710, 2607, 1208, 2881, 4438, 4438, 2131],
    15101: [2741, 2641, 1218, 2912, 4477, 4477, 2163],
    15201: [2773, 2672, 1228, 2944, 4519, 4519, 2198],
    15301: [2805, 2706, 1230, 2976, 4558, 4558, 2230],
    15401: [2837, 2738, 1240, 3007, 4598, 4598, 2263],
    15501: [2868, 2769, 1250, 3039, 4637, 4637, 2295],
    15601: [2900, 2803, 1261, 3071, 4676, 4676, 2327],
    15701: [2932, 2834, 1271, 3103, 4718, 4718, 2362],
    15801: [2963, 2868, 1281, 3134, 4758, 4758, 2395],
    15901: [2995, 2899, 1283, 3166, 4797, 4797, 2427],
    16001: [3027, 2930, 1293, 3198, 4836, 4836, 2459],
    16101: [3058, 2964, 1303, 3229, 4876, 4876, 2492],
    16201: [3090, 2995, 1313, 3261, 4918, 4918, 2527],
    16301: [3122, 3029, 1324, 3293, 4957, 4957, 2559],
    16401: [3154, 3060, 1334, 3325, 4996, 4996, 2591],
    16501: [3185, 3091, 1336, 3356, 5036, 5036, 2624],
    16601: [3217, 3126, 1346, 3388, 5075, 5075, 2656],
    16701: [3249, 3157, 1356, 3420, 5117, 5117, 2691],
    16801: [3280, 3191, 1366, 3451, 5156, 5156, 2723],
    16901: [3312, 3222, 1377, 3483, 5196, 5196, 2756],
    17001: [3344, 3253, 1387, 3515, 5235, 5235, 2788],
    17101: [3376, 3287, 1389, 3546, 5274, 5274, 2820],
    17201: [3407, 3318, 1399, 3578, 5317, 5317, 2856],
    17301: [3439, 3352, 1409, 3610, 5356, 5356, 2888],
    17401: [3471, 3383, 1419, 3642, 5395, 5395, 2920],
    17501: [3502, 3414, 1429, 3673, 5434, 5434, 2952],
    17601: [3534, 3448, 1440, 3705, 5474, 5474, 2985],
    17701: [3566, 3479, 1450, 3737, 5516, 5516, 3020],
    17801: [3597, 3514, 1452, 3768, 5555, 5555, 3052],
    17901: [3629, 3545, 1462, 3800, 5594, 5594, 3084],
    18001: [3661, 3576, 1472, 3832, 5634, 5634, 3117],
    18101: [3693, 3610, 1482, 3863, 5673, 5673, 3149],
    18201: [3724, 3641, 1493, 3895, 5715, 5715, 3184],
    18301: [3756, 3675, 1503, 3927, 5755, 5755, 3217],
    18401: [3788, 3706, 1505, 3959, 5794, 5794, 3249],
    18501: [3819, 3737, 1515, 3990, 5833, 5833, 3281],
    18601: [3851, 3771, 1525, 4022, 5872, 5872, 3313],
    18701: [3883, 3802, 1535, 4054, 5915, 5915, 3349],
    18801: [3914, 3836, 1546, 4085, 5954, 5954, 3381],
    18901: [3946, 3867, 1556, 4117, 5993, 5993, 3413],
    19001: [3978, 3902, 1558, 4149, 6033, 6033, 3446],
    19101: [4010, 3933, 1568, 4180, 6072, 6072, 3478],
    19201: [4041, 3964, 1578, 4212, 6114, 6114, 3513],
    19301: [4073, 3998, 1588, 4244, 6153, 6153, 3545],
    19401: [4105, 4029, 1598, 4276, 6193, 6193, 3578],
    19501: [4136, 4063, 1609, 4307, 6232, 6232, 3610],
    19601: [4168, 4093, 1610, 4339, 6271, 6271, 3642],
    19701: [4200, 4123, 1619, 4371, 6313, 6313, 3677],
    19801: [4232, 4157, 1628, 4402, 6353, 6353, 3710],
    19901: [4263, 4187, 1638, 4434, 6392, 6392, 3742],
    20001: [4327, 4250, 1656, 4498, 6471, 6471, 3807],
    20201: [4390, 4314, 1667, 4561, 6552, 6552, 3874],
    20401: [4453, 4377, 1685, 4624, 6631, 6631, 3939],
    20601: [4517, 4438, 1732, 4688, 6712, 6712, 4006],
    20801: [4580, 4501, 1781, 4751, 6791, 6791, 4071],
    21001: [4644, 4564, 1830, 4815, 6869, 6869, 4135],
    21201: [4707, 4628, 1880, 4878, 6951, 6951, 4203],
    21401: [4770, 4691, 1929, 4941, 7029, 7029, 4267],
    21601: [4834, 4752, 1976, 5005, 7111, 7111, 4335],
    21801: [4897, 4815, 2025, 5068, 7189, 7189, 4399],
    22001: [4961, 4879, 2075, 5132, 7268, 7268, 4464],
    22201: [5024, 4942, 2124, 5195, 7350, 7350, 4532],
    22401: [5088, 5005, 2173, 5259, 7429, 7429, 4597],
    22601: [5154, 5066, 2220, 5324, 7512, 7512, 4666],
    22801: [5219, 5129, 2269, 5389, 7592, 7592, 4732],
    23001: [5284, 5193, 2319, 5454, 7673, 7673, 4799],
    23201: [5349, 5256, 2368, 5520, 7756, 7756, 4868],
    23401: [5414, 5320, 2418, 5585, 7836, 7836, 4934],
    23601: [5479, 5383, 2467, 5650, 7919, 7919, 5003],
    23801: [5544, 5443, 2513, 5715, 8000, 8000, 5070],
    24001: [5609, 5507, 2563, 5780, 8080, 8080, 5136],
    24201: [5674, 5573, 2615, 5845, 8163, 8163, 5205],
    24401: [5739, 5637, 2665, 5910, 8243, 8243, 5271],
    24601: [5804, 5700, 2714, 5975, 8326, 8326, 5340],
    24801: [5869, 5764, 2764, 6040, 8406, 8406, 5406],
    25001: [5934, 5830, 2830, 6105, 8487, 8487, 5487],
    25201: [5999, 5894, 2894, 6170, 8570, 8570, 5570],
    25401: [6064, 5957, 2957, 6235, 8650, 8650, 5650],
    25601: [6129, 6024, 3024, 6300, 8733, 8733, 5733],
    25801: [6194, 6087, 3087, 6365, 8813, 8813, 5813],
    26001: [6259, 6150, 3150, 6430, 8894, 8894, 5894],
    26201: [6325, 6217, 3217, 6495, 8977, 8977, 5977],
    26401: [6390, 6280, 3280, 6560, 9057, 9057, 6057],
    26601: [6455, 6344, 3344, 6626, 9140, 9140, 6140],
    26801: [6520, 6410, 3410, 6691, 9220, 9220, 6220],
    27001: [6585, 6474, 3474, 6756, 9301, 9301, 6301],
    27201: [6650, 6537, 3537, 6821, 9384, 9384, 6384],
    27401: [6715, 6603, 3603, 6886, 9464, 9464, 6464],
    27601: [6780, 6667, 3667, 6951, 9547, 9547, 6547],
    27801: [6845, 6730, 3730, 7016, 9627, 9627, 6627],
    28001: [6910, 6794, 3794, 7081, 9708, 9708, 6708],
    28201: [6975, 6860, 3860, 7146, 9791, 9791, 6791],
    28401: [7040, 6924, 3924, 7211, 9871, 9871, 6871],
    28601: [7105, 6987, 3987, 7276, 9954, 9954, 6954],
    28801: [7170, 7054, 4054, 7341, 10034, 10034, 7034],
    29001: [7235, 7117, 4117, 7406, 10115, 10115, 7115],
    29201: [7300, 7180, 4180, 7471, 10198, 10198, 7198],
    29401: [7365, 7247, 4247, 7536, 10278, 10278, 7278],
    29601: [7431, 7310, 4310, 7601, 10361, 10361, 7361],
    29801: [7496, 7374, 4374, 7666, 10441, 10441, 7441],
    30001: [7561, 7441, 4441, 7731, 10522, 10522, 7522],
    30201: [7626, 7506, 4506, 7797, 10605, 10605, 7605],
    30401: [7691, 7571, 4571, 7862, 10685, 10685, 7685],
    30601: [7756, 7636, 4636, 7927, 10768, 10768, 7768],
    30801: [7821, 7703, 4703, 7992, 10848, 10848, 7848],
    31001: [7886, 7768, 4768, 8057, 10929, 10929, 7929],
    31201: [7951, 7833, 4833, 8122, 11012, 11012, 8012],
    31401: [8016, 7901, 4901, 8187, 11092, 11092, 8092],
    31601: [8081, 7966, 4966, 8252, 11175, 11175, 8175],
    31801: [8146, 8030, 5030, 8317, 11255, 11255, 8255],
    32001: [8211, 8098, 5098, 8382, 11336, 11336, 8336],
    32201: [8276, 8163, 5163, 8447, 11419, 11419, 8419],
    32401: [8341, 8228, 5228, 8512, 11499, 11499, 8499],
    32601: [8406, 8295, 5295, 8577, 11582, 11582, 8582],
    32801: [8471, 8360, 5360, 8642, 11662, 11662, 8662],
    33001: [8536, 8425, 5425, 8707, 11743, 11743, 8743],
    33201: [8602, 8493, 5493, 8772, 11826, 11826, 8826],
    33401: [8667, 8558, 5558, 8837, 11906, 11906, 8906],
    33601: [8732, 8622, 5622, 8903, 11989, 11989, 8989],
    33801: [8797, 8687, 5687, 8968, 12069, 12069, 9069],
    34001: [8862, 8755, 5755, 9033, 12150, 12150, 9150],
    34201: [8927, 8820, 5820, 9098, 12233, 12233, 9233],
    34401: [8992, 8884, 5884, 9163, 12310, 12310, 9310],
    34601: [9057, 8949, 5949, 9227, 12384, 12384, 9384],
    34801: [9121, 9011, 6011, 9292, 12458, 12458, 9458],
    35001: [9186, 9076, 6076, 9357, 12532, 12532, 9532],
    35201: [9253, 9143, 6143, 9422, 12606, 12606, 9606],
    35401: [9327, 9217, 6217, 9487, 12680, 12680, 9680],
    35601: [9401, 9291, 6291, 9552, 12754, 12754, 9754],
    35801: [9475, 9365, 6365, 9616, 12828, 12828, 9828],
    36001: [9549, 9439, 6439, 9681, 12902, 12902, 9902],
    36201: [9623, 9513, 6513, 9746, 12976, 12976, 9976],
    36401: [9697, 9587, 6587, 9811, 13050, 13050, 10050],
    36601: [9771, 9661, 6661, 9876, 13124, 13124, 10124],
    36801: [9845, 9735, 6735, 9941, 13198, 13198, 10198],
    37001: [9919, 9809, 6809, 10005, 13272, 13272, 10272],
    37201: [9993, 9883, 6883, 10070, 13346, 13346, 10346],
    37401: [10067, 9957, 6957, 10135, 13420, 13420, 10420],
    37601: [10141, 10031, 7031, 10200, 13494, 13494, 10494],
    37801: [10215, 10105, 7105, 10265, 13568, 13568, 10568],
    38001: [10289, 10179, 7179, 10329, 13642, 13642, 10642],
    38201: [10363, 10253, 7253, 10394, 13716, 13716, 10716],
    38401: [10437, 10327, 7327, 10459, 13790, 13790, 10790],
    38601: [10511, 10401, 7401, 10524, 13864, 13864, 10864],
    38801: [10585, 10475, 7475, 10589, 13938, 13938, 10938],
    39001: [10659, 10549, 7549, 10654, 14012, 14012, 11012],
    39201: [10733, 10623, 7623, 10718, 14086, 14086, 11086],
    39401: [10807, 10697, 7697, 10783, 14160, 14160, 11160],
    39601: [10881, 10771, 7771, 10848, 14234, 14234, 11234],
    39801: [10955, 10845, 7845, 10913, 14308, 14308, 11308],
    40001: [11029, 10919, 7919, 10978, 14382, 14382, 11382],
    40201: [11103, 10993, 7993, 11043, 14456, 14456, 11456],
    40401: [11177, 11067, 8067, 11107, 14530, 14530, 11530],
    40601: [11251, 11141, 8141, 11172, 14604, 14604, 11604],
    40801: [11325, 11215, 8215, 11237, 14678, 14678, 11678],
    41001: [11399, 11289, 8289, 11302, 14752, 14752, 11752],
    41201: [11473, 11363, 8363, 11367, 14826, 14826, 11826],
    41401: [11547, 11437, 8437, 11432, 14900, 14900, 11900],
    41601: [11621, 11511, 8511, 11496, 14974, 14974, 11974],
    41801: [11695, 11585, 8585, 11561, 15048, 15048, 12048],
    42001: [11769, 11659, 8659, 11626, 15122, 15122, 12122],
    42201: [11843, 11733, 8733, 11691, 15196, 15196, 12196],
    42401: [11917, 11807, 8807, 11756, 15270, 15270, 12270],
    42601: [11991, 11881, 8881, 11820, 15344, 15344, 12344],
    42801: [12065, 11955, 8955, 11885, 15418, 15418, 12418],
    43001: [12139, 12029, 9029, 11950, 15492, 15492, 12492],
    43201: [12213, 12103, 9103, 12015, 15566, 15566, 12566],
    43401: [12287, 12177, 9177, 12080, 15640, 15640, 12640],
    43601: [12361, 12251, 9251, 12145, 15714, 15714, 12714],
    43801: [12435, 12325, 9325, 12209, 15788, 15788, 12788],
    44001: [12509, 12399, 9399, 12274, 15862, 15862, 12862],
    44201: [12583, 12473, 9473, 12339, 15936, 15936, 12936],
    44401: [12657, 12547, 9547, 12404, 16010, 16010, 13010],
    44601: [12731, 12621, 9621, 12469, 16084, 16084, 13084],
    44801: [12805, 12695, 9695, 12534, 16158, 16158, 13158],
    45001: [12879, 12769, 9769, 12598, 16232, 16232, 13232],
    45201: [12953, 12843, 9843, 12663, 16306, 16306, 13306],
    45401: [13027, 12917, 9917, 12728, 16380, 16380, 13380],
    45601: [13101, 12991, 9991, 12793, 16454, 16454, 13454],
    45801: [13175, 13065, 10065, 12858, 16528, 16528, 13528],
    46001: [13249, 13139, 10139, 12922, 16602, 16602, 13602],
    46201: [13323, 13213, 10213, 12987, 16676, 16676, 13676],
    46401: [13397, 13287, 10287, 13052, 16750, 16750, 13750],
    46601: [13471, 13361, 10361, 13117, 16824, 16824, 13824],
    46801: [13545, 13435, 10435, 13182, 16898, 16898, 13898],
    47001: [13619, 13509, 10509, 13247, 16972, 16972, 13972],
    47201: [13693, 13583, 10583, 13311, 17046, 17046, 14046],
    47401: [13767, 13657, 10657, 13376, 17120, 17120, 14120],
    47601: [13841, 13731, 10731, 13441, 17194, 17194, 14194],
    47801: [13915, 13805, 10805, 13506, 17268, 17268, 14268],
    48001: [13989, 13879, 10879, 13571, 17342, 17342, 14342],
    48201: [14063, 13953, 10953, 13636, 17416, 17416, 14416],
    48401: [14137, 14027, 11027, 13700, 17490, 17490, 14490],
    48601: [14211, 14101, 11101, 13765, 17564, 17564, 14564],
    48801: [14285, 14175, 11175, 13830, 17638, 17638, 14638],
    49001: [14359, 14249, 11249, 13895, 17712, 17712, 14712],
    49201: [14433, 14323, 11323, 13960, 17786, 17786, 14786],
    49401: [14507, 14397, 11397, 14025, 17860, 17860, 14860],
    49601: [14581, 14471, 11471, 14089, 17934, 17934, 14934],
    49801: [14655, 14545, 11545, 14154, 18008, 18008, 15008],
    50001: [14729, 14619, 11625, 14219, 18082, 18082, 15088],
    50201: [14803, 14733, 11745, 14284, 18156, 18156, 15168],
    50401: [14877, 14851, 11869, 14349, 18230, 18230, 15248],
    50601: [14951, 14971, 11995, 14413, 18304, 18304, 15328],
    50801: [15025, 15091, 12121, 14478, 18378, 18378, 15408],
    51001: [15107, 15211, 12247, 14551, 18460, 18460, 15496],
    51201: [15221, 15332, 12374, 14656, 18574, 18574, 15616],
    51401: [15335, 15452, 12500, 14761, 18688, 18688, 15736],
    51601: [15449, 15572, 12626, 14866, 18802, 18802, 15856],
    51801: [15563, 15692, 12752, 14971, 18916, 18916, 15976],
    52001: [15677, 15810, 12876, 15076, 19030, 19030, 16096],
    52201: [15791, 15930, 13002, 15180, 19144, 19144, 16216],
    52401: [15905, 16050, 13128, 15285, 19258, 19258, 16336],
    52601: [16019, 16170, 13254, 15390, 19372, 19372, 16456],
    52801: [16133, 16291, 13381, 15495, 19486, 19486, 16576],
    53001: [16247, 16411, 13507, 15600, 19600, 19600, 16696],
    53201: [16361, 16531, 13633, 15705, 19714, 19714, 16816],
    53401: [16475, 16651, 13759, 15809, 19828, 19828, 16936],
    53601: [16589, 16768, 13882, 15914, 19942, 19942, 17056],
    53801: [16703, 16889, 14009, 16019, 20056, 20056, 17176],
    54001: [16817, 17009, 14135, 16124, 20170, 20170, 17296],
    54201: [16931, 17129, 14261, 16229, 20284, 20284, 17416],
    54401: [17045, 17250, 14388, 16333, 20398, 20398, 17536],
    54601: [17159, 17370, 14514, 16438, 20512, 20512, 17656],
    54801: [17273, 17490, 14640, 16543, 20626, 20626, 17776],
    55001: [17387, 17610, 14766, 16648, 20740, 20740, 17896],
    55201: [17501, 17730, 14892, 16753, 20854, 20854, 18016],
    55401: [17615, 17848, 15016, 16858, 20968, 20968, 18136],
    55601: [17729, 17968, 15142, 16962, 21082, 21082, 18256],
    55801: [17843, 18088, 15268, 17067, 21196, 21196, 18376],
    56001: [17957, 18165, 15351, 17172, 21310, 21310, 18496],
    56201: [18071, 18239, 15431, 17277, 21424, 21424, 18616],
    56401: [18185, 18313, 15511, 17382, 21538, 21538, 18736],
    56601: [18299, 18387, 15591, 17487, 21652, 21652, 18856],
    56801: [18413, 18475, 15685, 17591, 21766, 21766, 18976],
    57001: [18527, 18589, 15805, 17696, 21880, 21880, 19096],
    57201: [18641, 18703, 15925, 17801, 21994, 21994, 19216],
    57401: [18755, 18817, 16045, 17906, 22108, 22108, 19336],
    57601: [18869, 18931, 16165, 18011, 22222, 22222, 19456],
    57801: [18983, 19045, 16285, 18115, 22336, 22336, 19576],
    58001: [19097, 19159, 16405, 18220, 22450, 22450, 19696],
    58201: [19211, 19273, 16525, 18325, 22564, 22564, 19816],
    58401: [19325, 19387, 16645, 18430, 22678, 22678, 19936],
    58601: [19439, 19501, 16765, 18535, 22792, 22792, 20056],
    58801: [19553, 19615, 16885, 18640, 22906, 22906, 20176],
    59001: [19667, 19729, 17005, 18745, 23020, 23020, 20296],
    59201: [19786, 19847, 17129, 18849, 23134, 23134, 20416],
    59401: [19906, 19966, 17254, 18954, 23248, 23248, 20536],
    59601: [20026, 20090, 17384, 19059, 23362, 23362, 20656],
    59801: [20146, 20208, 17508, 19164, 23476, 23476, 20776],
    60001: [20266, 20327, 17633, 19269, 23590, 23590, 20896],
    60201: [20386, 20451, 17763, 19373, 23704, 23704, 21016],
    60401: [20506, 20569, 17887, 19478, 23818, 23818, 21136],
    60601: [20626, 20693, 18017, 19583, 23932, 23932, 21256],
    60801: [20746, 20812, 18142, 19688, 24046, 24046, 21376],
    61001: [20866, 20930, 18266, 19793, 24160, 24160, 21496],
    61201: [20986, 21054, 18396, 19898, 24274, 24274, 21616],
    61401: [21106, 21173, 18521, 20002, 24388, 24388, 21736],
    61601: [21226, 21291, 18645, 20107, 24502, 24502, 21856],
    61801: [21346, 21415, 18775, 20212, 24616, 24616, 21976],
    62001: [21466, 21534, 18900, 20317, 24730, 24730, 22096],
    62201: [21586, 21657, 19029, 20422, 24844, 24844, 22216],
    62401: [21706, 21776, 19154, 20526, 24958, 24958, 22336],
    62601: [21826, 21895, 19279, 20631, 25072, 25072, 22456],
    62801: [21946, 22018, 19408, 20736, 25186, 25186, 22576],
    63001: [22066, 22137, 19533, 20841, 25300, 25300, 22696],
    63201: [22186, 22256, 19658, 20946, 25414, 25414, 22816],
    63401: [22306, 22379, 19787, 21051, 25528, 25528, 22936],
    63601: [22426, 22498, 19912, 21155, 25642, 25642, 23056],
    63801: [22546, 22621, 20041, 21260, 25756, 25756, 23176],
    64001: [22666, 22740, 20166, 21365, 25870, 25870, 23296],
    64201: [22786, 22859, 20291, 21470, 25984, 25984, 23416],
    64401: [22906, 22982, 20420, 21575, 26098, 26098, 23536],
    64601: [23026, 23101, 20545, 21680, 26212, 26212, 23656],
    64801: [23146, 23220, 20670, 21784, 26326, 26326, 23776],
    65001: [23266, 23343, 20799, 21889, 26440, 26440, 23896],
    65201: [23386, 23462, 20924, 21994, 26554, 26554, 24016],
    65401: [23506, 23586, 21054, 22099, 26668, 26668, 24136],
    65601: [23626, 23704, 21178, 22204, 26782, 26782, 24256],
    65801: [23746, 23823, 21303, 22308, 26896, 26896, 24376],
    66001: [23866, 23947, 21433, 22413, 27010, 27010, 24496],
    66201: [23986, 24065, 21557, 22518, 27124, 27124, 24616],
    66401: [24106, 24184, 21682, 22623, 27238, 27238, 24736],
    66601: [24226, 24308, 21812, 22728, 27352, 27352, 24856],
    66801: [24346, 24426, 21936, 22833, 27466, 27466, 24976],
    67001: [24466, 24550, 22066, 22937, 27580, 27580, 25096],
    67201: [24586, 24669, 22191, 23042, 27694, 27694, 25216],
    67401: [24706, 24787, 22315, 23147, 27808, 27808, 25336],
    67601: [24826, 24911, 22445, 23252, 27922, 27922, 25456],
    67801: [24946, 25030, 22570, 23357, 28036, 28036, 25576],
    68001: [25066, 25153, 22699, 23462, 28150, 28150, 25696],
    68201: [25186, 25272, 22824, 23566, 28264, 28264, 25816],
    68401: [25306, 25391, 22949, 23671, 28378, 28378, 25936],
    68601: [25426, 25514, 23078, 23776, 28492, 28492, 26056],
    68801: [25546, 25633, 23203, 23881, 28606, 28606, 26176],
    69001: [25666, 25752, 23328, 23986, 28720, 28720, 26296],
    69201: [25786, 25875, 23457, 24091, 28834, 28834, 26416],
    69401: [25906, 25994, 23582, 24195, 28948, 28948, 26536],
    69601: [26026, 26117, 23711, 24300, 29062, 29062, 26656],
    69801: [26146, 26236, 23836, 24405, 29176, 29176, 26776],
    70001: [26266, 26355, 23961, 24510, 29290, 29290, 26896],
    70201: [26386, 26478, 24090, 24615, 29404, 29404, 27016],
    70401: [26506, 26597, 24215, 24719, 29518, 29518, 27136],
    70601: [26626, 26716, 24340, 24824, 29632, 29632, 27256],
    70801: [26746, 26839, 24469, 24929, 29746, 29746, 27376],
    71001: [26866, 26958, 24594, 25034, 29860, 29860, 27496],
    71201: [26986, 27082, 24724, 25139, 29974, 29974, 27616],
    71401: [27106, 27200, 24848, 25244, 30088, 30088, 27736],
    71601: [27226, 27319, 24973, 25348, 30202, 30202, 27856],
    71801: [27346, 27443, 25103, 25453, 30316, 30316, 27976],
    72001: [27466, 27561, 25227, 25558, 30430, 30430, 28096],
    72201: [27586, 27680, 25352, 25663, 30544, 30544, 28216],
    72401: [27706, 27804, 25482, 25768, 30658, 30658, 28336],
    72601: [27826, 27922, 25606, 25873, 30772, 30772, 28456],
    72801: [27946, 28046, 25736, 25977, 30886, 30886, 28576],
    73001: [28066, 28165, 25861, 26082, 31000, 31000, 28696],
    73201: [28186, 28283, 25985, 26187, 31114, 31114, 28816],
    73401: [28306, 28407, 26115, 26292, 31228, 31228, 28936],
    73601: [28426, 28526, 26240, 26397, 31342, 31342, 29056],
    73801: [28546, 28644, 26364, 26501, 31456, 31456, 29176],
    74001: [28666, 28768, 26494, 26606, 31570, 31570, 29296],
    74201: [28786, 28887, 26619, 26711, 31684, 31684, 29416],
    74401: [28906, 29010, 26748, 26816, 31798, 31798, 29536],
    74601: [29026, 29129, 26873, 26921, 31912, 31912, 29656],
    74801: [29146, 29248, 26998, 27026, 32026, 32026, 29776],
    75001: [29266, 29371, 27127, 27130, 32140, 32140, 29896],
    75201: [29386, 29490, 27252, 27235, 32254, 32254, 30016],
    75401: [29506, 29609, 27377, 27340, 32368, 32368, 30136],
    75601: [29626, 29732, 27506, 27445, 32482, 32482, 30256],
    75801: [29746, 29851, 27631, 27550, 32596, 32596, 30376],
    76001: [29866, 29974, 27760, 27655, 32710, 32710, 30496],
    76201: [29986, 30093, 27885, 27759, 32824, 32824, 30616],
    76401: [30106, 30212, 28010, 27864, 32938, 32938, 30736],
    76601: [30226, 30335, 28139, 27969, 33052, 33052, 30856],
    76801: [30346, 30454, 28264, 28074, 33166, 33166, 30976],
    77001: [30466, 30578, 28394, 28179, 33280, 33280, 31096],
    77201: [30586, 30696, 28518, 28284, 33394, 33394, 31216],
    77401: [30706, 30815, 28643, 28388, 33508, 33508, 31336],
    77601: [30826, 30939, 28773, 28493, 33622, 33622, 31456],
    77801: [30946, 31057, 28897, 28598, 33736, 33736, 31576],
    78001: [31066, 31176, 29022, 28703, 33850, 33850, 31696],
    78201: [31186, 31300, 29152, 28808, 33964, 33964, 31816],
    78401: [31306, 31418, 29276, 28912, 34078, 34078, 31936],
    78601: [31426, 31542, 29406, 29017, 34192, 34192, 32056],
    78801: [31546, 31661, 29531, 29122, 34306, 34306, 32176],
    79001: [31666, 31779, 29655, 29227, 34420, 34420, 32296],
    79201: [31786, 31903, 29785, 29332, 34534, 34534, 32416],
    79401: [31906, 32022, 29910, 29437, 34648, 34648, 32536],
    79601: [32026, 32140, 30034, 29541, 34762, 34762, 32656],
    79801: [32146, 32264, 30164, 29646, 34876, 34876, 32776],
    80001: [40, 40, 38, 37, 44, 44, 41, '%'],
    80601: [40, 41, 38, 37, 44, 44, 41, '%'],
    81201: [41, 41, 38, 37, 44, 44, 41, '%'],
    82201: [41, 41, 38, 38, 44, 44, 42, '%'],
    82401: [41, 41, 39, 38, 44, 44, 42, '%'],
    84801: [41, 42, 39, 38, 44, 44, 42, '%'],
    85601: [42, 42, 39, 38, 45, 45, 42, '%'],
    86001: [42, 42, 40, 38, 45, 45, 42, '%'],
    86801: [42, 42, 40, 38, 45, 45, 43, '%'],
    88201: [42, 42, 40, 39, 45, 45, 43, '%'],
    89601: [42, 43, 40, 39, 45, 45, 43, '%'],
    89801: [42, 43, 41, 39, 45, 45, 43, '%'],
    90401: [43, 43, 41, 39, 45, 45, 43, '%'],
    92201: [43, 43, 41, 39, 45, 45, 44, '%'],
    93201: [43, 43, 41, 39, 46, 46, 44, '%'],
    93801: [43, 43, 42, 39, 46, 46, 44, '%'],
    95001: [43, 44, 42, 40, 46, 46, 44, '%'],
    96001: [44, 44, 42, 40, 46, 46, 44, '%'],
    98201: [44, 44, 42, 40, 46, 46, 45, '%'],
    98401: [44, 44, 43, 40, 46, 46, 45, '%'],
    101001: [44, 45, 43, 40, 46, 46, 45, '%'],
    102001: [44, 45, 43, 40, 47, 47, 45, '%'],
    102201: [45, 45, 43, 40, 47, 47, 45, '%'],
    103001: [45, 45, 43, 41, 47, 47, 45, '%'],
    103401: [45, 45, 44, 41, 47, 47, 45, '%'],
    104801: [45, 45, 44, 41, 47, 47, 46, '%'],
    107801: [45, 46, 44, 41, 47, 47, 46, '%'],
    108801: [45, 46, 45, 41, 47, 47, 46, '%'],
    109201: [46, 46, 45, 41, 47, 47, 46, '%'],
    112401: [46, 46, 45, 42, 47, 47, 46, '%'],
    112601: [46, 46, 45, 42, 47, 47, 47, '%'],
    112801: [46, 46, 45, 42, 48, 48, 47, '%'],
    115001: [46, 46, 46, 42, 48, 48, 47, '%'],
    115801: [46, 47, 46, 42, 48, 48, 47, '%'],
    117401: [47, 47, 46, 42, 48, 48, 47, '%'],
    121601: [47, 47, 46, 42, 48, 48, 48, '%'],
    121801: [47, 47, 47, 42, 48, 48, 48, '%'],
    123801: [47, 47, 47, 43, 48, 48, 48, '%'],
    124801: [47, 48, 47, 43, 48, 48, 48, '%'],
    126001: [47, 48, 47, 43, 49, 49, 48, '%'],
    126801: [48, 48, 47, 43, 49, 49, 48, '%'],
    129601: [48, 48, 48, 43, 49, 49, 48, '%'],
    132201: [48, 48, 48, 43, 49, 49, 49, '%'],
    135401: [48, 49, 48, 43, 49, 49, 49, '%'],
    137801: [49, 49, 48, 44, 49, 49, 49, '%'],
    138401: [49, 49, 49, 44, 49, 49, 49, '%'],
    142801: [49, 49, 49, 44, 50, 50, 49, '%'],
    144801: [49, 49, 49, 44, 50, 50, 50, '%'],
    148001: [49, 50, 49, 44, 50, 50, 50, '%'],
    148401: [49, 50, 50, 44, 50, 50, 50, '%'],
    150801: [50, 50, 50, 44, 50, 50, 50, '%'],
    155201: [50, 50, 50, 45, 50, 50, 50, '%'],
    164801: [50, 51, 51, 45, 51, 51, 51, '%'],
    166801: [51, 51, 51, 45, 51, 51, 51, '%'],
    177601: [51, 51, 51, 46, 51, 51, 51, '%'],
    194801: [52, 52, 52, 46, 52, 52, 52, '%'],
    207601: [52, 52, 52, 47, 52, 52, 52, '%'],
    238201: [53, 53, 53, 47, 53, 53, 53, '%'],
    250001: [53, 53, 53, 48, 53, 53, 53, '%'],
    306201: [54, 54, 54, 48, 54, 54, 54, '%'],
    313801: [54, 54, 54, 49, 54, 54, 54, '%'],
    421601: [54, 54, 54, 50, 54, 54, 54, '%'],
    428801: [55, 55, 55, 50, 55, 55, 55, '%'],
    642201: [55, 55, 55, 51, 55, 55, 55, '%'],
    714801: [56, 56, 56, 51, 56, 56, 56, '%'],
    1346001: [56, 56, 56, 52, 56, 56, 56, '%']
  },
  38: {
    1: [0, 0, 0, 0, 0, 0, 0],
    1801: [134, 0, 133, 0, 134, 16, 134],
    1901: [144, 0, 141, 3, 144, 55, 144],
    2001: [155, 0, 150, 5, 155, 94, 155],
    2101: [157, 0, 150, 7, 157, 133, 157],
    2201: [167, 0, 158, 9, 172, 172, 167],
    2301: [178, 0, 166, 11, 211, 211, 178],
    2401: [188, 0, 175, 13, 250, 250, 188],
    2501: [199, 0, 183, 16, 289, 289, 199],
    2601: [209, 0, 191, 18, 328, 328, 209],
    2701: [220, 0, 200, 20, 367, 367, 220],
    2801: [222, 0, 200, 22, 406, 406, 222],
    2901: [232, 0, 208, 24, 445, 445, 232],
    3001: [243, 0, 216, 26, 484, 484, 243],
    3101: [253, 0, 225, 28, 523, 523, 253],
    3201: [264, 0, 233, 31, 562, 562, 264],
    3301: [274, 0, 241, 33, 601, 601, 274],
    3401: [277, 0, 241, 35, 640, 640, 277],
    3501: [287, 0, 250, 37, 679, 679, 287],
    3601: [298, 0, 258, 39, 718, 718, 298],
    3701: [308, 0, 266, 41, 757, 757, 308],
    3801: [319, 0, 275, 44, 796, 796, 319],
    3901: [329, 0, 283, 50, 835, 835, 329],
    4001: [331, 0, 283, 77, 874, 874, 331],
    4101: [342, 0, 291, 103, 913, 913, 342],
    4201: [352, 0, 300, 130, 952, 952, 352],
    4301: [363, 0, 308, 156, 987, 987, 363],
    4401: [373, 0, 316, 182, 1017, 1017, 373],
    4501: [383, 0, 325, 208, 1049, 1049, 383],
    4601: [384, 0, 325, 234, 1082, 1082, 384],
    4701: [394, 0, 333, 260, 1111, 1111, 394],
    4801: [405, 9, 342, 286, 1143, 1143, 405],
    4901: [414, 38, 352, 312, 1173, 1173, 414],
    5001: [425, 68, 362, 338, 1205, 1205, 425],
    5101: [435, 97, 372, 364, 1238, 1238, 435],
    5201: [445, 126, 382, 390, 1267, 1267, 445],
    5301: [447, 155, 383, 416, 1300, 1300, 447],
    5401: [456, 185, 393, 442, 1328, 1328, 456],
    5501: [467, 214, 403, 467, 1360, 1360, 467],
    5601: [477, 243, 413, 493, 1392, 1392, 477],
    5701: [487, 272, 423, 518, 1421, 1421, 487],
    5801: [510, 298, 433, 543, 1452, 1452, 497],
    5901: [534, 328, 434, 569, 1481, 1481, 498],
    6001: [558, 357, 444, 594, 1513, 1513, 509],
    6101: [581, 386, 454, 620, 1545, 1545, 519],
    6201: [605, 415, 464, 645, 1574, 1574, 529],
    6301: [629, 445, 474, 670, 1605, 1605, 539],
    6401: [652, 474, 484, 696, 1634, 1634, 549],
    6501: [676, 503, 486, 721, 1666, 1666, 551],
    6601: [700, 532, 496, 747, 1698, 1698, 561],
    6701: [723, 562, 506, 772, 1727, 1727, 571],
    6801: [747, 591, 516, 798, 1758, 1758, 581],
    6901: [771, 617, 525, 823, 1787, 1787, 591],
    7001: [795, 646, 535, 848, 1819, 1819, 601],
    7101: [818, 675, 537, 874, 1851, 1851, 603],
    7201: [842, 705, 547, 899, 1880, 1880, 613],
    7301: [866, 734, 557, 925, 1911, 1911, 623],
    7401: [889, 763, 567, 950, 1940, 1940, 633],
    7501: [913, 792, 577, 976, 1972, 1972, 643],
    7601: [937, 822, 587, 1001, 2004, 2004, 653],
    7701: [960, 851, 597, 1026, 2033, 2033, 663],
    7801: [984, 880, 598, 1052, 2064, 2064, 665],
    7901: [1008, 909, 608, 1077, 2093, 2093, 675],
    8001: [1031, 939, 618, 1103, 2125, 2125, 685],
    8101: [1055, 965, 628, 1128, 2157, 2157, 695],
    8201: [1079, 994, 638, 1153, 2186, 2186, 705],
    8301: [1103, 1023, 648, 1179, 2217, 2217, 715],
    8401: [1126, 1052, 649, 1204, 2246, 2246, 717],
    8501: [1150, 1075, 659, 1230, 2278, 2278, 727],
    8601: [1174, 1098, 669, 1255, 2310, 2310, 737],
    8701: [1197, 1124, 678, 1281, 2339, 2339, 747],
    8801: [1221, 1147, 688, 1306, 2370, 2370, 757],
    8901: [1245, 1169, 698, 1331, 2399, 2399, 767],
    9001: [1268, 1192, 699, 1357, 2431, 2431, 769],
    9101: [1292, 1218, 709, 1382, 2463, 2463, 779],
    9201: [1316, 1241, 718, 1408, 2492, 2492, 789],
    9301: [1340, 1264, 728, 1433, 2523, 2523, 799],
    9401: [1363, 1286, 737, 1459, 2552, 2552, 809],
    9501: [1387, 1312, 747, 1484, 2584, 2584, 819],
    9601: [1411, 1335, 748, 1510, 2616, 2616, 821],
    9701: [1434, 1357, 758, 1535, 2645, 2645, 831],
    9801: [1458, 1379, 768, 1560, 2676, 2676, 841],
    9901: [1482, 1405, 777, 1586, 2705, 2705, 851],
    10001: [1505, 1427, 787, 1611, 2737, 2737, 861],
    10101: [1529, 1449, 797, 1637, 2769, 2769, 871],
    10201: [1553, 1472, 806, 1662, 2798, 2798, 881],
    10301: [1576, 1497, 808, 1687, 2829, 2829, 883],
    10401: [1600, 1519, 817, 1713, 2858, 2858, 893],
    10501: [1624, 1542, 827, 1738, 2890, 2890, 903],
    10601: [1648, 1564, 836, 1764, 2922, 2922, 923],
    10701: [1671, 1590, 846, 1789, 2951, 2951, 945],
    10801: [1695, 1612, 856, 1815, 2982, 2982, 969],
    10901: [1719, 1634, 857, 1840, 3011, 3011, 991],
    11001: [1742, 1660, 867, 1865, 3043, 3043, 1016],
    11101: [1766, 1682, 876, 1891, 3075, 3075, 1041],
    11201: [1790, 1704, 886, 1916, 3104, 3104, 1063],
    11301: [1813, 1727, 896, 1942, 3135, 3135, 1087],
    11401: [1837, 1752, 905, 1967, 3164, 3164, 1109],
    11501: [1861, 1774, 907, 1992, 3196, 3196, 1134],
    11601: [1885, 1797, 916, 2018, 3228, 3228, 1159],
    11701: [1908, 1819, 926, 2043, 3257, 3257, 1181],
    11801: [1932, 1845, 936, 2069, 3288, 3288, 1205],
    11901: [1956, 1867, 945, 2094, 3324, 3324, 1234],
    12001: [1980, 1889, 955, 2120, 3362, 3362, 1265],
    12101: [2004, 1911, 956, 2146, 3400, 3400, 1296],
    12201: [2028, 1937, 966, 2171, 3438, 3438, 1327],
    12301: [2052, 1959, 975, 2197, 3477, 3477, 1359],
    12401: [2076, 1982, 985, 2223, 3515, 3515, 1390],
    12501: [2100, 2004, 995, 2249, 3553, 3553, 1421],
    12601: [2124, 2029, 1004, 2274, 3591, 3591, 1452],
    12701: [2148, 2052, 1014, 2300, 3630, 3630, 1484],
    12801: [2172, 2074, 1015, 2326, 3668, 3668, 1515],
    12901: [2196, 2100, 1025, 2351, 3706, 3706, 1546],
    13001: [2220, 2122, 1035, 2377, 3744, 3744, 1577],
    13101: [2244, 2144, 1044, 2403, 3783, 3783, 1609],
    13201: [2268, 2166, 1054, 2429, 3821, 3821, 1640],
    13301: [2292, 2192, 1064, 2454, 3859, 3859, 1671],
    13401: [2315, 2214, 1065, 2480, 3897, 3897, 1702],
    13501: [2339, 2237, 1074, 2506, 3936, 3936, 1734],
    13601: [2364, 2259, 1084, 2531, 3977, 3977, 1768],
    13701: [2388, 2284, 1094, 2557, 4022, 4022, 1806],
    13801: [2412, 2307, 1103, 2583, 4063, 4063, 1840],
    13901: [2436, 2329, 1113, 2609, 4104, 4104, 1874],
    14001: [2460, 2351, 1114, 2635, 4146, 4146, 1909],
    14101: [2487, 2380, 1124, 2663, 4187, 4187, 1943],
    14201: [2520, 2412, 1134, 2695, 4230, 4230, 1979],
    14301: [2552, 2447, 1145, 2728, 4271, 4271, 2013],
    14401: [2585, 2479, 1155, 2761, 4311, 4311, 2046],
    14501: [2617, 2511, 1165, 2793, 4351, 4351, 2079],
    14601: [2650, 2546, 1167, 2826, 4392, 4392, 2113],
    14701: [2683, 2578, 1177, 2858, 4435, 4435, 2149],
    14801: [2715, 2613, 1187, 2891, 4476, 4476, 2183],
    14901: [2748, 2645, 1197, 2923, 4516, 4516, 2216],
    15001: [2780, 2676, 1208, 2956, 4556, 4556, 2249],
    15101: [2813, 2712, 1218, 2989, 4597, 4597, 2283],
    15201: [2845, 2743, 1228, 3021, 4640, 4640, 2319],
    15301: [2878, 2778, 1230, 3054, 4680, 4680, 2352],
    15401: [2911, 2810, 1240, 3086, 4721, 4721, 2386],
    15501: [2943, 2842, 1250, 3119, 4761, 4761, 2419],
    15601: [2976, 2877, 1261, 3151, 4801, 4801, 2452],
    15701: [3008, 2909, 1271, 3184, 4845, 4845, 2489],
    15801: [3041, 2944, 1281, 3217, 4885, 4885, 2522],
    15901: [3073, 2976, 1283, 3249, 4926, 4926, 2556],
    16001: [3106, 3008, 1293, 3282, 4966, 4966, 2589],
    16101: [3139, 3043, 1303, 3314, 5006, 5006, 2622],
    16201: [3171, 3075, 1313, 3347, 5050, 5050, 2659],
    16301: [3204, 3110, 1324, 3379, 5090, 5090, 2692],
    16401: [3236, 3142, 1334, 3412, 5131, 5131, 2726],
    16501: [3269, 3174, 1336, 3445, 5171, 5171, 2759],
    16601: [3301, 3209, 1346, 3477, 5211, 5211, 2792],
    16701: [3334, 3241, 1356, 3510, 5255, 5255, 2829],
    16801: [3367, 3276, 1366, 3542, 5295, 5295, 2862],
    16901: [3399, 3308, 1377, 3575, 5335, 5335, 2895],
    17001: [3432, 3339, 1387, 3608, 5376, 5376, 2929],
    17101: [3464, 3375, 1389, 3640, 5416, 5416, 2962],
    17201: [3497, 3406, 1399, 3673, 5460, 5460, 2999],
    17301: [3530, 3441, 1409, 3705, 5500, 5500, 3032],
    17401: [3562, 3473, 1419, 3738, 5540, 5540, 3065],
    17501: [3595, 3505, 1429, 3770, 5581, 5581, 3099],
    17601: [3627, 3540, 1440, 3803, 5621, 5621, 3132],
    17701: [3660, 3572, 1450, 3836, 5664, 5664, 3168],
    17801: [3692, 3607, 1452, 3868, 5705, 5705, 3202],
    17901: [3725, 3639, 1462, 3901, 5745, 5745, 3235],
    18001: [3758, 3671, 1472, 3933, 5786, 5786, 3269],
    18101: [3790, 3706, 1482, 3966, 5826, 5826, 3302],
    18201: [3823, 3738, 1493, 3998, 5869, 5869, 3338],
    18301: [3855, 3773, 1503, 4031, 5910, 5910, 3372],
    18401: [3888, 3805, 1505, 4064, 5950, 5950, 3405],
    18501: [3920, 3837, 1515, 4096, 5990, 5990, 3438],
    18601: [3953, 3872, 1525, 4129, 6031, 6031, 3472],
    18701: [3986, 3904, 1535, 4161, 6074, 6074, 3508],
    18801: [4018, 3939, 1546, 4194, 6115, 6115, 3542],
    18901: [4051, 3971, 1556, 4226, 6155, 6155, 3575],
    19001: [4083, 4006, 1558, 4259, 6195, 6195, 3608],
    19101: [4116, 4038, 1568, 4292, 6236, 6236, 3642],
    19201: [4148, 4069, 1578, 4324, 6279, 6279, 3678],
    19301: [4181, 4104, 1588, 4357, 6319, 6319, 3711],
    19401: [4214, 4136, 1598, 4389, 6360, 6360, 3745],
    19501: [4246, 4171, 1609, 4422, 6400, 6400, 3778],
    19601: [4279, 4202, 1610, 4454, 6440, 6440, 3811],
    19701: [4311, 4233, 1619, 4487, 6484, 6484, 3848],
    19801: [4344, 4268, 1628, 4520, 6524, 6524, 3881],
    19901: [4376, 4299, 1649, 4552, 6565, 6565, 3915],
    20001: [4442, 4364, 1700, 4617, 6645, 6645, 3981],
    20201: [4507, 4429, 1751, 4683, 6729, 6729, 4051],
    20401: [4572, 4494, 1802, 4748, 6810, 6810, 4118],
    20601: [4637, 4556, 1850, 4813, 6894, 6894, 4188],
    20801: [4702, 4621, 1901, 4878, 6974, 6974, 4254],
    21001: [4767, 4687, 1953, 4943, 7055, 7055, 4321],
    21201: [4833, 4752, 2004, 5008, 7139, 7139, 4391],
    21401: [4898, 4817, 2055, 5073, 7220, 7220, 4458],
    21601: [4963, 4879, 2103, 5139, 7303, 7303, 4527],
    21801: [5028, 4944, 2154, 5204, 7384, 7384, 4594],
    22001: [5093, 5009, 2205, 5269, 7465, 7465, 4661],
    22201: [5158, 5075, 2257, 5334, 7549, 7549, 4731],
    22401: [5224, 5140, 2308, 5400, 7630, 7630, 4798],
    22601: [5291, 5202, 2356, 5467, 7716, 7716, 4870],
    22801: [5358, 5267, 2407, 5534, 7798, 7798, 4938],
    23001: [5425, 5332, 2458, 5600, 7880, 7880, 5006],
    23201: [5492, 5397, 2509, 5667, 7966, 7966, 5078],
    23401: [5558, 5463, 2561, 5734, 8048, 8048, 5146],
    23601: [5625, 5528, 2612, 5801, 8134, 8134, 5218],
    23801: [5692, 5590, 2660, 5868, 8216, 8216, 5286],
    24001: [5759, 5655, 2711, 5934, 8298, 8298, 5354],
    24201: [5826, 5723, 2765, 6001, 8384, 8384, 5426],
    24401: [5892, 5789, 2817, 6068, 8466, 8466, 5494],
    24601: [5959, 5854, 2868, 6135, 8552, 8552, 5566],
    24801: [6026, 5919, 2919, 6202, 8634, 8634, 5634],
    25001: [6093, 5987, 2987, 6268, 8716, 8716, 5716],
    25201: [6160, 6052, 3052, 6335, 8802, 8802, 5802],
    25401: [6226, 6118, 3118, 6402, 8884, 8884, 5884],
    25601: [6293, 6186, 3186, 6469, 8970, 8970, 5970],
    25801: [6360, 6251, 3251, 6536, 9052, 9052, 6052],
    26001: [6427, 6316, 3316, 6602, 9134, 9134, 6134],
    26201: [6494, 6385, 3385, 6669, 9220, 9220, 6220],
    26401: [6560, 6450, 3450, 6736, 9302, 9302, 6302],
    26601: [6627, 6515, 3515, 6803, 9388, 9388, 6388],
    26801: [6694, 6583, 3583, 6870, 9470, 9470, 6470],
    27001: [6761, 6648, 3648, 6936, 9552, 9552, 6552],
    27201: [6828, 6714, 3714, 7003, 9638, 9638, 6638],
    27401: [6894, 6782, 3782, 7070, 9720, 9720, 6720],
    27601: [6961, 6847, 3847, 7137, 9806, 9806, 6806],
    27801: [7028, 6912, 3912, 7204, 9888, 9888, 6888],
    28001: [7095, 6978, 3978, 7270, 9970, 9970, 6970],
    28201: [7162, 7046, 4046, 7337, 10056, 10056, 7056],
    28401: [7228, 7111, 4111, 7404, 10138, 10138, 7138],
    28601: [7295, 7176, 4176, 7471, 10224, 10224, 7224],
    28801: [7362, 7244, 4244, 7538, 10306, 10306, 7306],
    29001: [7429, 7310, 4310, 7604, 10388, 10388, 7388],
    29201: [7496, 7375, 4375, 7671, 10474, 10474, 7474],
    29401: [7562, 7443, 4443, 7738, 10556, 10556, 7556],
    29601: [7629, 7508, 4508, 7805, 10642, 10642, 7642],
    29801: [7696, 7574, 4574, 7872, 10724, 10724, 7724],
    30001: [7763, 7643, 4643, 7938, 10806, 10806, 7806],
    30201: [7830, 7709, 4709, 8005, 10892, 10892, 7892],
    30401: [7896, 7776, 4776, 8072, 10974, 10974, 7974],
    30601: [7963, 7842, 4842, 8139, 11060, 11060, 8060],
    30801: [8030, 7912, 4912, 8206, 11142, 11142, 8142],
    31001: [8097, 7979, 4979, 8273, 11225, 11225, 8225],
    31201: [8164, 8045, 5045, 8339, 11310, 11310, 8310],
    31401: [8230, 8115, 5115, 8406, 11392, 11392, 8392],
    31601: [8297, 8181, 5181, 8473, 11478, 11478, 8478],
    31801: [8364, 8248, 5248, 8540, 11560, 11560, 8560],
    32001: [8431, 8317, 5317, 8606, 11642, 11642, 8642],
    32201: [8498, 8384, 5384, 8673, 11728, 11728, 8728],
    32401: [8564, 8450, 5450, 8740, 11810, 11810, 8810],
    32601: [8631, 8520, 5520, 8807, 11896, 11896, 8896],
    32801: [8698, 8587, 5587, 8874, 11978, 11978, 8978],
    33001: [8765, 8653, 5653, 8940, 12060, 12060, 9060],
    33201: [8832, 8723, 5723, 9007, 12146, 12146, 9146],
    33401: [8898, 8789, 5789, 9074, 12228, 12228, 9228],
    33601: [8965, 8856, 5856, 9141, 12314, 12314, 9314],
    33801: [9032, 8922, 5922, 9208, 12396, 12396, 9396],
    34001: [9099, 8992, 5992, 9274, 12478, 12478, 9478],
    34201: [9166, 9058, 6058, 9341, 12564, 12564, 9564],
    34401: [9232, 9125, 6125, 9408, 12643, 12643, 9643],
    34601: [9299, 9191, 6191, 9475, 12719, 12719, 9719],
    34801: [9365, 9255, 6255, 9541, 12795, 12795, 9795],
    35001: [9432, 9321, 6321, 9608, 12871, 12871, 9871],
    35201: [9500, 9391, 6391, 9674, 12947, 12947, 9947],
    35401: [9576, 9467, 6467, 9741, 13023, 13023, 10023],
    35601: [9652, 9543, 6543, 9807, 13099, 13099, 10099],
    35801: [9728, 9619, 6619, 9874, 13175, 13175, 10175],
    36001: [9804, 9695, 6695, 9941, 13251, 13251, 10251],
    36201: [9880, 9771, 6771, 10007, 13327, 13327, 10327],
    36401: [9956, 9847, 6847, 10074, 13403, 13403, 10403],
    36601: [10032, 9923, 6923, 10140, 13479, 13479, 10479],
    36801: [10108, 9999, 6999, 10207, 13555, 13555, 10555],
    37001: [10184, 10075, 7075, 10273, 13631, 13631, 10631],
    37201: [10260, 10151, 7151, 10340, 13707, 13707, 10707],
    37401: [10336, 10227, 7227, 10407, 13783, 13783, 10783],
    37601: [10412, 10303, 7303, 10473, 13859, 13859, 10859],
    37801: [10488, 10379, 7379, 10540, 13935, 13935, 10935],
    38001: [10564, 10455, 7455, 10606, 14011, 14011, 11011],
    38201: [10640, 10531, 7531, 10673, 14087, 14087, 11087],
    38401: [10716, 10607, 7607, 10739, 14163, 14163, 11163],
    38601: [10792, 10683, 7683, 10806, 14239, 14239, 11239],
    38801: [10868, 10759, 7759, 10873, 14315, 14315, 11315],
    39001: [10944, 10835, 7835, 10939, 14391, 14391, 11391],
    39201: [11020, 10911, 7911, 11006, 14467, 14467, 11467],
    39401: [11096, 10987, 7987, 11072, 14543, 14543, 11543],
    39601: [11172, 11063, 8063, 11139, 14619, 14619, 11619],
    39801: [11248, 11139, 8139, 11205, 14695, 14695, 11695],
    40001: [11324, 11215, 8215, 11272, 14771, 14771, 11771],
    40201: [11400, 11291, 8291, 11338, 14847, 14847, 11847],
    40401: [11476, 11367, 8367, 11405, 14923, 14923, 11923],
    40601: [11552, 11443, 8443, 11472, 14999, 14999, 11999],
    40801: [11628, 11519, 8519, 11538, 15075, 15075, 12075],
    41001: [11704, 11595, 8595, 11605, 15151, 15151, 12151],
    41201: [11780, 11671, 8671, 11671, 15227, 15227, 12227],
    41401: [11856, 11747, 8747, 11738, 15303, 15303, 12303],
    41601: [11932, 11823, 8823, 11804, 15379, 15379, 12379],
    41801: [12008, 11899, 8899, 11871, 15455, 15455, 12455],
    42001: [12084, 11975, 8975, 11938, 15531, 15531, 12531],
    42201: [12160, 12051, 9051, 12004, 15607, 15607, 12607],
    42401: [12236, 12127, 9127, 12071, 15683, 15683, 12683],
    42601: [12312, 12203, 9203, 12137, 15759, 15759, 12759],
    42801: [12388, 12279, 9279, 12204, 15835, 15835, 12835],
    43001: [12464, 12355, 9355, 12270, 15911, 15911, 12911],
    43201: [12540, 12431, 9431, 12337, 15987, 15987, 12987],
    43401: [12616, 12507, 9507, 12404, 16063, 16063, 13063],
    43601: [12692, 12583, 9583, 12470, 16139, 16139, 13139],
    43801: [12768, 12659, 9659, 12537, 16215, 16215, 13215],
    44001: [12844, 12735, 9735, 12603, 16291, 16291, 13291],
    44201: [12920, 12811, 9811, 12670, 16367, 16367, 13367],
    44401: [12996, 12887, 9887, 12736, 16443, 16443, 13443],
    44601: [13072, 12963, 9963, 12803, 16519, 16519, 13519],
    44801: [13148, 13039, 10039, 12870, 16595, 16595, 13595],
    45001: [13224, 13115, 10115, 12936, 16671, 16671, 13671],
    45201: [13300, 13191, 10191, 13003, 16747, 16747, 13747],
    45401: [13376, 13267, 10267, 13069, 16823, 16823, 13823],
    45601: [13452, 13343, 10343, 13136, 16899, 16899, 13899],
    45801: [13528, 13419, 10419, 13202, 16975, 16975, 13975],
    46001: [13604, 13495, 10495, 13269, 17051, 17051, 14051],
    46201: [13680, 13571, 10571, 13336, 17127, 17127, 14127],
    46401: [13756, 13647, 10647, 13402, 17203, 17203, 14203],
    46601: [13832, 13723, 10723, 13469, 17279, 17279, 14279],
    46801: [13908, 13799, 10799, 13535, 17355, 17355, 14355],
    47001: [13984, 13875, 10875, 13602, 17431, 17431, 14431],
    47201: [14060, 13951, 10951, 13668, 17507, 17507, 14507],
    47401: [14136, 14027, 11027, 13735, 17583, 17583, 14583],
    47601: [14212, 14103, 11103, 13802, 17659, 17659, 14659],
    47801: [14288, 14179, 11179, 13868, 17735, 17735, 14735],
    48001: [14364, 14255, 11255, 13935, 17811, 17811, 14811],
    48201: [14440, 14331, 11331, 14001, 17887, 17887, 14887],
    48401: [14516, 14407, 11407, 14068, 17963, 17963, 14963],
    48601: [14592, 14483, 11483, 14134, 18039, 18039, 15039],
    48801: [14668, 14559, 11559, 14201, 18115, 18115, 15115],
    49001: [14744, 14635, 11635, 14268, 18191, 18191, 15191],
    49201: [14820, 14711, 11711, 14334, 18267, 18267, 15267],
    49401: [14896, 14787, 11787, 14401, 18343, 18343, 15343],
    49601: [14972, 14863, 11863, 14467, 18419, 18419, 15419],
    49801: [15048, 14939, 11939, 14534, 18495, 18495, 15495],
    50001: [15124, 15015, 12021, 14600, 18571, 18571, 15577],
    50201: [15200, 15132, 12144, 14667, 18647, 18647, 15659],
    50401: [15276, 15252, 12270, 14734, 18723, 18723, 15741],
    50601: [15352, 15376, 12400, 14800, 18799, 18799, 15823],
    50801: [15428, 15500, 12530, 14867, 18875, 18875, 15905],
    51001: [15513, 15623, 12659, 14942, 18959, 18959, 15995],
    51201: [15629, 15746, 12788, 15048, 19075, 19075, 16117],
    51401: [15745, 15870, 12918, 15155, 19191, 19191, 16239],
    51601: [15861, 15993, 13047, 15261, 19307, 19307, 16361],
    51801: [15977, 16117, 13177, 15368, 19423, 19423, 16483],
    52001: [16093, 16237, 13303, 15474, 19539, 19539, 16605],
    52201: [16209, 16361, 13433, 15581, 19655, 19655, 16727],
    52401: [16325, 16484, 13562, 15688, 19771, 19771, 16849],
    52601: [16441, 16608, 13692, 15794, 19887, 19887, 16971],
    52801: [16557, 16731, 13821, 15901, 20003, 20003, 17093],
    53001: [16673, 16855, 13951, 16007, 20119, 20119, 17215],
    53201: [16789, 16978, 14080, 16114, 20235, 20235, 17337],
    53401: [16905, 17102, 14210, 16220, 20351, 20351, 17459],
    53601: [17021, 17222, 14336, 16327, 20467, 20467, 17581],
    53801: [17137, 17346, 14466, 16434, 20583, 20583, 17703],
    54001: [17253, 17469, 14595, 16540, 20700, 20700, 17826],
    54201: [17369, 17593, 14725, 16647, 20815, 20815, 17947],
    54401: [17485, 17716, 14854, 16753, 20931, 20931, 18069],
    54601: [17601, 17840, 14984, 16860, 21047, 21047, 18191],
    54801: [17717, 17963, 15113, 16966, 21163, 21163, 18313],
    55001: [17833, 18087, 15243, 17073, 21279, 21279, 18435],
    55201: [17949, 18210, 15372, 17179, 21395, 21395, 18557],
    55401: [18065, 18330, 15498, 17286, 21511, 21511, 18679],
    55601: [18181, 18454, 15628, 17393, 21627, 21627, 18801],
    55801: [18297, 18577, 15757, 17499, 21743, 21743, 18923],
    56001: [18413, 18657, 15843, 17606, 21859, 21859, 19045],
    56201: [18529, 18733, 15925, 17712, 21975, 21975, 19167],
    56401: [18645, 18809, 16007, 17819, 22091, 22091, 19289],
    56601: [18761, 18885, 16089, 17925, 22207, 22207, 19411],
    56801: [18877, 18974, 16184, 18032, 22323, 22323, 19533],
    57001: [18993, 19090, 16306, 18139, 22439, 22439, 19655],
    57201: [19109, 19206, 16428, 18245, 22555, 22555, 19777],
    57401: [19225, 19322, 16550, 18352, 22671, 22671, 19899],
    57601: [19341, 19438, 16672, 18458, 22787, 22787, 20021],
    57801: [19457, 19554, 16794, 18565, 22903, 22903, 20143],
    58001: [19573, 19670, 16916, 18671, 23019, 23019, 20265],
    58201: [19689, 19786, 17038, 18778, 23135, 23135, 20387],
    58401: [19805, 19902, 17160, 18885, 23251, 23251, 20509],
    58601: [19921, 20018, 17282, 18991, 23367, 23367, 20631],
    58801: [20037, 20134, 17404, 19098, 23483, 23483, 20753],
    59001: [20153, 20250, 17526, 19204, 23600, 23600, 20876],
    59201: [20273, 20371, 17653, 19311, 23715, 23715, 20997],
    59401: [20395, 20492, 17780, 19417, 23831, 23831, 21119],
    59601: [20517, 20617, 17911, 19524, 23947, 23947, 21241],
    59801: [20639, 20738, 18038, 19631, 24063, 24063, 21363],
    60001: [20761, 20859, 18165, 19737, 24179, 24179, 21485],
    60201: [20883, 20985, 18297, 19844, 24295, 24295, 21607],
    60401: [21005, 21105, 18423, 19950, 24411, 24411, 21729],
    60601: [21127, 21231, 18555, 20057, 24527, 24527, 21851],
    60801: [21249, 21352, 18682, 20163, 24643, 24643, 21973],
    61001: [21371, 21473, 18809, 20270, 24759, 24759, 22095],
    61201: [21493, 21598, 18940, 20377, 24875, 24875, 22217],
    61401: [21615, 21719, 19067, 20483, 24991, 24991, 22339],
    61601: [21737, 21840, 19194, 20590, 25107, 25107, 22461],
    61801: [21859, 21966, 19326, 20696, 25223, 25223, 22583],
    62001: [21981, 22087, 19453, 20803, 25339, 25339, 22705],
    62201: [22103, 22212, 19584, 20909, 25455, 25455, 22827],
    62401: [22225, 22333, 19711, 21016, 25571, 25571, 22949],
    62601: [22347, 22454, 19838, 21123, 25687, 25687, 23071],
    62801: [22469, 22580, 19970, 21229, 25803, 25803, 23193],
    63001: [22591, 22700, 20096, 21336, 25919, 25919, 23315],
    63201: [22713, 22821, 20223, 21442, 26035, 26035, 23437],
    63401: [22835, 22947, 20355, 21549, 26151, 26151, 23559],
    63601: [22957, 23068, 20482, 21655, 26267, 26267, 23681],
    63801: [23079, 23193, 20613, 21762, 26383, 26383, 23803],
    64001: [23201, 23314, 20740, 21869, 26500, 26500, 23926],
    64201: [23323, 23435, 20867, 21975, 26615, 26615, 24047],
    64401: [23445, 23561, 20999, 22082, 26731, 26731, 24169],
    64601: [23567, 23682, 21126, 22188, 26847, 26847, 24291],
    64801: [23689, 23802, 21252, 22295, 26963, 26963, 24413],
    65001: [23811, 23928, 21384, 22401, 27079, 27079, 24535],
    65201: [23933, 24049, 21511, 22508, 27195, 27195, 24657],
    65401: [24055, 24175, 21643, 22615, 27311, 27311, 24779],
    65601: [24177, 24295, 21769, 22721, 27427, 27427, 24901],
    65801: [24299, 24416, 21896, 22828, 27543, 27543, 25023],
    66001: [24421, 24542, 22028, 22934, 27659, 27659, 25145],
    66201: [24543, 24663, 22155, 23041, 27775, 27775, 25267],
    66401: [24665, 24784, 22282, 23147, 27891, 27891, 25389],
    66601: [24787, 24909, 22413, 23254, 28007, 28007, 25511],
    66801: [24909, 25030, 22540, 23360, 28123, 28123, 25633],
    67001: [25031, 25156, 22672, 23467, 28239, 28239, 25755],
    67201: [25153, 25277, 22799, 23574, 28355, 28355, 25877],
    67401: [25275, 25397, 22925, 23680, 28471, 28471, 25999],
    67601: [25397, 25523, 23057, 23787, 28587, 28587, 26121],
    67801: [25519, 25644, 23184, 23893, 28703, 28703, 26243],
    68001: [25641, 25770, 23316, 24000, 28819, 28819, 26365],
    68201: [25763, 25890, 23442, 24106, 28935, 28935, 26487],
    68401: [25885, 26011, 23569, 24213, 29051, 29051, 26609],
    68601: [26007, 26137, 23701, 24320, 29167, 29167, 26731],
    68801: [26129, 26258, 23828, 24426, 29283, 29283, 26853],
    69001: [26251, 26379, 23955, 24533, 29400, 29400, 26976],
    69201: [26373, 26504, 24086, 24639, 29515, 29515, 27097],
    69401: [26495, 26625, 24213, 24746, 29631, 29631, 27219],
    69601: [26617, 26751, 24345, 24852, 29747, 29747, 27341],
    69801: [26739, 26872, 24472, 24959, 29863, 29863, 27463],
    70001: [26861, 26992, 24598, 25066, 29979, 29979, 27585],
    70201: [26983, 27118, 24730, 25172, 30095, 30095, 27707],
    70401: [27105, 27239, 24857, 25279, 30211, 30211, 27829],
    70601: [27227, 27360, 24984, 25385, 30327, 30327, 27951],
    70801: [27349, 27485, 25115, 25492, 30443, 30443, 28073],
    71001: [27471, 27606, 25242, 25598, 30559, 30559, 28195],
    71201: [27593, 27732, 25374, 25705, 30675, 30675, 28317],
    71401: [27715, 27853, 25501, 25812, 30791, 30791, 28439],
    71601: [27837, 27974, 25628, 25918, 30907, 30907, 28561],
    71801: [27959, 28099, 25759, 26025, 31023, 31023, 28683],
    72001: [28081, 28220, 25886, 26131, 31139, 31139, 28805],
    72201: [28203, 28341, 26013, 26238, 31255, 31255, 28927],
    72401: [28325, 28467, 26145, 26344, 31371, 31371, 29049],
    72601: [28447, 28587, 26271, 26451, 31487, 31487, 29171],
    72801: [28569, 28713, 26403, 26558, 31603, 31603, 29293],
    73001: [28691, 28834, 26530, 26664, 31719, 31719, 29415],
    73201: [28813, 28955, 26657, 26771, 31835, 31835, 29537],
    73401: [28935, 29080, 26788, 26877, 31951, 31951, 29659],
    73601: [29057, 29201, 26915, 26984, 32067, 32067, 29781],
    73801: [29179, 29322, 27042, 27090, 32183, 32183, 29903],
    74001: [29301, 29448, 27174, 27197, 32300, 32300, 30026],
    74201: [29423, 29569, 27301, 27304, 32415, 32415, 30147],
    74401: [29545, 29694, 27432, 27410, 32531, 32531, 30269],
    74601: [29667, 29815, 27559, 27517, 32647, 32647, 30391],
    74801: [29789, 29936, 27686, 27623, 32763, 32763, 30513],
    75001: [29911, 30062, 27818, 27730, 32879, 32879, 30635],
    75201: [30033, 30182, 27944, 27836, 32995, 32995, 30757],
    75401: [30155, 30303, 28071, 27943, 33111, 33111, 30879],
    75601: [30277, 30429, 28203, 28050, 33227, 33227, 31001],
    75801: [30399, 30550, 28330, 28156, 33343, 33343, 31123],
    76001: [30521, 30675, 28461, 28263, 33459, 33459, 31245],
    76201: [30643, 30796, 28588, 28369, 33575, 33575, 31367],
    76401: [30765, 30917, 28715, 28476, 33691, 33691, 31489],
    76601: [30887, 31043, 28847, 28582, 33807, 33807, 31611],
    76801: [31009, 31164, 28974, 28689, 33923, 33923, 31733],
    77001: [31131, 31289, 29105, 28796, 34039, 34039, 31855],
    77201: [31253, 31410, 29232, 28902, 34155, 34155, 31977],
    77401: [31375, 31531, 29359, 29009, 34271, 34271, 32099],
    77601: [31497, 31657, 29491, 29115, 34387, 34387, 32221],
    77801: [31619, 31777, 29617, 29222, 34503, 34503, 32343],
    78001: [31741, 31898, 29744, 29328, 34619, 34619, 32465],
    78201: [31863, 32024, 29876, 29435, 34735, 34735, 32587],
    78401: [31985, 32145, 30003, 29541, 34851, 34851, 32709],
    78601: [32107, 32270, 30134, 29648, 34967, 34967, 32831],
    78801: [32229, 32391, 30261, 29755, 35083, 35083, 32953],
    79001: [32351, 32512, 30388, 29861, 35200, 35200, 33076],
    79201: [32473, 32638, 30520, 29968, 35315, 35315, 33197],
    79401: [32595, 32759, 30647, 30074, 35431, 35431, 33319],
    79601: [32717, 32879, 30773, 30181, 35547, 35547, 33441],
    79801: [32839, 33005, 30905, 30287, 35663, 35663, 33563],
    80001: [41, 41, 39, 38, 45, 45, 42, '%'],
    80801: [41, 42, 39, 38, 45, 45, 42, '%'],
    81801: [42, 42, 39, 38, 45, 45, 42, '%'],
    82201: [42, 42, 39, 38, 45, 45, 43, '%'],
    82801: [42, 42, 40, 38, 45, 45, 43, '%'],
    83401: [42, 42, 40, 39, 45, 45, 43, '%'],
    85201: [42, 43, 40, 39, 45, 45, 43, '%'],
    85801: [42, 43, 40, 39, 46, 46, 43, '%'],
    86201: [43, 43, 41, 39, 46, 46, 43, '%'],
    87001: [43, 43, 41, 39, 46, 46, 44, '%'],
    89401: [43, 43, 41, 40, 46, 46, 44, '%'],
    90001: [43, 44, 42, 40, 46, 46, 44, '%'],
    91201: [44, 44, 42, 40, 46, 46, 44, '%'],
    92201: [44, 44, 42, 40, 46, 46, 45, '%'],
    93201: [44, 44, 42, 40, 47, 47, 45, '%'],
    94001: [44, 44, 43, 40, 47, 47, 45, '%'],
    95401: [44, 45, 43, 40, 47, 47, 45, '%'],
    96401: [44, 45, 43, 41, 47, 47, 45, '%'],
    96601: [45, 45, 43, 41, 47, 47, 45, '%'],
    98201: [45, 45, 43, 41, 47, 47, 46, '%'],
    98601: [45, 45, 44, 41, 47, 47, 46, '%'],
    101401: [45, 46, 44, 41, 47, 47, 46, '%'],
    102201: [45, 46, 44, 41, 48, 48, 46, '%'],
    102801: [46, 46, 44, 41, 48, 48, 46, '%'],
    103601: [46, 46, 45, 41, 48, 48, 46, '%'],
    104601: [46, 46, 45, 42, 48, 48, 46, '%'],
    105001: [46, 46, 45, 42, 48, 48, 47, '%'],
    108201: [46, 47, 45, 42, 48, 48, 47, '%'],
    109001: [46, 47, 46, 42, 48, 48, 47, '%'],
    110001: [47, 47, 46, 42, 48, 48, 47, '%'],
    112801: [47, 47, 46, 42, 48, 48, 48, '%'],
    113001: [47, 47, 46, 42, 49, 49, 48, '%'],
    114401: [47, 47, 46, 43, 49, 49, 48, '%'],
    115201: [47, 47, 47, 43, 49, 49, 48, '%'],
    116001: [47, 48, 47, 43, 49, 49, 48, '%'],
    118201: [48, 48, 47, 43, 49, 49, 48, '%'],
    121801: [48, 48, 47, 43, 49, 49, 49, '%'],
    122001: [48, 48, 48, 43, 49, 49, 49, '%'],
    125001: [48, 49, 48, 43, 49, 49, 49, '%'],
    126001: [48, 49, 48, 44, 49, 49, 49, '%'],
    126201: [48, 49, 48, 44, 50, 50, 49, '%'],
    127601: [49, 49, 48, 44, 50, 50, 49, '%'],
    129801: [49, 49, 49, 44, 50, 50, 49, '%'],
    132401: [49, 49, 49, 44, 50, 50, 50, '%'],
    135601: [49, 50, 49, 44, 50, 50, 50, '%'],
    138601: [50, 50, 50, 44, 50, 50, 50, '%'],
    140401: [50, 50, 50, 45, 50, 50, 50, '%'],
    143001: [50, 50, 50, 45, 51, 51, 50, '%'],
    145001: [50, 50, 50, 45, 51, 51, 51, '%'],
    148201: [50, 51, 50, 45, 51, 51, 51, '%'],
    148601: [50, 51, 51, 45, 51, 51, 51, '%'],
    152001: [51, 51, 51, 45, 51, 51, 51, '%'],
    158401: [51, 51, 51, 46, 51, 51, 51, '%'],
    165001: [51, 52, 52, 46, 52, 52, 52, '%'],
    168001: [52, 52, 52, 46, 52, 52, 52, '%'],
    181801: [52, 52, 52, 47, 52, 52, 52, '%'],
    195201: [53, 53, 53, 47, 53, 53, 53, '%'],
    213201: [53, 53, 53, 48, 53, 53, 53, '%'],
    238401: [54, 54, 54, 48, 54, 54, 54, '%'],
    257801: [54, 54, 54, 49, 54, 54, 54, '%'],
    306601: [55, 55, 55, 49, 55, 55, 55, '%'],
    326001: [55, 55, 55, 50, 55, 55, 55, '%'],
    429401: [56, 56, 56, 50, 56, 56, 56, '%'],
    443001: [56, 56, 56, 51, 56, 56, 56, '%'],
    691401: [56, 56, 56, 52, 56, 56, 56, '%'],
    715801: [57, 57, 57, 52, 57, 57, 57, '%'],
    1572801: [57, 57, 57, 53, 57, 57, 57, '%']
  },
  39: {
    1: [0, 0, 0, 0, 0, 0, 0],
    1801: [134, 0, 133, 0, 134, 16, 134],
    1901: [144, 0, 141, 3, 144, 56, 144],
    2001: [155, 0, 150, 5, 155, 96, 155],
    2101: [157, 0, 150, 7, 157, 136, 157],
    2201: [167, 0, 158, 9, 176, 176, 167],
    2301: [178, 0, 166, 11, 216, 216, 178],
    2401: [188, 0, 175, 13, 256, 256, 188],
    2501: [199, 0, 183, 16, 296, 296, 199],
    2601: [209, 0, 191, 18, 336, 336, 209],
    2701: [220, 0, 200, 20, 376, 376, 220],
    2801: [222, 0, 200, 22, 416, 416, 222],
    2901: [232, 0, 208, 24, 456, 456, 232],
    3001: [243, 0, 216, 26, 496, 496, 243],
    3101: [253, 0, 225, 28, 536, 536, 253],
    3201: [264, 0, 233, 31, 576, 576, 264],
    3301: [274, 0, 241, 33, 616, 616, 274],
    3401: [277, 0, 241, 35, 656, 656, 277],
    3501: [287, 0, 250, 37, 696, 696, 287],
    3601: [298, 0, 258, 39, 736, 736, 298],
    3701: [308, 0, 266, 41, 776, 776, 308],
    3801: [319, 0, 275, 44, 816, 816, 319],
    3901: [329, 0, 283, 50, 856, 856, 329],
    4001: [331, 0, 283, 78, 896, 896, 331],
    4101: [342, 0, 291, 105, 936, 936, 342],
    4201: [352, 0, 300, 132, 976, 976, 352],
    4301: [363, 0, 308, 159, 1013, 1013, 363],
    4401: [373, 0, 316, 185, 1043, 1043, 373],
    4501: [383, 0, 325, 212, 1076, 1076, 383],
    4601: [384, 0, 325, 239, 1109, 1109, 384],
    4701: [394, 0, 333, 265, 1139, 1139, 394],
    4801: [405, 9, 342, 292, 1173, 1173, 405],
    4901: [414, 39, 352, 319, 1203, 1203, 414],
    5001: [425, 69, 362, 345, 1236, 1236, 425],
    5101: [435, 99, 372, 372, 1269, 1269, 435],
    5201: [445, 129, 382, 399, 1299, 1299, 445],
    5301: [447, 159, 383, 426, 1333, 1333, 447],
    5401: [456, 189, 393, 452, 1362, 1362, 456],
    5501: [467, 219, 403, 478, 1395, 1395, 467],
    5601: [477, 250, 413, 504, 1428, 1428, 477],
    5701: [498, 279, 423, 530, 1457, 1457, 487],
    5801: [522, 306, 433, 556, 1490, 1490, 497],
    5901: [546, 336, 434, 582, 1519, 1519, 498],
    6001: [571, 366, 444, 608, 1552, 1552, 509],
    6101: [595, 396, 454, 634, 1585, 1585, 519],
    6201: [619, 426, 464, 660, 1614, 1614, 529],
    6301: [643, 456, 474, 686, 1647, 1647, 539],
    6401: [668, 486, 484, 712, 1676, 1676, 549],
    6501: [692, 516, 486, 739, 1709, 1709, 551],
    6601: [717, 546, 496, 765, 1742, 1742, 561],
    6701: [741, 576, 506, 791, 1771, 1771, 571],
    6801: [765, 606, 516, 817, 1804, 1804, 581],
    6901: [789, 633, 525, 843, 1833, 1833, 591],
    7001: [814, 663, 535, 869, 1866, 1866, 601],
    7101: [838, 693, 537, 895, 1899, 1899, 603],
    7201: [862, 723, 547, 921, 1928, 1928, 613],
    7301: [887, 753, 557, 947, 1961, 1961, 623],
    7401: [911, 783, 567, 973, 1990, 1990, 633],
    7501: [935, 813, 577, 999, 2023, 2023, 643],
    7601: [960, 843, 587, 1026, 2056, 2056, 653],
    7701: [984, 873, 597, 1052, 2085, 2085, 663],
    7801: [1008, 903, 598, 1078, 2118, 2118, 665],
    7901: [1032, 933, 608, 1104, 2147, 2147, 675],
    8001: [1057, 963, 618, 1130, 2180, 2180, 685],
    8101: [1081, 989, 628, 1156, 2213, 2213, 695],
    8201: [1105, 1019, 638, 1182, 2242, 2242, 705],
    8301: [1130, 1049, 648, 1208, 2275, 2275, 715],
    8401: [1154, 1079, 649, 1234, 2304, 2304, 717],
    8501: [1178, 1103, 659, 1260, 2337, 2337, 727],
    8601: [1203, 1126, 669, 1286, 2370, 2370, 737],
    8701: [1227, 1153, 678, 1312, 2399, 2399, 747],
    8801: [1251, 1176, 688, 1338, 2432, 2432, 757],
    8901: [1275, 1199, 698, 1365, 2461, 2461, 767],
    9001: [1300, 1223, 699, 1391, 2494, 2494, 769],
    9101: [1324, 1250, 709, 1417, 2527, 2527, 779],
    9201: [1348, 1273, 718, 1443, 2556, 2556, 789],
    9301: [1373, 1296, 728, 1469, 2589, 2589, 799],
    9401: [1397, 1319, 737, 1495, 2618, 2618, 809],
    9501: [1421, 1346, 747, 1521, 2651, 2651, 819],
    9601: [1446, 1369, 748, 1547, 2684, 2684, 821],
    9701: [1470, 1392, 758, 1573, 2713, 2713, 831],
    9801: [1494, 1415, 768, 1599, 2746, 2746, 841],
    9901: [1519, 1441, 777, 1625, 2775, 2775, 851],
    10001: [1543, 1464, 787, 1651, 2808, 2808, 861],
    10101: [1567, 1487, 797, 1678, 2841, 2841, 877],
    10201: [1592, 1509, 806, 1704, 2870, 2870, 899],
    10301: [1616, 1536, 808, 1730, 2903, 2903, 925],
    10401: [1640, 1559, 817, 1756, 2932, 2932, 947],
    10501: [1664, 1581, 827, 1782, 2965, 2965, 973],
    10601: [1689, 1604, 836, 1808, 2998, 2998, 999],
    10701: [1713, 1630, 846, 1834, 3027, 3027, 1021],
    10801: [1737, 1653, 856, 1860, 3060, 3060, 1047],
    10901: [1762, 1676, 857, 1886, 3089, 3089, 1069],
    11001: [1786, 1702, 867, 1912, 3122, 3122, 1095],
    11101: [1810, 1725, 876, 1938, 3155, 3155, 1121],
    11201: [1835, 1748, 886, 1964, 3184, 3184, 1143],
    11301: [1859, 1771, 896, 1991, 3217, 3217, 1169],
    11401: [1883, 1797, 905, 2017, 3246, 3246, 1191],
    11501: [1908, 1820, 907, 2043, 3279, 3279, 1217],
    11601: [1932, 1843, 916, 2069, 3312, 3312, 1243],
    11701: [1956, 1866, 926, 2095, 3341, 3341, 1265],
    11801: [1980, 1892, 936, 2121, 3374, 3374, 1291],
    11901: [2005, 1915, 945, 2147, 3410, 3410, 1320],
    12001: [2030, 1938, 955, 2174, 3449, 3449, 1352],
    12101: [2054, 1961, 956, 2200, 3488, 3488, 1384],
    12201: [2079, 1987, 966, 2226, 3528, 3528, 1417],
    12301: [2103, 2010, 975, 2253, 3567, 3567, 1449],
    12401: [2128, 2033, 985, 2279, 3606, 3606, 1481],
    12501: [2152, 2056, 995, 2305, 3645, 3645, 1513],
    12601: [2177, 2082, 1004, 2332, 3685, 3685, 1546],
    12701: [2202, 2105, 1014, 2358, 3724, 3724, 1578],
    12801: [2226, 2128, 1015, 2385, 3763, 3763, 1610],
    12901: [2251, 2154, 1025, 2411, 3802, 3802, 1642],
    13001: [2275, 2177, 1035, 2437, 3842, 3842, 1675],
    13101: [2300, 2200, 1044, 2464, 3881, 3881, 1707],
    13201: [2325, 2222, 1054, 2490, 3920, 3920, 1739],
    13301: [2349, 2249, 1064, 2516, 3959, 3959, 1771],
    13401: [2374, 2272, 1065, 2543, 3999, 3999, 1804],
    13501: [2398, 2294, 1074, 2569, 4038, 4038, 1836],
    13601: [2423, 2317, 1084, 2596, 4080, 4080, 1871],
    13701: [2448, 2344, 1094, 2622, 4126, 4126, 1910],
    13801: [2473, 2366, 1103, 2649, 4169, 4169, 1946],
    13901: [2497, 2389, 1113, 2675, 4211, 4211, 1981],
    14001: [2522, 2412, 1114, 2702, 4254, 4254, 2017],
    14101: [2550, 2442, 1124, 2730, 4296, 4296, 2052],
    14201: [2583, 2474, 1134, 2764, 4340, 4340, 2089],
    14301: [2617, 2510, 1145, 2797, 4382, 4382, 2124],
    14401: [2650, 2543, 1155, 2831, 4423, 4423, 2158],
    14501: [2684, 2576, 1165, 2864, 4465, 4465, 2193],
    14601: [2717, 2612, 1167, 2897, 4506, 4506, 2227],
    14701: [2751, 2644, 1177, 2931, 4551, 4551, 2265],
    14801: [2784, 2680, 1187, 2964, 4592, 4592, 2299],
    14901: [2817, 2713, 1197, 2998, 4634, 4634, 2334],
    15001: [2851, 2746, 1208, 3031, 4675, 4675, 2368],
    15101: [2884, 2782, 1218, 3065, 4716, 4716, 2402],
    15201: [2918, 2815, 1228, 3098, 4761, 4761, 2440],
    15301: [2951, 2850, 1230, 3132, 4803, 4803, 2475],
    15401: [2985, 2883, 1240, 3165, 4844, 4844, 2509],
    15501: [3018, 2916, 1250, 3199, 4885, 4885, 2543],
    15601: [3051, 2952, 1261, 3232, 4927, 4927, 2578],
    15701: [3085, 2985, 1271, 3265, 4971, 4971, 2615],
    15801: [3118, 3021, 1281, 3299, 5013, 5013, 2650],
    15901: [3152, 3053, 1283, 3332, 5054, 5054, 2684],
    16001: [3185, 3086, 1293, 3366, 5096, 5096, 2719],
    16101: [3219, 3122, 1303, 3399, 5137, 5137, 2753],
    16201: [3252, 3155, 1313, 3433, 5182, 5182, 2791],
    16301: [3286, 3191, 1324, 3466, 5223, 5223, 2825],
    16401: [3319, 3223, 1334, 3500, 5265, 5265, 2860],
    16501: [3353, 3256, 1336, 3533, 5306, 5306, 2894],
    16601: [3386, 3292, 1346, 3566, 5348, 5348, 2929],
    16701: [3420, 3325, 1356, 3600, 5392, 5392, 2966],
    16801: [3453, 3361, 1366, 3633, 5434, 5434, 3001],
    16901: [3486, 3393, 1377, 3667, 5475, 5475, 3035],
    17001: [3520, 3426, 1387, 3700, 5517, 5517, 3070],
    17101: [3553, 3462, 1389, 3734, 5558, 5558, 3104],
    17201: [3587, 3495, 1399, 3767, 5603, 5603, 3142],
    17301: [3620, 3531, 1409, 3801, 5644, 5644, 3176],
    17401: [3654, 3564, 1419, 3834, 5685, 5685, 3210],
    17501: [3687, 3596, 1429, 3867, 5727, 5727, 3245],
    17601: [3720, 3632, 1440, 3901, 5768, 5768, 3279],
    17701: [3754, 3665, 1450, 3934, 5813, 5813, 3317],
    17801: [3787, 3701, 1452, 3968, 5854, 5854, 3351],
    17901: [3821, 3734, 1462, 4001, 5896, 5896, 3386],
    18001: [3854, 3766, 1472, 4035, 5937, 5937, 3420],
    18101: [3888, 3802, 1482, 4068, 5979, 5979, 3455],
    18201: [3921, 3835, 1493, 4102, 6023, 6023, 3492],
    18301: [3955, 3871, 1503, 4135, 6065, 6065, 3527],
    18401: [3988, 3904, 1505, 4169, 6106, 6106, 3561],
    18501: [4021, 3936, 1515, 4202, 6148, 6148, 3596],
    18601: [4055, 3972, 1525, 4235, 6189, 6189, 3630],
    18701: [4088, 4005, 1535, 4269, 6234, 6234, 3668],
    18801: [4122, 4041, 1546, 4302, 6275, 6275, 3702],
    18901: [4155, 4074, 1556, 4336, 6317, 6317, 3737],
    19001: [4189, 4110, 1558, 4369, 6358, 6358, 3771],
    19101: [4222, 4142, 1568, 4403, 6399, 6399, 3805],
    19201: [4256, 4175, 1578, 4436, 6444, 6444, 3843],
    19301: [4289, 4211, 1603, 4470, 6486, 6486, 3878],
    19401: [4323, 4244, 1629, 4503, 6527, 6527, 3912],
    19501: [4356, 4280, 1658, 4536, 6568, 6568, 3946],
    19601: [4389, 4312, 1683, 4570, 6610, 6610, 3981],
    19701: [4423, 4344, 1708, 4603, 6654, 6654, 4018],
    19801: [4456, 4379, 1736, 4637, 6696, 6696, 4053],
    19901: [4490, 4410, 1760, 4670, 6737, 6737, 4087],
    20001: [4557, 4477, 1813, 4737, 6820, 6820, 4156],
    20201: [4624, 4544, 1866, 4804, 6906, 6906, 4228],
    20401: [4690, 4611, 1919, 4871, 6989, 6989, 4297],
    20601: [4757, 4675, 1969, 4938, 7075, 7075, 4369],
    20801: [4824, 4742, 2022, 5005, 7158, 7158, 4438],
    21001: [4891, 4809, 2075, 5072, 7241, 7241, 4507],
    21201: [4958, 4876, 2128, 5139, 7327, 7327, 4579],
    21401: [5025, 4943, 2181, 5205, 7410, 7410, 4648],
    21601: [5092, 5007, 2231, 5272, 7496, 7496, 4720],
    21801: [5159, 5074, 2284, 5339, 7579, 7579, 4789],
    22001: [5226, 5140, 2336, 5406, 7662, 7662, 4858],
    22201: [5293, 5207, 2389, 5473, 7748, 7748, 4930],
    22401: [5360, 5274, 2442, 5541, 7832, 7832, 5000],
    22601: [5429, 5338, 2492, 5609, 7919, 7919, 5073],
    22801: [5497, 5405, 2545, 5678, 8004, 8004, 5144],
    23001: [5566, 5472, 2598, 5746, 8088, 8088, 5214],
    23201: [5635, 5539, 2651, 5815, 8176, 8176, 5288],
    23401: [5703, 5606, 2704, 5883, 8261, 8261, 5359],
    23601: [5772, 5673, 2757, 5952, 8348, 8348, 5432],
    23801: [5840, 5736, 2806, 6021, 8433, 8433, 5503],
    24001: [5909, 5803, 2859, 6089, 8517, 8517, 5573],
    24201: [5977, 5874, 2916, 6158, 8605, 8605, 5647],
    24401: [6046, 5940, 2968, 6226, 8690, 8690, 5718],
    24601: [6114, 6007, 3021, 6295, 8777, 8777, 5791],
    24801: [6183, 6074, 3074, 6363, 8862, 8862, 5862],
    25001: [6251, 6144, 3144, 6432, 8946, 8946, 5946],
    25201: [6320, 6211, 3211, 6500, 9034, 9034, 6034],
    25401: [6388, 6278, 3278, 6569, 9119, 9119, 6119],
    25601: [6457, 6349, 3349, 6638, 9206, 9206, 6206],
    25801: [6526, 6415, 3415, 6706, 9291, 9291, 6291],
    26001: [6594, 6482, 3482, 6775, 9375, 9375, 6375],
    26201: [6663, 6552, 3552, 6843, 9463, 9463, 6463],
    26401: [6731, 6619, 3619, 6912, 9548, 9548, 6548],
    26601: [6800, 6686, 3686, 6980, 9635, 9635, 6635],
    26801: [6868, 6756, 3756, 7049, 9720, 9720, 6720],
    27001: [6937, 6823, 3823, 7117, 9804, 9804, 6804],
    27201: [7005, 6890, 3890, 7186, 9892, 9892, 6892],
    27401: [7074, 6960, 3960, 7254, 9977, 9977, 6977],
    27601: [7143, 7027, 4027, 7323, 10064, 10064, 7064],
    27801: [7211, 7094, 4094, 7391, 10149, 10149, 7149],
    28001: [7280, 7161, 4161, 7460, 10233, 10233, 7233],
    28201: [7348, 7231, 4231, 7529, 10321, 10321, 7321],
    28401: [7417, 7298, 4298, 7597, 10406, 10406, 7406],
    28601: [7485, 7365, 4365, 7666, 10493, 10493, 7493],
    28801: [7554, 7435, 4435, 7734, 10578, 10578, 7578],
    29001: [7622, 7502, 4502, 7803, 10662, 10662, 7662],
    29201: [7691, 7569, 4569, 7871, 10750, 10750, 7750],
    29401: [7759, 7639, 4639, 7940, 10835, 10835, 7835],
    29601: [7828, 7706, 4706, 8008, 10922, 10922, 7922],
    29801: [7896, 7773, 4773, 8077, 11007, 11007, 8007],
    30001: [7965, 7845, 4845, 8145, 11091, 11091, 8091],
    30201: [8034, 7913, 4913, 8214, 11179, 11179, 8179],
    30401: [8102, 7981, 4981, 8283, 11264, 11264, 8264],
    30601: [8171, 8049, 5049, 8351, 11351, 11351, 8351],
    30801: [8239, 8121, 5121, 8420, 11436, 11436, 8436],
    31001: [8308, 8189, 5189, 8488, 11520, 11520, 8520],
    31201: [8376, 8257, 5257, 8557, 11608, 11608, 8608],
    31401: [8445, 8329, 5329, 8625, 11693, 11693, 8693],
    31601: [8513, 8397, 5397, 8694, 11780, 11780, 8780],
    31801: [8582, 8465, 5465, 8762, 11865, 11865, 8865],
    32001: [8650, 8537, 5537, 8831, 11949, 11949, 8949],
    32201: [8719, 8605, 5605, 8899, 12037, 12037, 9037],
    32401: [8788, 8673, 5673, 8968, 12122, 12122, 9122],
    32601: [8856, 8745, 5745, 9037, 12209, 12209, 9209],
    32801: [8925, 8813, 5813, 9105, 12294, 12294, 9294],
    33001: [8993, 8881, 5881, 9174, 12378, 12378, 9378],
    33201: [9062, 8953, 5953, 9242, 12466, 12466, 9466],
    33401: [9130, 9021, 6021, 9311, 12551, 12551, 9551],
    33601: [9199, 9089, 6089, 9379, 12638, 12638, 9638],
    33801: [9267, 9158, 6158, 9448, 12723, 12723, 9723],
    34001: [9336, 9229, 6229, 9516, 12807, 12807, 9807],
    34201: [9404, 9297, 6297, 9585, 12895, 12895, 9895],
    34401: [9473, 9366, 6366, 9653, 12976, 12976, 9976],
    34601: [9541, 9434, 6434, 9722, 13054, 13054, 10054],
    34801: [9610, 9499, 6499, 9790, 13132, 13132, 10132],
    35001: [9678, 9567, 6567, 9858, 13210, 13210, 10210],
    35201: [9748, 9639, 6639, 9927, 13288, 13288, 10288],
    35401: [9826, 9717, 6717, 9995, 13366, 13366, 10366],
    35601: [9904, 9795, 6795, 10063, 13444, 13444, 10444],
    35801: [9982, 9873, 6873, 10132, 13522, 13522, 10522],
    36001: [10060, 9951, 6951, 10200, 13600, 13600, 10600],
    36201: [10138, 10029, 7029, 10268, 13678, 13678, 10678],
    36401: [10216, 10107, 7107, 10336, 13756, 13756, 10756],
    36601: [10294, 10185, 7185, 10405, 13834, 13834, 10834],
    36801: [10372, 10263, 7263, 10473, 13912, 13912, 10912],
    37001: [10450, 10341, 7341, 10541, 13990, 13990, 10990],
    37201: [10528, 10419, 7419, 10610, 14068, 14068, 11068],
    37401: [10606, 10497, 7497, 10678, 14146, 14146, 11146],
    37601: [10684, 10575, 7575, 10746, 14224, 14224, 11224],
    37801: [10762, 10653, 7653, 10815, 14302, 14302, 11302],
    38001: [10840, 10731, 7731, 10883, 14380, 14380, 11380],
    38201: [10918, 10809, 7809, 10951, 14458, 14458, 11458],
    38401: [10996, 10887, 7887, 11020, 14536, 14536, 11536],
    38601: [11074, 10965, 7965, 11088, 14614, 14614, 11614],
    38801: [11152, 11043, 8043, 11156, 14692, 14692, 11692],
    39001: [11230, 11121, 8121, 11225, 14770, 14770, 11770],
    39201: [11308, 11199, 8199, 11293, 14848, 14848, 11848],
    39401: [11386, 11277, 8277, 11361, 14926, 14926, 11926],
    39601: [11464, 11355, 8355, 11429, 15004, 15004, 12004],
    39801: [11542, 11433, 8433, 11498, 15082, 15082, 12082],
    40001: [11620, 11511, 8511, 11566, 15160, 15160, 12160],
    40201: [11698, 11589, 8589, 11634, 15238, 15238, 12238],
    40401: [11776, 11667, 8667, 11703, 15316, 15316, 12316],
    40601: [11854, 11745, 8745, 11771, 15394, 15394, 12394],
    40801: [11932, 11823, 8823, 11839, 15472, 15472, 12472],
    41001: [12010, 11901, 8901, 11908, 15550, 15550, 12550],
    41201: [12088, 11979, 8979, 11976, 15628, 15628, 12628],
    41401: [12166, 12057, 9057, 12044, 15706, 15706, 12706],
    41601: [12244, 12135, 9135, 12113, 15784, 15784, 12784],
    41801: [12322, 12213, 9213, 12181, 15862, 15862, 12862],
    42001: [12400, 12291, 9291, 12249, 15940, 15940, 12940],
    42201: [12478, 12369, 9369, 12318, 16018, 16018, 13018],
    42401: [12556, 12447, 9447, 12386, 16096, 16096, 13096],
    42601: [12634, 12525, 9525, 12454, 16174, 16174, 13174],
    42801: [12712, 12603, 9603, 12522, 16252, 16252, 13252],
    43001: [12790, 12681, 9681, 12591, 16330, 16330, 13330],
    43201: [12868, 12759, 9759, 12659, 16408, 16408, 13408],
    43401: [12946, 12837, 9837, 12727, 16486, 16486, 13486],
    43601: [13024, 12915, 9915, 12796, 16564, 16564, 13564],
    43801: [13102, 12993, 9993, 12864, 16642, 16642, 13642],
    44001: [13180, 13071, 10071, 12932, 16720, 16720, 13720],
    44201: [13258, 13149, 10149, 13001, 16798, 16798, 13798],
    44401: [13336, 13227, 10227, 13069, 16876, 16876, 13876],
    44601: [13414, 13305, 10305, 13137, 16954, 16954, 13954],
    44801: [13492, 13383, 10383, 13206, 17032, 17032, 14032],
    45001: [13570, 13461, 10461, 13274, 17110, 17110, 14110],
    45201: [13648, 13539, 10539, 13342, 17188, 17188, 14188],
    45401: [13726, 13617, 10617, 13411, 17266, 17266, 14266],
    45601: [13804, 13695, 10695, 13479, 17344, 17344, 14344],
    45801: [13882, 13773, 10773, 13547, 17422, 17422, 14422],
    46001: [13960, 13851, 10851, 13615, 17500, 17500, 14500],
    46201: [14038, 13929, 10929, 13684, 17578, 17578, 14578],
    46401: [14116, 14007, 11007, 13752, 17656, 17656, 14656],
    46601: [14194, 14085, 11085, 13820, 17734, 17734, 14734],
    46801: [14272, 14163, 11163, 13889, 17812, 17812, 14812],
    47001: [14350, 14241, 11241, 13957, 17890, 17890, 14890],
    47201: [14428, 14319, 11319, 14025, 17968, 17968, 14968],
    47401: [14506, 14397, 11397, 14094, 18046, 18046, 15046],
    47601: [14584, 14475, 11475, 14162, 18124, 18124, 15124],
    47801: [14662, 14553, 11553, 14230, 18202, 18202, 15202],
    48001: [14740, 14631, 11631, 14299, 18280, 18280, 15280],
    48201: [14818, 14709, 11709, 14367, 18358, 18358, 15358],
    48401: [14896, 14787, 11787, 14435, 18436, 18436, 15436],
    48601: [14974, 14865, 11865, 14504, 18514, 18514, 15514],
    48801: [15052, 14943, 11943, 14572, 18592, 18592, 15592],
    49001: [15130, 15021, 12021, 14640, 18670, 18670, 15670],
    49201: [15208, 15099, 12099, 14709, 18748, 18748, 15748],
    49401: [15286, 15177, 12177, 14777, 18826, 18826, 15826],
    49601: [15364, 15255, 12255, 14845, 18904, 18904, 15904],
    49801: [15442, 15333, 12333, 14913, 18982, 18982, 15982],
    50001: [15520, 15411, 12417, 14982, 19060, 19060, 16066],
    50201: [15598, 15531, 12543, 15050, 19138, 19138, 16150],
    50401: [15676, 15654, 12672, 15118, 19216, 19216, 16234],
    50601: [15754, 15781, 12805, 15187, 19294, 19294, 16318],
    50801: [15832, 15908, 12938, 15255, 19372, 19372, 16402],
    51001: [15918, 16035, 13071, 15332, 19459, 19459, 16495],
    51201: [16036, 16161, 13203, 15440, 19577, 19577, 16619],
    51401: [16154, 16288, 13336, 15548, 19695, 19695, 16743],
    51601: [16272, 16415, 13469, 15657, 19813, 19813, 16867],
    51801: [16390, 16542, 13602, 15765, 19931, 19931, 16991],
    52001: [16508, 16665, 13731, 15873, 20049, 20049, 17115],
    52201: [16626, 16792, 13864, 15982, 20167, 20167, 17239],
    52401: [16744, 16919, 13997, 16090, 20285, 20285, 17363],
    52601: [16862, 17045, 14129, 16198, 20403, 20403, 17487],
    52801: [16980, 17172, 14262, 16306, 20521, 20521, 17611],
    53001: [17098, 17299, 14395, 16415, 20639, 20639, 17735],
    53201: [17216, 17426, 14528, 16523, 20757, 20757, 17859],
    53401: [17334, 17552, 14660, 16631, 20875, 20875, 17983],
    53601: [17452, 17676, 14790, 16740, 20993, 20993, 18107],
    53801: [17570, 17803, 14923, 16848, 21111, 21111, 18231],
    54001: [17688, 17929, 15055, 16956, 21229, 21229, 18355],
    54201: [17806, 18056, 15188, 17065, 21347, 21347, 18479],
    54401: [17924, 18183, 15321, 17173, 21465, 21465, 18603],
    54601: [18042, 18310, 15454, 17281, 21583, 21583, 18727],
    54801: [18160, 18436, 15586, 17390, 21701, 21701, 18851],
    55001: [18278, 18563, 15719, 17498, 21819, 21819, 18975],
    55201: [18396, 18690, 15852, 17606, 21937, 21937, 19099],
    55401: [18514, 18813, 15981, 17715, 22055, 22055, 19223],
    55601: [18632, 18940, 16114, 17823, 22173, 22173, 19347],
    55801: [18750, 19067, 16247, 17931, 22291, 22291, 19471],
    56001: [18868, 19148, 16334, 18039, 22409, 22409, 19595],
    56201: [18986, 19226, 16418, 18148, 22527, 22527, 19719],
    56401: [19104, 19304, 16502, 18256, 22645, 22645, 19843],
    56601: [19222, 19382, 16586, 18364, 22763, 22763, 19967],
    56801: [19340, 19473, 16683, 18473, 22881, 22881, 20091],
    57001: [19458, 19591, 16807, 18581, 22999, 22999, 20215],
    57201: [19576, 19709, 16931, 18689, 23117, 23117, 20339],
    57401: [19694, 19827, 17055, 18798, 23235, 23235, 20463],
    57601: [19812, 19945, 17179, 18906, 23353, 23353, 20587],
    57801: [19930, 20063, 17303, 19014, 23471, 23471, 20711],
    58001: [20048, 20181, 17427, 19123, 23589, 23589, 20835],
    58201: [20166, 20299, 17551, 19231, 23707, 23707, 20959],
    58401: [20284, 20417, 17675, 19339, 23825, 23825, 21083],
    58601: [20402, 20535, 17799, 19448, 23943, 23943, 21207],
    58801: [20520, 20653, 17923, 19556, 24061, 24061, 21331],
    59001: [20638, 20771, 18047, 19664, 24179, 24179, 21455],
    59201: [20761, 20894, 18176, 19772, 24297, 24297, 21579],
    59401: [20885, 21017, 18305, 19881, 24415, 24415, 21703],
    59601: [21009, 21145, 18439, 19989, 24533, 24533, 21827],
    59801: [21133, 21268, 18568, 20097, 24651, 24651, 21951],
    60001: [21257, 21391, 18697, 20206, 24769, 24769, 22075],
    60201: [21381, 21519, 18831, 20314, 24887, 24887, 22199],
    60401: [21505, 21642, 18960, 20422, 25005, 25005, 22323],
    60601: [21629, 21769, 19093, 20531, 25123, 25123, 22447],
    60801: [21753, 21892, 19222, 20639, 25241, 25241, 22571],
    61001: [21877, 22015, 19351, 20747, 25359, 25359, 22695],
    61201: [22001, 22143, 19485, 20856, 25477, 25477, 22819],
    61401: [22125, 22266, 19614, 20964, 25595, 25595, 22943],
    61601: [22249, 22389, 19743, 21072, 25713, 25713, 23067],
    61801: [22373, 22517, 19877, 21181, 25831, 25831, 23191],
    62001: [22497, 22640, 20006, 21289, 25949, 25949, 23315],
    62201: [22621, 22768, 20140, 21397, 26067, 26067, 23439],
    62401: [22745, 22890, 20268, 21505, 26185, 26185, 23563],
    62601: [22869, 23013, 20397, 21614, 26303, 26303, 23687],
    62801: [22993, 23141, 20531, 21722, 26421, 26421, 23811],
    63001: [23117, 23264, 20660, 21830, 26539, 26539, 23935],
    63201: [23241, 23387, 20789, 21939, 26657, 26657, 24059],
    63401: [23365, 23515, 20923, 22047, 26775, 26775, 24183],
    63601: [23489, 23638, 21052, 22155, 26893, 26893, 24307],
    63801: [23613, 23766, 21186, 22264, 27011, 27011, 24431],
    64001: [23737, 23889, 21315, 22372, 27129, 27129, 24555],
    64201: [23861, 24011, 21443, 22480, 27247, 27247, 24679],
    64401: [23985, 24139, 21577, 22589, 27365, 27365, 24803],
    64601: [24109, 24262, 21706, 22697, 27483, 27483, 24927],
    64801: [24233, 24385, 21835, 22805, 27601, 27601, 25051],
    65001: [24357, 24513, 21969, 22914, 27719, 27719, 25175],
    65201: [24481, 24636, 22098, 23022, 27837, 27837, 25299],
    65401: [24605, 24764, 22232, 23130, 27955, 27955, 25423],
    65601: [24729, 24887, 22361, 23238, 28073, 28073, 25547],
    65801: [24853, 25010, 22490, 23347, 28191, 28191, 25671],
    66001: [24977, 25137, 22623, 23455, 28309, 28309, 25795],
    66201: [25101, 25260, 22752, 23563, 28427, 28427, 25919],
    66401: [25225, 25383, 22881, 23672, 28545, 28545, 26043],
    66601: [25349, 25511, 23015, 23780, 28663, 28663, 26167],
    66801: [25473, 25634, 23144, 23888, 28781, 28781, 26291],
    67001: [25597, 25762, 23278, 23997, 28899, 28899, 26415],
    67201: [25721, 25885, 23407, 24105, 29017, 29017, 26539],
    67401: [25845, 26008, 23536, 24213, 29135, 29135, 26663],
    67601: [25969, 26135, 23669, 24322, 29253, 29253, 26787],
    67801: [26093, 26258, 23798, 24430, 29371, 29371, 26911],
    68001: [26217, 26386, 23932, 24538, 29489, 29489, 27035],
    68201: [26341, 26509, 24061, 24647, 29607, 29607, 27159],
    68401: [26465, 26632, 24190, 24755, 29725, 29725, 27283],
    68601: [26589, 26760, 24324, 24863, 29843, 29843, 27407],
    68801: [26713, 26883, 24453, 24972, 29961, 29961, 27531],
    69001: [26837, 27006, 24582, 25080, 30079, 30079, 27655],
    69201: [26961, 27134, 24716, 25188, 30197, 30197, 27779],
    69401: [27085, 27256, 24844, 25296, 30315, 30315, 27903],
    69601: [27209, 27384, 24978, 25405, 30433, 30433, 28027],
    69801: [27333, 27507, 25107, 25513, 30551, 30551, 28151],
    70001: [27457, 27630, 25236, 25621, 30669, 30669, 28275],
    70201: [27581, 27758, 25370, 25730, 30787, 30787, 28399],
    70401: [27705, 27881, 25499, 25838, 30905, 30905, 28523],
    70601: [27829, 28004, 25628, 25946, 31023, 31023, 28647],
    70801: [27953, 28132, 25762, 26055, 31141, 31141, 28771],
    71001: [28077, 28255, 25891, 26163, 31259, 31259, 28895],
    71201: [28201, 28382, 26024, 26271, 31377, 31377, 29019],
    71401: [28325, 28505, 26153, 26380, 31495, 31495, 29143],
    71601: [28449, 28628, 26282, 26488, 31613, 31613, 29267],
    71801: [28573, 28756, 26416, 26596, 31731, 31731, 29391],
    72001: [28697, 28879, 26545, 26705, 31849, 31849, 29515],
    72201: [28821, 29002, 26674, 26813, 31967, 31967, 29639],
    72401: [28945, 29130, 26808, 26921, 32085, 32085, 29763],
    72601: [29069, 29253, 26937, 27029, 32203, 32203, 29887],
    72801: [29193, 29380, 27070, 27138, 32321, 32321, 30011],
    73001: [29317, 29503, 27199, 27246, 32439, 32439, 30135],
    73201: [29441, 29626, 27328, 27354, 32557, 32557, 30259],
    73401: [29565, 29754, 27462, 27463, 32675, 32675, 30383],
    73601: [29689, 29877, 27591, 27571, 32793, 32793, 30507],
    73801: [29813, 30000, 27720, 27679, 32911, 32911, 30631],
    74001: [29937, 30128, 27854, 27788, 33029, 33029, 30755],
    74201: [30061, 30251, 27983, 27896, 33147, 33147, 30879],
    74401: [30185, 30379, 28117, 28004, 33265, 33265, 31003],
    74601: [30309, 30501, 28245, 28113, 33383, 33383, 31127],
    74801: [30433, 30624, 28374, 28221, 33501, 33501, 31251],
    75001: [30557, 30752, 28508, 28329, 33619, 33619, 31375],
    75201: [30681, 30875, 28637, 28438, 33737, 33737, 31499],
    75401: [30805, 30998, 28766, 28546, 33855, 33855, 31623],
    75601: [30929, 31126, 28900, 28654, 33973, 33973, 31747],
    75801: [31053, 31249, 29029, 28762, 34091, 34091, 31871],
    76001: [31177, 31377, 29163, 28871, 34209, 34209, 31995],
    76201: [31301, 31500, 29292, 28979, 34327, 34327, 32119],
    76401: [31425, 31622, 29420, 29087, 34445, 34445, 32243],
    76601: [31549, 31750, 29554, 29196, 34563, 34563, 32367],
    76801: [31673, 31873, 29683, 29304, 34681, 34681, 32491],
    77001: [31797, 32001, 29817, 29412, 34799, 34799, 32615],
    77201: [31921, 32124, 29946, 29521, 34917, 34917, 32739],
    77401: [32045, 32247, 30075, 29629, 35035, 35035, 32863],
    77601: [32169, 32375, 30209, 29737, 35153, 35153, 32987],
    77801: [32293, 32498, 30338, 29846, 35271, 35271, 33111],
    78001: [32417, 32621, 30467, 29954, 35389, 35389, 33235],
    78201: [32541, 32748, 30600, 30062, 35507, 35507, 33359],
    78401: [32665, 32871, 30729, 30171, 35625, 35625, 33483],
    78601: [32789, 32999, 30863, 30279, 35743, 35743, 33607],
    78801: [32913, 33122, 30992, 30387, 35861, 35861, 33731],
    79001: [33037, 33245, 31121, 30496, 35979, 35979, 33855],
    79201: [33161, 33373, 31255, 30604, 36097, 36097, 33979],
    79401: [33285, 33496, 31384, 30712, 36215, 36215, 34103],
    79601: [33409, 33619, 31513, 30820, 36333, 36333, 34227],
    79801: [33533, 33746, 31646, 30929, 36451, 36451, 34351],
    80001: [42, 42, 40, 39, 46, 46, 43, '%'],
    81201: [42, 43, 40, 39, 46, 46, 43, '%'],
    82201: [43, 43, 40, 39, 46, 46, 43, '%'],
    82401: [43, 43, 40, 39, 46, 46, 44, '%'],
    83001: [43, 43, 41, 39, 46, 46, 44, '%'],
    84401: [43, 43, 41, 40, 46, 46, 44, '%'],
    85401: [43, 44, 41, 40, 46, 46, 44, '%'],
    85801: [43, 44, 41, 40, 47, 47, 44, '%'],
    86401: [43, 44, 42, 40, 47, 47, 44, '%'],
    86801: [44, 44, 42, 40, 47, 47, 44, '%'],
    87001: [44, 44, 42, 40, 47, 47, 45, '%'],
    90201: [44, 45, 43, 40, 47, 47, 45, '%'],
    90601: [44, 45, 43, 41, 47, 47, 45, '%'],
    91801: [45, 45, 43, 41, 47, 47, 45, '%'],
    92401: [45, 45, 43, 41, 47, 47, 46, '%'],
    93401: [45, 45, 43, 41, 48, 48, 46, '%'],
    94401: [45, 45, 44, 41, 48, 48, 46, '%'],
    95601: [45, 46, 44, 41, 48, 48, 46, '%'],
    97201: [46, 46, 44, 41, 48, 48, 46, '%'],
    97801: [46, 46, 44, 42, 48, 48, 46, '%'],
    98201: [46, 46, 44, 42, 48, 48, 47, '%'],
    98801: [46, 46, 45, 42, 48, 48, 47, '%'],
    101601: [46, 47, 45, 42, 48, 48, 47, '%'],
    102201: [46, 47, 45, 42, 49, 49, 47, '%'],
    103601: [47, 47, 45, 42, 49, 49, 47, '%'],
    103801: [47, 47, 46, 42, 49, 49, 47, '%'],
    105001: [47, 47, 46, 42, 49, 49, 48, '%'],
    106201: [47, 47, 46, 43, 49, 49, 48, '%'],
    108401: [47, 48, 46, 43, 49, 49, 48, '%'],
    109401: [47, 48, 47, 43, 49, 49, 48, '%'],
    110801: [48, 48, 47, 43, 49, 49, 48, '%'],
    112801: [48, 48, 47, 43, 49, 49, 49, '%'],
    113001: [48, 48, 47, 43, 50, 50, 49, '%'],
    115401: [48, 48, 48, 43, 50, 50, 49, '%'],
    116201: [48, 49, 48, 44, 50, 50, 49, '%'],
    119001: [49, 49, 48, 44, 50, 50, 49, '%'],
    121801: [49, 49, 48, 44, 50, 50, 50, '%'],
    122201: [49, 49, 49, 44, 50, 50, 50, '%'],
    125401: [49, 50, 49, 44, 50, 50, 50, '%'],
    126401: [49, 50, 49, 44, 51, 51, 50, '%'],
    128201: [49, 50, 49, 45, 51, 51, 50, '%'],
    128401: [50, 50, 49, 45, 51, 51, 50, '%'],
    130001: [50, 50, 50, 45, 51, 51, 50, '%'],
    132401: [50, 50, 50, 45, 51, 51, 51, '%'],
    135801: [50, 51, 50, 45, 51, 51, 51, '%'],
    138601: [50, 51, 51, 45, 51, 51, 51, '%'],
    139601: [51, 51, 51, 45, 51, 51, 51, '%'],
    143001: [51, 51, 51, 46, 51, 51, 51, '%'],
    143201: [51, 51, 51, 46, 52, 52, 51, '%'],
    145201: [51, 51, 51, 46, 52, 52, 52, '%'],
    148401: [51, 52, 51, 46, 52, 52, 52, '%'],
    148601: [51, 52, 52, 46, 52, 52, 52, '%'],
    153001: [52, 52, 52, 46, 52, 52, 52, '%'],
    161801: [52, 52, 52, 47, 52, 52, 52, '%'],
    165201: [52, 53, 53, 47, 53, 53, 53, '%'],
    169001: [53, 53, 53, 47, 53, 53, 53, '%'],
    186201: [53, 53, 53, 48, 53, 53, 53, '%'],
    195401: [54, 54, 54, 48, 54, 54, 54, '%'],
    219001: [54, 54, 54, 49, 54, 54, 54, '%'],
    238801: [55, 55, 55, 49, 55, 55, 55, '%'],
    266201: [55, 55, 55, 50, 55, 55, 55, '%'],
    307001: [56, 56, 56, 50, 56, 56, 56, '%'],
    339001: [56, 56, 56, 51, 56, 56, 56, '%'],
    429801: [57, 57, 57, 51, 57, 57, 57, '%'],
    466601: [57, 57, 57, 52, 57, 57, 57, '%'],
    716601: [58, 58, 58, 52, 58, 58, 58, '%'],
    748201: [58, 58, 58, 53, 58, 58, 58, '%'],
    1888201: [58, 58, 58, 54, 58, 58, 58, '%']
  },
  40: {
    1: [0, 0, 0, 0, 0, 0, 0],
    1801: [134, 0, 133, 0, 134, 17, 134],
    1901: [144, 0, 141, 3, 144, 58, 144],
    2001: [155, 0, 150, 5, 155, 99, 155],
    2101: [157, 0, 150, 7, 157, 140, 157],
    2201: [167, 0, 158, 9, 181, 181, 167],
    2301: [178, 0, 166, 11, 222, 222, 178],
    2401: [188, 0, 175, 13, 263, 263, 188],
    2501: [199, 0, 183, 16, 304, 304, 199],
    2601: [209, 0, 191, 18, 345, 345, 209],
    2701: [220, 0, 200, 20, 386, 386, 220],
    2801: [222, 0, 200, 22, 427, 427, 222],
    2901: [232, 0, 208, 24, 468, 468, 232],
    3001: [243, 0, 216, 26, 509, 509, 243],
    3101: [253, 0, 225, 28, 550, 550, 253],
    3201: [264, 0, 233, 31, 591, 591, 264],
    3301: [274, 0, 241, 33, 632, 632, 274],
    3401: [277, 0, 241, 35, 673, 673, 277],
    3501: [287, 0, 250, 37, 714, 714, 287],
    3601: [298, 0, 258, 39, 755, 755, 298],
    3701: [308, 0, 266, 41, 796, 796, 308],
    3801: [319, 0, 275, 44, 837, 837, 319],
    3901: [329, 0, 283, 51, 878, 878, 329],
    4001: [331, 0, 283, 78, 919, 919, 331],
    4101: [342, 0, 291, 106, 960, 960, 342],
    4201: [352, 0, 300, 134, 1001, 1001, 352],
    4301: [363, 0, 308, 161, 1038, 1038, 363],
    4401: [373, 0, 316, 189, 1069, 1069, 373],
    4501: [383, 0, 325, 216, 1103, 1103, 383],
    4601: [384, 0, 325, 244, 1137, 1137, 384],
    4701: [394, 0, 333, 271, 1168, 1168, 394],
    4801: [405, 10, 342, 298, 1202, 1202, 405],
    4901: [414, 40, 352, 325, 1233, 1233, 414],
    5001: [425, 71, 362, 353, 1267, 1267, 425],
    5101: [435, 102, 372, 380, 1301, 1301, 435],
    5201: [445, 133, 382, 407, 1332, 1332, 445],
    5301: [447, 163, 383, 435, 1366, 1366, 447],
    5401: [456, 194, 393, 462, 1396, 1396, 456],
    5501: [467, 225, 403, 488, 1430, 1430, 467],
    5601: [484, 256, 413, 515, 1463, 1463, 477],
    5701: [509, 286, 423, 542, 1494, 1494, 487],
    5801: [534, 314, 433, 569, 1527, 1527, 497],
    5901: [559, 345, 434, 595, 1557, 1557, 498],
    6001: [584, 375, 444, 622, 1591, 1591, 509],
    6101: [609, 406, 454, 649, 1624, 1624, 519],
    6201: [633, 437, 464, 676, 1655, 1655, 529],
    6301: [658, 468, 474, 702, 1688, 1688, 539],
    6401: [683, 498, 484, 729, 1718, 1718, 549],
    6501: [708, 529, 486, 756, 1752, 1752, 551],
    6601: [733, 560, 496, 783, 1786, 1786, 561],
    6701: [758, 591, 506, 809, 1816, 1816, 571],
    6801: [783, 621, 516, 836, 1849, 1849, 581],
    6901: [808, 649, 525, 863, 1879, 1879, 591],
    7001: [833, 679, 535, 890, 1913, 1913, 601],
    7101: [858, 710, 537, 916, 1946, 1946, 603],
    7201: [883, 741, 547, 943, 1977, 1977, 613],
    7301: [907, 772, 557, 970, 2010, 2010, 623],
    7401: [932, 802, 567, 997, 2040, 2040, 633],
    7501: [957, 833, 577, 1023, 2074, 2074, 643],
    7601: [982, 864, 587, 1050, 2107, 2107, 653],
    7701: [1007, 895, 597, 1077, 2138, 2138, 663],
    7801: [1032, 925, 598, 1103, 2171, 2171, 665],
    7901: [1057, 956, 608, 1130, 2201, 2201, 675],
    8001: [1082, 987, 618, 1157, 2235, 2235, 685],
    8101: [1107, 1014, 628, 1184, 2268, 2268, 695],
    8201: [1132, 1045, 638, 1210, 2299, 2299, 705],
    8301: [1157, 1076, 648, 1237, 2332, 2332, 715],
    8401: [1182, 1106, 649, 1264, 2362, 2362, 717],
    8501: [1207, 1130, 659, 1291, 2396, 2396, 727],
    8601: [1232, 1154, 669, 1318, 2429, 2429, 737],
    8701: [1256, 1182, 678, 1344, 2460, 2460, 747],
    8801: [1281, 1206, 688, 1371, 2493, 2493, 757],
    8901: [1306, 1229, 698, 1398, 2523, 2523, 767],
    9001: [1331, 1253, 699, 1424, 2557, 2557, 769],
    9101: [1356, 1281, 709, 1451, 2590, 2590, 779],
    9201: [1381, 1305, 718, 1478, 2621, 2621, 789],
    9301: [1406, 1329, 728, 1505, 2654, 2654, 799],
    9401: [1431, 1352, 737, 1531, 2684, 2684, 809],
    9501: [1456, 1380, 747, 1558, 2718, 2718, 819],
    9601: [1481, 1403, 748, 1585, 2751, 2751, 822],
    9701: [1506, 1427, 758, 1612, 2782, 2782, 846],
    9801: [1531, 1450, 768, 1638, 2815, 2815, 872],
    9901: [1555, 1477, 777, 1665, 2845, 2845, 895],
    10001: [1580, 1500, 787, 1692, 2879, 2879, 922],
    10101: [1605, 1524, 797, 1719, 2912, 2912, 948],
    10201: [1630, 1547, 806, 1745, 2943, 2943, 972],
    10301: [1655, 1574, 808, 1772, 2976, 2976, 998],
    10401: [1680, 1598, 817, 1799, 3006, 3006, 1021],
    10501: [1705, 1621, 827, 1826, 3040, 3040, 1048],
    10601: [1730, 1645, 836, 1852, 3073, 3073, 1074],
    10701: [1755, 1671, 846, 1879, 3104, 3104, 1098],
    10801: [1780, 1695, 856, 1906, 3137, 3137, 1124],
    10901: [1805, 1718, 857, 1933, 3167, 3167, 1147],
    11001: [1830, 1745, 867, 1959, 3201, 3201, 1174],
    11101: [1855, 1769, 876, 1986, 3234, 3234, 1200],
    11201: [1879, 1792, 886, 2013, 3265, 3265, 1224],
    11301: [1904, 1816, 896, 2039, 3298, 3298, 1250],
    11401: [1929, 1842, 905, 2066, 3328, 3328, 1273],
    11501: [1954, 1866, 907, 2093, 3362, 3362, 1300],
    11601: [1979, 1889, 916, 2120, 3395, 3395, 1326],
    11701: [2004, 1913, 926, 2146, 3426, 3426, 1350],
    11801: [2029, 1940, 936, 2173, 3459, 3459, 1376],
    11901: [2054, 1963, 945, 2200, 3496, 3496, 1406],
    12001: [2079, 1987, 955, 2227, 3536, 3536, 1439],
    12101: [2104, 2010, 956, 2254, 3577, 3577, 1473],
    12201: [2130, 2037, 966, 2281, 3617, 3617, 1506],
    12301: [2155, 2060, 975, 2308, 3657, 3657, 1539],
    12401: [2180, 2084, 985, 2335, 3697, 3697, 1572],
    12501: [2205, 2107, 995, 2362, 3738, 3738, 1606],
    12601: [2230, 2134, 1004, 2389, 3778, 3778, 1639],
    12701: [2256, 2158, 1014, 2416, 3818, 3818, 1672],
    12801: [2281, 2181, 1015, 2443, 3858, 3858, 1705],
    12901: [2306, 2208, 1025, 2470, 3899, 3899, 1739],
    13001: [2331, 2232, 1035, 2497, 3939, 3939, 1772],
    13101: [2357, 2255, 1044, 2524, 3979, 3979, 1805],
    13201: [2382, 2278, 1054, 2551, 4019, 4019, 1838],
    13301: [2407, 2305, 1064, 2578, 4060, 4060, 1872],
    13401: [2432, 2329, 1065, 2606, 4100, 4100, 1905],
    13501: [2457, 2352, 1074, 2633, 4140, 4140, 1938],
    13601: [2483, 2376, 1084, 2660, 4184, 4184, 1975],
    13701: [2508, 2403, 1094, 2687, 4231, 4231, 2015],
    13801: [2533, 2426, 1103, 2714, 4274, 4274, 2051],
    13901: [2559, 2450, 1113, 2741, 4318, 4318, 2088],
    14001: [2584, 2473, 1114, 2768, 4361, 4361, 2124],
    14101: [2612, 2503, 1124, 2798, 4405, 4405, 2161],
    14201: [2647, 2537, 1134, 2832, 4450, 4450, 2199],
    14301: [2681, 2574, 1145, 2866, 4493, 4493, 2235],
    14401: [2715, 2607, 1155, 2901, 4535, 4535, 2270],
    14501: [2750, 2641, 1165, 2935, 4578, 4578, 2306],
    14601: [2784, 2678, 1167, 2969, 4620, 4620, 2341],
    14701: [2818, 2711, 1177, 3004, 4666, 4666, 2380],
    14801: [2853, 2748, 1187, 3038, 4709, 4709, 2416],
    14901: [2887, 2782, 1197, 3072, 4751, 4751, 2451],
    15001: [2921, 2815, 1208, 3107, 4794, 4794, 2487],
    15101: [2956, 2852, 1218, 3141, 4836, 4836, 2522],
    15201: [2990, 2886, 1228, 3175, 4882, 4882, 2561],
    15301: [3024, 2922, 1230, 3210, 4925, 4925, 2597],
    15401: [3059, 2956, 1240, 3244, 4967, 4967, 2632],
    15501: [3093, 2990, 1250, 3278, 5010, 5010, 2668],
    15601: [3127, 3026, 1261, 3312, 5052, 5052, 2703],
    15701: [3162, 3060, 1271, 3347, 5098, 5098, 2742],
    15801: [3196, 3097, 1281, 3381, 5140, 5140, 2777],
    15901: [3230, 3130, 1283, 3415, 5183, 5183, 2813],
    16001: [3265, 3164, 1293, 3450, 5226, 5226, 2849],
    16101: [3299, 3201, 1303, 3484, 5268, 5268, 2884],
    16201: [3333, 3234, 1313, 3518, 5314, 5314, 2923],
    16301: [3368, 3271, 1324, 3553, 5356, 5356, 2958],
    16401: [3402, 3305, 1334, 3587, 5399, 5399, 2994],
    16501: [3436, 3338, 1336, 3621, 5441, 5441, 3029],
    16601: [3470, 3375, 1346, 3656, 5484, 5484, 3065],
    16701: [3505, 3409, 1356, 3690, 5530, 5530, 3104],
    16801: [3539, 3446, 1366, 3724, 5572, 5572, 3139],
    16901: [3573, 3479, 1377, 3759, 5615, 5615, 3175],
    17001: [3608, 3513, 1387, 3793, 5657, 5657, 3210],
    17101: [3642, 3550, 1389, 3827, 5700, 5700, 3246],
    17201: [3676, 3583, 1399, 3862, 5746, 5746, 3285],
    17301: [3711, 3620, 1409, 3896, 5788, 5788, 3320],
    17401: [3745, 3654, 1419, 3930, 5831, 5831, 3356],
    17501: [3779, 3687, 1429, 3965, 5873, 5873, 3391],
    17601: [3814, 3724, 1440, 3999, 5916, 5916, 3427],
    17701: [3848, 3758, 1450, 4033, 5961, 5961, 3465],
    17801: [3882, 3795, 1452, 4068, 6004, 6004, 3501],
    17901: [3917, 3828, 1462, 4102, 6046, 6046, 3536],
    18001: [3951, 3862, 1472, 4136, 6089, 6089, 3572],
    18101: [3985, 3899, 1482, 4170, 6132, 6132, 3608],
    18201: [4020, 3932, 1493, 4205, 6177, 6177, 3646],
    18301: [4054, 3969, 1503, 4239, 6220, 6220, 3682],
    18401: [4088, 4003, 1505, 4273, 6262, 6262, 3717],
    18501: [4123, 4036, 1515, 4308, 6305, 6305, 3753],
    18601: [4157, 4073, 1525, 4342, 6347, 6347, 3788],
    18701: [4191, 4107, 1541, 4376, 6393, 6393, 3827],
    18801: [4226, 4143, 1570, 4411, 6436, 6436, 3863],
    18901: [4260, 4177, 1597, 4445, 6478, 6478, 3898],
    19001: [4294, 4214, 1627, 4479, 6521, 6521, 3934],
    19101: [4328, 4247, 1653, 4514, 6563, 6563, 3969],
    19201: [4363, 4281, 1680, 4548, 6609, 6609, 4008],
    19301: [4397, 4318, 1710, 4582, 6652, 6652, 4044],
    19401: [4431, 4351, 1736, 4617, 6694, 6694, 4079],
    19501: [4466, 4388, 1766, 4651, 6737, 6737, 4115],
    19601: [4500, 4421, 1792, 4685, 6779, 6779, 4150],
    19701: [4534, 4454, 1818, 4720, 6825, 6825, 4189],
    19801: [4569, 4490, 1847, 4754, 6867, 6867, 4224],
    19901: [4603, 4522, 1872, 4788, 6910, 6910, 4260],
    20001: [4672, 4591, 1927, 4857, 6995, 6995, 4331],
    20201: [4740, 4660, 1982, 4926, 7083, 7083, 4405],
    20401: [4809, 4728, 2036, 4994, 7168, 7168, 4476],
    20601: [4878, 4794, 2088, 5063, 7257, 7257, 4551],
    20801: [4946, 4862, 2142, 5131, 7342, 7342, 4622],
    21001: [5015, 4931, 2197, 5200, 7427, 7427, 4693],
    21201: [5084, 5000, 2252, 5269, 7515, 7515, 4767],
    21401: [5152, 5069, 2307, 5337, 7600, 7600, 4838],
    21601: [5221, 5134, 2358, 5406, 7688, 7688, 4912],
    21801: [5289, 5203, 2413, 5475, 7773, 7773, 4983],
    22001: [5358, 5271, 2467, 5543, 7859, 7859, 5055],
    22201: [5427, 5340, 2522, 5612, 7947, 7947, 5129],
    22401: [5496, 5409, 2577, 5681, 8033, 8033, 5201],
    22601: [5567, 5474, 2628, 5752, 8123, 8123, 5277],
    22801: [5637, 5543, 2683, 5822, 8209, 8209, 5349],
    23001: [5707, 5612, 2738, 5892, 8296, 8296, 5422],
    23201: [5777, 5680, 2792, 5963, 8386, 8386, 5498],
    23401: [5848, 5749, 2847, 6033, 8473, 8473, 5571],
    23601: [5918, 5818, 2902, 6103, 8563, 8563, 5647],
    23801: [5988, 5883, 2953, 6174, 8650, 8650, 5720],
    24001: [6059, 5952, 3008, 6244, 8736, 8736, 5792],
    24201: [6129, 6024, 3066, 6314, 8826, 8826, 5868],
    24401: [6199, 6092, 3120, 6384, 8913, 8913, 5941],
    24601: [6269, 6161, 3175, 6455, 9003, 9003, 6017],
    24801: [6340, 6230, 3230, 6525, 9089, 9089, 6089],
    25001: [6410, 6302, 3302, 6595, 9176, 9176, 6176],
    25201: [6480, 6370, 3370, 6666, 9266, 9266, 6266],
    25401: [6551, 6439, 3439, 6736, 9353, 9353, 6353],
    25601: [6621, 6511, 3511, 6806, 9443, 9443, 6443],
    25801: [6691, 6580, 3580, 6876, 9529, 9529, 6529],
    26001: [6761, 6648, 3648, 6947, 9616, 9616, 6616],
    26201: [6832, 6720, 3720, 7017, 9706, 9706, 6706],
    26401: [6902, 6789, 3789, 7087, 9793, 9793, 6793],
    26601: [6972, 6858, 3858, 7158, 9883, 9883, 6883],
    26801: [7043, 6930, 3930, 7228, 9969, 9969, 6969],
    27001: [7113, 6998, 3998, 7298, 10056, 10056, 7056],
    27201: [7183, 7067, 4067, 7368, 10146, 10146, 7146],
    27401: [7254, 7139, 4139, 7439, 10233, 10233, 7233],
    27601: [7324, 7208, 4208, 7509, 10323, 10323, 7323],
    27801: [7394, 7276, 4276, 7579, 10409, 10409, 7409],
    28001: [7464, 7345, 4345, 7650, 10496, 10496, 7496],
    28201: [7535, 7417, 4417, 7720, 10586, 10586, 7586],
    28401: [7605, 7486, 4486, 7790, 10673, 10673, 7673],
    28601: [7675, 7554, 4554, 7860, 10763, 10763, 7763],
    28801: [7745, 7626, 4626, 7931, 10849, 10849, 7849],
    29001: [7816, 7695, 4695, 8001, 10936, 10936, 7936],
    29201: [7886, 7764, 4764, 8071, 11026, 11026, 8026],
    29401: [7956, 7836, 4836, 8142, 11113, 11113, 8113],
    29601: [8027, 7904, 4904, 8212, 11203, 11203, 8203],
    29801: [8097, 7973, 4973, 8282, 11289, 11289, 8289],
    30001: [8167, 8046, 5046, 8352, 11376, 11376, 8376],
    30201: [8238, 8116, 5116, 8423, 11466, 11466, 8466],
    30401: [8308, 8186, 5186, 8493, 11553, 11553, 8553],
    30601: [8378, 8256, 5256, 8563, 11643, 11643, 8643],
    30801: [8448, 8329, 5329, 8634, 11729, 11729, 8729],
    31001: [8519, 8400, 5400, 8704, 11816, 11816, 8816],
    31201: [8589, 8469, 5469, 8774, 11906, 11906, 8906],
    31401: [8659, 8543, 5543, 8844, 11993, 11993, 8993],
    31601: [8730, 8613, 5613, 8915, 12083, 12083, 9083],
    31801: [8800, 8683, 5683, 8985, 12169, 12169, 9169],
    32001: [8870, 8756, 5756, 9055, 12256, 12256, 9256],
    32201: [8940, 8826, 5826, 9126, 12346, 12346, 9346],
    32401: [9011, 8896, 5896, 9196, 12433, 12433, 9433],
    32601: [9081, 8969, 5969, 9266, 12523, 12523, 9523],
    32801: [9151, 9039, 6039, 9336, 12609, 12609, 9609],
    33001: [9222, 9109, 6109, 9407, 12696, 12696, 9696],
    33201: [9292, 9183, 6183, 9477, 12786, 12786, 9786],
    33401: [9362, 9253, 6253, 9547, 12873, 12873, 9873],
    33601: [9432, 9323, 6323, 9618, 12963, 12963, 9963],
    33801: [9503, 9393, 6393, 9688, 13049, 13049, 10049],
    34001: [9573, 9466, 6466, 9758, 13136, 13136, 10136],
    34201: [9643, 9536, 6536, 9829, 13226, 13226, 10226],
    34401: [9713, 9606, 6606, 9899, 13309, 13309, 10309],
    34601: [9784, 9676, 6676, 9969, 13389, 13389, 10389],
    34801: [9854, 9743, 6743, 10039, 13469, 13469, 10469],
    35001: [9924, 9813, 6813, 10109, 13549, 13549, 10549],
    35201: [9995, 9886, 6886, 10179, 13629, 13629, 10629],
    35401: [10075, 9966, 6966, 10249, 13709, 13709, 10709],
    35601: [10155, 10046, 7046, 10319, 13789, 13789, 10789],
    35801: [10235, 10126, 7126, 10389, 13869, 13869, 10869],
    36001: [10315, 10206, 7206, 10459, 13949, 13949, 10949],
    36201: [10395, 10286, 7286, 10529, 14029, 14029, 11029],
    36401: [10475, 10366, 7366, 10599, 14109, 14109, 11109],
    36601: [10555, 10446, 7446, 10669, 14189, 14189, 11189],
    36801: [10635, 10526, 7526, 10739, 14269, 14269, 11269],
    37001: [10715, 10606, 7606, 10809, 14349, 14349, 11349],
    37201: [10795, 10686, 7686, 10879, 14429, 14429, 11429],
    37401: [10875, 10766, 7766, 10950, 14509, 14509, 11509],
    37601: [10955, 10846, 7846, 11020, 14589, 14589, 11589],
    37801: [11035, 10926, 7926, 11090, 14669, 14669, 11669],
    38001: [11115, 11006, 8006, 11160, 14749, 14749, 11749],
    38201: [11195, 11086, 8086, 11230, 14829, 14829, 11829],
    38401: [11275, 11166, 8166, 11300, 14909, 14909, 11909],
    38601: [11355, 11246, 8246, 11370, 14989, 14989, 11989],
    38801: [11435, 11326, 8326, 11440, 15069, 15069, 12069],
    39001: [11515, 11406, 8406, 11510, 15150, 15150, 12150],
    39201: [11595, 11486, 8486, 11580, 15229, 15229, 12229],
    39401: [11675, 11566, 8566, 11650, 15309, 15309, 12309],
    39601: [11755, 11646, 8646, 11720, 15389, 15389, 12389],
    39801: [11835, 11726, 8726, 11790, 15469, 15469, 12469],
    40001: [11915, 11806, 8806, 11860, 15549, 15549, 12549],
    40201: [11995, 11886, 8886, 11930, 15629, 15629, 12629],
    40401: [12075, 11966, 8966, 12000, 15709, 15709, 12709],
    40601: [12155, 12046, 9046, 12070, 15789, 15789, 12789],
    40801: [12235, 12126, 9126, 12141, 15869, 15869, 12869],
    41001: [12315, 12206, 9206, 12211, 15949, 15949, 12949],
    41201: [12395, 12286, 9286, 12281, 16029, 16029, 13029],
    41401: [12475, 12366, 9366, 12351, 16109, 16109, 13109],
    41601: [12555, 12446, 9446, 12421, 16189, 16189, 13189],
    41801: [12635, 12526, 9526, 12491, 16269, 16269, 13269],
    42001: [12715, 12606, 9606, 12561, 16349, 16349, 13349],
    42201: [12795, 12686, 9686, 12631, 16429, 16429, 13429],
    42401: [12875, 12766, 9766, 12701, 16509, 16509, 13509],
    42601: [12955, 12846, 9846, 12771, 16589, 16589, 13589],
    42801: [13035, 12926, 9926, 12841, 16669, 16669, 13669],
    43001: [13115, 13006, 10006, 12911, 16749, 16749, 13749],
    43201: [13195, 13086, 10086, 12981, 16829, 16829, 13829],
    43401: [13275, 13166, 10166, 13051, 16909, 16909, 13909],
    43601: [13355, 13246, 10246, 13121, 16989, 16989, 13989],
    43801: [13435, 13326, 10326, 13191, 17069, 17069, 14069],
    44001: [13515, 13406, 10406, 13262, 17150, 17150, 14150],
    44201: [13595, 13486, 10486, 13331, 17229, 17229, 14229],
    44401: [13675, 13566, 10566, 13402, 17309, 17309, 14309],
    44601: [13755, 13646, 10646, 13472, 17389, 17389, 14389],
    44801: [13835, 13726, 10726, 13542, 17469, 17469, 14469],
    45001: [13915, 13806, 10806, 13612, 17549, 17549, 14549],
    45201: [13995, 13886, 10886, 13682, 17629, 17629, 14629],
    45401: [14075, 13966, 10966, 13752, 17709, 17709, 14709],
    45601: [14155, 14046, 11046, 13822, 17789, 17789, 14789],
    45801: [14235, 14126, 11126, 13892, 17869, 17869, 14869],
    46001: [14315, 14206, 11206, 13962, 17949, 17949, 14949],
    46201: [14395, 14286, 11286, 14032, 18029, 18029, 15029],
    46401: [14475, 14366, 11366, 14102, 18109, 18109, 15109],
    46601: [14555, 14446, 11446, 14172, 18189, 18189, 15189],
    46801: [14635, 14526, 11526, 14242, 18269, 18269, 15269],
    47001: [14715, 14606, 11606, 14312, 18349, 18349, 15349],
    47201: [14795, 14686, 11686, 14382, 18429, 18429, 15429],
    47401: [14875, 14766, 11766, 14452, 18509, 18509, 15509],
    47601: [14955, 14846, 11846, 14522, 18589, 18589, 15589],
    47801: [15035, 14926, 11926, 14593, 18669, 18669, 15669],
    48001: [15115, 15006, 12006, 14663, 18749, 18749, 15749],
    48201: [15195, 15086, 12086, 14733, 18829, 18829, 15829],
    48401: [15275, 15166, 12166, 14803, 18909, 18909, 15909],
    48601: [15355, 15246, 12246, 14873, 18989, 18989, 15989],
    48801: [15435, 15326, 12326, 14943, 19069, 19069, 16069],
    49001: [15515, 15406, 12406, 15013, 19150, 19150, 16150],
    49201: [15595, 15486, 12486, 15083, 19229, 19229, 16229],
    49401: [15675, 15566, 12566, 15153, 19309, 19309, 16309],
    49601: [15755, 15646, 12646, 15223, 19389, 19389, 16389],
    49801: [15835, 15726, 12726, 15293, 19469, 19469, 16469],
    50001: [15915, 15806, 12812, 15363, 19549, 19549, 16555],
    50201: [15995, 15929, 12941, 15433, 19629, 19629, 16641],
    50401: [16075, 16056, 13074, 15503, 19709, 19709, 16727],
    50601: [16155, 16186, 13210, 15573, 19789, 19789, 16813],
    50801: [16235, 16316, 13346, 15643, 19869, 19869, 16899],
    51001: [16324, 16446, 13482, 15722, 19958, 19958, 16994],
    51201: [16444, 16576, 13618, 15832, 20078, 20078, 17120],
    51401: [16564, 16706, 13754, 15942, 20198, 20198, 17246],
    51601: [16684, 16836, 13890, 16052, 20318, 20318, 17372],
    51801: [16804, 16966, 14026, 16162, 20438, 20438, 17498],
    52001: [16924, 17093, 14159, 16272, 20558, 20558, 17624],
    52201: [17044, 17223, 14295, 16382, 20678, 20678, 17750],
    52401: [17164, 17353, 14431, 16492, 20798, 20798, 17876],
    52601: [17284, 17483, 14567, 16602, 20918, 20918, 18002],
    52801: [17404, 17613, 14703, 16712, 21038, 21038, 18128],
    53001: [17524, 17743, 14839, 16822, 21158, 21158, 18254],
    53201: [17644, 17873, 14975, 16932, 21278, 21278, 18380],
    53401: [17764, 18003, 15111, 17042, 21398, 21398, 18506],
    53601: [17884, 18129, 15243, 17152, 21518, 21518, 18632],
    53801: [18004, 18259, 15379, 17263, 21638, 21638, 18758],
    54001: [18124, 18389, 15515, 17373, 21758, 21758, 18884],
    54201: [18244, 18519, 15651, 17483, 21878, 21878, 19010],
    54401: [18364, 18650, 15788, 17593, 21998, 21998, 19136],
    54601: [18484, 18779, 15923, 17703, 22118, 22118, 19262],
    54801: [18604, 18909, 16059, 17813, 22238, 22238, 19388],
    55001: [18724, 19039, 16195, 17923, 22358, 22358, 19514],
    55201: [18844, 19169, 16331, 18033, 22478, 22478, 19640],
    55401: [18964, 19296, 16464, 18143, 22598, 22598, 19766],
    55601: [19084, 19426, 16600, 18253, 22718, 22718, 19892],
    55801: [19204, 19556, 16736, 18363, 22838, 22838, 20018],
    56001: [19324, 19639, 16825, 18473, 22958, 22958, 20144],
    56201: [19444, 19719, 16911, 18583, 23078, 23078, 20270],
    56401: [19564, 19799, 16997, 18693, 23198, 23198, 20396],
    56601: [19684, 19879, 17083, 18803, 23318, 23318, 20522],
    56801: [19804, 19973, 17183, 18913, 23438, 23438, 20648],
    57001: [19924, 20093, 17309, 19023, 23558, 23558, 20774],
    57201: [20044, 20213, 17435, 19134, 23678, 23678, 20900],
    57401: [20164, 20333, 17561, 19244, 23798, 23798, 21026],
    57601: [20284, 20453, 17687, 19354, 23918, 23918, 21152],
    57801: [20404, 20573, 17813, 19464, 24038, 24038, 21278],
    58001: [20524, 20693, 17939, 19574, 24158, 24158, 21404],
    58201: [20644, 20813, 18065, 19684, 24278, 24278, 21530],
    58401: [20764, 20933, 18191, 19794, 24398, 24398, 21656],
    58601: [20884, 21053, 18317, 19904, 24518, 24518, 21782],
    58801: [21004, 21173, 18443, 20014, 24638, 24638, 21908],
    59001: [21124, 21293, 18569, 20124, 24758, 24758, 22034],
    59201: [21249, 21418, 18700, 20234, 24878, 24878, 22160],
    59401: [21375, 21543, 18831, 20344, 24998, 24998, 22286],
    59601: [21501, 21673, 18967, 20454, 25118, 25118, 22412],
    59801: [21627, 21798, 19098, 20564, 25238, 25238, 22538],
    60001: [21753, 21923, 19229, 20674, 25358, 25358, 22664],
    60201: [21879, 22053, 19365, 20784, 25478, 25478, 22790],
    60401: [22005, 22178, 19496, 20894, 25598, 25598, 22916],
    60601: [22131, 22308, 19632, 21004, 25718, 25718, 23042],
    60801: [22257, 22433, 19763, 21115, 25838, 25838, 23168],
    61001: [22383, 22558, 19894, 21225, 25958, 25958, 23294],
    61201: [22509, 22688, 20030, 21335, 26078, 26078, 23420],
    61401: [22635, 22813, 20161, 21445, 26198, 26198, 23546],
    61601: [22761, 22938, 20292, 21555, 26318, 26318, 23672],
    61801: [22887, 23068, 20428, 21665, 26438, 26438, 23798],
    62001: [23013, 23193, 20559, 21775, 26558, 26558, 23924],
    62201: [23139, 23323, 20695, 21885, 26678, 26678, 24050],
    62401: [23265, 23448, 20826, 21995, 26798, 26798, 24176],
    62601: [23391, 23573, 20957, 22105, 26918, 26918, 24302],
    62801: [23517, 23703, 21093, 22215, 27038, 27038, 24428],
    63001: [23643, 23828, 21224, 22325, 27158, 27158, 24554],
    63201: [23769, 23953, 21355, 22435, 27278, 27278, 24680],
    63401: [23895, 24083, 21491, 22545, 27398, 27398, 24806],
    63601: [24021, 24208, 21622, 22655, 27518, 27518, 24932],
    63801: [24147, 24338, 21758, 22765, 27638, 27638, 25058],
    64001: [24273, 24463, 21889, 22876, 27758, 27758, 25184],
    64201: [24399, 24588, 22020, 22986, 27878, 27878, 25310],
    64401: [24525, 24718, 22156, 23096, 27998, 27998, 25436],
    64601: [24651, 24843, 22287, 23206, 28118, 28118, 25562],
    64801: [24777, 24968, 22418, 23316, 28238, 28238, 25688],
    65001: [24903, 25098, 22554, 23426, 28358, 28358, 25814],
    65201: [25029, 25223, 22685, 23536, 28478, 28478, 25940],
    65401: [25155, 25353, 22821, 23646, 28598, 28598, 26066],
    65601: [25281, 25478, 22952, 23756, 28718, 28718, 26192],
    65801: [25407, 25603, 23083, 23866, 28838, 28838, 26318],
    66001: [25533, 25733, 23219, 23976, 28958, 28958, 26444],
    66201: [25659, 25858, 23350, 24086, 29078, 29078, 26570],
    66401: [25785, 25983, 23481, 24196, 29198, 29198, 26696],
    66601: [25911, 26113, 23617, 24306, 29318, 29318, 26822],
    66801: [26037, 26238, 23748, 24416, 29438, 29438, 26948],
    67001: [26163, 26368, 23884, 24526, 29558, 29558, 27074],
    67201: [26289, 26493, 24015, 24636, 29678, 29678, 27200],
    67401: [26415, 26618, 24146, 24746, 29798, 29798, 27326],
    67601: [26541, 26748, 24282, 24856, 29918, 29918, 27452],
    67801: [26667, 26873, 24413, 24967, 30038, 30038, 27578],
    68001: [26793, 27003, 24549, 25077, 30158, 30158, 27704],
    68201: [26919, 27128, 24680, 25187, 30278, 30278, 27830],
    68401: [27045, 27253, 24811, 25297, 30398, 30398, 27956],
    68601: [27171, 27383, 24947, 25407, 30518, 30518, 28082],
    68801: [27297, 27508, 25078, 25517, 30638, 30638, 28208],
    69001: [27423, 27633, 25209, 25627, 30758, 30758, 28334],
    69201: [27549, 27763, 25345, 25737, 30878, 30878, 28460],
    69401: [27675, 27888, 25476, 25847, 30998, 30998, 28586],
    69601: [27801, 28018, 25612, 25957, 31118, 31118, 28712],
    69801: [27927, 28143, 25743, 26067, 31238, 31238, 28838],
    70001: [28053, 28268, 25874, 26177, 31358, 31358, 28964],
    70201: [28179, 28398, 26010, 26287, 31478, 31478, 29090],
    70401: [28305, 28523, 26141, 26397, 31598, 31598, 29216],
    70601: [28431, 28648, 26272, 26507, 31718, 31718, 29342],
    70801: [28557, 28778, 26408, 26617, 31838, 31838, 29468],
    71001: [28683, 28903, 26539, 26727, 31958, 31958, 29594],
    71201: [28809, 29033, 26675, 26838, 32078, 32078, 29720],
    71401: [28935, 29158, 26806, 26948, 32198, 32198, 29846],
    71601: [29061, 29283, 26937, 27058, 32318, 32318, 29972],
    71801: [29187, 29413, 27073, 27168, 32438, 32438, 30098],
    72001: [29313, 29538, 27204, 27278, 32558, 32558, 30224],
    72201: [29439, 29663, 27335, 27388, 32678, 32678, 30350],
    72401: [29565, 29793, 27471, 27498, 32798, 32798, 30476],
    72601: [29691, 29918, 27602, 27608, 32918, 32918, 30602],
    72801: [29817, 30048, 27738, 27718, 33038, 33038, 30728],
    73001: [29943, 30173, 27869, 27828, 33158, 33158, 30854],
    73201: [30069, 30298, 28000, 27938, 33278, 33278, 30980],
    73401: [30195, 30428, 28136, 28048, 33398, 33398, 31106],
    73601: [30321, 30553, 28267, 28158, 33518, 33518, 31232],
    73801: [30447, 30678, 28398, 28268, 33638, 33638, 31358],
    74001: [30573, 30808, 28534, 28378, 33758, 33758, 31484],
    74201: [30699, 30933, 28665, 28488, 33878, 33878, 31610],
    74401: [30825, 31063, 28801, 28598, 33998, 33998, 31736],
    74601: [30951, 31188, 28932, 28708, 34118, 34118, 31862],
    74801: [31077, 31313, 29063, 28819, 34238, 34238, 31988],
    75001: [31203, 31443, 29199, 28929, 34358, 34358, 32114],
    75201: [31329, 31568, 29330, 29039, 34478, 34478, 32240],
    75401: [31455, 31693, 29461, 29149, 34598, 34598, 32366],
    75601: [31581, 31823, 29597, 29259, 34718, 34718, 32492],
    75801: [31707, 31948, 29728, 29369, 34838, 34838, 32618],
    76001: [31833, 32078, 29864, 29479, 34958, 34958, 32744],
    76201: [31959, 32203, 29995, 29589, 35078, 35078, 32870],
    76401: [32085, 32328, 30126, 29699, 35198, 35198, 32996],
    76601: [32211, 32458, 30262, 29809, 35318, 35318, 33122],
    76801: [32337, 32583, 30393, 29919, 35438, 35438, 33248],
    77001: [32463, 32713, 30529, 30029, 35558, 35558, 33374],
    77201: [32589, 32838, 30660, 30139, 35678, 35678, 33500],
    77401: [32715, 32963, 30791, 30249, 35798, 35798, 33626],
    77601: [32841, 33093, 30927, 30359, 35918, 35918, 33752],
    77801: [32967, 33218, 31058, 30469, 36038, 36038, 33878],
    78001: [33093, 33343, 31189, 30579, 36158, 36158, 34004],
    78201: [33219, 33473, 31325, 30689, 36278, 36278, 34130],
    78401: [33345, 33598, 31456, 30800, 36398, 36398, 34256],
    78601: [33471, 33728, 31592, 30910, 36518, 36518, 34382],
    78801: [33597, 33853, 31723, 31020, 36638, 36638, 34508],
    79001: [33723, 33978, 31854, 31130, 36758, 36758, 34634],
    79201: [33849, 34108, 31990, 31240, 36878, 36878, 34760],
    79401: [33975, 34233, 32121, 31350, 36998, 36998, 34886],
    79601: [34101, 34358, 32252, 31460, 37118, 37118, 35012],
    79801: [34227, 34488, 32388, 31570, 37238, 37238, 35138],
    80001: [43, 43, 41, 40, 47, 47, 44, '%'],
    81401: [43, 44, 41, 40, 47, 47, 44, '%'],
    82401: [43, 44, 41, 40, 47, 47, 45, '%'],
    82801: [44, 44, 41, 40, 47, 47, 45, '%'],
    83201: [44, 44, 42, 40, 47, 47, 45, '%'],
    85601: [44, 44, 42, 41, 47, 47, 45, '%'],
    85801: [44, 45, 42, 41, 47, 47, 45, '%'],
    86001: [44, 45, 42, 41, 48, 48, 45, '%'],
    86601: [44, 45, 43, 41, 48, 48, 45, '%'],
    87201: [44, 45, 43, 41, 48, 48, 46, '%'],
    87401: [45, 45, 43, 41, 48, 48, 46, '%'],
    90401: [45, 45, 44, 41, 48, 48, 46, '%'],
    90601: [45, 46, 44, 41, 48, 48, 46, '%'],
    92001: [45, 46, 44, 42, 48, 48, 46, '%'],
    92401: [46, 46, 44, 42, 48, 48, 47, '%'],
    93401: [46, 46, 44, 42, 49, 49, 47, '%'],
    94601: [46, 46, 45, 42, 49, 49, 47, '%'],
    96001: [46, 47, 45, 42, 49, 49, 47, '%'],
    98001: [47, 47, 45, 42, 49, 49, 47, '%'],
    98401: [47, 47, 45, 42, 49, 49, 48, '%'],
    99001: [47, 47, 46, 42, 49, 49, 48, '%'],
    99201: [47, 47, 46, 43, 49, 49, 48, '%'],
    102001: [47, 48, 46, 43, 49, 49, 48, '%'],
    102401: [47, 48, 46, 43, 50, 50, 48, '%'],
    104001: [47, 48, 47, 43, 50, 50, 48, '%'],
    104201: [48, 48, 47, 43, 50, 50, 48, '%'],
    105201: [48, 48, 47, 43, 50, 50, 49, '%'],
    108001: [48, 48, 47, 44, 50, 50, 49, '%'],
    108801: [48, 49, 47, 44, 50, 50, 49, '%'],
    109601: [48, 49, 48, 44, 50, 50, 49, '%'],
    111401: [49, 49, 48, 44, 50, 50, 49, '%'],
    113001: [49, 49, 48, 44, 50, 50, 50, '%'],
    113201: [49, 49, 48, 44, 51, 51, 50, '%'],
    115601: [49, 49, 49, 44, 51, 51, 50, '%'],
    116601: [49, 50, 49, 44, 51, 51, 50, '%'],
    118201: [49, 50, 49, 45, 51, 51, 50, '%'],
    119601: [50, 50, 49, 45, 51, 51, 50, '%'],
    122001: [50, 50, 49, 45, 51, 51, 51, '%'],
    122401: [50, 50, 50, 45, 51, 51, 51, '%'],
    125601: [50, 51, 50, 45, 51, 51, 51, '%'],
    126601: [50, 51, 50, 45, 52, 52, 51, '%'],
    129201: [51, 51, 50, 45, 52, 52, 51, '%'],
    130201: [51, 51, 51, 45, 52, 52, 51, '%'],
    130601: [51, 51, 51, 46, 52, 52, 51, '%'],
    132601: [51, 51, 51, 46, 52, 52, 52, '%'],
    136001: [51, 52, 51, 46, 52, 52, 52, '%'],
    138801: [51, 52, 52, 46, 52, 52, 52, '%'],
    140601: [52, 52, 52, 46, 52, 52, 52, '%'],
    143401: [52, 52, 52, 46, 53, 53, 52, '%'],
    145201: [52, 52, 52, 46, 53, 53, 53, '%'],
    146001: [52, 52, 52, 47, 53, 53, 53, '%'],
    148601: [52, 53, 52, 47, 53, 53, 53, '%'],
    148801: [52, 53, 53, 47, 53, 53, 53, '%'],
    154001: [53, 53, 53, 47, 53, 53, 53, '%'],
    165401: [53, 54, 54, 48, 54, 54, 54, '%'],
    170201: [54, 54, 54, 48, 54, 54, 54, '%'],
    190601: [54, 54, 54, 49, 54, 54, 54, '%'],
    195601: [55, 55, 55, 49, 55, 55, 55, '%'],
    225201: [55, 55, 55, 50, 55, 55, 55, '%'],
    239001: [56, 56, 56, 50, 56, 56, 56, '%'],
    274801: [56, 56, 56, 51, 56, 56, 56, '%'],
    307401: [57, 57, 57, 51, 57, 57, 57, '%'],
    352801: [57, 57, 57, 52, 57, 57, 57, '%'],
    430401: [58, 58, 58, 52, 58, 58, 58, '%'],
    492401: [58, 58, 58, 53, 58, 58, 58, '%'],
    717401: [59, 59, 59, 53, 59, 59, 59, '%'],
    814601: [59, 59, 59, 54, 59, 59, 59, '%']
  },
  41: {
    1: [0, 0, 0, 0, 0, 0, 0],
    1801: [134, 0, 133, 0, 134, 17, 134],
    1901: [144, 0, 141, 3, 144, 59, 144],
    2001: [155, 0, 150, 5, 155, 101, 155],
    2101: [157, 0, 150, 7, 157, 143, 157],
    2201: [167, 0, 158, 9, 185, 185, 167],
    2301: [178, 0, 166, 11, 227, 227, 178],
    2401: [188, 0, 175, 13, 269, 269, 188],
    2501: [199, 0, 183, 16, 311, 311, 199],
    2601: [209, 0, 191, 18, 353, 353, 209],
    2701: [220, 0, 200, 20, 395, 395, 220],
    2801: [222, 0, 200, 22, 437, 437, 222],
    2901: [232, 0, 208, 24, 479, 479, 232],
    3001: [243, 0, 216, 26, 521, 521, 243],
    3101: [253, 0, 225, 28, 563, 563, 253],
    3201: [264, 0, 233, 31, 605, 605, 264],
    3301: [274, 0, 241, 33, 647, 647, 274],
    3401: [277, 0, 241, 35, 689, 689, 277],
    3501: [287, 0, 250, 37, 731, 731, 287],
    3601: [298, 0, 258, 39, 773, 773, 298],
    3701: [308, 0, 266, 41, 815, 815, 308],
    3801: [319, 0, 275, 44, 857, 857, 319],
    3901: [329, 0, 283, 51, 899, 899, 329],
    4001: [331, 0, 283, 79, 941, 941, 331],
    4101: [342, 0, 291, 108, 983, 983, 342],
    4201: [352, 0, 300, 136, 1025, 1025, 352],
    4301: [363, 0, 308, 164, 1063, 1063, 363],
    4401: [373, 0, 316, 192, 1095, 1095, 373],
    4501: [383, 0, 325, 220, 1130, 1130, 383],
    4601: [384, 0, 325, 248, 1165, 1165, 384],
    4701: [394, 0, 333, 276, 1196, 1196, 394],
    4801: [405, 10, 342, 304, 1231, 1231, 405],
    4901: [414, 41, 352, 332, 1263, 1263, 414],
    5001: [425, 73, 362, 360, 1298, 1298, 425],
    5101: [435, 104, 372, 388, 1333, 1333, 435],
    5201: [445, 136, 382, 416, 1364, 1364, 445],
    5301: [447, 167, 383, 444, 1400, 1400, 447],
    5401: [456, 199, 393, 472, 1430, 1430, 456],
    5501: [469, 230, 403, 499, 1465, 1465, 467],
    5601: [494, 262, 413, 527, 1499, 1499, 477],
    5701: [520, 293, 423, 554, 1530, 1530, 487],
    5801: [545, 321, 433, 581, 1564, 1564, 497],
    5901: [571, 353, 434, 609, 1595, 1595, 498],
    6001: [596, 384, 444, 636, 1630, 1630, 509],
    6101: [622, 416, 454, 664, 1664, 1664, 519],
    6201: [648, 447, 464, 691, 1695, 1695, 529],
    6301: [673, 479, 474, 718, 1729, 1729, 539],
    6401: [699, 510, 484, 746, 1760, 1760, 549],
    6501: [724, 542, 486, 773, 1795, 1795, 551],
    6601: [750, 573, 496, 801, 1829, 1829, 561],
    6701: [775, 605, 506, 828, 1860, 1860, 571],
    6801: [801, 636, 516, 855, 1894, 1894, 581],
    6901: [826, 664, 525, 883, 1925, 1925, 591],
    7001: [852, 696, 535, 910, 1960, 1960, 601],
    7101: [877, 727, 537, 938, 1994, 1994, 603],
    7201: [903, 759, 547, 965, 2025, 2025, 613],
    7301: [928, 790, 557, 992, 2059, 2059, 623],
    7401: [954, 822, 567, 1020, 2090, 2090, 633],
    7501: [980, 853, 577, 1047, 2125, 2125, 643],
    7601: [1005, 885, 587, 1075, 2159, 2159, 653],
    7701: [1031, 916, 597, 1102, 2190, 2190, 663],
    7801: [1056, 948, 598, 1129, 2224, 2224, 665],
    7901: [1082, 979, 608, 1157, 2255, 2255, 675],
    8001: [1107, 1011, 618, 1184, 2290, 2290, 685],
    8101: [1133, 1039, 628, 1212, 2324, 2324, 695],
    8201: [1158, 1070, 638, 1239, 2355, 2355, 705],
    8301: [1184, 1102, 648, 1266, 2389, 2389, 715],
    8401: [1209, 1133, 649, 1294, 2420, 2420, 717],
    8501: [1235, 1158, 659, 1321, 2455, 2455, 727],
    8601: [1260, 1182, 669, 1349, 2489, 2489, 737],
    8701: [1286, 1210, 678, 1376, 2520, 2520, 747],
    8801: [1311, 1235, 688, 1403, 2554, 2554, 757],
    8901: [1337, 1259, 698, 1431, 2585, 2585, 767],
    9001: [1363, 1284, 699, 1458, 2620, 2620, 769],
    9101: [1388, 1312, 709, 1486, 2654, 2654, 779],
    9201: [1414, 1336, 718, 1513, 2685, 2685, 789],
    9301: [1439, 1361, 728, 1540, 2719, 2719, 811],
    9401: [1465, 1385, 737, 1568, 2750, 2750, 835],
    9501: [1490, 1413, 747, 1595, 2785, 2785, 863],
    9601: [1516, 1437, 748, 1623, 2819, 2819, 890],
    9701: [1541, 1461, 758, 1650, 2850, 2850, 914],
    9801: [1567, 1486, 768, 1677, 2884, 2884, 941],
    9901: [1592, 1513, 777, 1705, 2915, 2915, 965],
    10001: [1618, 1537, 787, 1732, 2950, 2950, 993],
    10101: [1643, 1561, 797, 1760, 2984, 2984, 1020],
    10201: [1669, 1585, 806, 1787, 3015, 3015, 1044],
    10301: [1694, 1613, 808, 1814, 3049, 3049, 1071],
    10401: [1720, 1637, 817, 1842, 3080, 3080, 1095],
    10501: [1746, 1661, 827, 1869, 3115, 3115, 1123],
    10601: [1771, 1685, 836, 1897, 3149, 3149, 1150],
    10701: [1797, 1712, 846, 1924, 3180, 3180, 1174],
    10801: [1822, 1736, 856, 1951, 3214, 3214, 1201],
    10901: [1848, 1760, 857, 1979, 3245, 3245, 1225],
    11001: [1873, 1788, 867, 2006, 3280, 3280, 1253],
    11101: [1899, 1812, 876, 2034, 3314, 3314, 1280],
    11201: [1924, 1836, 886, 2061, 3345, 3345, 1304],
    11301: [1950, 1860, 896, 2088, 3379, 3379, 1331],
    11401: [1975, 1888, 905, 2116, 3410, 3410, 1355],
    11501: [2001, 1912, 907, 2143, 3445, 3445, 1383],
    11601: [2026, 1936, 916, 2171, 3479, 3479, 1410],
    11701: [2052, 1960, 926, 2198, 3510, 3510, 1434],
    11801: [2077, 1987, 936, 2225, 3544, 3544, 1461],
    11901: [2103, 2011, 945, 2253, 3582, 3582, 1492],
    12001: [2129, 2035, 955, 2281, 3624, 3624, 1527],
    12101: [2155, 2059, 956, 2308, 3665, 3665, 1561],
    12201: [2181, 2087, 966, 2336, 3706, 3706, 1595],
    12301: [2206, 2111, 975, 2364, 3747, 3747, 1629],
    12401: [2232, 2135, 985, 2391, 3789, 3789, 1664],
    12501: [2258, 2159, 995, 2419, 3830, 3830, 1698],
    12601: [2284, 2187, 1004, 2447, 3871, 3871, 1732],
    12701: [2310, 2211, 1014, 2474, 3912, 3912, 1766],
    12801: [2336, 2235, 1015, 2502, 3954, 3954, 1801],
    12901: [2361, 2262, 1025, 2530, 3995, 3995, 1835],
    13001: [2387, 2286, 1035, 2558, 4036, 4036, 1869],
    13101: [2413, 2310, 1044, 2585, 4077, 4077, 1903],
    13201: [2439, 2334, 1054, 2613, 4119, 4119, 1938],
    13301: [2465, 2362, 1064, 2641, 4160, 4160, 1972],
    13401: [2490, 2386, 1065, 2668, 4201, 4201, 2006],
    13501: [2516, 2410, 1074, 2696, 4242, 4242, 2040],
    13601: [2542, 2434, 1084, 2724, 4287, 4287, 2078],
    13701: [2568, 2462, 1094, 2752, 4335, 4335, 2119],
    13801: [2594, 2486, 1103, 2779, 4380, 4380, 2157],
    13901: [2620, 2510, 1113, 2807, 4424, 4424, 2194],
    14001: [2646, 2534, 1114, 2835, 4469, 4469, 2232],
    14101: [2675, 2565, 1124, 2865, 4513, 4513, 2269],
    14201: [2710, 2599, 1134, 2900, 4560, 4560, 2309],
    14301: [2746, 2637, 1145, 2936, 4604, 4604, 2346],
    14401: [2781, 2671, 1155, 2971, 4648, 4648, 2383],
    14501: [2816, 2706, 1165, 3006, 4691, 4691, 2419],
    14601: [2851, 2744, 1167, 3041, 4735, 4735, 2456],
    14701: [2886, 2778, 1177, 3076, 4782, 4782, 2496],
    14801: [2922, 2816, 1187, 3112, 4825, 4825, 2532],
    14901: [2957, 2850, 1197, 3147, 4869, 4869, 2569],
    15001: [2992, 2884, 1208, 3182, 4913, 4913, 2606],
    15101: [3027, 2922, 1218, 3217, 4956, 4956, 2642],
    15201: [3062, 2957, 1228, 3252, 5003, 5003, 2682],
    15301: [3098, 2994, 1230, 3288, 5047, 5047, 2719],
    15401: [3133, 3029, 1240, 3323, 5090, 5090, 2755],
    15501: [3168, 3063, 1250, 3358, 5134, 5134, 2792],
    15601: [3203, 3101, 1261, 3393, 5177, 5177, 2828],
    15701: [3238, 3135, 1271, 3428, 5224, 5224, 2868],
    15801: [3273, 3173, 1281, 3463, 5268, 5268, 2905],
    15901: [3309, 3208, 1283, 3499, 5312, 5312, 2942],
    16001: [3344, 3242, 1293, 3534, 5355, 5355, 2978],
    16101: [3379, 3280, 1303, 3569, 5399, 5399, 3015],
    16201: [3414, 3314, 1313, 3604, 5446, 5446, 3055],
    16301: [3449, 3352, 1324, 3639, 5489, 5489, 3091],
    16401: [3485, 3386, 1334, 3675, 5533, 5533, 3128],
    16501: [3520, 3421, 1336, 3710, 5577, 5577, 3165],
    16601: [3555, 3459, 1346, 3745, 5620, 5620, 3201],
    16701: [3590, 3493, 1356, 3780, 5667, 5667, 3241],
    16801: [3625, 3531, 1366, 3815, 5711, 5711, 3278],
    16901: [3661, 3565, 1377, 3851, 5754, 5754, 3314],
    17001: [3696, 3599, 1387, 3886, 5798, 5798, 3351],
    17101: [3731, 3637, 1389, 3921, 5842, 5842, 3388],
    17201: [3766, 3672, 1399, 3956, 5889, 5889, 3428],
    17301: [3801, 3709, 1409, 3991, 5932, 5932, 3464],
    17401: [3837, 3744, 1419, 4027, 5976, 5976, 3501],
    17501: [3872, 3778, 1429, 4062, 6019, 6019, 3537],
    17601: [3907, 3816, 1440, 4097, 6063, 6063, 3574],
    17701: [3942, 3850, 1450, 4132, 6110, 6110, 3614],
    17801: [3977, 3888, 1452, 4167, 6154, 6154, 3651],
    17901: [4012, 3923, 1462, 4202, 6197, 6197, 3687],
    18001: [4048, 3957, 1472, 4238, 6241, 6241, 3724],
    18101: [4083, 3995, 1482, 4273, 6284, 6284, 3760],
    18201: [4118, 4029, 1498, 4308, 6331, 6331, 3800],
    18301: [4153, 4067, 1529, 4343, 6375, 6375, 3837],
    18401: [4188, 4101, 1556, 4378, 6419, 6419, 3874],
    18501: [4224, 4136, 1584, 4414, 6462, 6462, 3910],
    18601: [4259, 4174, 1615, 4449, 6506, 6506, 3947],
    18701: [4294, 4208, 1642, 4484, 6553, 6553, 3987],
    18801: [4329, 4246, 1673, 4519, 6596, 6596, 4023],
    18901: [4364, 4280, 1700, 4554, 6640, 6640, 4060],
    19001: [4400, 4318, 1731, 4590, 6684, 6684, 4097],
    19101: [4435, 4352, 1758, 4625, 6727, 6727, 4133],
    19201: [4470, 4387, 1786, 4660, 6774, 6774, 4173],
    19301: [4505, 4424, 1816, 4695, 6818, 6818, 4210],
    19401: [4540, 4459, 1844, 4730, 6861, 6861, 4246],
    19501: [4576, 4497, 1875, 4765, 6905, 6905, 4283],
    19601: [4611, 4530, 1901, 4801, 6948, 6948, 4319],
    19701: [4646, 4564, 1928, 4836, 6995, 6995, 4359],
    19801: [4681, 4601, 1958, 4871, 7039, 7039, 4396],
    19901: [4716, 4634, 1984, 4906, 7083, 7083, 4433],
    20001: [4787, 4705, 2041, 4977, 7170, 7170, 4506],
    20201: [4857, 4775, 2097, 5047, 7261, 7261, 4583],
    20401: [4927, 4845, 2153, 5117, 7348, 7348, 4656],
    20601: [4998, 4913, 2207, 5188, 7438, 7438, 4732],
    20801: [5068, 4983, 2263, 5258, 7525, 7525, 4805],
    21001: [5139, 5053, 2319, 5329, 7613, 7613, 4879],
    21201: [5209, 5124, 2376, 5399, 7703, 7703, 4955],
    21401: [5279, 5194, 2432, 5469, 7790, 7790, 5028],
    21601: [5350, 5261, 2485, 5540, 7881, 7881, 5105],
    21801: [5420, 5332, 2542, 5610, 7968, 7968, 5178],
    22001: [5491, 5402, 2598, 5680, 8055, 8055, 5251],
    22201: [5561, 5473, 2655, 5751, 8146, 8146, 5328],
    22401: [5632, 5543, 2711, 5822, 8234, 8234, 5402],
    22601: [5704, 5610, 2764, 5894, 8326, 8326, 5480],
    22801: [5776, 5681, 2821, 5966, 8415, 8415, 5555],
    23001: [5848, 5751, 2877, 6038, 8504, 8504, 5630],
    23201: [5920, 5821, 2933, 6110, 8596, 8596, 5708],
    23401: [5992, 5892, 2990, 6182, 8685, 8685, 5783],
    23601: [6064, 5962, 3046, 6254, 8777, 8777, 5861],
    23801: [6137, 6029, 3099, 6326, 8866, 8866, 5936],
    24001: [6208, 6100, 3156, 6398, 8955, 8955, 6011],
    24201: [6281, 6174, 3216, 6470, 9047, 9047, 6089],
    24401: [6353, 6244, 3272, 6542, 9136, 9136, 6164],
    24601: [6425, 6315, 3329, 6615, 9228, 9228, 6242],
    24801: [6497, 6385, 3385, 6687, 9317, 9317, 6317],
    25001: [6569, 6459, 3459, 6759, 9406, 9406, 6406],
    25201: [6641, 6529, 3529, 6831, 9498, 9498, 6498],
    25401: [6713, 6600, 3600, 6903, 9587, 9587, 6587],
    25601: [6785, 6674, 3674, 6975, 9679, 9679, 6679],
    25801: [6857, 6744, 3744, 7047, 9768, 9768, 6768],
    26001: [6929, 6814, 3814, 7119, 9857, 9857, 6857],
    26201: [7001, 6888, 3888, 7191, 9949, 9949, 6949],
    26401: [7073, 6959, 3959, 7263, 10038, 10038, 7038],
    26601: [7145, 7029, 4029, 7335, 10130, 10130, 7130],
    26801: [7217, 7103, 4103, 7407, 10219, 10219, 7219],
    27001: [7289, 7173, 4173, 7479, 10308, 10308, 7308],
    27201: [7361, 7244, 4244, 7551, 10400, 10400, 7400],
    27401: [7433, 7317, 4317, 7623, 10489, 10489, 7489],
    27601: [7505, 7388, 4388, 7695, 10581, 10581, 7581],
    27801: [7577, 7458, 4458, 7767, 10670, 10670, 7670],
    28001: [7649, 7529, 4529, 7839, 10759, 10759, 7759],
    28201: [7721, 7603, 4603, 7911, 10851, 10851, 7851],
    28401: [7793, 7673, 4673, 7983, 10940, 10940, 7940],
    28601: [7865, 7743, 4743, 8055, 11032, 11032, 8032],
    28801: [7937, 7817, 4817, 8127, 11121, 11121, 8121],
    29001: [8009, 7888, 4888, 8199, 11210, 11210, 8210],
    29201: [8081, 7958, 4958, 8271, 11302, 11302, 8302],
    29401: [8153, 8032, 5032, 8343, 11391, 11391, 8391],
    29601: [8225, 8102, 5102, 8415, 11483, 11483, 8483],
    29801: [8297, 8173, 5173, 8487, 11572, 11572, 8572],
    30001: [8369, 8248, 5248, 8559, 11661, 11661, 8661],
    30201: [8441, 8319, 5319, 8631, 11753, 11753, 8753],
    30401: [8513, 8391, 5391, 8703, 11842, 11842, 8842],
    30601: [8586, 8463, 5463, 8776, 11934, 11934, 8934],
    30801: [8658, 8538, 5538, 8848, 12023, 12023, 9023],
    31001: [8730, 8610, 5610, 8920, 12112, 12112, 9112],
    31201: [8802, 8682, 5682, 8992, 12204, 12204, 9204],
    31401: [8874, 8757, 5757, 9064, 12293, 12293, 9293],
    31601: [8946, 8829, 5829, 9136, 12385, 12385, 9385],
    31801: [9018, 8900, 5900, 9208, 12474, 12474, 9474],
    32001: [9090, 8975, 5975, 9280, 12563, 12563, 9563],
    32201: [9162, 9047, 6047, 9352, 12655, 12655, 9655],
    32401: [9234, 9119, 6119, 9424, 12744, 12744, 9744],
    32601: [9306, 9194, 6194, 9496, 12836, 12836, 9836],
    32801: [9378, 9266, 6266, 9568, 12925, 12925, 9925],
    33001: [9450, 9338, 6338, 9640, 13014, 13014, 10014],
    33201: [9522, 9413, 6413, 9712, 13106, 13106, 10106],
    33401: [9594, 9485, 6485, 9784, 13195, 13195, 10195],
    33601: [9666, 9556, 6556, 9856, 13287, 13287, 10287],
    33801: [9738, 9628, 6628, 9928, 13376, 13376, 10376],
    34001: [9810, 9703, 6703, 10000, 13465, 13465, 10465],
    34201: [9882, 9775, 6775, 10072, 13557, 13557, 10557],
    34401: [9954, 9847, 6847, 10144, 13643, 13643, 10643],
    34601: [10026, 9918, 6918, 10216, 13725, 13725, 10725],
    34801: [10098, 9987, 6987, 10288, 13807, 13807, 10807],
    35001: [10169, 10059, 7059, 10359, 13889, 13889, 10889],
    35201: [10243, 10134, 7134, 10431, 13971, 13971, 10971],
    35401: [10325, 10216, 7216, 10503, 14053, 14053, 11053],
    35601: [10407, 10298, 7298, 10575, 14135, 14135, 11135],
    35801: [10489, 10380, 7380, 10647, 14217, 14217, 11217],
    36001: [10571, 10462, 7462, 10718, 14299, 14299, 11299],
    36201: [10653, 10544, 7544, 10790, 14381, 14381, 11381],
    36401: [10735, 10626, 7626, 10862, 14463, 14463, 11463],
    36601: [10817, 10708, 7708, 10934, 14545, 14545, 11545],
    36801: [10899, 10790, 7790, 11006, 14627, 14627, 11627],
    37001: [10981, 10872, 7872, 11077, 14709, 14709, 11709],
    37201: [11063, 10954, 7954, 11149, 14791, 14791, 11791],
    37401: [11145, 11036, 8036, 11221, 14873, 14873, 11873],
    37601: [11227, 11118, 8118, 11293, 14955, 14955, 11955],
    37801: [11309, 11200, 8200, 11365, 15037, 15037, 12037],
    38001: [11391, 11282, 8282, 11436, 15119, 15119, 12119],
    38201: [11473, 11364, 8364, 11508, 15201, 15201, 12201],
    38401: [11555, 11446, 8446, 11580, 15283, 15283, 12283],
    38601: [11637, 11528, 8528, 11652, 15365, 15365, 12365],
    38801: [11719, 11610, 8610, 11724, 15447, 15447, 12447],
    39001: [11801, 11692, 8692, 11796, 15529, 15529, 12529],
    39201: [11883, 11774, 8774, 11867, 15611, 15611, 12611],
    39401: [11965, 11856, 8856, 11939, 15693, 15693, 12693],
    39601: [12047, 11938, 8938, 12011, 15775, 15775, 12775],
    39801: [12129, 12020, 9020, 12083, 15857, 15857, 12857],
    40001: [12211, 12102, 9102, 12154, 15939, 15939, 12939],
    40201: [12293, 12184, 9184, 12226, 16021, 16021, 13021],
    40401: [12375, 12266, 9266, 12298, 16103, 16103, 13103],
    40601: [12457, 12348, 9348, 12370, 16185, 16185, 13185],
    40801: [12539, 12430, 9430, 12442, 16267, 16267, 13267],
    41001: [12621, 12512, 9512, 12513, 16349, 16349, 13349],
    41201: [12703, 12594, 9594, 12585, 16431, 16431, 13431],
    41401: [12785, 12676, 9676, 12657, 16513, 16513, 13513],
    41601: [12867, 12758, 9758, 12729, 16595, 16595, 13595],
    41801: [12949, 12840, 9840, 12801, 16677, 16677, 13677],
    42001: [13031, 12922, 9922, 12872, 16759, 16759, 13759],
    42201: [13113, 13004, 10004, 12944, 16841, 16841, 13841],
    42401: [13195, 13086, 10086, 13016, 16923, 16923, 13923],
    42601: [13277, 13168, 10168, 13088, 17005, 17005, 14005],
    42801: [13359, 13250, 10250, 13160, 17087, 17087, 14087],
    43001: [13441, 13332, 10332, 13231, 17169, 17169, 14169],
    43201: [13523, 13414, 10414, 13303, 17251, 17251, 14251],
    43401: [13605, 13496, 10496, 13375, 17333, 17333, 14333],
    43601: [13687, 13578, 10578, 13447, 17415, 17415, 14415],
    43801: [13769, 13660, 10660, 13519, 17497, 17497, 14497],
    44001: [13851, 13742, 10742, 13591, 17579, 17579, 14579],
    44201: [13933, 13824, 10824, 13662, 17661, 17661, 14661],
    44401: [14015, 13906, 10906, 13734, 17743, 17743, 14743],
    44601: [14097, 13988, 10988, 13806, 17825, 17825, 14825],
    44801: [14179, 14070, 11070, 13878, 17907, 17907, 14907],
    45001: [14261, 14152, 11152, 13949, 17989, 17989, 14989],
    45201: [14343, 14234, 11234, 14021, 18071, 18071, 15071],
    45401: [14425, 14316, 11316, 14093, 18153, 18153, 15153],
    45601: [14507, 14398, 11398, 14165, 18235, 18235, 15235],
    45801: [14589, 14480, 11480, 14237, 18317, 18317, 15317],
    46001: [14671, 14562, 11562, 14308, 18399, 18399, 15399],
    46201: [14753, 14644, 11644, 14380, 18481, 18481, 15481],
    46401: [14835, 14726, 11726, 14452, 18563, 18563, 15563],
    46601: [14917, 14808, 11808, 14524, 18645, 18645, 15645],
    46801: [14999, 14890, 11890, 14596, 18727, 18727, 15727],
    47001: [15081, 14972, 11972, 14668, 18809, 18809, 15809],
    47201: [15163, 15054, 12054, 14739, 18891, 18891, 15891],
    47401: [15245, 15136, 12136, 14811, 18973, 18973, 15973],
    47601: [15327, 15218, 12218, 14883, 19055, 19055, 16055],
    47801: [15409, 15300, 12300, 14955, 19137, 19137, 16137],
    48001: [15491, 15382, 12382, 15027, 19219, 19219, 16219],
    48201: [15573, 15464, 12464, 15098, 19301, 19301, 16301],
    48401: [15655, 15546, 12546, 15170, 19383, 19383, 16383],
    48601: [15737, 15628, 12628, 15242, 19465, 19465, 16465],
    48801: [15819, 15710, 12710, 15314, 19547, 19547, 16547],
    49001: [15901, 15792, 12792, 15386, 19629, 19629, 16629],
    49201: [15983, 15874, 12874, 15457, 19711, 19711, 16711],
    49401: [16065, 15956, 12956, 15529, 19793, 19793, 16793],
    49601: [16147, 16038, 13038, 15601, 19875, 19875, 16875],
    49801: [16229, 16120, 13120, 15673, 19957, 19957, 16957],
    50001: [16311, 16202, 13208, 15745, 20039, 20039, 17045],
    50201: [16393, 16328, 13340, 15816, 20121, 20121, 17133],
    50401: [16475, 16458, 13476, 15888, 20203, 20203, 17221],
    50601: [16557, 16591, 13615, 15960, 20285, 20285, 17309],
    50801: [16639, 16725, 13755, 16032, 20367, 20367, 17397],
    51001: [16729, 16858, 13894, 16112, 20457, 20457, 17493],
    51201: [16851, 16991, 14033, 16224, 20579, 20579, 17621],
    51401: [16973, 17124, 14172, 16335, 20701, 20701, 17749],
    51601: [17095, 17257, 14311, 16447, 20823, 20823, 17877],
    51801: [17217, 17391, 14451, 16559, 20945, 20945, 18005],
    52001: [17339, 17521, 14587, 16671, 21067, 21067, 18133],
    52201: [17461, 17654, 14726, 16783, 21189, 21189, 18261],
    52401: [17583, 17787, 14865, 16894, 21311, 21311, 18389],
    52601: [17705, 17920, 15004, 17006, 21433, 21433, 18517],
    52801: [17827, 18054, 15144, 17118, 21555, 21555, 18645],
    53001: [17949, 18187, 15283, 17230, 21677, 21677, 18773],
    53201: [18071, 18320, 15422, 17342, 21799, 21799, 18901],
    53401: [18193, 18453, 15561, 17453, 21921, 21921, 19029],
    53601: [18315, 18583, 15697, 17565, 22043, 22043, 19157],
    53801: [18437, 18716, 15836, 17677, 22165, 22165, 19285],
    54001: [18559, 18850, 15976, 17789, 22287, 22287, 19413],
    54201: [18681, 18983, 16115, 17901, 22409, 22409, 19541],
    54401: [18803, 19116, 16254, 18012, 22531, 22531, 19669],
    54601: [18925, 19249, 16393, 18124, 22653, 22653, 19797],
    54801: [19047, 19383, 16533, 18236, 22775, 22775, 19925],
    55001: [19169, 19516, 16672, 18348, 22897, 22897, 20053],
    55201: [19291, 19649, 16811, 18460, 23019, 23019, 20181],
    55401: [19413, 19779, 16947, 18571, 23141, 23141, 20309],
    55601: [19535, 19912, 17086, 18683, 23263, 23263, 20437],
    55801: [19657, 20045, 17225, 18795, 23385, 23385, 20565],
    56001: [19779, 20131, 17317, 18907, 23507, 23507, 20693],
    56201: [19901, 20213, 17405, 19019, 23629, 23629, 20821],
    56401: [20023, 20295, 17493, 19130, 23751, 23751, 20949],
    56601: [20145, 20377, 17581, 19242, 23873, 23873, 21077],
    56801: [20267, 20472, 17682, 19354, 23995, 23995, 21205],
    57001: [20389, 20594, 17810, 19466, 24117, 24117, 21333],
    57201: [20511, 20716, 17938, 19578, 24239, 24239, 21461],
    57401: [20633, 20838, 18066, 19689, 24361, 24361, 21589],
    57601: [20755, 20960, 18194, 19801, 24483, 24483, 21717],
    57801: [20877, 21082, 18322, 19913, 24605, 24605, 21845],
    58001: [20999, 21204, 18450, 20025, 24727, 24727, 21973],
    58201: [21121, 21326, 18578, 20137, 24849, 24849, 22101],
    58401: [21243, 21448, 18706, 20248, 24971, 24971, 22229],
    58601: [21365, 21570, 18834, 20360, 25093, 25093, 22357],
    58801: [21487, 21692, 18962, 20472, 25215, 25215, 22485],
    59001: [21609, 21814, 19090, 20584, 25337, 25337, 22613],
    59201: [21736, 21941, 19223, 20696, 25459, 25459, 22741],
    59401: [21864, 22068, 19356, 20807, 25581, 25581, 22869],
    59601: [21992, 22201, 19495, 20919, 25703, 25703, 22997],
    59801: [22120, 22328, 19628, 21031, 25825, 25825, 23125],
    60001: [22248, 22455, 19761, 21143, 25947, 25947, 23253],
    60201: [22376, 22587, 19899, 21255, 26069, 26069, 23381],
    60401: [22504, 22714, 20032, 21366, 26191, 26191, 23509],
    60601: [22632, 22846, 20170, 21478, 26313, 26313, 23637],
    60801: [22760, 22973, 20303, 21590, 26435, 26435, 23765],
    61001: [22888, 23100, 20436, 21702, 26557, 26557, 23893],
    61201: [23016, 23232, 20574, 21814, 26679, 26679, 24021],
    61401: [23144, 23360, 20708, 21926, 26801, 26801, 24149],
    61601: [23272, 23487, 20841, 22037, 26923, 26923, 24277],
    61801: [23400, 23619, 20979, 22149, 27045, 27045, 24405],
    62001: [23528, 23746, 21112, 22261, 27167, 27167, 24533],
    62201: [23656, 23878, 21250, 22373, 27289, 27289, 24661],
    62401: [23784, 24005, 21383, 22485, 27411, 27411, 24789],
    62601: [23912, 24132, 21516, 22596, 27533, 27533, 24917],
    62801: [24040, 24264, 21654, 22708, 27655, 27655, 25045],
    63001: [24168, 24391, 21787, 22820, 27777, 27777, 25173],
    63201: [24296, 24519, 21921, 22932, 27899, 27899, 25301],
    63401: [24424, 24651, 22059, 23044, 28021, 28021, 25429],
    63601: [24552, 24778, 22192, 23155, 28143, 28143, 25557],
    63801: [24680, 24910, 22330, 23267, 28265, 28265, 25685],
    64001: [24808, 25037, 22463, 23379, 28387, 28387, 25813],
    64201: [24936, 25164, 22596, 23491, 28509, 28509, 25941],
    64401: [25064, 25296, 22734, 23603, 28631, 28631, 26069],
    64601: [25192, 25423, 22867, 23714, 28753, 28753, 26197],
    64801: [25320, 25550, 23000, 23826, 28875, 28875, 26325],
    65001: [25448, 25683, 23139, 23938, 28997, 28997, 26453],
    65201: [25576, 25810, 23272, 24050, 29119, 29119, 26581],
    65401: [25704, 25942, 23410, 24162, 29241, 29241, 26709],
    65601: [25832, 26069, 23543, 24273, 29363, 29363, 26837],
    65801: [25960, 26196, 23676, 24385, 29485, 29485, 26965],
    66001: [26088, 26328, 23814, 24497, 29607, 29607, 27093],
    66201: [26216, 26455, 23947, 24609, 29729, 29729, 27221],
    66401: [26344, 26582, 24080, 24721, 29851, 29851, 27349],
    66601: [26472, 26715, 24219, 24832, 29973, 29973, 27477],
    66801: [26600, 26842, 24352, 24944, 30095, 30095, 27605],
    67001: [26728, 26974, 24490, 25056, 30217, 30217, 27733],
    67201: [26856, 27101, 24623, 25168, 30339, 30339, 27861],
    67401: [26984, 27228, 24756, 25280, 30461, 30461, 27989],
    67601: [27112, 27360, 24894, 25391, 30583, 30583, 28117],
    67801: [27240, 27487, 25027, 25503, 30705, 30705, 28245],
    68001: [27368, 27619, 25165, 25615, 30827, 30827, 28373],
    68201: [27496, 27746, 25298, 25727, 30949, 30949, 28501],
    68401: [27624, 27874, 25432, 25839, 31071, 31071, 28629],
    68601: [27752, 28006, 25570, 25950, 31193, 31193, 28757],
    68801: [27880, 28133, 25703, 26062, 31315, 31315, 28885],
    69001: [28008, 28260, 25836, 26174, 31437, 31437, 29013],
    69201: [28136, 28392, 25974, 26286, 31559, 31559, 29141],
    69401: [28264, 28519, 26107, 26398, 31681, 31681, 29269],
    69601: [28392, 28651, 26245, 26509, 31803, 31803, 29397],
    69801: [28520, 28778, 26378, 26621, 31925, 31925, 29525],
    70001: [28648, 28905, 26511, 26733, 32047, 32047, 29653],
    70201: [28776, 29038, 26650, 26845, 32169, 32169, 29781],
    70401: [28904, 29165, 26783, 26957, 32291, 32291, 29909],
    70601: [29032, 29292, 26916, 27068, 32413, 32413, 30037],
    70801: [29160, 29424, 27054, 27180, 32535, 32535, 30165],
    71001: [29288, 29551, 27187, 27292, 32657, 32657, 30293],
    71201: [29416, 29683, 27325, 27404, 32779, 32779, 30421],
    71401: [29544, 29810, 27458, 27516, 32901, 32901, 30549],
    71601: [29672, 29937, 27591, 27627, 33023, 33023, 30677],
    71801: [29800, 30070, 27730, 27739, 33145, 33145, 30805],
    72001: [29928, 30197, 27863, 27851, 33267, 33267, 30933],
    72201: [30056, 30324, 27996, 27963, 33389, 33389, 31061],
    72401: [30184, 30456, 28134, 28075, 33511, 33511, 31189],
    72601: [30312, 30583, 28267, 28186, 33633, 33633, 31317],
    72801: [30440, 30715, 28405, 28298, 33755, 33755, 31445],
    73001: [30568, 30842, 28538, 28410, 33877, 33877, 31573],
    73201: [30696, 30969, 28671, 28522, 33999, 33999, 31701],
    73401: [30824, 31101, 28809, 28634, 34121, 34121, 31829],
    73601: [30952, 31229, 28943, 28745, 34243, 34243, 31957],
    73801: [31080, 31356, 29076, 28857, 34365, 34365, 32085],
    74001: [31208, 31488, 29214, 28969, 34487, 34487, 32213],
    74201: [31336, 31615, 29347, 29081, 34609, 34609, 32341],
    74401: [31464, 31747, 29485, 29193, 34731, 34731, 32469],
    74601: [31592, 31874, 29618, 29304, 34853, 34853, 32597],
    74801: [31720, 32001, 29751, 29416, 34975, 34975, 32725],
    75001: [31848, 32133, 29889, 29528, 35097, 35097, 32853],
    75201: [31976, 32260, 30022, 29640, 35219, 35219, 32981],
    75401: [32104, 32388, 30156, 29752, 35341, 35341, 33109],
    75601: [32232, 32520, 30294, 29863, 35463, 35463, 33237],
    75801: [32360, 32647, 30427, 29975, 35585, 35585, 33365],
    76001: [32488, 32779, 30565, 30087, 35707, 35707, 33493],
    76201: [32616, 32906, 30698, 30199, 35829, 35829, 33621],
    76401: [32744, 33033, 30831, 30311, 35951, 35951, 33749],
    76601: [32872, 33165, 30969, 30422, 36073, 36073, 33877],
    76801: [33000, 33292, 31102, 30534, 36195, 36195, 34005],
    77001: [33128, 33425, 31241, 30646, 36317, 36317, 34133],
    77201: [33256, 33552, 31374, 30758, 36439, 36439, 34261],
    77401: [33384, 33679, 31507, 30870, 36561, 36561, 34389],
    77601: [33512, 33811, 31645, 30981, 36683, 36683, 34517],
    77801: [33640, 33938, 31778, 31093, 36805, 36805, 34645],
    78001: [33768, 34065, 31911, 31205, 36927, 36927, 34773],
    78201: [33896, 34197, 32049, 31317, 37049, 37049, 34901],
    78401: [34024, 34324, 32182, 31429, 37171, 37171, 35029],
    78601: [34152, 34456, 32320, 31540, 37293, 37293, 35157],
    78801: [34280, 34584, 32454, 31652, 37415, 37415, 35285],
    79001: [34408, 34711, 32587, 31764, 37537, 37537, 35413],
    79201: [34536, 34843, 32725, 31876, 37659, 37659, 35541],
    79401: [34664, 34970, 32858, 31988, 37781, 37781, 35669],
    79601: [34792, 35097, 32991, 32099, 37903, 37903, 35797],
    79801: [34920, 35229, 33129, 32211, 38025, 38025, 35925],
    80001: [44, 44, 41, 40, 48, 48, 45, '%'],
    80201: [44, 44, 42, 40, 48, 48, 45, '%'],
    81201: [44, 44, 42, 41, 48, 48, 45, '%'],
    81801: [44, 45, 42, 41, 48, 48, 45, '%'],
    82401: [44, 45, 42, 41, 48, 48, 46, '%'],
    83401: [45, 45, 43, 41, 48, 48, 46, '%'],
    86001: [45, 46, 43, 41, 49, 49, 46, '%'],
    86801: [45, 46, 44, 42, 49, 49, 46, '%'],
    87201: [45, 46, 44, 42, 49, 49, 47, '%'],
    87801: [46, 46, 44, 42, 49, 49, 47, '%'],
    90601: [46, 46, 45, 42, 49, 49, 47, '%'],
    90801: [46, 47, 45, 42, 49, 49, 47, '%'],
    92401: [46, 47, 45, 42, 49, 49, 48, '%'],
    93001: [47, 47, 45, 42, 49, 49, 48, '%'],
    93201: [47, 47, 45, 43, 49, 49, 48, '%'],
    93601: [47, 47, 45, 43, 50, 50, 48, '%'],
    94801: [47, 47, 46, 43, 50, 50, 48, '%'],
    96201: [47, 48, 46, 43, 50, 50, 48, '%'],
    98401: [47, 48, 46, 43, 50, 50, 49, '%'],
    98601: [48, 48, 46, 43, 50, 50, 49, '%'],
    99201: [48, 48, 47, 43, 50, 50, 49, '%'],
    100801: [48, 48, 47, 44, 50, 50, 49, '%'],
    102201: [48, 49, 47, 44, 50, 50, 49, '%'],
    102601: [48, 49, 47, 44, 51, 51, 49, '%'],
    104201: [48, 49, 48, 44, 51, 51, 49, '%'],
    105001: [49, 49, 48, 44, 51, 51, 49, '%'],
    105201: [49, 49, 48, 44, 51, 51, 50, '%'],
    109001: [49, 50, 48, 44, 51, 51, 50, '%'],
    109601: [49, 50, 48, 45, 51, 51, 50, '%'],
    109801: [49, 50, 49, 45, 51, 51, 50, '%'],
    112201: [50, 50, 49, 45, 51, 51, 50, '%'],
    113001: [50, 50, 49, 45, 51, 51, 51, '%'],
    113401: [50, 50, 49, 45, 52, 52, 51, '%'],
    115801: [50, 50, 50, 45, 52, 52, 51, '%'],
    116801: [50, 51, 50, 45, 52, 52, 51, '%'],
    120201: [50, 51, 50, 46, 52, 52, 51, '%'],
    120401: [51, 51, 50, 46, 52, 52, 51, '%'],
    122001: [51, 51, 50, 46, 52, 52, 52, '%'],
    122601: [51, 51, 51, 46, 52, 52, 52, '%'],
    125801: [51, 52, 51, 46, 52, 52, 52, '%'],
    126601: [51, 52, 51, 46, 53, 53, 52, '%'],
    130201: [52, 52, 51, 46, 53, 53, 52, '%'],
    130401: [52, 52, 52, 46, 53, 53, 52, '%'],
    132801: [52, 52, 52, 46, 53, 53, 53, '%'],
    133001: [52, 52, 52, 47, 53, 53, 53, '%'],
    136401: [52, 53, 52, 47, 53, 53, 53, '%'],
    139001: [52, 53, 53, 47, 53, 53, 53, '%'],
    141401: [53, 53, 53, 47, 53, 53, 53, '%'],
    143601: [53, 53, 53, 47, 54, 54, 53, '%'],
    145401: [53, 53, 53, 47, 54, 54, 54, '%'],
    148801: [53, 54, 53, 48, 54, 54, 54, '%'],
    149001: [53, 54, 54, 48, 54, 54, 54, '%'],
    155001: [54, 54, 54, 48, 54, 54, 54, '%'],
    165601: [54, 55, 55, 48, 55, 55, 55, '%'],
    169001: [54, 55, 55, 49, 55, 55, 55, '%'],
    171201: [55, 55, 55, 49, 55, 55, 55, '%'],
    195401: [55, 55, 55, 50, 55, 55, 55, '%'],
    195801: [56, 56, 56, 50, 56, 56, 56, '%'],
    231601: [56, 56, 56, 51, 56, 56, 56, '%'],
    239401: [57, 57, 57, 51, 57, 57, 57, '%'],
    284201: [57, 57, 57, 52, 57, 57, 57, '%'],
    307801: [58, 58, 58, 52, 58, 58, 58, '%'],
    367801: [58, 58, 58, 53, 58, 58, 58, '%'],
    430801: [59, 59, 59, 53, 59, 59, 59, '%'],
    521001: [59, 59, 59, 54, 59, 59, 59, '%'],
    718201: [60, 60, 60, 54, 60, 60, 60, '%'],
    893001: [60, 60, 60, 55, 60, 60, 60, '%']
  },
  42: {
    1: [0, 0, 0, 0, 0, 0, 0],
    1801: [134, 0, 133, 0, 134, 17, 134],
    1901: [144, 0, 141, 3, 144, 60, 144],
    2001: [155, 0, 150, 5, 155, 103, 155],
    2101: [157, 0, 150, 7, 157, 146, 157],
    2201: [167, 0, 158, 9, 189, 189, 167],
    2301: [178, 0, 166, 11, 232, 232, 178],
    2401: [188, 0, 175, 13, 275, 275, 188],
    2501: [199, 0, 183, 16, 318, 318, 199],
    2601: [209, 0, 191, 18, 361, 361, 209],
    2701: [220, 0, 200, 20, 404, 404, 220],
    2801: [222, 0, 200, 22, 447, 447, 222],
    2901: [232, 0, 208, 24, 490, 490, 232],
    3001: [243, 0, 216, 26, 533, 533, 243],
    3101: [253, 0, 225, 28, 576, 576, 253],
    3201: [264, 0, 233, 31, 619, 619, 264],
    3301: [274, 0, 241, 33, 662, 662, 274],
    3401: [277, 0, 241, 35, 705, 705, 277],
    3501: [287, 0, 250, 37, 748, 748, 287],
    3601: [298, 0, 258, 39, 791, 791, 298],
    3701: [308, 0, 266, 41, 834, 834, 308],
    3801: [319, 0, 275, 44, 877, 877, 319],
    3901: [329, 0, 283, 51, 920, 920, 329],
    4001: [331, 0, 283, 80, 963, 963, 331],
    4101: [342, 0, 291, 109, 1006, 1006, 342],
    4201: [352, 0, 300, 138, 1049, 1049, 352],
    4301: [363, 0, 308, 167, 1089, 1089, 363],
    4401: [373, 0, 316, 196, 1121, 1121, 373],
    4501: [383, 0, 325, 224, 1157, 1157, 383],
    4601: [384, 0, 325, 253, 1193, 1193, 384],
    4701: [394, 0, 333, 282, 1225, 1225, 394],
    4801: [405, 10, 342, 310, 1261, 1261, 405],
    4901: [414, 42, 352, 339, 1293, 1293, 414],
    5001: [425, 75, 362, 368, 1329, 1329, 425],
    5101: [435, 107, 372, 396, 1365, 1365, 435],
    5201: [445, 139, 382, 425, 1397, 1397, 445],
    5301: [447, 171, 383, 454, 1433, 1433, 447],
    5401: [456, 204, 393, 482, 1465, 1465, 456],
    5501: [479, 236, 403, 510, 1500, 1500, 467],
    5601: [505, 268, 413, 538, 1535, 1535, 477],
    5701: [531, 300, 423, 566, 1567, 1567, 487],
    5801: [557, 329, 433, 594, 1602, 1602, 497],
    5901: [583, 361, 434, 622, 1634, 1634, 498],
    6001: [609, 394, 444, 650, 1669, 1669, 509],
    6101: [636, 426, 454, 678, 1704, 1704, 519],
    6201: [662, 458, 464, 706, 1736, 1736, 529],
    6301: [688, 490, 474, 734, 1771, 1771, 539],
    6401: [714, 523, 484, 762, 1803, 1803, 549],
    6501: [740, 555, 486, 790, 1838, 1838, 551],
    6601: [767, 587, 496, 819, 1873, 1873, 561],
    6701: [792, 619, 506, 846, 1905, 1905, 571],
    6801: [819, 652, 516, 875, 1940, 1940, 581],
    6901: [845, 680, 525, 903, 1972, 1972, 591],
    7001: [871, 713, 535, 931, 2007, 2007, 601],
    7101: [897, 745, 537, 959, 2042, 2042, 603],
    7201: [923, 777, 547, 987, 2074, 2074, 613],
    7301: [949, 809, 557, 1015, 2109, 2109, 623],
    7401: [976, 842, 567, 1043, 2141, 2141, 633],
    7501: [1002, 874, 577, 1071, 2176, 2176, 643],
    7601: [1028, 906, 587, 1099, 2211, 2211, 653],
    7701: [1054, 938, 597, 1127, 2243, 2243, 663],
    7801: [1080, 971, 598, 1155, 2278, 2278, 665],
    7901: [1106, 1003, 608, 1183, 2310, 2310, 675],
    8001: [1132, 1035, 618, 1211, 2345, 2345, 685],
    8101: [1159, 1064, 628, 1239, 2380, 2380, 695],
    8201: [1185, 1096, 638, 1267, 2412, 2412, 705],
    8301: [1211, 1128, 648, 1295, 2447, 2447, 715],
    8401: [1237, 1160, 649, 1324, 2479, 2479, 717],
    8501: [1263, 1186, 659, 1352, 2514, 2514, 727],
    8601: [1289, 1211, 669, 1380, 2549, 2549, 737],
    8701: [1315, 1239, 678, 1408, 2581, 2581, 747],
    8801: [1342, 1264, 688, 1436, 2616, 2616, 757],
    8901: [1368, 1289, 698, 1464, 2648, 2648, 768],
    9001: [1394, 1315, 699, 1492, 2683, 2683, 796],
    9101: [1420, 1343, 709, 1520, 2718, 2718, 824],
    9201: [1446, 1368, 718, 1548, 2750, 2750, 849],
    9301: [1472, 1393, 728, 1576, 2785, 2785, 877],
    9401: [1498, 1418, 737, 1604, 2817, 2817, 902],
    9501: [1525, 1447, 747, 1632, 2852, 2852, 930],
    9601: [1551, 1472, 748, 1660, 2887, 2887, 958],
    9701: [1577, 1496, 758, 1688, 2919, 2919, 983],
    9801: [1603, 1521, 768, 1716, 2954, 2954, 1011],
    9901: [1629, 1549, 777, 1744, 2986, 2986, 1036],
    10001: [1655, 1574, 787, 1773, 3021, 3021, 1064],
    10101: [1682, 1598, 797, 1801, 3056, 3056, 1092],
    10201: [1708, 1623, 806, 1829, 3088, 3088, 1117],
    10301: [1734, 1651, 808, 1857, 3123, 3123, 1145],
    10401: [1760, 1676, 817, 1885, 3155, 3155, 1170],
    10501: [1786, 1700, 827, 1913, 3190, 3190, 1198],
    10601: [1812, 1725, 836, 1941, 3225, 3225, 1226],
    10701: [1838, 1753, 846, 1969, 3257, 3257, 1251],
    10801: [1865, 1778, 856, 1997, 3292, 3292, 1279],
    10901: [1891, 1803, 857, 2025, 3324, 3324, 1304],
    11001: [1917, 1831, 867, 2053, 3359, 3359, 1332],
    11101: [1943, 1855, 876, 2081, 3394, 3394, 1360],
    11201: [1969, 1880, 886, 2109, 3426, 3426, 1385],
    11301: [1995, 1905, 896, 2137, 3461, 3461, 1413],
    11401: [2021, 1933, 905, 2165, 3493, 3493, 1438],
    11501: [2048, 1957, 907, 2193, 3528, 3528, 1466],
    11601: [2074, 1982, 916, 2222, 3563, 3563, 1494],
    11701: [2100, 2007, 926, 2250, 3595, 3595, 1519],
    11801: [2126, 2035, 936, 2278, 3630, 3630, 1547],
    11901: [2152, 2060, 945, 2306, 3669, 3669, 1579],
    12001: [2179, 2084, 955, 2334, 3711, 3711, 1614],
    12101: [2205, 2109, 956, 2363, 3753, 3753, 1649],
    12201: [2232, 2137, 966, 2391, 3795, 3795, 1684],
    12301: [2258, 2162, 975, 2419, 3838, 3838, 1720],
    12401: [2284, 2186, 985, 2448, 3880, 3880, 1755],
    12501: [2311, 2211, 995, 2476, 3922, 3922, 1790],
    12601: [2337, 2239, 1004, 2504, 3964, 3964, 1825],
    12701: [2364, 2264, 1014, 2533, 4007, 4007, 1861],
    12801: [2390, 2288, 1015, 2561, 4049, 4049, 1896],
    12901: [2417, 2317, 1025, 2589, 4091, 4091, 1931],
    13001: [2443, 2341, 1035, 2618, 4133, 4133, 1966],
    13101: [2469, 2366, 1044, 2646, 4176, 4176, 2002],
    13201: [2496, 2390, 1054, 2674, 4218, 4218, 2037],
    13301: [2522, 2419, 1064, 2703, 4260, 4260, 2072],
    13401: [2549, 2443, 1065, 2731, 4302, 4302, 2107],
    13501: [2575, 2468, 1074, 2759, 4345, 4345, 2143],
    13601: [2602, 2493, 1084, 2788, 4390, 4390, 2181],
    13701: [2628, 2521, 1094, 2816, 4440, 4440, 2224],
    13801: [2655, 2545, 1103, 2845, 4485, 4485, 2262],
    13901: [2681, 2570, 1113, 2873, 4531, 4531, 2301],
    14001: [2708, 2595, 1114, 2902, 4577, 4577, 2340],
    14101: [2738, 2626, 1124, 2933, 4622, 4622, 2378],
    14201: [2774, 2662, 1134, 2969, 4670, 4670, 2419],
    14301: [2810, 2700, 1145, 3005, 4715, 4715, 2457],
    14401: [2846, 2736, 1155, 3041, 4760, 4760, 2495],
    14501: [2882, 2771, 1165, 3077, 4804, 4804, 2532],
    14601: [2918, 2809, 1167, 3113, 4849, 4849, 2570],
    14701: [2954, 2845, 1177, 3149, 4897, 4897, 2611],
    14801: [2990, 2883, 1187, 3185, 4942, 4942, 2649],
    14901: [3026, 2919, 1197, 3221, 4987, 4987, 2687],
    15001: [3063, 2954, 1208, 3257, 5031, 5031, 2724],
    15101: [3099, 2993, 1218, 3293, 5076, 5076, 2762],
    15201: [3135, 3028, 1228, 3329, 5124, 5124, 2803],
    15301: [3171, 3066, 1230, 3365, 5169, 5169, 2841],
    15401: [3207, 3102, 1240, 3401, 5213, 5213, 2878],
    15501: [3243, 3137, 1250, 3438, 5258, 5258, 2916],
    15601: [3279, 3176, 1261, 3474, 5303, 5303, 2954],
    15701: [3315, 3211, 1271, 3510, 5351, 5351, 2995],
    15801: [3351, 3250, 1281, 3546, 5396, 5396, 3033],
    15901: [3387, 3285, 1283, 3582, 5440, 5440, 3070],
    16001: [3423, 3320, 1293, 3618, 5485, 5485, 3108],
    16101: [3459, 3359, 1303, 3654, 5530, 5530, 3146],
    16201: [3495, 3394, 1313, 3690, 5578, 5578, 3187],
    16301: [3531, 3433, 1324, 3726, 5623, 5623, 3225],
    16401: [3567, 3468, 1334, 3762, 5667, 5667, 3262],
    16501: [3603, 3503, 1336, 3798, 5712, 5712, 3300],
    16601: [3639, 3542, 1346, 3834, 5757, 5757, 3338],
    16701: [3676, 3577, 1356, 3870, 5805, 5805, 3379],
    16801: [3712, 3616, 1366, 3906, 5849, 5849, 3416],
    16901: [3748, 3651, 1377, 3942, 5894, 5894, 3454],
    17001: [3784, 3686, 1387, 3979, 5939, 5939, 3492],
    17101: [3820, 3725, 1389, 4015, 5983, 5983, 3529],
    17201: [3856, 3760, 1399, 4051, 6032, 6032, 3571],
    17301: [3892, 3799, 1409, 4087, 6076, 6076, 3608],
    17401: [3928, 3834, 1419, 4123, 6121, 6121, 3646],
    17501: [3964, 3869, 1429, 4159, 6166, 6166, 3684],
    17601: [4000, 3908, 1440, 4195, 6210, 6210, 3721],
    17701: [4036, 3943, 1450, 4231, 6258, 6258, 3762],
    17801: [4072, 3982, 1479, 4267, 6303, 6303, 3800],
    17901: [4108, 4017, 1507, 4303, 6348, 6348, 3838],
    18001: [4144, 4052, 1535, 4339, 6393, 6393, 3876],
    18101: [4180, 4091, 1567, 4375, 6437, 6437, 3913],
    18201: [4216, 4126, 1595, 4411, 6485, 6485, 3954],
    18301: [4253, 4165, 1627, 4447, 6530, 6530, 3992],
    18401: [4289, 4200, 1655, 4483, 6575, 6575, 4030],
    18501: [4325, 4235, 1683, 4519, 6619, 6619, 4067],
    18601: [4361, 4274, 1715, 4556, 6664, 6664, 4105],
    18701: [4397, 4309, 1743, 4592, 6712, 6712, 4146],
    18801: [4433, 4348, 1775, 4628, 6757, 6757, 4184],
    18901: [4469, 4383, 1803, 4664, 6802, 6802, 4222],
    19001: [4505, 4422, 1835, 4700, 6846, 6846, 4259],
    19101: [4541, 4457, 1863, 4736, 6891, 6891, 4297],
    19201: [4577, 4492, 1891, 4772, 6939, 6939, 4338],
    19301: [4613, 4531, 1923, 4808, 6984, 6984, 4376],
    19401: [4649, 4566, 1951, 4844, 7028, 7028, 4413],
    19501: [4685, 4605, 1983, 4880, 7073, 7073, 4451],
    19601: [4721, 4639, 2010, 4916, 7118, 7118, 4489],
    19701: [4757, 4674, 2038, 4952, 7166, 7166, 4530],
    19801: [4793, 4712, 2069, 4988, 7211, 7211, 4568],
    19901: [4830, 4746, 2096, 5024, 7255, 7255, 4605],
    20001: [4902, 4818, 2154, 5096, 7345, 7345, 4681],
    20201: [4974, 4890, 2212, 5169, 7438, 7438, 4760],
    20401: [5046, 4963, 2271, 5241, 7527, 7527, 4835],
    20601: [5118, 5031, 2325, 5313, 7620, 7620, 4914],
    20801: [5190, 5103, 2383, 5385, 7709, 7709, 4989],
    21001: [5262, 5176, 2442, 5457, 7798, 7798, 5064],
    21201: [5334, 5248, 2500, 5529, 7891, 7891, 5143],
    21401: [5407, 5320, 2558, 5601, 7981, 7981, 5219],
    21601: [5479, 5389, 2613, 5673, 8073, 8073, 5297],
    21801: [5551, 5461, 2671, 5746, 8163, 8163, 5373],
    22001: [5623, 5533, 2729, 5818, 8252, 8252, 5448],
    22201: [5695, 5605, 2787, 5890, 8345, 8345, 5527],
    22401: [5768, 5678, 2846, 5963, 8435, 8435, 5603],
    22601: [5842, 5746, 2900, 6037, 8530, 8530, 5684],
    22801: [5916, 5818, 2958, 6110, 8621, 8621, 5761],
    23001: [5989, 5891, 3017, 6184, 8712, 8712, 5838],
    23201: [6063, 5963, 3075, 6258, 8806, 8806, 5918],
    23401: [6137, 6035, 3133, 6332, 8897, 8897, 5995],
    23601: [6211, 6107, 3191, 6406, 8992, 8992, 6076],
    23801: [6285, 6176, 3246, 6479, 9083, 9083, 6153],
    24001: [6358, 6248, 3304, 6553, 9174, 9174, 6230],
    24201: [6432, 6324, 3366, 6627, 9268, 9268, 6310],
    24401: [6506, 6396, 3424, 6701, 9359, 9359, 6387],
    24601: [6580, 6468, 3482, 6774, 9454, 9454, 6468],
    24801: [6653, 6540, 3540, 6848, 9545, 9545, 6545],
    25001: [6727, 6616, 3616, 6922, 9636, 9636, 6636],
    25201: [6801, 6688, 3688, 6996, 9730, 9730, 6730],
    25401: [6875, 6760, 3760, 7070, 9821, 9821, 6821],
    25601: [6949, 6836, 3836, 7143, 9916, 9916, 6916],
    25801: [7022, 6908, 3908, 7217, 10007, 10007, 7007],
    26001: [7096, 6980, 3980, 7291, 10098, 10098, 7098],
    26201: [7170, 7056, 4056, 7365, 10192, 10192, 7192],
    26401: [7244, 7128, 4128, 7438, 10283, 10283, 7283],
    26601: [7317, 7200, 4200, 7512, 10378, 10378, 7378],
    26801: [7391, 7276, 4276, 7586, 10469, 10469, 7469],
    27001: [7465, 7348, 4348, 7660, 10560, 10560, 7560],
    27201: [7539, 7420, 4420, 7734, 10654, 10654, 7654],
    27401: [7613, 7496, 4496, 7807, 10745, 10745, 7745],
    27601: [7686, 7568, 4568, 7881, 10840, 10840, 7840],
    27801: [7760, 7640, 4640, 7955, 10931, 10931, 7931],
    28001: [7834, 7713, 4713, 8029, 11022, 11022, 8022],
    28201: [7908, 7788, 4788, 8102, 11116, 11116, 8116],
    28401: [7981, 7860, 4860, 8176, 11207, 11207, 8207],
    28601: [8055, 7933, 4933, 8250, 11302, 11302, 8302],
    28801: [8129, 8008, 5008, 8324, 11393, 11393, 8393],
    29001: [8203, 8080, 5080, 8397, 11484, 11484, 8484],
    29201: [8277, 8153, 5153, 8471, 11578, 11578, 8578],
    29401: [8350, 8228, 5228, 8545, 11669, 11669, 8669],
    29601: [8424, 8300, 5300, 8619, 11764, 11764, 8764],
    29801: [8498, 8373, 5373, 8693, 11855, 11855, 8855],
    30001: [8572, 8449, 5449, 8766, 11946, 11946, 8946],
    30201: [8645, 8523, 5523, 8840, 12040, 12040, 9040],
    30401: [8719, 8596, 5596, 8914, 12131, 12131, 9131],
    30601: [8793, 8670, 5670, 8988, 12226, 12226, 9226],
    30801: [8867, 8747, 5747, 9062, 12317, 12317, 9317],
    31001: [8941, 8820, 5820, 9135, 12408, 12408, 9408],
    31201: [9014, 8894, 5894, 9209, 12502, 12502, 9502],
    31401: [9088, 8971, 5971, 9283, 12593, 12593, 9593],
    31601: [9162, 9044, 6044, 9357, 12688, 12688, 9688],
    31801: [9236, 9118, 6118, 9430, 12779, 12779, 9779],
    32001: [9309, 9195, 6195, 9504, 12870, 12870, 9870],
    32201: [9383, 9268, 6268, 9578, 12964, 12964, 9964],
    32401: [9457, 9342, 6342, 9652, 13055, 13055, 10055],
    32601: [9531, 9419, 6419, 9726, 13150, 13150, 10150],
    32801: [9605, 9492, 6492, 9799, 13241, 13241, 10241],
    33001: [9678, 9566, 6566, 9873, 13332, 13332, 10332],
    33201: [9752, 9643, 6643, 9947, 13426, 13426, 10426],
    33401: [9826, 9716, 6716, 10021, 13517, 13517, 10517],
    33601: [9900, 9790, 6790, 10094, 13612, 13612, 10612],
    33801: [9973, 9863, 6863, 10168, 13703, 13703, 10703],
    34001: [10047, 9940, 6940, 10242, 13794, 13794, 10794],
    34201: [10121, 10014, 7014, 10316, 13888, 13888, 10888],
    34401: [10195, 10087, 7087, 10389, 13976, 13976, 10976],
    34601: [10268, 10161, 7161, 10463, 14060, 14060, 11060],
    34801: [10342, 10231, 7231, 10536, 14144, 14144, 11144],
    35001: [10415, 10304, 7304, 10610, 14228, 14228, 11228],
    35201: [10491, 10381, 7381, 10684, 14312, 14312, 11312],
    35401: [10575, 10465, 7465, 10757, 14396, 14396, 11396],
    35601: [10659, 10549, 7549, 10831, 14480, 14480, 11480],
    35801: [10743, 10633, 7633, 10904, 14564, 14564, 11564],
    36001: [10827, 10717, 7717, 10978, 14648, 14648, 11648],
    36201: [10911, 10801, 7801, 11051, 14732, 14732, 11732],
    36401: [10995, 10885, 7885, 11125, 14816, 14816, 11816],
    36601: [11079, 10969, 7969, 11198, 14900, 14900, 11900],
    36801: [11163, 11053, 8053, 11272, 14984, 14984, 11984],
    37001: [11247, 11137, 8137, 11345, 15068, 15068, 12068],
    37201: [11331, 11221, 8221, 11419, 15152, 15152, 12152],
    37401: [11415, 11305, 8305, 11493, 15236, 15236, 12236],
    37601: [11499, 11389, 8389, 11566, 15320, 15320, 12320],
    37801: [11583, 11473, 8473, 11640, 15404, 15404, 12404],
    38001: [11667, 11557, 8557, 11713, 15488, 15488, 12488],
    38201: [11751, 11641, 8641, 11787, 15572, 15572, 12572],
    38401: [11835, 11725, 8725, 11860, 15656, 15656, 12656],
    38601: [11919, 11809, 8809, 11934, 15740, 15740, 12740],
    38801: [12003, 11893, 8893, 12007, 15824, 15824, 12824],
    39001: [12087, 11977, 8977, 12081, 15908, 15908, 12908],
    39201: [12171, 12061, 9061, 12154, 15992, 15992, 12992],
    39401: [12255, 12145, 9145, 12228, 16076, 16076, 13076],
    39601: [12339, 12229, 9229, 12302, 16160, 16160, 13160],
    39801: [12423, 12313, 9313, 12375, 16244, 16244, 13244],
    40001: [12507, 12397, 9397, 12449, 16328, 16328, 13328],
    40201: [12591, 12481, 9481, 12522, 16412, 16412, 13412],
    40401: [12675, 12565, 9565, 12596, 16496, 16496, 13496],
    40601: [12759, 12649, 9649, 12669, 16580, 16580, 13580],
    40801: [12843, 12733, 9733, 12743, 16664, 16664, 13664],
    41001: [12927, 12817, 9817, 12816, 16748, 16748, 13748],
    41201: [13011, 12901, 9901, 12890, 16832, 16832, 13832],
    41401: [13095, 12985, 9985, 12963, 16916, 16916, 13916],
    41601: [13179, 13069, 10069, 13037, 17000, 17000, 14000],
    41801: [13263, 13153, 10153, 13111, 17084, 17084, 14084],
    42001: [13347, 13237, 10237, 13184, 17168, 17168, 14168],
    42201: [13431, 13321, 10321, 13258, 17252, 17252, 14252],
    42401: [13515, 13405, 10405, 13331, 17336, 17336, 14336],
    42601: [13599, 13489, 10489, 13405, 17420, 17420, 14420],
    42801: [13683, 13573, 10573, 13478, 17504, 17504, 14504],
    43001: [13767, 13657, 10657, 13552, 17588, 17588, 14588],
    43201: [13851, 13741, 10741, 13625, 17672, 17672, 14672],
    43401: [13935, 13825, 10825, 13699, 17756, 17756, 14756],
    43601: [14019, 13909, 10909, 13772, 17840, 17840, 14840],
    43801: [14103, 13993, 10993, 13846, 17924, 17924, 14924],
    44001: [14187, 14077, 11077, 13920, 18008, 18008, 15008],
    44201: [14271, 14161, 11161, 13993, 18092, 18092, 15092],
    44401: [14355, 14245, 11245, 14067, 18176, 18176, 15176],
    44601: [14439, 14329, 11329, 14140, 18260, 18260, 15260],
    44801: [14523, 14413, 11413, 14214, 18344, 18344, 15344],
    45001: [14607, 14497, 11497, 14287, 18428, 18428, 15428],
    45201: [14691, 14581, 11581, 14361, 18512, 18512, 15512],
    45401: [14775, 14665, 11665, 14434, 18596, 18596, 15596],
    45601: [14859, 14749, 11749, 14508, 18680, 18680, 15680],
    45801: [14943, 14833, 11833, 14581, 18764, 18764, 15764],
    46001: [15027, 14917, 11917, 14655, 18848, 18848, 15848],
    46201: [15111, 15001, 12001, 14729, 18932, 18932, 15932],
    46401: [15195, 15085, 12085, 14802, 19016, 19016, 16016],
    46601: [15279, 15169, 12169, 14876, 19100, 19100, 16100],
    46801: [15363, 15253, 12253, 14949, 19184, 19184, 16184],
    47001: [15447, 15337, 12337, 15023, 19268, 19268, 16268],
    47201: [15531, 15421, 12421, 15096, 19352, 19352, 16352],
    47401: [15615, 15505, 12505, 15170, 19436, 19436, 16436],
    47601: [15699, 15589, 12589, 15243, 19520, 19520, 16520],
    47801: [15783, 15673, 12673, 15317, 19604, 19604, 16604],
    48001: [15867, 15757, 12757, 15390, 19688, 19688, 16688],
    48201: [15951, 15841, 12841, 15464, 19772, 19772, 16772],
    48401: [16035, 15925, 12925, 15538, 19856, 19856, 16856],
    48601: [16119, 16009, 13009, 15611, 19940, 19940, 16940],
    48801: [16203, 16093, 13093, 15685, 20024, 20024, 17024],
    49001: [16287, 16177, 13177, 15758, 20108, 20108, 17108],
    49201: [16371, 16261, 13261, 15832, 20192, 20192, 17192],
    49401: [16455, 16345, 13345, 15905, 20276, 20276, 17276],
    49601: [16539, 16429, 13429, 15979, 20360, 20360, 17360],
    49801: [16623, 16513, 13513, 16052, 20444, 20444, 17444],
    50001: [16707, 16597, 13603, 16126, 20528, 20528, 17534],
    50201: [16791, 16727, 13739, 16199, 20612, 20612, 17624],
    50401: [16875, 16860, 13878, 16273, 20696, 20696, 17714],
    50601: [16959, 16996, 14020, 16347, 20780, 20780, 17804],
    50801: [17043, 17133, 14163, 16420, 20864, 20864, 17894],
    51001: [17135, 17269, 14305, 16502, 20956, 20956, 17992],
    51201: [17259, 17406, 14448, 16616, 21080, 21080, 18122],
    51401: [17383, 17542, 14590, 16729, 21204, 21204, 18252],
    51601: [17507, 17679, 14733, 16843, 21328, 21328, 18382],
    51801: [17631, 17815, 14875, 16956, 21452, 21452, 18512],
    52001: [17755, 17948, 15014, 17070, 21576, 21576, 18642],
    52201: [17879, 18085, 15157, 17183, 21700, 21700, 18772],
    52401: [18003, 18221, 15299, 17297, 21824, 21824, 18902],
    52601: [18127, 18358, 15442, 17410, 21948, 21948, 19032],
    52801: [18251, 18494, 15584, 17524, 22072, 22072, 19162],
    53001: [18375, 18631, 15727, 17637, 22196, 22196, 19292],
    53201: [18499, 18767, 15869, 17751, 22320, 22320, 19422],
    53401: [18623, 18904, 16012, 17864, 22444, 22444, 19552],
    53601: [18747, 19037, 16151, 17978, 22568, 22568, 19682],
    53801: [18871, 19173, 16293, 18092, 22692, 22692, 19812],
    54001: [18995, 19310, 16436, 18205, 22816, 22816, 19942],
    54201: [19119, 19446, 16578, 18319, 22940, 22940, 20072],
    54401: [19243, 19583, 16721, 18432, 23064, 23064, 20202],
    54601: [19367, 19719, 16863, 18546, 23188, 23188, 20332],
    54801: [19491, 19856, 17006, 18659, 23312, 23312, 20462],
    55001: [19615, 19992, 17148, 18773, 23436, 23436, 20592],
    55201: [19739, 20129, 17291, 18886, 23560, 23560, 20722],
    55401: [19863, 20262, 17430, 19000, 23684, 23684, 20852],
    55601: [19987, 20398, 17572, 19113, 23808, 23808, 20982],
    55801: [20111, 20535, 17715, 19227, 23932, 23932, 21112],
    56001: [20235, 20622, 17808, 19341, 24056, 24056, 21242],
    56201: [20359, 20706, 17898, 19454, 24180, 24180, 21372],
    56401: [20483, 20790, 17988, 19568, 24304, 24304, 21502],
    56601: [20607, 20874, 18078, 19681, 24428, 24428, 21632],
    56801: [20731, 20972, 18182, 19795, 24552, 24552, 21762],
    57001: [20855, 21096, 18312, 19908, 24676, 24676, 21892],
    57201: [20979, 21220, 18442, 20022, 24800, 24800, 22022],
    57401: [21103, 21344, 18572, 20135, 24924, 24924, 22152],
    57601: [21227, 21468, 18702, 20249, 25048, 25048, 22282],
    57801: [21351, 21592, 18832, 20362, 25172, 25172, 22412],
    58001: [21475, 21716, 18962, 20476, 25296, 25296, 22542],
    58201: [21599, 21840, 19092, 20590, 25420, 25420, 22672],
    58401: [21723, 21964, 19222, 20703, 25544, 25544, 22802],
    58601: [21847, 22088, 19352, 20817, 25668, 25668, 22932],
    58801: [21971, 22212, 19482, 20930, 25792, 25792, 23062],
    59001: [22095, 22336, 19612, 21044, 25916, 25916, 23192],
    59201: [22224, 22465, 19747, 21157, 26040, 26040, 23322],
    59401: [22354, 22594, 19882, 21271, 26164, 26164, 23452],
    59601: [22484, 22728, 20022, 21384, 26288, 26288, 23582],
    59801: [22614, 22857, 20157, 21498, 26412, 26412, 23712],
    60001: [22744, 22987, 20293, 21611, 26536, 26536, 23842],
    60201: [22874, 23121, 20433, 21725, 26660, 26660, 23972],
    60401: [23004, 23250, 20568, 21839, 26784, 26784, 24102],
    60601: [23134, 23384, 20708, 21952, 26908, 26908, 24232],
    60801: [23264, 23514, 20844, 22066, 27032, 27032, 24362],
    61001: [23394, 23643, 20979, 22179, 27156, 27156, 24492],
    61201: [23524, 23777, 21119, 22293, 27280, 27280, 24622],
    61401: [23654, 23906, 21254, 22406, 27404, 27404, 24752],
    61601: [23784, 24035, 21389, 22520, 27528, 27528, 24882],
    61801: [23914, 24170, 21530, 22633, 27652, 27652, 25012],
    62001: [24044, 24299, 21665, 22747, 27776, 27776, 25142],
    62201: [24174, 24433, 21805, 22860, 27900, 27900, 25272],
    62401: [24304, 24562, 21940, 22974, 28024, 28024, 25402],
    62601: [24434, 24692, 22076, 23088, 28148, 28148, 25532],
    62801: [24564, 24826, 22216, 23201, 28272, 28272, 25662],
    63001: [24694, 24955, 22351, 23315, 28396, 28396, 25792],
    63201: [24824, 25084, 22486, 23428, 28520, 28520, 25922],
    63401: [24954, 25219, 22627, 23542, 28644, 28644, 26052],
    63601: [25084, 25348, 22762, 23655, 28768, 28768, 26182],
    63801: [25214, 25482, 22902, 23769, 28892, 28892, 26312],
    64001: [25344, 25611, 23037, 23882, 29016, 29016, 26442],
    64201: [25474, 25740, 23172, 23996, 29140, 29140, 26572],
    64401: [25604, 25875, 23313, 24109, 29264, 29264, 26702],
    64601: [25734, 26004, 23448, 24223, 29388, 29388, 26832],
    64801: [25864, 26133, 23583, 24337, 29512, 29512, 26962],
    65001: [25994, 26267, 23723, 24450, 29636, 29636, 27092],
    65201: [26124, 26397, 23859, 24564, 29760, 29760, 27222],
    65401: [26254, 26531, 23999, 24677, 29884, 29884, 27352],
    65601: [26384, 26660, 24134, 24791, 30008, 30008, 27482],
    65801: [26514, 26789, 24269, 24904, 30132, 30132, 27612],
    66001: [26644, 26924, 24410, 25018, 30256, 30256, 27742],
    66201: [26774, 27053, 24545, 25131, 30380, 30380, 27872],
    66401: [26904, 27182, 24680, 25245, 30504, 30504, 28002],
    66601: [27034, 27316, 24820, 25358, 30628, 30628, 28132],
    66801: [27164, 27445, 24955, 25472, 30752, 30752, 28262],
    67001: [27294, 27580, 25096, 25586, 30876, 30876, 28392],
    67201: [27424, 27709, 25231, 25699, 31000, 31000, 28522],
    67401: [27554, 27838, 25366, 25813, 31124, 31124, 28652],
    67601: [27684, 27972, 25506, 25926, 31248, 31248, 28782],
    67801: [27814, 28102, 25642, 26040, 31372, 31372, 28912],
    68001: [27944, 28236, 25782, 26153, 31496, 31496, 29042],
    68201: [28074, 28365, 25917, 26267, 31620, 31620, 29172],
    68401: [28204, 28494, 26052, 26380, 31744, 31744, 29302],
    68601: [28334, 28629, 26193, 26494, 31868, 31868, 29432],
    68801: [28464, 28758, 26328, 26607, 31992, 31992, 29562],
    69001: [28594, 28887, 26463, 26721, 32116, 32116, 29692],
    69201: [28724, 29021, 26603, 26835, 32240, 32240, 29822],
    69401: [28854, 29150, 26738, 26948, 32364, 32364, 29952],
    69601: [28984, 29285, 26879, 27062, 32488, 32488, 30082],
    69801: [29114, 29414, 27014, 27175, 32612, 32612, 30212],
    70001: [29244, 29543, 27149, 27289, 32736, 32736, 30342],
    70201: [29374, 29677, 27289, 27402, 32860, 32860, 30472],
    70401: [29504, 29807, 27425, 27516, 32984, 32984, 30602],
    70601: [29634, 29936, 27560, 27629, 33108, 33108, 30732],
    70801: [29764, 30070, 27700, 27743, 33232, 33232, 30862],
    71001: [29894, 30199, 27835, 27856, 33356, 33356, 30992],
    71201: [30024, 30334, 27976, 27970, 33480, 33480, 31122],
    71401: [30154, 30463, 28111, 28084, 33604, 33604, 31252],
    71601: [30284, 30592, 28246, 28197, 33728, 33728, 31382],
    71801: [30414, 30726, 28386, 28311, 33852, 33852, 31512],
    72001: [30544, 30855, 28521, 28424, 33976, 33976, 31642],
    72201: [30674, 30985, 28657, 28538, 34100, 34100, 31772],
    72401: [30804, 31119, 28797, 28651, 34224, 34224, 31902],
    72601: [30934, 31248, 28932, 28765, 34348, 34348, 32032],
    72801: [31064, 31382, 29072, 28878, 34472, 34472, 32162],
    73001: [31194, 31512, 29208, 28992, 34596, 34596, 32292],
    73201: [31324, 31641, 29343, 29105, 34720, 34720, 32422],
    73401: [31454, 31775, 29483, 29219, 34844, 34844, 32552],
    73601: [31584, 31904, 29618, 29333, 34968, 34968, 32682],
    73801: [31714, 32033, 29753, 29446, 35092, 35092, 32812],
    74001: [31844, 32168, 29894, 29560, 35216, 35216, 32942],
    74201: [31974, 32297, 30029, 29673, 35340, 35340, 33072],
    74401: [32104, 32431, 30169, 29787, 35464, 35464, 33202],
    74601: [32234, 32560, 30304, 29900, 35588, 35588, 33332],
    74801: [32364, 32690, 30440, 30014, 35712, 35712, 33462],
    75001: [32494, 32824, 30580, 30127, 35836, 35836, 33592],
    75201: [32624, 32953, 30715, 30241, 35960, 35960, 33722],
    75401: [32754, 33082, 30850, 30354, 36084, 36084, 33852],
    75601: [32884, 33217, 30991, 30468, 36208, 36208, 33982],
    75801: [33014, 33346, 31126, 30582, 36332, 36332, 34112],
    76001: [33144, 33480, 31266, 30695, 36456, 36456, 34242],
    76201: [33274, 33609, 31401, 30809, 36580, 36580, 34372],
    76401: [33404, 33738, 31536, 30922, 36704, 36704, 34502],
    76601: [33534, 33873, 31677, 31036, 36828, 36828, 34632],
    76801: [33664, 34002, 31812, 31149, 36952, 36952, 34762],
    77001: [33794, 34136, 31952, 31263, 37076, 37076, 34892],
    77201: [33924, 34265, 32087, 31376, 37200, 37200, 35022],
    77401: [34054, 34395, 32223, 31490, 37324, 37324, 35152],
    77601: [34184, 34529, 32363, 31603, 37448, 37448, 35282],
    77801: [34314, 34658, 32498, 31717, 37572, 37572, 35412],
    78001: [34444, 34787, 32633, 31831, 37696, 37696, 35542],
    78201: [34574, 34922, 32774, 31944, 37820, 37820, 35672],
    78401: [34704, 35051, 32909, 32058, 37944, 37944, 35802],
    78601: [34834, 35185, 33049, 32171, 38068, 38068, 35932],
    78801: [34964, 35314, 33184, 32285, 38192, 38192, 36062],
    79001: [35094, 35443, 33319, 32398, 38316, 38316, 36192],
    79201: [35224, 35578, 33460, 32512, 38440, 38440, 36322],
    79401: [35354, 35707, 33595, 32625, 38564, 38564, 36452],
    79601: [35484, 35836, 33730, 32739, 38688, 38688, 36582],
    79801: [35614, 35970, 33870, 32852, 38812, 38812, 36712],
    80001: [45, 45, 42, 41, 49, 49, 46, '%'],
    80401: [45, 45, 43, 41, 49, 49, 46, '%'],
    82001: [45, 46, 43, 41, 49, 49, 46, '%'],
    82201: [45, 46, 43, 42, 49, 49, 46, '%'],
    82601: [45, 46, 43, 42, 49, 49, 47, '%'],
    83601: [45, 46, 44, 42, 49, 49, 47, '%'],
    84001: [46, 46, 44, 42, 49, 49, 47, '%'],
    86201: [46, 46, 44, 42, 50, 50, 47, '%'],
    86401: [46, 47, 44, 42, 50, 50, 47, '%'],
    87001: [46, 47, 45, 42, 50, 50, 47, '%'],
    87201: [46, 47, 45, 42, 50, 50, 48, '%'],
    88001: [46, 47, 45, 43, 50, 50, 48, '%'],
    88401: [47, 47, 45, 43, 50, 50, 48, '%'],
    90801: [47, 47, 46, 43, 50, 50, 48, '%'],
    91201: [47, 48, 46, 43, 50, 50, 48, '%'],
    92601: [47, 48, 46, 43, 50, 50, 49, '%'],
    93601: [48, 48, 46, 43, 50, 50, 49, '%'],
    93801: [48, 48, 46, 43, 51, 51, 49, '%'],
    94601: [48, 48, 46, 44, 51, 51, 49, '%'],
    95001: [48, 48, 47, 44, 51, 51, 49, '%'],
    96401: [48, 49, 47, 44, 51, 51, 49, '%'],
    98601: [48, 49, 47, 44, 51, 51, 50, '%'],
    99201: [49, 49, 47, 44, 51, 51, 50, '%'],
    99601: [49, 49, 48, 44, 51, 51, 50, '%'],
    102201: [49, 49, 48, 45, 51, 51, 50, '%'],
    102601: [49, 50, 48, 45, 52, 52, 50, '%'],
    104401: [49, 50, 49, 45, 52, 52, 50, '%'],
    105401: [49, 50, 49, 45, 52, 52, 51, '%'],
    105601: [50, 50, 49, 45, 52, 52, 51, '%'],
    109401: [50, 51, 49, 45, 52, 52, 51, '%'],
    110001: [50, 51, 50, 45, 52, 52, 51, '%'],
    111401: [50, 51, 50, 46, 52, 52, 51, '%'],
    113001: [51, 51, 50, 46, 52, 52, 51, '%'],
    113201: [51, 51, 50, 46, 52, 52, 52, '%'],
    113401: [51, 51, 50, 46, 53, 53, 52, '%'],
    116001: [51, 51, 51, 46, 53, 53, 52, '%'],
    117201: [51, 52, 51, 46, 53, 53, 52, '%'],
    121201: [52, 52, 51, 46, 53, 53, 52, '%'],
    122201: [52, 52, 51, 47, 53, 53, 53, '%'],
    122801: [52, 52, 52, 47, 53, 53, 53, '%'],
    126001: [52, 53, 52, 47, 53, 53, 53, '%'],
    126801: [52, 53, 52, 47, 54, 54, 53, '%'],
    130601: [52, 53, 53, 47, 54, 54, 53, '%'],
    131001: [53, 53, 53, 47, 54, 54, 53, '%'],
    132801: [53, 53, 53, 47, 54, 54, 54, '%'],
    135401: [53, 53, 53, 48, 54, 54, 54, '%'],
    136601: [53, 54, 53, 48, 54, 54, 54, '%'],
    139201: [53, 54, 54, 48, 54, 54, 54, '%'],
    142401: [54, 54, 54, 48, 54, 54, 54, '%'],
    143801: [54, 54, 54, 48, 55, 55, 54, '%'],
    145401: [54, 54, 54, 48, 55, 55, 55, '%'],
    148801: [54, 55, 54, 48, 55, 55, 55, '%'],
    149201: [54, 55, 55, 48, 55, 55, 55, '%'],
    151801: [54, 55, 55, 49, 55, 55, 55, '%'],
    156001: [55, 55, 55, 49, 55, 55, 55, '%'],
    165801: [55, 56, 56, 49, 56, 56, 56, '%'],
    172401: [56, 56, 56, 49, 56, 56, 56, '%'],
    172601: [56, 56, 56, 50, 56, 56, 56, '%'],
    196001: [57, 57, 57, 50, 57, 57, 57, '%'],
    200201: [57, 57, 57, 51, 57, 57, 57, '%'],
    238201: [57, 57, 57, 52, 57, 57, 57, '%'],
    239601: [58, 58, 58, 52, 58, 58, 58, '%'],
    294001: [58, 58, 58, 53, 58, 58, 58, '%'],
    308201: [59, 59, 59, 53, 59, 59, 59, '%'],
    383801: [59, 59, 59, 54, 59, 59, 59, '%'],
    431401: [60, 60, 60, 54, 60, 60, 60, '%'],
    552801: [60, 60, 60, 55, 60, 60, 60, '%'],
    719001: [61, 61, 61, 55, 61, 61, 61, '%'],
    987401: [61, 61, 61, 56, 61, 61, 61, '%']
  }
};
