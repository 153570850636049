import { calculateTax } from '@sparbanken-syd/sparbanken-tax';
/**
 * How to use
 *
 * Whatever you put in will get modified. We will modify the incoming
 * objects, e.g. an applicant will get its tax set. If you
 * do not want that, send in *copies*! All items are available as modified
 * objects from the applicants, cars, properties etc. arrays. Be careful!
 *
 */
export class Kalpylator {
  constructor(parameters = {}) {
    /**
     * INCOME
     */
    /**
     * The total net income per month, incomes - costs
     */
    this.monthlyNetIncome = 0;
    /**
     * All incomes (before tax)
     */
    this.monthlyIncome = 0;
    /**
     * All _income_ *costs* including tax
     */
    this.monthlyCost = 0;
    /**
     * All taxfree incomes for all incomes per month
     */
    this.monthlyTaxFreeIncome = 0;
    /**
     * Sum up the study loans, SEK/Month
     */
    this.monthlyStudyLoan = 0;
    /**
     * Sum of tax for all applicants, month in SEK
     */
    this.monthlyIncomeTax = 0;
    /**
     * Sum of other costs per month in SEK
     */
    this.monthlyOtherCosts = 0;
    /**
     * Indicates that one or more applicants have reduced income.
     */
    this.reducedIncome = false;
    /**
     * The total amount of interest paid per year
     */
    this.yearlyLoanInterestAmount = 0;
    /**
     * The tax return on loans per year
     */
    this.yearlyLoanTaxReturn = 0;
    /**
     * The tax return per month
     */
    this.monthlyLoanTaxReturn = 0;
    /**
     * Applicant age, youngest? Oldest? Average?
     * This is added to make sure that we can from the outside
     * make decisions based on "age".
     */
    this.applicantsAge = 0;
    this.applicantsMaxAge = 0;
    this.applicantsMinAge = 0;
    this.applicantsAverageAge = 0;
    this.applicantsAges = [];
    /**
     * LOANS
     */
    /**
     * The sum of all loan Mortgage per Month
     */
    this.monthlyLoanMortgage = 0;
    /**
     * Divided by 12
     */
    this.monthlyLoanInterest = 0;
    /**
     * Monthly cost for loan total, amortization + interest - tax return!
     */
    this.monthlyLoanCost = 0;
    /**
     * This is the total of the _new_ loans added. This can be overridden to give
     * a new KALP. It will override the loan amount and it _will_ impact tax reduction
     * "räntebidrag" etc.
     */
    this.newLoanTotalAmount = 0;
    /**
     * The total living expense of this application/
     * household
     */
    this.monthlyLivingExpense = 0;
    /**
     * Then number of midgets total
     */
    this.childrenCount = 0;
    /**
     * The total cost of housing those this is the
     * sum of the "livingExpense" for children.
     */
    this.monthlyChildrenCost = 0;
    /**
     * The normal "barnbidrag" sum for a Month!
     */
    this.childrenBenefitTotal = 0;
    /**
     * LIVING EXPENSE
     */
    /**
     * The so called "flerbarnstillägg", per bunch, per Month
     */
    this.childrenBenefitExtra = 0;
    /**
     * Total income from 'em, again as a Monthly value, both
     * children benefit (barnbidrag) and extra (flerbarnstillägg)
     */
    this.monthlyChildrenIncome = 0;
    /**
     * Debt Quota, this is expressed as a fraction of loans / income,
     * so if you want to show it to the bank, multiply by 100
     */
    this.debtQuota = null;
    /**
     * "Belåningsgrad", sum of loans / value with some
     * exceptions, for primary building. A building can have
     * exceptions on Amortization values.
     */
    this.loanDegree = 0;
    /**
     * The amount of amortization required in percent
     * based on loan degree and debt quota. Relates to
     * primary building only. It is 0, 0.01, 0.02 or 0.03
     */
    this.amortizationRequirement = 0 | 0.01 | 0.02 | 0.03;
    /**
     * Annual income is all income + child benefits
     */
    this.annualTotalIncome = 0;
    /**
     * Total amount of all mortgage loans. Used primarily
     * for Loan Degree
     */
    this.totalMortgageLoanAmount = 0;
    /**
     * Mirror these from primary building,
     * Note that the mortgageRequirement debt is set
     * in relation to sumOfNewLoans
     */
    this.mortgageRequirementDebt = 0;
    this.mortgageRequirementValue = 0;
    /**
     * The Market Value of the primary property, cf. the
     * mortgageRequirementValue
     */
    this.marketValue = 0;
    /**
     * Living cost, the total of all "properties", per month.
     * Includes rent, "runCost" and tax for all properties
     */
    this.monthlyPropertyCost = 0;
    /**
     * Total "Fastighetsavgift" for all properties
     */
    this.monthlyPropertyTax = 0;
    /**
     * Sum of all rent/fee
     */
    this.monthlyPropertyRent = 0;
    /**
     * Sum of all run costs
     */
    this.monthlyPropertyRunCost = 0;
    /**
     * Cars
     */
    /**
     * Monthly car cost is the cost of all cars present
     * no matter what car it is.
     */
    this.monthlyCarCost = 0;
    /**
     * The absolute total number of cars
     */
    this.carCount = 0;
    /**
     * The number of owned cars
     */
    this.ownedCarCount = 0;
    /**
     * The number of lease cars
     */
    this.leasedCarCount = 0;
    /**
     * The cost of owned cars
     */
    this.ownedCarCost = 0;
    /**
     * The cost of lease cars
     */
    this.leaseCarCost = 0;
    /**
     * Semi-private set of items
     */
    this.applicants = [];
    this.incomes = [];
    this.properties = [];
    this.loans = [];
    this.children = [];
    this.cars = [];
    this.pets = [];
    /**
     * We have setters for these
     */
    this.increasedInterest = 0;
    this.pMonthlyIncomeOverride = null;
    this.pNewLoanTotalAmountOverride = null;
    this.parameters = {
      defaultTaxTable: 33,
      domain: 'default',
      livingExpenseDeduction: [],
      livingExpenses: [{
        age: 65,
        expense: 9100
      }, {
        age: 200,
        expense: 5000
      }],
      cityDwellerFactor: 1,
      almostRetiredThreshold: 62,
      almostRetiredLivingExpense: 5000,
      almostRetiredIncomeFactor: 0.7,
      childrenBenefit: 1250,
      childrenBenefitExtra: [0, 0, 150, 730, 1740, 2990, 4240],
      loanDefaults: {
        'MORTGAGE': {
          interest: 0.05,
          mortgagePercent: 0.05
        },
        'BLANCO': {
          interest: 0.9,
          mortgagePercent: 0.1
        },
        'CREDIT': {
          interest: 0.1,
          mortgagePercent: 0.1
        },
        'BORGEN': {
          interest: 0.1,
          mortgagePercent: 0.1
        }
      },
      propertyDefaults: {
        'VILLA': {
          runCost: 4500,
          yearlyTax: 9287
        },
        'HYRES': {
          runCost: 200,
          yearlyTax: 0
        },
        'BOSTADSRATT': {
          runCost: 200,
          yearlyTax: 0
        },
        'FRITIDSHUS': {
          runCost: 1500,
          yearlyTax: 9287
        }
      },
      carDefaults: {
        'LEASE': {
          cost: 0 // Cost shite in SPB
        },

        'OWN': {
          cost: 0 // Cost nothing in SPB
        }
      }
    };

    this.setParameters(parameters);
  }
  /**
   * Set this to override the Monthly income in simulations.
   * If this value is set it will be used in the income calculations
   * and totally override the total *monthlyIncome* before calculating
   * the KALP. It will NOT impact e.g. tax calculations.
   *
   * It increases, or decreases the income _before_ tax and impacts the KALP
   * only. So it does not matter if it is taxfree income or taxable income
   * or "other costs" or anything that is in effect. It just increases that
   * number before the KALP is made.
   */
  get monthlyIncomeOverride() {
    return this.pMonthlyIncomeOverride;
  }
  set monthlyIncomeOverride(monthlyIncomeOverride) {
    this.pMonthlyIncomeOverride = monthlyIncomeOverride;
    this.calculate();
  }
  /**
   * It is possible to override the total amount of loans.
   * If this number is set the "new" loan, must be only one (1)
   * will get this as it value. If you enter several new loans
   * the new amount will be distributed evenly over the new loans
   * provided.
   */
  get newLoanTotalAmountOverride() {
    return this.pNewLoanTotalAmountOverride;
  }
  set newLoanTotalAmountOverride(newLoanTotalAmountOverride) {
    this.pNewLoanTotalAmountOverride = newLoanTotalAmountOverride;
    this.calculate();
  }
  /**
   * Do the calculations
   */
  calculate() {
    this.setApplicants();
    this.setIncomes();
    this.setCars();
    this.setChildren(); // Children are included in DebtQuota and must be done before properties
    this.setProperties();
    this.setLoans();
    this.calculateDebtQuota();
    return this.makeKalp();
  }
  /**
   * Add an item of a type
   */
  add(i) {
    this[i.type].push(i);
  }
  /**
   * Replace all items of type. You cannot reset
   * using this method. Note that you can from outside.
   * I need to think about this shite.
   */
  set(is) {
    if (is.length === 0) {
      return;
    }
    this[is[0].type] = is;
  }
  /**
   * Remove one item based on id.
   */
  remove(id, type) {
    this[type] = this[type].filter(e => e.id !== id);
  }
  /**
   * Set increased interest for all loans.
   *
   * @param increase - A number in points e.g. 100 => 1%, 250 => 2.5%
   */
  setIncreasedInterest(increase) {
    this.increasedInterest = increase / 100 / 100;
    this.calculate();
  }
  /**
   * Overrides the defaults, sort of.
   */
  setParameters(parameters) {
    Object.assign(this.parameters, parameters);
  }
  /**
   * The KALP is the end result
   */
  makeKalp() {
    /**
     * If override is set use that or use the calculated value
     */
    const income = this.monthlyIncome;
    // Bolånerådgivning special
    this.budget = this.monthlyLivingExpense + this.monthlyChildrenCost;
    this.income = income + this.monthlyChildrenIncome;
    this.cost = this.monthlyCost + this.monthlyPropertyCost + this.monthlyLoanCost + this.monthlyCarCost;
    /**
     * Note how the override is already set, and we use _this.income_
     */
    this.kalp = this.income - this.budget - this.cost;
    return this.kalp;
  }
  setCars() {
    this.monthlyCarCost = 0;
    const ownedCars = this.cars.filter(c => c.carType === 'OWN');
    this.ownedCarCost = this.sumCarCost(ownedCars, 'OWN');
    this.ownedCarCount = ownedCars.length;
    const leaseCars = this.cars.filter(c => c.carType === 'LEASE');
    this.leaseCarCost = this.sumCarCost(leaseCars, 'LEASE');
    this.leasedCarCount = leaseCars.length;
    this.carCount = this.leasedCarCount + this.ownedCarCount;
    this.monthlyCarCost = this.leaseCarCost + this.ownedCarCost;
  }
  /**
   * Sum up the cost of a certain car type. It uses the set value (zero
   * if not present) if that is higher than the default
   */
  sumCarCost(cars, type) {
    return cars.map(c => c.monthlyCost ?? 0).reduce((acc, cur) => acc + Math.max(cur, this.parameters.carDefaults[type].cost), 0);
  }
  /**
   * Calculate the interest total per year.
   */
  getYearlyLoanInterest() {
    this.yearlyLoanInterestAmount = this.loans.map(loan => loan.amount * (loan.interestRate + this.increasedInterest)).reduce((total, interest) => total + interest, 0);
    return this.yearlyLoanInterestAmount;
  }
  /**
   * The yearly tax return, based on all loans.
   * @param interest - The total amount of interest on a year
   */
  calculateInterestReturn(interest) {
    const highReturnLimit = 100 * 1000;
    this.yearlyLoanTaxReturn = 0;
    if (interest > highReturnLimit) {
      this.yearlyLoanTaxReturn = (interest - highReturnLimit) * 0.21 + 30000;
    } else {
      this.yearlyLoanTaxReturn = interest * 0.30;
    }
    return this.yearlyLoanTaxReturn;
  }
  setApplicants() {
    this.applicants.forEach(applicant => this.calculateTaxAge(applicant));
    this.monthlyLivingExpense = this.getLivingExpense();
    /**
     * Do applicant age calculus
     */
    this.applicantsMaxAge = Math.max(...this.applicantsAges);
    this.applicantsMinAge = Math.min(...this.applicantsAges);
    const totalAge = this.applicantsAges.reduce((sum, age) => sum + age, 0);
    this.applicantsAverageAge = totalAge / this.applicantsAges.length;
    this.applicantsAge = this.applicantsMinAge;
  }
  /**
   * Do all the things for every loan we have.
   * Set the class summaries
   * @private
   */
  setLoans() {
    this.monthlyLoanCost = 0;
    this.monthlyLoanInterest = 0;
    this.monthlyLoanMortgage = 0;
    this.yearlyLoanInterestAmount = 0;
    /**
     * Check all loans for original amount. Amount is never 0
     * or negative, only a positive value or null or undefined.
     */
    this.loans.forEach(loan => {
      if (!loan.originalAmount) {
        loan.originalAmount = loan.amount;
      }
    });
    /**
     * New loans are the loans that are added to the calculations.
     * If you do a "before" kalp the loans in the Kalpylator must not
     * include any "new" loans. But every KALP that includes new loans
     * will be summed up here before all other calculations.
     * If a loan pertains to a belåningsobjekt but is an existing loan,
     * then this loan has the ID "new_property_extra".
     */
    // Sum up the amount of new loans
    this.newLoanTotalAmount = this.newLoanTotalAmount = this.loans.filter(loan => loan.new && loan.id !== 'new_property_extra').reduce((acc, loan) => acc + loan.amount, 0);
    const factors = this.loans.filter(loan => loan.new && loan.id !== 'new_property_extra').map(loan => loan.amount / this.newLoanTotalAmount);
    // Each factor represents one loan, no more no less. We iterate over
    // the (new) loans and set a new amount based on new loan total and factor.
    this.loans.filter(loan => loan.new && loan.id !== 'new_property_extra').forEach((loan, index) => {
      if (this.newLoanTotalAmountOverride !== null) {
        loan.amount = this.pNewLoanTotalAmountOverride * factors[index];
      } else {
        loan.amount = loan.originalAmount;
      }
    });
    // Process NEW MORTGAGE loans. NEW MORTGAGE loans should have its
    // mortgage _percentage_ set by the Amortization requirement.
    this.loans.filter(loan => !!loan.new && loan.loanType === 'MORTGAGE').forEach(loan => {
      if (loan.mortgage < 0) {
        loan.mortgage = this.amortizationRequirement * loan.amount / 12;
      }
    });
    // The below is valid for ALL LOANS
    this.loans.forEach(loan => {
      // Set the mortgage for all loans that do not have mortgage,
      // note that new mortgage loans never is affected b/c they
      // Have been set explicitly above
      if (loan.mortgage < 0) {
        const defaultMortgage = this.parameters.loanDefaults[loan.loanType].mortgagePercent * loan.amount / 12;
        loan.mortgage = Math.max(loan.mortgage, defaultMortgage);
      }
      // Only if explicitly set as a value < 0
      if (loan.interestRate < 0) {
        const defaultInterest = this.parameters.loanDefaults[loan.loanType].interest;
        loan.interestRate = Math.max(loan.interestRate, defaultInterest);
      }
      // Amortizing If Tilläggslån, if it is we always, non conditionally
      // set the loan mortgage, but the interest is calculated as always
      if (loan.additionalLoan) {
        loan.mortgage = loan.amount / 120;
      }
      // We set new mortgage loans to the _new_ _mortgage_ loan mortgage
      // to the lowest possible based on requirements. The interest rate is
      // set to the new mortgage loan interest rate.
      loan.monthlyInterest = loan.amount * (loan.interestRate + this.increasedInterest) / 12;
      loan.monthlyCost = loan.monthlyInterest + loan.mortgage;
      this.monthlyLoanMortgage += loan.mortgage;
      this.monthlyLoanInterest += loan.monthlyInterest;
      this.monthlyLoanCost += loan.monthlyCost;
    });
    this.yearlyLoanInterestAmount = this.getYearlyLoanInterest();
    this.monthlyLoanInterest = this.yearlyLoanInterestAmount / 12;
    this.yearlyLoanTaxReturn = this.calculateInterestReturn(this.yearlyLoanInterestAmount);
    this.monthlyLoanTaxReturn = this.yearlyLoanTaxReturn / 12;
    this.monthlyLoanCost = this.monthlyLoanCost - this.monthlyLoanTaxReturn;
  }
  /**
   * Please remember, property is a building, a house.
   */
  setProperties() {
    /**
     * We must calculate the debtQuota before we set the laons.
     * These calculations depend on the loan _amount_ only
     */
    this.calculateDebtQuota();
    this.monthlyPropertyCost = 0;
    this.monthlyPropertyTax = 0;
    this.monthlyPropertyRent = 0;
    this.monthlyPropertyRunCost = 0;
    this.properties.forEach(property => {
      // Set the loan degree to
      property.degree = null;
      property.runCost = property.runCost || 0;
      property.rent = property.rent || 0;
      const loans = this.loans.filter(loan => loan.propertyId === property.id);
      property.sumOfLoans = loans.map(loan => loan.amount).reduce((total, amount) => total + amount, 0);
      const tax = Math.ceil(this.parameters.propertyDefaults[property.propertyType].yearlyTax / 12);
      const runCost = Math.max(this.parameters.propertyDefaults[property.propertyType].runCost, property.runCost);
      property.monthlyCost = runCost + tax + property.rent;
      property.tax = tax;
      property.runCost = runCost;
      if (property.primary === true) {
        this.setPrimaryBuilding(property);
      }
      this.monthlyPropertyCost += property.monthlyCost;
      this.monthlyPropertyTax += property.tax;
      this.monthlyPropertyRent += property.rent;
      this.monthlyPropertyRunCost += property.runCost;
    });
  }
  /**
   * Calculations for the primary building, sets the loan degree and the mortgage
   * requirement values and finally sets the Amortization Requirement.
   */
  setPrimaryBuilding(property) {
    property.marketValue = property.marketValue || property.loanAmount;
    property.mortgageRequirementDebt = property.mortgageRequirementDebt || property.sumOfLoans;
    property.mortgageRequirementValue = property.mortgageRequirementValue || property.marketValue;
    this.mortgageRequirementValue = property.mortgageRequirementValue;
    this.marketValue = property.marketValue;
    this.mortgageRequirementDebt = property.mortgageRequirementDebt;
    property.degree = property.mortgageRequirementDebt / property.mortgageRequirementValue;
    this.calculateAmortizationRequirement(property);
  }
  /**
   * Calulates the amortization requirement, note that debtQuota should
   * have been calculated first. Can be done on any building.
   *
   * @param property - The building we use as base
   * @private
   */
  calculateAmortizationRequirement(property) {
    const degree = property.degree;
    this.loanDegree = degree;
    let result = 0;
    const limits = [{
      degree: 0.5,
      result: 0.01
    }, {
      degree: 0.7,
      result: 0.02
    }];
    limits.forEach(limit => {
      if (degree > limit.degree) {
        result = limit.result;
      }
    });
    // Note that exactly 450 is ok!
    if (this.debtQuota > 4.5) {
      result += 0.01;
    }
    this.amortizationRequirement = result;
    return result;
  }
  setChildren() {
    this.childrenCount = this.children.length;
    this.monthlyChildrenCost = this.children.map(child => {
      child.monthlyCost = 0;
      child.age = child.age || 17;
      this.parameters.livingExpenses.forEach(le => {
        child.monthlyCost = child.monthlyCost === 0 && child.age <= le.age ? le.expense : child.monthlyCost;
      });
      return child.monthlyCost;
    }).reduce((total, cost) => total + cost, 0);
    this.childrenBenefitTotal = this.childrenCount * this.parameters.childrenBenefit;
    this.childrenBenefitExtra = this.parameters.childrenBenefitExtra[Math.min(this.childrenCount, 6)];
    this.monthlyChildrenIncome = this.childrenBenefitExtra + this.childrenBenefitTotal;
  }
  getLivingExpense() {
    this.applicants.forEach(applicant => {
      applicant.livingExpense = 0;
      this.applicantsAges.push(applicant.age);
      /**
       * Select based on lowest age, but if the applicant is retired we use the
       * age as if the applicant is 66? years
       */
      const age = applicant.occupationType === 'RETIRED' ? 65 : applicant.age;
      this.parameters.livingExpenses.forEach(le => {
        applicant.livingExpense = applicant.livingExpense === 0 && age < le.age ? le.expense : applicant.livingExpense;
      });
      if (this.setApplicantReducedIncome(applicant)) {
        applicant.livingExpense = this.parameters.almostRetiredLivingExpense;
      }
      if (applicant.cityDweller === true) {
        applicant.livingExpense = applicant.livingExpense * this.parameters.cityDwellerFactor;
      }
    });
    /**
     * If more than one applicant create deductions
     */
    if (this.applicants.length > 1) {
      const occupations = this.applicants.map(a => a.occupationType);
      occupations.sort((a, b) => a.localeCompare(b));
      this.parameters.livingExpenseDeduction.forEach(led => {
        led.class.sort();
        if (led.class[0] === occupations[0] && led.class[1] === occupations[1]) {
          this.applicants[1].livingExpense = this.applicants[1].livingExpense - led.deduction;
        }
      });
    }
    return this.applicants.map(a => a.livingExpense).reduce((total, le) => total + le, 0);
  }
  setIncomes() {
    this.monthlyNetIncome = 0;
    this.monthlyIncome = 0;
    this.monthlyCost = 0;
    this.monthlyTaxFreeIncome = 0;
    this.monthlyStudyLoan = 0;
    this.monthlyIncomeTax = 0;
    this.monthlyOtherCosts = 0;
    /**
     * Calculate a factor for each income.
     * The factor is the total income for all borrowers.
     */
    const factors = this.incomes.map(income => income.monthlyIncome).reduce((total, income) => total + income, 0);
    this.incomes.forEach(income => {
      /**
       * A person's income is recalculated based on the specific income divided by the household's total income.
       * Then multiplied by the income that exceeds the household's total income.
       */
      if (this.pMonthlyIncomeOverride > 0) {
        this.pOriginalIncome = income.monthlyIncome;
        income.monthlyIncome = income.monthlyIncome / factors * this.pMonthlyIncomeOverride;
      } else if (this.pOriginalIncome) {
        income.monthlyIncome = this.pOriginalIncome;
      }
      this.getTax(income);
      this.monthlyNetIncome += income.monthlyNetIncome;
      this.monthlyIncome += income.monthlyTotalIncome;
      this.monthlyCost += income.monthlyTotalCost;
      this.monthlyTaxFreeIncome += income.taxFreeIncome;
      this.monthlyStudyLoan += income.studyLoan;
      this.monthlyIncomeTax += income.monthlyTax;
      this.monthlyOtherCosts += income.otherCosts;
    });
  }
  /**
   * The Tax age is counted as being retired on this day of year
   * @param applicant
   * @private
   */
  calculateTaxAge(applicant) {
    const thisYear = new Date().getFullYear();
    const thisMonth = new Date().getMonth() + 1;
    const thisDay = new Date().getDate();
    const birthYear = Number.parseInt(applicant.sub.substring(0, 4));
    const birthMonth = Number.parseInt(applicant.sub.substring(4, 6));
    const birthDay = Number.parseInt(applicant.sub.substring(6, 8));
    let deduct = 1;
    if (birthMonth >= thisMonth && birthDay >= thisDay) {
      deduct = 0;
    }
    const age = thisYear - birthYear - deduct;
    applicant.age = age;
    return age;
  }
  /**
   * Get and set the tax for an income, returns the tax in SEK / Month
   * and sets debug values.
   * @param income
   * @private
   */
  getTax(income) {
    const tableNo = this.parameters.defaultTaxTable;
    const applicant = this.getApplicantFromIncome(income);
    let occupation = 0;
    if (applicant.occupationType === 'RETIRED') {
      occupation = 1;
    }
    const calculatedTax = calculateTax({
      personNummer: applicant.sub,
      occupation: occupation,
      income: income.monthlyIncome,
      table: tableNo,
      selectTableAfterAgeReduction: true,
      taxYear: 2023,
      municipality: applicant.municipalityCode,
      county: applicant.countyCode
    });
    income.taxColumn = calculatedTax.taxColumn;
    income.monthlyReducedIncome = income.monthlyIncome;
    if (calculatedTax.incomeAfterAgeReduction) {
      income.monthlyReducedIncome = calculatedTax.incomeAfterAgeReduction;
      income.reducedIncome = true;
    }
    income.taxTable = calculatedTax.table;
    income.monthlyTax = calculatedTax.tax;
    income.taxFreeIncome = income.taxFreeIncome || 0;
    income.studyLoan = income.studyLoan || 0;
    income.otherCosts = income.otherCosts || 0;
    income.monthlyTotalIncome = income.monthlyReducedIncome + income.taxFreeIncome;
    income.monthlyTotalCost = calculatedTax.tax + income.studyLoan + income.otherCosts;
    income.monthlyNetIncome = income.monthlyTotalIncome - income.monthlyTotalCost;
    return calculatedTax.tax;
  }
  /**
   * Very dangerous! If we do not find any we will use "EMPLOYEE" that is NOT
   * close to retirement (until ~2033). See if this can be improved.
   * This is used for a) see if income should be reduced, b) select tax column.
   */
  getApplicantFromIncome(income) {
    return this.applicants.find(a => a.id === income.applicantId) || {
      occupationType: 'EMPLOYED',
      sub: '197309193592'
    };
  }
  setApplicantReducedIncome(applicant) {
    const age = this.calculateTaxAge(applicant);
    applicant.reducedIncome = age >= this.parameters.almostRetiredThreshold && applicant.occupationType !== 'RETIRED';
    this.reducedIncome = this.reducedIncome || applicant.reducedIncome;
    return applicant.reducedIncome;
  }
  /**
   * Debt quota is calculated as the total amount (sum) of loons divided by
   * annual income, before tax, including taxfree, including children
   * benefits. Only Mortgage loans count.
   */
  calculateDebtQuota() {
    const sumOfLoans = this.loans.filter(loan => loan.loanType === 'MORTGAGE').map(loan => loan.amount).reduce((total, amount) => total + amount, 0);
    const annualIncome = this.incomes.map(income => income.monthlyIncome + income.taxFreeIncome).reduce((total, income) => total + income, 0) * 12;
    const totalIncome = annualIncome + this.childrenBenefitTotal * 12;
    this.annualTotalIncome = totalIncome;
    this.totalMortgageLoanAmount = sumOfLoans;
    this.debtQuota = sumOfLoans / totalIncome;
    return this.debtQuota;
  }
}
