import {Injectable} from '@angular/core'
import {KalpInput, TTypeOfKalp} from './kalp.service'
import {KalpylatorWrapper} from '../model/kalpylator-wrapper'
import {NGService} from './ng.service'

@Injectable({
  providedIn: 'root'
})
export class KalpylatorFactory {

  constructor(private ngService: NGService) {
  }

  public getKalpylator(kalpInput: KalpInput, extraInterest = 0, name: TTypeOfKalp = 'new'): KalpylatorWrapper {
    return new KalpylatorWrapper(this.ngService, kalpInput, extraInterest, name)
  }
}
