import {Component, OnDestroy, OnInit, NgZone} from '@angular/core'
import {Router} from '@angular/router'
import {ConfigService, SpbConfiguration} from '../../services/config.service'
import {EMPTY, Subscription} from 'rxjs'
import {CUSTOMERS_ROUTE_PATH, HOME_ROUTE_PATH} from '../../application/data-types'
import {TestService} from '../../services/test.service'
import {KalpService, Scenarios} from '../../services/kalp.service'
import {catchError, filter, switchMap} from 'rxjs/operators'
import {ControlService} from '../../services/control.service'
import {DataService} from '../../services/data.service'
import {environment} from '../../../environments/environment'
import {SingleSignOnService} from '@sparbanken-syd/sparbanken-syd-bankid'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public bankIdUrl = environment.authServiceUrl

  /**
   * Hide the menu etc. if not logged in at all.
   */
  public ready = false

  /**
   * Hide the menu from non Ir users
   */
  public isBrUser = false

  public mainPath = '/' + HOME_ROUTE_PATH
  public customerPath = '/' + CUSTOMERS_ROUTE_PATH

  public scenario: Scenarios | null = null

  private config$ = new Subscription()

  private scenario$ = new Subscription()

  constructor(
    public configService: ConfigService,
    public controlService: ControlService,
    public dataService: DataService,
    public testService: TestService,
    private kalpService: KalpService,
    private ngZone: NgZone,
    private router: Router,
    private singleSignOnService: SingleSignOnService
  ) {
  }

  public ngOnInit(): void {
    this.config$ = this.configService.configState$
      .pipe(
        filter((config: SpbConfiguration) => config.ready)
      )
      .subscribe({
        next: (config: SpbConfiguration) => {
          this.ready = config.ready
          this.isBrUser = config.brUser
        }
      })


    this.scenario$ = this.kalpService.scenarioChange$.subscribe({
      next: (scenario: Scenarios | null) => this.scenario = scenario
    })

    this.singleSignOnService.getToken(environment.authServiceUrl, environment.domain)
      .pipe(
        catchError(() => {
          this.logout()
          return EMPTY
        })
      )
      .subscribe(
        {
          next: (accessToken: string) => {
            this.configService.setToken(accessToken)
            return this.configService.getCurrentUser().subscribe()
          }
        })

  }

  public ngOnDestroy(): void {
    this.config$.unsubscribe()
    this.scenario$.unsubscribe()
  }

  public logout(): void {

    this.singleSignOnService.deleteToken(this.bankIdUrl).pipe(
      switchMap(() => {
        this.isBrUser = false
        return this.configService.resetToken()
      }))
      .subscribe({
        next: () => {
          this.configService.currentUser$.next({itemId: '', roles: [], sId: '', sub: '', name: ''})
          this.ready = false
          return this.router.navigate(['login'])
        }
      })
  }

  public reset(): void {
    this.dataService.reset()
    this.router.navigate(['/', HOME_ROUTE_PATH]).then()
  }
}
