import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'

export interface WaitDialogData {
  title: string
  message: string[]
  error?: string

  // Show the close button, default we do not.
  close?: boolean
}

@Component({
  selector: 'spb-wait-dialog',
  template: `<span matDialogTitle>{{data.title}}</span>
  <div matDialogContent *ngFor="let message of data.message">
    <p>{{message}}</p>
  </div>
  <p *ngIf="data.error">{{data.error}}</p>
  <div *ngIf="data.close" matDialogActions>
    <button mat-raised-button matDialogClose="" color="primary">Stäng</button>
  </div>`
})
export class WaitDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: WaitDialogData) {
  }
}
