import {Inject, Injectable} from '@angular/core'
import {BehaviorSubject, EMPTY, Observable, of, ReplaySubject} from 'rxjs'
import {LOCAL_STORAGE} from '../application/local-storage.provider'
import {HttpClient} from '@angular/common/http'
import {catchError, tap} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {HelperService} from '@sparbanken-syd/sparbanken-syd-bankid'

const ACCESS_TOKEN_NAME = 'br-at'

export const USER_ROLE = 'brUser'

/**
 * An SPB user is a sparbanken employee
 */
export interface SpbUser {
  /**
   * The database Id
   */
  itemId: string

  /**
   * Typically s-ID (s209856)
   */
  sId: string

  /**
   * Personnummer
   */
  sub: string

  /**
   * Full name like Daniel Bergdahl
   */
  name: string

  /**
   *
   */
  phone?: string

  /**
   *
   */
  pet?: string

  /**
   * An array of roles, like "admin", "credit"...
   */
  roles: string[]

  /**
   * We can have an office
   */
  office?: string

  /**
   * Emails is good and should be mandatory
   */
  email?: string
}

/**
 * Info about the logged in state to be communicated to
 * other parts of the application
 */
export interface SpbConfiguration {
  /**
   * The access token for those who need it.
   */
  token?: string | null

  /**
   * brUser - Can use the tool
   */
  brUser: boolean


  /**
   * This must always be set and should be true only if we can
   * proceed with application logic.
   */
  ready: boolean
}


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public configState$: BehaviorSubject<SpbConfiguration> =
    new BehaviorSubject<SpbConfiguration>({ready: false} as any)

  /**
   * Publish and subscribe to the currently logged in user
   * here...
   */
  public currentUser$: ReplaySubject<SpbUser> = new ReplaySubject<SpbUser>(1)

  private currentConfig: SpbConfiguration = {ready: false} as any

  constructor(
    private httpClient: HttpClient,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
  }

  /**
   * Called from Bootstrap. We need an Observable in return
   */
  public checkStatus = (): Observable<any> => {
    this.isLoggedIn()
    if (this.currentConfig.ready) {
      return this.getCurrentUser().pipe(
        catchError(() => EMPTY)
      )
    }
    return EMPTY
  }

  /**
   * Checks if user is logged in.
   */
  public isLoggedIn(): void {
    const token = this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME)
    // The set token will throw if not a valid string
    try {
      this.setToken(token)
    } catch (e) {
      // Can happen and is quite common.
    }
  }

  /**
   * Set the authentication token
   *
   * @param token - The token as received from the login service
   */
  public setToken(token: string | null): void {
    const payload = HelperService.GetTokenPayload(token)

    this.currentConfig = {
      token: null,
      brUser: false,
      ready: false
    }

    if (payload === null) {
      this.resetToken().subscribe()
    } else {
      this.currentConfig.token = token
      this.currentConfig.brUser = payload.roles.indexOf(USER_ROLE) !== -1
      this.currentConfig.ready = this.currentConfig.brUser
      this.injectedLocalStorage.setItem(ACCESS_TOKEN_NAME, token as string)
    }
    this.configState$.next(this.currentConfig)
  }

  /**
   * Reset what ever access token we might have had
   */
  public resetToken(): Observable<void> {
    this.injectedLocalStorage.removeItem(ACCESS_TOKEN_NAME)
    this.currentConfig = {ready: false} as any
    this.configState$.next(this.currentConfig)
    return of(undefined)
  }

  public getAccessToken(): string | null | undefined {
    return HelperService.ValidateToken(this.currentConfig.token)
  }

  public getCurrentUser(): Observable<SpbUser> {
    const url = `${environment.authServiceUrl}/users/self`
    return this.httpClient.get<SpbUser>(url).pipe(
      tap((user: SpbUser) => this.currentUser$.next(user))
    )
  }
}
