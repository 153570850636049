import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable} from 'rxjs'
import {debounceTime, filter, first, map, switchMap} from 'rxjs/operators'
import {DebtQuota, KalpService, LegacyKalp, Scenarios} from './kalp.service'
import {KalpLoan} from '../model/kalp-loan'
import {KalpBuilding} from '../model/kalp-building'
import {Mortgage} from '../model/mortgage'
import {StaticDataService} from './static-data.service'
import {Terms, TermsTexts} from '../model/terms-texts'

export interface Proposal {
  /**
   * This is a list of loans as we have "calculated" them
   */
  loans: KalpLoan[]

  /**
   * This is the target amount that we work against, eg. someone wants a loan of SEK 3 000 000
   * that is what we want to split into several loans.
   */
  loanAmount: number

  /**
   * Set this to true if you have made your own proposal
   */
  manual: boolean
}

export interface Questions {
  /**
   * First question, 1, 3, 5, 10 years you expect to live in the new property
   */
  q1: number

  /**
   * Second question, How much can the cost vary, 0 - little, 1, some, 2 a lot
   */
  q2: number
}

@Injectable({
  providedIn: 'root'
})
export class ProposalService {

  /**
   * This is where we publish our proposal
   *
   */
  public proposal$ = new BehaviorSubject<Proposal>({loanAmount: 0, loans: [], manual: false})

  /**
   * The current loan amount is set by someone...
   * Actually it is (was) set by the living component...
   */
  private loanAmount = 0

  private questions: Questions = {
    q1: 1,
    q2: 1
  }

  private scenario: Scenarios | null = Scenarios.Purchase

  private twoPercent = 0

  /**
   * Once the advisor has made changes, all is fixed until
   * explicitly not so.
   */
  private fixed = false

  constructor(
    private kalpService: KalpService,
    private staticDataService: StaticDataService
  ) {

    this.proposal$.pipe(
      filter((proposal: Proposal) => !!proposal)
    ).subscribe({
      next: (proposal: Proposal) => this.fixed = proposal.manual
    })

    this.kalpService.kalpResult$.pipe(
      filter((kalps: LegacyKalp[]) => kalps && kalps.length === 3)
    ).subscribe({
      next: (kalps: LegacyKalp[]) => {
        this.twoPercent = kalps[2].kalp
      }
    })

    /**
     * Normally we make a new loan if scenario, questions or loan
     * amount changes/emits
     */
    this.staticDataService.questions$.pipe(
      // Only proceed if all questions are answered
      filter((questions: Questions) => Object.values(questions)
        .reduce((complete: boolean, answer: number): boolean => complete && answer !== -1, true)),
      switchMap((questions: Questions) => {
        this.questions = questions
        return this.kalpService.loanAmount$
      }),
      switchMap((loanAmount: number) => {
        this.loanAmount = loanAmount
        return this.kalpService.scenarioChange$
      }),
      debounceTime(1000),
      // Heads up, this does pass if manual!
      filter((scenario: Scenarios | null) => !!scenario && !this.fixed)
    ).subscribe({
      next: ((scenario: Scenarios | null) => {
        this.proposal$.next({loanAmount: 0, loans: [], manual: false})
        this.scenario = scenario
        this.update()
      })
    })
  }

  public unlockProposal(): void {
    this.proposal$.pipe(
      first(),
      map((proposal: Proposal) => {
        this.fixed = false
        proposal.manual = false
        this.proposal$.next(proposal)
        this.update()
      })
    ).subscribe()
  }

  // easier to test
  public selectTerms(loans: KalpLoan[]): void {
    const terms1: Terms = {interestPercent: 0, text: 'Rörlig (3 mån)', terms: 3}
    const terms2: Terms = {interestPercent: 0, text: 'Rörlig (3 mån)', terms: 3}
    const terms3: Terms = {interestPercent: 0, text: 'Rörlig (3 mån)', terms: 3}
    const terms: Terms[] = [terms1, terms2, terms3]
    this.getLoansFromQ1(terms)
    // How long are you planning on living in the new house?

    // 1 = 1 year or less, all loans shall be three months, no exceptions
    if (this.questions.q1 === 1) {
      return this.setTerms(loans, terms)
    }

    // In the case of "Villkorsändring/Omsättning" or "Höjning" special rules apply
    // When there is only one loan it should be set on question advice only
    if (this.scenario === Scenarios.Increase || this.scenario === Scenarios.Conditions) {
      const medium = terms[1]
      const long = terms[2]
      if (loans.length === 1) {
        terms[0] = long // Loans - 1 = 0
      }

      if (loans.length === 2) {
        terms[0] = medium  // 1
        terms[1] = long   // 2 == loans .length = 2 (loans. length - 1 => long
      }
      // If more than 1 one loan can be three months
      if (loans.length > 1 && this.twoPercent > 0) {
        terms[0] = TermsTexts.getThreeMonth()
      }
      return this.setTerms(loans, terms)
    }

    // How do you feel about changes?
    // 0 = Stable, no change wanted
    // 1 = Some but not too much
    // 2 = May vary
    if (this.questions.q2 === 0) {
      // Go stable
      // No 3-month loans accepted, and we return the suggested distribution
      return this.setTerms(loans, terms)
    }

    /*
      Om det finns överskott i KALPen vid räntehöjning med 2 %  föreslås 1 eller 2 lån med 3-mån ränta
      (1 lån om sökt belopp är fördelat på 2 lån, och 2 om lånebelopp är fördelat på 3)
      */
    if (this.twoPercent > 0) {
      terms[0] = TermsTexts.getThreeMonth()
      if (loans.length > 2 && this.questions.q2 === 2) {
        terms[1] = TermsTexts.getThreeMonth() // 3-month
      }
    }
    return this.setTerms(loans, terms)
  }

  /**
   * Make a new proposal. Can be requested from the outside
   */
  private update(): void {
    if (this.scenario === Scenarios.Conditions) {
      this.setLoans()
    } else {
      this.makeProposal()
    }
  }

  /**
   * Sets the terms based on q1
   * @param terms
   * @private
   */
  private getLoansFromQ1(terms: Terms[]): any {
    switch (this.questions.q1) {
      case 1: {
        terms[0] = TermsTexts.getThreeMonth() // 3 month
        terms[1] = TermsTexts.getThreeMonth() // 3 month
        terms[2] = TermsTexts.getThreeMonth() // 3 month
        break
      }
      case 3: {
        // 3 years on first 2 and 1 o remaining
        terms[0] = TermsTexts.getByMonths(12) // 1 year
        terms[1] = TermsTexts.getByMonths(24) // 2 years
        terms[2] = TermsTexts.getByMonths(36)// 3 years
        break
      }
      default: {
        // default handles both 5 and 10 years, since they get the same result
        terms[0] = TermsTexts.getByMonths(12) // 1 year
        terms[1] = TermsTexts.getByMonths(36) // 3 years
        terms[2] = TermsTexts.getByMonths(60) // 5 years
        break
      }
    }
  }

  private setTerms(loans: KalpLoan[], terms: any): void {
    loans.forEach((loan, index) => {
      Object.assign(loan, terms[index])
    })

    // If one loan, the loan should be the longest
    if (loans.length === 1) {
      Object.assign(loans[0], terms[2])
    }

    // If two loans, the last loan should be the longest loan.
    if (loans.length === 2) {
      Object.assign(loans[1], terms[2])
    }
  }

  private getNumberOfLoans(): number {
    let numberOfLoans = 1
    const fractions = [1000 * 1000, 1500 * 1000]
    fractions.forEach((i: number) => {
      if (this.loanAmount > i) {
        numberOfLoans++
      }
    })
    return numberOfLoans
  }

  private makeProposal(): void {
    const numberOfLoans = this.getNumberOfLoans()
    const proposedLoans: KalpLoan[] = []
    //
    // [...Array(numberOfLoans).fill({})] creates an array with identical objects so they are all the same!
    for (let i = 0; i < numberOfLoans; i++) {
      const proposedLoan: KalpLoan = new KalpLoan(this.loanAmount / numberOfLoans)
      proposedLoan.new = true
      proposedLoans.push(proposedLoan)
    }

    this.selectTerms(proposedLoans)
    /**
     * flatten the proposed loans to nearest 100 000
     * 966 669 becomes 700 000
     */
    proposedLoans.forEach(loan => {
      loan.amount = Math.round(loan.amount / (100 * 1000)) * 100 * 1000
    })
    const sumOfLoans = proposedLoans.reduce((sum, loan: KalpLoan) => sum + loan.amount, 0)
    // Just adjust the last loan to fill it up to the same as loan amount.
    if (sumOfLoans !== this.loanAmount) {
      const index = sumOfLoans - this.loanAmount < 0 ? 0 : proposedLoans.length - 1
      proposedLoans[index].amount += (this.loanAmount - sumOfLoans)
    }
    this.kalpService.getActiveBuilding().subscribe({
      next: (building: KalpBuilding) => {
        proposedLoans.forEach((loan: KalpLoan) => {
          loan.property = building.id
        })
        this.sendProposal(proposedLoans)
      }
    })
  }

  private sendProposal(loans: KalpLoan[]): void {
    const proposal: Proposal = {
      loans: loans,
      loanAmount: this.loanAmount,
      manual: false
    }
    this.kalpService.setOtherLoans(proposal) // Will trigger a re-kalp
    this.updateLoansWithMortgages(proposal.loans)

    /**
     * Needs to do another kalp calculation after proposal update
     */
    this.kalpService.makeKalp()
  }

  /**
   * Sets the loans based on existing loans that need to be omsatta.
   */
  private setLoans(): void {
    let buildingId: string
    this.kalpService.getActiveBuilding().pipe(
      first(),
      switchMap((newBuilding: KalpBuilding) => {
        buildingId = newBuilding.id
        return this.kalpService.loans$
      }),
      first()
    ).subscribe({
      next: (kalpLoans: KalpLoan[]) => {
        const loans: KalpLoan[] = kalpLoans
          .filter((loan: KalpLoan) => loan.solve && loan.property === buildingId)
          .map((loan: KalpLoan) => {
            const newLoan = new KalpLoan(loan.amount)
            newLoan.interestPercent = loan.interestPercent
            newLoan.property = buildingId
            newLoan.mortgageAmount = loan.mortgageAmount
            newLoan.new = true
            return newLoan
          })
        this.selectTerms(loans)
        this.sendProposal(loans)
      }
    })
  }

  /**
   * Sets the proper mortgage based on mortgage requirement and
   * @private
   */
  private updateLoansWithMortgages(loans: KalpLoan[]): void {
    let requirement: number
    this.getMortgageRequirement().pipe(
      switchMap((mortgageRequirement: number) => {
          requirement = mortgageRequirement
          return this.kalpService.getActiveBuilding()
        }
      ))
      .subscribe({
        next: (building: KalpBuilding) => {
          const mortgage = new Mortgage(requirement, building.mortgageRequirementDebt)
          // We do it like this to avoid error on empty arrays
          loans.forEach((loan: KalpLoan, index: number) => {
            if (index === 0) {
              // In case of scenario we stick with existing amortization
              if (this.scenario === Scenarios.Conditions) {
                return
              }
              loan.setMortgageAmount(Math.max(0, mortgage.mortgageAmount - building.currentMortgage))
            }
          })
          const proposal: Proposal = {
            loans: loans,
            loanAmount: this.loanAmount,
            manual: false
          }
          this.proposal$.next(proposal)
        }
      })
  }

  /**
   * The mortgage requirement based on building loans and the "debt quota"
   * 0, 1, 2 or 3 %. The debt quota is fetched from kalp service it can
   * give an extra percent. The "Building" calculates its own extra based
   * on the loans. So when we have the building, we set the loans on the building
   * and get extra amortization requirements for free.
   * @private
   */
  private getMortgageRequirement(): Observable<number> {
    return this.kalpService.getDebtQuota().pipe(
      first(),
      map((dbq: DebtQuota) => dbq.amortizationRequirement)
    )
  }
}
