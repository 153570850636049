import { Skattesatser2022, Skattesatser2023, Tabeller2022, Tabeller2023 } from './definitions';
/**
 * These are all valid years and this object
 * must be updated when we add a new year.
 */
export const taxConfig = {
  2022: {
    columns: 7,
    retiredAge: 65
  },
  2023: {
    columns: 8,
    retiredAge: 65
  }
};
/**
 * A simple default to use if values are missing.
 */
export const defaultTaxInput = {
  personNummer: '',
  occupation: 0,
  income: 0,
  county: '',
  municipality: '',
  table: 33,
  selectTableAfterAgeReduction: false,
  taxYear: 2023
};
/**
 * Just a list of the Skattesatser, keep last year for
 * test and upgrade reasons.
 */
export const skattesatser = {
  2022: Skattesatser2022,
  2023: Skattesatser2023
};
/**
 * The available tables. Should be limited to this year,
 * and previous. The only reason for two is for easy upgrading
 * and testing
 */
export const tabeller = {
  2022: Tabeller2022,
  2023: Tabeller2023
};
