import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable, of} from 'rxjs'
import {Applicant, Car, Child, Income, Kalpylator, Parameters} from '@sparbanken-syd/kalpylator'
import {TTypeOfKalp} from './kalp.service'

export const YEARLY_TAX_VALUE = 9287

const defaultParameters: Parameters = {
  defaultTaxTable: 33,
  domain: 'default',
  livingExpenseDeduction: [
    {class: ['EMPLOYED', 'EMPLOYED'], deduction: 2400},
    {class: ['EMPLOYED', 'RETIRED'], deduction: 2000},
    {class: ['RETIRED', 'RETIRED'], deduction: 2000}
  ],
  livingExpenses: [
    {age: 6, expense: 3400},
    {age: 13, expense: 4300},
    {age: 17, expense: 5600},
    {age: 64, expense: 9600},
    {age: 67, expense: 7600},
    {age: 200, expense: 7600}
  ],
  cityDwellerFactor: 1,
  almostRetiredThreshold: 62,
  almostRetiredLivingExpense: 9100,
  almostRetiredIncomeFactor: 0.7,
  childrenBenefit: 1250, // As of 20-22
  childrenBenefitExtra: [0, 0, 150, 730, 1740, 2990, 4240],
  loanDefaults: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MORTGAGE: {
      interest: 0.06,
      mortgagePercent: 0.01
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    BLANCO: {
      interest: 0.09,
      mortgagePercent: 0.1
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    CREDIT: {
      interest: 0.09,
      mortgagePercent: 0
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    BORGEN: {
      interest: 0.1,
      mortgagePercent: 0.1
    }
  },
  propertyDefaults: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    VILLA: {
      runCost: 6200,
      yearlyTax: YEARLY_TAX_VALUE
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    HYRES: {
      runCost: 500,
      yearlyTax: 0
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    BOSTADSRATT: {
      runCost: 800,
      yearlyTax: 0
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    FRITIDSHUS: {
      runCost: 2800,
      yearlyTax: YEARLY_TAX_VALUE
    }
  },
  carDefaults: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    LEASE: {
      cost: 4700 // Standard SPB
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    OWN: {
      cost: 2500 // Standard SPB
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class NGService {

  public kalps$ = new BehaviorSubject<Kalpylator[]>(new Array(3))

  public parameters$ = new BehaviorSubject<Parameters>(defaultParameters)
  /**
   * New subjects for new kalpylator only
   */
  public ngChildren$ = new BehaviorSubject<Child[]>([])
  public ngApplicants$ = new BehaviorSubject<Applicant[]>([])
  public ngCars$ = new BehaviorSubject<Car[]>([])
  public ngIncomes$ = new BehaviorSubject<Income[]>([])

  private positionMap: Record<TTypeOfKalp, number> = {
    existing: 0,
    new: 1,
    new2p: 2
  }

  constructor() {
    this.getParameters()
  }

  /**
   * The order of the Kalps is important, the current
   * way of sorting them is stupid but will do for now
   * @param kalp
   * @param name
   */
  public setKalp(kalp: Kalpylator, name: TTypeOfKalp): void {
    const kalps = this.kalps$.value
    const pos = this.positionMap[name]
    kalps[pos] = kalp
    this.kalps$.next(kalps)
  }

  /**
   * This should eventually get the parameters from a backend
   */
  public getParameters(): Observable<Parameters> {
    return of(defaultParameters)
  }
}
