export const environment = {
  apiUrl: '/api',
  // authServiceUrl: '/service',
  authServiceUrl: 'https://spb-common-api-martin.internal.sparbankensyd.se/service',
  // datastoreUrl: '/kalp',
  datastoreUrl: 'https://br-martin.internal.sparbankensyd.se/kalp',
  rtUrl: '/interests',
  // apiUrl: 'https://br-martin.internal.sparbankensyd.se/api',
  // authServiceUrl: 'https://br-martin.internal.sparbankensyd.se/service',
  // rtUrl: 'https://br-martin.internal.sparbankensyd.se/interests',
  domain: 'br-martin.internal.sparbankensyd.se',
  testMode: true,
  production: false
}
