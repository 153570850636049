import {Injectable} from '@angular/core'
import {environment} from '../../environments/environment'
import {ActivatedRoute, ParamMap} from '@angular/router'
import {KalpService} from './kalp.service'
import {ControlService} from './control.service'

export enum TestDataType {
  ALL,
  APPLICANT,
  INCOME,
  LIVING = 3,
  EXISTINGLOANS = 4,
  QUESTIONS
}

@Injectable({
  providedIn: 'root'
})
export class TestService {

  public showTestData = environment.testMode

  public linear = false

  constructor(
    private route: ActivatedRoute,
    private kalpService: KalpService,
    private controlService: ControlService,
  ) {
    this.route.queryParamMap.subscribe({
      next: (paramsMap: ParamMap) => {
        if (paramsMap.get('index')) {
          // This happens before we have selected a new selection and is
          // therefore overriddden by the loading of customer
          this.controlService.step$.next(Number.parseInt(paramsMap.get('index') as string, 10))
        }
      }
    })
  }
}
